import {
  IconAlignLeft,
  IconCalendar,
  IconCalendarTime,
  IconClearFormatting,
  IconClock,
  IconCreditCard,
  IconCurrencyDollar,
  IconFileUpload,
  IconFlag,
  IconHash,
  IconHexagon,
  IconLink,
  IconList,
  IconListCheck,
  IconPercentage,
  IconRotateClockwise2,
  IconShieldLock,
  IconStar,
  IconToggleRight,
  IconTypography,
  IconUser,
} from '@tabler/icons-react';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  INTEGER,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
  TEXT,
} from '../constants/dataTypes';
import {
  CURRENCY,
  DATE as DATE_FORMAT,
  RATING,
  SINGLE_LINE_TEXT,
  UNFORMATTED_NUMBER,
} from '../constants/fieldFormats';
import { DataField } from '../models/DataTypeFields';

export const getIconForDataField = (field: DataField) => {
  if (field.name === 'id') {
    return IconFlag;
  }

  if (field.name === 'uuid') {
    return IconHexagon;
  }

  if (field.rollup) {
    return IconRotateClockwise2;
  }

  switch (field.type) {
    case TEXT: {
      if (field.typeOptions?.format !== SINGLE_LINE_TEXT) {
        return IconAlignLeft;
      }
      return IconTypography;
    }
    case DATE: {
      if (field.typeOptions?.format === DATE_FORMAT) {
        return IconCalendar;
      }

      return IconCalendarTime;
    }
    case INTEGER: {
      if (field.typeOptions?.format === RATING) {
        return IconStar;
      }

      if (field.typeOptions?.format === CURRENCY) {
        return IconCurrencyDollar;
      }

      if (field.typeOptions?.format === UNFORMATTED_NUMBER) {
        return IconClearFormatting;
      }

      return IconHash;
    }
    case DECIMAL: {
      if (field.typeOptions?.format === CURRENCY) {
        return IconCurrencyDollar;
      }

      if (field.typeOptions?.format === UNFORMATTED_NUMBER) {
        return IconClearFormatting;
      }

      return IconPercentage;
    }
    case DURATION:
      return IconClock;
    case BOOLEAN:
      return IconToggleRight;
    case SINGLE_OPTION:
      return IconList;
    case MULTIPLE_OPTION:
      return IconListCheck;
    case 'user':
      return IconUser;
    case 'file':
      return IconFileUpload;
    case 'membership':
      return IconCreditCard;
    case 'role':
      return IconShieldLock;
    default:
      return IconLink;
  }
};
