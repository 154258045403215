import React from 'react';
import loadable from '@loadable/component';
import {
  IconBrandYoutube,
  IconChartArea,
  IconChartBar,
  IconChartLine,
  IconChevronsRight,
  IconCreditCard,
  IconDatabase,
  IconDots,
  IconFile,
  IconFileCode,
  IconFileSymlink,
  IconFiles,
  IconFolder,
  IconId,
  IconInfoSquare,
  IconLayout2,
  IconLayoutColumns,
  IconLayoutGrid,
  IconLayoutKanban,
  IconLayoutList,
  IconLayoutRows,
  IconLink,
  IconList,
  IconListCheck,
  IconMarkdown,
  IconMenu,
  IconMessage,
  IconMessages,
  IconMouse,
  IconNote,
  IconPhoto,
  IconSeparator,
  IconShape,
  IconSpeakerphone,
  IconTable,
  Icon as IconType,
  IconTypography,
  IconVersions,
  IconVideo,
} from '@tabler/icons-react';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import {
  Badge,
  badgeTypes,
  buttonStyles,
  buttonTypes,
  horizontalNavTypes,
  variants,
} from '@noloco/components';
import { MUTATION } from '@noloco/core/src/constants/endpointTypes';
import MarkdownText from '../components/MarkdownText';
import OnboardingTasks from '../components/onboardingTasks/OnboardingTasks';
import { CREATE, UPDATE } from '../constants/actionTypes';
import { FILE } from '../constants/builtInDataTypes';
import chartAggregations from '../constants/chartAggregations';
import { AREA, BAR, LINE } from '../constants/chartTypes';
import collectionLayouts, {
  BOARD,
  CALENDAR,
  CARDS,
  COLUMNS,
  CONVERSATION,
  CollectionLayout,
  ROWS,
  TABLE,
} from '../constants/collectionLayouts';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  INTEGER,
  SINGLE_OPTION,
  TEXT,
} from '../constants/dataTypes';
import { API_REQUEST, INTERNAL } from '../constants/dataWrapperTypes';
import * as elements from '../constants/elements';
import { PAGE } from '../constants/linkTypes';
import orderByDirections from '../constants/orderByDirections';
import { MANY_TO_MANY } from '../constants/relationships';
import { DATABASE } from '../constants/scopeTypes';
import { SPAN, textTypeIcons } from '../constants/textTypes';
import timePeriods from '../constants/timePeriods';
import ElementConfig from '../models/ElementConfig';
import { Project } from '../models/Project';
import StateItem from '../models/StateItem';
import ArrayType from '../models/elementPropTypes/ArrayPropType';
import BoolType from '../models/elementPropTypes/BoolPropType';
import ComboPropType from '../models/elementPropTypes/ComboPropType';
import CustomExtractionPropType from '../models/elementPropTypes/CustomExtractionPropType';
import DataFieldPropType from '../models/elementPropTypes/DataFieldPropType';
import DataPropType from '../models/elementPropTypes/DataPropPropType';
import DataTypeNamePropType from '../models/elementPropTypes/DataTypeNamePropType';
import EnumType from '../models/elementPropTypes/EnumPropType';
import KeyMapPropType from '../models/elementPropTypes/KeyMapPropType';
import NodeType from '../models/elementPropTypes/NodePropType';
import NumberType from '../models/elementPropTypes/NumberPropType';
import PropGroup from '../models/elementPropTypes/PropGroup';
import RawDataPropType from '../models/elementPropTypes/RawDataPropPropType';
import StringType from '../models/elementPropTypes/StringPropType';
import StringPropType from '../models/elementPropTypes/StringPropType';
import VariablePropPropType from '../models/elementPropTypes/VariablePropPropType';
import ChildElementPropType from '../models/elementPropTypes/comboProps/ChildElementPropType';
import { buildCustomFilterProp } from '../models/elementPropTypes/comboProps/CustomFilterPropType';
import DataListPropType from '../models/elementPropTypes/comboProps/DataListPropType';
import EndpointPropType from '../models/elementPropTypes/comboProps/EndpointPropType';
import IconPropType from '../models/elementPropTypes/comboProps/IconPropType';
import ImagePropType from '../models/elementPropTypes/comboProps/ImagePropType';
import LinkPropType, {
  extractPageDataDependencies,
  pageDataPropTransformation,
} from '../models/elementPropTypes/comboProps/LinkPropType';
import VideoPropType from '../models/elementPropTypes/comboProps/VideoPropType';
import { findEndpoint } from '../utils/apis';
import { deriveViewState, getApiRequestState } from '../utils/elementState';
import { getText } from '../utils/lang';
import Button from './Button';
import Chart from './Chart';
import Content from './Content';
import Folder from './Folder';
import Group from './Group';
import Icon from './Icon';
import Iframe from './Iframe';
import Image from './Image';
import Link from './Link';
import Page from './Page';
import PageSwitch from './PageSwitch';
import Record from './Record';
import Text from './Text';
import Video from './Video';
import View from './View';
import ApiForm from './sections/ApiForm';
import Billing from './sections/Billing';
import Breadcrumbs from './sections/Breadcrumbs';
import Collection from './sections/Collection';
import Details from './sections/Details';
import FileSharing from './sections/FileSharing';
import FormSection from './sections/FormSection';
import Highlights from './sections/Highlights';
import Messaging from './sections/Messaging';
import QuickLinks from './sections/QuickLinks';
import Tabs from './sections/Tabs';
import Title from './sections/Title';
import RecordFileGallery from './sections/view/FileGallery';
import {
  getApiFieldsPropGroup,
  getFieldsPropGroup,
  setFormFields,
} from './utils/formOptions';
import { cloneCustomRules } from './utils/propTransformations';
import {
  deriveListInputState,
  extractCustomRulesPropDeps,
  extractRawDataPropDeps,
} from './utils/stateConfig';

const YoutubeVideo = loadable(() => import('./YoutubeVideo'));

const enumWithDefaults = (types: string[]) => ['default', ...types];

const LOREM_IPSUM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce semper eget tellus in ornare.';

const pageCloneTransformation = (element: any) => {
  const routePath = get(element, ['props', 'routePath']);
  const name = get(element, ['props', 'name']);
  const clonedRoutePath =
    !routePath || routePath === '' ? 'clone' : `${routePath}-clone`;
  let updatedElement = set(['props', 'routePath'], clonedRoutePath, element);
  updatedElement = set(
    ['props', 'new', 'publicForms'],
    undefined,
    updatedElement,
  );
  return set(['props', 'name'], `${name} (clone)`, updatedElement);
};

const VALIDATION_RULES_DEF = new ArrayType({
  field: new RawDataPropType(() => []).setPropTransformation((value) => value),
  value: new StringType().setUseRawValue(true).setAutomaticallyResolve(false),
}).setAutomaticallyResolve(false);

const FORM_FIELD_DEF = {
  conditions: new CustomExtractionPropType(
    extractCustomRulesPropDeps,
  ).setAutomaticallyResolve(false),
  customFilters: new ArrayType(buildCustomFilterProp(false)),
  defaultValue: new StringType()
    .setUseRawValue(true)
    .setAutomaticallyResolve(false),
  helpText: new StringType().setAutomaticallyResolve(false),
  newRecordTitle: new StringType().setAutomaticallyResolve(false),
  value: new StringType().setUseRawValue(true).setAutomaticallyResolve(false),
  optionsConfig: new KeyMapPropType({
    conditions: new CustomExtractionPropType(
      extractCustomRulesPropDeps,
    ).setAutomaticallyResolve(false),
  }),
  requiredConditions: new CustomExtractionPropType(
    extractCustomRulesPropDeps,
  ).setAutomaticallyResolve(false),
  validationRules: VALIDATION_RULES_DEF,
  elementConfig: new ComboPropType({
    maxValue: new StringPropType().setAutomaticallyResolve(false),
  }),
};

const CHART_TYPE = new ArrayType({
  helpText: new StringType(),
  xAxisLabel: new StringType(),
  yAxisLabel: new StringType(),
  xAxisValue: new RawDataPropType().setAutomaticallyResolve(false),
  series: new ArrayType({
    yAxisValue: new RawDataPropType().setAutomaticallyResolve(false),
  }),
  max: new StringType(),
});

const FORM_FIELDS_TYPE = new ArrayType(FORM_FIELD_DEF);

const NAVIGATE_TO_EMAIL_TYPE = {
  email: new StringPropType()
    .setUseRawValue(true)
    .setAutomaticallyResolve(false),
  subject: new StringPropType()
    .setUseRawValue(true)
    .setAutomaticallyResolve(false),
};
const NAVIGATE_TO_PAGE_TYPE = {
  pageData: new RawDataPropType(() => [TEXT, INTEGER, DECIMAL])
    .setExtractPropTypesDependencies(extractPageDataDependencies)
    .setPropTransformation(pageDataPropTransformation),
};
const NAVIGATE_TO_PHONE_TYPE = {
  phone: new StringPropType()
    .setUseRawValue(true)
    .setAutomaticallyResolve(false),
};
const NAVIGATE_TO_URL_TYPE = {
  url: new StringPropType().setUseRawValue(true).setAutomaticallyResolve(false),
};

const ACTION_BUTTONS_TYPE = new ArrayType({
  title: new StringPropType()
    .setUseRawValue(false)
    .setAutomaticallyResolve(false),
  description: new StringPropType()
    .setUseRawValue(false)
    .setAutomaticallyResolve(false),
  actions: new ArrayType({
    formFields: FORM_FIELDS_TYPE,
    navigate: new ComboPropType({
      ...NAVIGATE_TO_EMAIL_TYPE,
      ...NAVIGATE_TO_PAGE_TYPE,
      ...NAVIGATE_TO_PHONE_TYPE,
      ...NAVIGATE_TO_URL_TYPE,
    }),
    iframe: new ComboPropType({
      source: new StringPropType().setAutomaticallyResolve(false),
    }),
  }),
  link: new LinkPropType().setUseRawValue(true).setAutomaticallyResolve(false),
  notification: new ComboPropType({
    text: new StringPropType()
      .setUseRawValue(false)
      .setAutomaticallyResolve(false),
  }),
  visibilityRules: new ComboPropType({
    customRules: new CustomExtractionPropType(extractCustomRulesPropDeps)
      .setAutomaticallyResolve(false)
      .setPropTransformation(cloneCustomRules),
  }),
});

const BASE_RECORD_VIEW_TYPE = {
  subtitle: new StringType().setAutomaticallyResolve(false),
  editButtonText: new StringType().setAutomaticallyResolve(false),
  doneButtonText: new StringType().setAutomaticallyResolve(false),
  editButton: new ComboPropType({
    visibilityRules: new ComboPropType({
      customRules: new CustomExtractionPropType(extractCustomRulesPropDeps)
        .setAutomaticallyResolve(false)
        .setPropTransformation(cloneCustomRules),
    }),
  }),
};

const elementsConfig: Record<string, ElementConfig> = {
  [elements.BUTTON]: new ElementConfig({
    component: Button,
    Icon: IconMouse,
    content: true,
    props: {
      variant: new EnumType(variants),
      type: new EnumType(enumWithDefaults(buttonTypes)),
      style: new EnumType(enumWithDefaults(buttonStyles)),
      submitFormOnClick: new BoolType(),
    },
  }),
  [elements.BADGE]: new ElementConfig({
    component: Badge,
    Icon: IconNote,
    content: true,
    props: {
      variant: new EnumType(variants),
      type: new EnumType(enumWithDefaults(badgeTypes)),
    },
  }),
  [elements.CHART]: new ElementConfig({
    component: Chart,
    Icon: IconChartBar,
    canHaveChildren: false,
    props: {
      dataList: new DataListPropType(undefined),
      chartType: new EnumType([LINE, BAR, AREA]).setFormatLabel(
        (chartType: string) => {
          const ChartIcon = {
            [LINE]: IconChartLine,
            [BAR]: IconChartBar,
            [AREA]: IconChartArea,
          }[chartType];

          return (
            <div className="flex items-center">
              {ChartIcon && (
                <span className="mr-2">
                  <ChartIcon size={16} />
                </span>
              )}
              <span>
                {getText('elements', elements.CHART, 'chartType', chartType)}
              </span>
            </div>
          );
        },
      ),
      xAxisValue: new RawDataPropType((__: any, project: Project) => [
        TEXT,
        SINGLE_OPTION,
        DATE,
        INTEGER,
        DECIMAL,
        BOOLEAN,
        ...project.dataTypes.map((type) => type.name),
      ])
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true),
      yAxisValue: new RawDataPropType(() => [DATE, INTEGER, DECIMAL, BOOLEAN])
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true),
      timePeriod: new EnumType(timePeriods)
        .setFormatLabel((timePeriod) =>
          getText('elements', elements.CHART, 'timePeriod', timePeriod),
        )
        .setDisplay((props = {}) => get(props, 'xAxisValue.dataType') === DATE),
      aggregation: new EnumType(
        chartAggregations,
      ).setFormatLabel((aggregation) =>
        getText('elements', elements.CHART, 'aggregation', aggregation),
      ),
      sortBy: new DataFieldPropType(
        (element) => get(element, 'props.xAxisValue.dataType'),
        (field) => !field.relationship,
      ).setDisplay((props = {}, __1, __2, project: Project) => {
        const chartType = get(props, 'chartType');
        const dataType = get(props, 'xAxisValue.dataType');
        return (
          chartType === BAR &&
          dataType &&
          project.dataTypes.some(({ name }) => name === dataType)
        );
      }),
      sortDirection: new EnumType(orderByDirections)
        .setFormatLabel((val, element, __, project) => {
          const sortByField = get(element.props, 'sortBy');
          const dataTypeName = get(element.props, 'xAxisValue.dataType');
          const dataType = project.dataTypes.getByName(dataTypeName);
          const field = dataType && dataType.fields.getByName(sortByField);

          if (!field) {
            return null;
          }

          return `${field.display}: ${getText(
            'elements',
            elements.LIST,
            'sort',
            field.type,
            val,
          )}`;
        })
        .setDisplay((props = {}, __1, __2, project) => {
          const sortByField = get(props, 'sortBy');
          const chartType = get(props, 'chartType');
          const dataTypeName = get(props, 'xAxisValue.dataType');
          const dataType = project.dataTypes.getByName(dataTypeName);
          const field = dataType && dataType.fields.getByName(sortByField);
          return field && sortByField && chartType === BAR && dataType;
        }),
      title: new StringType(),
      subtitle: new StringType(),
      filters: new PropGroup({
        filters: new ArrayType({
          defaultValue: new StringType()
            .setUseRawValue(true)
            .setAutomaticallyResolve(true),
          field: new DataFieldPropType(
            (element) => get(element, 'props.dataList.dataType'),
            (field) =>
              field.relationship !== MANY_TO_MANY && field.type !== FILE,
          ),
          label: new StringType(),
          placeholder: new StringType(),
          multiple: new BoolType().setDisplay(
            (
              props: { dataList?: { dataType?: string } } = {},
              __,
              propPath,
              project,
            ) => {
              const { dataList: { dataType } = {} } = props;
              const fieldName = get(props, [...propPath.slice(0, -1), 'field']);
              if (!dataType || !fieldName) {
                return false;
              }
              const dt = project.dataTypes.getByName(dataType);
              if (!dt) {
                return false;
              }
              const field = dt.fields.getByName(fieldName);
              return field.relationship || field.type === SINGLE_OPTION;
            },
          ),
          // position: new EnumType(['TOP', 'LEFT', 'RIGHT']),
        }),
      }).setDisplay((props = {}) => {
        return !props.dataList || props.dataList.dataSource !== API_REQUEST;
      }),
      emptyState: new ComboPropType({
        title: new VariablePropPropType({
          hideable: false,
          placeholder: () =>
            getText(
              'core',
              elements.COLLECTION,
              'variables.emptyState.title.placeholder',
            ),
        }),
        image: new VariablePropPropType({
          type: new ImagePropType(undefined, true),
          elementType: elements.IMAGE,
        }),
      }),
    },
    deriveState: deriveListInputState,
  }),
  [elements.CONTENT]: new ElementConfig({
    component: Content,
    hidden: true,
    props: {
      items: new StringType(),
    },
  }),
  [elements.GROUP]: new ElementConfig({
    component: Group,
    Icon: IconShape,
    defaultProps: {
      w: 'full',
    },
  }),
  [elements.IFRAME]: new ElementConfig({
    component: Iframe,
    canHaveChildren: false,
    Icon: IconFileCode,
    props: {
      source: new StringType(),
      title: new StringType(),
      fullScreen: new BoolType(),
    },
  }),
  [elements.IMAGE]: new ElementConfig({
    component: Image,
    canHaveChildren: false,
    Icon: IconPhoto,
    props: {
      image: new ImagePropType(),
    },
  }),
  [elements.LINK]: new ElementConfig({
    component: Link,
    Icon: IconLink,
    props: {
      link: new LinkPropType(),
    },
  }),
  [elements.DIVIDER]: new ElementConfig({
    canHaveChildren: false,
    Icon: IconSeparator,
    props: {},
  }),
  [elements.FOLDER]: new ElementConfig({
    component: Folder,
    Icon: IconFolder,
  }),
  [elements.PAGE]: new ElementConfig({
    component: Page,
    Icon: IconFile,
    styleable: false,
    props: {
      sections: new NodeType(undefined, {
        Icon: IconList,
      }),
      dataSource: new EnumType([INTERNAL, API_REQUEST]),
      dataType: new DataTypeNamePropType(),
      endpoint: new EndpointPropType(),
      SubPages: new NodeType(undefined, {
        Icon: IconFile,
      }),
      isSubPage: new BoolType(),
    },
    deriveState: ({ id, props }, project, elementPath, context) => {
      let state: any[] = [];

      if (
        props.dataSource === API_REQUEST &&
        props.endpoint &&
        props.endpoint.endpointId
      ) {
        const { endpoint } = findEndpoint(
          project.apis,
          props.endpoint.apiId,
          props.endpoint.endpointId,
        );
        if (endpoint) {
          state = [
            ...state,
            ...getApiRequestState(
              { id, props: { endpoint: props.endpoint } },
              project,
              null,
              context,
            ),
          ];
        }
      } else if (props.dataType) {
        const dataType = project.dataTypes.getByName(props.dataType);
        if (dataType) {
          state = state.concat(
            context.getDataTypeOptions(
              new StateItem({
                id,
                dataType: props.dataType,
                path: '',
                source: DATABASE,
                display: props.name
                  ? props.name
                  : getText(
                      { dataType: props.dataType },
                      'elements',
                      elements.PAGE,
                      'state.pageType',
                    ),
              }),
            ),
          );
        }
      }
      return state;
    },
    cloneTransformation: pageCloneTransformation,
  }),
  [elements.PAGE_SWITCH]: new ElementConfig({
    component: PageSwitch,
    hidden: false,
    Icon: IconFiles,
    styleable: false,
    allowedChildTypes: [elements.PAGE],
  }),
  [elements.TEXT]: new ElementConfig({
    component: Text,
    Icon: IconTypography,
    content: true,
    props: {
      type: new EnumType([SPAN, elements.LINK, elements.BUTTON])
        .setDefault('span')
        .setFormatLabel((type: keyof typeof textTypeIcons) => (
          <div className="flex items-center">
            <Icon icon={{ name: textTypeIcons[type] }} className="w-6 h-6" />
            <span className="ml-2">
              {getText('elements', elements.TEXT, 'type', type)}
            </span>
          </div>
        )),
    },
  }),
  [elements.VIDEO]: new ElementConfig({
    component: Video,
    Icon: IconVideo,
    canHaveChildren: false,
    props: {
      video: new VideoPropType(),
      autoPlay: new BoolType(),
      muted: new BoolType(),
      loop: new BoolType(),
      controls: new BoolType(),
      poster: new ImagePropType(),
    },
  }),
  [elements.YOUTUBE_VIDEO]: new ElementConfig({
    //  @ts-expect-error not assignable to type 'ComponentType<{}> | null | undefined'.
    component: YoutubeVideo,
    Icon: IconBrandYoutube,
    props: {
      videoId: new StringType(),
      autoPlay: new BoolType().setDefault(false),
      showControls: new BoolType().setDefault(true),
      startSeconds: new StringType(),
    },
  }),

  /* --- SECTIONS ----- */
  [elements.API_FORM]: new ElementConfig({
    component: ApiForm,
    Icon: IconFileSymlink,
    canHaveChildren: false,
    section: true,
    props: {
      endpoint: new EndpointPropType()
        .setEndpointFilter(({ type }: { type: string }) => type === MUTATION)
        .setShowParams(false),
      fields: getApiFieldsPropGroup(),
      header: new PropGroup({
        title: new StringType(),
        subtitle: new StringType(),
      }),
      submitButton: new PropGroup({
        submitButton: new ComboPropType({
          text: new StringType(),
          icon: new IconPropType(),
          variant: new EnumType(variants),
          type: new EnumType(enumWithDefaults(buttonTypes)),
        }),
      }),
      successMessage: new PropGroup({
        successMessage: new ComboPropType({
          message: new StringType(),
          icon: new IconPropType(),
        }),
      }),
      errorMessage: new PropGroup({
        errorMessage: new ComboPropType({
          message: new StringType(),
          icon: new IconPropType(),
        }),
      }),
    },
  }),
  [elements.BILLING]: new ElementConfig({
    component: Billing,
    Icon: IconCreditCard,
    canHaveChildren: false,
    section: true,
    props: {
      header: new PropGroup({
        title: new StringType(),
        subtitle: new StringType(),
        subscriptionsTitle: new StringType(),
        subscriptionsSubtitle: new StringType(),
      }),
      emptyState: new PropGroup({
        emptyState: new ComboPropType({
          title: new VariablePropPropType({
            hideable: false,
            placeholder: () =>
              getText(
                'core',
                elements.COLLECTION,
                'variables.emptyState.title.placeholder',
              ),
          }),
          image: new VariablePropPropType({
            type: new ImagePropType(undefined, true),
            elementType: elements.IMAGE,
          }),
        }),
      }),
      showCustomerPortalButton: new BoolType().setDisplay(
        (__1, __2, __3, project) =>
          get(project, 'integrations.stripe.account.id'),
      ),
      tabs: new PropGroup({
        tabs: new ComboPropType({
          invoices: new StringPropType(),
          subscriptions: new StringPropType(),
        }),
      }).setDisplay((__1, __2, __3, project) =>
        get(project, 'integrations.stripe.account.id'),
      ),
    },
  }),
  [elements.BREADCRUMBS]: new ElementConfig({
    component: Breadcrumbs,
    Icon: IconDots,
    section: true,
    canHaveChildren: false,
  }),
  [elements.COLLECTION]: new ElementConfig({
    component: Collection,
    Icon: IconLayoutList,
    canHaveChildren: false,
    section: true,
    props: {
      data: new PropGroup({
        layout: new EnumType(collectionLayouts).setFormatLabel(
          (
            layout: Exclude<
              CollectionLayout,
              | 'TABLE_FULL'
              | 'CALENDAR'
              | 'TIMELINE'
              | 'GANTT'
              | 'CHARTS'
              | 'MAP'
              | 'SINGLE_RECORD'
              | 'SPLIT'
            >,
          ) => {
            const LayoutIcon: IconType = {
              [ROWS]: IconLayoutRows,
              [COLUMNS]: IconLayoutColumns,
              [CARDS]: IconLayoutGrid,
              [TABLE]: IconTable,
              [BOARD]: IconLayoutKanban,
              [CONVERSATION]: IconMessage,
            }[layout];

            return (
              <div className="flex items-center">
                {LayoutIcon && (
                  <span className="mr-2">
                    <LayoutIcon size={16} />
                  </span>
                )}
                <span>
                  {getText('elements', elements.COLLECTION, 'layout', layout)}
                </span>
              </div>
            );
          },
        ),
        dataList: new DataListPropType(undefined),
      }),
      vars: new PropGroup({
        groupBy: new RawDataPropType((__, project: Project) => [
          TEXT,
          SINGLE_OPTION,
          ...project.dataTypes.map((type) => type.name),
        ])
          // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
          .setExtractPropTypesDependencies(extractRawDataPropDeps)
          .setOnlyIncludeSelf(true)
          .setDisplay((props = {}) => props.layout === BOARD),
        groups: new ArrayType({
          field: new RawDataPropType((__, project: Project) => [
            TEXT,
            SINGLE_OPTION,
            ...project.dataTypes.map((type) => type.name),
          ])
            // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
            .setExtractPropTypesDependencies(extractRawDataPropDeps)
            .setOnlyIncludeSelf(true),
        }),
        limitPerGroup: new NumberType().setDisplay(
          (props = {}) => props.layout === BOARD,
        ),
        dateStart: new RawDataPropType(() => [DATE])
          // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
          .setExtractPropTypesDependencies(extractRawDataPropDeps)
          .setOnlyIncludeSelf(true)
          .setDisplay((props = {}) => props.layout === CALENDAR),
        dateEnd: new RawDataPropType(() => [DATE])
          // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
          .setExtractPropTypesDependencies(extractRawDataPropDeps)
          .setOnlyIncludeSelf(true)
          .setDisplay((props = {}) => props.layout === CALENDAR),
        ganttDependency: new RawDataPropType()
          // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
          .setExtractPropTypesDependencies(extractRawDataPropDeps)
          .setOnlyIncludeSelf(true),
        variables: new ComboPropType({
          image: new VariablePropPropType({
            type: new ImagePropType(undefined, true),
            elementType: elements.IMAGE,
          }),
          title: new VariablePropPropType({
            hideable: false,
            placeholder: () =>
              getText(
                'core',
                elements.COLLECTION,
                'variables.title.placeholder',
              ),
            hasLabel: (element) =>
              element.props && element.props.layout === TABLE,
          }),
          description: new VariablePropPropType({
            placeholder: () => LOREM_IPSUM,
          }),
          secondaryText: new VariablePropPropType({
            hasLabel: (element) =>
              element.props && element.props.layout !== CONVERSATION,
            placeholder: () => LOREM_IPSUM,
          }),
        }),
        additionalElements: new ArrayType({
          label: new StringType(),
          element: new ChildElementPropType(),
          fullWidth: new BoolType(),
        }),
      }),
      header: new PropGroup({
        title: new StringType(),
        subtitle: new StringType(),
      }),
      filters: new PropGroup({
        filters: new ArrayType({
          field: new DataFieldPropType(
            (element) => get(element, 'props.dataList.dataType'),
            (field) =>
              field.relationship !== MANY_TO_MANY && field.type !== FILE,
          ),
          label: new StringType(),
          placeholder: new StringType(),
          multiple: new BoolType().setDisplay(
            (
              props: { dataList?: { dataType?: string } } = {},
              __,
              propPath,
              project,
            ) => {
              const { dataList: { dataType } = {} } = props;
              const fieldName = get(props, [...propPath.slice(0, -1), 'field']);
              if (!dataType || !fieldName) {
                return false;
              }
              const dt = project.dataTypes.getByName(dataType);
              if (!dt) {
                return false;
              }
              const field = dt.fields.getByName(fieldName);
              return field.relationship || field.type === SINGLE_OPTION;
            },
          ),
          filter: new RawDataPropType((element, project, propPath = []) => {
            const dataType = get(element, 'props.dataList.dataType');
            const fieldName = get(element.props, [
              ...propPath.slice(0, -1),
              'field',
            ]);
            if (!dataType || !fieldName) {
              return [];
            }
            const dt = project.dataTypes.getByName(dataType);
            if (!dt) {
              return [];
            }
            const field = dt.fields.getByName(fieldName);
            return field ? [field.type] : [];
          })
            .setShowCollections(true)
            .setDisplay(
              (
                props: { dataList?: { dataType?: string } } = {},
                __,
                propPath,
                project,
              ) => {
                const { dataList: { dataType } = {} } = props;
                const fieldName = get(props, [
                  ...propPath.slice(0, -1),
                  'field',
                ]);
                if (!dataType || !fieldName) {
                  return false;
                }
                const dt = project.dataTypes.getByName(dataType);
                if (!dt) {
                  return false;
                }
                const field = dt.fields.getByName(fieldName);
                return field.relationship;
              },
            ),
          customFilters: new ArrayType(buildCustomFilterProp(true)),
          defaultValue: new StringType()
            .setUseRawValue(true)
            .setAutomaticallyResolve(true),
          helpText: new StringType().setAutomaticallyResolve(false),
          conditions: new CustomExtractionPropType(
            extractCustomRulesPropDeps,
          ).setAutomaticallyResolve(false),
        }),
      }).setDisplay((props = {}) => {
        return !props.dataList || props.dataList.dataSource !== API_REQUEST;
      }),
      emptyState: new PropGroup({
        emptyState: new ComboPropType({
          title: new VariablePropPropType({
            hideable: false,
            placeholder: () =>
              getText(
                'core',
                elements.COLLECTION,
                'variables.emptyState.title.placeholder',
              ),
          }),
          image: new VariablePropPropType({
            type: new ImagePropType(undefined, true),
            elementType: elements.IMAGE,
          }),
        }),
      }),
      link: new PropGroup({
        link: new LinkPropType(undefined, true),
      }),
      formOptions: new PropGroup({
        addNewButton: new BoolType().setOnChange(
          (
            canAddNew,
            previousCanAddNew,
            { updateProperty, element, project },
          ) => {
            const allowEditing = get(element, 'props.allowEditing', false);
            const inlineEditing = get(element, 'props.inlineEditing', false);
            if (
              canAddNew &&
              !previousCanAddNew &&
              !allowEditing &&
              !inlineEditing
            ) {
              const dataTypeName = get(element, 'props.dataList.dataType');
              setFormFields(dataTypeName, project.dataTypes, updateProperty);
            }
          },
        ),
        newButton: new ComboPropType({
          visibilityRules: new ComboPropType({
            customRules: new CustomExtractionPropType(
              extractCustomRulesPropDeps,
            )
              .setAutomaticallyResolve(false)
              .setPropTransformation(cloneCustomRules),
          }),
        }),
        newButtonText: new StringType().setDisplay(
          (props = {}) => props.addNewButton,
        ),
        allowEditing: new BoolType().setOnChange(
          (canEdit, previousCanEdit, { updateProperty, element, project }) => {
            const addNewButton = get(element, 'props.addNewButton', false);
            const inlineEditing = get(element, 'props.inlineEditing', false);

            if (
              canEdit &&
              !previousCanEdit &&
              !addNewButton &&
              !inlineEditing
            ) {
              const dataTypeName = get(element, 'props.dataList.dataType');
              setFormFields(dataTypeName, project.dataTypes, updateProperty);
            }
          },
        ),
        editButtonText: new StringType(true).setDisplay(
          (props = {}) => props.allowEditing,
        ),
        inlineEditing: new BoolType().setOnChange(
          (canEdit, previousCanEdit, { updateProperty, element, project }) => {
            const addNewButton = get(element, 'props.addNewButton', false);
            const allowEditing = get(element, 'props.allowEditing', false);
            if (canEdit && !previousCanEdit && !addNewButton && !allowEditing) {
              const dataTypeName = get(element, 'props.dataList.dataType');
              setFormFields(dataTypeName, project.dataTypes, updateProperty);
            }
          },
        ),
        allowDeleting: new BoolType(),
        confirmDeleteText: new StringType(true).setDisplay(
          (props = {}) => props.allowDeleting,
        ),
      }).setDisplay(
        (props) => get(props, 'dataList.dataSource') !== API_REQUEST,
      ),
      fields: new ArrayType({
        customFilters: new ArrayType(
          buildCustomFilterProp(false),
        ).setAutomaticallyResolve(false),
        value: new StringType().setUseRawValue(true),
        conditions: new CustomExtractionPropType(
          extractCustomRulesPropDeps,
        ).setAutomaticallyResolve(false),
        elementConfig: new ComboPropType({
          maxValue: new StringPropType().setAutomaticallyResolve(false),
        }),
      }),
      formFields: getFieldsPropGroup((element) =>
        get(element, 'props.dataList.dataType'),
      ).setDisplay(
        (props = {}) =>
          props.addNewButton || props.allowEditing || props.inlineEditing,
      ),
      charts: CHART_TYPE,
      record: new ComboPropType({
        actionButtons: ACTION_BUTTONS_TYPE,
      }),
    },
    deriveState: deriveListInputState,
  }),
  [elements.DETAILS]: new ElementConfig({
    component: Details,
    Icon: IconId,
    section: true,
    canHaveChildren: false,
    props: {
      title: new VariablePropPropType({
        placeholder: () => '',
      }),
      subtitle: new VariablePropPropType({
        placeholder: () => '',
      }),
      additionalElements: new ArrayType({
        label: new StringType(),
        element: new ChildElementPropType(),
        fullWidth: new BoolType(),
      }),
      formOptions: new PropGroup({
        allowEditing: new BoolType().setOnChange(
          (canEdit, previousCanEdit, { updateProperty, element, project }) => {
            if (canEdit && !previousCanEdit) {
              const dataTypeName = get(element, 'props.dataType');
              setFormFields(dataTypeName, project.dataTypes, updateProperty);
            }
          },
        ),
        editButtonText: new StringType().setDisplay(
          (props = {}) => props.allowEditing,
        ),
        allowDeleting: new BoolType(),
        dataType: new DataTypeNamePropType(),
        which: new DataPropType(({ props: { dataType = undefined } = {} }) =>
          dataType ? [dataType] : [],
        ).setDisplay((props = {}) => props.allowEditing || props.allowDeleting),
        pageAfterDelete: new LinkPropType()
          .setEditorProps({
            options: [PAGE],
            label: 'Page after delete',
          })
          .setDisplay((props = {}) => props.allowDeleting),
        confirmDeleteText: new StringType().setDisplay(
          (props = {}) => props.allowDeleting,
        ),
      }),
      fields: FORM_FIELDS_TYPE,
      formFields: getFieldsPropGroup().setDisplay(
        (props = {}) => props.addNewButton || props.allowEditing,
      ),
      actionButtons: ACTION_BUTTONS_TYPE,
    },
  }),
  [elements.FILE_SHARING]: new ElementConfig({
    component: FileSharing,
    Icon: IconFiles,
    section: true,
    canHaveChildren: false,
  }),
  [elements.MESSAGING]: new ElementConfig({
    component: Messaging,
    Icon: IconMessages,
    section: true,
    canHaveChildren: false,
    props: {
      clientTopMessage: new StringPropType(),
    },
  }),
  [elements.TITLE]: new ElementConfig({
    component: Title,
    Icon: IconSpeakerphone,
    section: true,
    canHaveChildren: false,
    props: {
      image: new VariablePropPropType({
        type: new ImagePropType(undefined, true),
        elementType: elements.IMAGE,
      }),
      title: new VariablePropPropType({
        hideable: false,
        placeholder: () =>
          getText('core', elements.COLLECTION, 'variables.title.placeholder'),
        hasLabel: (element) => element.props && element.props.layout === TABLE,
      }),
      subtitle: new VariablePropPropType({
        placeholder: () => LOREM_IPSUM,
      }),
      coverPhoto: new VariablePropPropType({
        type: new ImagePropType(undefined, true),
        elementType: elements.IMAGE,
        hideable: true,
      }),
      buttons: new ArrayType({
        text: new StringType(),
        icon: new IconPropType(),
        link: new LinkPropType(),
      }),
      actionButtons: ACTION_BUTTONS_TYPE,
    },
  }),
  [elements.FORM_V2]: new ElementConfig({
    component: FormSection,
    Icon: IconMenu,
    canHaveChildren: false,
    section: true,
    props: {
      dataType: new DataTypeNamePropType().setOnChange(
        (newValue, oldValue, { updateProperty, project }) => {
          if (newValue !== oldValue) {
            setFormFields(newValue, project.dataTypes, updateProperty);
          }
        },
      ),
      type: new EnumType([CREATE, UPDATE]),
      which: new DataPropType(
        ({
          props: { dataType, type } = {},
        }: {
          props: { dataType?: string; type?: string };
        }) => (type ? [dataType] : []),
      ).setDisplay((props = {}) => props.type === UPDATE),
      fields: getFieldsPropGroup(),
      header: new PropGroup({
        title: new StringType(),
        subtitle: new StringType(),
      }),
      submitButton: new PropGroup({
        submitButton: new ComboPropType({
          text: new StringType(),
          icon: new IconPropType(),
          variant: new EnumType(variants),
          type: new EnumType(enumWithDefaults(buttonTypes)),
        }),
      }),
      successMessage: new PropGroup({
        successMessage: new ComboPropType({
          message: new StringType(),
          icon: new IconPropType(),
        }),
      }),
      errorMessage: new PropGroup({
        errorMessage: new ComboPropType({
          message: new StringType(),
          icon: new IconPropType(),
        }),
      }),
      helpText: new StringPropType(),
      validationRules: VALIDATION_RULES_DEF,
    },
  }),
  [elements.HIGHLIGHTS]: new ElementConfig({
    Icon: IconInfoSquare,
    component: Highlights,
    canHaveChildren: false,
    section: true,
    props: {
      fields: FORM_FIELDS_TYPE,
      highlights: new ArrayType({
        label: new StringType(),
        text: new StringType(),
        icon: new IconPropType(),
      }).setMinimum(1),
    },
  }),
  [elements.QUICK_LINKS]: new ElementConfig({
    Icon: IconLink,
    component: QuickLinks,
    canHaveChildren: false,
    section: true,
    props: {
      links: new ArrayType({
        title: new StringType(),
        description: new StringType(),
        link: new LinkPropType(),
        icon: new IconPropType(),
        visibilityRules: new ComboPropType({
          customRules: new CustomExtractionPropType(extractCustomRulesPropDeps)
            .setAutomaticallyResolve(false)
            .setPropTransformation(cloneCustomRules),
        }),
      })
        .setMinimum(1)
        .setIncludeSelf(true),
      dense: new BoolType(),
    },
  }),
  [elements.MARKDOWN]: new ElementConfig({
    Icon: IconMarkdown,
    component: MarkdownText,
    canHaveChildren: false,
    props: {
      markdownText: new StringPropType().setMultiLine(true),
      content: new StringPropType().setMultiLine(true),
    },
  }),
  [elements.FILE_GALLERY]: new ElementConfig({
    component: RecordFileGallery,
    Icon: IconFiles,
    canHaveChildren: false,
    props: {},
  }),
  [elements.ONBOARDING_TASKS]: new ElementConfig({
    Icon: IconListCheck,
    component: OnboardingTasks,
    canHaveChildren: false,
    section: true,
    props: {
      title: new StringType(),
      subtitle: new StringType(),
    },
  }),
  [elements.RECORD]: new ElementConfig({
    Icon: IconDatabase,
    component: Record,
    canHaveChildren: false,
    props: {
      field: new RawDataPropType((__, project: Project) =>
        project.dataTypes.map((type) => type.name),
      )
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setIncludeCollections(true),
      emptyState: new StringType(),
    },
  }),
  [elements.RECORD_VIEW]: new ElementConfig({
    Icon: IconDatabase,
    canHaveChildren: false,
    props: BASE_RECORD_VIEW_TYPE,
  }),
  [elements.TABS]: new ElementConfig({
    component: Tabs,
    Icon: IconVersions,
    section: true,
    canHaveChildren: false,
    props: {
      tabType: new EnumType(horizontalNavTypes),
    },
  }),
  [elements.SECTION]: new ElementConfig({
    component: null,
    props: {
      fields: new ArrayType({
        customFilters: new ArrayType(buildCustomFilterProp()),
        value: new StringType().setUseRawValue(true),
      }),
    },
  }),
  [elements.FORM_SECTION]: new ElementConfig({
    component: null,
    props: {
      title: new VariablePropPropType({
        placeholder: () => '',
      }),
      subtitle: new VariablePropPropType({
        placeholder: () => '',
      }),
      fields: new ArrayType({
        config: new ComboPropType(FORM_FIELD_DEF),
      }),
      helpText: new StringPropType(),
    },
  }),
  [elements.STAGES]: new ElementConfig({
    component: null,
    Icon: IconChevronsRight,
    props: {
      stages: new DataPropType(() => [SINGLE_OPTION])
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true)
        .setAutomaticallyResolve(false),
      optionsConfig: new KeyMapPropType({
        conditions: new CustomExtractionPropType(
          extractCustomRulesPropDeps,
        ).setAutomaticallyResolve(false),
      }),
    },
  }),
  [elements.ACTION_BUTTONS]: new ElementConfig({
    component: null,
    props: {
      actionButtons: ACTION_BUTTONS_TYPE,
    },
  }),
  [elements.FIELD_CELL]: new ElementConfig({
    component: null,
    props: {
      elementConfig: new ComboPropType({
        maxValue: new StringPropType().setAutomaticallyResolve(false),
      }),
    },
  }),
  [elements.VIEW]: new ElementConfig({
    component: View,
    Icon: IconLayout2,
    section: true,
    canHaveChildren: false,
    props: {
      layout: new EnumType(collectionLayouts),
      dataList: new DataListPropType(undefined),
      limitPerGroup: new StringPropType(),
      // `groupBy` is deprectaed in favour of `groups` but we need to continue to support it
      groupBy: new RawDataPropType((__, project: Project) => [
        TEXT,
        SINGLE_OPTION,
        ...project.dataTypes.map((type) => type.name),
      ])
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true),
      groups: new ArrayType({
        field: new RawDataPropType((__, project: Project) => [
          TEXT,
          SINGLE_OPTION,
          ...project.dataTypes.map((type) => type.name),
        ])
          // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
          .setExtractPropTypesDependencies(extractRawDataPropDeps)
          .setOnlyIncludeSelf(true),
      }),
      dateStart: new RawDataPropType(() => [DATE])
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true),
      dateEnd: new RawDataPropType(() => [DATE])
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true),
      ganttDependency: new RawDataPropType()
        // @ts-expect-error TS2345: Argument of type is not assignable to parameter.
        .setExtractPropTypesDependencies(extractRawDataPropDeps)
        .setOnlyIncludeSelf(true),
      title: new StringType(),
      subtitle: new StringType(),
      coverPhoto: new ImagePropType(),
      emptyState: new ComboPropType({
        title: new VariablePropPropType({
          hideable: false,
          placeholder: () =>
            getText(
              'core',
              elements.COLLECTION,
              'variables.emptyState.title.placeholder',
            ),
        }),
        subtitle: new VariablePropPropType({
          hideable: false,
          placeholder: () => '',
        }),
        image: new VariablePropPropType({
          type: new ImagePropType(undefined, true),
          elementType: elements.IMAGE,
        }),
      }),
      fields: new ArrayType({
        customFilters: new ArrayType(
          buildCustomFilterProp(false),
        ).setAutomaticallyResolve(false),
        value: new StringType().setUseRawValue(true),
        conditions: new CustomExtractionPropType(
          extractCustomRulesPropDeps,
        ).setAutomaticallyResolve(false),
        elementConfig: new ComboPropType({
          maxValue: new StringPropType().setAutomaticallyResolve(false),
        }),
      }),
      filters: new ArrayType({
        customFilters: new ArrayType(buildCustomFilterProp(true)),
        defaultValue: new StringType()
          .setUseRawValue(true)
          .setAutomaticallyResolve(true),
        conditions: new CustomExtractionPropType(
          extractCustomRulesPropDeps,
        ).setAutomaticallyResolve(false),
        helpText: new StringType(),
      }),
      charts: CHART_TYPE,
      newButton: new ComboPropType({
        visibilityRules: new ComboPropType({
          customRules: new CustomExtractionPropType(extractCustomRulesPropDeps)
            .setAutomaticallyResolve(false)
            .setPropTransformation(cloneCustomRules),
        }),
      }),
      newButtonText: new StringType(),
      actionButtons: ACTION_BUTTONS_TYPE,
      editRelatedRecordButtons: new ComboPropType({
        config: new ComboPropType({
          customFilters: new ArrayType(buildCustomFilterProp()),
        }),
        show: new BoolType(),
        unlinkButtonText: new StringPropType(),
      }),
      exportButton: new ComboPropType({
        show: new BoolType(),
        text: new StringPropType(),
      }),
      importButton: new ComboPropType({
        show: new BoolType(),
        text: new StringPropType(),
        fields: new ArrayType({
          value: new StringType(),
        }),
      }),
      new: new ComboPropType({
        title: new StringType(),
        subtitle: new StringType(),
        coverPhoto: new ImagePropType(),
        saveButtonText: new StringType(),
        fields: FORM_FIELDS_TYPE,
      }),
      record: new ComboPropType({
        sections: new NodeType(undefined, {
          Icon: IconList,
        }),
        actionButtons: ACTION_BUTTONS_TYPE,
        tabs: new ArrayType({
          visibilityRules: new ComboPropType({
            customRules: new CustomExtractionPropType(
              extractCustomRulesPropDeps,
            )
              .setAutomaticallyResolve(false)
              .setPropTransformation(cloneCustomRules),
          }),
        }),
        ...BASE_RECORD_VIEW_TYPE,
      }),
    },
    deriveState: deriveViewState,
    cloneTransformation: pageCloneTransformation,
  }),
};

export default elementsConfig;
