import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import { getColorShade } from '@noloco/components';
import MarkdownText from '../../components/MarkdownText';
import withActionHandler from '../../components/canvas/withActionHandler';
import { BUTTON, LINK } from '../../constants/elements';
import { IS_WEBSITE_EXAMPLES } from '../../constants/env';
import SectionButton from './SectionButton';
import ActionButton from './view/ActionButton';

type Props = {};

const Title = forwardRef<any, Props>(
  (
    {
      // @ts-expect-error TS(2339): Property 'buttons' does not exist on type 'Props'.
      buttons,
      // @ts-expect-error TS(2339): Property 'breadcrumbs' does not exist on type 'Pro... Remove this comment to see the full error message
      breadcrumbs,
      // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
      className,
      children,
      // @ts-expect-error TS(2339): Property 'coverPhoto' does not exist on type 'Prop... Remove this comment to see the full error message
      coverPhoto,
      // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type 'Prop... Remove this comment to see the full error message
      editorMode,
      // @ts-expect-error TS(2339): Property 'image' does not exist on type 'Props'.
      image,
      // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type 'Props'... Remove this comment to see the full error message
      subtitle,
      // @ts-expect-error TS(2339): Property 'title' does not exist on type 'Props'.
      title,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Props'.
      onClick,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type 'Props'.
      project,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Props'.
      theme,
      // @ts-expect-error TS(2339): Property 'truncateTitle' does not exist on type 'Props'.
      truncateTitle,
    },
    ref,
  ) => {
    const secondaryColor = theme.brandColorGroups.secondary;
    const hasCoverPhoto =
      coverPhoto &&
      !coverPhoto.hidden &&
      coverPhoto.value &&
      coverPhoto.value.src;

    const TitleEl = IS_WEBSITE_EXAMPLES ? 'span' : 'h1';

    return (
      <div
        className={classNames(className, 'flex flex-col')}
        onClick={onClick}
        ref={ref}
      >
        {hasCoverPhoto && (
          <div
            className="flex rounded-lg overflow-hidden h-80 w-full bg-center bg-cover"
            style={{
              backgroundImage: `url(${coverPhoto.value.src
                .replace(/\(/g, '%28')
                .replace(/\)/g, '%29')})`,
            }}
          />
        )}
        {breadcrumbs}
        <div
          className={classNames(
            'flex md:flex-wrap items-center justify-center',
            {
              'pt-4': hasCoverPhoto,
            },
          )}
        >
          {!image.hidden && (
            <div
              className={classNames('mr-8 sm:w-full', {
                'ml-12': hasCoverPhoto,
              })}
            >
              <Box
                is="div"
                className={classNames(
                  'sm:mx-auto sm:mb-4 w-24 h-24 rounded-full sm:rounded-0 text-white bg-center bg-cover',
                  {
                    'w-20 h-20': !hasCoverPhoto,
                    'w-30 h-30 -mt-16 border-white border-4': hasCoverPhoto,
                  },
                )}
                bg={getColorShade(secondaryColor, 400)}
                style={{
                  backgroundImage: `url(${
                    image.value.src || `https://picsum.photos/id/1020/600/600`
                  })`,
                }}
              >
                <span className="block w-full h-full" />
              </Box>
            </div>
          )}
          {(!title.hidden || !subtitle.hidden) && (
            <div
              className={classNames(
                'flex items-center sm:w-full overflow-x-hidden',
                {
                  'w-full': !children && buttons.length === 0,
                },
              )}
            >
              <div className="flex flex-col overflow-hidden">
                {!title.hidden && (
                  <TitleEl
                    className={classNames(
                      'font-medium tracking-wider text-xl sm:overflow-visible sm:whitespace-normal',
                      {
                        truncate: truncateTitle,
                      },
                    )}
                  >
                    {title.value}
                  </TitleEl>
                )}
                {!subtitle.hidden && (
                  <MarkdownText className="mt-1" small={false}>
                    {subtitle.value}
                  </MarkdownText>
                )}
              </div>
            </div>
          )}
          {(children || buttons.length > 0) && (
            <div className="ml-auto flex items-end pl-4 sm:pl-0 sm:w-full sm:ml-0 sm:mt-4 space-x-2 flex-wrap sm:flex-nowrap space-y-2 justify-end">
              {children}
              {buttons.map((button: any, index: any) => {
                let ButtonComponent = SectionButton;
                if (
                  !editorMode &&
                  button.actions &&
                  button.actions.length > 0
                ) {
                  // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<RefAttributes<unkn... Remove this comment to see the full error message
                  ButtonComponent = withActionHandler(
                    SectionButton,
                    {
                      ...button,
                      type: BUTTON,
                    },
                    project,
                  );

                  return (
                    // @ts-expect-error TS(2322): Type '{ button: any; key: any; }' is not assignabl... Remove this comment to see the full error message
                    <ButtonComponent button={button} key={button.id || index} />
                  );
                } else if (button.link) {
                  let buttonConfig = set('type', LINK, button);
                  buttonConfig = set('buttonText', button.text, buttonConfig);
                  return (
                    <ActionButton
                      actionButton={buttonConfig}
                      editorMode={editorMode}
                      index={index}
                      project={project}
                      key={buttonConfig.id || index}
                    />
                  );
                }

                return (
                  // @ts-expect-error TS(2322): Type '{ button: any; key: any; }' is not assignabl... Remove this comment to see the full error message
                  <ButtonComponent button={button} key={button.id || index} />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  },
);

Title.defaultProps = {
  // @ts-expect-error TS(2322): Type '{ image: { hidden: boolean; }; buttons: neve... Remove this comment to see the full error message
  image: {
    hidden: true,
  },
  buttons: [],
  truncateTitle: true,
};

Title.displayName = 'Title';

export default withTheme(Title);
