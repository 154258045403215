import React, { forwardRef } from 'react';
import { Button as BaseButton } from '@noloco/components';
import { CUSTOM, Variant } from '@noloco/components/src/constants/variants';

type Props = {
  className?: string;
  disabled?: boolean;
  type?: any; // TODO: oneOfWithDefault(buttonTypes)
  buttonType?: string;
  style?: any; // TODO: oneOfWithDefault(buttonStyles)
  variant?: Variant;
};

const Button = forwardRef<any, Props>(
  (
    {
      children,
      className,
      disabled,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Props'.
      onClick,
      type,
      buttonType,
      // @ts-expect-error TS(2339): Property 'is' does not exist on type 'Props'.
      is,
      // @ts-expect-error TS(2339): Property 'href' does not exist on type 'Props'.
      href,
      // @ts-expect-error TS(2339): Property 'target' does not exist on type 'Props'.
      target,
      // @ts-expect-error TS(2339): Property 'rel' does not exist on type 'Props'.
      rel,
      // @ts-expect-error TS(2339): Property 'size' does not exist on type 'Props'.
      size,
      style,
      // @ts-expect-error TS(2339): Property 'submitFormOnClick' does not exist on typ... Remove this comment to see the full error message
      submitFormOnClick,
      variant,
    },
    ref,
  ) => (
    <BaseButton
      ref={ref}
      className={className}
      disabled={disabled}
      type={type}
      buttonType={submitFormOnClick ? 'submit' : buttonType}
      href={href}
      is={is}
      onClick={onClick}
      rel={rel}
      size={size}
      style={style}
      target={target}
      variant={variant}
    >
      {children}
    </BaseButton>
  ),
);

Button.defaultProps = {
  variant: CUSTOM,
};

export default Button;
