import React, { useCallback } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import loadable from '@loadable/component';
import { IconPaperclip, IconSend, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import { Loader, getColorShade } from '@noloco/components';
import useRefCallback from '@noloco/components/src/utils/hooks/useRefCallback';
import { ACCEPTED_MIMETYPES } from '../constants/mimetypes';
import AttachmentIcon from '../elements/sections/messaging/AttachmentIcon';
import useIsMacOs from '../utils/hooks/useIsMacOs';
import useReadFileInputAttachments from '../utils/hooks/useReadFileInputAttachments';

const AsyncRichTextEditor = loadable(() =>
  import('./richTextEditor/RichTextEditor'),
);

const MessageInput = ({
  className,
  dataTypes,
  isLoading,
  files,
  mentions,
  onChange,
  onChangeFiles,
  onSubmit,
  placeholder,
  projectName,
  richTextControls,
  shouldFocus,
  theme,
  value,
}: any) => {
  const secondaryColor = theme.brandColorGroups.secondary;
  const isMacOs = useIsMacOs();
  const handleEnter = useRefCallback(
    (event: any) => {
      if (
        event.code === 'Enter' &&
        ((!isMacOs && event.ctrlKey) || (isMacOs && event.metaKey))
      ) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        onSubmit(event);
        return false;
      }
    },
    // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
    [onSubmit],
  );

  const removeFile = useCallback(
    (file: any) => {
      // @ts-expect-error TS(7031): Binding element 'existingFile' implicitly has an '... Remove this comment to see the full error message
      onChangeFiles(files.filter(([existingFile]) => file !== existingFile));
    },
    [files, onChangeFiles],
  );

  const readFileInputAttachments = useReadFileInputAttachments();

  const onSelectFiles = useCallback(
    (newFiles: any) => {
      readFileInputAttachments(newFiles).then((resolvedFiles) => {
        onChangeFiles([...files, ...resolvedFiles]);
      });
    },
    [files, onChangeFiles, readFileInputAttachments],
  );

  const handlePaste = useRefCallback(
    (event: any) => {
      // Stop data actually being pasted into div
      event.stopPropagation();
      event.preventDefault();

      const files = event.clipboardData.files;
      if (files && files.length > 0) {
        onSelectFiles(files);
      }
    },
    // @ts-expect-error TS(2322): Type '(newFiles: any) => void' is not assignable t... Remove this comment to see the full error message
    [onSelectFiles],
  );

  return (
    <div className={classNames('flex flex-col items-end w-full', className)}>
      <div className="flex items-end w-full relative text-sm">
        <AsyncRichTextEditor
          className="max-h-screen-50 px-3 py-2"
          dataTypes={dataTypes}
          mentions={mentions}
          onChange={onChange}
          onEnter={handleEnter}
          onPaste={handlePaste}
          placeholder={placeholder}
          projectName={projectName}
          richTextControls={richTextControls}
          shouldFocus={shouldFocus}
          value={value}
        >
          <div className="flex items-end">
            <label
              htmlFor="file-attachment"
              className={classNames(
                'p-2 ml-2 rounded-lg hover:bg-gray-100',
                `text-${getColorShade(secondaryColor, '600')}`,
              )}
            >
              <IconPaperclip size={16} />
              <input
                accept={ACCEPTED_MIMETYPES.join(',')}
                onChange={({ target: { files: inputFiles } }) =>
                  onSelectFiles(inputFiles)
                }
                type="file"
                multiple={true}
                id="file-attachment"
                className="hidden"
              />
            </label>
            <button
              onClick={onSubmit}
              disabled={isLoading}
              className={classNames(
                'p-2 ml-2 rounded-lg text-white',
                `bg-${getColorShade(secondaryColor, '500')}`,
                `hover:bg-${getColorShade(secondaryColor, '600')}`,
              )}
            >
              {!isLoading ? <IconSend size={16} /> : <Loader size="sm" />}
            </button>
          </div>
        </AsyncRichTextEditor>
      </div>
      {files.length > 0 && (
        <div className="flex flex-wrap items-center w-full pb-2 px-2">
          {/* @ts-expect-error TS(7031): Binding element 'file' implicitly has an 'any' typ... Remove this comment to see the full error message */}
          {files.map(([file, __, preview]) => (
            <div
              key={preview}
              className="bg-gray-100 group relative rounded-lg m-1 p-2 w-36"
            >
              <div className="truncate">
                <AttachmentIcon
                  mimetype={file.type}
                  size={18}
                  className="opacity-50 mr-2 inline-block"
                />
                <span className="text-gray-700 text-xs">{file.name}</span>
              </div>
              <div
                onClick={() => removeFile(file)}
                className="absolute hidden group-hover:flex bg-gray-400 top-0 right-0 w-6 h-6 -mt-2 -mr-2 rounded-full items-center justify-center text-sm text-white cursor-pointer"
              >
                <IconX size={8} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

MessageInput.defaultProps = {
  shouldFocus: true,
};

export default withTheme(MessageInput);
