import React, { forwardRef } from 'react';
import Popover from './Popover';

const OFFSET = [0, 4];

type Props = {
  content: React.ReactNode;
  delayShow?: number;
  disabled?: boolean;
  isOpen?: boolean;
  popoverClassName?: string;
  offset?: [number, number];
  trigger?: 'click' | 'hover' | 'none';
  children?: any;
  showArrow?: any;
  placement?: any;
  bg?: string;
  p?: number | { t?: number; b?: number; l?: number; r?: number };
};

const Tooltip = forwardRef<any, Props>(
  (
    {
      children,
      content,
      disabled,
      offset = OFFSET,
      popoverClassName,
      trigger,
      bg,
      ...rest
    },
    ref,
  ) => (
    <Popover
      content={<div className="max-w-xs text-xs">{content}</div>}
      disabled={disabled}
      {...rest}
      offset={offset}
      popoverClassName={popoverClassName}
      ref={ref}
      trigger={trigger}
      bg={bg}
    >
      {children}
    </Popover>
  ),
);

Tooltip.defaultProps = {
  disabled: false,
  trigger: 'hover',
};

export default Tooltip;
