import {
  AUDIO,
  DOCUMENT,
  FileType,
  IMAGE,
  TEXT,
  VIDEO,
} from '../constants/fileTypes';
import {
  AUDIO_MIMETYPES,
  DOCUMENT_MIMETYPES,
  IMAGE_MIMETYPES,
  VIDEO_MIMETYPES,
} from '../constants/mimetypes';

export const getFileTypeFromMimetype = (mimetype: string): FileType => {
  if (AUDIO_MIMETYPES.includes(mimetype)) {
    return AUDIO;
  }
  if (IMAGE_MIMETYPES.includes(mimetype)) {
    return IMAGE;
  }
  if (VIDEO_MIMETYPES.includes(mimetype)) {
    return VIDEO;
  }
  if (DOCUMENT_MIMETYPES.includes(mimetype)) {
    return DOCUMENT;
  }

  return TEXT;
};

export const downloadCsvStringAsFile = (
  csvString: string,
  fileName: string,
): void => {
  var csvData = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  var csvURL = null;
  if (navigator.msSaveBlob) {
    csvURL = navigator.msSaveBlob(csvData, `${fileName}.csv`);
  } else {
    csvURL = window.URL.createObjectURL(csvData);
  }
  var tempLink = document.createElement('a');
  tempLink.href = csvURL as string;
  tempLink.setAttribute('download', `${fileName}.csv`);
  tempLink.click();
};

export const downloadFileFromUrl = async (
  fileUrl: string,
  fileName: string,
): Promise<void> => {
  fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    })
    .catch(console.error);
};
