import React, { memo } from 'react';
import { ONE_CLICK } from '../../../constants/actionButtons';
import ActionButton from './ActionButton';

const CollectionActionButtons = memo(
  ({
    actionButtons,
    buttonType,
    dataType,
    editorMode,
    project,
    scope,
    viewRootPathname,
  }: any) =>
    actionButtons.map((collectionActionButton: any, actionButtonIndex: any) => (
      <ActionButton
        actionButton={collectionActionButton}
        buttonType={buttonType}
        dataType={dataType}
        defaultExecution={ONE_CLICK}
        editorMode={editorMode}
        index={actionButtonIndex}
        key={collectionActionButton.id}
        rootPathname={viewRootPathname}
        project={project}
        recordScope={scope}
      />
    )),
);

export default CollectionActionButtons;
