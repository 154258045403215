import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TextInput as TailwindTextInput,
  getTailwindClassNames,
  withTheme,
} from '@darraghmckay/tailwind-react-ui';
import { IconEye, IconEyeOff, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Surface, Theme } from '../../models';
import { AdvancedSpacing, Spacing } from '../../models/spacing';
import { validationBorder } from '../../utils';
import ErrorText from '../form/ErrorText';
import { InputStyle, ROUNDED_LARGE } from './inputStyles';
import themeStyles from './inputTheme';

export const getInputStyles = ({
  bg,
  theme,
  m,
  disabled,
  surface,
  style,
}: any) => {
  const { surface: themeSurface = 'default' } = theme.textInput || {};
  const surfaceValue = surface || themeSurface;
  const bgValue = bg || theme.surfaceColors[surfaceValue];
  const borderColorValue = theme.borderColors[surfaceValue];
  const textColor = theme.textColors.on[surfaceValue] || theme.textColors.body;
  const disabledBg = surfaceValue === 'light' ? 'gray-100' : bgValue;

  return {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ...themeStyles(borderColorValue)[style],
    bg: disabled ? disabledBg : bgValue,
    m: { b: 0, ...m },
    text: textColor,
  };
};

export type TextInputProps = {
  bg?: string;
  name?: string;
  className?: string;
  debounceMs?: number;
  disabled?: boolean;
  icon?: JSX.Element | undefined;
  inline?: boolean;
  onBlur?: () => void;
  onChange?: (...args: any[]) => any;
  onFocus?: () => void;
  m?: Spacing;
  readOnly?: boolean;
  style?: InputStyle;
  styleObj?: any;
  valid?: boolean;
  clearable?: boolean;
  p?: AdvancedSpacing;
  surface?: Surface;
  type?: 'text' | 'password';
  theme: Theme;
  validationError?: any;
  value: string | null | undefined;
};

export const TextInput = forwardRef<any, TextInputProps>(
  (
    {
      bg,
      className,
      debounceMs,
      disabled,
      icon,
      inline,
      m,
      validationError,
      value,
      valid,
      onChange,
      style,
      styleObj,
      surface,
      type,
      clearable,
      readOnly,
      theme,
      p,
      ...rest
    },
    ref,
  ) => {
    const isPassword = useMemo(() => type === 'password', [type]);
    const [localValue, setLocalValue] = useState(value);
    const [reveal, setReveal] = useState(!isPassword);
    useEffect(() => {
      setLocalValue(value);
    }, [value]);

    const onInputChange = useMemo(
      () =>
        debounceMs && debounceMs > 0
          ? // @ts-expect-error TS(2345): Argument of type '((...args: any[]) => any) | unde... Remove this comment to see the full error message
            debounce(onChange, debounceMs)
          : onChange,
      [debounceMs, onChange],
    );

    const handleOnChange = useCallback(
      (event: any) => {
        // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onInputChange(event);
        setLocalValue(event.target.value);
      },
      [onInputChange],
    );

    const handleRevealChange = useCallback(
      (event: any) => {
        event.stopPropagation();
        setReveal(!reveal);
      },
      [reveal],
    );

    const handleClear = useCallback(() => {
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onInputChange({ target: { value: '' } });
      setLocalValue('');
    }, [onInputChange]);

    const typeMaybeRevealed = useMemo(
      () => (isPassword ? (reveal ? '' : type) : type),
      [isPassword, reveal, type],
    );

    const styleProps = useMemo(
      () =>
        getInputStyles({
          bg,
          theme,
          m,
          disabled: disabled,
          surface,
          style,
        }),
      [bg, disabled, m, style, surface, theme],
    );
    const padding = useMemo(() => {
      const defaulted = p ?? { x: theme.spacing.sm, y: theme.spacing.sm };

      if (icon) {
        return {
          ...defaulted,
          x: undefined,
          l: defaulted.l || Math.ceil((defaulted.x ?? 0) + 6),
          r: defaulted.l || defaulted.x,
        };
      }

      return clearable || isPassword
        ? {
            ...defaulted,
            x: undefined,
            l: defaulted.l || defaulted.x,
            r: defaulted.r || Math.ceil((defaulted.x ?? 0) + 5),
          }
        : defaulted;
    }, [clearable, icon, isPassword, p, theme.spacing.sm]);

    const buttonIcon = useMemo(() => {
      if (clearable) {
        return <IconX size={18} />;
      }

      return reveal ? <IconEye size={18} /> : <IconEyeOff size={18} />;
    }, [clearable, reveal]);

    return (
      <>
        <div
          className={classNames('flex w-full', {
            relative: clearable || isPassword || icon,
          })}
        >
          {icon && (
            <span className="absolute flex items-center h-full left-2 top-auto z-30 opacity-50">
              {icon}
            </span>
          )}
          <TailwindTextInput
            className={classNames(
              className,
              'sm:text-base rounded-lg h-8 sm:h-10',
              { 'focus:ring-2': !inline },
              validationBorder(validationError || !valid, theme),
            )}
            disabled={disabled}
            onChange={handleOnChange}
            {...styleProps}
            readOnly={readOnly}
            type={typeMaybeRevealed}
            style={styleObj}
            text={[styleProps.text, 'sm']}
            p={padding}
            {...rest}
            opacity={100}
            value={localValue || ''}
            ref={ref}
          />
          {(clearable || isPassword) && (
            <button
              type="button"
              className={classNames(
                getTailwindClassNames({
                  p: {
                    r: p?.r ?? p?.x ?? theme.spacing.sm,
                  },
                  text: styleProps.text,
                }),
                'h-full absolute right-0 top-auto z-50 text-opacity-25 focus:text-opacity-75 hover:text-opacity-75',
              )}
              onClick={clearable ? handleClear : handleRevealChange}
              tabIndex={-1}
            >
              {buttonIcon}
            </button>
          )}
        </div>
        {validationError && (
          <ErrorText data-testid={`${(rest as any).id}-error`}>
            {validationError}
          </ErrorText>
        )}
      </>
    );
  },
);

TextInput.defaultProps = {
  className: '',
  style: ROUNDED_LARGE,
  readOnly: false,
  onChange: () => null,
  // @ts-expect-error TS(2322): Type '{ className: string; style: string; readOnly... Remove this comment to see the full error message
  placeholder: 'Placeholder',
  valid: true,
  clearable: false,
};

export default withTheme(TextInput);
