import React from 'react';
import { QUICK_LINKS } from '../../../constants/elements';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import QuickLinks from '../QuickLinks';

const RecordQuickLinks = ({
  elementPath,
  editorMode,
  project,
  recordScope,
  sectionWidth,
  ...rest
}: any) => {
  const { dense, links } = useSectionScopeVariables(
    QUICK_LINKS,
    rest,
    project,
    elementPath,
    recordScope,
  );

  return (
    <QuickLinks
      dense={dense}
      links={links}
      project={project}
      recordScope={recordScope}
      editorMode={editorMode}
      className="shadow rounded-lg"
      sectionWidth={sectionWidth}
    />
  );
};

RecordQuickLinks.defaultProps = {};

export default RecordQuickLinks;
