import React from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getColorShade } from '@noloco/components';

const CalendarDayColumnWrapper = ({ className, children, theme }: any) => {
  const primaryColor = theme.brandColors.primary;

  return (
    <div
      className={classNames(
        className,
        'text-sm text-gray-300 w-full relative flex flex-col min-h-full',
        {
          [`bg-${getColorShade(primaryColor, 100)}`]:
            className &&
            (className.includes('rbc-now') || className.includes('rbc-today')),
        },
      )}
    >
      {children}
    </div>
  );
};

export default withTheme(CalendarDayColumnWrapper);
