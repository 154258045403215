import React, { useCallback } from 'react';
import { UPDATE } from '../../../constants/actionTypes';
import useActionButtonFormFields from '../../../utils/hooks/useActionButtonFormFields';
import { useFormSections } from '../../../utils/hooks/useFormSections';
import { getText } from '../../../utils/lang';
import FormSection from '../FormSection';
import { COMPACT } from '../forms/AutoFormSection';

const ActionButtonForm = ({
  action,
  dataType,
  onLoadingChange,
  onSuccess,
  project,
  record,
  scope,
}: any) => {
  const { id, type = UPDATE, field, formFields = [] } = action;

  const {
    actionDataType,
    fields,
    mutationType,
    recordId,
  } = useActionButtonFormFields(
    dataType,
    field,
    formFields,
    project,
    record,
    type,
  );
  const sections = useFormSections(formFields);

  const transformRecordScope = useCallback(
    (formScope) => ({
      ...scope,
      ...formScope,
    }),
    [scope],
  );

  if (!actionDataType) {
    return null;
  }

  return (
    actionDataType && (
      <FormSection
        className={`action-${id}-form`}
        dataType={actionDataType.name}
        fields={fields}
        sectionFormat={COMPACT}
        sections={sections}
        type={mutationType}
        project={project}
        onLoadingChange={onLoadingChange}
        onSuccess={onSuccess}
        showSuccess={false}
        successMessage={{
          message: '',
        }}
        errorMessage={{
          message: getText('core.COLLECTION.form.invalid'),
        }}
        which={recordId}
        transformRecordScope={transformRecordScope}
      />
    )
  );
};

export default ActionButtonForm;
