import React, { forwardRef, useCallback, useMemo } from 'react';
import { ErrorBoundary } from '@sentry/react';
import {
  IconAlertTriangle,
  IconArrowAutofitWidth,
  IconArrowDown,
  IconArrowUp,
  IconBackspace,
  IconCopy,
  IconFolders,
  IconGripVertical,
  IconTrash,
} from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import initial from 'lodash/initial';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Tooltip } from '@noloco/components';
import { LG, MD, XL } from '@noloco/components/src/constants/tShirtSizes';
import {
  useCloneElement,
  useRemoveSelected,
  useSwapElementAtIndex,
  useUpdateElements,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import { WithDraggable } from '../../../components/withDnD';
import { darkModeColors } from '../../../constants/darkModeColors';
import { SECTION as DRAGGABLE_SECTION } from '../../../constants/draggableItemTypes';
import {
  COLLECTION,
  DETAILS,
  DIVIDER,
  FILE_GALLERY,
  HIGHLIGHTS,
  IFRAME,
  MARKDOWN,
  QUICK_LINKS,
  SECTION,
  STAGES,
  TITLE,
  VIDEO,
} from '../../../constants/elements';
import {
  setSelectedElement,
  setSelectedSectionPath,
} from '../../../reducers/elements';
import {
  hasSelectedElementPathSelector,
  highlightedSectionSelector,
  selectedSectionSelector,
} from '../../../selectors/elementsSelectors';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useOnKeyPress from '../../../utils/hooks/useOnKeyPress';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import isTargetAnInput from '../../../utils/isTargetAnInput';
import { getText } from '../../../utils/lang';
import { RECORD_SCOPE, transformColumnarScope } from '../../../utils/scope';
import RecordFileGallery from './FileGallery';
import KeyboardShortcutTooltip from './KeyboardShortcutTooltip';
import RecordCollection from './RecordCollection';
import RecordDetails from './RecordDetails';
import RecordDivider from './RecordDivider';
import RecordHighlights from './RecordHighlights';
import RecordIframe from './RecordIframe';
import RecordMarkdown from './RecordMarkdown';
import RecordQuickLinks from './RecordQuickLinks';
import RecordStages from './RecordStages';
import RecordTitle from './RecordTitle';
import RecordVideo from './RecordVideo';

const LANG_KEY = 'core.sections.editor';

const DRAG = 'drag';
const CHANGE_TABS = 'changeTabs';
const CLONE = 'clone';
const MOVE_DOWN = 'moveDown';
const MOVE_UP = 'moveUp';
const REMOVE = 'remove';
const WIDTH = 'width';

const TOOLTIP = 'tooltip';

const sections = {
  [COLLECTION]: RecordCollection,
  [DETAILS]: RecordDetails,
  [HIGHLIGHTS]: RecordHighlights,
  [IFRAME]: RecordIframe,
  [TITLE]: RecordTitle,
  [STAGES]: RecordStages,
  [VIDEO]: RecordVideo,
  [FILE_GALLERY]: RecordFileGallery,
  [QUICK_LINKS]: RecordQuickLinks,
  [MARKDOWN]: RecordMarkdown,
  [DIVIDER]: RecordDivider,
};

const ARROW_UP = 'ArrowUp';
const ARROW_DOWN = 'ArrowDown';
const PERIOD = '.';
const COMMA = ',';
const KEY_D = 'd';
const BACKSPACE = 'Backspace';
const DEFAULT_TAB_ID = 'DEFAULT_TAB';

const Blank = ({ children }: any) => <div>{children}</div>;

const ViewSection = forwardRef(
  (
    {
      // @ts-expect-error TS(2339): Property 'backLink' does not exist on type '{}'.
      backLink,
      // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
      className,
      // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
      dataType,
      // @ts-expect-error TS(2339): Property 'record' does not exist on type '{}'.
      record,
      // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type '{}'.
      editorMode,
      // @ts-expect-error TS(2339): Property 'draggable' does not exist on type '{}'.
      draggable,
      // @ts-expect-error TS(2339): Property 'isOver' does not exist on type '{}'.
      isOver,
      // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{}'... Remove this comment to see the full error message
      elementPath,
      // @ts-expect-error TS(2339): Property 'isRecordView' does not exist on type '{}... Remove this comment to see the full error message
      isRecordView,
      // @ts-expect-error TS(2339): Property 'isEditingData' does not exist on type '{... Remove this comment to see the full error message
      isEditingData,
      // @ts-expect-error TS(2339): Property 'onError' does not exist on type ... Remove this comment to see the full error message
      onError,
      // @ts-expect-error TS(2339): Property 'onLoadingChange' does not exist on type ... Remove this comment to see the full error message
      onLoadingChange,
      // @ts-expect-error TS(2339): Property 'rootPathname' does not exist on type '{}... Remove this comment to see the full error message
      rootPathname,
      // @ts-expect-error TS(2339): Property 'section' does not exist on type '{}'.
      section,
      // @ts-expect-error TS(2339): Property 'pageId' does not exist on type '{}'.
      pageId,
      // @ts-expect-error TS(2339): Property 'path' does not exist on type '{}'.
      path,
      // @ts-expect-error TS(2339): Property 'index' does not exist on type '{}'.
      index,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
      project,
      // @ts-expect-error TS(2339): Property 'tabs' does not exist on type '{}'.
      tabs,
      // @ts-expect-error TS(2339): Property 'selectedTab' does not exist on type '{}'... Remove this comment to see the full error message
      selectedTab,
      // @ts-expect-error TS(2339): Property 'visibleSections' does not exist on type ... Remove this comment to see the full error message
      visibleSections,
      // @ts-expect-error TS(2339): Property 'dragRef' does not exist on type '{}'.
      dragRef,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const [isDarkModeEnabled] = useDarkMode();
    const highlightedSectionPath = useSelector(highlightedSectionSelector);

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const Section = useMemo(() => sections[section.type] || Blank, [
      section.type,
    ]);

    const sectionPath = useMemo(
      () =>
        isRecordView
          ? [...elementPath, 'props', 'record', 'sections', ...path]
          : [...elementPath, 'props', 'sections', ...path],
      [isRecordView, elementPath, path],
    );

    const useTabs = tabs && tabs.length > 1;

    const [updateElements] = useUpdateElements(project);

    const sectionIndexes = useMemo(() => {
      const index = visibleSections.findIndex(
        (s: any) => s.section.id === section.id,
      );
      const previous = index > 0 && get(visibleSections, [index - 1, 'index']);
      const next =
        index < visibleSections.length - 1 &&
        get(visibleSections, [index + 1, 'index']);

      return {
        index,
        previous,
        next,
      };
    }, [visibleSections, section.id]);

    const [cloneElement] = useCloneElement(project, sectionPath);
    const handleClone = useCallback(
      (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        cloneElement(elementPath);
      },
      [cloneElement, elementPath],
    );

    const tabOptions = useMemo(
      () =>
        tabs &&
        // @ts-expect-error TS(7006): Parameter 'tab' implicitly has an 'any' type.
        tabs.map((tab, idx) => ({
          value: idx === 0 ? null : tab.id,
          label: tab.title,
        })),
      [tabs],
    );

    const widthOptions = useMemo(
      () => [
        {
          label: getText(LANG_KEY, WIDTH, 'options.default'),
          value: undefined,
        },
        ...[3, 6, 9, LG, XL].map((value) => ({
          label: getText(LANG_KEY, WIDTH, 'options', value),
          value,
        })),
      ],
      [],
    );

    const onChangeTab = useCallback(
      (newTab: any) => updateElements([...sectionPath, 'tab'], newTab),
      [sectionPath, updateElements],
    );

    const onChangeWidth = useCallback(
      (newWidth: any) =>
        updateElements([...sectionPath, 'sectionWidth'], newWidth),
      [sectionPath, updateElements],
    );

    const recordScope = useMemo(() => {
      const recordWithColumnar = transformColumnarScope(
        record,
        dataType,
        project.dataTypes,
      );
      return {
        [RECORD_SCOPE]: recordWithColumnar,
        [pageId]: recordWithColumnar,
      };
    }, [dataType, pageId, project.dataTypes, record]);

    const sectionProps = useSectionScopeVariables(
      SECTION,
      section.props,
      project,
      sectionPath,
      recordScope,
      skipPropResolvingByValueIds([`${section.id}:RECORD`]),
    );

    const [onMoveElementUp, canMoveUp] = useSwapElementAtIndex(
      project,
      sectionPath,
      sectionIndexes.previous,
    );
    const [onMoveElementDown, canMoveDown] = useSwapElementAtIndex(
      project,
      sectionPath,
      sectionIndexes.next,
    );
    const [removeSelectedElement] = useRemoveSelected(project, sectionPath);

    const handleMoveUp = useCallback(
      (e: any) => {
        e.stopPropagation();
        if (sectionIndexes.previous !== false) {
          // @ts-expect-error TS2349: This expression is not callable. Not all constituents of type 'boolean | (() => any)' are callable. Type 'false' has no call signatures.
          onMoveElementUp();
          dispatch(
            setSelectedSectionPath([...initial(path), sectionIndexes.previous]),
          );
          dispatch(setSelectedElement(elementPath));
        }
      },
      [dispatch, elementPath, onMoveElementUp, path, sectionIndexes.previous],
    );

    const handleMoveDown = useCallback(
      (e: any) => {
        e.stopPropagation();
        if (sectionIndexes.next !== false) {
          // @ts-expect-error TS2349: This expression is not callable. Not all constituents of type 'boolean | (() => any)' are callable. Type 'false' has no call signatures.
          onMoveElementDown();
          dispatch(
            setSelectedSectionPath([...initial(path), sectionIndexes.next]),
          );
          dispatch(setSelectedElement(elementPath));
        }
      },
      [dispatch, elementPath, onMoveElementDown, path, sectionIndexes.next],
    );

    const handleRemove = useCallback(
      (e: any) => {
        e.stopPropagation();
        if (!isTargetAnInput(e.target)) {
          removeSelectedElement();
          dispatch(setSelectedSectionPath([]));
        }
      },
      [dispatch, removeSelectedElement],
    );

    const handleMoveToRightTab = useCallback(() => {
      const currentTabIndex = selectedTab
        ? tabs.findIndex((tab: any) => tab.id === selectedTab.id)
        : 0;
      const newTabIndex = currentTabIndex + 1;
      if (newTabIndex < tabs.length) {
        onChangeTab(get(tabs, [newTabIndex, 'id'], null));
        dispatch(setSelectedSectionPath([]));
      }
    }, [selectedTab, tabs, onChangeTab, dispatch]);

    const handleMoveToLeftTab = useCallback(() => {
      const currentTabIndex = selectedTab
        ? tabs.findIndex((tab: any) => tab.id === selectedTab.id)
        : 0;
      const newTabIndex = currentTabIndex - 1;
      if (newTabIndex >= 0) {
        const newTabId = get(tabs, [newTabIndex, 'id']);
        onChangeTab(newTabId === DEFAULT_TAB_ID ? null : newTabId);
        dispatch(setSelectedSectionPath([]));
      }
    }, [selectedTab, tabs, onChangeTab, dispatch]);

    const hasSelectedElement = useSelector(hasSelectedElementPathSelector);

    const selectedSectionPath = useSelector(selectedSectionSelector);

    const onSelectSection = useCallback(() => {
      dispatch(setSelectedSectionPath(path));
      if (!isRecordView) {
        dispatch(setSelectedElement(elementPath));
      }
    }, [dispatch, path, elementPath, isRecordView]);

    const isSelected = useMemo(
      () =>
        hasSelectedElement && selectedSectionPath.join('.') === path.join('.'),
      [path, selectedSectionPath, hasSelectedElement],
    );

    const isHighlighted = useMemo(
      () =>
        highlightedSectionPath &&
        highlightedSectionPath.join('.') === path.join('.'),
      [highlightedSectionPath, path],
    );

    const fullScreen = useMemo(() => get(sectionProps, 'fullScreen', false), [
      sectionProps,
    ]);

    const isShortcutEnabled = useMemo(
      () =>
        editorMode &&
        hasSelectedElement &&
        first(selectedSectionPath) === index,
      [editorMode, hasSelectedElement, selectedSectionPath, index],
    );

    useOnKeyPress(ARROW_UP, handleMoveUp, {
      ctrlKey: true,
      shiftKey: true,
      enabled: isShortcutEnabled,
    });

    useOnKeyPress(ARROW_DOWN, handleMoveDown, {
      ctrlKey: true,
      shiftKey: true,
      enabled: isShortcutEnabled,
    });

    useOnKeyPress(PERIOD, handleMoveToRightTab, {
      ctrlKey: true,
      shiftKey: true,
      enabled: isShortcutEnabled && isRecordView,
    });

    useOnKeyPress(COMMA, handleMoveToLeftTab, {
      ctrlKey: true,
      shiftKey: true,
      enabled: isShortcutEnabled && isRecordView,
    });

    useOnKeyPress(KEY_D, handleClone, {
      ctrlKey: true,
      shiftKey: false,
      enabled: isShortcutEnabled,
    });

    useOnKeyPress(BACKSPACE, handleRemove, {
      ctrlKey: true,
      shiftKey: false,
      enabled: isShortcutEnabled,
    });

    return (
      <div
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ref}
        id={section.id}
        className={classNames(
          className,
          'w-full relative',
          {
            'px-4 sm:px-1 py-2': !fullScreen,
            'h-screen overflow-hidden': fullScreen,
            'hover:ring-2 hover:ring-opacity-50 hover:ring-pink-400 rounded-lg':
              editorMode && !isSelected && !fullScreen && !isOver,
            'ring-2 ring-opacity-100 ring-pink-400 rounded-lg':
              editorMode && isSelected && !fullScreen,
            'ring-2 ring-opacity-50 ring-pink-400 rounded-lg':
              isHighlighted && !isSelected,
            'max-w-6xl mx-auto': !section.sectionWidth && !fullScreen,
            'max-w-screen-2xl mx-auto': section.sectionWidth === LG,
            'max-w-full mx-auto': section.sectionWidth === XL,
            'max-w-1/2 md:max-w-full': section.sectionWidth === 6,
            'max-w-1/4 md:max-w-full': section.sectionWidth === 3,
            'max-w-3/4 md:max-w-full': section.sectionWidth === 9,
          },
          section && section.type
            ? `section-${section.type.toLowerCase()}`
            : '',
        )}
        data-testid="record-view-section"
        onClick={editorMode ? onSelectSection : undefined}
      >
        {/* @ts-expect-error TS2786: 'ErrorBoundary' cannot be used as a JSX component. */}
        <ErrorBoundary
          fallback={
            editorMode && (
              <>
                <div
                  className={classNames(
                    'border rounded-lg shadow-md flex items-center justify-center p-6 text-sm',
                    {
                      [`${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`]: isDarkModeEnabled,
                      'bg-white border-gray-200': !isDarkModeEnabled,
                    },
                  )}
                >
                  <IconAlertTriangle className="flex-shrink-0" size={24} />
                  <div className="text-left text-gray-500 dark:text-gray-400 py-16 ml-4 flex flex-col">
                    <h3>{getText('errors.section.title')}</h3>
                    <p className="text-xs text-gray-400 dark:text-gray-500">
                      {getText('errors.section.subtitle')}
                    </p>
                  </div>
                </div>
                {isSelected && !fullScreen && (
                  <div className="absolute top-0 left-0 -mt-4 h-8 ml-4 px-1 space-x-1 flex items-center rounded-lg bg-pink-400 text-white">
                    <Tooltip
                      content={
                        <KeyboardShortcutTooltip
                          text={getText(LANG_KEY, REMOVE, TOOLTIP)}
                          shortcut={<IconBackspace size={14} />}
                        />
                      }
                      key={REMOVE}
                      placement="top"
                      bg={
                        isDarkModeEnabled
                          ? darkModeColors.surfaces.elevation1LiteralColor
                          : 'white'
                      }
                    >
                      <button
                        className="p-1 opacity-75 hover:opacity-100"
                        onClick={handleRemove}
                      >
                        <IconTrash size={14} />
                      </button>
                    </Tooltip>
                  </div>
                )}
              </>
            )
          }
        >
          {draggable && isOver && (
            <div className="my-2 w-full h-1.5 rounded-full bg-pink-400" />
          )}
          <Section
            sectionId={section.id}
            {...sectionProps}
            backLink={backLink}
            dataType={dataType}
            isSelected={isSelected}
            editorMode={editorMode}
            elementPath={elementPath}
            isEditingData={isEditingData}
            rootPathname={rootPathname}
            isRecordView={isRecordView}
            onLoadingChange={onLoadingChange}
            onError={onError}
            sectionPath={path}
            record={record}
            recordScope={recordScope}
            project={project}
            sectionWidth={section.sectionWidth}
          >
            {section.type}
          </Section>
          {editorMode && !isSelected && (
            <div className="absolute inset-y-0 inset-x-0 rounded-lg overflow-hidden" />
          )}
          {editorMode && isSelected && !fullScreen && (
            <div className="absolute top-0 left-0 -mt-4 h-8 ml-4 px-1 space-x-1 flex items-center rounded-lg bg-pink-400 text-white">
              <div ref={dragRef}>
                <Tooltip
                  content={
                    <span
                      className={classNames({
                        [darkModeColors.text.primary]: isDarkModeEnabled,
                      })}
                    >
                      {getText(LANG_KEY, DRAG, TOOLTIP)}
                    </span>
                  }
                  key={DRAG}
                  placement="top"
                  bg={
                    isDarkModeEnabled
                      ? darkModeColors.surfaces.elevation1LiteralColor
                      : 'white'
                  }
                >
                  <button
                    className="p-1 opacity-75 disabled:opacity-50 cursor-move"
                    draggable={true}
                  >
                    <IconGripVertical size={14} />
                  </button>
                </Tooltip>
              </div>
              <Tooltip
                content={
                  <KeyboardShortcutTooltip
                    text={getText(LANG_KEY, MOVE_DOWN, TOOLTIP)}
                    shortcut={<IconArrowDown size={14} />}
                  />
                }
                key={MOVE_DOWN}
                placement="top"
                bg={
                  isDarkModeEnabled
                    ? darkModeColors.surfaces.elevation1LiteralColor
                    : 'white'
                }
              >
                <button
                  className={classNames('p-1 opacity-75 disabled:opacity-50', {
                    'hover:opacity-100': canMoveDown,
                  })}
                  disabled={
                    !canMoveDown ||
                    sectionIndexes.index > visibleSections.length - 1
                  }
                  onClick={handleMoveDown}
                >
                  <IconArrowDown size={14} />
                </button>
              </Tooltip>
              <Tooltip
                content={
                  <KeyboardShortcutTooltip
                    text={getText(LANG_KEY, MOVE_UP, TOOLTIP)}
                    shortcut={<IconArrowUp size={14} />}
                  />
                }
                key={MOVE_UP}
                placement="top"
                bg={
                  isDarkModeEnabled
                    ? darkModeColors.surfaces.elevation1LiteralColor
                    : 'white'
                }
              >
                <button
                  className={classNames('p-1 opacity-75 disabled:opacity-50', {
                    'hover:opacity-100': canMoveUp,
                  })}
                  disabled={!canMoveUp || sectionIndexes.index === 0}
                  onClick={handleMoveUp}
                >
                  <IconArrowUp size={14} />
                </button>
              </Tooltip>
              <Dropdown
                // @ts-expect-error TS(7031): Binding element 'child' implicitly has an 'any' ty... Remove this comment to see the full error message
                Button={({ children: child }) => child}
                className="flex items-center justify-center pacity-75 hover:opacity-100"
                onChange={onChangeWidth}
                options={widthOptions}
                size={MD}
                value={section.sectionWidth}
                stopPropagation={true}
              >
                <Tooltip
                  content={
                    <span
                      className={
                        isDarkModeEnabled ? darkModeColors.text.primary : ''
                      }
                    >
                      {getText(LANG_KEY, WIDTH, TOOLTIP)}
                    </span>
                  }
                  key={WIDTH}
                  placement="top"
                  bg={
                    isDarkModeEnabled
                      ? darkModeColors.surfaces.elevation1LiteralColor
                      : 'white'
                  }
                >
                  <div>
                    <IconArrowAutofitWidth size={14} />
                  </div>
                </Tooltip>
              </Dropdown>
              <Tooltip
                content={
                  <KeyboardShortcutTooltip
                    text={getText(LANG_KEY, CLONE, TOOLTIP)}
                    shortcut={'D'}
                  />
                }
                key={CLONE}
                placement="top"
                bg={
                  isDarkModeEnabled
                    ? darkModeColors.surfaces.elevation1LiteralColor
                    : 'white'
                }
              >
                <button
                  className="p-1 opacity-75 disabled:opacity-50 hover:opacity-100"
                  onClick={handleClone}
                >
                  <IconCopy size={14} />
                </button>
              </Tooltip>
              {isRecordView && (
                <Dropdown
                  // @ts-expect-error TS(7031): Binding element 'child' implicitly has an 'any' ty... Remove this comment to see the full error message
                  Button={({ children: child }) => child}
                  className={classNames('flex items-center justify-center', {
                    'opacity-75 hover:opacity-100': useTabs,
                    'opacity-50': !useTabs,
                  })}
                  onChange={onChangeTab}
                  disabled={!useTabs}
                  options={tabOptions}
                  size={LG}
                  stopPropagation={true}
                >
                  <Tooltip
                    content={
                      <KeyboardShortcutTooltip
                        text={getText(LANG_KEY, CHANGE_TABS, TOOLTIP)}
                        shortcut="< or >"
                      />
                    }
                    key={CHANGE_TABS}
                    placement="top"
                    bg={
                      isDarkModeEnabled
                        ? darkModeColors.surfaces.elevation1LiteralColor
                        : 'white'
                    }
                  >
                    <div>
                      <IconFolders size={14} />
                    </div>
                  </Tooltip>
                </Dropdown>
              )}
              <Tooltip
                content={
                  <KeyboardShortcutTooltip
                    text={getText(LANG_KEY, REMOVE, TOOLTIP)}
                    shortcut={<IconBackspace size={14} />}
                  />
                }
                key={REMOVE}
                placement="top"
                bg={
                  isDarkModeEnabled
                    ? darkModeColors.surfaces.elevation1LiteralColor
                    : 'white'
                }
              >
                <button
                  className="p-1 opacity-75 hover:opacity-100"
                  onClick={handleRemove}
                >
                  <IconTrash size={14} />
                </button>
              </Tooltip>
            </div>
          )}
        </ErrorBoundary>
      </div>
    );
  },
);

export default WithDraggable(ViewSection, DRAGGABLE_SECTION);
