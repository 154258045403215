import * as Icons from '@tabler/icons-react';
import ComboType from '../ComboPropType';
import EnumType from '../EnumPropType';

class IconPropType extends ComboType {
  constructor(propMapper?: ((props: any) => any) | undefined) {
    super(
      {
        name: new EnumType(
          Object.keys(Icons).map((icon) => icon.replace('Icon', '')),
        ),
      },
      undefined,
      propMapper,
    );
  }
}

export default IconPropType;
