import { useMemo } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import last from 'lodash/last';
import { useSelector } from 'react-redux';
import { BLANK_QUERY_STRING } from '../../queries/project';
import { scopeSelector } from '../../selectors/dataSelectors';
import useCacheQuery from './useCacheQuery';
import useDataListQueryObject from './useDataListQueryObject';
import { useInvalidateProjectData } from './useInvalidateProjectData';
import useSetQuery from './useSetQuery';

const mockData = [...Array(20)].map((__, i) => ({
  node: { id: i },
}));

const mockDataNodes = mockData.map((edge) => edge.node);

const useCollectionQuery = (
  dataType: any,
  project: any,
  element: any,
  elementPath: any,
  {
    additionalDeps,
    after,
    before,
    autoRefresh,
    countOnly,
    customFilters,
    filter,
    limit,
    orderBy,
  }: any = {},
) => {
  const scope = useSelector(scopeSelector);
  const {
    // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
    dataType: listDataType,
    // @ts-expect-error TS(2339): Property 'error' does not exist on type '{}'.
    error,
    // @ts-expect-error TS(2339): Property 'query' does not exist on type '{}'.
    query,
    // @ts-expect-error TS(2339): Property 'queryObject' does not exist on type '{}'... Remove this comment to see the full error message
    queryObject,
    // @ts-expect-error TS(2339): Property 'nodeQueryObject' does not exist on type ... Remove this comment to see the full error message
    nodeQueryObject,
    // @ts-expect-error TS(2339): Property 'variables' does not exist on type '{}'.
    variables,
    // @ts-expect-error TS(2339): Property 'valuePath' does not exist on type '{}'.
    valuePath,
    // @ts-expect-error TS(2339): Property 'skip' does not exist on type '{}'.
    skip,
  } = useDataListQueryObject(dataType, project, elementPath, {
    additionalDeps,
    after,
    before,
    filter,
    orderBy,
    limit,
    customFilters,
    countOnly,
  });

  const gqlQueryString = useMemo(
    () =>
      gql`
        ${query ? query : BLANK_QUERY_STRING}
      `,
    [query],
  );

  const id = `${element.id}${countOnly ? ':COUNT' : ''}`;

  const queryRef = useMemo(
    () => ({
      id,
      variables,
      dataType,
      query,
      queryObject,
      nodeQueryObject,
      type: element.type,
      valuePath: `${valuePath}.`,
    }),
    [
      dataType,
      element.type,
      id,
      nodeQueryObject,
      query,
      queryObject,
      valuePath,
      variables,
    ],
  );

  useSetQuery(queryRef, !!countOnly);

  const parentValuePath = useMemo(
    () =>
      filter && filter.path
        ? last(filter.path.split('.'))
        : [`${dataType}Collection`],
    [dataType, filter],
  );

  const { loading, error: dataError, data, refetch } = useCacheQuery(
    gqlQueryString,
    {
      variables,
      context: { projectQuery: true, projectName: project.name },
      errorPolicy: 'all',
      skip: !dataType || skip,
    },
  );
  useInvalidateProjectData(autoRefresh ? refetch : () => {});

  const connection = useMemo(() => {
    const splitValuePath = (valuePath || '').split('.');
    return loading
      ? {
          edges: [{ id: 'loading' }],
        }
      : get(data, splitValuePath) ||
          get(scope, splitValuePath) ||
          get(data, splitValuePath.slice(1));
  }, [data, loading, scope, valuePath]);

  const {
    edges = [],
    pageInfo = {},
    totalCount,
    ...restConnectionProps
  } = useMemo(() => connection || {}, [connection]);

  const nodes = useMemo(() => edges.map((edge: any) => edge.node), [edges]);

  if (!dataType) {
    return {
      loading: false,
      limit,
      edges: [...mockData].slice(0, limit ? Math.min(limit, 20) : 6),
      totalCount: mockData.length,
      pageInfo: { hasNextPage: false },
      parentValuePath,
      nodeQueryObject,
      nodes: [...mockDataNodes].slice(0, limit ? Math.min(limit, 20) : 6),
      skip: false,
    };
  }

  if (skip || error || (dataError && !data)) {
    return {
      error: error || dataError,
      edges: [],
      nodes: [],
      parentValuePath,
      nodeQueryObject,
      skip: true,
    };
  }

  return {
    edges,
    listDataType: listDataType,
    rawData: data,
    nodes,
    pageInfo,
    totalCount,
    nodeQueryObject,
    connection: restConnectionProps,
    loading,
    error,
    parentValuePath,
    valuePath,
  };
};

export default useCollectionQuery;
