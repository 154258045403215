import React from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { getColorShade } from '@noloco/components';
import useDarkMode from '../../../../utils/hooks/useDarkMode';

const CalendarDateCellWrapper = ({ children, theme }: any) => {
  const primaryColor = get(theme, 'brandColors.primary', null);
  const className = get(children, 'props.className', null);
  const [isDarkModeEnabled] = useDarkMode();

  return (
    <div
      className={classNames(
        className,
        'flex items-center min-h-full border-l bg-transparent',
        {
          [`bg-${getColorShade(primaryColor, 600)}`]:
            className &&
            className.includes('rbc-off-range') &&
            isDarkModeEnabled,
        },
      )}
    >
      {children}
    </div>
  );
};

export default withTheme(CalendarDateCellWrapper);
