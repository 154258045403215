import React, { forwardRef, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { CREATE } from '@noloco/core/src/constants/actionTypes';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { clearFieldValues } from '../../../reducers/formFields';
import { useBackLink } from '../../../utils/hooks/useBacklink';
import useFormFields from '../../../utils/hooks/useFormFields';
import { useFormSections } from '../../../utils/hooks/useFormSections';
import useTrackAppPage, {
  PageTypes,
} from '../../../utils/hooks/useTrackAppPage';
import FormSection from '../FormSection';
import Title from '../Title';
import { CARDS } from '../forms/AutoFormSection';
import ViewBreadcrumbs from './ViewBreadcrumbs';

const NewForm = forwardRef(
  (
    {
      // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
      className,
      // @ts-expect-error TS(2339): Property 'coverPhoto' does not exist on type '{}'.
      coverPhoto,
      // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
      dataType,
      // @ts-expect-error TS(2339): Property 'fields' does not exist on type '{}'.
      fields,
      // @ts-expect-error TS(2339): Property 'icon' does not exist on type '{}'.
      icon,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type '{}'.
      onClick,
      // @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'.
      name,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
      project,
      // @ts-expect-error TS(2339): Property 'rootPathname' does not exist on type '{}... Remove this comment to see the full error message
      rootPathname,
      // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type '{}'.
      subtitle,
      // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
      title,
      // @ts-expect-error TS(2339): Property 'saveButtonText' does not exist on type '... Remove this comment to see the full error message
      saveButtonText,
      // @ts-expect-error TS(2339): Property 'viewRootPathname' does not exist on type... Remove this comment to see the full error message
      viewRootPathname,
      // @ts-expect-error TS(2339): Property 'showBreadcrumbs' does not exist on type ... Remove this comment to see the full error message
      showBreadcrumbs,
      // @ts-expect-error TS(2339): Property 'successMessage' does not exist on type '... Remove this comment to see the full error message
      successMessage,
      // @ts-expect-error TS(2339): Property 'redirectOnSuccess' does not exist on typ... Remove this comment to see the full error message
      redirectOnSuccess,
      // @ts-expect-error TS(2339): Property 'hideFormOnSuccess' does not exist on typ... Remove this comment to see the full error message
      hideFormOnSuccess,
      // @ts-expect-error TS(2339): Property 'disableFeatureCheck' does not exist on t... Remove this comment to see the full error message
      disableFeatureCheck = false,
      // @ts-expect-error TS(2339): Property 'neverAllowNewRecords' does not exist on ... Remove this comment to see the full error message
      neverAllowNewRecords = false,
    },
    ref,
  ) => {
    const { push } = useRouter();
    const dispatch = useDispatch();
    const backLink = useBackLink(project);

    useTrackAppPage(PageTypes.NEW);

    const formFields = useFormFields(fields, dataType, project);
    const formSections = useFormSections(fields);

    useEffect(() => {
      return () => {
        // Clear all values on unmount
        dispatch(
          clearFieldValues({
            dataTypeName: dataType.name,
            id: 'NEW',
            fieldNames: dataType.fields.map((field: any) => field.name),
            updateTime: Date.now(),
          }),
        );
      };
    }, [dataType.fields, dataType.name, dispatch]);

    const onSuccessRedirect = useCallback(
      ({ uuid }: any) => {
        if (backLink && backLink.to.includes('/view/')) {
          push(backLink.to);
        } else {
          push(`${rootPathname}/view/${uuid}`);
        }
      },
      [backLink, push, rootPathname],
    );

    return (
      <div
        className={classNames(
          'mt-8 sm:mt-4 px-4 w-full mx-auto max-w-xl',
          className,
        )}
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ref}
        onClick={onClick}
        data-testid="new-record-form"
      >
        {showBreadcrumbs && (
          <ViewBreadcrumbs
            backLink={backLink}
            className="flex mb-6 sm:mb-3 sm:text-xs"
            additionalLinks={[
              {
                to: '/new',
                name:
                  title ||
                  getText(
                    { dataType: dataType.display },
                    'core.COLLECTION.form.new',
                  ),
              },
            ]}
            icon={icon}
            name={name}
            rootPathname={viewRootPathname}
          />
        )}
        {(title || subtitle || coverPhoto) && (
          <Title
            subtitle={{
              hidden: !subtitle,
              value: subtitle,
            }}
            title={{
              hidden: !title,
              value: title,
            }}
            coverPhoto={{
              hidden: !coverPhoto,
              value: coverPhoto,
            }}
            className="mb-4 sm:mb-2"
          />
        )}
        <FormSection
          className="max-w-xl"
          dataType={dataType.name}
          fields={formFields}
          sectionFormat={CARDS}
          sections={formSections}
          type={CREATE}
          project={project}
          onSuccess={redirectOnSuccess ? onSuccessRedirect : null}
          submitButton={{
            text: saveButtonText
              ? saveButtonText
              : getText('core.COLLECTION.form.save'),
            icon: { name: 'Check' },
          }}
          successMessage={successMessage}
          errorMessage={{
            message: getText('core.COLLECTION.form.invalid'),
          }}
          hideFormOnSuccess={hideFormOnSuccess}
          disableFeatureCheck={disableFeatureCheck}
          neverAllowNewRecords={neverAllowNewRecords}
        />
      </div>
    );
  },
);

NewForm.displayName = 'NewForm';
export default NewForm;
