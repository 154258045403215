import { useCallback } from 'react';

const MAX_FORM_SUBMISSION_ATTEMPTS = 10;

const useActionsWithFormInputs = () => {
  const submitForm = useCallback((action: any, __: any, attempt = 0) => {
    const form = document.querySelector(`.action-${action.id}-form form`);
    if (form) {
      form.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    } else if (attempt < MAX_FORM_SUBMISSION_ATTEMPTS) {
      setTimeout(() => {
        submitForm(action, undefined, attempt + 1);
      }, 150 * (attempt + 1));
    }
  }, []);

  return {
    submitForm,
  };
};

export default useActionsWithFormInputs;
