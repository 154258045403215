import React, { useCallback, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconChevronDown } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Button, Dropdown, Loader, getColorShade } from '@noloco/components';
import { useHasPermissionToUpdateRelatedRecordCollection } from '../../../utils/hooks/useHasPermissionToUpdateRelatedRecordCollection';
import { useUpdateRelatedRecordCollection } from '../../../utils/hooks/useUpdateRelatedRecordCollection';
import { getText } from '../../../utils/lang';
import { RECORD_SCOPE } from '../../../utils/scope';
import RelationalDataFieldInput from '../collections/filters/RelationalDataFieldInput';

const AddRelatedRecordButton = ({
  config,
  dataList,
  dataType,
  newButtonVisible,
  project,
  rootDataType,
  scope,
  surface,
  theme,
}: any) => {
  const {
    dataTypeField,
    fieldApiArgName,
    isMultiRelationship,
    relationalDataField,
    update,
    updateLoading,
  } = useUpdateRelatedRecordCollection(
    dataList,
    project,
    rootDataType,
    dataType,
  );

  const canUpdateField = useHasPermissionToUpdateRelatedRecordCollection(
    dataType,
    dataTypeField,
  );

  const primaryColor = theme.brandColors.primary;

  const additionalFields = useMemo(
    () => ({
      __args: {
        ...(config.orderBy ? { orderBy: config.orderBy } : {}),
        ...(config.customFilters ? { where: config.customFilters } : {}),
      },
    }),
    [config.customFilters, config.orderBy],
  );

  const onAddRelatedRecord = useCallback(
    (nodeToAdd: any) => {
      const variables = {
        id: nodeToAdd.id,
      };

      if (isMultiRelationship) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        variables[fieldApiArgName] = [scope[RECORD_SCOPE].id];
      } else {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        variables[fieldApiArgName] = scope[RECORD_SCOPE].id;
      }

      return update({ variables });
    },
    [fieldApiArgName, isMultiRelationship, scope, update],
  );

  const DropdownInput = useMemo(
    () => (props: any) => (
      <Dropdown {...props} Button={({ children }: any) => children} />
    ),
    [],
  );

  return (
    <RelationalDataFieldInput
      additionalFields={additionalFields}
      allowNewRecords={false}
      dataTypes={project.dataTypes}
      disabled={!canUpdateField}
      field={relationalDataField}
      filter={config.collectionFilter}
      inline={false}
      onChange={onAddRelatedRecord}
      project={project}
      projectName={project.name}
      resultFilter={({ id }: any) =>
        !get(scope, [RECORD_SCOPE, dataList.filter.path, 'edges'], [])
          .map(({ node }: any) => node.id)
          .includes(id)
      }
      Input={DropdownInput}
      surface={surface}
    >
      <Button
        className={classNames(
          'flex items-center space-x-2 rounded-tr-lg rounded-br-lg h-8 border-l',
          `border-${getColorShade(primaryColor, 600)}`,
          {
            'border-l rounded-br-lg rounded-tr-lg': newButtonVisible,
            'rounded-lg': !newButtonVisible,
          },
        )}
        disabled={!canUpdateField || updateLoading}
        variant="primary"
        border={[false]}
        rounded={false}
      >
        <div className="py-px">
          {updateLoading ? (
            <Loader size="xs" />
          ) : newButtonVisible ? (
            <IconChevronDown size={16} />
          ) : (
            <div className="flex space-x-2">
              <span>
                {getText(
                  { dataType: dataType.display },
                  'core.COLLECTION.form.link',
                )}
              </span>
              <IconChevronDown size={16} />
            </div>
          )}
        </div>
      </Button>
    </RelationalDataFieldInput>
  );
};

export default withTheme(AddRelatedRecordButton);
