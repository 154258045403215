import React, { useMemo } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconMenu } from '@tabler/icons-react';
import first from 'lodash/first';
import kebabCase from 'lodash/kebabCase';
import { Link } from 'react-router-dom';
import {
  HorizontalNav,
  HorizontalNavItem,
  Popover,
  getColorShade,
} from '@noloco/components';
import { darkModeColors } from '../../../constants/darkModeColors';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import RecordViewTabItem from './RecordViewTabItem';

const RecordViewTabs = ({
  editorMode,
  project,
  queryRecordId,
  recordId,
  recordScope,
  rootPathname,
  tab,
  theme,
  visibleTabs,
}: any) => {
  const activeTab = useMemo(
    () =>
      (visibleTabs &&
        visibleTabs.find((tabItem: any) => kebabCase(tabItem.title) === tab)) ||
      first(visibleTabs),
    [tab, visibleTabs],
  );

  const primaryColor = theme.brandColors.primary;
  const [isDarkModeEnabled] = useDarkMode();

  if (isDarkModeEnabled) {
    theme.borderColors.default = darkModeColors.borders.oneLiteralColor;
    theme.borderColors.light = darkModeColors.borders.oneLiteralColor;
  }

  if (!visibleTabs || visibleTabs.length <= 1) {
    return null;
  }

  return (
    <>
      <Box
        className={`w-full md:flex justify-between hidden items-center px-4 py-2 border-b-2 font-medium text-sm ${
          isDarkModeEnabled ? darkModeColors.borders.one : ''
        }`}
        border={getColorShade(primaryColor, 500)}
        text={getColorShade(primaryColor, 700)}
        data-testid="record-view-tabs"
      >
        <span className="truncate mr-2">{activeTab.title}</span>
        <Popover
          content={visibleTabs.map((tabDef: any, tabIndex: any) => (
            <RecordViewTabItem
              className={`px-2 py-1 rounded-lg my-0.5 w-full text-sm hover:${
                isDarkModeEnabled ? darkModeColors.borders.two : 'bg-gray-100'
              }`}
              editorMode={editorMode}
              key={tabDef && tabDef.id}
              tabDef={tabDef}
              tab={tab}
              tabIndex={tabIndex}
              is={Link}
              queryRecordId={queryRecordId}
              project={project}
              recordId={recordId}
              recordScope={recordScope}
              rootPathname={rootPathname}
            />
          ))}
          placement="bottom-end"
        >
          <button>
            <IconMenu size={16} />
          </button>
        </Popover>
      </Box>
      <HorizontalNav
        center={false}
        className={`pb-0.5 text-sm md:hidden max-w-6xl mx-auto space-x-8 w-full ${
          isDarkModeEnabled ? darkModeColors.borders.two : ''
        }`}
        type="bottom-border"
      >
        {visibleTabs.map((tabDef: any, tabIndex: any) => (
          <RecordViewTabItem
            editorMode={editorMode}
            key={tabDef && tabDef.id}
            tabDef={tabDef}
            tab={tab}
            tabIndex={tabIndex}
            is={HorizontalNavItem}
            queryRecordId={queryRecordId}
            project={project}
            recordId={recordId}
            recordScope={recordScope}
            rootPathname={rootPathname}
          />
        ))}
      </HorizontalNav>
    </>
  );
};

export default withTheme(RecordViewTabs);
