import React, { useState } from 'react';
import { IconCheck, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import { Button, Loader, Tooltip } from '@noloco/components';
import { LINK } from '@noloco/components/src/components/button/buttonTypes';
import { SM, XS } from '@noloco/components/src/constants/tShirtSizes';
import { SECONDARY } from '@noloco/components/src/constants/variants';
import { BOARD, SPLIT, TABLE_FULL } from '../../../constants/collectionLayouts';
import { ACTION_BUTTONS } from '../../../constants/elements';
import { ActionButton, ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { BaseRecord } from '../../../models/Record';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { getText } from '../../../utils/lang';
import { RECORD_SCOPE } from '../../../utils/scope';
import { QueuedAction } from '../view/ActionButtonExecutionWrapper';

type BulkActionsBarProps = {
  layout: string;
  activeActionItem: QueuedAction | undefined;
  elementPath: ElementPath;
  handleBulkActionButtonClick: (actionButton: ActionButton) => void;
  oneClickActionButtons: ActionButton[];
  project: Project;
  scope?: Record<string, any>;
  selectedRows: BaseRecord[];
  setSelectAllRows: (allRowsSelected: boolean) => void;
  setSelectedRows: (selectedRows: BaseRecord[]) => void;
};

const BulkActionsBar = ({
  layout,
  activeActionItem,
  elementPath,
  handleBulkActionButtonClick,
  oneClickActionButtons,
  project,
  scope,
  selectedRows,
  setSelectAllRows,
  setSelectedRows,
}: BulkActionsBarProps) => {
  const [confirmTriggerId, setConfirmTriggerId] = useState<string | null>(null);

  const { actionButtons } = useSectionScopeVariables(
    ACTION_BUTTONS,
    { actionButtons: oneClickActionButtons },
    project,
    elementPath,
    scope,
    skipPropResolvingByValueIds([RECORD_SCOPE]),
  );

  return (
    <div
      className={classNames(
        'flex flex-shrink-0 items-center justify-between text-xs overflow-hidden select-non bg-gray-100 dark:bg-gray-800 border-gray-200 dark:border-gray-700',
        {
          'rounded-lg mb-2 shadow': layout !== TABLE_FULL && layout !== SPLIT,
          'py-2 px-4': layout !== SPLIT,
          'p-4': layout === SPLIT,
          'mx-4': layout === BOARD,
        },
      )}
    >
      <div>
        <span>{selectedRows.length}</span>
        <span className="text-gray-500 mx-1">
          {getText(
            { context: selectedRows.length },
            'elements.VIEW.display.bulkActions.recordsSelected',
          )}
        </span>
        <span className="text-gray-500 mx-1">|</span>
        <Button
          onClick={() => {
            setSelectAllRows(false);
            setSelectedRows([]);
          }}
          size={SM}
          type={LINK}
        >
          {getText('elements.VIEW.display.bulkActions.unselectAll')}
        </Button>
        <span className="text-gray-500 mx-1">|</span>
        <Button onClick={() => setSelectAllRows(true)} size={SM} type={LINK}>
          {getText('elements.VIEW.display.bulkActions.selectAll')}
        </Button>
      </div>
      <div className="flex items-center space-x-2">
        {actionButtons.map((actionButton: ActionButton) => (
          <Tooltip
            content={
              <div className="flex items-center">
                <span className="mr-4">
                  {getText('elements.VIEW.display.bulkActions.applyAction')}
                </span>
                <button
                  onClick={() => {
                    setConfirmTriggerId(null);
                    handleBulkActionButtonClick(actionButton);
                  }}
                  className="p-1 opacity-50 hover:opacity-100"
                >
                  <IconCheck size={16} />
                </button>
                <button
                  onClick={() => setConfirmTriggerId(null)}
                  className="p-1 opacity-50 hover:opacity-100"
                >
                  <IconX size={16} />
                </button>
              </div>
            }
            trigger="none"
            bg="white"
            isOpen={confirmTriggerId === actionButton.id}
          >
            <Button
              className="flex items-center space-x-2"
              disabled={activeActionItem}
              onClick={() => setConfirmTriggerId(actionButton.id)}
              size={SM}
              variant={actionButton.appearance || SECONDARY}
            >
              <span>{actionButton.buttonText}</span>
              {activeActionItem &&
                activeActionItem.action.id === actionButton.id && (
                  <Loader size={XS} />
                )}
            </Button>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default BulkActionsBar;
