import React, { useMemo } from 'react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import { USER } from '../../../constants/builtInDataTypes';
import useAddSection from '../../../utils/hooks/useAddSection';
import useScopeUser from '../../../utils/hooks/useScopeUser';
import useTrackAppPage, {
  PageTypes,
} from '../../../utils/hooks/useTrackAppPage';
import RecordViewSectionOptions from '../../RecordViewSectionOptions';
import RecordViewSections from './RecordViewSections';

const nullFn = () => null;

const BlankPage = ({
  sections,
  elementId,
  elementPath,
  editorMode,
  project,
}: any) => {
  const user = useScopeUser();
  const [addSection] = useAddSection(project, sections, elementPath, [
    'sections',
  ]);

  const userTypeWithRelations = useMemo(
    () => project.dataTypes.getByName(USER),
    [project.dataTypes],
  );

  const pageId = `${elementId}:PAGE`;
  const recordScope = useMemo(
    () => ({
      [pageId]: user,
    }),
    [pageId, user],
  );

  useTrackAppPage(PageTypes.BLANK);

  const fullScreen = useMemo(() => {
    const sections = get(
      project,
      ['elements', ...elementPath, 'props', 'sections'],
      false,
    );

    if ((sections as any).length === 1) {
      // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
      return get(first(sections), 'props.fullScreen', false);
    }

    return false;
  }, [project, elementPath]);

  return (
    <div
      className={classNames(
        'flex flex-wrap w-full',
        {
          'mt-8 mb-8 mr-auto ml-auto pr-4 pl-4 gap-y-4 pb-32': !fullScreen,
          'max-w-screen': fullScreen,
        },
        `view-${elementId}`,
      )}
    >
      <RecordViewSections
        data={user}
        dataType={userTypeWithRelations}
        editorMode={editorMode}
        elementPath={elementPath}
        isEditingData={false}
        onError={nullFn}
        onLoadingChange={nullFn}
        pageId={pageId}
        isRecordView={false}
        project={project}
        recordScope={recordScope}
        rootPathname={null}
        sections={sections}
        selectedTab={null}
        tabs={[]}
        visibleTabs={[]}
      />
      {editorMode && !fullScreen && (
        <RecordViewSectionOptions
          dataType={userTypeWithRelations}
          onSelect={addSection}
        />
      )}
    </div>
  );
};

export default BlankPage;
