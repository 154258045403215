export const LINE = 'line';
export const BAR = 'bar';
export const AREA = 'area';
export const FUNNEL = 'funnel';
export const RADAR = 'radar';
export const PIE = 'pie';
export const STATISTIC = 'statistic';
export const GAUGE = 'gauge';

const chartTypes = [LINE, BAR, AREA, PIE, RADAR, FUNNEL, STATISTIC, GAUGE];

export type ChartType =
  | 'line'
  | 'bar'
  | 'area'
  | 'funnel'
  | 'radar'
  | 'pie'
  | 'statistic'
  | 'gauge';

export default chartTypes;
