import React, { useCallback } from 'react';
import classNames from 'classnames';
import FormattedNumberInput from './FormattedNumberInput';

const inlineStyleProps = {
  border: 'transparent',
  bg: 'transparent',
  text: ['xs'],
  P: { x: 0, y: 0 },
};

const InlineFormattedNumberInput = ({
  id,
  autoFocus,
  className,
  value,
  onBlur,
  onChange,
  onFocus,
  onFocusChange,
  prefix,
  suffix,
  style,
  styleObj,
  surface,
  thousandSeparator,
  ...rest
}: any) => {
  const handleOnBlur = useCallback(
    (event: any) => {
      if (onBlur) {
        onBlur(event);
      }
      onFocusChange(false);
    },
    [onBlur, onFocusChange],
  );

  const handleOnFocus = useCallback(
    (event: any) => {
      if (onFocus) {
        onFocus(event);
      }
      onFocusChange(true);
    },
    [onFocus, onFocusChange],
  );

  return (
    <FormattedNumberInput
      id={id}
      autoFocus={autoFocus}
      inline={true}
      className={classNames(className, 'text-right')}
      value={value}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      onChange={onChange}
      thousandSeparator={thousandSeparator}
      style={style}
      styleObj={styleObj}
      surface={surface}
      prefix={prefix}
      suffix={suffix}
      {...inlineStyleProps}
      {...rest}
    />
  );
};

InlineFormattedNumberInput.defaultProps = {
  onFocusChange: () => null,
};

export default InlineFormattedNumberInput;
