import BaseArrayTypeMap from './BaseArrayTypeMap';
import { FieldPermission } from './Permission';

type ExtendedFieldPermission = FieldPermission & { dataFieldId?: number };

class FieldPermissions extends BaseArrayTypeMap<ExtendedFieldPermission> {
  fieldMap: Record<string, ExtendedFieldPermission>;

  constructor(arrayOrLength: ExtendedFieldPermission[] | number) {
    super(arrayOrLength);

    if (arrayOrLength instanceof FieldPermissions) {
      this.fieldMap = arrayOrLength.fieldMap || {};
    } else {
      this.fieldMap = {};
    }
  }

  _mapEntry(fieldPermission: ExtendedFieldPermission) {
    super._mapEntry(fieldPermission);

    if (fieldPermission.dataField) {
      this.fieldMap[fieldPermission.dataField.id] = fieldPermission;
    } else if (fieldPermission.dataFieldId) {
      this.fieldMap[fieldPermission.dataFieldId] = fieldPermission;
    }
  }

  getByFieldId(fieldId: string | number) {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.fieldMap[fieldId];
  }
}

export default FieldPermissions;
