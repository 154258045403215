export const EQUAL: Filter = 'equals';
export const CONTAINS: Filter = 'contains';

export const IN: Filter = 'in';
export const NOT_IN: Filter = 'notIn';

export const GREATER: Filter = 'gt';
export const GREATER_OR_EQUAL: Filter = 'gte';
export const LESS: Filter = 'lt';
export const LESS_OR_EQUAL: Filter = 'lte';

export const NOT: Filter = 'not';
export const AND: Filter = 'and';
export const OR: Filter = 'or';

export type Filter =
  | 'equals'
  | 'contains'
  | 'in'
  | 'notIn'
  | 'gt'
  | 'gte'
  | 'lt'
  | 'lte'
  | 'not'
  | 'and'
  | 'or';

const filterNames: Filter[] = [
  EQUAL,
  IN,
  NOT_IN,
  CONTAINS,
  GREATER,
  GREATER_OR_EQUAL,
  LESS,
  LESS_OR_EQUAL,
];

export default filterNames;
