import React from 'react';
import classNames from 'classnames';

const CalendarEvent = ({ title }: any) => (
  <div
    className={classNames(
      'py-px text-xs whitespace-normal line-clamp-2 max-w-full',
    )}
  >
    <div className="truncate">{title}</div>
  </div>
);

export default CalendarEvent;
