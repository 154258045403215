import React, { useContext, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { Popover, getColorShade } from '@noloco/components';
import { DnDContext } from '@noloco/react-big-calendar/lib/addons/dragAndDrop/DnDContext';
import { ROWS } from '../../../../constants/collectionLayouts';
import { darkModeColors } from '../../../../constants/darkModeColors';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import useRecordRowLink from '../../../../utils/hooks/useRecordRowLink';
import useRouter from '../../../../utils/hooks/useRouter';
import { stringifyPercent } from '../../../../utils/styles';
import CollectionEventPopoverContent from './CollectionEventPopoverContent';

const CalendarEventWrapper = ({
  className,
  children,
  event,
  onClick,
  theme,
  style,
  type,
  project,
  Row,
  rowLink,
}: any) => {
  const {
    // @ts-expect-error TS(2339): Property 'button' does not exist on type '{}'.
    query: { button, step },
  } = useRouter();
  const primaryColor = theme.brandColors.primary;
  const { draggable } = useContext(DnDContext);

  const recordRowLink = useRecordRowLink(
    rowLink,
    project,
    ROWS,
    event.rootPathname,
    get(event, 'record.uuid'),
  );

  const actionButtonIsOpen = useMemo(() => !!button && !!step, [button, step]);
  const [isDarkModeEnabled] = useDarkMode();

  const isBeingDragged = useMemo(
    () =>
      draggable.dragAndDropAction.interacting &&
      draggable.dragAndDropAction.event === event,
    [
      draggable.dragAndDropAction.event,
      draggable.dragAndDropAction.interacting,
      event,
    ],
  );

  const isTime = type === 'time';

  const pcStyle = useMemo(
    () =>
      style && isTime
        ? {
            ...style,
            top: stringifyPercent(style.top),
            left: stringifyPercent(Math.max(0, style.xOffset)),
            width: stringifyPercent(style.width),
            height: stringifyPercent(style.height),
          }
        : undefined,
    [isTime, style],
  );

  return (
    <Popover
      closeOnOutsideClick={!actionButtonIsOpen}
      bg={
        isDarkModeEnabled
          ? darkModeColors.surfaces.elevation1LiteralColor
          : undefined
      }
      content={
        <CollectionEventPopoverContent
          event={event}
          isDarkModeEnabled={isDarkModeEnabled}
          recordRowLink={recordRowLink as string}
          Row={Row}
        />
      }
      className="overflow-hidden"
      p={{ x: 0, y: 0 }}
      placement="right"
    >
      <div
        className={classNames(
          className,
          'rounded-lg overflow-hidden flex-wrap border',
          {
            'absolute overflow-hidden': isTime,
            'pl-2': !isTime,
            'opacity-0':
              isBeingDragged &&
              (!className || !className.includes('drag-preview')),
            'opacity-75': className && className.includes('drag-preview'),
          },
          `bg-${getColorShade(event.color || primaryColor, 600)}`,
          `border-${getColorShade(event.color || primaryColor, 800)}`,
        )}
        style={pcStyle}
        onClick={onClick}
      >
        {children}
      </div>
    </Popover>
  );
};

export default withTheme(CalendarEventWrapper);
