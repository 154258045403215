import React from 'react';
import isNil from 'lodash/isNil';

const StatisticChart = ({
  data,
  primaryAxisFormatter,
}: {
  data: { x: number | null };
  primaryAxisFormatter: (x: number) => any;
}) => {
  if (isNil(data.x)) {
    <div className="text-2xl whitespace-nowrap truncate opacity-50">--</div>;
  }

  return (
    data && (
      <div className="text-2xl whitespace-nowrap font-medium tracking-wider truncate">
        {primaryAxisFormatter(data.x!)}
      </div>
    )
  );
};

export default StatisticChart;
