import React, { forwardRef, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import kebabCase from 'lodash/kebabCase';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import { User } from '../../../models/User';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import useRouter from '../../../utils/hooks/useRouter';
import useTrackAppPage, {
  PageTypes,
} from '../../../utils/hooks/useTrackAppPage';
import { getDepsForViewSections } from '../../../utils/sectionDependencies';
import { QueryRoute } from '../../Page';
import RecordComments from './RecordComments';
import RecordViewBody from './RecordViewBody';
import { filterSections } from './RecordViewSections';

const RecordView = forwardRef(
  (
    {
      // @ts-expect-error TS(2339): Property 'actionButtons' does not exist on type '{... Remove this comment to see the full error message
      actionButtons,
      // @ts-expect-error TS(2339): Property 'comments' does not exist on type '{}'.
      comments = {},
      // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
      className,
      // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
      dataType,
      // @ts-expect-error TS(2339): Property 'element' does not exist on type '{}'.
      element,
      // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{}'... Remove this comment to see the full error message
      elementPath,
      // @ts-expect-error TS(2339): Property 'hideEditButton' does not exist on type '... Remove this comment to see the full error message
      hideEditButton,
      // @ts-expect-error TS(2339): Property 'editButtonText' does not exist on type '... Remove this comment to see the full error message
      editButtonText,
      // @ts-expect-error TS(2339): Property 'doneButtonText' does not exist on type '... Remove this comment to see the full error message
      doneButtonText,
      // @ts-expect-error TS(2339): Property 'editButton' does not exist on type '{}'.
      editButton = {},
      // @ts-expect-error TS(2339): Property 'icon' does not exist on type '{}'.
      icon,
      // @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'.
      name,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type '{}'.
      onClick,
      // @ts-expect-error TS(2339): Property 'permissions' does not exist on type '{}'... Remove this comment to see the full error message
      permissions,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
      project,
      // @ts-expect-error TS(2339): Property 'recordId' does not exist on type '{}'.
      recordId,
      // @ts-expect-error TS(2339): Property 'rootPathname' does not exist on type '{}... Remove this comment to see the full error message
      rootPathname,
      // @ts-expect-error TS(2339): Property 'sections' does not exist on type '{}'.
      sections,
      // @ts-expect-error TS(2339): Property 'isSplitLayout' does not exist on type '{... Remove this comment to see the full error message
      isSplitLayout,
      // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
      scope,
      // @ts-expect-error TS(2339): Property 'tabs' does not exist on type '{}'.
      tabs,
      // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
      title,
      // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type '{}'.
      subtitle,
      // @ts-expect-error TS(2339): Property 'useUpdateProperty' does not exist on typ... Remove this comment to see the full error message
      useUpdateProperty,
      // @ts-expect-error TS(2339): Property 'hideBreadcrumbs' does not exist on typ... Remove this comment to see the full error message
      hideBreadcrumbs,
    },
    ref,
  ) => {
    const { user } = useAuthWrapper();
    const {
      // @ts-expect-error TS(2339): Property 'tab' does not exist on type '{}'.
      query: { tab, _comments },
      replaceQueryParams,
    } = useRouter();

    const selectedTab = useMemo(() => {
      const defaultTab = first(tabs);
      const activeTab =
        tabs && tabs.find((t: any) => kebabCase(t.title) === tab);
      if (activeTab === defaultTab) {
        return null;
      }

      return activeTab;
    }, [tab, tabs]);

    useTrackAppPage(PageTypes.RECORD);

    const commentsAreOpen = _comments === 'true';

    useEffect(() => {
      if (_comments === undefined && comments.openByDefault) {
        replaceQueryParams({ _comments: true });
      }
    }, [_comments, comments.openByDefault, replaceQueryParams]);

    const fieldPermissionsEnabled = useIsFeatureEnabled(
      FIELD_LEVEL_PERMISSIONS,
    );

    const dependencies = useMemo(() => {
      const tabSections = filterSections(tabs, tabs, sections, selectedTab);
      const mappedSections = tabSections.map(
        (tabSection: any) => tabSection.section,
      );

      const deps = getDepsForViewSections(
        element,
        mappedSections,
        project.dataTypes,
        dataType,
        user as User,
        actionButtons,
        tabs,
        title,
        fieldPermissionsEnabled,
      );

      deps.push({
        id: `${element.id}:VIEW`,
        path: 'nolocoCommentsCollection.totalCount',
      });

      return deps;
    }, [
      actionButtons,
      dataType,
      element,
      fieldPermissionsEnabled,
      project,
      sections,
      selectedTab,
      tabs,
      title,
      user,
    ]);

    const pageId = `${element.id}:VIEW`;
    const elementWithUpdatedId = useMemo(() => set('id', pageId, element), [
      element,
      pageId,
    ]);

    return (
      // @ts-expect-error TS(2322): Type '{ children: ({ loading, data }: any) => Elem... Remove this comment to see the full error message
      <QueryRoute
        dataType={dataType.name}
        dataTypes={project.dataTypes}
        dataProperty="uuid"
        dependencies={dependencies}
        element={elementWithUpdatedId}
        property="recordId"
        project={project}
        recordId={recordId}
        scope={scope}
        showLoading={false}
      >
        {({ loading, data, refetch }: any) => {
          if (!loading && !data) {
            return null;
          }

          const recordScope = {
            [pageId]: data,
          };

          return (
            <div
              className={classNames('w-full', className, {
                'pl-2': !!_comments,
              })}
              // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
              ref={ref}
              onClick={onClick}
              data-testid="record-view"
            >
              <div className="flex flex-col items-center flex-grow mx-auto w-full pb-32">
                <RecordViewBody
                  actionButtons={actionButtons}
                  comments={comments}
                  classNames={classNames}
                  data={data}
                  dataType={dataType}
                  element={element}
                  elementPath={elementPath}
                  hideEditButton={hideEditButton}
                  editButtonText={editButtonText}
                  doneButtonText={doneButtonText}
                  editButtonVisibilityRules={editButton.visibilityRules}
                  icon={icon}
                  isSplitLayout={isSplitLayout}
                  loading={loading}
                  name={name}
                  pageId={pageId}
                  permissions={permissions}
                  project={project}
                  recordId={recordId}
                  recordScope={recordScope}
                  refetch={refetch}
                  rootPathname={rootPathname}
                  scope={scope}
                  sections={sections}
                  tabs={tabs}
                  title={title}
                  subtitle={subtitle}
                  useUpdateProperty={useUpdateProperty}
                  hideBreadcrumbs={hideBreadcrumbs}
                />
              </div>
              {commentsAreOpen && data && comments.show && (
                <RecordComments
                  dataType={dataType}
                  project={project}
                  openByDefault={comments.openByDefault}
                  record={data}
                />
              )}
            </div>
          );
        }}
      </QueryRoute>
    );
  },
);

RecordView.defaultProps = {
  // @ts-expect-error TS(2322): Type '{ actionButtons: never[]; sections: never[];... Remove this comment to see the full error message
  actionButtons: [],
  sections: [],
  isSplitLayout: false,
  showSplitList: false,
  tabs: [],
  useUpdateProperty: () => [null],
};

export default RecordView;
