import React, { useMemo } from 'react';
import { getText } from '@noloco/core/src/utils/lang';
import { darkModeColors } from '../../../constants/darkModeColors';
import { DETAILS, TITLE } from '../../../constants/elements';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import Title from '../Title';
import ActionButton from './ActionButton';

const RecordTitle = ({
  dataType,
  elementPath,
  editorMode,
  record,
  rootPathname,
  project,
  recordScope,
  ...rest
}: any) => {
  const { title, subtitle, coverPhoto } = useSectionScopeVariables(
    TITLE,
    rest,
    project,
    elementPath,
    recordScope,
  );

  const { actionButtons = [] } = useSectionScopeVariables(
    DETAILS,
    rest,
    project,
    elementPath,
    recordScope,
  );

  const [isDarkModeEnabled] = useDarkMode();

  const isTitleAndSubtitleEmpty = useMemo(
    () => !title?.value && !subtitle?.value,
    [title, subtitle],
  );

  if (isTitleAndSubtitleEmpty && !editorMode) {
    return null;
  }

  if (isTitleAndSubtitleEmpty && editorMode) {
    return (
      <div
        className={`border rounded-lg shadow-md flex items-center justify-center p-8 ${
          isDarkModeEnabled
            ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.two}`
            : 'bg-white border-gray-200'
        }`}
      >
        <div className="text-center w-full text-gray-600 py-24">
          {getText('elements.DETAILS.view.empty')}
        </div>
      </div>
    );
  }

  return (
    <Title
      image={{ hidden: true }}
      subtitle={subtitle}
      coverPhoto={coverPhoto}
      project={project}
      title={title}
      editorMode={editorMode}
    >
      {actionButtons.map((actionButton: any, actionButtonIndex: any) => (
        <ActionButton
          actionButton={actionButton}
          dataType={dataType}
          editorMode={editorMode}
          index={actionButtonIndex}
          key={actionButtonIndex.id}
          rootPathname={rootPathname}
          record={record}
          recordScope={recordScope}
          project={project}
        />
      ))}
    </Title>
  );
};

RecordTitle.defaultProps = {};

export default RecordTitle;
