import React from 'react';
import get from 'lodash/get';
import { MANY_TO_ONE } from '../constants/relationships';
import { getFullValuePath } from '../utils/data';
import useMergedScope from '../utils/hooks/useMergedScope';
import RelatedCellItem from './sections/collections/RelatedCellItem';

type Props = {};

// @ts-expect-error TS(2339): Property 'emptyState' does not exist on type 'Prop... Remove this comment to see the full error message
const Record = ({ emptyState, field, project, scope }: Props) => {
  const mergedScope = useMergedScope(scope);

  if (!field || !field.dataType) {
    return null;
  }
  const valuePath = getFullValuePath(field).replace(/\.node$/, '');
  const value = get(mergedScope, valuePath);

  const dataType = field && project.dataTypes.getByName(field.dataType);

  if (!value) {
    return null;
  }

  if (Array.isArray(value)) {
    return (
      <div className="flex flex-wrap">
        {value
          .map((edge) => edge.node)
          .map((nodeValue) => (
            <RelatedCellItem
              key={nodeValue.id}
              className="mb-2 mr-2"
              dataTypes={project.dataTypes}
              field={{
                type: dataType.name,
                relationship: MANY_TO_ONE,
              }}
              value={nodeValue}
              single={true}
              size={14}
            />
          ))}
      </div>
    );
  }

  if (value && emptyState) {
    return <div className="text-gray-600 text-sm">{emptyState}</div>;
  }

  return (
    <RelatedCellItem
      dataTypes={project.dataTypes}
      field={{
        type: dataType.name,
        relationship: MANY_TO_ONE,
      }}
      value={value}
      single={true}
      size={14}
    />
  );
};

export default Record;
