import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { DateTime } from 'luxon';
import DatePickerPopover, {
  formatLocalDateTimeResult,
} from '@noloco/components/src/components/input/DatePickerPopover';
import TextInput from '@noloco/components/src/components/input/TextInput';
import { ROUNDED_LARGE } from '@noloco/components/src/components/input/inputStyles';
import useLocale from '@noloco/components/src/utils/hooks/useLocale';
import { DATE_SHORT, TIME, TIME_24 } from '../constants/dateFormatOptions';

const TIME_COMMA_SEPARATOR_REGEX = /(,? \d\d?:\d\d)/;

const formatDate = (
  start: any,
  end: any,
  isRange: any,
  selectTime: any,
  timeZone: any,
  locale: any,
) => {
  if (!start || (isRange && !end)) {
    return null;
  }
  const dateFormat =
    selectTime && !isRange ? DateTime.DATETIME_SHORT : DateTime.DATE_SHORT;
  let formattedStart = start;
  if (selectTime) {
    if (timeZone) {
      formattedStart = start.setZone(timeZone);
    } else {
      formattedStart = start.toLocal();
    }
  }

  const formattedStartString = formattedStart
    .setLocale(locale.code)
    .toLocaleString(dateFormat)
    .replace(',', '');
  if (!isRange) {
    return formattedStartString;
  }

  let formattedEnd = end;

  if (selectTime) {
    if (timeZone) {
      formattedEnd = end.setZone(timeZone);
    } else {
      formattedEnd = end.toLocal();
    }
  }

  return `${formattedStartString} - ${formattedEnd
    .setLocale(locale.code)
    .toLocaleString(dateFormat)
    .replace(',', '')}`;
};

type Props = {
  clearable?: boolean;
  value?: any; // TODO: PropTypes.instanceOf(Date)
  placeholder?: React.ReactNode | string;
  isRange?: boolean;
  style?: any; // TODO: oneOfWithDefault(inputStyles)
  selectTime?: boolean;
};

const DatePicker = forwardRef<any, Props>(
  (
    {
      // @ts-expect-error TS(2339): Property 'autoFocus' does not exist on type 'Props... Remove this comment to see the full error message
      autoFocus,
      // @ts-expect-error TS(2339): Property 'bg' does not exist on type 'Props'.
      bg,
      // @ts-expect-error TS(2339): Property 'borderColor' does not exist on type 'Pro... Remove this comment to see the full error message
      borderColor,
      clearable,
      // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Props'... Remove this comment to see the full error message
      disabled,
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'Props'.
      id,
      // @ts-expect-error TS(2339): Property 'inline' does not exist on type 'Props'.
      inline,
      isRange,
      // @ts-expect-error TS(2339): Property 'onBlur' does not exist on type 'Props'.
      onBlur,
      // @ts-expect-error TS(2339): Property 'onChange' does not exist on type 'Props'... Remove this comment to see the full error message
      onChange,
      // @ts-expect-error TS(2339): Property 'open' does not exist on type 'Props'.
      open,
      placeholder,
      selectTime,
      style,
      // @ts-expect-error TS(2339): Property 'surface' does not exist on type 'Props'.
      surface,
      // @ts-expect-error TS(2339): Property 'timeZone' does not exist on type 'Props'... Remove this comment to see the full error message
      timeZone,
      // @ts-expect-error TS(2339): Property 'validationError' does not exist on type ... Remove this comment to see the full error message
      validationError,
      value,
      ...rest
    },
    ref,
  ) => {
    const locale = useLocale();
    const [localValidationError, setLocalValidationError] = useState(false);

    const debouncedOnChange = useMemo(() => debounce(onChange, 600), [
      onChange,
    ]);

    const onTextValueChange = useCallback(
      (event: any) => {
        let nextValue = event.target.value;

        if (!nextValue) {
          setLocalValidationError(false);
          return debouncedOnChange(null);
        }

        const includeTime = selectTime && !isRange;

        if (includeTime) {
          nextValue = nextValue.replace(TIME_COMMA_SEPARATOR_REGEX, ',$1');
        }

        const [date, time = null] = nextValue.split(', ', 2);
        const isAmPmTime =
          includeTime &&
          time &&
          (time.toLowerCase().endsWith('am') ||
            time.toLowerCase().endsWith('pm'));

        const timeFormat = isAmPmTime ? TIME : TIME_24;
        const dateTimeFormat = `${DATE_SHORT} ${timeFormat}`;

        let dateValue = DateTime.fromFormat(date, DATE_SHORT, {
          locale: locale.code,
        });

        if (dateValue.isValid) {
          let dateToUpdate = dateValue;
          const timeValue =
            includeTime &&
            DateTime.fromFormat(time, timeFormat, {
              locale: locale.code,
            });

          if (timeValue) {
            if (timeValue.isValid) {
              dateToUpdate = DateTime.fromFormat(
                `${date} ${time}`,
                dateTimeFormat,
                { locale: locale.code },
              );
            } else {
              return setLocalValidationError(true);
            }
          }

          debouncedOnChange(
            formatLocalDateTimeResult(dateToUpdate, selectTime, timeZone),
          );
          setLocalValidationError(false);
        } else {
          setLocalValidationError(true);
        }
      },
      [debouncedOnChange, isRange, locale.code, selectTime, timeZone],
    );

    return (
      <DatePickerPopover
        id={id}
        bg={bg}
        borderColor={borderColor}
        clearable={clearable}
        disabled={disabled}
        isRange={isRange}
        onBlur={onBlur}
        onChange={onChange}
        open={open}
        selectTime={selectTime}
        style={style}
        surface={surface}
        timeZone={timeZone}
        value={value}
      >
        {({ startDate, endDate, selectsRange, selectTime }: any) => (
          <TextInput
            id={`${id}-text`}
            data-testid={`${id}-text`}
            autoFocus={autoFocus}
            bg={bg}
            readOnly={isRange}
            disabled={disabled}
            inline={inline}
            placeholder={placeholder}
            surface={surface}
            style={style}
            onBlur={onBlur}
            onChange={onTextValueChange}
            autoComplete="off"
            value={formatDate(
              startDate,
              endDate,
              selectsRange,
              selectTime,
              timeZone,
              locale,
            )}
            valid={!localValidationError}
            validationError={validationError}
            {...rest}
            ref={ref}
          />
        )}
      </DatePickerPopover>
    );
  },
);

DatePicker.defaultProps = {
  clearable: true,
  isRange: false,
  // @ts-expect-error TS(2322): Type '{ clearable: true; isRange: false; inline: b... Remove this comment to see the full error message
  inline: false,
  style: ROUNDED_LARGE,
  selectTime: false,
  theme: 'default',
};

export default DatePicker;
