import React, { forwardRef, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Route, Switch } from 'react-router-dom';
import { Loader } from '@noloco/components';
import {
  ElementRenderer,
  getElementPath,
} from '@noloco/ui/src/components/canvas/ProjectRenderer';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { isElementVisible } from '../components/canvas/withVisibilityRules';
import { CUSTOM_VISIBILITY_RULES } from '../constants/features';
import { DATABASE } from '../constants/scopeTypes';
import { Project } from '../models/Project';
import { BLANK_QUERY_STRING, getPageQueryString } from '../queries/project';
import {
  findDependentValues,
  formatValue,
  transformDepsToQueryObject,
} from '../utils/data';
import { getPagesConfig, updateScope } from '../utils/elements';
import useAuthWrapper from '../utils/hooks/useAuthWrapper';
import { useInvalidateProjectData } from '../utils/hooks/useInvalidateProjectData';
import useRouter from '../utils/hooks/useRouter';
import useSetDocumentTitle from '../utils/hooks/useSetDocumentTitle';
import useSetQuery from '../utils/hooks/useSetQuery';
import { getText } from '../utils/lang';
import { getPagePath } from '../utils/pages';
import { transformColumnarScope } from '../utils/scope';
import BlankPage from './sections/view/BlankPage';

// @ts-expect-error TS(7006): Parameter 'loading' implicitly has an 'any' type.
export const getPageScope = (loading, pageData, dataType, dataTypes) => ({
  ...transformColumnarScope(pageData, dataType, dataTypes),
  loading,
});

// @ts-expect-error TS(7006): Parameter 'existingScope' implicitly has an 'any' ... Remove this comment to see the full error message
const getPageLoadingScope = (existingScope, elementId, loading) => ({
  ...existingScope,

  [elementId]: {
    loading,
  },
});

const QueryRouteResult = ({
  children,
  error,
  pageData,
  dataType,
  dataTypes,
  elementId,
  loading,
  scope,
  showLoading,
  refetch,
}: any) => {
  const pageScope = useMemo(
    () => pageData && getPageScope(false, pageData, dataType, dataTypes),
    [dataType, dataTypes, pageData],
  );
  const nextScope = useMemo(
    () => getPageLoadingScope(scope, elementId, loading),
    [elementId, loading, scope],
  );

  if (loading && showLoading) {
    return (
      <div className="flex w-full h-full items-center justify-center py-24">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {(error || (!pageData && !loading)) && (
        <div className="absolute top-0 left-0 right-0 p-8">
          <div className="p-4 bg-red-200 text-red-900 border-red-300 border rounded w-full">
            {error && <pre>{String(error)}</pre>}
            {!pageData && (
              <span>
                {getText({ dataType: dataType.display }, 'errors.notFound')}
              </span>
            )}
          </div>
        </div>
      )}
      {updateScope(
        children({ data: pageScope, loading, error, refetch }),
        nextScope,
        {
          loading,
        },
      )}
    </>
  );
};

export const QueryRoute = forwardRef(
  (
    {
      children,
      // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
      dataType,
      // @ts-expect-error TS(2339): Property 'dataTypes' does not exist on type '{}'.
      dataTypes,
      // @ts-expect-error TS(2339): Property 'dataProperty' does not exist on type '{}... Remove this comment to see the full error message
      dataProperty,
      // @ts-expect-error TS(2339): Property 'dependencies' does not exist on type '{}... Remove this comment to see the full error message
      dependencies,
      // @ts-expect-error TS(2339): Property 'element' does not exist on type '{}'.
      element,
      // @ts-expect-error TS(2339): Property 'property' does not exist on type '{}'.
      property,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
      project,
      // @ts-expect-error TS(2339): Property 'recordId' does not exist on type '{}'.
      recordId,
      // @ts-expect-error TS(2339): Property 'showLoading' does not exist on type '{}'... Remove this comment to see the full error message
      showLoading = true,
      // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
      scope,
    },
    ref,
  ) => {
    const { query } = useRouter();

    const pageTypeDeps = useMemo(
      () => dependencies.filter((dep: any) => dep.path),
      [dependencies],
    );

    const pageDataType = useMemo(() => project.dataTypes.getByName(dataType), [
      dataType,
      project.dataTypes,
    ]);
    const urlField = useMemo(
      () =>
        pageDataType && property && pageDataType.fields.getByName(dataProperty),
      [dataProperty, pageDataType, property],
    );

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const rawValue = recordId || query[property];

    const variables = useMemo(
      () => ({
        [dataProperty]: urlField
          ? // @ts-expect-error TS(2554): Expected 5-6 arguments, but got 2.
            formatValue(rawValue, urlField.type)
          : rawValue,
      }),
      [dataProperty, rawValue, urlField],
    );

    if (pageDataType && pageTypeDeps.length === 0) {
      pageDataType.fields
        .filter((f: any) => f.unique)
        .forEach((field: any) => {
          pageTypeDeps.push({
            path: field.name,
            source: DATABASE,
            type: field.type,
            id: element.id,
          });
        });
    }

    const gqlQueryString = useMemo(() => {
      const dataTypes = project.dataTypes;

      return pageDataType
        ? getPageQueryString(
            pageDataType.apiName,
            dataProperty
              ? { [dataProperty]: variables[dataProperty] }
              : undefined,
            transformDepsToQueryObject(pageDataType, dataTypes, pageTypeDeps),
          )
        : BLANK_QUERY_STRING;
    }, [
      dataProperty,
      pageDataType,
      pageTypeDeps,
      project.dataTypes,
      variables,
    ]);

    const queryObject = useMemo(
      () => ({
        id: element.id,
        variables,
        dataType,
        dataProperty,
        type: element.type,
        query: gqlQueryString,
      }),
      [
        dataProperty,
        dataType,
        element.id,
        element.type,
        gqlQueryString,
        variables,
      ],
    );
    useSetQuery(queryObject);

    const { loading, error, data, refetch } = useQuery(
      gql`
        ${gqlQueryString}
      `,
      {
        skip:
          !pageDataType || pageTypeDeps.length === 0 || rawValue === undefined,
        variables,
        errorPolicy: 'all',
        context: {
          projectQuery: true,
          projectName: project.name,
        },
      },
    );
    useInvalidateProjectData(refetch);

    const pageData = useMemo(
      () => pageDataType && get(data, pageDataType.apiName),
      [data, pageDataType],
    );

    if (error) {
      console.log(error);
    }

    if (!pageDataType) {
      return (
        <div className="p-4 bg-red-500 rounded w-full text-white my-4">
          {error || getText({ dataType }, 'errors.page')}
        </div>
      );
    }

    return (
      <QueryRouteResult
        children={children}
        error={error}
        pageData={pageData}
        dataType={pageDataType}
        dataTypes={dataTypes}
        elementId={element.id}
        loading={loading}
        ref={ref}
        refetch={refetch}
        scope={scope}
        showLoading={showLoading}
      />
    );
  },
);

type PageProps = {
  auth?: boolean;
  children?: React.ReactNode;
  dataType?: string;
  editorMode?: boolean;
  elementPath: (string | number)[];
  dataProperty?: string;
  routePath?: string;
  project: Project;
};

const Page = forwardRef<any, PageProps>(
  (
    {
      auth,
      children,
      dataType,
      editorMode,
      elementPath,
      dataProperty,
      routePath,
      project,
      // @ts-expect-error TS(2339): Property 'scope' does not exist on type 'PageProps... Remove this comment to see the full error message
      scope,
      // @ts-expect-error TS(2339): Property 'V2' does not exist on type 'PageProps'.
      V2 = false,
      // @ts-expect-error TS(2339): Property 'sections' does not exist on type 'PagePr... Remove this comment to see the full error message
      sections = [],
      // @ts-expect-error TS(2339): Property 'elementId' does not exist on type 'PageP... Remove this comment to see the full error message
      elementId,
      ...rest
    },
    ref,
  ) => {
    const { fetchedUser, user } = useAuthWrapper();
    const element = get(project.elements, elementPath);
    const deps = useMemo(
      () => findDependentValues(element.id, element, project.dataTypes),
      [element, project.dataTypes],
    );

    const { path, property } = getPagePath(
      '/',
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      routePath,
      dataType,
      dataProperty,
    );

    const documentTitle = useMemo(() => {
      const { projectPages } = getPagesConfig(
        project.elements,
        project.settings,
      );
      const titleParts = [get(element, 'props.name')];

      const parentPageId = get(element, 'props.parentPage');
      const parentPage =
        parentPageId && projectPages.find((el: any) => el.id === parentPageId);

      if (parentPage) {
        titleParts.push(get(parentPage, 'props.name'));
      }

      return titleParts.join(' — ');
    }, [element, project]);

    useSetDocumentTitle(documentTitle);

    const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
    const subPages = useMemo(
      () =>
        get(element, 'props.SubPages', []).filter(
          (subPage: any) =>
            !fetchedUser ||
            isElementVisible(
              subPage,
              project,
              user,
              scope,
              editorMode,
              customRulesEnabled,
            ),
        ),
      [
        customRulesEnabled,
        editorMode,
        element,
        fetchedUser,
        project,
        scope,
        user,
      ],
    );

    if (property) {
      return (
        <Route {...rest} path={path}>
          {/* @ts-expect-error TS(2322): Type '{ children: () => ReactNode; auth: boolean |... Remove this comment to see the full error message */}
          <QueryRoute
            auth={auth}
            dataType={dataType}
            dataTypes={project.dataTypes}
            dataProperty={dataProperty}
            dependencies={deps}
            element={element}
            elementPath={elementPath}
            property={property}
            project={project}
            scope={scope}
            ref={ref}
          >
            {() => children}
          </QueryRoute>
        </Route>
      );
    }

    return (
      <Switch>
        {subPages.map((subPage: any, index: any) => (
          <Route key={subPage.id} path={`${path}/${subPage.props.routePath}`}>
            {V2 ? (
              <BlankPage
                sections={get(subPage, 'props.sections', [])}
                elementId={subPage.id}
                elementPath={[...elementPath, 'props', 'SubPages', index]}
                project={project}
                editorMode={editorMode}
              />
            ) : (
              get(
                subPage,
                'children',
                [],
              ).map((subPageChild: any, childIndex: any) => (
                <ElementRenderer
                  editorMode={editorMode}
                  element={subPageChild}
                  elementPath={getElementPath(
                    [...elementPath, 'props', 'SubPages', index, 'children'],
                    childIndex,
                  )}
                  key={subPageChild.id}
                  index={childIndex}
                  project={project}
                  scope={scope}
                />
              ))
            )}
          </Route>
        ))}
        <Route>
          {V2 ? (
            <BlankPage
              sections={sections}
              elementId={elementId}
              elementPath={elementPath}
              project={project}
              editorMode={editorMode}
            />
          ) : (
            React.Children.map(children, (child) =>
              // @ts-expect-error TS(2769): No overload matches this call.
              React.cloneElement(child, {
                scope,
                ref,
              }),
            )
          )}
        </Route>
      </Switch>
    );
  },
);

Page.defaultProps = {
  auth: false,
  dataType: undefined,
  dataProperty: undefined,
  editorMode: false,
  routePath: undefined,
};

export default Page;
