import React, { forwardRef, memo, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Element from '@noloco/core/src/components/canvas/Element';
import { VIEW } from '@noloco/core/src/constants/elements';
import elementsConfig from '@noloco/core/src/elements';
import { setSelectedElement as dispatchSetSelectedElement } from '@noloco/core/src/reducers/elements';
import useHover from '@noloco/core/src/utils/hooks/useHover';
import ElementEditorWrapper from './ElementEditorWrapper';
import ElementHighlight from './ElementHighlight';

const ElementWrapper = memo(
  forwardRef(
    (
      {
        // @ts-expect-error TS(2339): Property 'element' does not exist on type '{ child... Remove this comment to see the full error message
        element,
        // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type '{ ch... Remove this comment to see the full error message
        isSelected,
        // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{ c... Remove this comment to see the full error message
        elementPath,
        // @ts-expect-error TS(2339): Property 'setSelectedElement' does not exist on ty... Remove this comment to see the full error message
        setSelectedElement,
        // @ts-expect-error TS(2339): Property 'project' does not exist on type '{ child... Remove this comment to see the full error message
        project,
        ...props
      },
      ref,
    ) => {
      const {
        // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
        props: { className, onClick, onMouseOver, onMouseOut } = {},
      } = element;
      const elementConfig = elementsConfig[element.type];
      const [elementRef, isHighlighted] = useHover(
        !elementConfig || elementConfig.hidden || isSelected,
        {
          stopPropagation: true,
        },
      );

      const handleOnClick = useCallback(
        (event) => {
          if (onClick) {
            onClick(event);
          }

          if (!elementConfig || !elementConfig.hidden) {
            event.stopPropagation();
            setSelectedElement(elementPath);
          }
        },
        [onClick, elementConfig, setSelectedElement, elementPath],
      );

      return (
        elementConfig && (
          <>
            {/* @ts-expect-error TS(2322): Type '{ children: Element; element: any; elementPa... Remove this comment to see the full error message */}
            <ElementEditorWrapper
              element={element}
              elementPath={elementPath}
              project={project}
              ref={ref}
            >
              <Element
                {...props}
                className={classNames(className)}
                editorMode={true}
                element={element}
                elementPath={elementPath}
                onClick={elementConfig.hidden ? undefined : handleOnClick}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                isSelected={isSelected}
                project={project}
                // @ts-expect-error TS(2322): Type 'boolean | MutableRefObject<null>' is not ass... Remove this comment to see the full error message
                ref={elementRef}
                ChildWrapper={ConnectedElementWrapper}
              />
            </ElementEditorWrapper>
            {!elementConfig.hidden &&
              elementConfig.styleable &&
              element.type !== VIEW &&
              isHighlighted && (
                <ElementHighlight
                  // @ts-expect-error TS(2322): Type '{ project: any; element: any; elementConfig:... Remove this comment to see the full error message
                  project={project}
                  element={element}
                  elementConfig={elementConfig}
                  elementPath={elementPath}
                  setSelectedElement={setSelectedElement}
                  selected={isSelected}
                />
              )}
          </>
        )
      );
    },
  ),
);

(ElementWrapper as any).propTypes = {
  highlighted: PropTypes.array,
  isSelected: PropTypes.bool.isRequired,
};

const mapStateToProps = (
  { elements: { selected } }: any,
  { elementPath }: any,
) => ({
  isSelected: selected && selected.join('.') === elementPath.join('.'),
});

const mapDispatch = {
  setSelectedElement: dispatchSetSelectedElement,
};

const ConnectedElementWrapper = connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(ElementWrapper);

export default ConnectedElementWrapper;
