import React, { forwardRef, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { Loader } from '@noloco/components';
import withDataFields from '../../../components/canvas/withDataFields';
import {
  CARDS,
  CHARTS,
  EVENT_BASED_LAYOUTS,
  MAP,
  ROWS,
  SPLIT,
  TABLE,
  TABLE_FULL,
} from '../../../constants/collectionLayouts';
import { RecordEdge } from '../../../models/Record';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { PAGINATION_STYLES } from '../../sections/Collection';
import CollectionCharts from './CollectionCharts';
import CollectionEvents from './CollectionEvents';
import CollectionMap from './CollectionMap';
import CollectionRecord from './CollectionRecord';
import DraggableGroupView from './DraggableGroupView';
import GroupedVirtualizedLayout from './layouts/GroupedVirtualizedLayout';
import TableLayout from './layouts/TableLayout';
import VirtualizedCollectionBody from './layouts/VirtualizedCollectionBody';
import RowPagination from './pagination/RowPagination';

const DEFAULT_COLUMN_WIDTHS = {};

const CollectionDataBody = ({
  actionButtons,
  calendarDate,
  calendarView,
  enableDragAndDropEdit,
  charts,
  customFilters,
  dateStart,
  dateStartField,
  dateEndField,
  ganttDependency,
  dataList,
  dataType,
  editRelatedRecordButtons,
  editorMode,
  EmptyState,
  element,
  elementPath,
  fieldConfigs,
  formatRecordScope,
  groupByFields,
  groupOptions,
  handleSetOrderBy,
  hideEmptyGroups,
  innerClassName,
  layout,
  limitPerGroup,
  map,
  mapLatField,
  mapLngField,
  maxStickyColumnIndex,
  orderBy,
  permissions,
  project,
  qsSuffix,
  recordConfig,
  recordQueryString,
  recordTitle,
  rootDataType,
  rowLink,
  scope,
  setPaginationQuery,
  showCardHeroImage,
  showTableSummary,
  transformRecordScope,
  viewRootPathname,
  edges,
  rawData,
  pageInfo,
  totalCount,
  nodeQueryObject,
  loading,
  error,
  parentValuePath,
  bulkActionsEnabled,
  allRowsSelected,
  setSelectAllRows,
  selectedRows,
  setSelectedRows,
  theme,
}: any) => {
  const [isDarkModeEnabled] = useDarkMode();

  const WrappedCollectionRecord = useMemo(
    () => withDataFields(CollectionRecord, element, project, editorMode),
    [editorMode, element, project],
  );

  const CollectionRecordWrapper = useMemo(
    () =>
      forwardRef(
        (
          {
            className,
            children,
            edge,
            index,
            isLast,
            'data-index': dataIndex,
            'data-group-key': dataGroupKey,
            draggable,
            record,
            columnWidths,
          }: any,
          recordRef,
        ) => (
          <WrappedCollectionRecord
            actionButtons={actionButtons}
            className={className}
            dataList={dataList}
            dataType={dataType}
            editorMode={editorMode}
            element={element}
            elementPath={elementPath}
            edge={edge}
            editRelatedRecordButtons={editRelatedRecordButtons}
            fieldConfigs={fieldConfigs}
            formatRecordScope={formatRecordScope}
            groupByFields={groupByFields}
            isLast={isLast}
            index={index}
            maxStickyColumnIndex={maxStickyColumnIndex}
            loading={loading}
            layout={layout}
            parentValuePath={parentValuePath}
            project={project}
            record={record}
            rootDataType={rootDataType}
            rowLink={rowLink}
            recordQueryString={recordQueryString}
            scope={scope}
            showCardHeroImage={showCardHeroImage}
            transformRecordScope={transformRecordScope}
            viewRootPathname={viewRootPathname}
            ref={recordRef}
            data-index={dataIndex}
            data-group-key={dataGroupKey}
            draggable={draggable}
            columnWidths={columnWidths}
            surface={isDarkModeEnabled ? 'dark' : 'light'}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          >
            {children}
          </WrappedCollectionRecord>
        ),
      ),
    [
      WrappedCollectionRecord,
      actionButtons,
      dataList,
      dataType,
      editRelatedRecordButtons,
      editorMode,
      element,
      elementPath,
      fieldConfigs,
      formatRecordScope,
      groupByFields,
      layout,
      loading,
      maxStickyColumnIndex,
      parentValuePath,
      project,
      recordQueryString,
      rootDataType,
      rowLink,
      scope,
      showCardHeroImage,
      transformRecordScope,
      viewRootPathname,
      isDarkModeEnabled,
      selectedRows,
      setSelectedRows,
    ],
  );

  const RecordLayoutByEdge = useMemo(
    () =>
      forwardRef<
        HTMLTableRowElement,
        {
          className?: string;
          edge: RecordEdge;
          index: number;
          'data-index': number;
          'data-group-key'?: string;
          draggable: boolean;
          columnWidths: Record<number, number>;
        }
      >(
        (
          {
            className,
            edge,
            index,
            columnWidths,
            'data-index': dataIndex,
            'data-group-key': dataGroupKey,
            draggable,
          },
          recordLayoutRef,
        ) => {
          return (
            <CollectionRecordWrapper
              className={className}
              edge={edge}
              isLast={index === edges.length - 1}
              index={index}
              data-index={dataIndex}
              data-group-key={dataGroupKey}
              draggable={draggable}
              key={get(edge, 'node.id')}
              record={edge.node}
              ref={recordLayoutRef}
              columnWidths={columnWidths}
            />
          );
        },
      ),
    [CollectionRecordWrapper, edges],
  );

  const RecordLayoutByIndex = useMemo(
    () =>
      forwardRef<
        HTMLTableRowElement,
        {
          index: number;
          columnWidths: Record<number, number>;
          'data-index': number;
        }
      >(
        (
          {
            index,
            columnWidths = DEFAULT_COLUMN_WIDTHS,
            'data-index': dataIndex,
          },
          recordLayoutRef,
        ) => {
          const edge = edges[index];
          if (!edge) {
            return null;
          }

          return (
            <RecordLayoutByEdge
              index={index}
              edge={edge}
              data-index={dataIndex}
              draggable={false}
              ref={recordLayoutRef}
              columnWidths={columnWidths}
            />
          );
        },
      ),
    [RecordLayoutByEdge, edges],
  );

  const rows = useMemo(() => {
    if (groupByFields.length > 0) {
      return (
        <DraggableGroupView
          dataType={dataType}
          edges={edges}
          elementId={element.id}
          customFilters={customFilters}
          groupByFields={groupByFields}
          groupOptions={groupOptions}
          hideEmptyGroups={hideEmptyGroups}
          layout={layout || ROWS}
          limitPerGroup={limitPerGroup}
          fields={fieldConfigs}
          nodeQueryObject={nodeQueryObject}
          Row={CollectionRecordWrapper}
          enableDragAndDropEdit={enableDragAndDropEdit}
          bulkActionsEnabled={bulkActionsEnabled}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      );
    }

    return edges.map(
      (edge: any, index: any) =>
        edge && (
          <RecordLayoutByIndex
            key={edge.node?.uuid}
            columnWidths={DEFAULT_COLUMN_WIDTHS}
            data-index={index}
            index={index}
          />
        ),
    );
  }, [
    groupByFields,
    edges,
    dataType,
    element.id,
    customFilters,
    groupOptions,
    hideEmptyGroups,
    layout,
    limitPerGroup,
    fieldConfigs,
    nodeQueryObject,
    CollectionRecordWrapper,
    enableDragAndDropEdit,
    bulkActionsEnabled,
    selectedRows,
    setSelectedRows,
    RecordLayoutByIndex,
  ]);

  const isEventsBasedLayout = useMemo(
    () => EVENT_BASED_LAYOUTS.includes(layout),
    [layout],
  );

  const dropIndicator = useMemo(() => {
    if (layout === TABLE || layout === TABLE_FULL) {
      return (
        <tr className={`h-1 bg-${theme.brandColorGroups.primary}-400`}>
          <td colSpan={fieldConfigs.length} />
        </tr>
      );
    }

    return (
      <div className={`h-1 bg-${theme.brandColorGroups.primary}-400 w-full`}>
        &nbsp;
      </div>
    );
  }, [fieldConfigs.length, layout, theme.brandColorGroups.primary]);

  if (loading && !isEventsBasedLayout) {
    return (
      <div
        className={classNames('flex justify-center items-center w-full p-8', {
          'col-span-3 md:col-span-1': layout === CARDS,
        })}
      >
        <Loader size="sm" />
      </div>
    );
  }

  if (error && !rawData) {
    console.log('error:', error);
    return (
      <div
        className={classNames('flex justify-center items-center w-full p-6', {
          'col-span-3 md:col-span-1': layout === CARDS,
        })}
      >
        <em>Something went wrong</em>
      </div>
    );
  }

  if (layout === MAP) {
    return (
      <CollectionMap
        dataType={dataType}
        dataTypes={project.dataTypes}
        editorMode={editorMode}
        edges={edges}
        EmptyState={EmptyState}
        project={project}
        groupByFields={groupByFields}
        map={map}
        latField={mapLatField}
        lngField={mapLngField}
        pagination={
          dataList.showPagination &&
          pageInfo && (
            <RowPagination
              className={PAGINATION_STYLES[MAP](isDarkModeEnabled as boolean)}
              showSummary={false}
              pageInfo={pageInfo}
              totalCount={totalCount}
              setPaginationQuery={setPaginationQuery}
              currentLength={edges.length}
            />
          )
        }
        recordTitleField={get(recordConfig, 'title')}
        Row={CollectionRecordWrapper}
      />
    );
  }

  if (edges.length === 0 && !isEventsBasedLayout) {
    return <EmptyState />;
  }

  if (layout === CHARTS) {
    return (
      <CollectionCharts
        charts={charts}
        dataType={dataType}
        dataTypes={project.dataTypes}
        editorMode={editorMode}
        edges={edges}
        project={project}
      />
    );
  }

  if (isEventsBasedLayout) {
    const eventEdges = edges.filter(
      (edge: [{ id: string }] | RecordEdge[]) => !('id' in edge),
    );

    return (
      <CollectionEvents
        calendarView={calendarView}
        canUpdate={permissions.update}
        date={calendarDate}
        dataType={dataType}
        dateStart={dateStart}
        dateStartField={dateStartField}
        dateEndField={dateEndField}
        elementId={element.id}
        ganttDependency={ganttDependency}
        edges={eventEdges}
        groupByFields={groupByFields}
        loading={loading && dateStartField}
        nodeQueryObject={nodeQueryObject}
        project={project}
        qsSuffix={qsSuffix}
        recordTitleField={recordTitle ?? get(recordConfig, 'title')}
        rootPathname={viewRootPathname}
        Row={CollectionRecordWrapper}
        rowLink={rowLink}
        enableDragAndDropEdit={enableDragAndDropEdit}
        layout={layout}
      />
    );
  }

  if (layout === TABLE || layout === TABLE_FULL) {
    if (groupByFields.length > 0) {
      return (
        <GroupedVirtualizedLayout
          className={innerClassName}
          dataType={dataType}
          dropIndicator={dropIndicator}
          edges={edges}
          elementId={element.id}
          customFilters={customFilters}
          groupByFields={groupByFields}
          groupOptions={groupOptions}
          hideEmptyGroups={hideEmptyGroups}
          layout={layout || ROWS}
          limitPerGroup={limitPerGroup}
          fields={fieldConfigs}
          nodeQueryObject={nodeQueryObject}
          maxStickyColumnIndex={maxStickyColumnIndex}
          orderBy={orderBy}
          handleSetOrderBy={handleSetOrderBy}
          RecordLayoutByEdge={RecordLayoutByEdge}
          pageInfo={pageInfo}
          setPaginationQuery={setPaginationQuery}
          showPagination={dataList.showPagination && pageInfo}
          enableDragAndDropEdit={enableDragAndDropEdit}
          showTableSummary={showTableSummary}
          totalCount={totalCount}
          VirtualizedLayout={TableLayout}
          bulkActionsEnabled={bulkActionsEnabled}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          allRowsSelected={allRowsSelected}
          setSelectAllRows={setSelectAllRows}
        />
      );
    }

    return (
      <TableLayout
        className={innerClassName}
        count={edges.length}
        edges={edges}
        layout={layout}
        fieldConfigs={fieldConfigs}
        maxStickyColumnIndex={maxStickyColumnIndex}
        orderBy={orderBy}
        handleSetOrderBy={handleSetOrderBy}
        pageInfo={pageInfo}
        RecordLayoutByIndex={RecordLayoutByIndex}
        setPaginationQuery={setPaginationQuery}
        showPagination={dataList.showPagination && pageInfo}
        showTableSummary={showTableSummary}
        totalCount={totalCount}
        bulkActionsEnabled={bulkActionsEnabled}
        allRowsSelected={allRowsSelected}
        setSelectAllRows={setSelectAllRows}
      />
    );
  }

  if (layout === ROWS || layout === SPLIT) {
    if (groupByFields.length > 0) {
      return (
        <GroupedVirtualizedLayout
          bulkActionsEnabled={bulkActionsEnabled}
          className={innerClassName}
          dataType={dataType}
          dropIndicator={dropIndicator}
          edges={edges}
          elementId={element.id}
          customFilters={customFilters}
          groupByFields={groupByFields}
          groupOptions={groupOptions}
          hideEmptyGroups={hideEmptyGroups}
          layout={layout || ROWS}
          limitPerGroup={limitPerGroup}
          fields={fieldConfigs}
          nodeQueryObject={nodeQueryObject}
          maxStickyColumnIndex={maxStickyColumnIndex}
          orderBy={orderBy}
          handleSetOrderBy={handleSetOrderBy}
          RecordLayoutByEdge={RecordLayoutByEdge}
          pageInfo={pageInfo}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setPaginationQuery={setPaginationQuery}
          showPagination={dataList.showPagination && pageInfo}
          enableDragAndDropEdit={enableDragAndDropEdit}
          showTableSummary={showTableSummary}
          totalCount={totalCount}
          VirtualizedLayout={VirtualizedCollectionBody}
        />
      );
    }
    return (
      <VirtualizedCollectionBody
        className={innerClassName}
        count={edges.length}
        edges={edges}
        layout={layout}
        fieldConfigs={fieldConfigs}
        pageInfo={pageInfo}
        RecordLayoutByIndex={RecordLayoutByIndex}
        setPaginationQuery={setPaginationQuery}
        showPagination={dataList.showPagination && pageInfo}
        totalCount={totalCount}
      />
    );
  }

  const getPaginationStyles = PAGINATION_STYLES[layout];
  const paginationStyles =
    getPaginationStyles !== undefined
      ? getPaginationStyles(isDarkModeEnabled)
      : undefined;

  return (
    <>
      {rows}
      {dataList.showPagination && pageInfo && (
        <RowPagination
          className={paginationStyles}
          showSummary={layout === ROWS}
          pageInfo={pageInfo}
          totalCount={totalCount}
          setPaginationQuery={setPaginationQuery}
          currentLength={edges.length}
        />
      )}
    </>
  );
};

export default withTheme(CollectionDataBody);
