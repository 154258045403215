import React, { memo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { Theme, getColorShade } from '@noloco/components';
import { darkModeColors } from '../../../constants/darkModeColors';
import {
  ASC,
  DESC,
  OrderByDirection,
} from '../../../constants/orderByDirections';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import Checkbox from '../../Checkbox';

type AdditionalElement = {
  label: string;
  alignRight: boolean;
  isSortActive: boolean;
  onSort: (newSortDirection: any) => void;
};

type CollectionTableHeadProps = {
  className: string;
  sticky: boolean;
  maxStickyColumnIndex: number;
  additionalElements: AdditionalElement[];
  orderBy: { field: string; direction: OrderByDirection };
  theme: Theme;
  variables: any;
  columnWidths: Record<number, number>;
  setColumnWidths: (
    columnWidths: (
      currentWidthState: Record<number, number>,
    ) => { [x: number]: number },
  ) => void;
  bulkActionsEnabled?: boolean;
  allRowsSelected?: boolean;
  setSelectAllRows?: (allRowsSelected: boolean) => void;
};

const CollectionTableHead = memo(
  ({
    className,
    sticky,
    maxStickyColumnIndex,
    additionalElements,
    orderBy,
    theme,
    variables,
    columnWidths = {},
    setColumnWidths,
    bulkActionsEnabled = false,
    allRowsSelected = false,
    setSelectAllRows,
  }: CollectionTableHeadProps) => {
    const primaryColor = theme.brandColors.primary;
    const [isDarkModeEnabled] = useDarkMode();

    return (
      <thead
        className={classNames(
          className,
          {
            [`${
              isDarkModeEnabled
                ? darkModeColors.surfaces.elevation1
                : 'bg-gray-200 bg-opacity-25'
            }`]: !sticky,
            [`sticky top-0 ${
              isDarkModeEnabled
                ? darkModeColors.surfaces.elevation2
                : 'bg-gray-100'
            }  z-20 shadow-xs`]: sticky,
          },
          `text-left border-b rounded-tl-lg rounded-tr-lg ${
            isDarkModeEnabled
              ? `${darkModeColors.borders.one} ${darkModeColors.text.secondary}`
              : 'border-gray-200 text-gray-500'
          } text-xs tracking-wider uppercase font-medium`,
        )}
      >
        <tr>
          {!variables.title.hidden && (
            <th>
              <div className="pl-6 py-2 pr-3 whitespace-nowrap">
                {variables.title.label}
              </div>
            </th>
          )}
          {!variables.secondaryText.hidden && (
            <th>
              <div
                className={classNames('py-2 px-3 whitespace-nowrap', {
                  'pl-6': variables.title.hidden,
                })}
              />
            </th>
          )}
          {bulkActionsEnabled && setSelectAllRows && (
            <th
              className={classNames(
                'flex justify-center items-center h-10 w-10',
                {
                  'left-0 sticky z-20': !isNil(maxStickyColumnIndex),
                },
                isDarkModeEnabled
                  ? darkModeColors.surfaces.elevation2
                  : 'bg-gray-100 shadow-r',
              )}
            >
              <Checkbox
                className="flex w-3"
                size="sm"
                value={allRowsSelected}
                onChange={({
                  target: { checked },
                }: {
                  target: { checked: boolean };
                }) => setSelectAllRows(checked)}
              />
            </th>
          )}
          {additionalElements &&
            additionalElements.map((elementConfig: any, index: any) => (
              <th
                className={classNames(
                  {
                    [`${
                      bulkActionsEnabled ? 'left-10' : 'left-0'
                    } sticky z-20`]:
                      !isNil(maxStickyColumnIndex) &&
                      index <= maxStickyColumnIndex,
                    'table-fixed overflow-hidden': columnWidths[index],
                  },
                  `${
                    isDarkModeEnabled
                      ? darkModeColors.surfaces.elevation2
                      : 'bg-gray-100 shadow-r'
                  }`,
                )}
                ref={
                  setColumnWidths
                    ? (el: HTMLTableCellElement) => {
                        if (el && columnWidths[index] === undefined) {
                          setColumnWidths(
                            (currentWidthState: Record<number, number>) => ({
                              ...currentWidthState,
                              [index]: el.clientWidth,
                            }),
                          );
                        }
                      }
                    : undefined
                }
                style={{
                  minWidth: columnWidths[index]
                    ? `${columnWidths[index]}px`
                    : undefined,
                  maxWidth: columnWidths[index]
                    ? `${columnWidths[index]}px`
                    : undefined,
                }}
                key={elementConfig.label}
              >
                <div
                  className={classNames(
                    'flex items-center py-2 px-3 whitespace-nowrap',
                    {
                      'justify-end': elementConfig.alignRight,
                      'pl-6':
                        index === 0 &&
                        variables.title.hidden &&
                        variables.secondaryText.hidden &&
                        !bulkActionsEnabled,
                    },
                  )}
                >
                  {elementConfig.label}
                  {elementConfig.onSort && (
                    <div className="flex flex-col ml-3">
                      <button
                        className={classNames(
                          `hover:text-${getColorShade(
                            primaryColor,
                            500,
                          )} hover:opacity-100`,

                          elementConfig.isSortActive &&
                            orderBy &&
                            orderBy.direction === ASC
                            ? `text-${getColorShade(primaryColor, 500)}`
                            : 'opacity-50',
                        )}
                        onClick={() => elementConfig.onSort(ASC)}
                      >
                        <IconChevronUp size={13} strokeWidth={3} />
                      </button>
                      <button
                        className={classNames(
                          `-mt-1 hover:text-${getColorShade(
                            primaryColor,
                            500,
                          )} hover:opacity-100`,

                          elementConfig.isSortActive &&
                            orderBy &&
                            orderBy.direction === DESC
                            ? `text-${getColorShade(primaryColor, 500)}`
                            : 'opacity-50',
                        )}
                        onClick={() => elementConfig.onSort(DESC)}
                      >
                        <IconChevronDown size={13} strokeWidth={3} />
                      </button>
                    </div>
                  )}
                </div>
              </th>
            ))}
        </tr>
      </thead>
    );
  },
);

(CollectionTableHead as any).propTypes = {};

export default withTheme(CollectionTableHead);
