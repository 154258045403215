import { BOOLEAN, DATE } from '../constants/dataTypes';

export const getValueScope = (now: any) => ({
  OTHER: {
    empty: null,
  },
  [BOOLEAN]: {
    true: true,
    false: false,
  },

  [DATE]: {
    today: {
      now: now.toISO(),
      start: now.startOf('day').toISO(),
      end: now.endOf('day').toISO(),
    },
    week: {
      start: now.startOf('week').toISO(),
      end: now.endOf('week').toISO(),
    },
    month: {
      start: now.startOf('month').toISO(),
      end: now.endOf('month').toISO(),
    },
    year: {
      start: now.startOf('year').toISO(),
      end: now.endOf('year').toISO(),
    },
  },
});
