import first from 'lodash/first';
import get from 'lodash/get';
import range from 'lodash/range';
import snakeCase from 'lodash/snakeCase';
import { shade } from 'tailwind-color-generator/logics';
import tailwindColors from 'tailwindcss/colors';
import { Color, getColorShade } from '@noloco/components';
import { baseColors } from '../constants/tailwindStyles';
import { CUSTOM_PRIMARY_COLOR } from './styles';

export const COLOR_SHADES = range(0, 1000, 100).map((shade) =>
  Math.max(shade, 50),
);

export const getColorByIndex = (index: any, shade = 500) =>
  getColorShade(
    baseColors[baseColors.length - (index % baseColors.length) - 1],
    shade,
  );

const AIRTABLE_COLOR_MAP = {
  cyan: 'indigo',
};

export const mapAirtableColor = (color: string) => {
  const convertedColor = first(snakeCase(color).split('_'));
  if (baseColors.includes(convertedColor as Color)) {
    return convertedColor;
  }

  // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
  return AIRTABLE_COLOR_MAP[convertedColor];
};

export const getColorsForColorGroup = (colorGroup: any, customColors: any) => {
  if (colorGroup === CUSTOM_PRIMARY_COLOR && customColors) {
    return customColors;
  }

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return tailwindColors[colorGroup] || tailwindColors.blue;
};

export const formatHexCode = (str: any) =>
  (str && str.startsWith('#') ? str : `#${str || ''}`)
    .replace(/([^0-9a-fA-F#])/g, '')
    .substring(0, 7);

export const isValidHexCode = (str: any) =>
  str && /^#([0-9a-fA-F]{2}){3}$/.test(str);

const DEFAULT_SATURATION_FACTOR = 1.771968374684816;
const DEFAULT_LIGHT_FACTOR = 7.3903743315508015;

export const getCustomThemeColors = (baseColor: any) => {
  const shades = shade(
    baseColor,
    DEFAULT_SATURATION_FACTOR,
    DEFAULT_LIGHT_FACTOR,
  );
  return COLOR_SHADES.reduce(
    (acc, colorShadeName, index) => ({
      ...acc,
      [colorShadeName]: shades[index],
    }),
    {},
  );
};

export const restrictInputToHexCodes = (event: any) => {
  event.target.value = formatHexCode(event.target.value);
};

export const updateCustomShadeCssVariable = (shade: any, value: any) =>
  document.documentElement.style.setProperty(`--nlc-primary-${shade}`, value);

export const updateCustomThemeCssVariables = (customColors: any) => {
  COLOR_SHADES.forEach((colorShade) => {
    updateCustomShadeCssVariable(colorShade, customColors[colorShade]);
  });
};

export const getPrimaryColorGroup = (theme: any) => {
  const primaryColor = theme.brandColorGroups.primary;
  const defaultColorGroup = tailwindColors.blue;
  if (primaryColor === CUSTOM_PRIMARY_COLOR) {
    return get(theme, 'customColors.primary', defaultColorGroup);
  }

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return tailwindColors[primaryColor] || defaultColorGroup;
};
