import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Element from '../../../components/canvas/Element';
import withDataFields from '../../../components/canvas/withDataFields';
import { BUTTON, LINK } from '../../../constants/elements';
import cappedMemoize from '../../../utils/cappedMemoize';

const getElementPath = cappedMemoize(
  (basePath, index) => [...basePath, 'additionalElements', index, 'element'],
  {
    maxKeys: 200,
  },
);

export const AdditionalElement = memo(
  ({
    // @ts-expect-error TS(2339): Property 'dataId' does not exist on type '{}'.
    dataId,
    // @ts-expect-error TS(2339): Property 'element' does not exist on type '{... Remove this comment to see the full error message
    element,
    // @ts-expect-error TS(2339): Property 'elementConfig' does not exist on type '{... Remove this comment to see the full error message
    elementConfig,
    // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type '{}'.
    editorMode,
    // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{}'... Remove this comment to see the full error message
    elementPath,
    // @ts-expect-error TS(2339): Property 'record' does not exist on type '{}'.
    record,
    // @ts-expect-error TS(2339): Property 'index' does not exist on type '{}'.
    index,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
    project,
    // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
    scope,
  }) => {
    if (elementConfig.render) {
      return elementConfig.render(dataId, scope, record);
    }

    const ElementWithDataFields = withDataFields(
      Element,
      element,
      project,
      editorMode,
    );

    const preventClickPropagation = [BUTTON, LINK].includes(
      elementConfig.element.type,
    );

    return (
      <ElementWithDataFields
        element={elementConfig.element}
        editorMode={editorMode}
        onClick={
          preventClickPropagation ? (e: any) => e.stopPropagation() : undefined
        }
        key={getElementPath(elementPath, index).join('.')}
        elementPath={getElementPath(elementPath, index)}
        project={project}
        scope={scope}
      />
    );
  },
);

const AdditionalElements = memo(
  ({
    // @ts-expect-error TS(2339): Property 'additionalElements' does not exist on ty... Remove this comment to see the full error message
    additionalElements,
    // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
    className,
    // @ts-expect-error TS(2339): Property 'dataId' does not exist on type '{}'.
    dataId,
    // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type '{}'.
    editorMode,
    // @ts-expect-error TS(2339): Property 'innerClassName' does not exist on type '... Remove this comment to see the full error message
    innerClassName,
    // @ts-expect-error TS(2339): Property 'record' does not exist on type '{}'... Remove this comment to see the full error message
    record,
    // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{}'... Remove this comment to see the full error message
    elementPath,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
    project,
    // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
    scope,
  }) => {
    return (
      additionalElements &&
      additionalElements.length > 0 && (
        <div className={className}>
          {additionalElements.map((elementConfig: any, index: any) => (
            <div
              className={classNames(
                {
                  'mb-4': index !== additionalElements.length,
                  'col-span-2': elementConfig.fullWidth,
                },
                innerClassName,
              )}
              id={elementConfig.id || elementConfig.element.id}
              key={elementConfig.id || elementConfig.element.id}
            >
              {elementConfig.label && (
                <span className="font-medium text-gray-500 tracking-wider">
                  {elementConfig.label}
                </span>
              )}

              <AdditionalElement
                // @ts-expect-error TS(2322): Type '{ dataId: any; elementConfig: any; editorMod... Remove this comment to see the full error message
                dataId={dataId}
                elementConfig={elementConfig}
                editorMode={editorMode}
                key={getElementPath(elementPath, index).join('.')}
                elementPath={elementPath}
                record={record}
                index={index}
                project={project}
                scope={scope}
              />
            </div>
          ))}
        </div>
      )
    );
  },
);

(AdditionalElements as any).propTypes = {
  disableLabels: PropTypes.bool,
};

(AdditionalElements as any).defaultProps = {
  disableLabels: false,
};

export default AdditionalElements;
