import React, { useState } from 'react';
import loadable from '@loadable/component';
import classNames from 'classnames';
import {
  AUDIO_MIMETYPES,
  IMAGE_MIMETYPES,
  VIDEO_MIMETYPES,
} from '../../../constants/mimetypes';
import AttachmentIcon from './AttachmentIcon';

const FilePreviewModal = loadable(() => import('../forms/FilePreviewModal'));

const getPreview = (attachment: any, bg: any) => {
  if (AUDIO_MIMETYPES.includes(attachment.mimetype)) {
    return (
      <div
        className={classNames(
          bg,
          'flex items-center rounded-lg m-1 p-4 max-w-full',
        )}
      >
        <AttachmentIcon
          mimetype={attachment.mimetype}
          size={24}
          className="opacity-50 mr-2 inline-block"
        />
        <audio controls={true}>
          <source src={attachment.url} type={attachment.mimetype} />
          Your browser does not support the audio element.
        </audio>
      </div>
    );
  }

  if (IMAGE_MIMETYPES.includes(attachment.mimetype)) {
    return (
      <div className={classNames(bg, 'rounded-lg m-1 p-2 w-10/12')}>
        <img
          src={attachment.url}
          alt={attachment.name}
          className="w-full h-auto rounded-lg"
        />
      </div>
    );
  }

  if (VIDEO_MIMETYPES.includes(attachment.mimetype)) {
    return (
      <div className={classNames(bg, 'rounded-lg m-1 p-2 w-10/12')}>
        <video
          src={attachment.url}
          className="w-full h-auto rounded-lg"
          controls={true}
        />
      </div>
    );
  }

  return (
    <div className={classNames(bg, 'rounded-lg m-1 p-4 max-w-full truncate')}>
      <AttachmentIcon
        mimetype={attachment.mimetype}
        size={18}
        className="opacity-50 mr-2 inline-block"
      />
      <span className="text-gray-700">{attachment.name}</span>
    </div>
  );
};

const AttachmentPreview = ({ attachment, bg, className }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        className={classNames('cursor-pointer', className)}
        onClick={() => setIsOpen(true)}
      >
        {getPreview(attachment, bg)}
      </div>
      {isOpen && (
        <FilePreviewModal
          // @ts-expect-error TS(2322): Type '{ files: any[]; onClose: () => void; }' is n... Remove this comment to see the full error message
          files={[attachment]}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

AttachmentPreview.defaultProps = {
  bg: 'bg-gray-100',
};

export default AttachmentPreview;
