import React from 'react';
import { Button } from '@noloco/components';
import { AUDIO, IMAGE, VIDEO } from '../../../constants/fileTypes';
import {
  APPLICATION_PDF,
  AUDIO_MPEG,
  IMAGE_HEIC,
  MS_OFFICE_TYPES,
  TEXT_MIMETYPES,
  VIDEO_MOV,
} from '../../../constants/mimetypes';
import { getText } from '../../../utils/lang';
import DocumentPreviewIframe from '../forms/DocumentPreviewIframe';
import FileDownloadButton from '../forms/FileDownloadButton';
import AttachmentIcon from '../messaging/AttachmentIcon';

const FilePreviewElement = ({ file }: any) => {
  const { fileType, mimetype, url, name } = file;
  if (fileType === IMAGE && mimetype !== IMAGE_HEIC) {
    return (
      <img
        src={url}
        alt={name}
        className="mx-auto rounded-lg max-h-full max-w-full"
      />
    );
  }

  if (fileType === AUDIO) {
    return (
      <div className="flex items-center rounded-lg m-1 p-4 max-w-full">
        <AttachmentIcon
          mimetype={AUDIO_MPEG}
          size={24}
          className="opacity-50 mr-2 inline-block"
        />
        <audio controls={true}>
          <source src={url} type={mimetype} />
          Your browser does not support the audio element.
        </audio>
      </div>
    );
  }

  if (fileType === VIDEO) {
    if (mimetype === VIDEO_MOV) {
      // Prevent files from being downloaded
      return (
        <video
          className="mx-auto bg-black rounded-lg max-h-full h-full max-w-full border w-full"
          autoPlay={false}
          muted={true}
          controls={false}
        >
          <source src={url} type={mimetype} />
        </video>
      );
    }

    return (
      <iframe
        src={url}
        className="mx-auto bg-black rounded-lg max-h-full h-full max-w-full border w-full"
        title="video-iframe"
        allowFullScreen
        frameBorder="0"
      ></iframe>
    );
  }

  if (mimetype === APPLICATION_PDF) {
    return (
      <iframe
        id="pdf-iframe"
        className="w-full h-full border-0 overflow-hidden rounded-lg"
        title="pdf-iframe"
        src={file.url}
        frameBorder="0"
        key={file.url}
      />
    );
  }

  if (
    mimetype &&
    (MS_OFFICE_TYPES.includes(mimetype.toLowerCase()) ||
      TEXT_MIMETYPES.includes(mimetype))
  ) {
    return <DocumentPreviewIframe key={file.url} file={file} />;
  }

  return (
    <div className="truncate block pt-2 pb-2">
      <AttachmentIcon
        mimetype={mimetype || APPLICATION_PDF}
        size={18}
        className="opacity-50 mr-2 inline-block"
      />
      <span className="text-gray-700">{name}</span>
      <FileDownloadButton className="mt-4 mx-auto" file={file} is={Button}>
        <span className="ml-2">{getText('elements.FILE_UPLOAD.download')}</span>
      </FileDownloadButton>
    </div>
  );
};

export default FilePreviewElement;
