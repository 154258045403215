import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconExternalLink } from '@tabler/icons-react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Button, Loader } from '@noloco/components';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { getStripeCustomerPortalQueryString } from '../../../queries/project';
import useScopeUser from '../../../utils/hooks/useScopeUser';
import { getText } from '../../../utils/lang';

const StripeCustomerPortalButton = ({ project }: any) => {
  const user = useScopeUser();
  const customerId = get(user, 'company.customerPaymentsId');

  const [loading, setLoading] = useState(false);
  const errorAlert = useGraphQlErrorAlert();
  const [createCustomerPortalSession] = useMutation(
    gql`
      ${getStripeCustomerPortalQueryString()}
    `,
    {
      context: {
        projectQuery: true,
        projectName: project.name,
      },
    },
  );

  const onOpenStripeCustomerPortal = useCallback(() => {
    setLoading(true);
    createCustomerPortalSession({
      variables: {
        returnUrl: document.location.href,
      },
    })
      .then((mutationData) => {
        const session = mutationData.data.createCustomerPortalSession;
        document.location = session.successUrl;
      })
      .catch((e) => {
        setLoading(false);
        errorAlert(getText('core.BILLING.customerPortal.error'), e);
      });
  }, [createCustomerPortalSession, errorAlert]);

  if (!customerId) {
    return null;
  }

  return (
    customerId && (
      <Button
        className="flex items-center"
        onClick={onOpenStripeCustomerPortal}
      >
        {!loading ? (
          <>
            <span> {getText('core.BILLING.customerPortal.button')}</span>
            <IconExternalLink className="ml-2 opacity-75" size={16} />
          </>
        ) : (
          <Loader size="sm" />
        )}
      </Button>
    )
  );
};

export default StripeCustomerPortalButton;
