import { AUDIO, DOCUMENT, IMAGE, TEXT, VIDEO } from './fileTypes';

export const APPLICATION_JSON = 'application/json';
export const APPLICATION_PDF = 'application/pdf';
export const APPLICATION_XML = 'application/xml';
export const AUDIO_3GPP = 'audio/3gpp';
export const AUDIO_3GPP2 = 'audio/3gpp2';
export const AUDIO_AAC = 'audio/aac';
export const AUDIO_MPEG = 'audio/mpeg';
export const AUDIO_WAV = 'audio/wav';
export const AUDIO_WEBM = 'audio/webm';
export const IMAGE_BMP = 'image/bmp';
export const IMAGE_GIF = 'image/gif';
export const IMAGE_HEIC = 'image/heic';
export const IMAGE_JPEG = 'image/jpeg';
export const IMAGE_MS_ICON = 'image/vnd.microsoft.icon';
export const IMAGE_PNG = 'image/png';
export const IMAGE_SVG = 'image/svg+xml';
export const IMAGE_WEBP = 'image/webp';
export const MESSAGE_RFC_822 = 'message/rfc822';
export const TEXT_CSS = 'text/css';
export const TEXT_CSV = 'text/csv';
export const TEXT_XML = 'text/xml';
export const TEXT_HTML = 'text/html';
export const TEXT_JAVASCRIPT = 'text/javascript';
export const TEXT_PLAIN = 'text/plain';
export const VIDEO_3GPP = 'video/3gpp';
export const VIDEO_3GPP2 = 'video/3gpp2';
export const VIDEO_MPEG = 'video/mpeg';
export const VIDEO_MP4 = 'video/mp4';
export const VIDEO_MOV = 'video/quicktime';
export const VIDEO_WEBM = 'video/webm';
export const ZIP = 'application/zip';
export const SEVEN_ZIP = 'application/x-7z-compressed';

export const MS_DOC = 'application/msword';
export const MS_DOT = 'application/msword';

export const MS_DOCX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const MS_DOTX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template';
export const MS_DOCM = 'application/vnd.ms-word.document.macroEnabled.12';
export const MS_DOTM = 'application/vnd.ms-word.template.macroEnabled.12';

export const MS_XLS = 'application/vnd.ms-excel';
export const MS_XLT = 'application/vnd.ms-excel';
export const MS_XLA = 'application/vnd.ms-excel';

export const MS_XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const MS_XLTX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template';
export const MS_XLSM = 'application/vnd.ms-excel.sheet.macroEnabled.12';
export const MS_XLTM = 'application/vnd.ms-excel.template.macroEnabled.12';
export const MS_XLAM = 'application/vnd.ms-excel.addin.macroEnabled.12';
export const MS_XLSB = 'application/vnd.ms-excel.sheet.binary.macroEnabled.12';

export const MS_PPT = 'application/vnd.ms-powerpoint';
export const MS_POT = 'application/vnd.ms-powerpoint';
export const MS_PPS = 'application/vnd.ms-powerpoint';
export const MS_PPA = 'application/vnd.ms-powerpoint';

export const MS_PPTX =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation';
export const MS_POTX =
  'application/vnd.openxmlformats-officedocument.presentationml.template';
export const MS_PPSX =
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow';
export const MS_PPAM = 'application/vnd.ms-powerpoint.addin.macroEnabled.12';
export const MS_PPTM =
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12';
export const MS_POTM = 'application/vnd.ms-powerpoint.template.macroEnabled.12';
export const MS_PPSM =
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12';

export const MS_OFFICE_TYPES = [
  MS_DOC,
  MS_DOT,
  MS_DOCX,
  MS_DOTX,
  MS_DOCM,
  MS_DOTM,
  MS_XLS,
  MS_XLT,
  MS_XLA,
  MS_XLSX,
  MS_XLTX,
  MS_XLSM,
  MS_XLTM,
  MS_XLAM,
  MS_XLSB,
  MS_PPT,
  MS_POT,
  MS_PPS,
  MS_PPA,
  MS_PPTX,
  MS_POTX,
  MS_PPSX,
  MS_PPAM,
  MS_PPTM,
  MS_POTM,
  MS_PPSM,
].map((mimetype) => mimetype.toLowerCase());

export const ACCEPTED_MIMETYPES = [
  APPLICATION_JSON,
  APPLICATION_PDF,
  APPLICATION_XML,
  AUDIO_3GPP,
  AUDIO_3GPP2,
  AUDIO_AAC,
  AUDIO_MPEG,
  AUDIO_WAV,
  AUDIO_WEBM,
  IMAGE_BMP,
  IMAGE_GIF,
  IMAGE_HEIC,
  IMAGE_JPEG,
  IMAGE_MS_ICON,
  IMAGE_PNG,
  IMAGE_SVG,
  IMAGE_WEBP,
  MESSAGE_RFC_822,
  ...MS_OFFICE_TYPES,
  TEXT_CSS,
  TEXT_CSV,
  TEXT_HTML,
  TEXT_JAVASCRIPT,
  TEXT_PLAIN,
  TEXT_XML,
  VIDEO_3GPP,
  VIDEO_3GPP2,
  VIDEO_MPEG,
  VIDEO_MP4,
  VIDEO_MOV,
  VIDEO_WEBM,
  ZIP,
  SEVEN_ZIP,
].map((mimetype) => mimetype.toLowerCase());

export const AUDIO_MIMETYPES = [
  AUDIO_3GPP,
  AUDIO_3GPP2,
  AUDIO_AAC,
  AUDIO_MPEG,
  AUDIO_WAV,
  AUDIO_WEBM,
];

export const IMAGE_MIMETYPES = [
  IMAGE_BMP,
  IMAGE_GIF,
  IMAGE_HEIC,
  IMAGE_JPEG,
  IMAGE_MS_ICON,
  IMAGE_PNG,
  IMAGE_SVG,
  IMAGE_WEBP,
];

export const VIDEO_MIMETYPES = [
  VIDEO_3GPP,
  VIDEO_3GPP2,
  VIDEO_MPEG,
  VIDEO_WEBM,
  VIDEO_MP4,
  VIDEO_MOV,
];

export const DOCUMENT_MIMETYPES = [
  APPLICATION_PDF,
  APPLICATION_XML,
  ...MS_OFFICE_TYPES,
  MESSAGE_RFC_822,
];

export const TEXT_MIMETYPES = [
  APPLICATION_JSON,
  TEXT_CSS,
  TEXT_CSV,
  TEXT_HTML,
  TEXT_JAVASCRIPT,
  TEXT_PLAIN,
  TEXT_XML,
];

export const FILE_TYPE_TO_MIMETYPES = {
  [AUDIO]: AUDIO_MIMETYPES,
  [IMAGE]: IMAGE_MIMETYPES,
  [VIDEO]: VIDEO_MIMETYPES,
  [DOCUMENT]: DOCUMENT_MIMETYPES,
  [TEXT]: TEXT_MIMETYPES,
};

export const CSV_MIMETYPES = [TEXT_CSV, MS_XLS];
