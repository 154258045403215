import React, { memo } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getColorShade } from '@noloco/components';
import useScopeVariables from '../../../utils/hooks/useScopeVariables';
import Link from '../../Link';

const CollectionConversation = memo(
  ({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type '{}'.
    index,
    // @ts-expect-error TS(2339): Property 'additionalElementsRenderer' does not exi... Remove this comment to see the full error message
    additionalElementsRenderer,
    // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type '{}'.
    editorMode,
    // @ts-expect-error TS(2339): Property 'element' does not exist on type '{}'.
    element,
    // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{}'... Remove this comment to see the full error message
    elementPath,
    // @ts-expect-error TS(2339): Property 'theme' does not exist on type '{}'.
    theme,
    // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
    scope,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
    project,
    // @ts-expect-error TS(2339): Property 'rowLink' does not exist on type '{}'.
    rowLink,
    // @ts-expect-error TS(2339): Property 'rawVariables' does not exist on type '{}... Remove this comment to see the full error message
    rawVariables,
  }) => {
    const secondaryColor = theme.brandColorGroups.secondary;
    const primaryColor = theme.brandColorGroups.primary;
    const {
      link = rowLink,
      variables: { title, image, description, secondaryText } = rawVariables,
    } = useScopeVariables(scope, element, project, elementPath);

    const additionalElements = additionalElementsRenderer(
      scope,
      'w-full grid grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-4 mt-8',
      'flex flex-col min-w-0',
    );

    return (
      <Link
        className="px-8 py-4 flex-col items-center w-full hover:bg-gray-100 hover:bg-opacity-50"
        // @ts-expect-error TS(2322): Type '{ children: Element; className: string; edit... Remove this comment to see the full error message
        editorMode={editorMode}
        link={link}
        scope={scope}
      >
        <div className="flex items-start w-full">
          {!image.hidden && (
            <div className="w-10 flex flex-shrink-0 min-w-0">
              <Box
                is="div"
                className={classNames(
                  'sm:mr-4 sm:mb-4 w-10 h-10 rounded-full sm:rounded-0 text-white bg-center bg-cover',
                )}
                bg={getColorShade(secondaryColor, 400)}
                style={{
                  backgroundImage: `url(${
                    image.value.src ||
                    `https://picsum.photos/id/${(index % 10) + 1020}/600/600`
                  })`,
                }}
              >
                <span className="block w-10 h-10" />
              </Box>
            </div>
          )}
          <div className="px-4 flex-grow flex-shrink min-w-0 flex flex-col justify-center max-w-full">
            <Box
              is="span"
              className="text-lg font-medium"
              text={getColorShade(primaryColor, 800)}
            >
              {title.value}
            </Box>
            {!description.hidden && (
              <span className="text-lg text-gray-400">{description.value}</span>
            )}
            {additionalElements}
          </div>
          {!secondaryText.hidden && (
            <div className="flex flex-shrink min-w-0">
              <span className="text-sm text-gray-400 whitespace-nowrap">
                {secondaryText.value}
              </span>
            </div>
          )}
        </div>
      </Link>
    );
  },
);

(CollectionConversation as any).propTypes = {};

(CollectionConversation as any).defaultProps = {
  index: 0,
};

export default withTheme(CollectionConversation);
