import React from 'react';
import classNames from 'classnames';
import FormField from '../form/FormField';
import LoginSubmit from './LoginSubmit';
import SimpleLayout from './SimpleLayout';

const CardLogin = ({
  className,
  children,
  errors,
  logoUrl,
  emailLabel,
  onSubmit,
  buttonText,
  forgotText,
  passwordLabel,
  registerText,
  rememberLabel,
  titleText,
  email,
  password,
  rememberMe,
  setEmail,
  setPassword,
  setRememberMe,
  surface,
}: any) => {
  return (
    <SimpleLayout
      errors={errors}
      logoUrl={logoUrl}
      onSubmit={onSubmit}
      subTitleText={registerText}
      titleText={titleText}
    >
      <div
        className={classNames(
          className,
          'bg-white p-7 sm:px-4 mt-6 shadow overflow-hidden sm:rounded-none rounded-lg',
        )}
      >
        <input type="hidden" name="remember" value="true" />
        <div>
          <FormField
            aria-label="Email address"
            name="email"
            type="email"
            onChange={({ target: { value } }: any) => setEmail(value)}
            required
            label={emailLabel}
            placeholder=""
            value={email}
            surface={surface}
          />
        </div>
        <div className="mt-3">
          <FormField
            aria-label="Password"
            name="password"
            type="password"
            onChange={({ target: { value } }: any) => setPassword(value)}
            required
            label={passwordLabel}
            placeholder=""
            value={password}
            surface={surface}
          />
        </div>
        <LoginSubmit
          buttonText={buttonText}
          forgotText={forgotText}
          rememberMe={rememberMe}
          rememberLabel={rememberLabel}
          setRememberMe={setRememberMe}
        />
        {children}
      </div>
    </SimpleLayout>
  );
};

export default CardLogin;
