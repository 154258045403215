import React from 'react';

const BaseExample = ({
  children,
  showGradient = true,
}: {
  children: any;
  showGradient?: boolean;
}) => (
  <div className="flex flex-col max-h-screen-75 md:max-h-screen overflow-hidden relative p-2 max-w-screen-xl mx-auto my-16">
    {children}
    {showGradient && (
      <div
        className="absolute z-50 bottom-0 left-0 right-0 h-32 bg-gradient-to-b
    from-transparent to-white"
      />
    )}
  </div>
);

export default BaseExample;
