import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import {
  BELOW,
  BELOW_SOLID,
  CONNECTED,
  ICON_TOOLTIP,
  INLINE_TOOLTIP,
} from './errorPositions';

const errorStyles = (bg: any) => ({
  [BELOW_SOLID]: {
    rounded: 'lg',
    text: ['sm', 'white'],
    font: 'bold',
    p: { x: 4, y: 2 },
    bg,
    m: { t: 2 },
  },

  [CONNECTED]: {
    m: { t: 0 },
    roundedB: true,
    text: ['sm', 'white'],
    font: 'bold',
    p: { x: 4, y: 2 },
    bg,
  },

  [BELOW]: { m: { t: 0.5, l: 1 }, text: ['sm', bg], font: 'bold' },

  [INLINE_TOOLTIP]: {
    rounded: 'lg',
    text: ['sm', 'white'],
    font: 'bold',
    p: { x: 4, y: 2 },
    bg,
  },

  [ICON_TOOLTIP]: {
    rounded: 'lg',
    text: ['sm', 'white'],
    font: 'bold',
    p: { x: 4, y: 2 },
    bg,
  },
});

type ErrorTextProps = {
  className?: string;
  theme: {};
  type?: any; // TODO: oneOfWithDefault(errorPositions)
};

const ErrorText = forwardRef<any, ErrorTextProps>(
  ({ children, className, theme, type, ...rest }, ref) => {
    const badgeColor = (theme as any).brandColors['secondary'];
    return (
      <Box
        data-testid="error-text"
        {...rest}
        className={classNames(className, {
          'tooltip left bottom-0': type === INLINE_TOOLTIP,
        })}
        {...errorStyles(badgeColor)[type]}
        ref={ref}
      >
        {children}
      </Box>
    );
  },
);

ErrorText.defaultProps = {
  className: '',
  type: BELOW_SOLID,
};

export default withTheme(ErrorText);
