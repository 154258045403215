import {
  BUSINESS,
  FREE,
  PRO,
  PROFESSIONAL,
  SCALE,
  STARTER,
  TEAM,
} from './accountPlans';

export const DEPRECATED_PLANS = [STARTER, PROFESSIONAL];

export const FLAT_PLANS = [STARTER, PROFESSIONAL, TEAM, SCALE];

export const SEAT_BASED_PLANS = [PRO, BUSINESS];

export const CURRENT_PLANS = [FREE, ...SEAT_BASED_PLANS];
