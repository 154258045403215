import { TEXT } from '../constants/dataTypes';

class StateItem {
  id: string;
  path: string;
  source: string;
  dataType: string;
  display: string;
  args: Record<string, any> | undefined;

  constructor({
    id,
    path,
    source,
    dataType = TEXT,
    display,
    args,
  }: {
    id: string;
    path: string;
    source: string;
    dataType: string;
    display: string;
    args?: Record<string, any> | undefined;
  }) {
    this.path = path;
    this.id = id;
    this.source = source;
    this.dataType = dataType;
    this.display = display;
    this.args = args;
  }
}

export default StateItem;
