import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconMessage } from '@tabler/icons-react';
import classNames from 'classnames';
import reverse from 'lodash/fp/reverse';
import set from 'lodash/fp/set';
import uniqBy from 'lodash/fp/uniqBy';
import get from 'lodash/get';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useSelector } from 'react-redux';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ScrollToBottom from 'react-scroll-to-bottom';
import { Loader, getColorShade } from '@noloco/components';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import MessageInput from '../../../components/MessageInput';
import RightPanel from '../../../components/RightPanel';
import { darkModeColors } from '../../../constants/darkModeColors';
import { IS_SSR } from '../../../constants/env';
import MessagesIcon from '../../../img/MessagesIcon';
import {
  NOLOCO_COMMENTS_COLLECTION,
  getCommentsQuery,
  getCreateCommentMutation,
} from '../../../queries/comments';
import { editorModeSelector } from '../../../selectors/elementsSelectors';
import { addDataItemToCollectionCache } from '../../../utils/apolloCache';
import useActivePollInterval from '../../../utils/hooks/useActivePollInterval';
import useCacheQuery from '../../../utils/hooks/useCacheQuery';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import usePrevious from '../../../utils/hooks/usePrevious';
import useRouter from '../../../utils/hooks/useRouter';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { getText } from '../../../utils/lang';
import RecordComment from './RecordComment';

const LIMIT = 20;

const MAX_PORTAL_WIDTH = 1280;

const LANG_KEY = 'core.COMMENT';

const canSubmit = (cleanText: any, filesToSend: any) =>
  (cleanText && cleanText.trim()) || filesToSend.length > 0;

const RecordComments = ({
  dataType,
  project,
  openByDefault,
  record,
  theme,
}: any) => {
  const { pushQueryParams } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [filesToSend, setFilesToSend] = useState([]);
  const [commentText, setCommentText] = useState('');
  const editorMode = useSelector(editorModeSelector);

  const secondaryColor = theme.brandColorGroups.secondary;

  const errorAlert = useGraphQlErrorAlert();

  const windowSize = useWindowSize();

  const [isDarkModeEnabled] = useDarkMode();

  const commentQuery = useMemo(() => getCommentsQuery(dataType, LIMIT), [
    dataType,
  ]);
  const createMutation = useMemo(() => getCreateCommentMutation(dataType), [
    dataType,
  ]);

  const onCloseComments = useCallback(() => {
    pushQueryParams({ _comments: openByDefault ? false : undefined });
  }, [openByDefault, pushQueryParams]);

  const context = useMemo(
    () => ({
      projectQuery: true,
      projectName: project.name,
    }),
    [project.name],
  );

  const jitteredPollInterval = useActivePollInterval();

  const {
    client: apolloClient,
    data: recordData,
    fetchMore,
    loading,
  } = useCacheQuery(commentQuery, {
    variables: {
      uuid: record.uuid,
      after: null,
    },
    errorPolicy: 'all',
    pollInterval: jitteredPollInterval,
    context,
  });

  const cachedComments = useMemo(
    () =>
      get(
        recordData,
        [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'edges'],
        [],
      ).map((edge: any) => edge.node),
    [dataType.apiName, recordData],
  );

  const cachedPageInfo = useMemo(
    () =>
      get(
        recordData,
        [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'pageInfo'],
        {},
      ),
    [dataType.apiName, recordData],
  );

  const [comments, setComments] = useState(cachedComments);
  const [pageInfo, setPageInfo] = useState(cachedPageInfo);

  const previousRecordId = usePrevious(record.id);
  const sameRecord = previousRecordId === record.id;

  useEffect(() => {
    if (!sameRecord) {
      setComments(cachedComments);
      setPageInfo(cachedPageInfo);
    } else if (cachedComments.length > comments.length) {
      setComments(uniqBy('id', cachedComments));
      setPageInfo(cachedPageInfo);
    } else {
      const firstCachedId = get(cachedComments, [0, 'id']);
      const firstStateId = get(comments, [0, 'id']);
      if (firstCachedId !== firstStateId) {
        setComments(uniqBy('id', [...cachedComments, ...comments]));
      }
    }
  }, [
    comments,
    cachedComments,
    previousRecordId,
    record.id,
    sameRecord,
    cachedPageInfo,
  ]);

  const [loaderRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: pageInfo.hasNextPage,
    onLoadMore: () => {
      fetchMore({
        variables: { uuid: record.uuid, after: pageInfo.endCursor },
        // @ts-expect-error TS(2345): Argument of type '{ variables: { uuid: any; after:... Remove this comment to see the full error message
        disabled: !pageInfo,
        updateQuery: (previousResults, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResults;
          }

          const previousComments = get(
            previousResults,
            [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'edges'],
            [],
          );

          const newComments = get(
            fetchMoreResult,
            [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'edges'],
            [],
          );

          const newPageInfo = get(
            fetchMoreResult,
            [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'pageInfo'],
            {},
          );

          const recordWithEdges = set(
            [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'edges'],
            uniqBy('node.id', [...previousComments, ...newComments]),
            recordData,
          );

          return set(
            [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'pageInfo'],
            newPageInfo,
            recordWithEdges,
          );
        },
      });
    },
    disabled: false,
    rootMargin: '100px 0px 0px 0px',
  });

  const [createComment] = useMutation(createMutation, { context });

  const onChange = useCallback((nextValue: any) => {
    setCommentText(nextValue);
  }, []);

  const addNewCommentToCache = useCallback(
    (newCommentData: any) => {
      try {
        addDataItemToCollectionCache(
          newCommentData,
          apolloClient,
          commentQuery,
          'Comment',
          {
            uuid: record.uuid,
            after: null,
          },
          {
            collectionPathInput: `${dataType.apiName}.${NOLOCO_COMMENTS_COLLECTION}`,
          },
        );
      } catch (e) {
        console.error(e);
      }
    },
    [apolloClient, commentQuery, dataType.apiName, record.uuid],
  );

  const createNewComment = useCallback(
    (text: any, attachments: any) =>
      createComment({
        variables: {
          text: text ? text.trim() : null,
          attachments,
          recordId: record.id,
        },
      }).then(({ data: newCommentData }) => {
        if (newCommentData.createComment) {
          addNewCommentToCache(newCommentData);
        }
      }),
    [addNewCommentToCache, createComment, record.id],
  );

  const onCreateNewMessage = useCallback(
    async (event: any) => {
      event.preventDefault();
      if (!isLoading) {
        setIsLoading(true);
      }

      const currentValue = commentText
        // @ts-expect-error TS(2550): Property 'replaceAll' does not exist on type 'stri... Remove this comment to see the full error message
        .replaceAll('<br>', '\n')
        .replaceAll('&nbsp;', ' ')
        .trim();
      if (canSubmit(currentValue, filesToSend)) {
        try {
          await createNewComment(
            currentValue,
            filesToSend.map((file) => file[0]),
          );
        } catch (error) {
          errorAlert(getText('core.MESSAGING.errors.send'), error);
          setIsLoading(false);
          return;
        }
      }
      setCommentText('');

      setFilesToSend([]);
      setIsLoading(false);
    },
    [commentText, createNewComment, errorAlert, filesToSend, isLoading],
  );

  const totalCount = get(record, `${NOLOCO_COMMENTS_COLLECTION}.totalCount`, 0);

  // If it's server-side don't render the ScrollToBottom container because it causes problems
  const CommentContainer = IS_SSR ? 'div' : ScrollToBottom;

  return (
    <RightPanel
      className="flex"
      footer={
        <div
          className={classNames(
            `flex justify-end  border-t ${
              isDarkModeEnabled
                ? `${darkModeColors.surfaces.elevation0} ${darkModeColors.borders.one} ${darkModeColors.text.primary}`
                : 'bg-white'
            }`,
          )}
        >
          <MessageInput
            dataTypes={project.dataTypes}
            files={filesToSend}
            isLoading={isLoading}
            mentions={true}
            onChange={onChange}
            onChangeFiles={setFilesToSend}
            onSubmit={onCreateNewMessage}
            placeholder={getText(LANG_KEY, 'new.placeholder')}
            projectName={project.name}
            shouldFocus={!openByDefault && !editorMode}
            value={commentText}
          />
        </div>
      }
      onClose={onCloseComments}
      title={
        <div className="flex items-center">
          <IconMessage className="mr-2" size={18} />
          <span>{getText({ context: totalCount }, LANG_KEY, 'title')}</span>
        </div>
      }
      rootSelector={
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        windowSize.width > MAX_PORTAL_WIDTH ? '.right-sidebar' : undefined
      }
      usePortal={true}
    >
      <div className="flex flex-col flex-grow mb-2 h-full">
        {comments.length > 0 && (
          <CommentContainer
            className={`flex flex-col h-full max-h-full overflow-y-auto mx-auto w-full p-0 ${
              isDarkModeEnabled ? darkModeColors.text.primary : ''
            }`}
            followButtonClassName="hidden"
            scrollViewClassName="p-4 relative space-y-4"
          >
            {pageInfo && pageInfo.hasNextPage && (
              <div className="w-full py-4 flex justify-center" ref={loaderRef}>
                <Loader size="sm" />
              </div>
            )}
            {reverse(comments).map((comment: any) => (
              // @ts-expect-error TS(2322): Type '{ comment: any; key: any; }' is not assignab... Remove this comment to see the full error message
              <RecordComment comment={comment} key={comment.id} />
            ))}
          </CommentContainer>
        )}
        {!loading && comments.length === 0 && (
          <div className="w-full h-full flex flex-col items-center justify-center text-center">
            <MessagesIcon
              className="w-36 mb-8"
              text={[getColorShade(secondaryColor, '400')]}
              isDarkModeEnabled={isDarkModeEnabled}
            />
            <h2
              className={`font-medium text-base ${
                isDarkModeEnabled
                  ? darkModeColors.text.primary
                  : 'text-gray-800'
              }`}
            >
              {getText(LANG_KEY, 'empty.title')}
            </h2>
          </div>
        )}
        {loading && comments.length === 0 && (
          <div className="w-full h-full flex items-center justify-center">
            <Loader />
          </div>
        )}
      </div>
    </RightPanel>
  );
};

export default withTheme(RecordComments);
