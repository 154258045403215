import { useCallback } from 'react';
import first from 'lodash/first';
import get from 'lodash/get';
import {
  AlertCustomOptions,
  AlertType,
  positions,
  useAlert as useBaseAlert,
} from 'react-alert';
import { Variant } from '@noloco/components';
import {
  DANGER,
  INFO,
  PRIMARY,
  WARNING,
} from '@noloco/components/src/constants/variants';

export const DEFAULT_TIMEOUT_MS = 5000;

export const useAlert = (type: Variant) => {
  const alert = useBaseAlert();

  return useCallback(
    (
      message: string,
      text?: string,
      options: AlertCustomOptions & {
        icon?: string;
        onClick?: () => void;
        onDismiss?: () => void;
        text?: string;
      } = {},
    ) =>
      alert.show(message, {
        type: type as AlertType,
        text,
        timeout: DEFAULT_TIMEOUT_MS,
        position: positions.TOP_RIGHT,
        ...options,
      }),
    [alert, type],
  );
};

export const useRemoveAllAlerts = () => {
  const alert = useBaseAlert();

  return useCallback(() => alert.removeAll(), [alert]);
};

export const useErrorAlert = () => useAlert(DANGER);
export const useWarningAlert = () => useAlert(WARNING);
export const useInfoAlert = () => useAlert(INFO);
export const useSuccessAlert = () => useAlert(PRIMARY);

export const getTextFromError = (error: any) => {
  if (!error) {
    return {};
  }

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    const gqlError = first(error.graphQLErrors);
    const title = (gqlError as any).name ?? (gqlError as any).extensions?.title;
    return {
      title,
      message: error.graphQLErrors.map((er: any) => er.message).join('\n'),
    };
  }

  const networkError = get(error, 'networkError.result.message');
  if (networkError) {
    return { message: networkError };
  }

  if (error.message) {
    return { message: error.message };
  }

  return { message: String(error) };
};

export const useGraphQlErrorAlert = () => {
  const errorAlert = useAlert(DANGER);
  return useCallback(
    (defaultTitle: any, error: any, options = {}) => {
      const { title, message } = getTextFromError(error);
      return errorAlert(title || defaultTitle, message, options);
    },
    [errorAlert],
  );
};
