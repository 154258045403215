import first from 'lodash/first';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { isElementVisible } from '../components/canvas/withVisibilityRules';
import { TEXT } from '../constants/dataTypes';
import { API_REQUEST } from '../constants/dataWrapperTypes';
import { FOLDER, VIEW } from '../constants/elements';
import { PAGE } from '../constants/linkTypes';
import { SUPPORTED_PROTOCOLS } from '../constants/urls';
import { Project } from '../models/Project';
import { findEndpoint } from './apis';
import { resolveDataValue } from './data';
import { getPagesConfig } from './elements';
import { findPageInPath, replaceDoubleSlashes } from './pages';
import { flattenStateItem } from './state';

export const formatUrl = (url: any) =>
  !url ||
  SUPPORTED_PROTOCOLS.some((protocol) => url.startsWith(protocol)) ||
  url.includes('://')
    ? url
    : `https://${url}`;

export const formatQueryString = (queryString: any) =>
  queryString ? `?${queryString}` : '';

export const getPageTo = (
  pageIdPath: any,
  pageData: any,
  pages: any,
  scope: any,
  project: any,
) => {
  const pathArr = pageIdPath.reduce((pathAcc: any, pageId: any) => {
    const pageItem = findPageInPath(pageId, pageIdPath, pages);
    if (pageId === 'new') {
      return [...pathAcc, 'new'];
    }

    if (!pageItem || !pageItem.props) {
      return pathAcc;
    }

    const {
      parentPage,
      routePath,
      dataProperty,
      dataType,
      dataSource,
    } = pageItem.props;
    if (dataSource === API_REQUEST) {
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      const { endpoint: { endpointId, apiId } = {} } = pageItem.props;
      const { endpoint } = findEndpoint(project.apis, apiId, endpointId);
      if (!endpoint) {
        return pathAcc;
      }

      return [
        ...pathAcc,
        routePath,
        ...endpoint.parameters.map((param: any) => {
          const val = get(pageData, ['endpointParams', param.name]);
          return (
            val &&
            resolveDataValue(val, scope, project, param.dataType !== TEXT)
          );
        }),
      ];
    }

    if (parentPage && !dataProperty) {
      const parentPageItem = findPageInPath(parentPage, pageIdPath, pages);
      if (parentPageItem) {
        return [...pathAcc, parentPageItem.props.routePath, routePath];
      }
    }

    if (pageItem.props.isSubPage && pageItem.parent) {
      return [...pathAcc, pageItem.parent.props.routePath, routePath];
    }

    const pageDataItem = dataType && get(pageData, dataType);
    const valuePath = pageDataItem && flattenStateItem(pageDataItem);
    const pathValue =
      pageDataItem && valuePath && get(scope, valuePath.split('.'));
    return [...pathAcc, routePath, pathValue];
  }, []);

  return replaceDoubleSlashes(
    `/${pathArr.filter((path: any) => !!path && path !== '/').join('/')}`,
  );
};

const objectToQueryString = (params: any) =>
  Object.entries(params)
    .filter(([__, val]) => val !== undefined)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
const appendParams = (url: any, params: any) =>
  `${url}${
    params && Object.values(params).filter(Boolean).length > 0
      ? `${url && url.includes('?') ? '&' : '?'}${objectToQueryString(params)}`
      : ''
  }`;

export const getLinkProps = (
  { type, active, to, href, target }: any,
  editorMode: any,
  trackingParams = {},
) => {
  if (type === PAGE || (to && !href)) {
    return {
      active,
      is: Link,
      to: appendParams(to, trackingParams),
    };
  }

  return {
    is: 'a',
    href: appendParams(href, trackingParams),
    target,
    ...(target === '_blank' ? { rel: 'noopener noreferrer' } : {}),
  };
};

const hasViewParent = (view: any, elements: any) => {
  const parentPageId = get(view, 'props.parentPage');
  if (!parentPageId) {
    return false;
  }
  const parent = elements.find((el: any) => el.id === parentPageId);
  return !parent || parent.type !== FOLDER;
};

export const getViewsForDataType = (dataTypeName: any, project: Project) => {
  const { isV2, pagesPath } = getPagesConfig(
    project.elements,
    project.settings,
  );
  const elements = isV2 ? project.elements : get(project.elements, pagesPath);
  return elements.filter(
    (v: any) =>
      v.type === VIEW &&
      get(v, 'props.dataList.dataType') === dataTypeName &&
      !hasViewParent(v, elements),
  );
};

export const getViewForDataType = (dataTypeName: any, project: any) =>
  first(getViewsForDataType(dataTypeName, project));

const getParentPage = (view: any, project: Project) => {
  const parentPageId = get(view, 'props.parentPage');
  if (parentPageId) {
    const { isV2, pagesPath } = getPagesConfig(
      project.elements,
      project.settings,
    );
    const elements = isV2 ? project.elements : get(project.elements, pagesPath);
    return elements.find((el: any) => el.id === parentPageId);
  }
  return null;
};

const getViewRoutePrefixForView = (view: any, project: any) => {
  const routePath = get(view, 'props.routePath');

  if (view && routePath) {
    const parentPageId = get(view, 'props.parentPage');
    if (parentPageId) {
      const parent = getParentPage(view, project);

      if (parent && parent.type === FOLDER) {
        return `/${parent.props.routePath}/${routePath}`;
      }
    }

    return `/${routePath}`;
  }

  return null;
};

export const getViewById = (viewId: string, project: Project) => {
  const { projectPages } = getPagesConfig(project.elements, project.settings);
  return projectPages.find((v: any) => v.type === VIEW && v.id === viewId);
};

export const getViewRoutePrefixForViewId = (viewId: any, project: Project) => {
  const { projectPages } = getPagesConfig(project.elements, project.settings);

  const view = projectPages.find(
    (v: any) =>
      v.type === VIEW && v.id === viewId && !hasViewParent(v, projectPages),
  );

  return getViewRoutePrefixForView(view, project);
};

export const getViewRoutePrefixForDataType = (
  dataTypeName: any,
  project: any,
) => {
  const view = getViewForDataType(dataTypeName, project);
  return getViewRoutePrefixForView(view, project);
};

export const getAllowedViewRoutePrefixForDataType = (
  dataTypeName: any,
  project: any,
  user: any,
  scope: any,
  customRulesEnabled: any,
) => {
  const editorMode = false;
  const views = getViewsForDataType(dataTypeName, project);
  const view = views.find((testView: any) => {
    const parent = getParentPage(testView, project);

    if (
      parent &&
      !isElementVisible(
        parent,
        project,
        user,
        scope,
        editorMode,
        customRulesEnabled,
      )
    ) {
      return false;
    }

    return isElementVisible(
      testView,
      project,
      user,
      scope,
      editorMode,
      customRulesEnabled,
    );
  });
  return getViewRoutePrefixForView(view, project);
};
