import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BaseMutationOptions, useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import shortId from 'shortid';
import { Surface } from '@noloco/components';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { INVITE_USER } from '../../constants/actionTypes';
import { FILE } from '../../constants/builtInDataTypes';
import { DATE, MULTIPLE_OPTION, TEXT } from '../../constants/dataTypes';
import { FORM_SECTION } from '../../constants/elements';
import { FIELD_VALIDATION_RULES } from '../../constants/features';
import { DATE as DATE_FORMAT } from '../../constants/fieldFormats';
import { MANY_TO_MANY } from '../../constants/relationships';
import { CREATE, UPDATE } from '../../constants/workflowTriggerTypes';
import AutoFormField from '../../elements/sections/forms/AutoFormField';
import { DataField } from '../../models/DataTypeFields';
import { DataType } from '../../models/DataTypes';
import {
  BaseRecord,
  CollectionConnection,
  RecordValue,
} from '../../models/Record';
import {
  FormConfigWithField,
  FormFieldConfig,
  FormSectionConfig,
} from '../../models/View';
import { QueryObject, reduceFieldsToQueryObject } from '../../queries/data';
import { MutationType, getMutationQueryString } from '../../queries/project';
import { setFieldValue } from '../../reducers/formFields';
import { projectDataSelector } from '../../selectors/projectSelectors';
import { lookupOfArray } from '../arrays';
import { conditionsAreMet, getAdditionalFieldsForForm } from '../data';
import { skipPropResolvingByValueIds } from '../elementPropResolvers';
import { getFileTypeFromMimetype } from '../files';
import pascalCase from '../pascalCase';
import { isMultiField, isMultiRelationship } from '../relationships';
import { RECORD_SCOPE } from '../scope';
import useFormFieldsState, { DEBOUNCED_TYPES } from '../useFormFieldsState';
import useAutoFormVariables from './useAutoFormVariables';
import { useBuildDataItemRecordScope } from './useBuildDataItemRecordScope';
import { useFieldVisibilityConditions } from './useFieldVisibilityConditions';
import useMergedScope from './useMergedScope';
import usePrevious from './usePrevious';
import useSectionScopeVariables from './useSectionScopeVariables';

const ROOT_ELEMENT_PATH = [0];

type Props = UseAutoFormConfig & {
  children: any;
};

type Section = {
  section: { fields: FormFieldConfig[]; id: string };
  config: FormSectionConfig;
};

type UseFormContext = {
  isLoading: boolean;
  hasSubmit: boolean;
  additionalRelatedFields: QueryObject;
  formFields: FormConfigWithField[];
  changedFieldsMap: Record<string, boolean>;
  hasChangedMap: Record<string, boolean>;
  HelpText: any;
  onSubmit: (event: any, updateAllRecords?: boolean) => Promise<void>;
  resolvedSectionsWithConditionsMet: Section[];
  fieldsWithHiddenSectionsRemoved: FormConfigWithField[];
  renderFormField: (
    formField: FormConfigWithField,
    updateAllRecords?: boolean,
    setUpdateAllRecords?: (updateAllRecords: boolean) => void,
  ) => any;
  sections?: Section[];
};

const DEFAULT_NODE_QUERY = {
  id: true,
  uuid: true,
  createdAt: true,
  updatedAt: true,
};

const getIdValue = (val: any) => {
  if (typeof val == 'object' && val.id) {
    return String(val.id);
  }

  return String(val);
};

const formatDateFieldValue = (field: DataField, value: string): string => {
  const hasTime = get(field, 'typeOptions.format') !== DATE_FORMAT;
  const timeZone = get(field, 'typeOptions.timeZone');

  const dateTime = DateTime.fromISO(value);
  const DateTimeZone = hasTime && !timeZone ? DateTime.local : DateTime.utc;

  return DateTimeZone(
    dateTime.year,
    dateTime.month,
    dateTime.day,
    hasTime ? dateTime.hour : 0,
    hasTime ? dateTime.minute : 0,
    hasTime ? dateTime.second : 0,
  ).toISO();
};

const formatRawValuesForForm = (field: DataField, value: RecordValue) => {
  if (isNil(value)) {
    return undefined;
  }

  if (field.type === TEXT) {
    return String(value);
  }

  if (field.type === MULTIPLE_OPTION) {
    if (typeof value === 'string') {
      return value
        .split(',')
        .filter((optionString) =>
          field.options?.find((option) => option.name === optionString),
        );
    }
  }

  if (!field.relationship && !field.relatedField) {
    if (field.type === MULTIPLE_OPTION && value && !Array.isArray(value)) {
      return [value];
    }

    const isValidDate =
      field.type === DATE && value && DateTime.fromISO(value as string).isValid;
    if (isValidDate) {
      return formatDateFieldValue(field, value as string);
    }

    return value;
  }

  if (isMultiField(field)) {
    if (
      typeof value === 'object' &&
      (value as BaseRecord | CollectionConnection).edges
    ) {
      return value;
    }

    return {
      edges: Array.isArray(value)
        ? value.map((val) => ({ node: { id: String(val) } }))
        : [{ node: { id: String(value) } }],
    };
  } else if (value) {
    if (
      typeof value === 'object' &&
      (value as BaseRecord | CollectionConnection).edges
    ) {
      return first(
        (value as CollectionConnection).edges.map((edge: any) => edge.node),
      );
    }

    return { id: getIdValue(value) };
  }
};

const extractPrefilledAndDefaultValues = (resolvedFields: any) =>
  resolvedFields
    .filter(
      ({ config }: any) =>
        config.value !== undefined ||
        !isEmpty(config.defaultValue) ||
        isBoolean(config.defaultValue) ||
        isFinite(config.defaultValue),
    )
    .reduce((valueAcc: any, { field, config }: any) => {
      if (config.value !== undefined) {
        valueAcc[field.apiName] = formatRawValuesForForm(field, config.value);
      } else {
        valueAcc[field.apiName] = formatRawValuesForForm(
          field,
          config.defaultValue,
        );
      }
      return valueAcc;
    }, {});

const getUpdatedValue = (
  field: any,
  value: any,
  dataItem: any,
  draftValues: any,
) => {
  if (field.type === FILE) {
    if (!isMultiRelationship(field.relationship)) {
      if (value !== null) {
        const file = value[0];
        return {
          fileType: getFileTypeFromMimetype(file.type),
          name: file.name,
          url: value[2],
        };
      }
    } else {
      const valueWithIds = value.map((uploadVal: any) => [
        ...uploadVal,
        shortId.generate(),
      ]);

      return {
        edges: [
          ...get(
            draftValues,
            [field.apiName, 'edges'],
            get(dataItem, [field.apiName, 'edges'], []),
          ),
          ...valueWithIds.map((file: any) => ({
            node: {
              id: file[3],
              fileType: getFileTypeFromMimetype(file[0].type),
              name: file[0].name,
              url: file[2],
            },
          })),
        ],
      };
    }
  }

  return value;
};

type UseAutoFormConfig = {
  authQuery?: boolean;
  dataType: DataType;
  fields: FormConfigWithField[];
  formatRecordScope?: (record: BaseRecord) => BaseRecord;
  HelpText?: any;
  inline?: boolean;
  innerClassName?: string;
  inviteEnabled?: boolean;
  Label?: any;
  mutationType: MutationType;
  neverAllowNewRecords?: boolean;
  onAddDataItem?: (record: BaseRecord) => void;
  onError?: (error: Error) => void;
  onErrorResetFormFieldValues?: boolean;
  onFieldFailsValidation?: (field: DataField) => void;
  onFieldPassesValidation?: (field: DataField) => void;
  onLoadingChange?: (loading: boolean) => void;
  onLoadingFinish?: () => void;
  onSuccess?: (record: BaseRecord) => void;
  queryOptions?: Omit<BaseMutationOptions, 'context'>;
  readOnly?: boolean;
  ReadOnlyCell?: any;
  sections?: Section[];
  skipResolvingForValueIds?: string[];
  submitOnBlur?: boolean | ((field: DataField) => boolean);
  surface?: Surface;
  transformRecordScope?: (
    scope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>;
  value: BaseRecord | null;
  bulkActionsEnabled?: boolean;
  isRowChecked?: boolean;
  selectedRows?: BaseRecord[];
};

const useAutoFormContext = ({
  authQuery,
  dataType,
  fields,
  formatRecordScope = identity,
  HelpText,
  inline,
  innerClassName,
  inviteEnabled = true,
  Label,
  mutationType,
  neverAllowNewRecords,
  onAddDataItem = () => null,
  onError,
  onErrorResetFormFieldValues,
  onFieldFailsValidation,
  onFieldPassesValidation,
  onLoadingChange,
  onLoadingFinish,
  onSuccess,
  queryOptions,
  readOnly,
  ReadOnlyCell,
  sections,
  skipResolvingForValueIds = [],
  submitOnBlur = false,
  surface,
  transformRecordScope = identity,
  value,
  bulkActionsEnabled,
  isRowChecked,
  selectedRows = [],
}: UseAutoFormConfig): UseFormContext => {
  const dispatch = useDispatch();
  const project = useSelector(projectDataSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [originalDataItem, setOriginalDataItem] = useState(value);
  const [hasSubmit, setHasSubmit] = useState(false);

  const { fields: initiallyResolvedFields } = useSectionScopeVariables(
    FORM_SECTION,
    { fields },
    project,
    ROOT_ELEMENT_PATH,
    skipPropResolvingByValueIds([RECORD_SCOPE, ...skipResolvingForValueIds]),
  );

  const additionalRelatedFields = useMemo(
    () => getAdditionalFieldsForForm(fields),
    [fields],
  );

  const [
    draftValues,
    setDraftValues,
    clearFormFieldValues,
  ] = useFormFieldsState(dataType.name, value ? value.id : undefined, !inline);

  const changedFieldsMap = useMemo(
    () =>
      Object.keys(draftValues || {})
        .map((fieldKey) => dataType.fields.getByName(fieldKey))
        .filter(Boolean)
        .reduce((acc, field) => ({ ...acc, [field!.id]: true }), {}),
    [dataType.fields, draftValues],
  );

  const initialDataItem = useMemo(
    () => ({
      ...value,
      ...extractPrefilledAndDefaultValues(initiallyResolvedFields),
      ...draftValues,
    }),
    [value, initiallyResolvedFields, draftValues],
  );

  const buildRecordScope = useBuildDataItemRecordScope(
    formatRecordScope,
    transformRecordScope,
  );

  const initialRecordScope = useMemo(() => buildRecordScope(initialDataItem), [
    buildRecordScope,
    initialDataItem,
  ]);

  const initialScope = useMergedScope(initialRecordScope);

  const { fields: postResolvedFields } = useSectionScopeVariables(
    FORM_SECTION,
    { fields },
    project,
    [0],
    initialScope,
    skipPropResolvingByValueIds(skipResolvingForValueIds),
  );

  const dataItem = useMemo(
    () => ({
      ...value,
      ...extractPrefilledAndDefaultValues(postResolvedFields),
      ...draftValues,
    }),
    [value, postResolvedFields, draftValues],
  );

  const recordScope = useMemo(() => buildRecordScope(dataItem), [
    buildRecordScope,
    dataItem,
  ]);

  const scope = useMergedScope(recordScope);

  const resolvedFieldsWithConditionsMet = useFieldVisibilityConditions(
    fields,
    dataType,
    project,
    recordScope,
    scope,
    // Need to remove label here because it's a react component that can't be memoized unfortunately
    // @ts-expect-error TS(2345): Argument of type '<T>(fieldObj: T) => LodashSet1x4... Remove this comment to see the full error message
    (fieldObj) => set(['config', 'label'], undefined, fieldObj),
    // Need to add label back in
    (field: any, index: any) =>
      set(['config', 'label'], get(fields, [index, 'config', 'label']), field),
  );

  const resolvedSectionsWithConditionsMet = useFieldVisibilityConditions(
    sections,
    dataType,
    project,
    recordScope,
    scope,
  );

  const fieldsWithHiddenSectionsRemoved = useMemo(() => {
    if (!sections) {
      return resolvedFieldsWithConditionsMet;
    }

    const allSectionFieldNamesLookup = sections.reduce(
      (fieldNamesLookup, { section }: any) => ({
        ...fieldNamesLookup,
        ...lookupOfArray(section.fields, 'name'),
      }),
      {} as Record<string, { config: FormSectionConfig }>,
    );
    const visibleSectionFieldNamesLookup = resolvedSectionsWithConditionsMet.reduce(
      // @ts-expect-error TS(7006): Parameter 'fieldNamesLookup' implicitly has an 'an... Remove this comment to see the full error message
      (fieldNamesLookup, { section }: any) => ({
        ...fieldNamesLookup,
        ...lookupOfArray(section.fields, 'name'),
      }),
      {},
    );

    return resolvedFieldsWithConditionsMet.filter(
      ({ config }: any) =>
        !allSectionFieldNamesLookup[config.field] ||
        !!visibleSectionFieldNamesLookup[config.field],
    );
  }, [
    resolvedFieldsWithConditionsMet,
    resolvedSectionsWithConditionsMet,
    sections,
  ]);

  const isValidationEnabled = useIsFeatureEnabled(FIELD_VALIDATION_RULES);

  const isFieldConditionallyRequired = useCallback(
    (requiredConditions: any) =>
      conditionsAreMet(requiredConditions || [], scope, project, dataType),
    [dataType, project, scope],
  );

  const {
    changedFields: hasChangedMap,
    uploads,
    getQueryVariables,
    setUploads,
    setChangedFields,
  } = useAutoFormVariables(
    project,
    dataType,
    fieldsWithHiddenSectionsRemoved,
    mutationType,
    originalDataItem,
    onError,
    isValidationEnabled,
    isFieldConditionallyRequired,
    formatRecordScope,
    transformRecordScope,
    changedFieldsMap,
  );

  const isUserCreation = mutationType === CREATE && dataType.apiName === 'user';

  const dynamicNodeQueryObject = useMemo(
    () =>
      fieldsWithHiddenSectionsRemoved
        .map((fieldConfig: any) => fieldConfig.field)
        .reduce(
          reduceFieldsToQueryObject(project.dataTypes, {
            includeCollections: true,
            includeNestedFields: true,
            includeHidden: true,
          }),
          DEFAULT_NODE_QUERY,
        ),
    [project.dataTypes, fieldsWithHiddenSectionsRemoved],
  );

  const creationQueryString = useMemo(
    () =>
      gql`
        ${getMutationQueryString(
          isUserCreation && inviteEnabled ? INVITE_USER : CREATE,
          dataType.apiName,
          dataType.fields,
          dynamicNodeQueryObject,
        )}
      `,
    [
      isUserCreation,
      inviteEnabled,
      dataType.apiName,
      dataType.fields,
      dynamicNodeQueryObject,
    ],
  );

  const updateQueryString = useMemo(
    () =>
      gql`
        ${getMutationQueryString(
          UPDATE,
          dataType.apiName,
          dataType.fields,
          dynamicNodeQueryObject,
        )}
      `,
    [dataType.apiName, dataType.fields, dynamicNodeQueryObject],
  );

  const queryOptionsObj = useMemo(
    () => ({
      ...queryOptions,
      context: {
        authQuery,
        projectQuery: true,
        projectName: project.name,
      },
    }),
    [authQuery, project.name, queryOptions],
  );

  const [createDataItem] = useMutation(creationQueryString, queryOptionsObj);
  const [updateDataItem] = useMutation(updateQueryString, queryOptionsObj);
  const debouncedInlineUpdateDataItem = useMemo(
    () => debounce(updateDataItem, 400),
    [updateDataItem],
  );

  const canBulkUpdate = useMemo(
    () =>
      bulkActionsEnabled &&
      isRowChecked &&
      selectedRows &&
      selectedRows.length > 1,
    [bulkActionsEnabled, isRowChecked, selectedRows],
  );

  const saveValues = useCallback(
    (
      draftRecord: any,
      draftUploads: any,
      changedField?: DataField,
    ): Promise<void> => {
      const updateTimeMs = Date.now();

      if (mutationType === CREATE) {
        setIsLoading(true);
      }

      setHasSubmit(true);
      const {
        variables,
        optimisticResponse,
        changedFields,
      } = getQueryVariables(draftRecord, draftUploads, changedField);

      // Make sure it's not just the ID that's in the variables object
      const hasNonIdMutationValues =
        variables && Object.keys(variables).length > 1;
      if (hasNonIdMutationValues) {
        const mutation =
          mutationType === UPDATE
            ? inline &&
              changedField &&
              DEBOUNCED_TYPES.includes(changedField.type)
              ? debouncedInlineUpdateDataItem
              : updateDataItem
            : createDataItem;
        const mutationResult = mutation({
          variables,
          optimisticResponse,
        });

        if (!mutationResult) {
          return Promise.reject();
        }

        // @ts-expect-error TS(2769): No overload matches this call.
        const changedFieldsIds = Object.keys(changedFields).filter(
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          (changedFieldId) => changedFields[changedFieldId],
        );
        const fieldsToWipe = changedFieldsIds.reduce(
          (acc, changedFieldId) => ({
            ...acc,
            [changedFieldId]: false,
          }),
          {},
        );
        const changedFieldNames = changedFieldsIds.map(
          (fieldId) => dataType.fields.getById(fieldId)!.apiName,
        );

        setIsLoading(true);
        setChangedFields(fieldsToWipe);
        setUploads({});

        return mutationResult
          .then((updatedData) => {
            let item = null;
            if (mutationType === CREATE && updatedData.data) {
              const mutationPrefix =
                isUserCreation && inviteEnabled ? 'invite' : 'create';
              const newItem =
                updatedData.data[
                  `${mutationPrefix}${pascalCase(dataType.apiName)}`
                ];
              if (newItem) {
                onAddDataItem(newItem);
                item = newItem;
              }
              setOriginalDataItem({} as BaseRecord);
            } else {
              const updatedItem =
                updatedData.data[`update${pascalCase(dataType.apiName)}`];
              if (updatedItem) {
                setOriginalDataItem(updatedItem);
                item = updatedItem;
              }
            }

            setHasSubmit(false);

            setTimeout(() => {
              clearFormFieldValues(changedFieldNames, updateTimeMs);
            }, 6000);

            if (onSuccess && item) {
              onSuccess(item);
            }
          })
          .catch((e) => {
            // Reset state
            // @ts-expect-error TS(2345): Argument of type '{} | undefined' is not assignabl... Remove this comment to see the full error message
            setChangedFields(changedFields);
            setUploads(draftUploads);

            if (onErrorResetFormFieldValues) {
              clearFormFieldValues(changedFieldNames, updateTimeMs);
            }

            console.error(e);
            if (e.message === 'Failed to fetch') {
              captureException(e);
            }
            if (onError) {
              onError(e);
            }
          })
          .finally(() => {
            if (onLoadingFinish) {
              onLoadingFinish();
            }
            if (!canBulkUpdate) {
              setIsLoading(false);
            }
          });
      } else if (mutationType === CREATE) {
        setIsLoading(false);
      }
      return Promise.resolve();
    },
    [
      mutationType,
      getQueryVariables,
      inline,
      debouncedInlineUpdateDataItem,
      updateDataItem,
      createDataItem,
      setChangedFields,
      setUploads,
      dataType.fields,
      dataType.apiName,
      onSuccess,
      isUserCreation,
      inviteEnabled,
      onAddDataItem,
      clearFormFieldValues,
      onError,
      onErrorResetFormFieldValues,
      onLoadingFinish,
      canBulkUpdate,
    ],
  );

  const previousLoading = usePrevious(isLoading);
  useEffect(() => {
    if (
      onLoadingChange &&
      previousLoading !== undefined &&
      previousLoading !== isLoading
    ) {
      onLoadingChange(isLoading);
    }
  }, [onLoadingChange, isLoading, previousLoading]);

  const onSubmit = useCallback(
    async (event: any, updateAllRecords?: boolean) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (canBulkUpdate && updateAllRecords) {
        const rowsToUpdate: any = selectedRows.map((selectedRow) => ({
          ...selectedRow,
          ...draftValues,
        }));

        if (rowsToUpdate?.length > 0) {
          return Promise.all(
            rowsToUpdate?.map((rowToUpdate: any) =>
              saveValues(rowToUpdate, uploads),
            ),
          ).then(() => setIsLoading(false));
        }
      }

      return saveValues(dataItem, uploads);
    },
    [dataItem, saveValues, uploads, selectedRows, canBulkUpdate, draftValues],
  );

  const onValueChange = useCallback(
    (draftUploads: any) => (changedField: DataField, changedValue: any) => {
      if (submitOnBlur) {
        if (typeof submitOnBlur !== 'function' || submitOnBlur(changedField)) {
          saveValues(
            { ...dataItem, [changedField.apiName]: changedValue },
            draftUploads,
            changedField,
          );
        }
      }
    },
    [dataItem, saveValues, submitOnBlur],
  );

  const singleUpdateDataItemField = useCallback(
    (field: DataField, value: any) => {
      setChangedFields((currentChangedFields) => ({
        ...currentChangedFields,
        [field.id]: true,
      }));

      const updatedValue = getUpdatedValue(field, value, dataItem, draftValues);

      let nextUploads = uploads;

      if (field.type === FILE) {
        if (!isMultiRelationship(field.relationship)) {
          nextUploads = set([field.apiName], value, nextUploads);
          setUploads(nextUploads);
        } else {
          const valueWithIds = value.map((uploadVal: any) => [
            ...uploadVal,
            shortId.generate(),
          ]);
          nextUploads = set(
            [field.apiName],
            [...get(uploads, field.apiName, []), ...valueWithIds],
            nextUploads,
          );
          setUploads(nextUploads);
        }
      }

      setDraftValues(field, updatedValue, onValueChange(nextUploads));
    },
    [
      dataItem,
      draftValues,
      onValueChange,
      setChangedFields,
      setDraftValues,
      setUploads,
      uploads,
    ],
  );

  const bulkUpdateDataItemField = useCallback(
    (field: DataField, value: any) => {
      setChangedFields((currentChangedFields) => ({
        ...currentChangedFields,
        [field.id]: true,
      }));

      const updatedValue = getUpdatedValue(field, value, dataItem, draftValues);
      const nodesToUpdate: any = selectedRows.map((selectedRow) => ({
        ...selectedRow,
        [field.name]: updatedValue,
      }));

      if (nodesToUpdate?.length > 0) {
        selectedRows.map((selectedRow) =>
          dispatch(
            setFieldValue({
              dataTypeName: dataType.name,
              id: selectedRow.id,
              fieldName: field.apiName,
              value,
            }),
          ),
        );

        return Promise.all(
          nodesToUpdate?.map((node: any) => saveValues(node, uploads, field)),
        ).then(() => setIsLoading(false));
      }
    },
    [
      selectedRows,
      dataItem,
      draftValues,
      saveValues,
      uploads,
      dispatch,
      setChangedFields,
      dataType.name,
    ],
  );

  const updateDataItemField = useCallback(
    (field: DataField) => async (value: any, updateAllRecords?: boolean) => {
      if (updateAllRecords === true) {
        return bulkUpdateDataItemField(field, value);
      }

      return singleUpdateDataItemField(field, value);
    },
    [singleUpdateDataItemField, bulkUpdateDataItemField],
  );

  const removeFile = useCallback(
    (field: any) => (fileId: any) => {
      if (field.type === FILE && field.relationship === MANY_TO_MANY) {
        setChangedFields((currentChangedFields) => ({
          ...currentChangedFields,
          [field.id]: true,
        }));

        const isUpload = get(uploads, [field.apiName], []).some(
          (upload: any) => upload[3] === fileId,
        );

        if (isUpload) {
          setUploads((currentUploads) =>
            set(
              field.apiName,
              get(currentUploads, [field.apiName], []).filter(
                (upload: any) => upload[3] !== fileId,
              ),
              currentUploads,
            ),
          );
        }

        const fileFieldKey = `${field.apiName}Id`;
        const fileIdsValue = [...get(dataItem, fileFieldKey, []), fileId];

        if (!isUpload) {
          saveValues(
            { ...dataItem, [fileFieldKey]: fileIdsValue },
            uploads,
            field,
          );
        }
        const updatedValue = {
          edges: get(dataItem, [field.apiName, 'edges'], []).filter(
            (edge: any) => edge.node.id !== fileId,
          ),
        };
        // No need to include the callback for the file edges as these arent included in the mutation
        // Only the IDs are used in the mutation, but we need to set the draft values to update the UI
        setDraftValues(field, updatedValue);
      }
    },
    [
      dataItem,
      saveValues,
      setChangedFields,
      setDraftValues,
      setUploads,
      uploads,
    ],
  );

  const formFields = useMemo(
    () =>
      fieldsWithHiddenSectionsRemoved.filter(
        ({ config }: any) => !config.hidden,
      ),
    [fieldsWithHiddenSectionsRemoved],
  );

  const renderFormField = useCallback(
    (
      { config, field }: FormConfigWithField,
      updateAllRecords?: boolean,
      setUpdateAllRecords?: (updateAllRecords: boolean) => void,
    ) => {
      config.allowNewRecords = !neverAllowNewRecords;
      return (
        <AutoFormField
          additionalRelatedFields={additionalRelatedFields}
          authQuery={authQuery}
          className={innerClassName}
          config={config}
          dataItem={dataItem}
          dataType={dataType}
          dataTypes={project.dataTypes}
          disabled={config.disabled}
          field={field}
          hasChanged={hasChangedMap[field.id] || hasSubmit}
          HelpText={HelpText}
          inline={inline}
          isFieldConditionallyRequired={isFieldConditionallyRequired}
          key={field.id}
          Label={Label}
          mutationType={mutationType}
          onFieldFailsValidation={onFieldFailsValidation}
          onFieldPassesValidation={onFieldPassesValidation}
          project={project}
          readOnly={readOnly}
          ReadOnlyCell={ReadOnlyCell}
          removeFile={removeFile}
          scope={scope}
          surface={surface}
          updateDataItemField={updateDataItemField}
          canBulkUpdate={canBulkUpdate}
          updateAllRecords={updateAllRecords}
          setUpdateAllRecords={setUpdateAllRecords}
        />
      );
    },
    [
      neverAllowNewRecords,
      additionalRelatedFields,
      authQuery,
      innerClassName,
      dataItem,
      dataType,
      project,
      hasChangedMap,
      hasSubmit,
      HelpText,
      inline,
      isFieldConditionallyRequired,
      Label,
      mutationType,
      onFieldFailsValidation,
      onFieldPassesValidation,
      readOnly,
      ReadOnlyCell,
      removeFile,
      scope,
      surface,
      updateDataItemField,
      canBulkUpdate,
    ],
  );

  return {
    isLoading,
    hasSubmit,
    additionalRelatedFields,
    formFields,
    changedFieldsMap,
    hasChangedMap,
    HelpText,
    onSubmit,
    resolvedSectionsWithConditionsMet,
    fieldsWithHiddenSectionsRemoved,
    renderFormField,
    sections,
  };
};

const formContext = createContext<UseFormContext>({} as UseFormContext);

export const AutoFormProvider = ({ children, ...rest }: Props) => {
  const form = useAutoFormContext(rest);

  return <formContext.Provider value={form}>{children}</formContext.Provider>;
};

export const useAutoForm = () => {
  return useContext(formContext);
};
