import React, { forwardRef, memo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Element } from '../../models/Element';
import { Project } from '../../models/Project';
import { scopeSelector } from '../../selectors/dataSelectors';
import cappedMemoize from '../../utils/cappedMemoize';
import { getDataScopeDeps } from '../../utils/data';
import { RECORD_SCOPE } from '../../utils/scope';
import { flattenStateItem } from '../../utils/state';

export const getDataFieldsScopeSelector: (
  dataScopeDeps: any,
  defaultScope: any,
  project: Project,
  editorMode: boolean,
) => (state: any) => { scope: any } = (
  dataScopeDeps,
  defaultScope,
  project,
  editorMode,
) =>
  createSelector([scopeSelector], (scope) => {
    if (editorMode) {
      return { ...defaultScope, ...scope };
    }

    const dataScopeFields =
      dataScopeDeps.length > 0
        ? getDataScopeFields(dataScopeDeps, scope)
        : null;

    return { ...defaultScope, ...dataScopeFields };
  });

const getDataScopeValues = cappedMemoize(
  (valuePath, dataScope) => {
    return get(dataScope, valuePath.split('.'));
  },
  { maxKeys: 200 },
);

export const getDataScopeFields = cappedMemoize(
  (dataScopeDeps, dataScope) =>
    dataScopeDeps.reduce((deps: any, dep: any) => {
      const valuePath = flattenStateItem(dep);
      const depValue = getDataScopeValues(valuePath, dataScope);
      if (depValue === undefined) {
        return deps;
      }
      return set(valuePath.split('.'), depValue, deps);
    }, {}),
  { maxKeys: 200 },
);

const withDataFields = (
  WrappedComponent: any,
  child: Element,
  project: Project,
  editorMode: boolean,
) => {
  const dataScopeDeps = getDataScopeDeps(child).filter(
    (dep) => (dep && dep.id !== RECORD_SCOPE) || false,
  );
  const dataFieldsScopeSelector = getDataFieldsScopeSelector(
    dataScopeDeps,
    null,
    project,
    editorMode,
  );

  if (dataScopeDeps.length === 0) {
    return WrappedComponent;
  }
  const WithDataFields = memo(
    forwardRef((props, ref) => {
      WrappedComponent.displayName = `${WrappedComponent.displayName} - with DataFields`;

      const scope = useSelector(dataFieldsScopeSelector);
      return <WrappedComponent {...props} ref={ref} dataScopeFields={scope} />;
    }),
  );

  WithDataFields.displayName = `WithDataFields`;

  return WithDataFields;
};

export default withDataFields;
