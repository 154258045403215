import React from 'react';
import { Loader } from '@noloco/components';
import FeatureLockedButton from '@noloco/ui/src/components/FeatureLockedButton';
import { useFeatureUsage } from '@noloco/ui/src/utils/hooks/useFeatureUsage';
import { CREATE } from '../../constants/actionTypes';
import { DATABASE_RECORDS } from '../../constants/features';
import { DATATYPES_EXCLUDED_FROM_RECORD_USAGE } from '../../constants/usage';
import { getText } from '../../utils/lang';
import Icon from '../Icon';

type Props = {};

const SectionButton = ({
  // @ts-expect-error TS(2339): Property 'button' does not exist on type 'Props'.
  button,
  // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
  className,
  // @ts-expect-error TS(2339): Property 'dataTypeName' does not exist on type 'Pr... Remove this comment to see the full error message
  dataTypeName,
  // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Props'... Remove this comment to see the full error message
  disabled,
  // @ts-expect-error TS(2339): Property 'loading' does not exist on type 'Props'.
  loading,
  // @ts-expect-error TS(2339): Property 'mutationType' does not exist on type 'Pr... Remove this comment to see the full error message
  mutationType,
  // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Props'.
  onClick,
  // @ts-expect-error TS(2339): Property 'placeholder' does not exist on type 'Pro... Remove this comment to see the full error message
  placeholder,
  // @ts-expect-error TS(2339): Property 'disableFeatureCheck' does not exist on t... Remove this comment to see the full error message
  disableFeatureCheck = false,
  ...rest
}: Props) => {
  const currentNumber = useFeatureUsage(DATABASE_RECORDS);

  return (
    <FeatureLockedButton
      checkEnabled={
        !disableFeatureCheck &&
        mutationType === CREATE &&
        !DATATYPES_EXCLUDED_FROM_RECORD_USAGE.includes(dataTypeName)
      }
      className={className}
      currentNumber={currentNumber}
      disabled={disabled || loading}
      feature={DATABASE_RECORDS}
      onClick={onClick}
      {...rest}
    >
      <div className="flex items-center">
        {!loading && button.icon && button.icon.name && (
          <Icon icon={button.icon} className="opacity-75 w-6 h-6 mr-2" />
        )}
        {loading && <Loader size="sm" className="mr-2" />}
        <span className="py-1 whitespace-nowrap">
          {button.text ||
            placeholder ||
            getText('elements.TITLE.buttons.placeholder')}
        </span>
      </div>
    </FeatureLockedButton>
  );
};

export default SectionButton;
