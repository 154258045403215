import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Loader, getColorShade } from '@noloco/components';
import MarkdownText from '../../components/MarkdownText';
import { CREATE, UPDATE } from '../../constants/actionTypes';
import { darkModeColors } from '../../constants/darkModeColors';
import { BLANK_QUERY_STRING } from '../../queries/project';
import { AutoFormProvider } from '../../utils/hooks/useAutoForm';
import useAutoFormConfig from '../../utils/hooks/useAutoFormConfig';
import useDarkMode from '../../utils/hooks/useDarkMode';
import Icon from '../Icon';
import SectionButton from './SectionButton';
import Title from './Title';
import RelatedCellItem from './collections/RelatedCellItem';
import AutoForm from './forms/AutoForm';

const HelpText = ({ config }: any) => {
  const [isDarkModeEnabled] = useDarkMode();
  return (
    <div
      className={`flex py-0.5  text-xs ${
        isDarkModeEnabled ? darkModeColors.text.secondary : 'text-gray-600'
      }`}
    >
      <MarkdownText small={true}>{config.helpText}</MarkdownText>
    </div>
  );
};

const Label = ({ htmlFor, label, required }: any) => (
  <label className="font-medium text-sm relative" htmlFor={htmlFor}>
    <span>{label}</span>
    {required && (
      <span className="absolute right-0 top-0 -mt-1.5 -mr-2 text-base opacity-75">
        *
      </span>
    )}
  </label>
);

const hasInvalidVisibleFields = (
  invalidFieldNames: any,
  visibleFieldConfigs: any,
) => {
  const visibleFieldNames = visibleFieldConfigs.map(
    ({ field }: any) => field.name,
  );
  const invalidVisibleFields = invalidFieldNames.filter((fieldName: any) =>
    visibleFieldNames.includes(fieldName),
  );
  return invalidVisibleFields.length > 0;
};

type FormSectionProps = {};

const FormSection = forwardRef<any, FormSectionProps>(
  (
    {
      // @ts-expect-error TS(2339): Property 'className' does not exist on type 'FormS... Remove this comment to see the full error message
      className,
      // @ts-expect-error TS(2339): Property 'dataType' does not exist on type 'FormSe... Remove this comment to see the full error message
      dataType,
      // @ts-expect-error TS(2339): Property 'fields' does not exist on type 'FormSect... Remove this comment to see the full error message
      fields,
      // @ts-expect-error TS(2339): Property 'sectionFormat' does not exist on type 'F... Remove this comment to see the full error message
      sectionFormat,
      // @ts-expect-error TS(2339): Property 'sections' does not exist on type 'FormSe... Remove this comment to see the full error message
      sections,
      // @ts-expect-error TS(2339): Property 'errorMessage' does not exist on type 'Fo... Remove this comment to see the full error message
      errorMessage,
      // @ts-expect-error TS(2339): Property 'successMessage' does not exist on type '... Remove this comment to see the full error message
      successMessage,
      // @ts-expect-error TS(2339): Property 'onLoadingChange' does not exist on type ... Remove this comment to see the full error message
      onLoadingChange,
      // @ts-expect-error TS(2339): Property 'onSuccess' does not exist on type 'FormS... Remove this comment to see the full error message
      onSuccess,
      // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type 'FormSe... Remove this comment to see the full error message
      subtitle,
      // @ts-expect-error TS(2339): Property 'title' does not exist on type 'FormSecti... Remove this comment to see the full error message
      title,
      // @ts-expect-error TS(2339): Property 'showSuccess' does not exist on type 'For... Remove this comment to see the full error message
      showSuccess,
      // @ts-expect-error TS(2339): Property 'submitButton' does not exist on type 'Fo... Remove this comment to see the full error message
      submitButton,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'FormSec... Remove this comment to see the full error message
      onClick,
      // @ts-expect-error TS(2339): Property 'type' does not exist on type 'FormSectio... Remove this comment to see the full error message
      type,
      // @ts-expect-error TS(2339): Property 'which' does not exist on type 'FormSecti... Remove this comment to see the full error message
      which,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type 'FormSec... Remove this comment to see the full error message
      project,
      // @ts-expect-error TS(2339): Property 'queryOptions' does not exist on type 'Fo... Remove this comment to see the full error message
      queryOptions,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'FormSecti... Remove this comment to see the full error message
      theme,
      // @ts-expect-error TS(2339): Property 'onSubmit' does not exist on type 'FormSe... Remove this comment to see the full error message
      onSubmit,
      // @ts-expect-error TS(2339): Property 'hideFormOnSuccess' does not exist on typ... Remove this comment to see the full error message
      hideFormOnSuccess,
      // @ts-expect-error TS(2339): Property 'disableFeatureCheck' does not exist on t... Remove this comment to see the full error message
      disableFeatureCheck = false,
      // @ts-expect-error TS(2339): Property 'neverAllowNewRecords' does not exist on ... Remove this comment to see the full error message
      neverAllowNewRecords = false,
      // @ts-expect-error TS(2339): Property 'neverAllowNewRecords' does not exist on ... Remove this comment to see the full error message
      transformRecordScope,
    },
    ref,
  ) => {
    const errorColor = theme.brandColorGroups.warning;
    const successColor = theme.brandColorGroups.success;

    const [loading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = useState(false);

    const [isDarkModeEnabled] = useDarkMode();

    const [invalidFields, setInvalidFields] = useState([]);
    const onFieldFailsValidation = useCallback(
      (field: any) => {
        // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        if (!invalidFields.includes(field.name)) {
          // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
          setInvalidFields([...invalidFields, field.name]);
        }
      },
      [invalidFields],
    );
    const onFieldPassesValidation = useCallback(
      (field: any) => {
        // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        if (invalidFields.includes(field.name)) {
          setInvalidFields(
            invalidFields.filter(
              (invalidFieldName) => invalidFieldName !== field.name,
            ),
          );
        }
      },
      [invalidFields],
    );

    useEffect(() => {
      if (onLoadingChange) {
        onLoadingChange(loading);
        return () => onLoadingChange(false);
      }
    }, [onLoadingChange, loading]);

    const dt = useMemo(() => project.dataTypes.getByName(dataType), [
      dataType,
      project.dataTypes,
    ]);
    const {
      dataTypeName,
      dataTypeWithRelations,
      fieldConfigs,
      itemQueryString,
      sectionConfigs,
    } = useAutoFormConfig(dt, fields, project, which, sections);

    const { data, loading: queryLoading, error } = useQuery(
      gql`
        ${itemQueryString || BLANK_QUERY_STRING}
      `,
      {
        variables: { id: which },
        errorPolicy: 'all',
        skip: !dt || type === CREATE || !which || !itemQueryString,
        context: {
          projectQuery: true,
          projectName: project.name,
        },
      },
    );

    if (error) {
      console.error('Error loading record for form:', error);
    }

    const onError = useCallback((formError: any) => {
      setSuccess(false);
      if (typeof formError === 'object') {
        if (formError.graphQLErrors && formError.graphQLErrors.length > 0) {
          setErrors(formError.graphQLErrors.map((er: any) => er.message));
        } else if (formError.networkError && formError.networkError.result) {
          const networkError = get(formError, 'networkError.result.message');
          // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
          setErrors([networkError]);
        } else if (formError.message) {
          setErrors(formError.message.split('\n'));
        } else {
          // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
          setErrors(String(formError).split('\n'));
        }
      } else {
        // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
        setErrors(String(formError).split('\n'));
      }
    }, []);

    const handleOnSuccess = useCallback(
      (dataItem: any) => {
        setErrors(null);
        setSuccess(true);
        if (onSuccess) {
          onSuccess(dataItem);
        }

        if (onSubmit) {
          onSubmit(null, { value: dataItem });
        }
      },
      [onSubmit, onSuccess],
    );

    return (
      <div
        className={classNames('flex flex-col w-full mx-auto', className)}
        ref={ref}
        onClick={onClick}
      >
        {(title || subtitle) && (
          <Title
            subtitle={{
              hidden: !subtitle,
              value: subtitle,
            }}
            title={{
              hidden: !title,
              value: title,
            }}
            className="mb-4"
          />
        )}
        {errors && (
          <Box
            bg={getColorShade(errorColor, '400')}
            className="p-4 rounded-lg flex mb-6 mt-2 items-center text-white"
          >
            {errorMessage.icon && errorMessage.icon.name && (
              <Icon
                icon={errorMessage.icon}
                className="w-6 h-6 opacity-50 mr-3"
              />
            )}
            <div className="flex flex-col">
              <span className="font-medium text-sm">
                {errorMessage.message}
              </span>
              <div className="flex flex-col space-y-1 text-xs mt-1">
                {(errors as any).map((error: any) => (
                  <span key={error}>{error}</span>
                ))}
              </div>
            </div>
          </Box>
        )}
        {success && showSuccess && (
          <Box
            bg={getColorShade(successColor, '400')}
            className="p-3 rounded-lg flex items-center text-white mb-2"
          >
            {successMessage.icon && successMessage.icon.name && (
              <Icon
                icon={successMessage.icon}
                className="w-6 h-8 opacity-50 mr-3"
              />
            )}
            <span className="font-medium text-base">
              {successMessage.message}
            </span>
          </Box>
        )}
        {!queryLoading && fieldConfigs && (
          <>
            {hideFormOnSuccess && success ? null : (
              <AutoFormProvider
                innerClassName="mb-5"
                HelpText={HelpText}
                Label={Label}
                value={get(data, [dataTypeName])}
                onError={onError}
                onSuccess={handleOnSuccess}
                dataType={dataTypeWithRelations}
                fields={fieldConfigs}
                sections={sectionConfigs}
                onFieldFailsValidation={onFieldFailsValidation}
                onFieldPassesValidation={onFieldPassesValidation}
                onLoadingChange={setIsLoading}
                onLoadingFinish={() => setIsLoading(false)}
                mutationType={type}
                onAddDataItem={() => null}
                queryOptions={queryOptions}
                ReadOnlyCell={RelatedCellItem}
                surface={isDarkModeEnabled ? 'dark' : 'light'}
                neverAllowNewRecords={neverAllowNewRecords}
                transformRecordScope={transformRecordScope}
              >
                <AutoForm
                  className="grid gap-x-2 grid-cols-12"
                  sectionFormat={sectionFormat}
                >
                  {submitButton &&
                    (({ fieldsForValidation }: any) => (
                      <div className="mb-6 mt-3">
                        <SectionButton
                          // @ts-expect-error TS(2322): Type '{ dataTypeName: any; disabled: boolean; load... Remove this comment to see the full error message
                          dataTypeName={dataTypeName}
                          disabled={
                            loading ||
                            (type === UPDATE && !which) ||
                            hasInvalidVisibleFields(
                              invalidFields,
                              fieldsForValidation,
                            )
                          }
                          loading={loading}
                          mutationType={type}
                          button={submitButton}
                          submitFormOnClick={true}
                          placeholder="Submit"
                          disableFeatureCheck={disableFeatureCheck}
                        />
                      </div>
                    ))}
                </AutoForm>
              </AutoFormProvider>
            )}
          </>
        )}
        {queryLoading && (
          <div className="w-full h-96 flex items-center justify-center">
            <Loader
              size="md"
              className={
                isDarkModeEnabled
                  ? darkModeColors.text.primary
                  : 'text-gray-800'
              }
            />
          </div>
        )}
      </div>
    );
  },
);

FormSection.defaultProps = {
  // @ts-expect-error TS(2322): Type '{ dataType: string; fields: never[]; section... Remove this comment to see the full error message
  dataType: 'user',
  fields: [],
  sections: null,
  showSuccess: true,
  type: CREATE,
};

FormSection.displayName = 'FormSection';

export default withTheme(FormSection);
