import { useCallback } from 'react';
import { getText } from '../lang';
import { useErrorAlert } from './useAlerts';

const MAX_ATTACHMENT_SIZE = 45000000;

const useReadFileInputAttachments = () => {
  const errorAlert = useErrorAlert();
  const onSelectFiles = useCallback(
    async (newFiles: any) => {
      const filePromises = [];
      if (newFiles.length > 0) {
        for (const file of newFiles) {
          if (file.size < MAX_ATTACHMENT_SIZE) {
            filePromises.push(
              new Promise((resolve) => {
                const reader = new FileReader();

                reader.onabort = () =>
                  console.error('file reading was aborted');
                reader.onerror = () => console.error('file reading has failed');
                reader.onload = () => {
                  // Do whatever you want with the file contents
                  const arrayBuffer = reader.result;

                  setTimeout(() => {
                    resolve([file, arrayBuffer, URL.createObjectURL(file)]);
                  }, 100);
                };
                reader.readAsArrayBuffer(file);
              }),
            );
          } else {
            errorAlert(
              getText({ fileName: file.name }, 'core.uploads.sizeError'),
            );
          }
        }
      }
      return Promise.all(filePromises);
    },
    [errorAlert],
  );

  return onSelectFiles;
};

export default useReadFileInputAttachments;
