export const EQUAL = 'EQUAL';
export const NOT_EQUAL = 'NOT_EQUAL';
export const CONTAINS = 'CONTAINS';
export const DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN';

export const IN = 'IN';
export const NOT_IN = 'NOT_IN';

export const AFTER = 'AFTER';
export const BEFORE = 'BEFORE';

export const AFTER_OR_EQUAL = 'AFTER_OR_EQUAL';
export const BEFORE_OR_EQUAL = 'BEFORE_OR_EQUAL';

export const GREATER = 'GREATER';
export const GREATER_OR_EQUAL = 'GREATER_OR_EQUAL';
export const LESS = 'LESS';
export const LESS_OR_EQUAL = 'LESS_OR_EQUAL';

export const EMPTY = 'EMPTY';
export const NOT_EMPTY = 'NOT_EMPTY';

export const TRUE = 'TRUE';
export const FALSE = 'FALSE';

export const OR = 'OR';

export const BETWEEN = 'BETWEEN';

export const EMAIL = 'EMAIL';
export const REGEX = 'REGEX';
export const URL = 'URL';

const operators = [
  EQUAL,
  NOT_EQUAL,
  CONTAINS,
  AFTER,
  BEFORE,
  GREATER,
  GREATER_OR_EQUAL,
  LESS,
  LESS_OR_EQUAL,
  EMPTY,
  NOT_EMPTY,
  TRUE,
  FALSE,
  IN,
  NOT_IN,
];

export type Operator =
  | 'EQUAL'
  | 'NOT_EQUAL'
  | 'CONTAINS'
  | 'DOES_NOT_CONTAIN'
  | 'AFTER'
  | 'BEFORE'
  | 'AFTER_OR_EQUAL'
  | 'BEFORE_OR_EQUAL'
  | 'GREATER'
  | 'GREATER_OR_EQUAL'
  | 'LESS'
  | 'LESS_OR_EQUAL'
  | 'EMPTY'
  | 'NOT_EMPTY'
  | 'TRUE'
  | 'FALSE'
  | 'IN'
  | 'OR'
  | 'NOT_IN';

export type ConditionOperator = Operator | ('EMAIL' | 'REGEX' | 'URL');

export default operators;
