import React, { useMemo, useState } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconLock } from '@tabler/icons-react';
import classNames from 'classnames';
import { Button, Tooltip } from '@noloco/components';
import { getText } from '@noloco/core/src/utils/lang';
import { APP_DASHBOARD_URL } from '../constants/env';
import { useFeatureLimit } from '../utils/hooks/useFeatureLimit';
import { useFeatureLockedTooltip } from '../utils/hooks/useFeatureLockedTooltip';
import useIsFeatureEnabled from '../utils/hooks/useIsFeatureEnabled';
import OverageWarningModal from './OverageWarningModal';
import ProFeatureBadge from './ProFeatureBadge';

type OwnProps = {
  children?: React.ReactNode;
  checkEnabled?: boolean;
  currentNumber?: number;
  onClick?: (...args: any[]) => any;
  feature?: any; // TODO: PropTypes.oneOf(FEATURES)
  iconClassName?: string;
  tooltip?: React.ReactNode | string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof FeatureLockedButton.defaultProps;

// @ts-expect-error TS(7022): 'FeatureLockedButton' implicitly has type 'any' be... Remove this comment to see the full error message
const FeatureLockedButton = ({
  children,
  className,
  compactBadge,
  disabled,
  onClick,
  feature,
  checkEnabled,
  currentNumber,
  iconClassName,
  is,
  tooltipPlacement,
  ...rest
}: Props) => {
  const [overageModalIsOpen, setOverageModalIsOpen] = useState(false);

  const isEnabled = useIsFeatureEnabled(feature);
  const { limit, overage } = useFeatureLimit(feature);
  const tooltip = useFeatureLockedTooltip(feature, currentNumber);

  const featureEnabled = useMemo(
    () => !checkEnabled || isEnabled || !!overage,
    [checkEnabled, isEnabled, overage],
  );
  const showOverageModal = useMemo(
    () =>
      checkEnabled &&
      !isEnabled &&
      overage &&
      (limit - currentNumber) % overage.increment === 0,
    [checkEnabled, currentNumber, isEnabled, limit, overage],
  );

  const handleClick = (event: any) => {
    if (!featureEnabled) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (onClick) {
        onClick(event);
      }
    }
  };

  return (
    <>
      <Tooltip
        // @ts-expect-error TS(2322): Type '{ children: Element; delayHide: number; disa... Remove this comment to see the full error message
        delayHide={300}
        disabled={featureEnabled}
        placement={tooltipPlacement}
        content={
          <div className="flex flex-col text-sm">
            {tooltip}
            <a
              href={`${APP_DASHBOARD_URL}/billing`}
              className="mt-2 text-teal-500 hover:underline"
            >
              {getText('features.explore')}
            </a>
          </div>
        }
        bg="white"
      >
        <Box
          className={classNames('group relative', className)}
          disabled={disabled && featureEnabled}
          onClick={
            showOverageModal ? () => setOverageModalIsOpen(true) : handleClick
          }
          is={is}
          {...rest}
        >
          <div className="flex items-center">
            {children}
            {!featureEnabled && (
              <IconLock
                size={16}
                className={classNames('ml-2', iconClassName)}
              />
            )}
            {checkEnabled && !showOverageModal && (
              <ProFeatureBadge
                compact={compactBadge}
                currentNumber={currentNumber}
                feature={feature}
                tooltipPlacement={tooltipPlacement}
              />
            )}
          </div>
        </Box>
      </Tooltip>
      {overageModalIsOpen && (
        <OverageWarningModal
          // @ts-expect-error TS(2322): Type '{ handleClick: (event: any) => void; feature... Remove this comment to see the full error message
          handleClick={handleClick}
          feature={feature}
          overage={overage}
          setIsOpen={setOverageModalIsOpen}
        />
      )}
    </>
  );
};

FeatureLockedButton.defaultProps = {
  checkEnabled: true,
  enabled: true,
  iconClassName: 'text-gray-200',
  is: Button,
};

export default FeatureLockedButton;
