import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityRulesWrapper from '../../../components/canvas/VisibilityRulesWrapper';
import { WithDropable } from '../../../components/withDnD';
import { SECTION } from '../../../constants/draggableItemTypes';
import { Element, ElementPath, ViewTab } from '../../../models/Element';
import { setSelectedSectionPath } from '../../../reducers/elements';
import { hasSelectedElementPathSelector } from '../../../selectors/elementsSelectors';
import cappedMemoize from '../../../utils/cappedMemoize';
import { useNextBackLink } from '../../../utils/hooks/useBacklink';
import useSectionsDragAndDrop from '../../../utils/hooks/useSectionsDragAndDrop';
import { getText } from '../../../utils/lang';
import ViewSection from './ViewSection';

const getSectionPath = cappedMemoize((index) => [index], { maxKeys: 500 });

export const filterSections = (
  tabs: ViewTab[],
  visibleTabs: ViewTab[],
  sections: Element[],
  selectedTab: ViewTab | null | undefined,
) => {
  const useTabs = tabs && tabs.length > 1;
  const tabIds = tabs.map((t: any) => t.id);
  const visibleTabIds = visibleTabs.map((t: any) => t.id);

  if (visibleTabIds.length === 0 && useTabs) {
    return [];
  }

  return sections
    .map((section, index) => ({
      section,
      index,
    }))
    .filter(({ section }: any) => {
      if (!useTabs) {
        // If not using tabs then render anything that isn't hidden.
        return (
          !section ||
          !(
            tabIds.includes(section.tab) && !visibleTabIds.includes(section.tab)
          )
        );
      }

      return (
        section &&
        ((!selectedTab && (!section.tab || !tabIds.includes(section.tab))) ||
          (selectedTab && section.tab === selectedTab.id))
      );
    });
};

const RecordViewSections = ({
  data,
  dataType,
  editorMode,
  elementPath,
  isEditingData,
  onError,
  onLoadingChange,
  pageId,
  isRecordView,
  project,
  recordScope,
  rootPathname,
  sections,
  selectedTab,
  tabs,
  visibleTabs,
}: any) => {
  const hasSelectedElement = useSelector(hasSelectedElementPathSelector);

  const backLink = useNextBackLink(pageId.replace(':VIEW', ''));

  const dispatch = useDispatch();
  const onUpdateOrder = useCallback(
    (selectedSectionPath: ElementPath) => {
      dispatch(setSelectedSectionPath(selectedSectionPath));
    },
    [dispatch],
  );

  const sectionPath = useMemo(
    () => (isRecordView ? ['record', 'sections'] : ['sections']),
    [isRecordView],
  );

  const {
    formatItem: formatSection,
    draftItems: draftSections,
    findItem: findSection,
    onSaveOrder,
    draggable,
  } = useSectionsDragAndDrop({
    sections,
    sectionPath,
    elementPath,
    onUpdateOrder,
  });

  const tabSections = useMemo(
    () => filterSections(tabs, visibleTabs, draftSections, selectedTab),
    [tabs, visibleTabs, draftSections, selectedTab],
  );

  if (visibleTabs.length === 0 && tabs.length > 1) {
    return (
      <div className="w-full flex items-center justify-center py-8">
        {getText('elements.VIEW.tabs.empty')}
      </div>
    );
  }

  return tabSections.map(
    ({ section, index }: { section: Element; index: number }) => {
      if (!section) {
        return null;
      }

      return (
        <VisibilityRulesWrapper
          editorMode={editorMode}
          key={section.id}
          project={project}
          visibilityRules={section.visibilityRules}
          visibilityRulesScope={recordScope}
        >
          <ViewSection
            key={section.id}
            backLink={backLink}
            dataType={dataType}
            editorMode={editorMode}
            elementPath={elementPath}
            isEditingData={isEditingData}
            onError={() => {
              onError(section.id);
            }}
            onLoadingChange={(nextLoading: any) => {
              onLoadingChange(section.id, nextLoading);
            }}
            isRecordView={isRecordView}
            path={getSectionPath(index)}
            index={index}
            findItem={findSection}
            item={formatSection(section)}
            draggable={draggable(index)}
            droppable={editorMode && hasSelectedElement}
            onSaveOrder={onSaveOrder}
            rootPathname={rootPathname}
            pageId={pageId}
            project={project}
            record={data}
            section={section}
            tabs={visibleTabs}
            selectedTab={selectedTab}
            visibleSections={tabSections}
          />
        </VisibilityRulesWrapper>
      );
    },
  );
};

export default WithDropable(RecordViewSections, SECTION);
