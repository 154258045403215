import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  IconArrowDown,
  IconArrowUp,
  IconCopy,
  IconSquare,
  IconTrash,
} from '@tabler/icons-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import elements from '@noloco/core/src/elements';
import { getText } from '@noloco/core/src/utils/lang';
import {
  useCloneElement,
  useMoveElementDown,
  useMoveElementUp,
  useRemoveSelected,
} from '../../utils/hooks/projectHooks';

const REFRESH_TIME_MS = 75;

let canvasRef: any;
const getCanvasRef = () => {
  if (!canvasRef || !canvasRef.isConnected) {
    canvasRef = document.querySelector('.canvas .simplebar-content');
  }
  return canvasRef;
};

const ElementHighlight = memo(
  forwardRef(
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{ child... Remove this comment to see the full error message
    ({ project, element, elementPath, selected, showSpacing }, ref) => {
      const [t, setT] = useState(0);
      const [onMoveElementUp, canMoveUp] = useMoveElementUp(
        project,
        elementPath,
      );
      const [onMoveElementDown, canMoveDown] = useMoveElementDown(
        project,
        elementPath,
      );
      const [removeSelectedElement] = useRemoveSelected(project, elementPath);
      const [cloneElement] = useCloneElement(project, elementPath);

      useEffect(() => {
        setTimeout(() => {
          setT(t + 1);
        }, REFRESH_TIME_MS);
      }, [t]);

      const onRemoveSelected = useCallback(
        (event) => {
          event.stopPropagation();
          removeSelectedElement();
        },
        [removeSelectedElement],
      );

      const onCloneSelected = useCallback(
        (event) => {
          event.stopPropagation();
          cloneElement();
        },
        [cloneElement],
      );

      const { props: elementProps = {} } = element || {};

      const { Icon = IconSquare } = elements[element.type] || {};

      const canvas = getCanvasRef();

      const tooltip = () => {
        const elementReference = document.querySelector(
          `.element-${element.id}`,
        );
        if (!elementReference) {
          return null;
        }

        // @ts-expect-error TS(2339): Property 'offsetHeight' does not exist on type 'El... Remove this comment to see the full error message
        const { offsetHeight, offsetWidth } = elementReference;
        const canvasRect = canvas.getBoundingClientRect();
        const boundingRect = elementReference.getBoundingClientRect();
        const zIndex = 9999;
        const highlightStyle = {
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex,
          transform: `translate(${boundingRect.left - canvasRect.left}px, ${
            boundingRect.top - canvasRect.top
          }px)`,
        };
        const size = 1;

        const positionTooltipLeft = true;

        const controlsTopPosition = 0;

        const bgClassNames = 'bg-pink-300';
        return (
          // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
          <div ref={ref} style={highlightStyle}>
            <>
              <div
                className={classNames('absolute', bgClassNames)}
                style={{
                  top: 0 - size,
                  left: 0 - size,
                  height: size,
                  width: offsetWidth + size * 2,
                }}
              />
              <div
                className={classNames('absolute', bgClassNames)}
                style={{
                  top: offsetHeight,
                  left: 0 - size,
                  height: size,
                  width: offsetWidth + size * 2,
                }}
              />
              <div
                className={classNames('absolute', bgClassNames)}
                style={{
                  top: 0 - size,
                  left: 0 - size,
                  height: offsetHeight + size * 2,
                  width: size,
                }}
              />
              <div
                className={classNames('absolute', bgClassNames)}
                style={{
                  top: 0 - size,
                  left: offsetWidth,
                  height: offsetHeight + size * 2,
                  width: size,
                }}
              />

              {/* ----- CONTROLS --- */}
              {!showSpacing && (
                <div
                  className={classNames(
                    'absolute cursor-default top-0 left-0 -mt-4 h-8 ml-4 px-2 flex items-center rounded-lg bg-pink-400 text-white',
                  )}
                  style={{
                    top: controlsTopPosition,
                    ...(positionTooltipLeft
                      ? { left: 0 - size }
                      : { right: -offsetWidth }),
                    height: 24,
                  }}
                >
                  <Icon
                    className={classNames(
                      'w-4',
                      positionTooltipLeft ? 'mr-2' : 'ml-2',
                    )}
                  />
                  <span className="whitespace-nowrap">
                    {elementProps.name ||
                      getText('elements', element.type, 'label')}
                  </span>
                  {selected && (
                    <div
                      className={classNames(
                        'flex items-center ',
                        positionTooltipLeft ? 'ml-8' : 'mr-8',
                      )}
                    >
                      <button
                        disabled={!canMoveUp}
                        className="w-6 mx-1 cursor-pointer focus:bg-pink-700 hover:bg-pink-500 disabled:opacity-50"
                        // @ts-expect-error TS(2322): Type 'boolean | (() => any)' is not assignable to ... Remove this comment to see the full error message
                        onClick={onMoveElementUp}
                      >
                        <IconArrowUp className="w-4 mx-auto" />
                      </button>
                      <button
                        disabled={!canMoveDown}
                        className="w-6 mx-1 cursor-pointer focus:bg-pink-700 hover:bg-pink-500 disabled:opacity-50"
                        // @ts-expect-error TS(2322): Type 'boolean | (() => any)' is not assignable to ... Remove this comment to see the full error message
                        onClick={onMoveElementDown}
                      >
                        <IconArrowDown className="w-4 mx-auto" />
                      </button>
                      {!element.static && (
                        <>
                          <div
                            className="w-6 mx-1 cursor-pointer focus:bg-pink-700 hover:bg-pink-500"
                            onClick={onCloneSelected}
                          >
                            <IconCopy className="w-4 mx-auto" />
                          </div>
                          <div
                            className="w-6 mx-1 cursor-pointer focus:bg-pink-700 hover:bg-pink-500"
                            onClick={onRemoveSelected}
                          >
                            <IconTrash className="w-4 mx-auto" />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
          </div>
        );
      };

      if (!canvas || !element) {
        return null;
      }

      return ReactDOM.createPortal(tooltip(), canvas);
    },
  ),
);

(ElementHighlight as any).propTypes = {
  project: PropTypes.object.isRequired,
  elementPath: PropTypes.array,
  selected: PropTypes.bool.isRequired,
  showSpacing: PropTypes.bool,
  showFloatingProperties: PropTypes.bool,
};

(ElementHighlight as any).defaultProps = {
  showSpacing: false,
};

export default ElementHighlight;
