import { ChartType, GAUGE, STATISTIC } from '../constants/chartTypes';
import { DataField } from '../models/DataTypeFields';
import { DataType } from '../models/DataTypes';

export const getFieldPathFromPath = (path: string): string =>
  path.replace(/^edges\.(node\.)?/, '');

export const getFieldFromAxisValuePath = (
  axisValuePath: string,
  dataType: DataType,
): DataField | undefined | null => {
  if (!axisValuePath || !dataType) {
    return null;
  }
  const fieldName = getFieldPathFromPath(axisValuePath);
  return dataType.fields.getByName(fieldName);
};

export const isMetricChart = (chartType: ChartType | null | undefined) =>
  chartType === STATISTIC || chartType === GAUGE;
