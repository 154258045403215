export const AUTO = 'AUTO';
export const DAY = 'day';
export const WEEK = 'week';
export const MONTH = 'month';
export const QUARTER = 'quarter';
export const YEAR = 'year';

const timePeriods = [AUTO, DAY, WEEK, MONTH, QUARTER, YEAR];

export type TimePeriod = 'AUTO' | 'day' | 'week' | 'month' | 'quarter' | 'year';

export default timePeriods;
