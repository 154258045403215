import React from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { useSelector } from 'react-redux';
import { LINE } from '../../../constants/chartTypes';
import { WEEK } from '../../../constants/timePeriods';
import { editorModeSelector } from '../../../selectors/elementsSelectors';
import { isMetricChart } from '../../../utils/charts';
import {
  ChartData,
  metricData,
  sampleData,
  sampleXMetricValue,
  sampleXValue,
  sampleYValue,
} from '../../Chart';

const CollectionChart = ({ chart, data, dataType, dataTypes, theme }: any) => {
  const editorMode = useSelector(editorModeSelector);

  const series = chart.series || [];

  const metricChart = isMetricChart(chart.chartType);

  if (
    !chart.xAxisValue ||
    (!metricChart && (series.length === 0 || !series[0].yAxisValue))
  ) {
    if (!editorMode) {
      return null;
    }

    return (
      <ChartData
        // @ts-expect-error TS(2322): Type '{ aggregation: any; chartType: any; timePeri... Remove this comment to see the full error message
        chartId={chart.id}
        aggregation={chart.aggregation}
        chartType={chart.chartType || LINE}
        timePeriod={chart.timePeriod || WEEK}
        dataTypes={dataTypes}
        nodes={metricChart ? metricData : sampleData}
        theme={theme}
        id="SAMPLE"
        xAxisValue={metricChart ? sampleXMetricValue : sampleXValue}
        series={[{ id: 'sample', yAxisValue: sampleYValue }]}
        useOptionColors={chart.useOptionColors}
      />
    );
  }

  return (
    <ChartData
      // @ts-expect-error TS(2322): Type '{ aggregation: any; chartType: any; timePeri... Remove this comment to see the full error message
      chartId={chart.id}
      aggregation={chart.aggregation}
      chartType={chart.chartType}
      timePeriod={chart.timePeriod}
      dataType={dataType}
      dataTypes={dataTypes}
      nodes={data}
      theme={theme}
      id={`chart-${chart.id}`}
      max={chart.max}
      xAxisLabel={chart.xAxisLabel}
      xAxisValue={chart.xAxisValue}
      yAxisLabel={chart.yAxisLabel}
      series={series}
      useOptionColors={chart.useOptionColors}
    />
  );
};

export default withTheme(CollectionChart);
