import PropTypes from 'prop-types';
import * as elementTypes from '../constants/elements';

const margins: any[] = [];
const sizes: any[] = [];

export const oneOfKeys = (object: any) => PropTypes.oneOf(Object.keys(object));

export const oneOfValues = (object: any) =>
  PropTypes.oneOf(Object.values(object));

export const oneOfWithDefault = (array: any[]) =>
  PropTypes.oneOf(['default', ...array]);

type elementTypeProps = {
  id?: string;
  children?: any[];
  type: any; // TODO: oneOfValues(elementTypes)
  props?: any;
};

// @ts-expect-error TS(2322): Type 'Requireable<InferProps<{ id: Requireable<str... Remove this comment to see the full error message
const elementType: PropTypes.Requireable<elementTypeProps> = PropTypes.shape({
  id: PropTypes.string,
  children: PropTypes.array,
  type: oneOfValues(elementTypes).isRequired,
  props: PropTypes.object,
});
export { elementType };

export const spacingShape = {
  t: PropTypes.oneOf(margins),
  r: PropTypes.oneOf(margins),
  b: PropTypes.oneOf(margins),
  l: PropTypes.oneOf(margins),
  x: PropTypes.oneOf(margins),
  y: PropTypes.oneOf(margins),
};

export const spacingType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.shape(spacingShape),
]);

export const sizeType = PropTypes.oneOf(sizes);

export const stringOrNodeType = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.string,
]);
