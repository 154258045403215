import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconChevronDown } from '@tabler/icons-react';
import classNames from 'classnames';
import { LG, MD, SM, XS } from '../../constants/tShirtSizes';
import { validationBorder } from '../../utils';
import ErrorText from '../form/ErrorText';
import { ROUNDED_LARGE } from '../input/inputStyles';
import SelectBase from './SelectBase';
import SelectButton from './SelectButton';
import caretSizes from './caretSizes';

const isSet = (val: any) => val !== undefined;

/*
(ts-migrate) TODO: Migrate the remaining prop types
...RawSelectBase.propTypes
*/
type SelectInputProps = {};

const SelectInput = forwardRef<any, SelectInputProps>(
  (
    {
      // @ts-expect-error TS(2339): Property 'bg' does not exist on type 'SelectInputP... Remove this comment to see the full error message
      bg,
      // @ts-expect-error TS(2339): Property 'borderColor' does not exist on type 'Sel... Remove this comment to see the full error message
      borderColor,
      // @ts-expect-error TS(2339): Property 'listBg' does not exist on type 'SelectIn... Remove this comment to see the full error message
      listBg,
      // @ts-expect-error TS(2339): Property 'contained' does not exist on type 'Selec... Remove this comment to see the full error message
      contained,
      // @ts-expect-error TS(2339): Property 'Button' does not exist on type 'SelectIn... Remove this comment to see the full error message
      Button,
      // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Selec... Remove this comment to see the full error message
      className,
      // @ts-expect-error TS(2339): Property 'direction' does not exist on type 'Selec... Remove this comment to see the full error message
      direction,
      // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Select... Remove this comment to see the full error message
      disabled,
      // @ts-expect-error TS(2339): Property 'hasMore' does not exist on type 'SelectI... Remove this comment to see the full error message
      hasMore,
      // @ts-expect-error TS(2339): Property 'hideDropdownIndicator' does not exist on... Remove this comment to see the full error message
      hideDropdownIndicator,
      // @ts-expect-error TS(2339): Property 'footer' does not exist on type 'SelectIn... Remove this comment to see the full error message
      footer,
      // @ts-expect-error TS(2339): Property 'label' does not exist on type 'SelectInp... Remove this comment to see the full error message
      label,
      // @ts-expect-error TS(2339): Property 'loading' does not exist on type 'SelectI... Remove this comment to see the full error message
      loading,
      // @ts-expect-error TS(2339): Property 'options' does not exist on type 'SelectI... Remove this comment to see the full error message
      options,
      // @ts-expect-error TS(2339): Property 'open' does not exist on type 'SelectInpu... Remove this comment to see the full error message
      open,
      // @ts-expect-error TS(2339): Property 'size' does not exist on type 'SelectInpu... Remove this comment to see the full error message
      size,
      // @ts-expect-error TS(2339): Property 'style' does not exist on type 'SelectInp... Remove this comment to see the full error message
      style,
      // @ts-expect-error TS(2339): Property 'validationError' does not exist on type ... Remove this comment to see the full error message
      validationError,
      // @ts-expect-error TS(2339): Property 'value' does not exist on type 'SelectInp... Remove this comment to see the full error message
      value,
      // @ts-expect-error TS(2339): Property 'onChange' does not exist on type 'Select... Remove this comment to see the full error message
      onChange,
      // @ts-expect-error TS(2339): Property 'onSearchChange' does not exist on type '... Remove this comment to see the full error message
      onSearchChange,
      // @ts-expect-error TS(2339): Property 'onFetchMore' does not exist on type 'Sel... Remove this comment to see the full error message
      onFetchMore,
      // @ts-expect-error TS(2339): Property 'onOpenChange' does not exist on type 'Se... Remove this comment to see the full error message
      onOpenChange,
      // @ts-expect-error TS(2339): Property 'multiple' does not exist on type 'Select... Remove this comment to see the full error message
      multiple,
      // @ts-expect-error TS(2339): Property 'placeholder' does not exist on type 'Sel... Remove this comment to see the full error message
      placeholder,
      // @ts-expect-error TS(2339): Property 'searchable' does not exist on type 'Sele... Remove this comment to see the full error message
      searchable,
      // @ts-expect-error TS(2339): Property 'hideOptions' does not exist on type 'Sel... Remove this comment to see the full error message
      hideOptions,
      // @ts-expect-error TS(2339): Property 'showValidationErrorText' does not exist ... Remove this comment to see the full error message
      showValidationErrorText,
      // @ts-expect-error TS(2339): Property 'surface' does not exist on type 'SelectI... Remove this comment to see the full error message
      surface,
      // @ts-expect-error TS(2339): Property 'text' does not exist on type 'SelectInpu... Remove this comment to see the full error message
      text,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'SelectInp... Remove this comment to see the full error message
      theme,
      // @ts-expect-error TS(2339): Property 'usePortal' does not exist on type 'Selec... Remove this comment to see the full error message
      usePortal,
      // @ts-expect-error TS(2339): Property 'coloredOptionType' does not exist on type 'Selec... Remove this comment to see the full error message
      coloredOptionType,
      ...rest
    },
    ref,
  ) => {
    surface = surface ?? theme?.selectInput?.surface;

    return (
      <SelectBase
        bg={bg}
        borderColor={borderColor}
        contained={contained}
        className={className}
        direction={direction}
        disabled={disabled}
        footer={footer}
        hasMore={hasMore}
        hideDropdownIndicator={hideDropdownIndicator}
        listBg={listBg}
        label={label}
        loading={loading}
        open={open}
        onChange={onChange}
        onFetchMore={onFetchMore}
        onOpenChange={onOpenChange}
        onSearchChange={onSearchChange}
        multiple={multiple}
        options={options}
        ref={ref}
        searchable={searchable}
        hideOptions={hideOptions}
        size={size}
        surface={surface}
        style={style}
        text={text}
        value={value}
        usePortal={usePortal}
        coloredOptionType={coloredOptionType}
        {...rest}
      >
        {({ formattedValue, selectedValue, styles }: any) => (
          <>
            <Box
              as="div"
              className={classNames(
                'cursor-default relative text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5 max-w-full min-h-8 pl-2 flex items-center',
                {
                  'text-sm': size === SM || size === MD,
                  'text-xs': size === XS,
                  'sm:min-h-8': size === XS || size === SM,
                  'sm:min-h-10': size === MD,
                  'sm:min-h-12': size === LG,
                },
                validationBorder(validationError, theme),
              )}
              {...styles}
            >
              {isSet(value) || isSet(selectedValue) ? (
                <Button
                  placeholder={placeholder}
                  selectedValue={selectedValue}
                  disabled={disabled}
                  multiple={multiple}
                  onChange={onChange}
                  options={options}
                  surface={surface}
                  value={formattedValue}
                  coloredOptionType={coloredOptionType}
                />
              ) : (
                <SelectButton
                  placeholder={placeholder}
                  onChange={onChange}
                  disabled={disabled}
                  surface={surface}
                  coloredOptionType={coloredOptionType}
                />
              )}
              {!hideDropdownIndicator && (
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <IconChevronDown size={caretSizes[size]} />
                </span>
              )}
            </Box>
            {validationError && showValidationErrorText && (
              <ErrorText className="text-left">{validationError}</ErrorText>
            )}
          </>
        )}
      </SelectBase>
    );
  },
);

SelectInput.defaultProps = {
  // @ts-expect-error TS(2322): Type '{ Button: ({ placeholder, multiple, disabled... Remove this comment to see the full error message
  Button: SelectButton,
  contained: false,
  style: ROUNDED_LARGE,
  className: '',
  direction: 'right',
  hideDropdownIndicator: false,
  size: MD,
  multiple: false,
  placeholder: '',
  showValidationErrorText: true,
  textCenter: false,
  usePortal: true,
  coloredOptionType: false,
};

export default withTheme(SelectInput);
