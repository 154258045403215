import React from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import {
  IconCircleDashed,
  IconCircleX,
  IconFile,
  IconPhoto,
  IconVideo,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { Tooltip } from '@noloco/components';
import {
  AUDIO,
  DOCUMENT,
  IMAGE,
  VIDEO,
} from '@noloco/core/src/constants/fileTypes';
import { getFileTypeFromMimetype } from '@noloco/core/src/utils/files';
import { getText } from '@noloco/core/src/utils/lang';
import {
  APPLICATION_PDF,
  AUDIO_MPEG,
  IMAGE_HEIC,
} from '../../../constants/mimetypes';
import AttachmentIcon from '../messaging/AttachmentIcon';

const AUDIO_ELEMENT_STYLE = {
  backgroundColor: '#f2f3f4',
};

const LANG_KEY = 'core.DROPZONE';

const FilePreview = ({ fileType, mimetype, src, name, maxH, surface }: any) => {
  if (fileType === IMAGE && mimetype !== IMAGE_HEIC) {
    return (
      <Box
        is="img"
        maxH={maxH}
        src={src}
        alt={name}
        className="mx-auto rounded"
      />
    );
  }

  if (fileType === AUDIO) {
    return (
      <Tooltip content={name}>
        <Box
          className="flex items-center rounded-lg m-1 p-4 max-w-full text-black"
          maxH={maxH}
          style={AUDIO_ELEMENT_STYLE}
        >
          <AttachmentIcon
            mimetype={AUDIO_MPEG}
            size={24}
            className="opacity-75 mr-2 inline-block"
          />
          <audio controls={true}>
            <source src={src} type={mimetype} />
            Your browser does not support the audio element.
          </audio>
        </Box>
      </Tooltip>
    );
  }

  if (fileType === VIDEO) {
    return (
      <Box
        is="video"
        maxH={maxH}
        src={src}
        className="mx-auto rounded"
        autoPlay={false}
        muted={true}
        controls={false}
      />
    );
  }

  return (
    <div className="truncate block p-2 max-w-64">
      <AttachmentIcon
        mimetype={mimetype || APPLICATION_PDF}
        size={18}
        className="opacity-50 mr-2 inline-block"
      />
      <span
        className={classNames(
          surface === 'dark' ? 'text-gray-100' : 'text-gray-700',
        )}
      >
        {name}
      </span>
    </div>
  );
};

const getFileTypeIcon = (fileType: any) => {
  switch (fileType) {
    case VIDEO:
      return IconVideo;
    case DOCUMENT:
      return IconFile;
    default:
      return IconPhoto;
  }
};

const DropzonePreview = ({
  className,
  disabled,
  fileType,
  id,
  uploadingImgData,
  loading,
  mediaItem,
  placeholder,
  showIcon,
  maxH,
  onRemove,
  surface,
}: any) => {
  const handleRemove = (event: any) => {
    event.stopPropagation();
    onRemove(event);
  };

  const Icon = showIcon && getFileTypeIcon(fileType);

  return (
    <>
      {!uploadingImgData && !mediaItem && (
        <>
          {showIcon && <Icon size={36} className="mb-4" />}
          <span>{placeholder || getText(LANG_KEY, 'label')}</span>
          <div
            aria-hidden="true"
            className="whitespace-nowrap overflow-hidden text-sm invisible h-0"
          >
            {placeholder || getText(LANG_KEY, 'label')}
          </div>
        </>
      )}
      {(uploadingImgData || mediaItem) && (
        <div className={className}>
          {uploadingImgData && (
            <FilePreview
              id={id}
              maxH={maxH}
              name={uploadingImgData.file.name}
              mimetype={uploadingImgData.file.mimetype}
              fileType={fileType}
              src={uploadingImgData.preview}
              surface={surface}
            />
          )}
          {mediaItem && !uploadingImgData && !loading && (
            <FilePreview
              id={id}
              maxH={maxH}
              name={mediaItem.name}
              fileType={
                mediaItem.fileType ||
                (mediaItem.mimetype &&
                  getFileTypeFromMimetype(mediaItem.mimetype))
              }
              mimetype={mediaItem.mimetype}
              src={mediaItem.uri || mediaItem.url}
              surface={surface}
            />
          )}
          {loading && (
            <div className="absolute inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center rounded-lg">
              <IconCircleDashed size={36} className="animate-spin" />
            </div>
          )}
          {!loading && !disabled && (
            <div className="absolute text-gray-600 top-0 right-0 -mt-4 -mr-4 bg-gray-200 bg-opacity-25 rounded-full">
              <IconCircleX size={24} onClick={handleRemove} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

DropzonePreview.defaultProps = {
  disabled: false,
  fileType: IMAGE,
  showIcon: true,
};

export default DropzonePreview;
