import shortId from 'shortid';
import { CHARTS, ROWS } from '../constants/collectionLayouts';
import { SIMPLE } from '../constants/elementGroups';
import { CHART, COLLECTION, QUICK_LINKS } from '../constants/elements';
import compoundElements, {
  CompoundElement,
} from '../elements/compoundElements';

export const getDefaultPropsForSectionType = (sectionType: string) => {
  switch (sectionType) {
    case CHART:
      return {
        layout: CHARTS,
      };
    case COLLECTION:
      return {
        dataList: {
          limit: [{ text: '10' }],
          showPagination: true,
        },
        layout: ROWS,
      };
    case QUICK_LINKS: {
      const quikLinkConfig = compoundElements[SIMPLE].find(
        (e: CompoundElement) => e.type === QUICK_LINKS,
      );
      if (quikLinkConfig) {
        const links = quikLinkConfig.props?.links.map(
          (link: Record<any, any>) => ({
            ...link,
            id: shortId.generate(),
          }),
        );

        return { links };
      }
      return {};
    }
    default:
      return {};
  }
};
