import { LG, XL } from '@noloco/components/src/constants/tShirtSizes';
import { BOOLEAN } from '../constants/dataTypes';
import {
  BILLING,
  COLLECTION,
  FILE_SHARING,
  MESSAGING,
  ONBOARDING_TASKS,
} from '../constants/elements';
import { WORKFLOW_FAILURE_TYPE } from '../constants/workflowFailureTypes';

const en = {
  errors: {
    projectNotFound: 'We could not find a project on this domain',
    generic: 'Something went wrong, please try again',
    genericNoRetry: 'Oops! Something went wrong',
    notFound: 'This {dataType} could not be found',
    pageNotFound: 'This page could not be found',
    forms: {
      required: "The field '{field}' is required",
    },
    requiresLogin: 'You must be logged in',
    list:
      "You need to use at lease one value from your '{dataType}' list before it will work correctly",
    typeNotFound:
      "The collection '{dataType}' could not be found. If it has been deleted you will need to remove this component",
    page: "This page configuration is invalid. Has '{dataType}' been deleted?",
    data: {
      message: 'Oops! Something went wrong',
      delete: {
        description: "We couldn't delete this record. Please try again.",
      },
      invite: 'Oops! There was a problem inviting {email}',
    },
    formula: {
      empty: 'No formula provided',
      invalidFormula: 'Your formula is invalid',
      unknownError: "There's a problem with your formula.",
    },
    section: {
      title: 'Oops! Something went wrong showing this section.',
      subtitle: ' Make sure it is configured correctly.',
    },
    editor: 'Oops! Something went wrong',
    popUpBlocker:
      'The link could not be opened, you may need to disable your pop-up blocker',
    errorBoundary: {
      title: 'Oops, there was a problem loading this page',
      subtitle: 'Please try again or contact an admin',
    },
    fetchProject:
      'Something went wrong fetching the latest version of your app. Please try again',
    workflowFailures: {
      [WORKFLOW_FAILURE_TYPE.ACTION_FAILED_TO_EXECUTE]: {
        title: 'Workflow action failed',
        message:
          'One of your workflow actions failed to execute. Review your workflow configuration.',
      },
      [WORKFLOW_FAILURE_TYPE.INVALID_EMAIL_MESSAGE]: {
        title: 'Invalid email message',
        message:
          'Your workflow email action has an invalid message. Review your workflow configuration.',
      },
      [WORKFLOW_FAILURE_TYPE.INVALID_EMAIL_SUBJECT]: {
        title: 'Invalid email subject',
        message:
          'Your workflow email action has an invalid subject. Review your workflow configuration.',
      },
      [WORKFLOW_FAILURE_TYPE.INVALID_DATA_TYPE]: {
        title: 'Invalid collection',
        message:
          'Your action has an invalid collection. Has the collection been deleted since saving the workflow? Please review your workflow configuration.',
      },
      [WORKFLOW_FAILURE_TYPE.INVALID_RECORD_ID]: {
        title: 'Invalid record Id',
        message:
          'Your action has an invalid or missing record ID. Review your workflow configuration.',
      },
      [WORKFLOW_FAILURE_TYPE.INVALID_EMAIL_TO]: {
        title: 'Invalid email to address',
        message:
          'Your workflow email action has an invalid to address. Review your workflow configuration.',
      },
      [WORKFLOW_FAILURE_TYPE.INVALID_WEBHOOK_RESPONSE]: {
        title: 'Invaid webhook response',
        message:
          'Your workflow webhook action received an invalid response. Review your workflow configuration.',
      },
      [WORKFLOW_FAILURE_TYPE.INVALID_WEBHOOK_URL]: {
        title: 'Invaid webhook url',
        message:
          'Your workflow webhook action has an invalid webhook url. Review your workflow configuration.',
      },
      [WORKFLOW_FAILURE_TYPE.NO_CONFIG_FOUND]: {
        title: 'Workflow configuration not found',
        message:
          'The configuration for your workflow was not found. Review your workflow configuration.',
      },
      [WORKFLOW_FAILURE_TYPE.NO_RECORD_ITEM]: {
        title: 'Record not found',
        message:
          'The record for your workflow was not found. Please try again.',
      },
      [WORKFLOW_FAILURE_TYPE.UNSUPPORTED_ACTION]: {
        title: 'Invalid workflow configuration',
        message: 'Your workflow has an invalid configuration.',
      },
      [WORKFLOW_FAILURE_TYPE.WEBHOOK_REQUEST_FAILED]: {
        title: 'Webhook request failed',
        message: 'Your workflow webhook request failed. Please try again.',
      },
      [WORKFLOW_FAILURE_TYPE.WORKFLOW_FAILED_TO_EXECUTE]: {
        title: 'Workflow failed to run',
        message: 'There was a problem running this workflow. Please try again.',
      },
      [WORKFLOW_FAILURE_TYPE.RECORD_NOT_FOUND]: {
        title: 'Record not found',
        message:
          'We could not find the record you wished to change. Please try again.',
      },
    },
    maintenanceMode: {
      fallbackMessage: 'Noloco is under maintenance',
      subText:
        'We are making some changes to Noloco, but we should be back really soon. For more information please check our status page',
    },
  },
  notifications: {
    empty: "There's nothing here.",
    error: "We're having some trouble loading your notifications right now.",
    invite: 'Success! {email} has been sent an invite',
    label: 'Notifications',
    markAsRead: 'Mark as read',
    markAsUnread: 'Mark as unread',
    read: 'Read',
    showMore: 'Show more',
    unread: 'Unread',
  },
  general: {
    builtWith: 'Built with Noloco',
  },
  auth: {
    login: {
      or: 'Or',
      button: 'Sign in',
      forgot: 'Forgot your password?',
      title: 'Sign in to your account',
      newUser: "Don't have an account?",
      registerLink: 'Sign up for free',
      next: 'Next',
      appRegisterLink: 'Create an account',
      magicLink: {
        title: 'Magic link sent!',
        subtitle:
          'We have sent an email to **{email}**. Just click the link in the email to sign in!',
        password: 'Use your password to sign in',
      },
      oauth: {
        finished: {
          description: 'You can close this page.',
          error:
            'We ran into an **{error}** error caused by **{errorDescription}** when setting up your OAuth integration. Please contact support for assistance setting this up.',
          title: 'Authorized!',
        },
      },
      saml: {
        button: 'Click here to sign in',
        description: `You will be redirected to your organisation's single sign on page.`,
      },
    },
    signOut: 'Sign out',
    internal: 'Internal',
    client: 'Client',
    join: {
      button: 'Accept my invitation',
      title: 'Finish setting up your account',
      fields: {
        email: 'Confirm your email address',
      },
      error: 'You need a valid invitation token to continue',
    },
    forgotPassword: {
      email: {
        label: 'Your email',
        placeholder: 'lisa@example.com',
      },
      confirm: 'Email me a recovery link',
      submitted:
        'If we find your account we will send you an email with more instructions. Just click the link if you get it',
    },
    resetPassword: {
      email: {
        label: 'Confirm your email',
      },
      password: {
        label: 'Your new password',
      },
      confirmPassword: {
        label: 'Confirm your password',
      },
      confirm: 'Reset my password',
      resetTokenInvalid:
        'A valid password reset token is required to reset your password',
    },
    recoverPassword: {
      invalid: 'Your email must be a valid email address',
    },
    register: {
      button: 'Sign up',
      title: 'Sign up to create your app',
      appTitle: 'Create your account',
      or: 'Already registered?',
      loginLink: 'Sign in now',
      validation: {
        empty: 'This field can not be blank',
        emptyFields: 'Email, password and password confirmation are required',
        email: {
          invalid: 'That is not a valid email',
        },
        password: {
          invalid: 'Your password is not strong enough',
          length: 'Your password must be at least 8 characters long',
          numbers: 'Your password must include at least one number',
          case:
            'Your password must include a mix of uppercase and lowercase letters',
          symbol:
            'Your password must include a special character such as ($, %, *, @)',
        },
        confirmPassword: {
          invalid: 'Your password does not match the confirmation',
        },
        invitationToken: {
          invalid: 'An invitation token is required to accept this invite',
        },
      },
    },
    fields: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email address',
      workEmail: 'Work email address',
      password: 'Password',
      confirmPassword: 'Confirm your password',
      rememberMe: 'Remember me',
    },
    social: {
      or: 'Or continue with',
    },
    profile: {
      view: 'View profile',
    },
    testimonial: {
      title: 'Two weeks down to two days',
      description:
        'The ultimate benefit of adopting Noloco is that what used to take two weeks to approve and process payments now takes just two days.',
      name: 'Clayton Garner',
      occupation: 'Vice President Operations',
      company: 'Fergmar Enterprises',
    },
  },
  values: {
    [BOOLEAN]: {
      true: 'True',
      false: 'False',
    },
  },
  hints: {
    markdown: {
      help: 'This section supports',
      name: 'Markdown',
    },
    darkMode: {
      darkModeToggle: 'Click to enable dark mode',
      lightModeToggle: 'Click to enable light mode',
    },
  },
  core: {
    dataTypes: {
      loading: 'Loading...',
      none: 'None',
      delete: {
        title: 'Are you sure you want to delete this?',
        cancel: 'Cancel',
        confirm: 'Delete',
        confirmText:
          'To confirm please type **{confirmText}** in the box below',
      },
    },
    keyValues: {
      key: 'Key',
      value: 'Value',
    },
    sections: {
      editor: {
        drag: {
          tooltip: 'Drag to move',
        },
        changeTabs: {
          tooltip: 'Change tabs',
        },
        clone: {
          tooltip: 'Clone',
        },
        moveDown: {
          tooltip: 'Move down',
        },
        moveUp: {
          tooltip: 'Move up',
        },
        remove: {
          tooltip: 'Remove',
        },
        width: {
          tooltip: 'Width',
          options: {
            default: 'Default',
            [LG]: 'Large',
            [XL]: 'Full width',
            3: '25%',
            6: '50%',
            9: '75%',
          },
        },
      },
    },
    SELECT_INPUT: {
      none: 'None',
      placeholder: 'Select a {dataType}',
      new: 'New',
      search: 'Search...',
      empty: "No results for '{search}'",
      noResults: 'There are no options',
    },
    [BILLING]: {
      tabs: {
        invoices: 'Invoices',
        subscriptions: 'Subscriptions',
      },
      customerPortal: {
        button: 'Manage billing',
        error: 'Oops! Something went wrong opening your billing portal',
      },
      new: {
        invoice: 'New Invoice',
        subscription: 'New Subscription',
      },
      list: {
        recipient: 'Recipient',
        description: 'Description',
        amount: 'Amount',
        status: 'Status',
        created: 'Created',
        due: 'Due',
        actionsHeader: 'Actions',
        interval: 'Interval',
        currentPeriodEnd: 'Next invoice',
        subscription: 'Subscription',
        filter: 'Filter by company',
        actions: {
          send: 'Send',
          edit: 'Edit',
          delete: 'Delete',
          download: 'Download PDF',
          pay: 'Pay',
          details: 'Details',
          open: {
            customer: 'Open customer in Stripe',
            subscription: 'Open in Stripe',
            link: 'Open',
          },
        },
        invoiceStatus: {
          draft: 'Draft',
          open: 'Open',
          paid: 'Paid',
          uncollectable: 'Uncollectable',
          void: 'Void',
        },
        subscriptionStatus: {
          incomplete: 'Incomplete',
          incomplete_expired: 'Expired',
          trialing: 'Trialing',
          active: 'Active',
          past_due: 'Past due',
          canceled: 'Cancelled',
          unpaid: 'Unpaid',
        },
      },
      form: {
        breadcrumbs: {
          invoices: 'Invoices',
          edit: 'Edit invoice',
          new: {
            invoice: 'New invoice',
            subscription: 'Subscription',
          },
        },
        customer: {
          label: 'Customer',
          placeholder: 'Choose a customer',
        },
        items: {
          label: 'Items',
          add: 'Add item',
          description: {
            label: 'Description',
            placeholder: 'Logo redesign',
          },
          price: {
            label: 'Price',
            placeholder: '$49.99',
          },
          quantity: {
            label: 'Quantity',
            placeholder: '1',
          },
          total: 'Total',
          subtotal: 'Subtotal',
          taxes: {
            add: 'Add taxes',
            rate: {
              label: 'Tax rate',
            },
          },
        },
        memo: 'Memo',
        payment: {
          due: {
            label: 'Due date',
            days: 'days',
          },
        },
        currency: {
          label: 'Currency',
          usd: 'US Dollar (USD)',
          eur: 'Euro (EUR)',
          gbp: 'British Pound (GBP)',
          aud: 'Australian Dollar (AUD)',
          cad: 'Canadian Dollar (CAD)',
          sek: 'Swedish Krona (SEK)',
        },
        interval: {
          label: 'Billing period',
          placeholder: 'Choose a period',
          options: {
            week: 'Weekly',
            month: 'Monthly',
            year: 'Yearly',
            'one-time': 'One time',
          },
        },
        save: {
          invoice: 'Save invoice',
          subscription: 'Save subscription',
        },
        cancel: 'Cancel',
      },
      errors: {
        invoice: {
          create: 'Oops! There was a problem creating your invoice',
          update: 'Oops! There was a problem updating your invoice',
          finalize: 'Oops! There was a problem sending your invoice',
        },
        subscription: {
          create: 'Oops! There was a problem creating your subcription',
        },
      },
    },
    [COLLECTION]: {
      vars: {
        labelField: 'Label',
        valueField: 'Value',
      },
      variables: {
        title: {
          label: 'Title',
          placeholder: 'Title {index}',
        },
        subtitle: {
          label: 'Subtitle',
        },
        image: {
          label: 'Image',
        },
        coverPhoto: {
          label: 'Cover photo',
        },
        description: {
          label: 'Description',
        },
        secondaryText: {
          label: 'Secondary text',
        },
        emptyState: {
          title: {
            placeholder: 'No items could be found',
          },
          subtitle: {
            placeholder:
              "Try adjusting your filter to find what you're looking for",
          },
        },
      },
      pagination: {
        showing: 'Showing {count} of {totalCount} results',
        previous: 'Previous',
        next: 'Next',
      },
      infiniteScroll: {
        showing: {
          1: 'Showing {context} result',
          _: 'Showing {context} results',
        },
      },
      form: {
        new: 'New {dataType}',
        edit: 'Edit {dataType}',
        link: 'Add {dataType}',
        save: 'Save',
        editButton: 'Edit',
        deleteButton: 'Delete',
        success: '{dataType} saved successfully',
        error: 'There was a problem saving your {dataType}',
        placeholder: "Value for '{fieldName}'...",
        invalid: 'Something went wrong submitting the form',
      },
      groups: {
        limit: {
          showAll: 'Show all',
          showLess: 'Show less',
        },
        empty: 'Empty',
      },
    },
    COMMENT: {
      title: {
        0: 'Comments',
        1: '1 Comment',
        _: '{context} Comments',
      },
      toggle: {
        tooltip: 'Record comments',
      },
      new: {
        placeholder: 'Add a comment...',
      },
      empty: {
        title: 'No comments on this record yet',
      },
    },
    DROPZONE: {
      label: 'Drag and drop a file or click to upload',
    },
    [MESSAGING]: {
      placeholder: 'Send a message',
      clientTopMessage:
        "Send a message here to chat to our team. We'll get back to you as soon as possible.",
      empty: {
        title: 'No messages yet!',
        subtitle: 'Send the first message below',
      },
      attachment: 'Attachment',
      errors: {
        send: 'Oops! There was a problem sending your message',
      },
      search: {
        placeholder: 'Search by company name',
      },
      disabled: {
        title: 'Messaging is currently disabled',
        subtitle:
          'Messaging has not been set up for this account, please contact an admin',
      },
    },
    [FILE_SHARING]: {
      empty: {
        title: 'There are no shared files in this folder',
      },
      title: 'Shared files',
      root: 'Root folder',
      googleDriveFile: 'This file is saved in Google Drive',
      new: {
        upload: 'Upload files',
        folder: 'New folder',
        cancel: 'Cancel',
        save: 'Save',
      },
      uploading: 'Uploading files...',
      drop: {
        title: 'Drop files to upload them',
        subtitle: 'Dropped files will instantly be uploaded to this folder',
        reject: 'We cannot upload that file type',
      },
      rename: 'Rename',
      delete: 'Delete',
      downloadSigned: 'View signed file',
      confirmDelete: {
        file: "Are you sure you want to delete '{name}'?",
        folder:
          "Are you sure you want to delete '{name}' and all of its contents?",
      },
      placeholder: 'File name',
      headers: {
        name: 'Name',
        creator: 'Creator',
        created: 'Created',
      },
      errors: {
        upload: 'Oops! There was a problem uploading your file',
        createFolder: 'Oops! There was a problem creating the folder',
        rename: 'Oops! There was a problem renaming the file',
        delete: 'Oops! There was a problem deleting the file',
      },
    },
    [ONBOARDING_TASKS]: {
      save: 'Save',
      progress: 'Progress',
      confirmDelete: 'Really delete?',
      edit: {
        new: 'New Task',
        placeholder: {
          title: 'Task title',
          description: 'Describe your task in more detail',
        },
        task: {
          title: 'Task {count}',
          description: 'You need to...',
        },
        error: 'Oops! There was an error saving your tasks',
      },
      type: {
        company: {
          label: 'Company task',
          help: 'One person per company needs to do this',
          tooltip: 'One person from your company has to do this',
        },
        user: {
          label: 'User task',
          help: 'Each user needs to do this',
          tooltip: 'You have to do this',
        },
      },
    },
    uploads: {
      sizeError:
        "File '{fileName}' is too large to upload. Please choose a file smaller than 45mb",
    },
    copied: 'Copied!',
  },
};

export default en;
