import React, { useCallback, useEffect, useState } from 'react';
import { Portal, Transition } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { darkModeColors } from '../constants/darkModeColors';
import useDarkMode from '../utils/hooks/useDarkMode';

type OwnProps = {
  children: React.ReactNode;
  onClose: (...args: any[]) => any;
  rootSelector?: string;
  title: React.ReactNode;
  usePortal: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof RightPanel.defaultProps;

// @ts-expect-error TS(7022): 'RightPanel' implicitly has type 'any' because it ... Remove this comment to see the full error message
const RightPanel = ({
  children,
  className,
  footer,
  title,
  onClose,
  rootSelector,
  usePortal,
  ...rest
}: Props) => {
  const [show, setShow] = useState(null);

  useEffect(() => {
    if (show === null) {
      // @ts-expect-error TS(2345): Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
      setShow(true);
    }
  }, [show]);

  const [isDarkModeEnabled] = useDarkMode();

  const handleClose = useCallback(
    (e: any) => {
      // @ts-expect-error TS(2345): Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
      setShow(false);
      setTimeout(() => {
        onClose(e);
      }, 250);
    },
    [onClose],
  );

  const sidebar = (
    <Transition
      show={!!show}
      enter="ease-out duration-300"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="ease-in duration-300"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
      className={classNames(
        className,
        'w-full max-w-md transition transform border-l',
        'flex-col flex-grow overflow-hidden text-gray-800 z-40 absolute inset-y-0 right-0 shadow',
        isDarkModeEnabled ? darkModeColors.surfaces.elevation0 : 'bg-white',
        isDarkModeEnabled ? darkModeColors.borders.three : '',
      )}
      {...rest}
    >
      <div
        className={`flex justify-between items-center w-full border-b py-3 px-2 shadow ${
          isDarkModeEnabled
            ? `${darkModeColors.surfaces.elevation0} ${darkModeColors.borders.one} ${darkModeColors.text.secondary}`
            : 'bg-white text-gray-600'
        }`}
      >
        <span className="text-base">{title}</span>
        <IconX
          size={14}
          onClick={handleClose}
          className="hover:text-gray-500"
        />
      </div>
      <div
        className={`flex-grow overflow-hidden text-sm ${
          isDarkModeEnabled ? darkModeColors.surfaces.elevation0 : 'bg-gray-50'
        }`}
      >
        {children}
      </div>
      {footer}
    </Transition>
  );

  const root =
    typeof document !== 'undefined' && document.querySelector(rootSelector);

  return usePortal && root ? (
    <Portal>{ReactDOM.createPortal(sidebar, root)}</Portal>
  ) : (
    sidebar
  );
};

RightPanel.defaultProps = {
  className: 'flex',
  rootSelector: '#root',
  usePortal: false,
};

export default RightPanel;
