import React, { useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { getColorShade } from '@noloco/components';
import { FILE } from '../../../../constants/builtInDataTypes';
import {
  BOARD,
  CALENDAR,
  CARDS,
  COLUMNS,
  GANTT,
  MAP,
  TIMELINE,
} from '../../../../constants/collectionLayouts';
import { darkModeColors } from '../../../../constants/darkModeColors';
import { IMAGE } from '../../../../constants/fileTypes';
import { getValuePathForFieldConfig } from '../../../../utils/fields';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import useSelectRecordOnCmdClick from '../../../../utils/hooks/useSelectRecordOnCmdClick';
import { isMultiRelationship } from '../../../../utils/relationships';
import CollectionCardGallery from '../CollectionCardGallery';
import CardFieldCell from './CardFieldCell';

const CardRecordLayout = ({
  actionButtons,
  className,
  children,
  dataType,
  elementId,
  fieldConfigs,
  layout,
  record,
  project,
  rowLink,
  showCardHeroImage,
  transformRecordScope,
  bulkActionsEnabled = false,
  isRowChecked = false,
  handleCheckboxChange,
  selectedRows,
  theme,
}: any) => {
  const [isDarkModeEnabled] = useDarkMode();
  const selectRecordOnCmdClick = useSelectRecordOnCmdClick(
    handleCheckboxChange,
    isRowChecked,
    selectedRows,
  );

  const primaryColor = theme.brandColors.primary;

  const images = useMemo(() => {
    if (!showCardHeroImage) {
      return null;
    }

    const imageFieldConfig = first(fieldConfigs);
    if (!imageFieldConfig || (imageFieldConfig as any).field.type !== FILE) {
      return null;
    }

    // @ts-expect-error TS(2339): Property 'field' does not exist on type 'unknown'.
    const { field, parent } = imageFieldConfig;

    if (isMultiRelationship(field.relationship)) {
      const fileValues = get(
        record,
        [...getValuePathForFieldConfig(field, parent), 'edges'],
        [],
      );

      const imageFiles =
        fileValues &&
        fileValues
          .filter((fileEdge: any) => fileEdge.node.fileType === IMAGE)
          .map((fileEdge: any) => ({
            id: fileEdge.node.id,
            name: fileEdge.node.name,
            src: fileEdge.node.url,
          }));

      if (imageFiles && imageFiles.length > 0) {
        return imageFiles;
      }
    } else {
      const fileValue = get(record, getValuePathForFieldConfig(field, parent));

      if (fileValue && fileValue.fileType === IMAGE) {
        return [
          {
            id: fileValue.id,
            name: fileValue.name,
            src: fileValue.url,
          },
        ];
      }
    }

    return null;
  }, [fieldConfigs, record, showCardHeroImage]);

  const Row = rowLink ? Link : 'div';

  const backgroundColor = isDarkModeEnabled
    ? darkModeColors.surfaces.elevation1
    : 'bg-white';

  const hover = `hover:${
    isDarkModeEnabled ? darkModeColors.surfaces.elevation2 : 'bg-gray-100'
  }`;

  const cardBorderColor = isDarkModeEnabled
    ? darkModeColors.borders.one
    : 'border-gray-200';

  return (
    // @ts-expect-error TS(2322): Type '{ children: any[]; to: any; className: strin... Remove this comment to see the full error message
    <Row
      to={rowLink}
      onClick={selectRecordOnCmdClick}
      className={classNames(
        className,
        backgroundColor,
        hover,
        'flex overflow-hidden hover:bg-opacity-50 relative group',
        {
          'sm:flex-wrap flex-col items-center w-full  h-full':
            layout === CARDS || layout === BOARD,
          'text-xs w-80 flex-shrink-0 flex-wrap sm:w-full mb-3 relative':
            layout === COLUMNS,
          [`border ${cardBorderColor} rounded-lg shadow-lg`]:
            layout !== CALENDAR &&
            layout !== TIMELINE &&
            layout !== GANTT &&
            layout !== MAP,
          'w-screen max-w-xs flex flex-col': [
            CALENDAR,
            TIMELINE,
            GANTT,
          ].includes(layout),
        },
        {
          [darkModeColors.text.primary]: isDarkModeEnabled,
          [`bg-${getColorShade(
            primaryColor,
            100,
          )} bg-opacity-75 dark:bg-${getColorShade(
            primaryColor,
            900,
          )}`]: isRowChecked,
        },
      )}
      data-testid="collection-record"
    >
      {showCardHeroImage && images && (
        // @ts-expect-error TS(2786): 'CollectionCardGallery' cannot be used as a JSX co... Remove this comment to see the full error message
        <CollectionCardGallery
          image={{ hidden: false }}
          images={images}
          index={0}
        />
      )}
      <div className="'w-full flex flex-col p-4 space-y-4 text-xs w-full">
        {children}
        {fieldConfigs.map(
          (
            { field, parent, parentFieldType, config, permissions }: any,
            index: any,
          ) =>
            (index > 0 || !(showCardHeroImage && images)) && (
              <CardFieldCell
                // @ts-expect-error TS(2322): Type '{ config: any; dataType: any; elementId: any... Remove this comment to see the full error message
                config={config}
                dataType={parentFieldType || dataType}
                elementId={elementId}
                field={field}
                key={`${field.name}:${(parentFieldType || dataType).name}`}
                parent={parent}
                permissions={permissions}
                record={record}
                project={project}
                transformRecordScope={transformRecordScope}
                bulkActionsEnabled={bulkActionsEnabled}
                isRowChecked={isRowChecked}
                selectedRows={selectedRows}
              />
            ),
        )}
      </div>
      {actionButtons}
    </Row>
  );
};

export default withTheme(CardRecordLayout);
