export const FREE: AccountPlan = 'FREE';

// DEPRECATED
export const STARTER: AccountPlan = 'STARTER';
export const PROFESSIONAL: AccountPlan = 'PROFESSIONAL';

// Account based plans
export const TEAM: AccountPlan = 'TEAM';
export const SCALE: AccountPlan = 'SCALE';

// User based plans
export const PRO: AccountPlan = 'PRO';
export const BUSINESS: AccountPlan = 'BUSINESS';

// Custom plans
export const ENTERPRISE: AccountPlan = 'ENTERPRISE';
export const EXPERT: AccountPlan = 'EXPERT';

export type AccountPlan =
  | 'FREE'
  | 'STARTER'
  | 'PROFESSIONAL'
  | 'PRO'
  | 'TEAM'
  | 'SCALE'
  | 'BUSINESS'
  | 'ENTERPRISE'
  | 'EXPERT';

const accountPlans = [
  FREE,
  STARTER,
  PROFESSIONAL,
  TEAM,
  PRO,
  SCALE,
  BUSINESS,
  ENTERPRISE,
];

export default accountPlans;
