export const NONE = 'NONE';
export const SUM = 'SUM';
export const COUNT = 'COUNT';
export const AVERAGE = 'AVERAGE';
export const MINIMUM = 'MINIMUM';
export const MAXIMUM = 'MAXIMUM';

const chartAggregations = [SUM, COUNT, AVERAGE, MINIMUM, MAXIMUM];

export type ChartAggregation =
  | 'SUM'
  | 'COUNT'
  | 'AVERAGE'
  | 'MINIMUM'
  | 'MAXIMUM';

export default chartAggregations;
