import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import { FLAT_PLANS } from '../constants/accountPlanTypes';
import {
  AccountPlan,
  BUSINESS,
  ENTERPRISE,
  PROFESSIONAL,
  STARTER,
  TEAM,
} from '../constants/accountPlans';
import {
  Feature,
  LEGACY_PLAN_CONFIG,
  PlanConfig,
  PlanConfigs,
  SEAT_BASED_PLAN_CONFIG,
  USAGE_BASED_PLAN_CONFIG,
  USAGE_BASED_PLAN_EPOCH,
} from '../constants/features';
import { isPaidPlanTrialExpired, isTrialActive } from './billing';

export const FEATURES = Object.keys(
  Object.values(LEGACY_PLAN_CONFIG).reduce(
    (acc, plan) => ({
      ...acc,
      ...plan,
    }),
    {},
  ),
);

export const computePlans = (baseConfigs: any, teamPlan: any): PlanConfigs =>
  Object.entries(baseConfigs).reduce(
    (acc, [planType, basePlan]) => ({
      ...acc,
      [planType]: {
        // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
        ...basePlan,
        ...get(teamPlan, ['customConfig'], {}),
      },
    }),
    {},
  );

const DEPRECATED_PLANS = [STARTER, PROFESSIONAL];

const getBasePlanConfig = (teamPlan: any) => {
  if (!teamPlan) {
    return null;
  }

  const teamPlanLastChangedAt = DateTime.fromJSDate(
    new Date(teamPlan.subscriptionChangedAt),
  );

  if (
    DEPRECATED_PLANS.includes(teamPlan.type) ||
    (teamPlan.type === TEAM && teamPlanLastChangedAt < USAGE_BASED_PLAN_EPOCH)
  ) {
    return LEGACY_PLAN_CONFIG;
  }

  if (!FLAT_PLANS.includes(teamPlan.type)) {
    return SEAT_BASED_PLAN_CONFIG;
  }

  return USAGE_BASED_PLAN_CONFIG;
};

export const getPlanConfigs = (teamPlan: any): PlanConfigs | null => {
  const baseConfigs = getBasePlanConfig(teamPlan);
  if (!baseConfigs) {
    return null;
  }

  return computePlans(baseConfigs, teamPlan);
};

export const getPlanConfig = (teamPlan: any): PlanConfig | null => {
  if (!teamPlan) {
    return null;
  }

  const baseConfigs = getBasePlanConfig(teamPlan);
  return { ...get(baseConfigs, [teamPlan.type]), ...teamPlan.customConfig };
};

export const isFeatureEnabledForPlan = (
  teamPlan: any,
  feature: Feature,
  currentNumber: any,
) => {
  const config = getPlanConfig(teamPlan);

  if (!config) {
    return false;
  }

  const featureLimit = config[feature];
  // No restriction for this feature on this plan
  if (isNil(featureLimit)) {
    return true;
  }

  if (featureLimit === false || featureLimit === true) {
    return featureLimit;
  }

  return !isNaN(currentNumber) && featureLimit > currentNumber;
};

export const isFeatureEnabled = (
  teamPlan: any,
  feature: Feature,
  currentNumber: any,
): boolean => {
  if (!teamPlan) {
    return false;
  }

  if (isPaidPlanTrialExpired(teamPlan)) {
    return false;
  }

  if (isTrialActive(teamPlan)) {
    const trialType = BUSINESS;

    return isFeatureEnabledForPlan(
      { ...teamPlan, type: trialType },
      feature,
      currentNumber,
    );
  }

  return isFeatureEnabledForPlan(teamPlan, feature, currentNumber);
};

export const getValidPlanLangKey = (accountPlan: AccountPlan | null) =>
  accountPlan ?? ENTERPRISE;
