import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  TextArea as TailwindTextTextArea,
  withTheme,
} from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { validationBorder } from '../../utils';
import ErrorText from '../form/ErrorText';
import { getInputStyles } from './TextInput';
import { ROUNDED_LARGE } from './inputStyles';

/*
(ts-migrate) TODO: Migrate the remaining prop types
...TailwindTextTextArea.propTypes
*/
type Props = {
  className?: string;
  onChange?: (...args: any[]) => any;
  style?: any; // TODO: oneOfWithDefault(inputStyles)
  styleObj?: any;
};

export const TextArea = forwardRef<any, Props>(
  (
    {
      // @ts-expect-error TS(2339): Property 'bg' does not exist on type 'Props'.
      bg,
      className,
      // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Props'... Remove this comment to see the full error message
      disabled,
      // @ts-expect-error TS(2339): Property 'validationError' does not exist on type ... Remove this comment to see the full error message
      validationError,
      // @ts-expect-error TS(2339): Property 'value' does not exist on type 'Props'.
      value,
      onChange,
      style,
      styleObj,
      // @ts-expect-error TS(2339): Property 'surface' does not exist on type 'Props'.
      surface,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Props'.
      theme,
      // @ts-expect-error TS(2339): Property 'rows' does not exist on type 'Props'.
      rows,
      // @ts-expect-error TS(2339): Property 'minRows' does not exist on type 'Props'.
      minRows,
      ...rest
    },
    ref,
  ) => {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
      setLocalValue(value);
    }, [value]);

    const handleOnChange = (event: any) => {
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onChange(event);
      setLocalValue(event.target.value);
    };

    const styleProps = useMemo(
      () =>
        getInputStyles({
          bg,
          theme,
          disabled,
          surface,
          style,
        }),
      [bg, disabled, style, surface, theme],
    );

    return (
      <>
        <TailwindTextTextArea
          className={classNames(
            className,
            'text-sm sm:text-base rounded-lg',
            validationBorder(validationError, theme),
          )}
          disabled={disabled}
          onChange={handleOnChange}
          style={styleObj}
          {...{ 'ring-focus': 2 }}
          {...styleProps}
          {...rest}
          value={localValue || ''}
          rows={rows ? Math.max(minRows, rows) : minRows}
          ref={ref}
        />
        {validationError && <ErrorText>{validationError}</ErrorText>}
      </>
    );
  },
);

TextArea.defaultProps = {
  className: '',
  style: ROUNDED_LARGE,
  // @ts-expect-error TS(2322): Type '{ className: string; style: string; surface:... Remove this comment to see the full error message
  surface: 'default',
  onChange: () => null,
  placeholder: 'Placeholder',
  minRows: 1,
};

export default withTheme(TextArea);
