import React from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { darkModeColors } from '../constants/darkModeColors';

const MessagesIcon = ({ className, text, isDarkModeEnabled }: any) => (
  <Box
    className={classNames(className, 'w-56 mb-8 fill-current')}
    is="svg"
    viewBox="0 0 743 497"
    fill="none"
    text={text}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M74.125 8H450.443C463.202 8.01437 475.435 13.0893 484.457 22.1114C493.479 31.1335 498.554 43.366 498.568 56.1251V262.001C498.554 274.76 493.479 286.993 484.457 296.015C475.435 305.037 463.202 310.112 450.443 310.126H74.125C61.3659 310.112 49.1334 305.037 40.1113 296.015C31.0892 286.993 26.0143 274.76 26 262.001V56.1251C26.0143 43.366 31.0892 31.1335 40.1113 22.1114C49.1334 13.0893 61.3658 8.01437 74.125 8V8Z"
      fill={isDarkModeEnabled ? darkModeColors.icons.secondary : '#F2F2F2'}
    />
    <path
      d="M74.1245 21.8542H450.443C459.529 21.8644 468.24 25.4783 474.665 31.9032C481.09 38.328 484.704 47.039 484.714 56.1251V262.001C484.704 271.087 481.09 279.798 474.665 286.223C468.24 292.648 459.529 296.262 450.443 296.272H74.1245C65.0385 296.262 56.3276 292.648 49.9028 286.223C43.4781 279.798 39.8642 271.087 39.854 262.001V56.1251C39.8642 47.0391 43.4781 38.3281 49.9028 31.9033C56.3276 25.4785 65.0385 21.8645 74.1245 21.8542Z"
      fill={isDarkModeEnabled ? darkModeColors.icons.tertiary : 'white'}
    />
    <path
      d="M77.7966 383.836C76.661 383.833 75.5373 383.606 74.4897 383.168C72.9261 382.539 71.5887 381.453 70.6525 380.052C69.7163 378.651 69.2249 377 69.2427 375.315V304.145L164.125 300.953L83.7529 381.325C82.9757 382.117 82.049 382.747 81.0265 383.178C80.0041 383.609 78.9062 383.833 77.7966 383.836V383.836Z"
      fill={isDarkModeEnabled ? darkModeColors.icons.secondary : '#F2F2F2'}
    />
    <path
      d="M403.312 73.063H120.339C109.01 73.063 99.8257 82.2473 99.8257 93.5767V93.5768C99.8257 104.906 109.01 114.091 120.339 114.091H403.312C414.641 114.091 423.826 104.906 423.826 93.5767C423.826 82.2473 414.641 73.063 403.312 73.063Z"
      fill={isDarkModeEnabled ? darkModeColors.icons.secondary : '#F2F2F2'}
    />
    <path
      d="M403.77 138.549H120.798C109.468 138.549 100.284 147.734 100.284 159.063V159.063C100.284 170.392 109.468 179.577 120.798 179.577H403.77C415.1 179.577 424.284 170.392 424.284 159.063C424.284 147.734 415.1 138.549 403.77 138.549Z"
      fill={isDarkModeEnabled ? darkModeColors.icons.secondary : '#F2F2F2'}
    />
    <path
      d="M404.229 204.035H121.256C109.927 204.035 100.743 213.22 100.743 224.549V224.549C100.743 235.879 109.927 245.063 121.256 245.063H404.229C415.558 245.063 424.743 235.879 424.743 224.549C424.743 213.22 415.558 204.035 404.229 204.035Z"
      fill={isDarkModeEnabled ? darkModeColors.icons.secondary : '#F2F2F2'}
    />
    <path
      d="M668.36 113H292.042C279.283 113.014 267.05 118.089 258.028 127.111C249.006 136.133 243.931 148.366 243.917 161.125V367.001C243.931 379.76 249.006 391.993 258.028 401.015C267.05 410.037 279.283 415.112 292.042 415.126H668.36C681.119 415.112 693.352 410.037 702.374 401.015C711.396 391.993 716.471 379.76 716.485 367.001V161.125C716.471 148.366 711.396 136.133 702.374 127.111C693.352 118.089 681.119 113.014 668.36 113V113Z"
      fill="#E6E6E6"
    />
    <path
      d="M668.361 126.854H292.042C282.956 126.864 274.245 130.478 267.82 136.903C261.395 143.328 257.781 152.039 257.771 161.125V367.001C257.781 376.087 261.395 384.798 267.82 391.223C274.245 397.648 282.956 401.262 292.042 401.272H668.361C677.447 401.262 686.158 397.648 692.582 391.223C699.007 384.798 702.621 376.087 702.631 367.001V161.125C702.621 152.039 699.007 143.328 692.582 136.903C686.158 130.478 677.447 126.864 668.361 126.854Z"
      fill={isDarkModeEnabled ? darkModeColors.icons.tertiary : 'white'}
    />
    <path
      d="M639.732 486.325L559.36 405.953L654.243 409.145V480.315C654.26 482 653.769 483.651 652.833 485.052C651.897 486.453 650.559 487.539 648.996 488.168C647.948 488.606 646.824 488.833 645.689 488.836C644.579 488.833 643.481 488.609 642.459 488.178C641.436 487.747 640.51 487.117 639.732 486.325V486.325Z"
      fill="#E6E6E6"
    />
    <path
      d="M397.201 290.063C411.561 290.063 423.201 278.422 423.201 264.063C423.201 249.704 411.561 238.063 397.201 238.063C382.842 238.063 371.201 249.704 371.201 264.063C371.201 278.422 382.842 290.063 397.201 290.063Z"
      fill="currentColor"
    />
    <path
      d="M480.201 290.063C494.561 290.063 506.201 278.422 506.201 264.063C506.201 249.704 494.561 238.063 480.201 238.063C465.842 238.063 454.201 249.704 454.201 264.063C454.201 278.422 465.842 290.063 480.201 290.063Z"
      fill="currentColor"
    />
    <path
      d="M563.201 290.063C577.561 290.063 589.201 278.422 589.201 264.063C589.201 249.704 577.561 238.063 563.201 238.063C548.842 238.063 537.201 249.704 537.201 264.063C537.201 278.422 548.842 290.063 563.201 290.063Z"
      fill="currentColor"
    />
  </Box>
);

export default MessagesIcon;
