import gql from 'graphql-tag';
import {
  fieldFragment,
  permissionRuleFragment,
  publicProjectFragment,
  rollupFragment,
  workflowFragment,
} from '@noloco/core/src/queries/core';

const dataTypeFragment = `
  id
  name
  apiName
  display
  internal
  fields {
    ${fieldFragment}
  }
  primaryField {
    id
    name
    apiName
  }
  rollups {
    ${rollupFragment}
  }
  workflows {
    ${workflowFragment}
  }
  permissions {
    ${permissionRuleFragment}
  }
  permissionsEnabled
  readOnly
  source {
    id
    type
    collectionType
    connection
    display
    syncEnabled
  }
`;

const apiEndpointFragment = `
  id
  name
  display
  method
  type
  path
  body
  parameters {
    id
    name
    dataType
    testValue
  }
  headers {
    key
    value
  }
  dataShape
  transformer
`;

const apiFragment = `
  id
  name
  display
  baseUrl
  headers {
    key
    value
  }
  authentication
  options
  endpoints {
    ${apiEndpointFragment}
  }
`;

const projectFragment = `
  ${publicProjectFragment}
  publishedVersion
  live
  template
  hasExternalUsers
  internalDomains
  hasUnpublishedChanges
  workflows
  domains {
    id
    name
  }
  apis {
    ${apiFragment}
  }
  users {
    createdAt
    user {
      id
      email
      firstName
      lastName
    }
  }
  integrations {
    stripe {
      id
      account {
        id
        chargesEnabled
        country
        defaultCurrency
        payoutsEnabled
      }
    }
    airtable {
      id
    }
    google {
      signIn {
        id
        clientId
      }
      drive {
        id
      }
    }
    smtp {
      id
      from
      host
      secure
      port
      username
      password
    }
  }
  apiKeys {
    user
    project
  }
  creator {
    id
    createdAt
  }
`;

const templateFragment = `
  id
  name
  title
  thumbnail
  airtableBaseLink
  previewLink
  templateInfoUrl
  description
  category
  dataTypeSourceType
  projects {
    id
    name
    dataSourceLink
    dataSourceType
    previewLink
  }
`;

export const PROJECT_QUERY_STRING = `
  query project ($projectId: String!) {
    project(projectId: $projectId) {
      ${projectFragment}
    }
  }
`;

export const PROJECT_QUERY = gql`
  ${PROJECT_QUERY_STRING}
`;

export const BILLING_PLAN_QUERY = gql`
  query billingPlan {
    billingPlan {
      type
      team {
        name
        id
      }
      paymentMethodId
      createdAt
      trialEnd
      periodEnd
      status
      subscriptionChangedAt
      customConfig
      addons {
        externalUsers {
          enabled
          domains
        }
      }
      usage {
        plan {
          activeUsers
          activeWorkflows
          builders
          projects
          teamMembers
          workflowRuns
        }
        project {
          filesGb
          records
          userRoles
        }
      }
    }
  }
`;

const dataTypeHintFragment = `
  hint
`;

export const IS_VALID_PROJECT_NAME_QUERY = gql`
  query isValidProjectName($projectId: String!) {
    isValidProjectName(projectId: $projectId)
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $name: String!
    $path: [String!]!
    $value: JSON
    $id: String
  ) {
    updateProject(name: $name, path: $path, value: $value, id: $id) {
      id
      elements
      settings
      workflows
      hasUnpublishedChanges
    }
  }
`;

export const UPDATE_PROJECT_STATUS = gql`
  mutation updateProjectStatus($name: String!, $live: Boolean!) {
    updateProjectStatus(name: $name, live: $live) {
      ${projectFragment}
    }
  }
`;

export const CREATE_DATA_ENVIRONMENT_FOR_PROJECT = gql`
  mutation createDataEnvironmentForProject(
    $projectName: String!
    $name: String!
    $description: String!
    $color: String!
  ) {
    createDataEnvironmentForProject(
      projectName: $projectName
      name: $name
      description: $description
      color: $color
    ) {
      id
      name
      description
      color
    }
  }
`;

export const GET_DATA_ENVIRONMENTS_FOR_PROJECT = gql`
  query projectDataEnvironments($projectName: String!) {
    projectDataEnvironments(projectName: $projectName) {
      id
      name
      description
      color
    }
  }
`;

export const UPDATE_DATA_ENVIRONMENT_FOR_PROJECT = gql`
  mutation updateDataEnvironmentForProject(
    $id: Int!
    $projectName: String!
    $name: String!
    $description: String!
    $color: String!
  ) {
    updateDataEnvironmentForProject(
      id: $id
      projectName: $projectName
      name: $name
      description: $description
      color: $color
    ) {
      id
      name
      description
      color
    }
  }
`;

export const DELETE_DATA_ENVIRONMENT_FOR_PROJECT = gql`
  mutation deleteDataEnvironmentForProject($projectName: String!, $id: Int!) {
    deleteDataEnvironmentForProject(id: $id, projectName: $projectName) {
      name
      description
      color
    }
  }
`;

export const PUBLISH_PROJECT_BY_VERSION = gql`
    mutation publishProjectByVersion($name: String!, $targetVersion: Int!) {
        publishProjectByVersion(name: $name, targetVersion: $targetVersion) {
            ${projectFragment}
        }
    }
`;

export const PUBLISH_PROJECT = gql`
    mutation publishProject($name: String!, $publishMessage: String) {
        publishProject(name: $name, publishMessage: $publishMessage) {
            ${projectFragment}
        }
    }
`;

export const REVERT_EDITOR_TO_PROJECT_VERSION = gql`
  mutation revertEditorToProjectVersion($name: String!, $targetVersion: Int!){
    revertEditorToProjectVersion(name: $name, targetVersion: $targetVersion) {
      ${projectFragment}
    }
  }
`;

export const PROJECT_SUBSCRIPTION = gql`
  subscription OnProjectUpdate($projectName: String!) {
    projectUpdated(projectName: $projectName) {
      id
      path
      value
      project {
        id
        name
        elements
        settings
        publishedVersion
        hasUnpublishedChanges
        media {
          id
          name
          fileType
          size
          uri
          mimetype
        }
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject($name: String!) {
    createProject(name: $name) {
      ${projectFragment}
    }
  }
`;

export const CLONE_PROJECT = gql`
  mutation cloneProject($name: String!, $baseName: String!, $dataSourceConnection: JSON, $dataSourceType: DataTypeSourceType) {
    cloneProject(name: $name, baseName: $baseName, dataSourceConnection: $dataSourceConnection, dataSourceType: $dataSourceType) {
      ${projectFragment}
    }
  }
`;
export const DELETE_PROJECT = gql`
  mutation deleteProject($name: String!) {
    deleteProject(name: $name) {
      id
      name
    }
  }
`;

export const TEMPLATES = gql`
  query {
    templates {
      data {
        ${templateFragment}
      }
    }
  }
`;

export const TEMPLATE = gql`
  query template($id: ID!) {
    template(id: $id) {
      ${templateFragment}
    }
  }
`;

export const UPLOAD_PROJECT_MEDIA = gql`
  mutation uploadMedia($projectName: String!, $file: Upload!) {
    uploadMedia(projectName: $projectName, file: $file) {
      id
      uri
      name
      size
      fileType
      mimetype
      encoding
    }
  }
`;

export const ADD_DATA_TYPE = gql`
  mutation addDataType($projectName: String!, $display: String!) {
    addDataType(projectName: $projectName, display: $display) {
      ${dataTypeFragment}
    }
  }
`;

export const UPDATE_DATA_TYPE = gql`
  mutation updateDataType($projectName: String!, $id: ID!, $primaryField: ID) {
    updateDataType(projectName: $projectName, id: $id, primaryField: $primaryField) {
      ${dataTypeFragment}
    }
  }
`;

export const UPDATE_DATA_FIELD_ORDER = gql`
  mutation updateDataFieldOrder($projectName: String!, $dataTypeId: ID!, $order: [DataFieldOrderInput!]!) {
    updateDataFieldOrder(projectName: $projectName, dataTypeId: $dataTypeId, order: $order) {
      ${dataTypeFragment}
    }
  }
`;

export const UPDATE_DATA_FIELD_TYPE = gql`
  mutation updateDataFieldType($projectName: String!, $id: ID!, $type: String!) {
    updateDataFieldType(projectName: $projectName, id: $id, type: $type) {
      ${fieldFragment}
    }
  }
`;

export const ADD_DATA_FIELD = gql`
  mutation addDataField(
    $projectName: String!
    $dataTypeId: Int!
    $display: String!
    $type: String!
    $typeOptions: JSON,
    $unique: Boolean
    $options: [DataFieldOptionInput!]
    $relationship: Relationship
    $reverseDisplay: String
    $autoRelationshipConfig: AutoRelationshipFieldConfigInput
  ) {
    addDataField(
      projectName: $projectName
      dataTypeId: $dataTypeId
      display: $display
      type: $type
      typeOptions: $typeOptions,
      unique: $unique
      options: $options
      relationship: $relationship
      reverseDisplay: $reverseDisplay
      autoRelationshipConfig: $autoRelationshipConfig
    ) {
      ${fieldFragment}
    }
  }
`;

export const UPDATE_DATA_FIELD = gql`
  mutation updateDataField(
    $projectName: String!
    $id: Int!
    $display: String
    $typeOptions: JSON,
    $options: [DataFieldOptionOrderInput!],
  ) {
    updateDataField(
      projectName: $projectName
      id: $id
      display: $display
      typeOptions: $typeOptions
      options: $options
    ) {
      ${fieldFragment}
    }
  }
`;

export const DELETE_DATA_FIELD = gql`
  mutation deleteDataField(
    $projectName: String!
    $dataTypeId: Int!
    $dataFieldId: Int!
  ) {
    deleteDataField(
      projectName: $projectName
      dataTypeId: $dataTypeId
      dataFieldId: $dataFieldId
    ) {
       ${dataTypeFragment} 
    }
  }
`;

export const DELETE_ROLLUP_FIELD = gql`
  mutation deleteDataRollup(
    $projectName: String!
    $dataTypeId: Int!
    $rollupId: Int!
  ) {
    deleteDataRollup(
      projectName: $projectName
      dataTypeId: $dataTypeId
      rollupId: $rollupId
    )
  }
`;

export const DISCONNECT_DATA_SOURCE = gql`
  mutation disconnectDataSource($projectName: String!, $id: ID!) {
    disconnectDataSource(projectName: $projectName, id: $id)
  }
`;

export const DELETE_DATA_TYPE = gql`
  mutation deleteDataType($projectName: String!, $dataTypeId: Int!) {
    deleteDataType(projectName: $projectName, dataTypeId: $dataTypeId)
  }
`;

export const DISABLE_DATA_TYPE = gql`
  mutation disableDataType($projectName: String!, $dataTypeId: Int!) {
    disableDataType(projectName: $projectName, dataTypeId: $dataTypeId)
  }
`;

export const ENABLE_DATA_TYPES = gql`
  mutation enableDataTypes($projectName: String!, $dataTypeIds: [Int!]!) {
    enableDataTypes(projectName: $projectName, dataTypeIds: $dataTypeIds)
  }
`;

export const ADD_DATA_FIELD_OPTION = gql`
  mutation createDataFieldOption($projectName: String!, $dataTypeId: Int!, $dataFieldId: Int!, $option: DataFieldOptionInput!) {
    createDataFieldOption(projectName: $projectName, dataTypeId: $dataTypeId, dataFieldId: $dataFieldId, option: $option) {
      ${fieldFragment}
    }
  }
`;

export const REMOVE_DATA_FIELD_OPTION = gql`
  mutation removeDataFieldOption($projectName: String!, $dataTypeId: Int!, $dataFieldId: Int!, $optionId: Int!) {
    removeDataFieldOption(projectName: $projectName, dataTypeId: $dataTypeId, dataFieldId: $dataFieldId, optionId: $optionId) {
      ${fieldFragment}
    }
  }
`;

export const RENAME_DATA_FIELD_OPTION = gql`
  mutation renameDataFieldOption($projectName: String!, $dataTypeId: Int!, $dataFieldId: Int!, $optionId: Int!, $option: DataFieldOptionInput!) {
    renameDataFieldOption(projectName: $projectName, dataTypeId: $dataTypeId, dataFieldId: $dataFieldId, optionId: $optionId, option: $option) {
      ${fieldFragment}
    }
  }
`;

export const ADD_DATA_ROLLUP = gql`
  mutation addDataRollup(
    $projectName: String!
    $dataTypeId: Int!
    $display: String!
    $relatedField: String!
    $field: String!
    $aggregation: RollupAggregation!
  ) {
    addDataRollup(
      projectName: $projectName
      dataTypeId: $dataTypeId
      display: $display
      relatedField: $relatedField
      field: $field
      aggregation: $aggregation
    ) {
      ${rollupFragment}
    }
  }
`;

export const CONNECT_DATA_SOURCE = gql`
  mutation connectDataSource(
    $projectName: String!
    $display: String!
    $connection: JSON!
    $type: DataTypeSourceType!
  ) {
    connectDataSource(
      projectName: $projectName
      display: $display
      connection: $connection
      type: $type
    ) {
      id
      name
      display
      externalId
      connection
      type
      hasSynced
      types {
        ${dataTypeFragment}
      }
    }
  }
`;

export const DISABLE_DATA_SOURCE_TYPES = gql`
  mutation disableDataSourceTypes(
    $id: ID!
    $projectName: String!
    $dataTypeIds: [ID!]!
    $type: DataTypeSourceType!
  ) {
    disableDataSourceTypes(
      projectName: $projectName
      id: $id
      dataTypeIds: $dataTypeIds
      type: $type
    ) {
      id
      name
      display
      connection
      type
      hasSynced
      types {
        ${dataTypeFragment}
      }
    }
  }
`;

export const UPDATE_DATA_SOURCE = gql`
  mutation updateDataSource(
    $id: ID!
    $projectName: String!
    $externalId: String!
    $display: String!
    $connection: JSON!
    $type: DataTypeSourceType!
    $environmentId: Int
  ) {
    updateDataSource(
      id: $id
      projectName: $projectName
      externalId: $externalId
      display: $display
      connection: $connection
      type: $type
      environmentId: $environmentId
    ) {
      id
      name
      display
      externalId
      connection
      type
      hasSynced
      types {
        ${dataTypeFragment}
      }
    }
  }
`;

export const GET_DATA_SOURCE = gql`
  query dataSource($projectId: String!, $id: ID!) {
    dataSource(projectId: $projectId, id: $id) {
      id
      name
      display
      externalId
      connection
      hasSynced
      environmentConfigs {
        id
        connection
        dataEnvironmentId
        dataSourceId
      }
    }
  }
`;

export const GET_FULL_DATA_SOURCE = gql`
  query dataSource($projectId: String!, $id: ID!) {
    dataSource(projectId: $projectId, id: $id) {
      id
      name
      display
      externalId
      connection
      hasSynced
      types {
        ${dataTypeFragment}
      }
    }
  }
`;

export const GET_DATA_SOURCES = gql`
  query dataSources($projectId: String!) {
    dataSources(projectId: $projectId) {
      id
      name
      display
      externalId
      connection
      hasSynced
      type
      types {
        ${dataTypeFragment}
      }
    }
  }
`;

export const SYNC_DATA_SOURCE_SCHEMA = gql`
  mutation syncDataSourceSchema($projectName: String!, $id: ID!) {
    syncDataSourceSchema(projectName: $projectName, id: $id)
  }
`;

export const SYNC_DATA_SOURCE_DATA = gql`
  mutation syncDataSourceData(
    $projectName: String!
    $id: ID!
    $isFirstSync: Boolean
    $dataEnvironmentId: Int
  ) {
    syncDataSourceData(
      projectName: $projectName
      id: $id
      isFirstSync: $isFirstSync
      dataEnvironmentId: $dataEnvironmentId
    )
  }
`;

const USER_LIST_FRAGMENT = `
  id
  name
  display
  dataType {
    ${dataTypeFragment}
  }
  emailField {
    ${fieldFragment}
  }
  firstNameField {
    ${fieldFragment}
  }
  lastNameField {
    ${fieldFragment}
  }
  companyField {
    ${fieldFragment}
  }
  paymentsIdField {
    ${fieldFragment}
  }
  fileSharingIdField {
    ${fieldFragment}
  }
  defaultRoleId
  source {
    type
    collectionType
    name
    display
    id
  }
  dataSource {
    name
    display
    type
    externalId
    hasSynced
  }
  conditionalInclude
  conditionalRoles {
    id
    roleId
    conditions
  }
`;

export const GET_DATA_TYPE = gql`
  query dataType($projectId: String!, $id: ID!) {
    dataType(projectId: $projectId, id: $id) {
      ${dataTypeFragment}
    }
  }
`;

export const GET_DISABLED_DATA_TYPES = gql`
  query disabledDataTypes($projectId: String!, $dataSourceId: ID!) {
    disabledDataTypes(projectId: $projectId, dataSourceId: $dataSourceId) {
      id
      display
    }
  }
`;

export const GET_USER_LISTS = gql`
  query userLists($projectId: String!) {
    userLists(projectId: $projectId) {
      ${USER_LIST_FRAGMENT}
    }
  }
`;

export const ADD_USER_LIST = gql`
  mutation addUserList($projectName: String!, $userList: UserListInput!) {
    addUserList(projectName: $projectName, userList: $userList) {
      ${USER_LIST_FRAGMENT}
    }
  }
`;

export const UPDATE_USER_LIST = gql`
  mutation updateUserList($projectName: String!, $userList: UserListInput!) {
    updateUserList(projectName: $projectName, userList: $userList) {
      ${USER_LIST_FRAGMENT}
    }
  }
`;

export const DELETE_USER_LIST = gql`
  mutation deleteUserList($projectName: String!, $id: ID!, $dataTypeId: ID!) {
    deleteUserList(projectName: $projectName, id: $id, dataTypeId: $dataTypeId)
  }
`;

const AIRTABLE_BASES_FRAGMENT = `
  id
  name
  permissionLevel
  connected
`;

export const GET_AIRTABLE_BASES = gql`
  query airtableBases($projectId: String) {
    airtableBases(projectId: $projectId) {
      ${AIRTABLE_BASES_FRAGMENT}
    }
  }
`;

export const GET_XANO_WORKSPACES = gql`
  query xanoWorkspaces(
    $authToken: String
    $dataSourceId: ID
    $projectId: String
  ) {
    xanoWorkspaces(
      xanoAuthToken: $authToken
      dataSourceId: $dataSourceId
      projectId: $projectId
    ) {
      id
      name
      domain
      customDomain
      instanceName
      instanceDisplay
      description
      connected
    }
  }
`;

const SAML_CONFIG_FRAGMENT = `
  id
  entryPoint
  issuer
  cert
  emailAttribute
  firstNameAttribute
  lastNameAttribute
  defaultRoleId
`;

export const DELETE_SAML_CONFIG = gql`
  mutation deleteSamlConfig($projectName: String!) {
    deleteSamlConfig(projectName: $projectName)
  }
`;

export const GET_SAML_CONFIG = gql`
  query samlConfig($projectId: String!) {
    samlConfig(projectId: $projectId) {
      ${SAML_CONFIG_FRAGMENT}
    }
  }
`;

export const UPDATE_SAML_CONFIG = gql`
  mutation updateSamlConfig($projectName: String!, $samlConfig: SamlConfigInput!) {
    updateSamlConfig(projectName: $projectName, samlConfig: $samlConfig) {
      ${SAML_CONFIG_FRAGMENT}
    }
  }
`;

export const TOGGLE_DATA_TYPE_PERMISSIONS_ENABLED = gql`
 mutation togglePermissionsEnabled($projectName: String!, $dataTypeId: Int!) {
  togglePermissionsEnabled(
      projectName: $projectName
      dataTypeId: $dataTypeId
  ) {
    ${dataTypeFragment}
  }
 }
`;

export const ADD_DATA_TYPE_PERMISSION_RULE = gql`
 mutation addPermissionRule($projectName: String!, $permissionRule: PermissionRuleInput!) {
  addPermissionRule(
      projectName: $projectName
      permissionRule: $permissionRule
  ) {
    ${permissionRuleFragment}
  }
 }
`;

export const CLONE_DATA_TYPE_PERMISSION_RULE = gql`
  mutation clonePermissionRule(
    $projectName: String!
    $dataTypeId: Int!
    $id: Int!
  ) {
    clonePermissionRule(
      projectName: $projectName
      dataTypeId: $dataTypeId
      id: $id
    ) {
      ${permissionRuleFragment}
    }
  }
`;

export const DELETE_DATA_TYPE_PERMISSION_RULE = gql`
  mutation deletePermissionRule(
    $projectName: String!
    $dataTypeId: Int!
    $id: Int!
  ) {
    deletePermissionRule(
      projectName: $projectName
      dataTypeId: $dataTypeId
      id: $id
    )
  }
`;

export const UPDATE_DATA_TYPE_PERMISSION_RULE = gql`
 mutation updatePermissionRule($projectName: String!, $permissionRule: PermissionRuleInput!) {
  updatePermissionRule(
      projectName: $projectName
      permissionRule: $permissionRule
  ) {
    ${permissionRuleFragment}
  }
 }
`;

export const ADD_DATA_WORKFLOW = gql`
  mutation addDataWorkflow(
    $projectName: String!
    $dataTypeId: Int!
    $name: String!
    $trigger: ActionType!
  ) {
    addDataWorkflow(
      projectName: $projectName
      dataTypeId: $dataTypeId
      name: $name
      trigger: $trigger
    ) {
      ${workflowFragment}
    }
  }
`;

export const UPDATE_DATA_WORKFLOW = gql`
    mutation updateDataWorkflow(
        $projectName: String!
        $workflowId: ID!
        $name: String!
        $description: String
        $trigger: ActionType!
        $enabled: Boolean
    ) {
        updateDataWorkflow(
            projectName: $projectName
            workflowId: $workflowId
            name: $name
            description: $description
            trigger: $trigger
            enabled: $enabled
        ) {
            ${workflowFragment}
        }
    }
`;

export const CLONE_DATA_WORKFLOW = gql`
    mutation cloneDataWorkflow(
        $projectName: String!
        $workflowId: ID!
    ) {
        cloneDataWorkflow(
            projectName: $projectName
            workflowId: $workflowId
        ) {
            ${workflowFragment}
        }
    }
`;

export const REMOVE_DATA_WORKFLOW = gql`
  mutation removeDataWorkflow($projectName: String!, $workflowId: ID!) {
    removeDataWorkflow(projectName: $projectName, workflowId: $workflowId)
  }
`;

export const ADD_API = gql`
    mutation addApi($projectName: String!, $api: ApiInput!) {
        addApi(
            projectName: $projectName
            api: $api
        ) {
            ${apiFragment}
        }
    }
`;

export const UPDATE_API = gql`
    mutation updateApi($projectName: String!, $api: ApiInput!) {
        updateApi(
            projectName: $projectName
            api: $api
        ) {
            ${apiFragment}
        }
    }
`;

export const AUTHORIZE_OAUTH2_API = gql`
  mutation authorizeOAuth2Api($projectName: String!, $apiId: String!, $accessCode: String!) {
    authorizeOAuth2Api(
      projectName: $projectName
      apidId: $apiId
      accessCode: $accessCode
    ) {
      ${apiFragment}
    }
  }
`;

export const ADD_API_ENDPOINT = gql`
    mutation addEndpoint($projectName: String!, $apiId: String!, $endpoint: ApiEndpointInput!) {
        addEndpoint(
            projectName: $projectName
            apiId: $apiId
            endpoint: $endpoint
        ) {
            ${apiEndpointFragment}
        }
    }
`;

export const UPDATE_API_ENDPOINT = gql`
    mutation updateEndpoint($projectName: String!, $apiId: String!, $endpoint: ApiEndpointInput!) {
        updateEndpoint(
            projectName: $projectName
            apiId: $apiId
            endpoint: $endpoint
        ) {
            ${apiEndpointFragment}
        }
    }
`;

export const TEST_API_ENDPOINT = gql`
  mutation testEndpoint(
    $projectName: String!
    $apiId: String!
    $endpoint: ApiEndpointInput!
  ) {
    testEndpoint(
      projectName: $projectName
      apiId: $apiId
      endpoint: $endpoint
    ) {
      status
      data
    }
  }
`;

export const TEST_DATA_SOURCE_ENDPOINT = gql`
  mutation testDataSourceEndpoint(
    $projectName: String!
    $dataSourceId: ID!
    $api: ApiInput!
    $endpoint: ApiEndpointInput!
  ) {
    testDataSourceEndpoint(
      projectName: $projectName
      dataSourceId: $dataSourceId
      api: $api
      endpoint: $endpoint
    ) {
      status
      data
      error
    }
  }
`;

export const CONNECT_STRIPE = gql`
  mutation connectStripe($projectName: String!) {
    connectStripe(projectName: $projectName) {
      account {
        id
        businessType
        businessProfile {
          mcc
          name
          productDescription
          supportEmail
        }
        chargesEnabled
        country
        defaultCurrency
        payoutsEnabled
      }
      url
    }
  }
`;

export const CONNECT_GOOGLE_SIGN_IN = gql`
  mutation connectGoogleSignIn($projectName: String!, $clientId: String!) {
    connectGoogleSignIn(projectName: $projectName, clientId: $clientId) {
      signIn {
        id
        clientId
      }
    }
  }
`;

export const INVITE_USERS = gql`
  mutation inviteUsers($projectName: String!, $userEmails: [String!]!) {
    inviteUsers(projectName: $projectName, userEmails: $userEmails) {
      createdAt
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const REMOVE_PROJECT_BUILDER = gql`
  mutation removeProjectBuilder($projectName: String!, $userId: ID!) {
    removeProjectBuilder(projectName: $projectName, userId: $userId)
  }
`;

export const ADD_DOMAIN = gql`
  mutation addDomain($projectName: String!, $name: String!) {
    addDomain(projectName: $projectName, name: $name) {
      id
      name
    }
  }
`;

export const REMOVE_DOMAIN = gql`
  mutation removeDomain($projectName: String!, $id: ID!) {
    removeDomain(projectName: $projectName, id: $id) {
      id
      name
    }
  }
`;

export const CREATE_PUBLIC_FORM = gql`
    mutation createPublicForm(
        $projectName: String!
        $formName: String!
        $dataTypeId: ID!
        $elementId: String!
        $onSubmitMessage: String!
    ) {
        createPublicForm(
            projectName: $projectName
            formName: $formName
            dataTypeId: $dataTypeId
            elementId: $elementId
            onSubmitMessage: $onSubmitMessage
        ) {
            id
            dataType {
                ${dataTypeFragment}
            }
            elementId
            referenceId
            createdAt
            creator {
                id
                email
                firstName
                lastName
            }
        }
    }
`;

export const DELETE_PUBLIC_FORM = gql`
  mutation deletePublicForm(
    $projectName: String!
    $publicFormReferenceId: String!
    $elementId: String!
    $dataTypeId: ID!
  ) {
    deletePublicForm(
      projectName: $projectName
      publicFormReferenceId: $publicFormReferenceId
      elementId: $elementId
      dataTypeId: $dataTypeId
    )
  }
`;

export const UPDATE_SMTP_CONFIG = gql`
  mutation updateSmtpConfig($projectName: String!, $config: SmtpConfigInput!) {
    updateSmtpConfig(projectName: $projectName, config: $config) {
      id
      from
      host
      port
      secure
      username
      password
    }
  }
`;

export const CUSTOM_QUERY = gql`
  query customQuery($projectName: String!, $dataTypeId: ID!) {
    customQuery(projectName: $projectName, dataTypeId: $dataTypeId) {
      display
      primaryKeyName
      query
    }
  }
`;

export const TEST_DATA_SOURCE_CUSTOM_QUERY = gql`
  query testDataSourceCustomQuery(
    $projectName: String!
    $dataSourceId: ID!
    $query: String!
  ) {
    testDataSourceCustomQuery(
      projectName: $projectName
      dataSourceId: $dataSourceId
      query: $query
    ) {
      sample
      schema {
        apiName
        display
        name
        options {
          color
          display
          name
          order
        }
        type
        typeOptions
      }
    }
  }
`;

export const PROJECT_VERSION_HISTORY_QUERY = gql`
  query projectVersionHistory($projectName: String!) {
    projectVersionHistory(projectName: $projectName) {
      publishedBy {
        firstName
        lastName
        email
      }
      publishedAt
      publishMessage
      version
    }
  }
`;

export const RECOMMEND_DATA_TYPES = gql`
  query recommendDataTypes($prompt: String!) {
    recommendDataTypes(prompt: $prompt) {
      dataTypes {
        description
        display
        name
        fields {
          display
          name
          relationship
          reverseDisplayName
          reverseName
          type
          typeOptions
          unique
        }
        sample
        source {
          id
          type
        }
      }
      data {
        name
        records
      }
    }
  }
`;

export const SAMPLE_RECOMMEND_DATA_TYPE = gql`
  query sampleRecommendedDataType(
    $recommendedDataType: RecommendedDataTypeInput!
  ) {
    sampleRecommendedDataType(recommendedDataType: $recommendedDataType) {
      sample
    }
  }
`;

export const EDITOR_HINT = gql`
  query editorHint($projectName: String!, $dataTypeId: ID!, $type: DataTypeHintType!) {
    editorHint(projectName: $projectName, dataTypeId: $dataTypeId, type: $type) {
      ${dataTypeHintFragment}
    }
  }
`;

export const FEEDBACK_EDITOR_HINT = gql`
  mutation feedbackEditorHint(
    $projectName: String!
    $dataTypeId: ID!
    $type: DataTypeHintType!
    $feedback: DataTypeHintFeedback!
  ) {
    feedbackEditorHint(
      projectName: $projectName
      dataTypeId: $dataTypeId
      type: $type
      feedback: $feedback
    ) {
      ${dataTypeHintFragment}
    }
  }
`;

export const GENERATE_EDITOR_HINTS = gql`
  mutation generateEditorHints($projectName: String!, $dataTypeId: ID!) {
    generateEditorHints(projectName: $projectName, dataTypeId: $dataTypeId)
  }
`;

export const CREATE_RECOMMENDED_DATA_TYPES = gql`
  mutation createRecommendedDataTypes(
    $projectName: String!
    $recommendedDataTypes: [RecommendedDataTypeInput!]!
  ) {
    createRecommendedDataTypes(
      projectName: $projectName
      recommendedDataTypes: $recommendedDataTypes
    ) {
      ${projectFragment}
    }
  }
`;

export const ADD_DATA_SOURCE_CUSTOM_QUERY = gql`
  mutation addDataSourceCustomQuery(
    $projectName: String!
    $dataSourceId: ID!
    $display: String!
    $primaryKeyName: String!
    $query: String!
  ) {
    addDataSourceCustomQuery(
      projectName: $projectName
      dataSourceId: $dataSourceId
      display: $display
      primaryKeyName: $primaryKeyName
      query: $query
    ) {
      ${dataTypeFragment}
    }
  }
`;

export const UPDATE_DATA_SOURCE_CUSTOM_QUERY = gql`
  mutation updateDataSourceCustomQuery(
    $projectName: String!
    $dataTypeId: ID!
    $display: String!
    $primaryKeyName: String!
    $query: String!
  ) {
    updateDataSourceCustomQuery(
      projectName: $projectName
      dataTypeId: $dataTypeId
      display: $display
      primaryKeyName: $primaryKeyName
      query: $query
    ) {
      ${dataTypeFragment}
    }
  }
`;
