import React, { useMemo } from 'react';
import get from 'lodash/get';
import { Loader } from '@noloco/components';
import { formatDisplayField } from '../../../utils/dataTypes';
import Title from '../Title';
import RecordCommentsButton from './RecordCommentsButton';
import ViewBreadcrumbs from './ViewBreadcrumbs';

const getTitleValue = (titleField: any, data: any) =>
  titleField && !titleField.relationship
    ? formatDisplayField(titleField, get(data, titleField.apiName))
    : null;

const RecordViewTitle = ({
  backLink,
  children,
  classNames,
  comments = {},
  data,
  dataType,
  icon,
  loading,
  isSplitLayout,
  name,
  rootPathname,
  title,
  subtitle,
  hideBreadcrumbs,
}: any) => {
  const titleField = useMemo(
    () => title && dataType && dataType.fields.getByName(title),
    [dataType, title],
  );

  const titleValue = useMemo(() => getTitleValue(titleField, data), [
    data,
    titleField,
  ]);

  return (
    <Title
      className="py-6 sm:py-3 w-full max-w-6xl mx-auto"
      breadcrumbs={
        !hideBreadcrumbs && (
          <div className="flex items-start">
            <ViewBreadcrumbs
              className={classNames(
                'mb-2 sm:mb-1 sm:text-xs',
                isSplitLayout ? 'hidden md:flex' : 'flex',
              )}
              additionalLinks={
                !isSplitLayout
                  ? [
                      {
                        to: `/view/${get(data, 'uuid')}`,
                        name: !loading ? (
                          (titleField && titleValue) || dataType.display
                        ) : (
                          <Loader size="sm" />
                        ),
                      },
                    ]
                  : []
              }
              backLink={backLink}
              icon={isSplitLayout ? { name: 'ArrowBack' } : icon}
              name={name}
              rootPathname={rootPathname}
            />
            {comments.show && <RecordCommentsButton record={data} />}
          </div>
        )
      }
      subtitle={{
        hidden: !subtitle,
        value: subtitle,
      }}
      title={{
        hidden: !titleField || titleField.relationship,
        value: !loading ? (
          titleValue
        ) : (
          <Loader size="sm" className="text-gray-600" type="Dots" />
        ),
      }}
    >
      {children}
    </Title>
  );
};

export default RecordViewTitle;
