import React, { memo } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { Loader } from '@noloco/components';
import {
  BOOLEAN,
  DECIMAL,
  INTEGER,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import { QUERY } from '../constants/endpointTypes';
import { getApiRequestQueryString } from '../queries/project';
import { getApiEndpointQueryName } from '../utils/apis';
import cappedMemoize from '../utils/cappedMemoize';
import { formatBooleanValue } from '../utils/data';
import usePrevious from '../utils/hooks/usePrevious';
import useSetScopeFieldValue from '../utils/hooks/useSetScopeFieldValue';

// @ts-expect-error TS(7006): Parameter 'loading' implicitly has an 'any' type.
const getScope = (loading, data) => ({
  ...data,
  loading,
});

const formatParam = (param: any, value: any) => {
  switch (param.dataType) {
    case DECIMAL:
      return parseFloat(value);
    case INTEGER:
      return parseInt(value, 10);
    case BOOLEAN:
      return formatBooleanValue(value);
    default:
    case TEXT:
      return String(value);
  }
};

export const getVariables = cappedMemoize(
  (parameters, params) =>
    parameters.reduce(
      // @ts-expect-error TS(7006): Parameter 'paramAcc' implicitly has an 'any' type.
      (paramAcc, param) => ({
        ...paramAcc,

        [camelCase(param.name)]:
          !isNil(params[param.name]) && params[param.name] !== ''
            ? formatParam(param, params[param.name])
            : formatParam(param, param.testValue),
      }),
      {},
    ),
  {
    maxKeys: 50,
  },
);

export const DataGetQueryWrapper = memo(
  // @ts-expect-error TS(2339): Property 'api' does not exist on type '{}'.
  ({ api, children, elementId, endpoint, variables, projectName }) => {
    const requestString = getApiRequestQueryString(api, endpoint);

    const { loading, data } = useQuery(
      gql`
        ${requestString}
      `,
      {
        variables,
        context: { projectName, projectQuery: true },
        errorPolicy: 'all',
      },
    );

    const apiData = get(data, getApiEndpointQueryName(api, endpoint));
    const previousLoading = usePrevious(loading);

    useSetScopeFieldValue(loading, elementId, getScope(loading, apiData));

    // Using previousLoading make it load until the next tick, when the scope has been updated
    if (loading || previousLoading) {
      return (
        <div className="flex w-full h-full items-center justify-center py-24">
          <Loader />
        </div>
      );
    }
    // @ts-expect-error this expression is not callable
    return children({ loading, rawData: data, variables, data: apiData });
  },
);

// @ts-expect-error TS(2339): Property 'children' does not exist on type '{}'.
const DataWrapper = memo(({ children, elementId, endpoint, project }) => {
  if (!endpoint || !endpoint.endpoint) {
    return children;
  }

  const variablesAreValid = endpoint.endpoint.parameters.every((param: any) => {
    const v = endpoint.params[param.name];
    return v !== undefined && (typeof v !== 'number' || !isNaN(v));
  });

  if (variablesAreValid) {
    const variables = getVariables(
      endpoint.endpoint.parameters,
      endpoint.params,
    );

    if (endpoint.endpoint.type === QUERY) {
      return (
        // @ts-expect-error TS(2322): Type '{ children: () => any; api: any; elementId: ... Remove this comment to see the full error message
        <DataGetQueryWrapper
          api={endpoint.api}
          elementId={elementId}
          params={endpoint.params}
          endpoint={endpoint.endpoint}
          projectName={project.name}
          variables={variables}
        >
          {() => children}
        </DataGetQueryWrapper>
      );
    }
  }
  return children;
});

export default DataWrapper;
