import React from 'react';
import classNames from 'classnames';
import { darkModeColors } from '../../../constants/darkModeColors';
import { RecordEdge } from '../../../models/Record';
import { CollectionField } from '../../../models/View';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { FieldConfig } from '../../../utils/permissions';
import CollectionColumnSummaryCell from './CollectionColumnSummaryCell';

type TableSummaryFooterProps = {
  className: string;
  fieldConfigs: FieldConfig<CollectionField>[];
  edges: RecordEdge[];
  bulkActionsEnabled: boolean;
};

const TableSummaryFooter = ({
  className,
  fieldConfigs,
  edges,
  bulkActionsEnabled,
}: TableSummaryFooterProps) => {
  const [isDarkModeEnabled] = useDarkMode();

  return (
    <tr
      className={classNames(
        className,
        'sticky z-20 shadow-xs text-left border-b text-xs tracking-wider uppercase font-medium',
        `${
          isDarkModeEnabled
            ? `${darkModeColors.surfaces.elevation0} ${darkModeColors.text.primary} ${darkModeColors.borders.one} ${darkModeColors.divides.one}`
            : 'bg-gray-50 border-gray-200 text-gray-800 shadow-t'
        }`,
      )}
    >
      {bulkActionsEnabled && <td className="w-9"></td>}
      {fieldConfigs.map(({ field, config }: any) => (
        <CollectionColumnSummaryCell
          className="py-1"
          config={config}
          key={field.id}
          field={field}
          rows={edges}
        />
      ))}
    </tr>
  );
};

export default TableSummaryFooter;
