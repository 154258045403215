import React, { useMemo } from 'react';
import classNames from 'classnames';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { CUSTOM_VISIBILITY_RULES } from '../../constants/features';
import useMergedScope from '../../utils/hooks/useMergedScope';
import useScopeUser from '../../utils/hooks/useScopeUser';
import {
  shouldCheckVisibilityRules,
  shouldRenderComponent,
} from './withVisibilityRules';

const VisibilityRulesWrapper = ({
  children,
  project,
  editorMode,
  visibilityRules,
  visibilityRulesScope = {},
}: any) => {
  const currentUser = useScopeUser();
  const scope = useMergedScope(visibilityRulesScope);
  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
  const shouldRender = useMemo(() => {
    if (!visibilityRules || !shouldCheckVisibilityRules(visibilityRules)) {
      return true;
    }

    return shouldRenderComponent(
      currentUser,
      visibilityRules,
      project,
      scope,
      customRulesEnabled,
    );
  }, [currentUser, customRulesEnabled, project, scope, visibilityRules]);

  const childrenToRender = useMemo(() => {
    if (!shouldRender && !editorMode) {
      return null;
    }

    if (!shouldRender && editorMode) {
      return React.cloneElement(children, {
        className: classNames(children.props.className, 'opacity-50'),
      });
    }

    return children;
  }, [children, editorMode, shouldRender]);

  return childrenToRender;
};

export default VisibilityRulesWrapper;
