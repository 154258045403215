import React, { useCallback, useMemo, useState } from 'react';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { LG, MD, SM, XL } from '@noloco/components/src/constants/tShirtSizes';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { darkModeColors } from '../../../constants/darkModeColors';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { getText } from '../../../utils/lang';
import { fieldPermissions } from '../../../utils/permissions';
import { isMultiField } from '../../../utils/relationships';
import FilePreviewElement from './FilePreviewElement';

const getFileValues = (
  fieldName: any,
  dataType: any,
  user: any,
  record: any,
  recordScope: any,
  fieldPermissionsEnabled: any,
) => {
  const field = dataType.fields.getByName(fieldName);
  if (!field) {
    return [];
  }

  const permissions = fieldPermissions(
    field,
    fieldPermissionsEnabled && dataType.permissionsEnabled,
    dataType.permissions,
    user,
  );

  if (!permissions.read) {
    return [];
  }

  if (isMultiField(field)) {
    const fieldFiles = get(record, [field.apiName, 'edges'], []);
    return fieldFiles.map((fileEdge: any) => get(fileEdge, 'node'));
  }

  const fieldValue = get(record, [field.apiName]);

  if (fieldValue !== undefined) {
    return [fieldValue];
  }

  return [];
};

const RecordFileGallery = ({
  record,
  size,
  showFileName,
  field,
  dataType,
  editorMode,
}: any) => {
  const { user } = useAuthWrapper();
  const [isDarkModeEnabled] = useDarkMode();
  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);
  const files = useMemo(
    // @ts-expect-error TS(2554): Expected 6 arguments, but got 5.
    () => getFileValues(field, dataType, user, record, fieldPermissionsEnabled),
    [field, dataType, user, record, fieldPermissionsEnabled],
  );

  const [localFileIndex, setFileIndex] = useState(0);
  const hasPreviousFile = localFileIndex > 0 && files[localFileIndex - 1];
  const hasNextFile = localFileIndex < files.length - 1;

  const previousFile = useCallback(() => {
    if (hasPreviousFile) {
      setFileIndex(localFileIndex - 1);
    }
  }, [localFileIndex, hasPreviousFile]);

  const nextFile = useCallback(() => {
    if (hasNextFile) {
      setFileIndex(localFileIndex + 1);
    }
  }, [localFileIndex, hasNextFile]);

  const file = useMemo(() => {
    return files.length > 0 ? files[localFileIndex] : null;
  }, [localFileIndex, files]);

  if ((files.length === 0 || file === null) && !editorMode) {
    return null;
  }

  if ((files.length === 0 || file === null) && editorMode) {
    return (
      <div
        className={`border rounded-lg shadow-md flex items-center justify-center p-8 ${
          isDarkModeEnabled
            ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.two}`
            : 'bg-white border-gray-200'
        }`}
      >
        <div className="text-center w-full text-gray-600 py-20 text-sm">
          {getText('elements.DETAILS.view.empty')}
        </div>
      </div>
    );
  }

  const shouldShowArrows = files.length > 1;
  return (
    <div
      className={classNames('h-screen w-full rounded-lg overflow-hidden', {
        'max-h-screen-25': size === SM,
        'max-h-screen-50': size === MD,
        'max-h-screen-75': size === LG,
        'max-h-screen': size === XL,
      })}
    >
      <div
        className={`flex flex-col w-full h-screen max-h-full overflow-hidden relative border ${
          isDarkModeEnabled
            ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.two}`
            : 'bg-white border-gray-200'
        }`}
      >
        {showFileName && (
          <div className="pl-2 pr-2 pt-1 pb-1 text-lg font-medium tracking-wider">
            {file?.name || ''}
          </div>
        )}
        <div className="flex items-center justify-center w-full overflow-hidden relative h-full">
          <FilePreviewElement file={file} />
        </div>
        {shouldShowArrows && hasPreviousFile && (
          <div className="flex items-center absolute left-4 top-0 bottom-0 z-50">
            <button
              onClick={previousFile}
              disabled={!hasPreviousFile}
              className={`rounded-full p-1 shadow-lg border ${
                isDarkModeEnabled
                  ? `${darkModeColors.surfaces.elevation2} ${darkModeColors.text.secondary} ${darkModeColors.borders.two}`
                  : 'bg-white border-gray-200 text-gray-700'
              }`}
            >
              <IconArrowLeft size={20} />
            </button>
          </div>
        )}
        {shouldShowArrows && hasNextFile && (
          <div className="flex items-center ml-auto absolute top-0 bottom-0 right-4 z-50">
            <button
              onClick={nextFile}
              disabled={!hasNextFile}
              className={`rounded-full p-1 shadow-lg border ${
                isDarkModeEnabled
                  ? `${darkModeColors.surfaces.elevation2} ${darkModeColors.text.secondary} ${darkModeColors.borders.two}`
                  : 'bg-white border-gray-200 text-gray-700'
              }`}
            >
              <IconArrowRight size={20} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

RecordFileGallery.defaultProps = { size: SM };

export default RecordFileGallery;
