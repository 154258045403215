import React from 'react';
import get from 'lodash/get';
import omit from 'lodash/omit';
import * as elements from '../constants/elements';
import { Element, ElementPath } from '../models/Element';
import { ProjectSettings } from '../models/Project';

export const makeTextChild = (text: string) => [
  {
    type: elements.CONTENT,
    props: {
      items: [{ text }],
    },
  },
];

export const updateScope = (
  children: React.ReactElement,
  scope: Record<any, any>,
  otherProps: Record<any, any> = {},
) =>
  React.Children.map(children, (child) =>
    React.cloneElement(child, {
      ...otherProps,
      scope,
    }),
  );

const elementProps = [
  '0',
  '1',
  'editorMode',
  'onClick',
  'onMouseOver',
  'onMouseOut',
  'isSelected',
  'elementId',
  'elementPath',
  'loading',
  'project',
  'ref',
  'scope',
  'bgImage',
  'isSelected',
];

export const omitElementProps = (restProps: Record<any, any>) =>
  omit(restProps, elementProps);

export const getPagesConfig = (
  elements: Element[],
  settings: ProjectSettings,
): {
  isV2: boolean;
  portalPath: ElementPath;
  pagesPath: ElementPath;
  projectPages: Element[];
} => {
  const isV2 = get(settings, 'flags.v2', false);
  const portalPageIndex = isV2
    ? -1
    : get(elements, '0.children', []).length - 1;
  const portalPath = isV2
    ? []
    : `0.children.${portalPageIndex}.children.0`.split('.');
  const pagesPath = [...portalPath, ...(isV2 ? [] : ['children'])];

  return {
    isV2,
    portalPath,
    pagesPath,
    projectPages: isV2 ? elements : get(elements, pagesPath, []),
  };
};
