import React, { memo } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { Link } from 'react-router-dom';
import { getColorShade } from '@noloco/components';
import { darkModeColors } from '../../../constants/darkModeColors';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useScopeVariables from '../../../utils/hooks/useScopeVariables';

const CollectionTable = memo(
  ({
    // @ts-expect-error TS(2339): Property 'additionalElementsRenderer' does not exi... Remove this comment to see the full error message
    additionalElementsRenderer,
    // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{}'... Remove this comment to see the full error message
    elementPath,
    // @ts-expect-error TS(2339): Property 'index' does not exist on type '{}'.
    index,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
    project,
    // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
    scope,
    // @ts-expect-error TS(2339): Property 'maxStickyColumnIndex' does not exist on ... Remove this comment to see the full error message
    maxStickyColumnIndex,
    // @ts-expect-error TS(2339): Property 'element' does not exist on type '{}'.
    element,
    // @ts-expect-error TS(2339): Property 'isView' does not exist on type '{}'.
    isView,
    // @ts-expect-error TS(2339): Property 'rawVariables' does not exist on type '{}... Remove this comment to see the full error message
    rawVariables,
    // @ts-expect-error TS(2339): Property 'rowLink' does not exist on type '{}'.
    rowLink,
    // @ts-expect-error TS(2339): Property 'theme' does not exist on type '{}'.
    theme,
  }) => {
    const secondaryColor = theme.brandColorGroups.secondary;
    const primaryColor = theme.brandColorGroups.primary;
    const {
      link = rowLink,
      variables: { title, image, description, secondaryText } = rawVariables,
    } = useScopeVariables(scope, element, project, elementPath);
    const linkValue = rowLink || link;

    const additionalElementCells = additionalElementsRenderer(
      scope,
      '',
      '',
      true,
    );
    const containerIs =
      linkValue && linkValue.to
        ? Link
        : !linkValue || !linkValue.href
        ? 'div'
        : 'a';

    const [isDarkModeEnabled] = useDarkMode();

    return (
      <>
        {!isView && (
          <td>
            <Box
              is={containerIs}
              {...linkValue}
              className="flex pl-6 py-2 pr-3 whitespace-pre-wrap"
            >
              {!image.hidden && (
                <Box
                  className={classNames(
                    'sm:mr-3 mr-4 w-10 h-10 rounded-full sm:rounded-0 text-white bg-center bg-cover',
                  )}
                  bg={getColorShade(secondaryColor, 400)}
                  style={{
                    backgroundImage: `url(${
                      image.value.src ||
                      `https://picsum.photos/id/${(index % 10) + 1020}/600/600`
                    })`,
                  }}
                >
                  <span className="block w-14 h-14" />
                </Box>
              )}
              <div className="w-full min-w-0 flex flex-col justify-center flex-grow max-w-xl">
                <Box
                  is="span"
                  className="text-lg font-medium"
                  text={getColorShade(primaryColor, 800)}
                >
                  {title.value}
                </Box>
                <div
                  aria-hidden="true"
                  className="whitespace-nowrap overflow-hidden text-lg font-medium invisible h-0"
                >
                  {title.value}
                </div>
                {!description.hidden && (
                  <>
                    <span className="text-sm text-gray-400">
                      {description.value}
                    </span>
                    <div
                      aria-hidden="true"
                      className="whitespace-nowrap overflow-hidden text-sm invisible h-0"
                    >
                      {description.value}
                    </div>
                  </>
                )}
              </div>
            </Box>
          </td>
        )}
        {!secondaryText.hidden && !isView && (
          <td>
            <Box
              is={containerIs}
              {...linkValue}
              className={classNames(
                'flex flex-col justify-center py-2 whitespace-pre-wrap pl-4 max-w-xl',
                index === additionalElementCells.length - 1 ? 'pr-6' : 'pr-3',
              )}
            >
              <span>{secondaryText.label}</span>
              <span className="text-sm text-gray-400">
                {secondaryText.value}
              </span>
              <div
                aria-hidden="true"
                className="whitespace-nowrap overflow-hidden text-sm invisible h-0"
              >
                {secondaryText.value}
              </div>
            </Box>
          </td>
        )}
        {additionalElementCells &&
          additionalElementCells.map((child: any, index: any) => (
            <td
              className={classNames(
                {
                  'left-0 sticky z-10 ':
                    !isNil(maxStickyColumnIndex) &&
                    index <= maxStickyColumnIndex,
                },
                `${
                  isDarkModeEnabled
                    ? darkModeColors.surfaces.elevation1
                    : 'bg-white shadow-r'
                }`,
              )}
              key={index}
            >
              <Box
                is={containerIs}
                {...linkValue}
                className={classNames(
                  'flex flex-col py-2 whitespace-pre-wrap max-w-xl max-h-48 overflow-y-auto',
                  index === additionalElementCells.length - 1 ? 'px-6' : 'pr-3',
                  index === 0 && (isView || secondaryText.hidden)
                    ? 'pl-6'
                    : 'pl-3',
                )}
              >
                {child}
              </Box>
            </td>
          ))}
      </>
    );
  },
);

(CollectionTable as any).propTypes = {};

export default withTheme(CollectionTable);
