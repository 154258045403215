import set from 'lodash/fp/set';
import { QUERY } from '@noloco/core/src/constants/endpointTypes';
import { findEndpoint } from '../../../utils/apis';
import { resolveDataValue } from '../../../utils/data';
import ComboType from '../ComboPropType';
import NumberType from '../NumberPropType';

const endpointResolver = (
  comboProps: any,
  project: any,
  scope: any,
  editorMode: any,
) => {
  const { endpointId, apiId, params = {} } = comboProps;

  const endpointData = findEndpoint(project.apis, apiId, endpointId);

  const paramData = !editorMode
    ? Object.entries(params).reduce((propAcc, [key, dataValue]) => {
        if (!dataValue) {
          return set([key], null, propAcc);
        }

        const value = resolveDataValue(dataValue, scope, project, true);
        return set([key], value, propAcc);
      }, {})
    : {};
  return {
    ...endpointData,
    params: paramData,
  };
};

class EndpointPropType extends ComboType {
  endpointFilter: any;
  showParams: any;
  constructor(propMapper?: any, display?: any) {
    super(
      {
        apiId: new NumberType(),
        endpointId: new NumberType(),
      },
      endpointResolver,
      propMapper,
      display,
    );

    this.showParams = true;
    this.endpointFilter = ({ type }: any) => type === QUERY;
    // @ts-expect-error TS(2345): Argument of type '(prop: Record<any, any>, depende... Remove this comment to see the full error message
    this.setExtractPropTypesDependencies((prop, dependencies) => {
      if (!prop) {
        return dependencies;
      }
      const { params = {} } = prop || {};
      return Object.values(params).reduce((depsAcc, paramDep) => {
        if (!paramDep) {
          return depsAcc;
        }
        return [
          // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
          ...depsAcc,
          ...(paramDep as any)
            .map((paramItem: any) => paramItem && paramItem.data)
            .filter(Boolean),
        ];
      }, dependencies);
    });
  }

  setEndpointFilter(endpointFilter: any) {
    this.endpointFilter = endpointFilter;
    return this;
  }

  setShowParams(showParams: any) {
    this.showParams = showParams;
    return this;
  }
}

export default EndpointPropType;
