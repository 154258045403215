import { useCallback } from 'react';
import {
  CREATE,
  DELETE,
  NAVIGATE,
  ON_DEMAND,
  UPDATE,
} from '../../constants/actionTypes';

const useActions = (
  onCreate: any,
  onDelete: any,
  onNavigate: any,
  onNext: any,
  onRunWorkflow: any,
  onUpdate: any,
) => {
  const onExecuteAction = useCallback(
    (action: any, setIsLoading: any) => {
      if (!action) {
        return onNext();
      }

      switch (action.type) {
        case CREATE:
          return onCreate(action, setIsLoading);
        case DELETE:
          return onDelete(action, setIsLoading);
        case NAVIGATE:
          return onNavigate(action, setIsLoading);
        case ON_DEMAND:
          return onRunWorkflow(action, setIsLoading);
        case UPDATE:
          return onUpdate(action, setIsLoading);
        default:
          return onNext();
      }
    },
    [onCreate, onDelete, onNavigate, onNext, onRunWorkflow, onUpdate],
  );

  return {
    onExecuteAction,
  };
};

export default useActions;
