import React, { forwardRef, memo, useMemo } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import get from 'lodash/get';
import { Loader } from '@noloco/components';
import { updateScope } from '../utils/elements';
import DataListWrapper from './DataListWrapper';
import Group from './Group';

const getItemScope = (
  // @ts-expect-error TS(7006): Parameter 'existingScope' implicitly has an 'any' ... Remove this comment to see the full error message
  existingScope,
  // @ts-expect-error TS(7006): Parameter 'elementId' implicitly has an 'any' type... Remove this comment to see the full error message
  elementId,
  // @ts-expect-error TS(7006): Parameter 'loading' implicitly has an 'any' type.
  loading,
  // @ts-expect-error TS(7006): Parameter 'edge' implicitly has an 'any' type.
  edge,
  // @ts-expect-error TS(7006): Parameter 'index' implicitly has an 'any' type.
  index,
  // @ts-expect-error TS(7006): Parameter 'isFirst' implicitly has an 'any' type.
  isFirst,
  // @ts-expect-error TS(7006): Parameter 'isLast' implicitly has an 'any' type.
  isLast,
  // @ts-expect-error TS(7006): Parameter 'connectionProps' implicitly has an 'any... Remove this comment to see the full error message
  connectionProps,
) => ({
  ...existingScope,

  [elementId]: {
    ...(loading
      ? { loading }
      : {
          edges: edge,
          index,
          isFirst: index === 0,
          isLast,
          ...connectionProps,
        }),
  },
});

export const ListItem = memo(
  ({
    children,
    // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
    className,
    // @ts-expect-error TS(2339): Property 'connectionProps' does not exist on type ... Remove this comment to see the full error message
    connectionProps,
    // @ts-expect-error TS(2339): Property 'edge' does not exist on type '{}'.
    edge,
    // @ts-expect-error TS(2339): Property 'elementId' does not exist on type '{}'.
    elementId,
    // @ts-expect-error TS(2339): Property 'style' does not exist on type '{}'.
    style,
    // @ts-expect-error TS(2339): Property 'is' does not exist on type '{}'.
    is = Group,
    // @ts-expect-error TS(2339): Property 'index' does not exist on type '{}'.
    index,
    // @ts-expect-error TS(2339): Property 'isLast' does not exist on type '{}'.
    isLast,
    // @ts-expect-error TS(2339): Property 'listItem' does not exist on type '{}'.
    listItem,
    // @ts-expect-error TS(2339): Property 'loading' does not exist on type '{}'.
    loading,
    // @ts-expect-error TS(2339): Property 'ref' does not exist on type '{}'.
    ref,
    // @ts-expect-error TS(2339): Property 'onClick' does not exist on type '{}'.
    onClick,
    // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
    scope,
  }) => {
    const itemScope = useMemo(
      () =>
        getItemScope(
          scope,
          elementId,
          loading,
          edge,
          index,
          index === 0,
          isLast,
          connectionProps,
        ),
      [connectionProps, edge, elementId, index, isLast, loading, scope],
    );

    return (
      <Box
        is={is}
        className={className}
        key={listItem.id}
        ref={index === 0 ? ref : undefined}
        style={style}
        onClick={onClick}
      >
        {updateScope(children as any, itemScope, { loading })}
      </Box>
    );
  },
);

type Props = {
  autoRefresh: boolean;
  children: React.ReactNode;
  editorMode: boolean;
  EmptyState?: React.ReactNode;
  className?: string;
  orderBy?: {
    direction?: any; // TODO: PropTypes.oneOf(orderByDirections)
    field?: string;
  };
  scope: any;
};

const RepeatingList = forwardRef<any, Props>(
  (
    {
      autoRefresh,
      className,
      children,
      // @ts-expect-error TS(2339): Property 'dataType' does not exist on type 'Props'... Remove this comment to see the full error message
      dataType,
      // @ts-expect-error TS(2339): Property 'dataSource' does not exist on type 'Prop... Remove this comment to see the full error message
      dataSource,
      // @ts-expect-error TS(2339): Property 'endpoint' does not exist on type 'Props'... Remove this comment to see the full error message
      endpoint,
      editorMode,
      // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type 'Pro... Remove this comment to see the full error message
      elementPath,
      // @ts-expect-error TS(2339): Property 'showEmptyState' does not exist on type '... Remove this comment to see the full error message
      showEmptyState,
      EmptyState,
      // @ts-expect-error TS(2339): Property 'filter' does not exist on type 'Props'.
      filter,
      // @ts-expect-error TS(2339): Property 'customFilters' does not exist on type 'P... Remove this comment to see the full error message
      customFilters,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type 'Props'.
      project,
      // @ts-expect-error TS(2339): Property 'limit' does not exist on type 'Props'.
      limit,
      scope,
      // @ts-expect-error TS(2339): Property 'style' does not exist on type 'Props'.
      style,
      orderBy,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Props'.
      onClick,
    },
    ref,
  ) => {
    const element = get(project.elements, elementPath, {});

    return (
      <DataListWrapper
        autoRefresh={autoRefresh}
        elementId={element.id}
        dataType={dataType}
        dataSource={dataSource}
        endpoint={endpoint}
        elementPath={elementPath}
        filter={filter}
        customFilters={customFilters}
        project={project}
        limit={limit}
        scope={scope}
        orderBy={orderBy}
      >
        {({
          loading,
          connection,
          idPath = 'node.id',
          edges,
          parentValuePath,
          error,
        }) => {
          if (loading) {
            return (
              <div className="flex justify-center items-center w-full p-8">
                <Loader type="Bars" size="sm" />
              </div>
            );
          }
          if (error) {
            console.log(error);
            return (
              <div className="flex justify-center items-center w-full p-8">
                <em>Something went wrong</em>
              </div>
            );
          }

          if (
            ((editorMode && showEmptyState) || edges.length === 0) &&
            EmptyState
          ) {
            // @ts-expect-error TS(2604): JSX element type 'EmptyState' does not have any co... Remove this comment to see the full error message
            return <EmptyState />;
          }

          return edges.map((listItem: any, index: any) => (
            <ListItem
              // @ts-expect-error TS(2322): Type '{ className: string | undefined; children: R... Remove this comment to see the full error message
              className={className}
              children={children}
              style={style}
              key={get(listItem, idPath)}
              ref={ref}
              index={index}
              listItem={listItem}
              loading={loading}
              dataType={dataType}
              elementId={element.id}
              onClick={onClick}
              edge={get(edges, [index])}
              parentValuePath={parentValuePath}
              isLast={index === edges.length - 1}
              connectionProps={connection}
              scope={scope}
            />
          ));
        }}
      </DataListWrapper>
    );
  },
);

RepeatingList.defaultProps = {
  autoRefresh: false,
  className: '',
  // @ts-expect-error TS(2322): Type '{ autoRefresh: false; className: string; cus... Remove this comment to see the full error message
  customFilters: [],
};

export default RepeatingList;
