import React from 'react';
import classNames from 'classnames';
import MarkdownText from '../../../components/MarkdownText';
import { STATISTIC } from '../../../constants/chartTypes';
import { darkModeColors } from '../../../constants/darkModeColors';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import CollectionChart from './CollectionChart';

const CollectionChartWrapper = ({
  className,
  chart,
  data,
  dataType,
  dataTypes,
}: any) => {
  const [isDarkModeEnabled] = useDarkMode();

  return (
    <div
      className={classNames(
        className,
        {
          'col-span-6': !chart.columnSpan || chart.columnSpan === 6,
          'col-span-9': chart.columnSpan === 9,
          'col-span-3': chart.columnSpan === 3,
          'col-span-4': chart.columnSpan === 4,
          'col-span-12': chart.columnSpan === 12,
        },
        'lg:col-span-12 flex flex-col justify-end',
        'border rounded-lg shadow-md p-3',
        `${
          isDarkModeEnabled ? darkModeColors.surfaces.elevation1 : 'bg-white'
        }`,
        `${isDarkModeEnabled ? darkModeColors.borders.one : 'border-gray-200'}`,
      )}
    >
      {chart.title && (
        <h3
          className={classNames({
            'text-lg mb-2': chart.chartType !== STATISTIC,
            'text-sm mb-1 text-gray-400 tracking-wider':
              chart.chartType === STATISTIC,
          })}
        >
          {chart.title}
        </h3>
      )}
      {chart.helpText && (
        <MarkdownText
          className={classNames('mb-2', {
            'text-xs text-gray-400': chart.chartType === STATISTIC,
          })}
          small={chart.chartType !== STATISTIC}
        >
          {chart.helpText}
        </MarkdownText>
      )}
      <CollectionChart
        chart={chart}
        dataType={dataType}
        dataTypes={dataTypes}
        data={data}
      />
    </div>
  );
};

export default CollectionChartWrapper;
