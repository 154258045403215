import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { SINGLE_OPTION } from '../../constants/dataTypes';
import DataTypes from '../../models/DataTypes';
import { getValueScope } from '../baseScope';
import { getOptionFieldScopeForProject } from '../scope';
import useSetScopeFieldValue from './useSetScopeFieldValue';

/*
  05/08/2022
* It is possible that if a user leaves a tab open long enough,
* the value of nowLocal could go stale enough to cause unexpected behavior
* in any functionality that depends on relative dates derived from nowLocal
*/
const nowLocal = DateTime.local();

export const valuesScope = getValueScope(nowLocal);

const useSetBaseScope = (dataTypes: DataTypes) => {
  const optionsScope = useMemo(() => getOptionFieldScopeForProject(dataTypes), [
    dataTypes,
  ]);

  useSetScopeFieldValue(false, 'values', valuesScope);
  useSetScopeFieldValue(false, SINGLE_OPTION, optionsScope);
};

export default useSetBaseScope;
