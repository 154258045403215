import React, { forwardRef, memo } from 'react';
import classNames from 'classnames';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { ACTIVE } from '../../constants/accountStatus';
import {
  ALL,
  LOGGED_IN,
  NOT_LOGGED_IN,
} from '../../constants/authVisibilityRules';
import { CUSTOM_VISIBILITY_RULES } from '../../constants/features';
import { CLIENT, INTERNAL } from '../../constants/internalUsersVisibilityRules';
import {
  WITHOUT_PLANS,
  WITH_PLANS,
} from '../../constants/membershipVisibilityRules';
import { WITHOUT_ROLES, WITH_ROLES } from '../../constants/roleVisibilityRules';
import { conditionIsTrue } from '../../utils/data';
import useMergedScope from '../../utils/hooks/useMergedScope';
import useScopeUser from '../../utils/hooks/useScopeUser';
import { isConnected } from '../../utils/payments';
import { isInternal } from '../../utils/user';

export const shouldCheckVisibilityRules = (visibilityRules: any) => {
  const { auth, roleRule, type, customRules } = visibilityRules;

  return !(
    (!auth || auth === ALL) &&
    (!roleRule || roleRule === ALL) &&
    (!type || type === ALL) &&
    (!customRules || customRules.length === 0)
  );
};

export const shouldRenderComponent = (
  currentUser: any,
  {
    auth,
    membership,
    roleRule,
    type,
    plans = [],
    roles = [],
    customRules = [],
  }: any,
  project: any,
  scope: any,
  customRulesEnabled: any,
) => {
  if (!currentUser || currentUser.loading) {
    return false;
  }

  if (auth && auth !== ALL) {
    if (auth === NOT_LOGGED_IN && currentUser.id) {
      return false;
    }

    if (auth === LOGGED_IN && !currentUser.id) {
      return false;
    }
  }

  if (type && type !== ALL) {
    if (type === INTERNAL && !isInternal(currentUser)) {
      return false;
    }

    if (type === CLIENT && isInternal(currentUser)) {
      return false;
    }
  }

  if (roleRule && roleRule !== ALL) {
    if (!currentUser.id) {
      return false;
    }

    const userRoleId = currentUser.role && currentUser.role.referenceId;

    if (roles.length > 0) {
      const hasSpecifiedRole = roles.includes(userRoleId);

      if (roleRule === WITH_ROLES) {
        if (!userRoleId || !hasSpecifiedRole) {
          return false;
        }
      }

      if (roleRule === WITHOUT_ROLES) {
        if (userRoleId && hasSpecifiedRole) {
          return false;
        }
      }
    }
  }

  if (membership && membership !== ALL) {
    if (!currentUser.id) {
      return false;
    }

    const { stripe } = project.integrations;

    const isMembershipConnected = isConnected(stripe);
    if (!isMembershipConnected) {
      return true;
    }

    const subscription = currentUser.membership;
    const planId = subscription && subscription.plan.id;

    if (membership === WITH_PLANS) {
      if (!subscription) {
        return false;
      }

      const hasActivePlan =
        plans.includes(planId) &&
        (subscription.plan.status === ACTIVE ||
          !subscription.plan.interval ||
          subscription.plan.interval === 'one-time');

      if (!hasActivePlan) {
        return false;
      }
    }

    if (membership === WITHOUT_PLANS) {
      if (subscription && plans.includes(planId)) {
        return false;
      }
    }
  }

  if (customRulesEnabled && customRules.length > 0) {
    // @ts-expect-error TS(7006): Parameter 'andConditions' implicitly has an 'any' ... Remove this comment to see the full error message
    const ruleConditionsAreMet = customRules.some((andConditions) =>
      andConditions.every(
        // @ts-expect-error TS(2554): Expected 4 arguments, but got 3.
        (condition: any) => conditionIsTrue(condition, scope, project).result,
      ),
    );

    if (!ruleConditionsAreMet) {
      return false;
    }
  }

  // Should always fallback to true
  return true;
};

export const isElementVisible = (
  element: any,
  project: any,
  user: any,
  scope: any,
  editorMode = false,
  customRulesEnabled: any,
) =>
  editorMode ||
  !element.visibilityRules ||
  !shouldCheckVisibilityRules(element.visibilityRules) ||
  shouldRenderComponent(
    user,
    element.visibilityRules,
    project,
    scope,
    customRulesEnabled,
  );

const withVisibilityRules = (
  WrappedComponent: any,
  editorMode: any,
  visibilityRules: any,
) => {
  if (!visibilityRules) {
    return WrappedComponent;
  }

  if (!shouldCheckVisibilityRules(visibilityRules)) {
    return WrappedComponent;
  }

  const WithVisibilityRules = memo(
    forwardRef(
      // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
      ({ className, project, visibilityRulesScope = {}, ...rest }, ref) => {
        if (!project) {
          throw new Error(
            `withVisibilityRules: project prop not set on ${
              WrappedComponent.displayName || 'component'
            }`,
          );
        }

        const currentUser = useScopeUser();
        const scope = useMergedScope(visibilityRulesScope);
        const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
        const shouldRender = shouldRenderComponent(
          currentUser,
          visibilityRules,
          project,
          scope,
          customRulesEnabled,
        );

        if (!editorMode && !shouldRender) {
          return null;
        }

        return (
          <WrappedComponent
            {...rest}
            className={classNames(className, {
              'opacity-50': !shouldRender,
            })}
            project={project}
            scope={scope}
            ref={ref}
          />
        );
      },
    ),
  );

  WithVisibilityRules.displayName = 'WithVisibilityRules';

  return WithVisibilityRules;
};

export default withVisibilityRules;
