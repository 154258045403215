import React, { forwardRef, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import {
  API_REQUEST,
  NAVIGATE,
  OPEN_STRIPE_PORTAL,
} from '../../constants/actionTypes';
import { QUERY } from '../../constants/endpointTypes';
import {
  getApiRequestQueryString,
  getStripeCustomerPortalQueryString,
} from '../../queries/project';
import { findEndpoint } from '../../utils/apis';
import useImperativeQuery from '../../utils/hooks/useImperitiveQuery';
import useMergedScope from '../../utils/hooks/useMergedScope';
import { useOpenUrl } from '../../utils/hooks/useOpenUrl';
import useRouter from '../../utils/hooks/useRouter';
import { resolveNavigationObject } from '../../utils/navigation';
import { isConnected } from '../../utils/payments';

const onClickEvent = {
  type: 'CLICK',
  eventName: 'onClick',
};

const fixURLIfNecessary = (urlString: any) => {
  let urlStringCopy = urlString;
  if (urlString.startsWith('/')) {
    urlStringCopy = `${document.location.hostname}${urlStringCopy}`;
  }

  if (!/https?:\/\//.test(urlStringCopy)) {
    urlStringCopy = `https://${urlStringCopy}`;
  }
  return urlStringCopy;
};

const getUrlFromNavigationObject = (
  navigate: any,
  actionsScope: any,
  project: any,
) => {
  const { to, href } = resolveNavigationObject(navigate, actionsScope, project);
  if (href) {
    return fixURLIfNecessary(href);
  }

  if (to) {
    return fixURLIfNecessary(to);
  }
  return `https://${document.location.hostname}`;
};

const withActionHandler = (
  WrappedComponent: any,
  element: any,
  project: any,
) => {
  const actionsMap = element.actions.reduce((actionAcc: any, action: any) => {
    const { id, type } = action;
    const mutationContext = {
      projectQuery: true,
      projectName: project.name,
    };

    if (type === OPEN_STRIPE_PORTAL) {
      return {
        ...actionAcc,
        [id]: useMutation(
          gql`
            ${getStripeCustomerPortalQueryString()}
          `,
          {
            context: mutationContext,
          },
        ),
      };
    }

    if (type === API_REQUEST) {
      const { api, endpoint } = findEndpoint(
        project.apis,
        action.apiId,
        action.endpointId,
      );

      if (endpoint) {
        const mutationHook =
          endpoint.type === QUERY ? useImperativeQuery : useMutation;
        if (api && endpoint) {
          const requestString = getApiRequestQueryString(api, endpoint);
          return {
            ...actionAcc,
            [id]: mutationHook(
              gql`
                ${requestString}
              `,
              {
                context: mutationContext,
              },
            ),
          };
        }
      }
    }

    return actionAcc;
  }, {});

  // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
  const WithActionHandler = forwardRef(({ scope, ...props }, ref) => {
    const openUrl = useOpenUrl();
    const { push } = useRouter();
    const mergedScope = useMergedScope(scope);

    const eventHandlers = useMemo(() => {
      const handleEventActions = (eventActions: any, initialEventScope: any) =>
        eventActions.reduce(
          (previousAction: any, action: any) =>
            previousAction.then((actionsScope: any) => {
              if (action.type === NAVIGATE) {
                const { to, href } = resolveNavigationObject(
                  action.navigate,
                  actionsScope,
                  project,
                );
                const redirectTo = queryString.parse(document.location.search)
                  .redirectPath;

                if (href && !redirectTo) {
                  return new Promise((resolve) => {
                    openUrl(href, action.navigate.newTab);
                    resolve(actionsScope);
                  });
                } else if (redirectTo || to) {
                  return new Promise((resolve) => {
                    push(redirectTo || to);
                    resolve(actionsScope);
                  });
                }
              }

              if (action.type === OPEN_STRIPE_PORTAL) {
                const { stripe } = project.integrations || {};
                const { returnUrl } = action;
                if (isConnected(stripe)) {
                  const mutationVars = {};
                  (mutationVars as any).returnUrl = getUrlFromNavigationObject(
                    returnUrl,
                    actionsScope,
                    project,
                  );

                  const [mutation] = actionsMap[action.id];
                  return mutation({ variables: mutationVars })
                    .then((mutationData: any) => {
                      const session =
                        mutationData.data.createCustomerPortalSession;
                      document.location = session.successUrl;
                    })
                    .then(() => Promise.resolve(actionsScope));
                }
              }

              return Promise.resolve(actionsScope);
            }),
          Promise.resolve({ ...initialEventScope, ...mergedScope }),
        );

      return [onClickEvent].reduce((handlerAcc, eventConfig) => {
        const eventActions = element.actions.filter(
          (action: any) => !action.event || action.event === eventConfig.type,
        );

        if (eventActions.length === 0) {
          return handlerAcc;
        }

        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const nativeHandler = props[eventConfig.eventName];
        const eventActionsHandler = (eventContext: any) => {
          const { value, onError } = eventContext || {};
          handleEventActions(eventActions, {
            [eventConfig.type]: value,
          }).catch((error: any) => {
            if (onError) {
              onError(error);
            } else {
              console.log(error);
            }
          });
        };

        const actionHandlerFn = !(eventConfig as any).debounce
          ? eventActionsHandler
          : debounce(eventActionsHandler, (eventConfig as any).debounceMs);

        return {
          ...handlerAcc,
          [eventConfig.eventName]: (event: any, eventContext: any) => {
            if (nativeHandler) {
              nativeHandler(event);
            }
            actionHandlerFn(eventContext);
          },
        };
      }, {});
    }, [mergedScope, openUrl, push, props]);

    return (
      <WrappedComponent ref={ref} scope={scope} {...props} {...eventHandlers} />
    );
  });

  return WithActionHandler;
};

export default withActionHandler;
