import React, { useCallback, useState } from 'react';
import { IconTableImport } from '@tabler/icons-react';
import { oneOfVariants } from '@noloco/components';
import { getText } from '@noloco/core/src/utils/lang';
import FeatureLockedButton from '@noloco/ui/src/components/FeatureLockedButton';
import { useFeatureUsage } from '@noloco/ui/src/utils/hooks/useFeatureUsage';
import { DATABASE_RECORDS } from '../../../constants/features';
import { DATATYPES_EXCLUDED_FROM_RECORD_USAGE } from '../../../constants/usage';
import ImportModal from './ImportModal';

const BASE_LANG_KEY = 'data.import';

type OwnProps = {
  // @ts-expect-error TS(2749): 'oneOfVariants' refers to a value, but is being us... Remove this comment to see the full error message
  variant?: oneOfVariants;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ImportButton.defaultProps;

// @ts-expect-error TS(7022): 'ImportButton' implicitly has type 'any' because i... Remove this comment to see the full error message
const ImportButton = ({
  buttonText,
  buttonType,
  fields,
  dataType,
  project,
  variant,
}: Props) => {
  const [showImportModal, setShowImportModal] = useState(false);

  const currentNumber = useFeatureUsage(DATABASE_RECORDS);

  const onClose = useCallback(() => {
    setShowImportModal(false);
  }, []);

  return (
    <>
      <FeatureLockedButton
        checkEnabled={
          !DATATYPES_EXCLUDED_FROM_RECORD_USAGE.includes(dataType.name)
        }
        className="flex items-center space-x-2 import-button"
        currentNumber={currentNumber}
        feature={DATABASE_RECORDS}
        onClick={() => setShowImportModal(true)}
        tooltipPlacement="bottom"
        type={variant === 'primary' ? 'outline' : buttonType}
        variant={variant}
      >
        <IconTableImport className="opacity-75 mr-2" size={16} />
        <span>{buttonText || getText(BASE_LANG_KEY, 'button')}</span>
      </FeatureLockedButton>
      {showImportModal && (
        <ImportModal
          fields={fields}
          dataType={dataType}
          onClose={onClose}
          project={project}
        />
      )}
    </>
  );
};

ImportButton.defaultProps = {
  variant: 'primary',
};

export default ImportButton;
