import { useCallback } from 'react';
import merge from 'lodash/fp/merge';
import { useSelector } from 'react-redux';
import { scopeSelector } from '../../selectors/dataSelectors';
import { useBuildDataItemRecordScope } from './useBuildDataItemRecordScope';

export const useBuildDataItemMergedScope = (
  formatDataItem: any,
  transformRecordScope: any,
) => {
  const buildDataItemRecordScope = useBuildDataItemRecordScope(
    formatDataItem,
    transformRecordScope,
  );
  const userScope = useSelector(scopeSelector);

  return useCallback(
    (dataItem: any) => merge(buildDataItemRecordScope(dataItem), userScope),
    [buildDataItemRecordScope, userScope],
  );
};
