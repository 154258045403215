import { useCallback, useMemo } from 'react';
import initial from 'lodash/initial';
import { useAddChild } from '@noloco/ui/src/utils/hooks/projectHooks';
import { generateViewFromDataSource } from '@noloco/ui/src/utils/layout';
import { DataType } from '../../models/DataTypes';
import { Element } from '../../models/Element';
import { Project } from '../../models/Project';
import { getPagesConfig } from '../elements';
import useRouter from './useRouter';

export const useAddView = (project: Project, parentPage?: Element) => {
  const { push } = useRouter();

  const { isV2, pagesPath } = useMemo(
    () => getPagesConfig(project.elements, project.settings),
    [project],
  );

  const [addChild] = useAddChild(
    project,
    isV2 ? [] : initial(pagesPath),
    isV2 ? [] : ['children'],
  );

  const onAddView = useCallback(
    (dataType: DataType) => {
      const newPage = generateViewFromDataSource(
        dataType.display,
        dataType,
        project,
        { createdAt: new Date() },
        { userGenerated: false, createdAt: new Date() },
      );
      if (parentPage) {
        // @ts-expect-error TS2339: Property 'parentPage' does not exist on type
        newPage.props.parentPage = parentPage.id;
      }

      addChild(newPage);
      setTimeout(() => {
        push(
          `${parentPage ? `/${parentPage.props.routePath}` : ''}/${
            newPage.props.routePath
          }`,
        );
      }, 500);
      return newPage;
    },
    [addChild, parentPage, project, push],
  );

  return onAddView;
};
