export const FILTER = 'FILTER';
export const SEND_EMAIL = 'SEND_EMAIL';
export const FOR_EACH = 'FOR_EACH';
export const WEBHOOK = 'WEBHOOK';
export const CREATE_RECORD = 'CREATE_RECORD';
export const UPDATE_RECORD = 'UPDATE_RECORD';
export const DELETE_RECORD = 'DELETE_RECORD';

const workflowActionTypes = [
  SEND_EMAIL,
  WEBHOOK,
  CREATE_RECORD,
  UPDATE_RECORD,
  DELETE_RECORD,
  FILTER,
  FOR_EACH,
];

export type WorkflowActionType =
  | 'FILTER'
  | 'SEND_EMAIL'
  | 'FOR_EACH'
  | 'WEBHOOK'
  | 'CREATE_RECORD'
  | 'UPDATE_RECORD'
  | 'DELETE_RECORD';

export default workflowActionTypes;
