// Source: https://github.com/litichevskiydv/partitioned-loops/blob/develop/src/index.js
// Reason: https://nodejs.org/en/docs/guides/dont-block-the-event-loop/

const forEachBody = <T>(
  iterator: IterableIterator<T>,
  body: (value: T, loopState: any) => void,
  loopState: any,
  callback: (value: void) => void,
): void => {
  const iteratorState = iterator.next();
  if (iteratorState.done || loopState.break === true) callback(loopState);
  else {
    body(iteratorState.value, loopState);
    setImmediate(forEachBody.bind(null, iterator, body, loopState, callback));
  }
};

export const partionedForEach = async <T>(
  source: T[],
  body: (value: T, loopState: any) => void,
  loopState?: any,
): Promise<void> =>
  new Promise((resolve) => {
    forEachBody(source[Symbol.iterator](), body, loopState || {}, resolve);
  });
