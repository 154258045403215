import React from 'react';
import { IconChevronDown } from '@tabler/icons-react';
import classNames from 'classnames';
import { Button, Popover } from '@noloco/components';
import {
  ButtonType,
  OUTLINE,
} from '@noloco/components/src/components/button/buttonTypes';

type MobileActionButtonsWrapperProps = {
  children: any;
  newButtonVisible: boolean;
  rounded?: boolean;
  type?: ButtonType;
};

const MobileActionButtonsWrapper = ({
  children,
  newButtonVisible,
  rounded = true,
  type,
}: MobileActionButtonsWrapperProps): JSX.Element => {
  return (
    <Popover
      placement="bottom-end"
      content={<div className="flex flex-col w-48 space-y-1">{children}</div>}
    >
      <Button
        className={classNames(
          'hidden sm:flex items-center space-x-2 h-8 border-l',
          {
            'border-l rounded-br-lg rounded-tr-lg': newButtonVisible && rounded,
            'rounded-lg': !newButtonVisible && rounded,
            'border-t border-r border-b': type === OUTLINE,
            'rounded-tr-none rounded-br-none': !rounded,
          },
        )}
        type={type}
        variant="primary"
        border={[false]}
        rounded={false}
      >
        <div className="py-px">
          <IconChevronDown size={16} />
        </div>
      </Button>
    </Popover>
  );
};

export default MobileActionButtonsWrapper;
