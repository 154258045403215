import { Omit } from 'lodash';
import { DataFieldIdentifier } from '../models/ProjectArrayTypeMap';
import { DECIMAL, DataFieldType, INTEGER, TEXT } from './dataTypes';
import { KeyValue } from './keyValue';
import { Relationship } from './relationships';

// Authentication

export const NONE: Authentication = 'NONE';
export const OAUTH2: Authentication = 'OAUTH2';

export type Authentication = 'NONE' | 'OAUTH2';

export const apiAuthenticationTypes: Authentication[] = [NONE, OAUTH2];

export type OAuth2Options = {
  accessToken?: string;
  accessTokenUrl?: string;
  authorizationUrl?: string;
  callbackUrl?: string;
  clientId?: string;
  clientSecret?: string;
  refreshToken?: string;
  scopes?: string;
};

export type OAuth2CallbackState = {
  dataSourceId: number;
  projectName: string;
};

// Pagination

export const CURSOR: Pagination = 'CURSOR';
export const OFFSET: Pagination = 'OFFSET';
export const PAGE: Pagination = 'PAGE';

export type Pagination = 'CURSOR' | 'OFFSET' | 'PAGE';

// Endpoints

export type Endpoint = {
  body: string | null;
  cursorPath: string | null;
  display: string;
  fields: EndpointDataField[] | null;
  firstPage: number | null;
  headers: KeyValue[];
  idField: string | null;
  method: string;
  pagination: Pagination | null;
  parameters: KeyValue[];
  path: string;
  resultPath: string[] | null;
};

export const ARRAY: EndpointDataFieldType = 'ARRAY';
export const NESTED: EndpointDataFieldType = 'NESTED';

export type EndpointDataFieldType = DataFieldType | string | 'ARRAY' | 'NESTED';

export interface EndpointDataFieldOption {
  display: string;
}
export interface EndpointDataField extends Omit<DataFieldIdentifier, 'id'> {
  display: string;
  options?: EndpointDataFieldOption[];
  path: string[];
  relationship: Relationship | null;
  type: EndpointDataFieldType;
}

// Data Source Connection

export type ConnectionInput = {
  authentication: Authentication;
  baseUrl: string;
  endpoints: Endpoint[];
  headers: KeyValue[];
  options: OAuth2Options | null;
};

export interface ApiConnection {
  authentication: Authentication;
  baseUrl: string;
  headers: KeyValue[];
  options: OAuth2Options | null;
}

// Relationships

export const ID_FIELD_TYPES = [DECIMAL, INTEGER, TEXT];
