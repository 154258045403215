import React from 'react';
import FormField from '../form/FormField';
import SubmitButton from './SubmitButton';

const RegisterForm = ({
  children,
  disabled,
  emailLabel,
  buttonText,
  confirmPasswordLabel,
  passwordLabel,
  hideEmail,
  email,
  footer,
  password,
  confirmPassword,
  setEmail,
  setPassword,
  setConfirmPassword,
  emailErrorMessage,
  passwordErrorMessage,
  confirmPasswordErrorMessage,
  surface,
  socialLogins,
}: any) => (
  <>
    {children}
    {!hideEmail && (
      <FormField
        aria-label="Email address"
        autoComplete="email"
        name="email"
        type="email"
        onChange={({ target: { value } }: any) => setEmail(value)}
        required
        errorType="below-solid"
        label={emailLabel}
        placeholder=""
        value={email}
        errorMessage={emailErrorMessage}
        surface={surface}
      />
    )}
    <FormField
      aria-label="Password"
      autoComplete="current-password"
      className="mt-3"
      name="password"
      type="password"
      onChange={({ target: { value } }: any) => setPassword(value)}
      required
      errorType="below-solid"
      label={passwordLabel}
      placeholder=""
      errorMessage={passwordErrorMessage}
      surface={surface}
      value={password}
    />
    <FormField
      aria-label="Confirm password"
      className="mt-3"
      name="confirm-password"
      type="password"
      onChange={({ target: { value } }: any) => setConfirmPassword(value)}
      required
      errorType="below-solid"
      label={confirmPasswordLabel}
      placeholder=""
      value={confirmPassword}
      errorMessage={confirmPasswordErrorMessage}
      surface={surface}
    />
    <SubmitButton disabled={disabled}>{buttonText}</SubmitButton>
    {socialLogins}
    {footer}
  </>
);

export default RegisterForm;
