import React from 'react';
import last from 'lodash/last';
import { DataField } from '../models/DataTypeFields';
import { DataType } from '../models/DataTypes';
import { DepValue } from '../models/Element';
import StateItem from '../models/StateItem';
import { getIconForDataField } from './dataFieldIcons';

export type DataItemOption = {
  label: any;
  heading?: boolean;
  value?: StateItem;
  disabled?: boolean;
  options?: DataItemOption[];
};

export type DataItemValueOption = Omit<DataItemOption, 'heaing' | 'options'> & {
  icon: any;
  buttonLabel: string;
  field: DataField | undefined;
  dataType: DataType | undefined;
  options?: DataItemValueOption[];
};

export type SelectItemOption = {
  label: any;
  value?: string;
  disabled?: boolean;
  options?: SelectItemOption[];
};

export const getElementStateKey = (screen: string, selectedState: string) => {
  if (!screen && !selectedState) {
    return null;
  }

  if (!screen) {
    return selectedState;
  }

  if (!selectedState) {
    return screen;
  }

  return `${screen}.${selectedState}`;
};

export const formatStateItemAsOption = (
  stateItem: StateItem,
  dataPath: { display: string }[] = [],
  field?: DataField,
  dataType?: DataType,
): DataItemValueOption & { value: StateItem } => {
  const Icon = getIconForDataField({
    name: last((stateItem.path || '').split('.')),
    type: stateItem.dataType,
  } as DataField);
  return {
    icon: <Icon size={16} />,
    label: stateItem.display,
    buttonLabel: [...dataPath, stateItem]
      .map((pathItem) => pathItem.display)
      .filter(Boolean)
      .join(' > '),
    value: stateItem,
    field,
    dataType,
  };
};

export const flattenStateItem = (value: StateItem | DepValue): string =>
  value ? `${value.id}${value.path ? `.${value.path}` : ''}` : '';
