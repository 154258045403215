import PropTypes from 'prop-types';
import tShirtSizes from '../constants/tShirtSizes';
import variants from '../constants/variants';

export const oneOfKeys = (object: any) => PropTypes.oneOf(Object.keys(object));

export const oneOfValues = (object: any) =>
  PropTypes.oneOf(Object.values(object));

export const oneOfVariants = PropTypes.oneOf(variants);
export const oneOfTShirtSizes = PropTypes.oneOf(tShirtSizes);

export const oneOfWithDefault = (array: any) =>
  PropTypes.oneOf(['default', ...array]);
