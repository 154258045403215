import React, { useCallback, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import { Loader, Modal } from '@noloco/components';
import { MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import MarkdownText from '../../../components/MarkdownText';
import {
  CREATE,
  DELETE,
  ON_DEMAND,
  UPDATE,
} from '../../../constants/actionTypes';
import { IFRAME } from '../../../constants/elements';
import useActions from '../../../utils/hooks/useActions';
import useActionsWithFormInputs from '../../../utils/hooks/useActionsWithFormInputs';
import { getText } from '../../../utils/lang';
import ActionButtonForm from './ActionButtonForm';
import RecordIframe from './RecordIframe';

const ACTIONS_TYPES_WITH_INPUT = [CREATE, UPDATE, IFRAME];

const actionRequiresInput = (action: any) => {
  if (!ACTIONS_TYPES_WITH_INPUT.includes(action.type)) {
    return false;
  }

  return (
    get(action, 'formFields', []).some((formField: any) => !formField.hidden) ||
    action.type === IFRAME
  );
};

const ModalActionButton = ({
  action,
  actionButton,
  actionIndex,
  dataType,
  onDelete,
  onFinish,
  onNavigate,
  onNext,
  onRunWorflow,
  project,
  record,
  recordScope,
}: any) => {
  const [lastExecuted, setLastExecuted] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { submitForm } = useActionsWithFormInputs();

  const { onExecuteAction } = useActions(
    submitForm,
    onDelete,
    onNavigate,
    onNext,
    onRunWorflow,
    submitForm,
  );
  const onConfirm = useCallback(() => {
    setLastExecuted(actionIndex);
    return onExecuteAction(action, setIsLoading);
  }, [action, actionIndex, onExecuteAction]);

  const shouldContinue = useMemo(
    () =>
      actionIndex > 0 &&
      (!action || !actionRequiresInput(action)) &&
      lastExecuted !== actionIndex,
    [action, actionIndex, lastExecuted],
  );

  const size = useMemo(() => {
    const type = get(action, 'type', null);

    if (type === DELETE) {
      return SM;
    }

    if (type === IFRAME) {
      return get(action, 'props.size', MD);
    }

    return MD;
  }, [action]);

  useEffect(() => {
    if (shouldContinue) {
      onConfirm();
    }
  }, [onConfirm, shouldContinue]);

  return (
    record && (
      <Modal
        closeOnOutsideClick={false}
        confirmDisabled={isLoading || shouldContinue}
        data-testid="action-button-modal"
        onClose={() => onFinish(true)}
        onCancel={() => onFinish(true)}
        onConfirm={onConfirm}
        confirmText={
          isLoading || shouldContinue ? (
            <Loader size="sm" />
          ) : (
            getText('elements.VIEW.actionButtons.confirm')
          )
        }
        size={size}
        variant={action.type === DELETE ? 'danger' : 'primary'}
        title={actionButton.title}
      >
        {actionButton.description && (
          <div className="mb-6 text-base w-full">
            <MarkdownText disabledHeadings={false}>
              {actionButton.description}
            </MarkdownText>
          </div>
        )}

        {action && action.type === IFRAME && (
          <RecordIframe
            dataType={dataType}
            project={project}
            record={record}
            showBorder={false}
            {...action.iframe}
          />
        )}

        {action && ![DELETE, ON_DEMAND, IFRAME].includes(action.type) && (
          <ActionButtonForm
            action={action}
            dataType={dataType}
            onSuccess={onNext}
            onLoadingChange={setIsLoading}
            project={project}
            record={record}
            scope={recordScope}
          />
        )}
      </Modal>
    )
  );
};

export default ModalActionButton;
