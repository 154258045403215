import React from 'react';
import { BaseModal, Button } from '@noloco/components';
import { XL } from '@noloco/core/src/constants/screens';
import { getText } from '@noloco/core/src/utils/lang';
import backgroundImage from '../../img/onboarding-bg.png';
import onboardingChecklist from '../../img/onboarding-checklist.png';

const LANG_KEY = 'newProject.onboardingModal';

const OnboardingModal = ({ replaceQueryParams }: any) => (
  <BaseModal
    size={XL}
    closeOnOutsideClick={true}
    onClose={() => replaceQueryParams({ __onboardingModal: undefined })}
  >
    <div
      className="p-8 bg-cover bg-no-repeat"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="w-full grid grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-4">
        <div className="flex items-center col-span-1">
          <div>
            <h1 className="text-xl font-bold text-black">
              {getText(LANG_KEY, 'title')}
            </h1>
            <hr className="my-3 bg-gray-100" />
            <h1 className="text-lg text-gray-700">
              {getText(LANG_KEY, 'subTitle')}
            </h1>
            <Button
              className="w-full mt-4 bg-pink-500"
              onClick={() =>
                replaceQueryParams({ __onboardingModal: undefined })
              }
            >
              {getText(LANG_KEY, 'getStarted')}
            </Button>
          </div>
        </div>
        <div className="col-span-1">
          <img
            src={onboardingChecklist}
            alt="onboarding-checklist"
            className="rounded-lg shadow-lg"
          />
          <h1 className="text-lg text-gray-700 mt-4">
            {getText(LANG_KEY, 'onboardingChecklist')}
          </h1>
        </div>
      </div>
    </div>
  </BaseModal>
);

export default OnboardingModal;
