import { useEffect, useState } from 'react';
import { CREATE, UPDATE } from '../../../constants/actionTypes';
import useActionWithHiddenInputsMutation from '../../../utils/hooks/useActionWithHiddenInputsMutation';
import useActions from '../../../utils/hooks/useActions';
import useActionsWithHiddenInputs from '../../../utils/hooks/useActionsWithHiddenInputs';

const OneClickRecordActionButton = ({
  action,
  actionIndex,
  dataType,
  onDelete,
  onNavigate,
  onNext,
  onRunWorflow,
  project,
  record,
}: any) => {
  const [lastExecuted, setLastExecuted] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const createMutation = useActionWithHiddenInputsMutation(
    action,
    dataType,
    CREATE,
    project,
    record,
  );
  const updateMutation = useActionWithHiddenInputsMutation(
    action,
    dataType,
    UPDATE,
    project,
    record,
  );

  const { onCreate, onUpdate } = useActionsWithHiddenInputs(
    createMutation,
    updateMutation,
    onNext,
  );

  const { onExecuteAction } = useActions(
    onCreate,
    onDelete,
    onNavigate,
    onNext,
    onRunWorflow,
    onUpdate,
  );

  useEffect(() => {
    if (!isLoading && lastExecuted !== actionIndex) {
      setLastExecuted(actionIndex);
      onExecuteAction(action, setIsLoading);
    }
  }, [
    action,
    actionIndex,
    isLoading,
    lastExecuted,
    onExecuteAction,
    setIsLoading,
  ]);

  return null;
};

export default OneClickRecordActionButton;
