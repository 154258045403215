import React, { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import gql from 'graphql-tag';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { Badge, Button, Loader, RatingInput } from '@noloco/components';
import { addDataItemToCollectionCache } from '@noloco/core/src/utils/apolloCache';
import withDataFields from '../../components/canvas/withDataFields';
import {
  BOARD,
  CALENDAR,
  CARDS,
  CHARTS,
  COLUMNS,
  CONVERSATION,
  GANTT,
  MAP,
  ROWS,
  SPLIT,
  TABLE,
  TABLE_FULL,
  TIMELINE,
} from '../../constants/collectionLayouts';
import { darkModeColors } from '../../constants/darkModeColors';
import { DATE, INTEGER, SINGLE_OPTION, TEXT } from '../../constants/dataTypes';
import { INTERNAL } from '../../constants/dataWrapperTypes';
import { DATE_MED } from '../../constants/dateFormatOptions';
import { RATING } from '../../constants/fieldFormats';
import { GREATER_OR_EQUAL, LESS_OR_EQUAL } from '../../constants/operators';
import { ASC, OrderByDirection } from '../../constants/orderByDirections';
import { UPDATE } from '../../constants/workflowTriggerTypes';
import { DataField } from '../../models/DataTypeFields';
import DataTypes, { DataType } from '../../models/DataTypes';
import { DepValue } from '../../models/Element';
import { BaseRecord, RecordEdge, RecordValue } from '../../models/Record';
import { queryStateSelector } from '../../selectors/queriesSelectors';
import { removeDataItemFromCollectionCache } from '../../utils/apolloCache';
import { getDepsForField } from '../../utils/data';
import { findPreviewFields } from '../../utils/dataTypes';
import {
  DataFieldWithDataType,
  getFieldFromDependency,
  sortOptions,
} from '../../utils/fields';
import { useGraphQlErrorAlert } from '../../utils/hooks/useAlerts';
import { useAuth } from '../../utils/hooks/useAuth';
import { AutoFormProvider } from '../../utils/hooks/useAutoForm';
import useDarkMode from '../../utils/hooks/useDarkMode';
import { getText } from '../../utils/lang';
import { getOperatorForFieldFilter } from '../../utils/operator';
import pascalCase from '../../utils/pascalCase';
import { isMultiRelationship } from '../../utils/relationships';
import { DEBOUNCED_TYPES } from '../../utils/useFormFieldsState';
import DataListWrapper from '../DataListWrapper';
import { ListItem } from '../RepeatingList';
import DeleteConfirmButtonWrapper from './DeleteConfirmButtonWrapper';
import EditButtonWrapper from './EditButtonWrapper';
import Title from './Title';
import AdditionalElements, {
  AdditionalElement,
} from './collections/AdditionalElements';
import CollectionCalendarPopoverContent from './collections/CollectionCalendarPopoverContent';
import CollectionCards from './collections/CollectionCards';
import CollectionConversation from './collections/CollectionConversation';
import CollectionFilter from './collections/CollectionFilter';
import CollectionRows from './collections/CollectionRows';
import CollectionTable from './collections/CollectionTable';
import CollectionTableHead from './collections/CollectionTableHead';
import DataItemFormModal from './collections/DataItemFormModal';
import { ReadOnlyFieldCellValue } from './collections/FieldCell';
import RowPagination from './collections/pagination/RowPagination';
import InlineAutoForm from './forms/InlineAutoForm';

const COLLECTION_ACTION_BUTTON_CELL_STYLE_BASE = 'flex right-2 text-center';

export const COLLECTION_ACTION_BUTTON_CELL_STYLES = {
  [BOARD]: classNames('absolute', COLLECTION_ACTION_BUTTON_CELL_STYLE_BASE),
  [CARDS]: classNames('absolute', COLLECTION_ACTION_BUTTON_CELL_STYLE_BASE),
  [MAP]: classNames('absolute', COLLECTION_ACTION_BUTTON_CELL_STYLE_BASE),
  [CALENDAR]: classNames('absolute', COLLECTION_ACTION_BUTTON_CELL_STYLE_BASE),
  [COLUMNS]: classNames(
    'absolute px-2',
    COLLECTION_ACTION_BUTTON_CELL_STYLE_BASE,
  ),
  [ROWS]: classNames('absolute', COLLECTION_ACTION_BUTTON_CELL_STYLE_BASE),
  [TABLE]: classNames('sticky right-2 text-center z-20'),
  [TABLE_FULL]: classNames('sticky right-2 text-center z-20'),
  [SPLIT]: classNames('absolute', COLLECTION_ACTION_BUTTON_CELL_STYLE_BASE),
};

const COLLECTION_ACTION_BUTTON_WRAPPER_STYLE_NON_TABLE =
  'flex flex-row-reverse flex-wrap gap-x-1';

const COLLECTION_ACTION_BUTTON_WRAPPER_STYLE_TABLE =
  'absolute right-0 flex gap-x-1 items-center my-auto h-full bottom-1/2';

export const COLLECTION_ACTION_BUTTON_WRAPPER_STYLES = {
  [BOARD]: COLLECTION_ACTION_BUTTON_WRAPPER_STYLE_NON_TABLE,
  [CARDS]: COLLECTION_ACTION_BUTTON_WRAPPER_STYLE_NON_TABLE,
  [MAP]: COLLECTION_ACTION_BUTTON_WRAPPER_STYLE_NON_TABLE,
  [CALENDAR]: COLLECTION_ACTION_BUTTON_WRAPPER_STYLE_NON_TABLE,
  [COLUMNS]: COLLECTION_ACTION_BUTTON_WRAPPER_STYLE_NON_TABLE,
  [ROWS]: COLLECTION_ACTION_BUTTON_WRAPPER_STYLE_NON_TABLE,
  [TABLE]: COLLECTION_ACTION_BUTTON_WRAPPER_STYLE_TABLE,
  [TABLE_FULL]: COLLECTION_ACTION_BUTTON_WRAPPER_STYLE_TABLE,
  [SPLIT]: COLLECTION_ACTION_BUTTON_WRAPPER_STYLE_NON_TABLE,
};

const COLLECTION_ACTION_BUTTON_STYLE_NON_TABLE = 'mt-2';
const COLLECTION_ACTION_BUTTON_STYLE_TABLE = 'inline-block';

export const COLLECTION_ACTION_BUTTON_STYLES = {
  [BOARD]: COLLECTION_ACTION_BUTTON_STYLE_NON_TABLE,
  [CARDS]: COLLECTION_ACTION_BUTTON_STYLE_NON_TABLE,
  [MAP]: COLLECTION_ACTION_BUTTON_STYLE_NON_TABLE,
  [CALENDAR]: COLLECTION_ACTION_BUTTON_STYLE_NON_TABLE,
  [COLUMNS]: COLLECTION_ACTION_BUTTON_STYLE_NON_TABLE,
  [ROWS]: COLLECTION_ACTION_BUTTON_STYLE_NON_TABLE,
  [TABLE]: COLLECTION_ACTION_BUTTON_STYLE_TABLE,
  [TABLE_FULL]: COLLECTION_ACTION_BUTTON_STYLE_TABLE,
  [SPLIT]: COLLECTION_ACTION_BUTTON_STYLE_NON_TABLE,
};

export const COLLECTION_ITEMS = {
  [ROWS]: CollectionRows,
  [CARDS]: CollectionCards,
  [COLUMNS]: CollectionCards,
  [TABLE]: CollectionTable,
  [TABLE_FULL]: CollectionTable,
  [CONVERSATION]: CollectionConversation,
  [BOARD]: CollectionCards,
  [SPLIT]: CollectionRows,
  [CALENDAR]: CollectionCalendarPopoverContent,
};

const defaultCollectionWrapperColors = {
  background: 'bg-white',
  border: 'border-gray-200',
  divide: 'divide-gray-200',
};

const darkModeCollectionWrapperColors = {
  background: darkModeColors.surfaces.elevation1,
  border: darkModeColors.borders.one,
  divide: darkModeColors.divides.one,
};

export const COLLECTION_WRAPPER_STYLES = {
  [ROWS]: (darkModeEnabled = false): string => {
    const backgroundColor = darkModeEnabled
      ? darkModeCollectionWrapperColors.background
      : defaultCollectionWrapperColors.background;

    const borderColor = darkModeEnabled
      ? darkModeCollectionWrapperColors.border
      : defaultCollectionWrapperColors.border;

    return `flex flex-col ${backgroundColor} border ${borderColor} rounded-lg shadow-md mb-2 overflow-y-hidden`;
  },
  [CARDS]: (): string =>
    'grid grid-cols-3 md:grid-cols-1 gap-4 mt-4 max-w-full',
  [COLUMNS]: (): string =>
    'flex sm:flex-wrap mt-4 overflow-y-auto max-w-full space-x-3',
  [CONVERSATION]: (): string =>
    'flex flex-col bg-white border border-gray-200 rounded-lg shadow-md mt-4 divide-y divide-gray-200',
  [TABLE]: (darkModeEnabled = false): string => {
    const backgroundColor = darkModeEnabled
      ? darkModeCollectionWrapperColors.background
      : defaultCollectionWrapperColors.background;

    const borderColor = darkModeEnabled
      ? darkModeCollectionWrapperColors.border
      : defaultCollectionWrapperColors.border;

    return `${backgroundColor} w-full shadow-lg mb-2 overflow-y-hidden overflow-x-auto border ${borderColor} rounded-lg`;
  },
  [TABLE_FULL]: (darkModeEnabled = false): string => {
    const backgroundColor = darkModeEnabled
      ? darkModeCollectionWrapperColors.background
      : defaultCollectionWrapperColors.background;

    const borderColor = darkModeEnabled
      ? darkModeCollectionWrapperColors.border
      : defaultCollectionWrapperColors.border;

    return `${backgroundColor} w-full overflow-x-auto overflow-y-hidden border ${borderColor} h-full`;
  },
  [MAP]: (darkModeEnabled = false): string => {
    const backgroundColor = darkModeEnabled
      ? darkModeColors.surfaces.elevation0
      : defaultCollectionWrapperColors.background;

    const borderColor = darkModeEnabled
      ? darkModeCollectionWrapperColors.border
      : defaultCollectionWrapperColors.border;

    return `${backgroundColor} w-full overflow-x-auto overflow-y-auto border ${borderColor} h-full`;
  },

  [CALENDAR]: (): string => 'w-full overflow-hidden h-full',
  [TIMELINE]: (): string =>
    'flex flex-col flex-grow w-full h-full overflow-hidden',
  [GANTT]: (): string => 'w-full overflow-hidden h-full',
  [BOARD]: (): string =>
    'flex items-start overflow-y-auto max-w-full space-x-4 mt-6 h-full px-4',
  [SPLIT]: (darkModeEnabled = false): string => {
    const backgroundColor = darkModeEnabled
      ? darkModeColors.surfaces.elevation0
      : defaultCollectionWrapperColors.background;

    const borderColor = darkModeEnabled
      ? darkModeCollectionWrapperColors.border
      : defaultCollectionWrapperColors.border;

    return `${backgroundColor} w-full overflow-x-auto overflow-y-hidden border ${borderColor} border-r-0`;
  },
  [CHARTS]: (): string => 'flex flex-col max-w-full',
};

export const COLLECTION_ITEM_STYLES = {
  [BOARD]: (): string => 'flex relative',
  [ROWS]: (): string => 'text-xs flex flex-wrap relative',
  [CONVERSATION]: (): string => 'text-xs flex flex-wrap',
  [CARDS]: (): string => 'text-xs flex flex-wrap h-full relative',
  [COLUMNS]: (): string =>
    'text-xs w-80 flex flex-shrink-0 flex-wrap sm:w-full mb-3 px-2 h-full relative',
  [TABLE]: (isDarkModeEnabled = false): string =>
    `text-xs w-full px-6 hover:${
      isDarkModeEnabled ? darkModeColors.surfaces.elevation2 : 'bg-gray-100'
    } hover:bg-opacity-25 relative`,
};

// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
COLLECTION_ITEM_STYLES[SPLIT] = COLLECTION_ITEM_STYLES[ROWS];
// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
COLLECTION_ITEM_STYLES[TABLE_FULL] = COLLECTION_ITEM_STYLES[TABLE];

export const PAGINATION_STYLES: Record<
  string,
  (isDarkModeEnabled: boolean) => string
> = {
  [ROWS]: (isDarkModeEnabled = false): string =>
    `justify-end space-x-4 border-t ${
      isDarkModeEnabled
        ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
        : 'bg-white'
    } rounded-bl-lg rounded-br-lg sticky left-0 bottom-0`,
  [TABLE_FULL]: (isDarkModeEnabled = false): string =>
    `justify-end space-x-4 ${
      isDarkModeEnabled
        ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
        : 'bg-white'
    } sticky left-0 bottom-0 border-t z-20`,
  [SPLIT]: (isDarkModeEnabled = false): string =>
    `justify-end space-x-4 ${
      isDarkModeEnabled
        ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
        : 'bg-white'
    } sticky left-0 bottom-0 border-t z-20`,
  [CONVERSATION]: (isDarkModeEnabled = false): string =>
    `justify-end space-x-4 ${
      isDarkModeEnabled ? darkModeColors.surfaces.elevation1 : 'bg-white'
    } rounded-bl-lg rounded-br-lg`,
  [CARDS]: (): string => 'justify-center w-full col-span-3 md:col-span-1',
  [COLUMNS]: (): string => 'justify-center flex w-full',
  [MAP]: (isDarkModeEnabled = false): string =>
    `justify-end space-x-4 py-3 ${
      isDarkModeEnabled ? darkModeColors.surfaces.elevation1 : 'bg-white'
    } border rounded-lg sticky left-0 bottom-0 shadow-lg mt-auto`,
};

const identity = (val: any) => val;

export type Group = {
  id: string;
  depth: number;
  rawValue: RecordValue;
  label: RecordValue;
  rows?: (RecordEdge & { index: number })[];
  key: string;
  groups?: Group[];
};

export type GroupByConfig = {
  key: string;
  label: string;
  field?: DataFieldWithDataType;
  sortBy: (groupA: Group, groupB: Group) => number;
  renderLabel?: (val: RecordValue) => any;
  initialGroups?: Omit<Group, 'depth' | 'id'>[];
  groupValue?: (val: RecordValue) => RecordValue;
};

export const getGroupByDepPaths = (
  groupByDep: DepValue,
  dataField: DataFieldWithDataType | undefined,
  groupBySort: OrderByDirection | undefined,
  dataType: DataType,
  dataTypes: DataTypes,
): GroupByConfig => {
  const field =
    dataField ??
    (getFieldFromDependency(
      groupByDep.path.split('.'),
      dataType,
      dataTypes,
    ) as DataFieldWithDataType);

  const orderByDirection = groupBySort === ASC ? 1 : -1;

  const defaultSortBy = (groupA: Group, groupB: Group): number => {
    // Always put nil values at the bottom
    if (isNil(groupA.rawValue)) {
      return 1;
    }

    if (isNil(groupB.rawValue)) {
      return -1;
    }

    return (groupA.rawValue > groupB.rawValue ? 1 : -1) * orderByDirection;
  };

  if (groupByDep.dataType === TEXT || !groupByDep.dataType) {
    return {
      field,
      key: groupByDep.path || '',
      label: groupByDep.path || '',
      sortBy: defaultSortBy,
    };
  }

  if (groupByDep.dataType === SINGLE_OPTION) {
    if (field) {
      return {
        key: groupByDep.path || '',
        label: groupByDep.path || '',
        field,
        renderLabel: (val: RecordValue) => {
          const option = field.options?.find((op) => op.name === val);
          return option ? option.display : val;
        },
        sortBy: (groupA: Group, groupB: Group) => {
          if (isNil(groupA.rawValue)) {
            return 1;
          }

          if (isNil(groupB.rawValue)) {
            return -1;
          }

          const optionA = field.options?.find(
            (op) => op.name === groupA.rawValue,
          );
          const optionB = field.options?.find(
            (op) => op.name === groupB.rawValue,
          );

          if (optionA && optionB) {
            return (optionA.order - optionB.order) * orderByDirection;
          }

          if (optionA) {
            return -1 * orderByDirection;
          }

          return optionB ? orderByDirection : 0;
        },
        initialGroups: sortOptions(field.options ?? []).map((option) => ({
          rawValue: option.name,
          label: option.display,
          rows: [],
          key: option.name,
        })),
      };
    }

    return {
      key: groupByDep.path || '',
      sortBy: defaultSortBy,
      label: groupByDep.path || '',
      field,
    };
  }

  if (groupByDep.dataType === DATE) {
    return {
      key: groupByDep.path || '',
      label: groupByDep.path || '',
      field,
      groupValue: (dateWithTime: any) => {
        if (!dateWithTime) {
          return null;
        }

        return DateTime.fromISO(dateWithTime).toUTC().toISODate();
      },
      sortBy: (groupA: Group, groupB: Group) => {
        if (isNil(groupA.rawValue)) {
          return 1;
        }

        if (isNil(groupB.rawValue)) {
          return -1;
        }

        const dateA = DateTime.fromISO(groupA.rawValue as string)
          .toUTC()
          .startOf('day');
        const dateB = DateTime.fromISO(groupB.rawValue as string)
          .toUTC()
          .startOf('day');

        return (dateA > dateB ? 1 : -1) * orderByDirection;
      },
      renderLabel: (dateWithTime: any) => {
        if (!dateWithTime) {
          return null;
        }

        // @ts-expect-error TS(2559): Type '"DD"' has no properties in common with type ... Remove this comment to see the full error message
        return DateTime.fromISO(dateWithTime).toUTC().toLocaleString(DATE_MED);
      },
    };
  }

  if (field && field.type === INTEGER) {
    return {
      key: groupByDep.path,
      field,
      label: groupByDep.path || '',
      sortBy:
        field.typeOptions?.format === RATING
          ? (groupA: Group, groupB: Group) =>
              (((groupA.rawValue as number) ?? 0) -
                ((groupB.rawValue as number) ?? 0)) *
              orderByDirection
          : defaultSortBy,
      renderLabel:
        field.typeOptions?.format === RATING
          ? (rating: RecordValue) => (
              <RatingInput
                disabled={true}
                maxRating={get(field.typeOptions, 'max')}
                value={rating}
              />
            )
          : undefined,
    };
  }

  const relatedType = dataTypes.getByName(groupByDep.dataType);
  if (relatedType) {
    const { textFields } = findPreviewFields(relatedType.fields, relatedType);

    const key = `${groupByDep.path}.id`;
    if (textFields.length === 0) {
      return {
        key,
        field,
        sortBy: defaultSortBy,
        label: key,
      };
    }

    return {
      key,
      field,
      sortBy: defaultSortBy,
      label: `${groupByDep.path}.${textFields[0].name}`,
    };
  }

  return {
    key: groupByDep.path,
    field,
    sortBy: defaultSortBy,
    label: groupByDep.path,
  };
};

const shouldSubmitOnBlur = (field: DataField) =>
  !DEBOUNCED_TYPES.includes(field.type);

type CollectionProps = {};

const Collection = forwardRef<any, CollectionProps>(
  (
    {
      // @ts-expect-error TS(2339): Property 'addNewButton' does not exist on type 'Co... Remove this comment to see the full error message
      addNewButton,
      // @ts-expect-error TS(2339): Property 'allowDeleting' does not exist on type 'C... Remove this comment to see the full error message
      allowDeleting,
      // @ts-expect-error TS(2339): Property 'allowEditing' does not exist on type 'Co... Remove this comment to see the full error message
      allowEditing,
      // @ts-expect-error TS(2339): Property 'additionalElements' does not exist on ty... Remove this comment to see the full error message
      additionalElements,
      // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Colle... Remove this comment to see the full error message
      className,
      // @ts-expect-error TS(2339): Property 'dataList' does not exist on type 'Collec... Remove this comment to see the full error message
      dataList,
      // @ts-expect-error TS(2339): Property 'fields' does not exist on type 'Collecti... Remove this comment to see the full error message
      fields: formFields,
      // @ts-expect-error TS(2339): Property 'layout' does not exist on type 'Collecti... Remove this comment to see the full error message
      layout,
      // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type 'Coll... Remove this comment to see the full error message
      editorMode,
      // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type 'Col... Remove this comment to see the full error message
      elementPath,
      // @ts-expect-error TS(2339): Property 'editButtonText' does not exist on type '... Remove this comment to see the full error message
      editButtonText,
      // @ts-expect-error TS(2339): Property 'groupBy' does not exist on type 'Collect... Remove this comment to see the full error message
      groupBy,
      // @ts-expect-error TS(2339): Property 'limitPerGroup' does not exist on type 'C... Remove this comment to see the full error message
      limitPerGroup,
      // @ts-expect-error TS(2339): Property 'inlineEditing' does not exist on type 'C... Remove this comment to see the full error message
      inlineEditing,
      // @ts-expect-error TS(2339): Property 'newButtonText' does not exist on type 'C... Remove this comment to see the full error message
      newButtonText,
      // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type 'Collec... Remove this comment to see the full error message
      subtitle,
      // @ts-expect-error TS(2339): Property 'title' does not exist on type 'Collectio... Remove this comment to see the full error message
      title,
      // @ts-expect-error TS(2339): Property 'scope' does not exist on type 'Collectio... Remove this comment to see the full error message
      scope,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Collect... Remove this comment to see the full error message
      onClick,
      // @ts-expect-error TS(2339): Property 'emptyState' does not exist on type 'Coll... Remove this comment to see the full error message
      emptyState,
      // @ts-expect-error TS(2339): Property 'filters' does not exist on type 'Collect... Remove this comment to see the full error message
      filters,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Colle... Remove this comment to see the full error message
      theme,
      // @ts-expect-error TS(2339): Property 'variables' does not exist on type 'Colle... Remove this comment to see the full error message
      variables,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type 'Collect... Remove this comment to see the full error message
      project,
    },
    ref,
  ) => {
    const [showFormModal, setShowFormModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [paginationQuery, setPaginationQuery] = useState(null);
    const [filterValues, setFilterValues] = useState({});
    const [groupOpenStates, setGroupOpenStates] = useState({});
    const [isDarkModeEnabled] = useDarkMode();
    const element = get(project.elements, elementPath, {});
    // @ts-expect-error TS(2339): Property 'client' does not exist on type 'unknown'... Remove this comment to see the full error message
    const { client: apolloClient } = useAuth();
    const collectionQuery = useSelector(queryStateSelector(element.id));
    const dataType = useMemo(
      () =>
        dataList &&
        dataList.dataSource === INTERNAL &&
        dataList.dataType &&
        project.dataTypes.getByName(dataList.dataType),
      [dataList, project.dataTypes],
    );
    const fields = useMemo(() => (dataType ? dataType.fields : []), [dataType]);
    const validFilters = useMemo(
      () =>
        filters
          .map((filter: any) => {
            const field =
              filter.field &&
              fields.find(({ name }: any) => name === filter.field);
            if (field) {
              return {
                filter,
                field,
              };
            }
            return null;
          })
          .filter(Boolean),
      [fields, filters],
    );

    const errorAlert = useGraphQlErrorAlert();
    const onFormError = useCallback(
      (error: any) => {
        errorAlert(getText('errors.data.message'), error);
      },
      [errorAlert],
    );

    const updateFilterValue = useCallback(
      (filter: any, field: any, newValue: any) => {
        return setFilterValues((existingFilterValues) => ({
          ...existingFilterValues,
          [field.name]: newValue === null ? undefined : newValue,
        }));
      },
      [setFilterValues],
    );

    const toggleGroupState = useCallback(
      (groupKey: any) => () => {
        setGroupOpenStates((currentGroupOpenStates) => ({
          ...currentGroupOpenStates,
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          [groupKey]: !currentGroupOpenStates[groupKey],
        }));
      },
      [],
    );

    const additionalElementsWithActions = useMemo(
      () => [
        ...additionalElements,
        ...(inlineEditing && formFields
          ? formFields
              .filter((formField: any) => !formField.hidden && formField.field)
              // @ts-expect-error TS(7006): Parameter 'formField' implicitly has an 'any' type... Remove this comment to see the full error message
              .map((formField, index) => ({
                id: `form-field-${formField.field}-${index}`,
                label: formField.label,

                render: (__: any, scope: any, record: BaseRecord) => (
                  <InlineAutoForm
                    dataType={dataType}
                    elementId={element.id}
                    field={formField.field}
                    project={project}
                    scope={scope}
                    ReadOnlyCell={() => (
                      <ReadOnlyFieldCellValue
                        field={formField.field}
                        layout={layout}
                        value={record}
                        config={formField}
                        project={project}
                        theme={theme}
                      />
                    )}
                    surface="light"
                  />
                ),
              }))
          : []),
        ...(allowEditing
          ? [
              {
                element: { id: 'edit' },
                render: (dataId: any, scope: any) => (
                  // @ts-expect-error TS(2322): Type '{ children: any; element: any; elementPath: ... Remove this comment to see the full error message
                  <EditButtonWrapper
                    element={element}
                    elementPath={elementPath}
                    scope={scope}
                    onClick={() => {
                      setShowFormModal(true);
                      setSelectedId(dataId);
                      return false;
                    }}
                    project={project}
                  >
                    {editButtonText ||
                      getText('core.COLLECTION.form.editButton')}
                  </EditButtonWrapper>
                ),
                label: '',
              },
            ]
          : []),
        ...(allowDeleting
          ? [
              {
                element: { id: 'delete' },
                render: (dataId: any, scope: any) => (
                  // @ts-expect-error TS(2322): Type '{ children: string; dataType: any; element: ... Remove this comment to see the full error message
                  <DeleteConfirmButtonWrapper
                    dataType={dataType}
                    element={element}
                    elementPath={elementPath}
                    scope={scope}
                    project={project}
                    onDelete={() => {
                      const { query, valuePath, variables } = collectionQuery;
                      removeDataItemFromCollectionCache(
                        dataId,
                        apolloClient,
                        gql`
                          ${query}
                        `,
                        dataType.name,
                        variables,
                        { collectionPathInput: valuePath.replace(/\.$/, '') },
                      );
                    }}
                    itemId={dataId}
                  >
                    {getText('core.COLLECTION.form.deleteButton')}
                  </DeleteConfirmButtonWrapper>
                ),
                label: '',
              },
            ]
          : []),
      ],
      [
        additionalElements,
        inlineEditing,
        formFields,
        allowEditing,
        allowDeleting,
        dataType,
        element,
        project,
        theme,
        layout,
        elementPath,
        editButtonText,
        collectionQuery,
        apolloClient,
      ],
    );

    const filterComponents = useMemo(
      () =>
        validFilters.map(({ filter, field }: any) => (
          <div className="mx-2">
            {filter.label && (
              <label className="text-gray-400 font-medium uppercase">
                {filter.label}
              </label>
            )}
            <CollectionFilter
              project={project}
              filter={filter}
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              value={filterValues[field.name]}
              field={field}
              onChange={(newValue: any) =>
                updateFilterValue(filter, field, newValue)
              }
            />
          </div>
        )),
      [filterValues, project, updateFilterValue, validFilters],
    );

    const customFilters = useMemo(
      () =>
        validFilters
          .reduce((filterAcc: any, { filter, field }: any) => {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            const filterValue = filterValues[field.name];
            if (filterValue !== undefined) {
              const fieldKey =
                field.relationship && !isMultiRelationship(field.relationship)
                  ? `${field.name}Id`
                  : field.name;

              if (field.type !== DATE) {
                return [
                  ...filterAcc,
                  {
                    field: fieldKey,
                    operator: getOperatorForFieldFilter(field, filter),
                    result:
                      field.relationship && filterValue
                        ? filter.multiple && filterValue.edges
                          ? filterValue.edges.map((i: any) => i.node.id)
                          : [filterValue.id]
                        : filterValue,
                  },
                ];
              }

              return [
                ...filterAcc,
                {
                  field: fieldKey,
                  operator: GREATER_OR_EQUAL,
                  result: filterValue.start,
                },
                ...(filterValue.end
                  ? [
                      {
                        field: fieldKey,
                        operator: LESS_OR_EQUAL,
                        result: DateTime.fromISO(filterValue.end)
                          .endOf('day')
                          .toISO(),
                      },
                    ]
                  : []),
              ];
            }

            return filterAcc;
          }, [])
          .filter(Boolean),
      [filterValues, validFilters],
    );

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const CollectItem = COLLECTION_ITEMS[layout] || COLLECTION_ITEMS[ROWS];

    const EmptyState = useMemo(
      () => () => (
        <div
          className={classNames(
            'w-full p-16 flex items-center justify-center flex-col text-center',
            { 'col-span-3 md:col-span-1': layout === CARDS },
          )}
        >
          <h2 className="font-medium text-base">{emptyState.title.value}</h2>
          {!emptyState.image.hidden && emptyState.image.value.src && (
            <img
              className="rounded-lg w-full max-w-xs mt-4"
              src={emptyState.image.value.src}
              alt={emptyState.title.value}
            />
          )}
        </div>
      ),
      [emptyState, layout],
    );

    const closeDataItemModal = () => {
      setShowFormModal(false);
      setSelectedId(null);
    };

    const additionalDeps = useMemo(() => {
      if (dataType && formFields && formFields.length > 0 && inlineEditing) {
        return formFields.reduce((depAcc: any, formField: any) => {
          const field = dataType.fields.getByName(formField.field);

          if (field) {
            return [
              ...depAcc,
              ...getDepsForField(
                field,
                project.dataTypes,
                element.id,
                'edges.node.',
              ),
            ];
          }
          return depAcc;
        }, []);
      }

      return [];
    }, [dataType, element.id, formFields, inlineEditing, project.dataTypes]);

    const onAddDataItem = (newDataItem: any) => {
      if (collectionQuery && !selectedId) {
        const { query, variables, valuePath } = collectionQuery;
        const vars = {
          ...variables,
          after: null,
        };
        const creationKey = dataType.name === 'user' ? 'invite' : 'create';
        addDataItemToCollectionCache(
          {
            [`${creationKey}${pascalCase(dataType.name)}`]: newDataItem,
          },
          apolloClient,
          gql`
            ${query}
          `,
          dataType.name,
          vars,
          {
            collectionPathInput: valuePath.replace(/\.$/, ''),
          },
        );
      }

      setTimeout(() => {
        closeDataItemModal();
      }, 400);
    };

    const renderAdditionalElements = useCallback(
      (listItem: any, idPath: any) => (
        nestedScope: any,
        className: any,
        innerClassName: any,
        disableLabels: any,
      ) => {
        if (disableLabels) {
          return (
            additionalElementsWithActions &&
            additionalElementsWithActions.length > 0 &&
            additionalElementsWithActions.map((elementConfig, index) => (
              <AdditionalElement
                // @ts-expect-error TS(2322): Type '{ elementConfig: any; editorMode: any; key: ... Remove this comment to see the full error message
                elementConfig={elementConfig}
                editorMode={editorMode}
                element={element}
                key={index}
                elementPath={elementPath}
                dataId={get(listItem, idPath)}
                record={listItem}
                index={index}
                project={project}
                scope={nestedScope}
              />
            ))
          );
        }

        return (
          <AdditionalElements
            // @ts-expect-error TS(2322): Type '{ className: any; innerClassName: any; disab... Remove this comment to see the full error message
            className={className}
            innerClassName={innerClassName}
            disableLabels={disableLabels}
            elementPath={elementPath}
            element={element}
            dataId={get(listItem, idPath)}
            record={listItem}
            editorMode={editorMode}
            project={project}
            additionalElements={additionalElementsWithActions}
            scope={nestedScope}
          />
        );
      },
      [
        additionalElementsWithActions,
        editorMode,
        element,
        elementPath,
        project,
      ],
    );

    return (
      <div className={classNames(className)} ref={ref} onClick={onClick}>
        {(title || subtitle || filterComponents.length > 0 || addNewButton) && (
          <Title
            subtitle={{
              hidden: !subtitle,
              value: subtitle,
            }}
            title={{
              hidden: !title,
              value: title,
            }}
            className="mb-4"
          >
            {filterComponents}
            {addNewButton && dataType && (
              <Button
                className="whitespace-nowrap"
                onClick={() => setShowFormModal(true)}
              >
                {newButtonText ||
                  getText(
                    { dataType: dataType.display },
                    'core.COLLECTION.form.new',
                  )}
              </Button>
            )}
          </Title>
        )}
        <Box
          className={
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            COLLECTION_WRAPPER_STYLES[layout](isDarkModeEnabled as boolean) ||
            COLLECTION_WRAPPER_STYLES[ROWS](isDarkModeEnabled as boolean)
          }
        >
          {!dataType && false && (
            <div
              className={classNames(
                'p-4 bg-blue-100 text-blue-900 flex flex-col gap-2 rounded w-full mb-4',
                { 'col-span-3 md:col-span-1': layout === CARDS },
              )}
            >
              {getText('elements.COLLECTION.invalid')}
            </div>
          )}
          <DataListWrapper
            additionalDeps={additionalDeps}
            project={project}
            elementPath={elementPath}
            editorMode={editorMode}
            EmptyState={EmptyState}
            scope={scope}
            {...dataList}
            // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
            {...paginationQuery}
            customFilters={[
              ...customFilters,
              ...(dataList.customFilters || []),
            ]}
          >
            {({
              loading,
              connection,
              idPath = 'node.id',
              edges,
              rawData,
              pageInfo,
              totalCount,
              parentValuePath,
              error,
            }) => {
              if (loading) {
                return (
                  <div
                    className={classNames(
                      'flex justify-center items-center w-full p-6',
                      { 'col-span-3 md:col-span-1': layout === CARDS },
                    )}
                  >
                    <Loader type="Bars" size="sm" />
                  </div>
                );
              }

              if (error && !rawData) {
                console.log(error);
                return (
                  <div
                    className={classNames(
                      'flex justify-center items-center w-full p-6',
                      { 'col-span-3 md:col-span-1': layout === CARDS },
                    )}
                  >
                    <em>Something went wrong</em>
                  </div>
                );
              }

              if (edges.length === 0) {
                return <EmptyState />;
              }

              const WrappedListItem = withDataFields(
                ListItem,
                element,
                project,
                editorMode,
              );

              // @ts-expect-error TS(2339): Property 'listItem' does not exist on type '{}'.
              const Row = memo(({ listItem, index }) => (
                <AutoFormProvider
                  value={listItem}
                  dataType={dataType}
                  fields={formFields ?? []}
                  onError={onFormError}
                  submitOnBlur={shouldSubmitOnBlur}
                  mutationType={UPDATE}
                  surface="dark"
                >
                  <WrappedListItem
                    className={
                      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      COLLECTION_ITEM_STYLES[
                        layout || COLLECTION_ITEM_STYLES[ROWS]
                      ](isDarkModeEnabled)
                    }
                    is={layout === TABLE ? 'tr' : 'div'}
                    key={get(listItem, idPath)}
                    ref={ref}
                    index={index}
                    listItem={listItem}
                    loading={loading}
                    dataType={dataType}
                    elementId={element.id}
                    onClick={onClick}
                    edge={get(edges, [index])}
                    parentValuePath={parentValuePath}
                    isLast={index === edges.length - 1}
                    connectionProps={connection}
                    scope={scope}
                  >
                    <CollectItem
                      index={index}
                      additionalElementsRenderer={renderAdditionalElements(
                        listItem,
                        idPath,
                      )}
                      editorMode={editorMode}
                      element={element}
                      elementPath={elementPath}
                      project={project}
                    />
                  </WrappedListItem>
                </AutoFormProvider>
              ));

              if (layout === BOARD && groupBy) {
                const {
                  initialGroups = [],
                  key: keyPath,
                  label: labelPath,
                  sortBy,
                  renderLabel = identity,
                } = getGroupByDepPaths(
                  groupBy,
                  undefined,
                  ASC,
                  dataType,
                  project.dataTypes,
                );
                const key = keyPath.replace(/^edges\./, '');
                const labelPathFixed = labelPath.replace(/^edges\./, '');
                const groups = edges.reduce(
                  (groupsAcc: any, dataRow: any, index: any) => {
                    const groupByValue = get(dataRow, key);
                    const groupIndex = groupsAcc.findIndex(
                      (g: any) => g.key === groupByValue,
                    );
                    if (groupIndex < 0) {
                      return [
                        ...groupsAcc,
                        {
                          key: groupByValue,
                          rawValue: get(dataRow, labelPathFixed),
                          label: renderLabel(get(dataRow, labelPathFixed)),
                          rows: [{ ...dataRow, index }],
                        },
                      ];
                    }

                    return set(
                      [groupIndex, 'rows'],
                      [...groupsAcc[groupIndex].rows, { ...dataRow, index }],
                      groupsAcc,
                    );
                  },
                  initialGroups,
                );

                if (sortBy) {
                  groups.sort(sortBy);
                }

                return groups.map((group: any) => (
                  <div
                    key={group.key}
                    className="flex flex-col w-full max-w-xs flex-shrink-0 pb-6"
                  >
                    <div className="text-lg font-medium uppercase truncate text-gray-600 flex items-center px-2">
                      <span>{group.label || group.key || 'No value'}</span>
                      <Badge className="ml-3 rounded-full" variant="secondary">
                        {group.rows.length}
                      </Badge>
                    </div>
                    <div className="mt-4 space-y-2 flex flex-col">
                      {group.rows
                        .slice(
                          0,
                          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                          !groupOpenStates[group.key] && limitPerGroup
                            ? limitPerGroup
                            : undefined,
                        )
                        .map((edge: any) => (
                          // @ts-expect-error TS(2322): Type '{ listItem: any; index: any; }' is not assig... Remove this comment to see the full error message
                          <Row listItem={edge} index={edge.index} />
                        ))}
                      {limitPerGroup && group.rows.length > limitPerGroup && (
                        <button
                          className="mt-3 mb-6 bg-white p-2 text-sm rounded-lg text-center text-gray-600 font-medium uppercase tracking-wider hover:shadow-xs hover:text-gray-800 border"
                          onClick={toggleGroupState(group.key)}
                        >
                          {getText(
                            'core.COLLECTION.groups.limit',
                            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                            groupOpenStates[group.key] ? 'showLess' : 'showAll',
                          )}
                        </button>
                      )}
                      {group.rows.length === 0 && (
                        <div className="mb-6 bg-white p-2 text-sm rounded-lg text-center text-gray-600 font-medium uppercase tracking-wider hover:shadow-xs border">
                          {getText('core.COLLECTION.groups.empty')}
                        </div>
                      )}
                    </div>
                  </div>
                ));
              }

              const rows = edges.map((listItem: any, index: any) => (
                // @ts-expect-error TS(2322): Type '{ listItem: any; index: any; }' is not assig... Remove this comment to see the full error message
                <Row listItem={listItem} index={index} />
              ));

              if (layout === TABLE) {
                return (
                  <>
                    <table
                      className={`min-w-full divide-y ${
                        isDarkModeEnabled
                          ? darkModeColors.divides.one
                          : 'divide-gray-200'
                      } overflow-hidden`}
                    >
                      <CollectionTableHead
                        additionalElements={additionalElementsWithActions}
                        variables={variables}
                      />
                      <tbody className="bg-white divide-y divide-gray-200">
                        {rows /* not this one */}
                      </tbody>
                    </table>
                    {dataList.showPagination && pageInfo && (
                      <RowPagination
                        className={classNames(
                          PAGINATION_STYLES[ROWS](isDarkModeEnabled as boolean),
                        )}
                        pageInfo={pageInfo}
                        totalCount={totalCount}
                        setPaginationQuery={setPaginationQuery}
                        currentLength={edges.length}
                      />
                    )}
                  </>
                );
              }

              return (
                <>
                  {rows}
                  {dataList.showPagination && pageInfo && (
                    <RowPagination
                      className={PAGINATION_STYLES[layout](
                        isDarkModeEnabled as boolean,
                      )}
                      showSummary={layout === ROWS}
                      pageInfo={pageInfo}
                      totalCount={totalCount}
                      setPaginationQuery={setPaginationQuery}
                      currentLength={edges.length}
                    />
                  )}
                </>
              );
            }}
          </DataListWrapper>
        </Box>
        {(addNewButton || allowEditing) &&
          showFormModal &&
          formFields &&
          dataType && (
            <DataItemFormModal
              // @ts-expect-error TS(2322): Type '{ editorMode: any; formFields: any; id: any;... Remove this comment to see the full error message
              editorMode={editorMode}
              formFields={formFields}
              id={element.id}
              onAddDataItem={onAddDataItem}
              onClose={closeDataItemModal}
              dataType={dataType}
              itemId={selectedId}
              project={project}
            />
          )}
      </div>
    );
  },
);

Collection.defaultProps = {
  // @ts-expect-error TS(2322): Type '{ additionalElements: never[]; filters: neve... Remove this comment to see the full error message
  additionalElements: [],
  filters: [],
};

export default withTheme(Collection);
