import BaseArrayTypeMap from './BaseArrayTypeMap';

interface DataIdentifier {
  id: number;
  name: string;
  apiName: string;
}

export type DataTypeName = string;
export type DataFieldName = string;

export interface DataTypeIdentifier extends DataIdentifier {
  name: DataTypeName;
}
export interface DataFieldIdentifier extends DataIdentifier {
  name: DataFieldName;
}
class ProjectArrayTypeMap<T extends DataIdentifier> extends BaseArrayTypeMap<
  T
> {
  apiNameMap: Record<string, T>;
  nameMap: Record<string, T>;

  constructor(arrayOrLength?: Number | T[] | ProjectArrayTypeMap<T>) {
    super(arrayOrLength);

    if (arrayOrLength instanceof ProjectArrayTypeMap) {
      //Avoid re-calculating these maps if already populated
      this.apiNameMap = arrayOrLength.apiNameMap;
      this.nameMap = arrayOrLength.nameMap;
    } else {
      this.nameMap = {};
      this.apiNameMap = {};
    }
  }

  _mapEntry(entry: T) {
    super._mapEntry(entry);
    if (this.idMap) {
      this.idMap[entry.id] = entry;
      this.nameMap[entry.name] = entry;
      this.apiNameMap[entry.apiName] = entry;
    }
  }

  getByName(name: string) {
    if (!this.idMap) {
      this._mapEntries();
    }
    return this.nameMap ? this.nameMap[name] : undefined;
  }

  getByApiName(apiName: string) {
    if (!this.idMap) {
      this._mapEntries();
    }
    return this.apiNameMap ? this.apiNameMap[apiName] : undefined;
  }
}

export default ProjectArrayTypeMap;
