import React, { forwardRef, useCallback, useMemo } from 'react';
import reverse from 'lodash/fp/reverse';
import { BaseRecord, RecordEdge } from '../../../../models/Record';
import cappedMemoize from '../../../../utils/cappedMemoize';
import useCollectionGroups, {
  CollectionGroupsConfig,
} from '../../../../utils/hooks/useCollectionGroups';
import { Group } from '../../Collection';
import DraggableRecordLayout from './DraggableRecordLayout';
import DroppableGroupHeader from './DroppableGroupHeader';
import { TableLayoutProps } from './TableLayout';

type RecordLayoutByEdge = {
  className?: string;
  edge: RecordEdge;
  index: number;
  columnWidths: Record<number, number>;
  'data-index': number;
  'data-group-key'?: string;
  draggable: boolean;
  ref: React.Ref<HTMLTableRowElement>;
};

type GroupedVirtualizedLayoutProps = Omit<
  TableLayoutProps,
  'count' | 'fieldConfigs' | 'RecordLayoutByIndex' | 'isGroup'
> &
  CollectionGroupsConfig & {
    selectedRows: BaseRecord[];
    setSelectedRows: (
      setter: (currentValue: BaseRecord[]) => BaseRecord[],
    ) => void;
    dropIndicator: JSX.Element;
    RecordLayoutByEdge: React.FC<RecordLayoutByEdge>;
    VirtualizedLayout: React.FC<TableLayoutProps>;
  };

const flattenGroupsWithParentGroups = (
  groups: Group[],
  groupCollapsedStates: Record<string, boolean>,
  parentGroups: number[] = [],
  initialState: { group: Group | null; parentGroups: number[] }[] = [],
): { group: Group | null; parentGroups: number[] }[] =>
  groups.reduce((flatGroups, group) => {
    const index = flatGroups.length;
    flatGroups.push({ group: group, parentGroups });
    if (!groupCollapsedStates[group.id] && group.groups) {
      flattenGroupsWithParentGroups(
        group.groups,
        groupCollapsedStates,
        [...parentGroups, index],
        initialState,
      );
    } else if (group.rows && !groupCollapsedStates[group.id]) {
      flatGroups.push(
        ...Array(group.rows.length).fill({
          group: null,
          parentGroups: [...parentGroups, index],
        }),
      );
    }

    return flatGroups;
  }, initialState);

const getGroupByIndexesFromFlatGroups = (
  flatGroups: { group: Group | null }[],
) =>
  flatGroups.reduce((acc, { group }, index) => {
    if (group) {
      acc[index] = group;
    }
    return acc;
  }, {} as Record<number, Group>);

const GroupedVirtualizedLayout = ({
  className,
  customFilters,
  dataType,
  dropIndicator,
  edges,
  elementId,
  enableDragAndDropEdit,
  fields,
  handleSetOrderBy,
  groupByFields,
  groupOptions,
  hideEmptyGroups,
  layout,
  limitPerGroup,
  nodeQueryObject,
  maxStickyColumnIndex,
  orderBy,
  pageInfo,
  RecordLayoutByEdge,
  setPaginationQuery,
  showPagination,
  showTableSummary,
  totalCount,
  VirtualizedLayout,
  bulkActionsEnabled,
  selectedRows,
  setSelectedRows,
  allRowsSelected,
  setSelectAllRows,
}: GroupedVirtualizedLayoutProps) => {
  const {
    canUpdateViaDrag,
    groupCollapsedStates,
    toggleGroupCollapsedState,
    handleCardDrop,
    firstSummaryIndex,
    visibleGroups,
  } = useCollectionGroups({
    customFilters,
    dataType,
    edges,
    elementId,
    fields,
    groupByFields,
    groupOptions,
    hideEmptyGroups,
    layout,
    limitPerGroup,
    nodeQueryObject,
    enableDragAndDropEdit,
  });

  const flatGroups = useMemo(
    () => flattenGroupsWithParentGroups(visibleGroups, groupCollapsedStates),
    [groupCollapsedStates, visibleGroups],
  );

  const groupHeadersByIndex = useMemo(
    () => getGroupByIndexesFromFlatGroups(flatGroups),
    [flatGroups],
  );

  const GroupHeader = useMemo(
    () =>
      forwardRef<any, { group: Group; index: number }>(
        ({ group, index }, indexRef) => (
          <DroppableGroupHeader
            bulkActionsEnabled={bulkActionsEnabled}
            group={group}
            isCollapsed={!!groupCollapsedStates[group.id]}
            fields={fields}
            firstSummaryIndex={firstSummaryIndex}
            layout={layout}
            onDrop={handleCardDrop}
            index={index}
            toggleGroupCollapsedState={toggleGroupCollapsedState}
            data-index={index}
            dropIndicator={dropIndicator}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            ref={indexRef}
          />
        ),
      ),
    [
      bulkActionsEnabled,
      dropIndicator,
      fields,
      firstSummaryIndex,
      groupCollapsedStates,
      handleCardDrop,
      layout,
      selectedRows,
      setSelectedRows,
      toggleGroupCollapsedState,
    ],
  );

  const groupHeaderIndexes: number[] = useMemo(
    () =>
      Object.keys(groupHeadersByIndex)
        .map(parseFloat)
        .sort((a, b) => a - b),
    [groupHeadersByIndex],
  );

  const getGroupHeaderForEdgeIndex = useMemo(
    () =>
      cappedMemoize(
        (index: number) =>
          groupHeaderIndexes.length -
          reverse(groupHeaderIndexes).findIndex(
            (headerIndex) => index > headerIndex,
          ) -
          1,
        { maxKeys: 1000 },
      ),
    [groupHeaderIndexes],
  );

  const isGroup = useCallback((index: number) => !!flatGroups[index].group, [
    flatGroups,
  ]);

  const getStickyIndexesForIndex = useCallback(
    (index: number): number[] => flatGroups[index].parentGroups ?? [],
    [flatGroups],
  );

  const RecordLayoutOrGroupHeaderByIndex = useMemo(
    () =>
      forwardRef<
        HTMLTableRowElement,
        {
          index: number;
          columnWidths: Record<number, number>;
        }
      >(({ index, columnWidths }, indexRef) => {
        if (groupHeadersByIndex[index]) {
          const group = groupHeadersByIndex[index];
          return <GroupHeader group={group} index={index} />;
        }

        const groupIndex = getGroupHeaderForEdgeIndex(index);

        const groupHeaderIndex = groupHeaderIndexes[groupIndex];

        const group = groupHeadersByIndex[groupHeaderIndex];
        const indexInGroup = index - groupHeaderIndex - 1;
        const edge = group.rows && group.rows[indexInGroup];

        if (edge) {
          return (
            <DraggableRecordLayout
              index={edge.index}
              edge={edge}
              data-index={index}
              ref={indexRef}
              draggable={canUpdateViaDrag}
              group={group}
              onDrop={handleCardDrop}
              record={edge.node}
              dropIndicator={dropIndicator}
              RecordLayoutByEdge={RecordLayoutByEdge}
              columnWidths={columnWidths}
            />
          );
        }

        return null;
      }),
    [
      GroupHeader,
      RecordLayoutByEdge,
      canUpdateViaDrag,
      dropIndicator,
      getGroupHeaderForEdgeIndex,
      groupHeaderIndexes,
      groupHeadersByIndex,
      handleCardDrop,
    ],
  );

  return (
    <VirtualizedLayout
      className={className}
      count={flatGroups.length}
      edges={edges}
      fieldConfigs={fields}
      handleSetOrderBy={handleSetOrderBy}
      layout={layout}
      orderBy={orderBy}
      maxStickyColumnIndex={maxStickyColumnIndex}
      pageInfo={pageInfo}
      RecordLayoutByIndex={RecordLayoutOrGroupHeaderByIndex}
      setPaginationQuery={setPaginationQuery}
      showPagination={showPagination}
      showTableSummary={showTableSummary}
      totalCount={totalCount}
      isGroup={isGroup}
      getStickyIndexesForIndex={getStickyIndexesForIndex}
      bulkActionsEnabled={bulkActionsEnabled}
      allRowsSelected={allRowsSelected}
      setSelectAllRows={setSelectAllRows}
    />
  );
};

export default GroupedVirtualizedLayout;
