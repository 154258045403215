import React, { useMemo, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { Tooltip, getColorShade } from '@noloco/components';
import { SINGLE_OPTION } from '@noloco/core/src/constants/dataTypes';
import { getText } from '@noloco/core/src/utils/lang';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { darkModeColors } from '../../../constants/darkModeColors';
import { STAGES } from '../../../constants/elements';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import { getFieldFromDependency } from '../../../utils/fields';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { getVisibleOptions } from '../../../utils/hooks/useFieldVisibilityConditions';
import useMergedScope from '../../../utils/hooks/useMergedScope';
import useScopeUser from '../../../utils/hooks/useScopeUser';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { fieldPermissions } from '../../../utils/permissions';
import SelectableStage from './SelectableStage';

const RecordStages = ({
  dataType,
  elementPath,
  editorMode,
  optionsConfig,
  project,
  record,
  recordScope,
  sectionId,
  stages: stagesDep,
  useColors,
  disableEditing,
  theme,
}: any) => {
  const user = useScopeUser();
  const mergedScope = useMergedScope(recordScope);
  const { stages } = useSectionScopeVariables(
    STAGES,
    { stages: stagesDep },
    project,
    elementPath,
    recordScope,
  );

  const [localValue, setLocalValue] = useState(undefined);

  const draftValue = useMemo(() => localValue || stages, [localValue, stages]);

  const primaryColor = theme.brandColors.primary;

  const [isDarkModeEnabled] = useDarkMode();

  const stagesField = useMemo(
    () =>
      stagesDep &&
      getFieldFromDependency(
        stagesDep.path.split('.'),
        dataType,
        project.dataTypes,
      ),
    [dataType, project.dataTypes, stagesDep],
  );

  const isValidField = useMemo(
    () => get(stagesField, 'type') === SINGLE_OPTION,
    [stagesField],
  );

  const sortedOptions = useMemo(
    () =>
      isValidField && stagesField
        ? // @ts-expect-error TS(2554): Expected 5 arguments, but got 4.
          getVisibleOptions(
            sortBy(stagesField.options, 'order'),
            { optionsConfig },
            mergedScope,
            project,
          )
        : [],
    [isValidField, optionsConfig, project, mergedScope, stagesField],
  );

  const activeStageIndex = useMemo(() => {
    if (!isValidField || !draftValue) {
      return -1;
    }

    return sortedOptions.findIndex((option: any) => option.name === draftValue);
  }, [draftValue, isValidField, sortedOptions]);

  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);
  const canEdit = useMemo(() => {
    if (
      !isValidField ||
      stagesDep.path.split('.').length > 1 ||
      stagesField.readOnly
    ) {
      return false;
    }

    const permissions = fieldPermissions(
      stagesField,
      fieldPermissionsEnabled && dataType.permissionsEnabled,
      dataType.permissions,
      user,
    );

    return permissions.update;
  }, [
    dataType.permissions,
    dataType.permissionsEnabled,
    fieldPermissionsEnabled,
    isValidField,
    stagesDep,
    stagesField,
    user,
  ]);

  if (!isValidField) {
    if (editorMode) {
      return (
        <div
          className={`border rounded-lg shadow-md flex items-center justify-center p-8 ${
            isDarkModeEnabled
              ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
              : 'bg-white  border-gray-200'
          }`}
        >
          <div
            className={`text-center w-full py-8 ${
              isDarkModeEnabled
                ? darkModeColors.text.secondary
                : 'text-gray-600'
            }`}
          >
            {getText('elements.DETAILS.view.empty')}
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <div
      className={`w-full flex items-center justify-between border rounded-lg overflow-y-auto max-w-full ${
        isDarkModeEnabled
          ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.two}`
          : 'bg-white border-gray-300'
      }`}
      data-testid="stages-section"
    >
      {sortedOptions.map((option: any, index: any) => {
        const isComplete = index < activeStageIndex;
        const isActive = index === activeStageIndex;
        const isIncomplete = index > activeStageIndex;
        const collapsed =
          sortedOptions.length > 4 && index < activeStageIndex - 1;

        const StageComponent = canEdit && !isActive ? SelectableStage : 'div';

        const useOptionColor = useColors && option.color;
        const stageColor = useOptionColor || primaryColor;
        const bgShade = useOptionColor ? 300 : 500;

        return (
          <StageComponent
            className={classNames(
              'flex items-center pl-6 pr-10 py-3 whitespace-nowrap text-sm relative group',
              {
                'cursor-pointer':
                  !disableEditing && (isComplete || isIncomplete),
                'text-gray-800': isComplete && !isDarkModeEnabled,
                [darkModeColors.text.secondary]:
                  isComplete && isDarkModeEnabled,
                [`text-${getColorShade(stageColor, 500)}`]: isActive,
                'text-gray-500 border-gray-500':
                  isIncomplete && !isDarkModeEnabled,
                [`${darkModeColors.text.secondary} ${darkModeColors.borders.two}`]:
                  isIncomplete && isDarkModeEnabled,
                'w-full': !collapsed,
              },
              `stage-option-${option.name}-${sectionId}`,
            )}
            disableEditing={disableEditing}
            dataType={dataType}
            // @ts-expect-error TS(2322): Type 'Dispatch<SetStateAction<undefined>>' is not ... Remove this comment to see the full error message
            onChange={setLocalValue}
            project={project}
            recordId={record.id}
            stagesField={stagesField}
            value={option.name}
            key={option.name}
          >
            <Tooltip
              disabled={!collapsed}
              content={
                <span
                  className={`${
                    isDarkModeEnabled ? darkModeColors.text.primary : ''
                  }`}
                >
                  {option.display}
                </span>
              }
              bg="white"
            >
              <div
                className={classNames(
                  'w-7 h-7 rounded-full border-2 flex-shrink-0 items-center justify-center flex text-xs',
                  `${isDarkModeEnabled ? darkModeColors.text.primary : ''}`,
                  {
                    [`border-${getColorShade(stageColor, bgShade)}`]:
                      isComplete || isActive,
                    [`${
                      isDarkModeEnabled
                        ? darkModeColors.surfaces.elevation2
                        : 'bg-white'
                    } group-hover:bg-${getColorShade(
                      stageColor,
                      bgShade + 100,
                    )}`]: !disableEditing && isComplete,
                    [`bg-${getColorShade(stageColor, bgShade)}`]: isActive,
                    'group-hover:bg-gray-500': isIncomplete && !useOptionColor,
                    [`group-hover:border-${getColorShade(
                      stageColor,
                      bgShade - 200,
                    )} group-hover:bg-${getColorShade(
                      stageColor,
                      bgShade - 100,
                    )}`]: !disableEditing && isIncomplete && useOptionColor,
                  },
                )}
              >
                {isComplete ? (
                  <IconCheck
                    className={classNames(
                      `text-${getColorShade(stageColor, bgShade)}`,
                      { [`group-hover:text-white`]: !disableEditing },
                    )}
                    size={16}
                  />
                ) : (
                  <>
                    <span
                      className={classNames({
                        'group-hover:hidden': !disableEditing && isIncomplete,
                        'text-white': isActive,
                      })}
                    >
                      {index + 1}
                    </span>
                    <IconCheck
                      className={classNames('text-white hidden', {
                        'group-hover:block': !disableEditing && isIncomplete,
                        [`group-hover:text-${getColorShade(stageColor, 700)}`]:
                          !disableEditing && isIncomplete && useOptionColor,
                      })}
                      size={16}
                    />
                  </>
                )}
              </div>
            </Tooltip>
            {!collapsed && <span className="ml-3">{option.display}</span>}
            {index < sortedOptions.length - 1 && (
              <div
                className="absolute top-0 right-0 h-full w-5"
                aria-hidden="true"
              >
                <svg
                  className={`h-full w-full ${
                    isDarkModeEnabled
                      ? darkModeColors.text.tertiary
                      : 'text-gray-300'
                  }`}
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </StageComponent>
        );
      })}
    </div>
  );
};

RecordStages.defaultProps = {};

export default withTheme(RecordStages);
