import React, { useCallback, useState } from 'react';
import {
  IconFilePencil,
  IconMail,
  IconPlayerPlayFilled,
  IconWebhook,
  TablerIconsProps,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { Loader } from '@noloco/components';
import { Project } from '@noloco/core/src/models/Project';
import { WEBSITE_EXAMPLES, trackEvent } from '@noloco/core/src/utils/analytics';
import useSetBaseScope from '@noloco/core/src/utils/hooks/useSetBaseScope';
import { getText } from '@noloco/core/src/utils/lang';
import BaseExample from '../BaseExample';

const LANG_KEY = 'websiteExamples.workflows';

const PulsingRing = () => (
  <div className="absolute top-0 left-0 right-0 bottom-0 ring-2 ring-opacity-75 ring-pink-500 animate-pulse rounded-lg" />
);

type ExampleActionProps = {
  active: boolean;
  complete: boolean;
  Icon: (props: TablerIconsProps) => JSX.Element;
  label: string;
  description: string;
};

const ExampleAction = ({
  active,
  complete,
  Icon,
  label,
  description,
}: ExampleActionProps) => {
  return (
    <div
      className={classNames(
        'rounded-lg border shadow w-full h-20 flex items-center bg-white relative',
        { 'ring-2 ring-pink-500': complete },
      )}
    >
      {active && !complete && <PulsingRing />}
      <div className="flex items-center justify-center w-20 h-20">
        {active ? (
          <Loader size="sm" />
        ) : (
          <Icon className="flex-shrink-0" size={24} />
        )}
      </div>
      <div className="flex flex-col ml-2 px-4 py-2">
        <span className="text-xs uppercase text-gray-400">{label}</span>
        <span className="text-sm text-gray-800">{description}</span>
      </div>
    </div>
  );
};

const ActionDivider = ({ complete }: { complete: boolean }) => (
  <div className="w-20 h-8 flex items-center justify-center">
    <div
      className={classNames('w-0.5 h-full bg-gray-400', {
        'bg-gray-400': !complete,
        'bg-pink-500': complete,
      })}
    />
  </div>
);

const WorkflowsExample = ({ project }: { project: Project }) => {
  useSetBaseScope(project.dataTypes);

  const [isLoading, setIsLoading] = useState(false);
  const [workflowAction, setWorkflowAction] = useState<number | null>(null);

  const nextAction = useCallback(() => {
    setIsLoading(false);
    let actionNumber: number | null = null;
    setWorkflowAction((currentAction) => {
      actionNumber = currentAction;
      return currentAction !== null ? currentAction + 1 : 0;
    });
    setTimeout(() => {
      setIsLoading(true);
      setTimeout(() => {
        if (actionNumber === null || actionNumber < 3) {
          nextAction();
        } else {
          setWorkflowAction(null);
          setIsLoading(false);
        }
      }, 1400);
    }, 500);
  }, []);

  const startWorkflow = useCallback(() => {
    setIsLoading(true);
    trackEvent(WEBSITE_EXAMPLES.WORKFLOW_STARTED);
    setTimeout(() => {
      nextAction();
    }, 800);
  }, [nextAction]);

  return (
    <BaseExample showGradient={false}>
      <div className="flex md:flex-col items-center overflow-hidden">
        <div className="flex flex-col justify-center items-center mb-8 flex-shrink-0 w-1/2 md:w-full space-y-3 px-4">
          <div className="flex flex-col ">
            <h2 className="text-3xl font-medium mb-3">
              {getText(LANG_KEY, 'title')}
            </h2>
            <p className="text-base mb-8">{getText(LANG_KEY, 'subtitle')}</p>
          </div>
        </div>
        <div className="p-6 top-0 z-30 flex flex-col w-full">
          <div className="flex items-center">
            <button
              className={classNames(
                'rounded-lg border shadow w-20 h-20 flex items-center justify-center bg-white relative ring-opacity-75 ring-pink-500',
                {
                  'border-pink-500 ring-2 ': !!workflowAction || isLoading,
                  'hover:ring-2': !workflowAction,
                },
              )}
              disabled={isLoading || !!workflowAction}
              onClick={startWorkflow}
            >
              {workflowAction === null && !isLoading && <PulsingRing />}
              {workflowAction === null && isLoading ? (
                <Loader size="md" />
              ) : (
                <IconPlayerPlayFilled className="flex-shrink-0" size={24} />
              )}
            </button>
            <div className="ml-6 flex flex-col">
              <span className="text-xs uppercase text-gray-400">
                {getText(LANG_KEY, 'trigger.label')}
              </span>
              <span className="text-sm text-gray-800">
                {getText(LANG_KEY, 'trigger.type')}
              </span>
            </div>
          </div>
          <ActionDivider
            complete={workflowAction !== null && workflowAction >= 0}
          />
          <ExampleAction
            active={workflowAction === 0 && isLoading}
            complete={workflowAction !== null && workflowAction > 0}
            Icon={IconMail}
            label={getText(LANG_KEY, 'email.label')}
            description={getText(LANG_KEY, 'email.description')}
          />
          <ActionDivider complete={!!workflowAction && workflowAction >= 1} />
          <ExampleAction
            active={workflowAction === 1 && isLoading}
            complete={!!workflowAction && workflowAction > 1}
            Icon={IconFilePencil}
            label={getText(LANG_KEY, 'update.label')}
            description={getText(LANG_KEY, 'update.description')}
          />
          <ActionDivider complete={!!workflowAction && workflowAction >= 2} />
          <ExampleAction
            active={workflowAction === 2 && isLoading}
            complete={!!workflowAction && workflowAction > 2}
            Icon={IconWebhook}
            label={getText(LANG_KEY, 'webhook.label')}
            description={getText(LANG_KEY, 'webhook.description')}
          />
        </div>
      </div>
    </BaseExample>
  );
};

export default WorkflowsExample;
