import React, { useCallback, useMemo, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import {
  IconBuildingStore,
  IconCheck,
  IconChevronRight,
  IconLink,
  IconTrash,
  IconUser,
  IconX,
} from '@tabler/icons-react';
import classNames from 'classnames';
import {
  AutoSizedTextInput,
  Dropdown,
  TextInput,
  Tooltip,
  getColorShade,
} from '@noloco/components';
import { getText } from '../../utils/lang';

type Props = {};

const OnobardingTask = ({
  // @ts-expect-error TS(2339): Property 'editing' does not exist on type 'Props'.
  editing,
  // @ts-expect-error TS(2339): Property 'onCheck' does not exist on type 'Props'.
  onCheck,
  // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Props'.
  onClick,
  // @ts-expect-error TS(2339): Property 'onDelete' does not exist on type 'Props'... Remove this comment to see the full error message
  onDelete,
  // @ts-expect-error TS(2339): Property 'onUpdate' does not exist on type 'Props'... Remove this comment to see the full error message
  onUpdate,
  // @ts-expect-error TS(2339): Property 'task' does not exist on type 'Props'.
  task,
  // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Props'.
  theme,
}: Props) => {
  const secondaryColor = theme.brandColorGroups.secondary;
  const successColor = theme.brandColorGroups.success;
  const [showLinkEditor, setShowLinkEditor] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const onUpdateTask = useCallback(
    (path: any) => ({ target: { value } }: any) => {
      onUpdate(path, value);
    },
    [onUpdate],
  );

  const toggleConfirmDelete = (e: any) => {
    e.stopPropagation();
    setShowConfirmDelete((currentConfirm) => !currentConfirm);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      setShowLinkEditor(false);
    }
  };

  const onConfirmDelete = (e: any) => {
    e.stopPropagation();
    setShowConfirmDelete(false);
    onDelete();
  };

  const onToggleLinkEditor = (e: any) => {
    e.stopPropagation();
    setShowLinkEditor(!showLinkEditor);
  };

  const handleClick = (e: any) => {
    setShowLinkEditor(false);
    if (onClick) {
      onClick(e);
    }
  };

  const typeOptions = useMemo(
    () =>
      ['company', 'user'].map((type) => ({
        value: type,
        label: getText('core.ONBOARDING_TASKS.type', type, 'label'),
        help: getText('core.ONBOARDING_TASKS.type', type, 'help'),
        Icon:
          type === 'user' ? (
            <IconUser className="opacity-75" size={16} />
          ) : (
            <IconBuildingStore className="opacity-75" size={16} />
          ),
      })),
    [],
  );

  return (
    <div
      className={classNames(
        'flex items-start group p-3 border border-gray-100 rounded-lg relative',
      )}
      onClick={handleClick}
    >
      <div
        onClick={onCheck}
        className={classNames(
          'w-12 h-12 flex mt-2 justify-center items-center flex-shrink-0 rounded-full',
          {
            [`group-hover:bg-${getColorShade(
              secondaryColor,
              200,
            )} group-hover:text-${getColorShade(
              secondaryColor,
              800,
            )}`]: !task.completed,
            'bg-gray-200 text-gray-400': !task.completed,
            [`bg-${getColorShade(successColor, 200)} text-${getColorShade(
              successColor,
              800,
            )}`]: task.completed,
          },
        )}
      >
        <IconCheck size={16} />
      </div>
      <div
        className={classNames(
          'ml-4 flex flex-grow justify-center flex-col overflow-hidden max-w-full',
          {
            'line-through opacity-50': task.completed,
          },
        )}
      >
        <h2 className="text-lg font-medium text-gray-800">
          {editing ? (
            <AutoSizedTextInput
              value={task.title}
              onChange={onUpdateTask('title')}
              placeholder={getText(
                'core.ONBOARDING_TASKS.edit.placeholder.title',
              )}
              text="text-gray-800"
            />
          ) : (
            task.title
          )}
        </h2>
        {(task.description || editing) && (
          <p className="text-sm mt-px text-gray-500 whitespace-pre-wrap">
            {editing ? (
              <AutoSizedTextInput
                text="text-gray-500"
                type="textarea"
                value={task.description}
                onChange={onUpdateTask('description')}
                placeholder={getText(
                  'core.ONBOARDING_TASKS.edit.placeholder.description',
                )}
              />
            ) : (
              task.description
            )}
          </p>
        )}
      </div>
      <div className="flex justify-center items-center flex-shrink-0 my-auto mx-4 text-gray-400">
        <Dropdown
          className={classNames('inline-flex relative')}
          Button={({ children }: any) => <span>{children}</span>}
          disabled={!editing}
          onChange={(newType: any) => onUpdate('type', newType)}
          placeholder=""
          direction="bottom"
          options={typeOptions}
          w={48}
          minW={48}
        >
          <Tooltip
            content={getText(
              'core.ONBOARDING_TASKS.type',
              task.type,
              'tooltip',
            )}
            disabled={editing}
          >
            <span>
              {task.type === 'user' ? (
                <IconUser size={22} />
              ) : (
                <IconBuildingStore size={22} />
              )}
            </span>
          </Tooltip>
        </Dropdown>
      </div>
      <div
        className={classNames(
          'flex justify-center invisible items-center flex-shrink-0 my-auto mr-4 gray-400',
          { 'group-hover:visible': !task.completed },
        )}
      >
        <IconChevronRight size={22} />
      </div>
      {editing && (
        <div
          className={classNames(
            'flex items-center absolute py-1 px-2 space-x-2 top-0 right-0 -mt-5 -mr-4 bg-gray-600 text-gray-200 rounded-lg group-hover:visible',
            { invisible: !showLinkEditor },
          )}
        >
          {showConfirmDelete && !showLinkEditor && (
            <span className="text-gray-100">
              {getText('core.ONBOARDING_TASKS.confirmDelete')}
            </span>
          )}
          {!showConfirmDelete && (
            <button
              onClick={onToggleLinkEditor}
              className="p-1 hover:text-white"
            >
              <IconLink size={14} />
            </button>
          )}
          {showLinkEditor && !showConfirmDelete && (
            <TextInput
              onClick={(e: any) => e.stopPropagation()}
              value={task.url}
              onChange={onUpdateTask('url')}
              onKeyDown={handleKeyPress}
              placeholder="https://mysite.com"
              p={{ x: 2, y: 1 }}
              bg="gray-500"
              border={0}
              text={['sm', 'gray-100']}
            />
          )}
          {!showConfirmDelete && showLinkEditor && (
            <button
              onClick={onToggleLinkEditor}
              className="p-1 hover:text-white text-green-200"
            >
              <IconCheck size={14} />
            </button>
          )}
          {!showConfirmDelete && !showLinkEditor && (
            <button
              onClick={toggleConfirmDelete}
              className="p-1 hover:text-white"
            >
              <IconTrash size={14} />
            </button>
          )}
          {showConfirmDelete && (
            <>
              <button
                onClick={onConfirmDelete}
                className="p-1 hover:text-white text-red-200"
              >
                <IconTrash size={14} />
              </button>

              <button
                onClick={toggleConfirmDelete}
                className="p-1 hover:text-white "
              >
                <IconX size={14} />
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default withTheme(OnobardingTask);
