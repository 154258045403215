import set from 'lodash/fp/set';
import {
  DECIMAL,
  INTEGER,
  SINGLE_OPTION,
} from '@noloco/core/src/constants/dataTypes';
import { FILE, MEMBERSHIP } from '../constants/builtInDataTypes';
import { MULTIPLE_OPTION, TEXT } from '../constants/dataTypes';
import {
  MANY_TO_ONE,
  ONE_TO_MANY,
  ONE_TO_ONE,
} from '../constants/relationships';
import { DataField } from '../models/DataTypeFields';
import DataTypes, { DataType } from '../models/DataTypes';
import { findPreviewFields } from '../utils/dataTypes';
import { isDefaultField } from '../utils/defaultFields';
import { getPrimaryField, rollupToFakeField } from '../utils/fields';
import { getFieldReverseApiName } from '../utils/fields';
import { isMultiRelationship } from '../utils/relationships';

export type QueryObject = {
  [key: string]: boolean | QueryObject;
};

export const BASE_CONNECTION: QueryObject = {
  edges: {
    node: {},
  },
};

export const FULL_CONNECTION: QueryObject = {
  totalCount: true,
  ...BASE_CONNECTION,
  pageInfo: {
    startCursor: true,
    endCursor: true,
    hasNextPage: true,
    hasPreviousPage: true,
  },
};

export const BASE_QUERY_OBJECT = {
  id: true,
  uuid: true,
};

export const FILE_QUERY_OBJECT = {
  ...BASE_QUERY_OBJECT,
  fileType: true,
  mimetype: true,
  url: true,
  name: true,
};

export const MEMBERSHIP_QUERY_OBJECT = {
  ...BASE_QUERY_OBJECT,
  user: {
    id: true,
    uuid: true,
  },
  subscriptionId: true,
  customerId: true,
  plan: {
    ...BASE_QUERY_OBJECT,
    name: true,
    amount: true,
    currency: true,
    interval: true,
    status: true,
  },
};

const PREVIEW_TYPES = [TEXT, SINGLE_OPTION, DECIMAL, INTEGER, MULTIPLE_OPTION];

export const getPreviewableFieldsQueryObject = (
  relatedTypeFields: DataField[],
): Record<string, any> =>
  relatedTypeFields.reduce((queryObject, field) => {
    if (PREVIEW_TYPES.includes(field.type)) {
      if (field.type === SINGLE_OPTION && field.options?.length === 0) {
        return queryObject;
      }

      return {
        ...queryObject,
        [field.apiName]: true,
      };
    }

    if (field.type === FILE) {
      if (!isMultiRelationship(field.relationship)) {
        return {
          ...queryObject,
          [field.apiName]: FILE_QUERY_OBJECT,
        };
      }

      return {
        ...queryObject,
        [field.apiName]: set(
          ['edges', 'node'],
          FILE_QUERY_OBJECT,
          BASE_CONNECTION,
        ),
      };
    }

    return queryObject;
  }, BASE_QUERY_OBJECT);

const getRelatedTypeFields = (
  relatedType: DataType,
  includeNestedFields: boolean,
): DataField[] => {
  if (includeNestedFields) {
    return relatedType.fields;
  }

  const primaryField = getPrimaryField(relatedType);
  const { imageField, secondaryField, textFields } = findPreviewFields(
    relatedType.fields,
    relatedType,
  );
  const nestedFieldIdsToInclude = [
    imageField?.id,
    primaryField?.id,
    secondaryField?.id,
    ...textFields.map(({ id }) => id),
  ].filter(Boolean);

  return relatedType.fields.filter(
    (nestedField) =>
      isDefaultField(nestedField) ||
      nestedFieldIdsToInclude.includes(nestedField.id),
  );
};

export const reduceFieldsToQueryObject = (
  dataTypes: DataTypes,
  options: {
    includeCollections: boolean;
    includeNestedFields: boolean;
    includeHidden: boolean;
  },
) => (queryAcc: Record<any, any>, field: DataField): QueryObject => {
  const { relatedField, relationship, type } = field;
  if (!options.includeHidden && field.hidden) {
    return queryAcc;
  }

  if (type === FILE) {
    if ([ONE_TO_ONE, MANY_TO_ONE].includes(field.relationship as string)) {
      return set([field.apiName], FILE_QUERY_OBJECT, queryAcc);
    }

    return set(
      [field.apiName],
      set(['edges', 'node'], FILE_QUERY_OBJECT, BASE_CONNECTION),
      queryAcc,
    );
  }

  if (relationship) {
    const relatedType = dataTypes.getByName(field.type);
    if (!relatedType || relatedType.internal) {
      return queryAcc;
    }

    if (type === MEMBERSHIP) {
      if ([ONE_TO_ONE, MANY_TO_ONE].includes(field.relationship as string)) {
        return set([field.apiName], MEMBERSHIP_QUERY_OBJECT, queryAcc);
      }

      return set(
        [field.apiName],
        set(['edges', 'node'], MEMBERSHIP_QUERY_OBJECT, BASE_CONNECTION),
        queryAcc,
      );
    }

    const isSingleRelationship = [ONE_TO_ONE, MANY_TO_ONE].includes(
      relationship,
    );

    if (!isSingleRelationship && !options.includeCollections) {
      return queryAcc;
    }

    const nestedFields = getRelatedTypeFields(
      relatedType,
      options.includeNestedFields,
    );
    const fieldsToFetch = isSingleRelationship
      ? getPreviewableFieldsQueryObject(nestedFields)
      : set(
          ['edges', 'node'],
          getPreviewableFieldsQueryObject(nestedFields),
          BASE_CONNECTION,
        );

    return set([field.apiName], fieldsToFetch, queryAcc);
  }

  if (relatedField) {
    const relatedType = dataTypes.getByName(field.type);

    if (!relatedType) {
      return queryAcc;
    }

    const nestedFields = getRelatedTypeFields(
      relatedType,
      options.includeNestedFields,
    );

    if (
      relatedField.relationship === ONE_TO_MANY ||
      relatedField.relationship === ONE_TO_ONE
    ) {
      const reverseApiFieldKey = getFieldReverseApiName(relatedField, {
        apiName: field.type,
      } as DataType);
      return set(
        [reverseApiFieldKey as string],
        getPreviewableFieldsQueryObject(nestedFields),
        queryAcc,
      );
    }

    if (!options.includeCollections) {
      return queryAcc;
    }

    return set(
      [field.apiName],
      set(
        ['edges', 'node'],
        getPreviewableFieldsQueryObject(nestedFields),
        BASE_CONNECTION,
      ),
      queryAcc,
    );
  }

  if (
    (field.type === SINGLE_OPTION || field.type === MULTIPLE_OPTION) &&
    field.options?.length === 0
  ) {
    return queryAcc;
  }

  return set([field.apiName], true, queryAcc);
};

export const getDataTypeQueryObject = (
  dataTypeWithRelations: DataType,
  dataTypes: DataTypes,
  options: {
    includeCollections: boolean;
    includeNestedFields: boolean;
    includeHidden: boolean;
  },
): QueryObject => ({
  ...dataTypeWithRelations.fields.reduce(
    reduceFieldsToQueryObject(dataTypes, options),
    {},
  ),
  ...dataTypeWithRelations.rollups
    .map((rollup) =>
      rollupToFakeField(rollup, dataTypeWithRelations, dataTypes),
    )
    .reduce(
      reduceFieldsToQueryObject(dataTypes, options),
      {} as Record<any, any>,
    ),
});

export const getDataTypeCollectionQueryObject = (
  dataTypeWithRelations: DataType,
  dataTypes: DataTypes,
  includeNestedFields = true,
): QueryObject =>
  set(
    ['edges', 'node'],
    getDataTypeQueryObject(dataTypeWithRelations, dataTypes, {
      includeCollections: true,
      includeNestedFields,
      includeHidden: false,
    }),
    FULL_CONNECTION,
  );
