import get from 'lodash/get';
import initial from 'lodash/initial';
import upperFirst from 'lodash/upperFirst';
import ArrayType from '../../models/elementPropTypes/ArrayPropType';
import BoolType from '../../models/elementPropTypes/BoolPropType';
import DataFieldPropType from '../../models/elementPropTypes/DataFieldPropType';
import EndpointParamPropType from '../../models/elementPropTypes/EndpointParamPropType';
import PropGroup from '../../models/elementPropTypes/PropGroup';
import RawDataPropType from '../../models/elementPropTypes/RawDataPropPropType';
import StringType from '../../models/elementPropTypes/StringPropType';
import { isDefaultField } from '../../utils/defaultFields';
import { isMultiRelationship } from '../../utils/relationships';

const defaultGetDataTypeFn = (element: any) => get(element, 'props.dataType');
const defaultGetApiEndpointFn = (element: any) =>
  get(element, 'props.endpoint');

const commonFormFields = {
  label: new StringType().setDisplay((props = {}, __: any, propPath: any) => {
    // @ts-expect-error TS(2769): No overload matches this call.
    const hidden = get(props, [...initial(propPath), 'hidden']);
    return !hidden;
  }),
  helpText: new StringType()
    .setDisplay((props = {}, __: any, propPath: any) => {
      // @ts-expect-error TS(2769): No overload matches this call.
      const hidden = get(props, [...initial(propPath), 'hidden']);
      return !hidden;
    })
    .setMultiLine(true),
  placeholder: new StringType().setDisplay(
    (props = {}, __: any, propPath: any) => {
      // @ts-expect-error TS(2769): No overload matches this call.
      const hidden = get(props, [...initial(propPath), 'hidden']);
      return !hidden;
    },
  ),
  required: new BoolType().setDisplay((props = {}, __: any, propPath: any) => {
    // @ts-expect-error TS(2769): No overload matches this call.
    const hidden = get(props, [...initial(propPath), 'hidden']);
    return !hidden;
  }),
};

// @ts-expect-error TS(7023): 'getFieldsPropDefinition' implicitly has return ty... Remove this comment to see the full error message
const getFieldsPropDefinition = (
  getDataType = defaultGetDataTypeFn,
  allowSubCreation = true,
) =>
  new ArrayType({
    // @ts-expect-error TS(2554): Expected 0-2 arguments, but got 3.
    field: new DataFieldPropType(getDataType, undefined, 'edges.node'),
    hidden: new BoolType(),
    value: new StringType()
      .setDisplay((props = {}, __: any, propPath: any) => {
        // @ts-expect-error TS(2769): No overload matches this call.
        const hidden = get(props, [...initial(propPath), 'hidden']);
        return !!hidden;
      })
      .setUseRawValue(true)
      // @ts-expect-error TS(2345): Argument of type '(element: any, project: any, pro... Remove this comment to see the full error message
      .setGetDataTypes((element: any, project: any, propPath: any) => {
        const dataTypeName = getDataType(element);
        const dataType = project.dataTypes.getByName(dataTypeName);

        if (!dataType) {
          return undefined;
        }

        // @ts-expect-error TS(2769): No overload matches this call.
        const formField = get(element.props, initial(propPath));
        const field = dataType.fields.getByName(formField.field);

        return field && field.relationship ? [field.type] : undefined;
      }),
    ...commonFormFields,
    collectionFilter: new RawDataPropType(
      // @ts-expect-error TS(2345): Argument of type '(element: any, project: any, pro... Remove this comment to see the full error message
      (element: any, project: any, propPath: any) => {
        const dataType = getDataType(element);
        const fieldName = get(element.props, [
          ...propPath.slice(0, -1),
          'field',
        ]);
        if (!dataType || !fieldName) {
          return [];
        }
        const dt = project.dataTypes.getByName(dataType);
        if (!dt) {
          return false;
        }
        const field = dt.fields.getByName(fieldName);
        return field ? [field.type] : [];
      },
    )
      .setShowCollections(true)
      .setDisplay((props = {}, __: any, propPath: any, project: any) => {
        const dataType = getDataType({ props });
        const fieldName = get(props, [...propPath.slice(0, -1), 'field']);
        if (!dataType || !fieldName) {
          return false;
        }
        const dt = project.dataTypes.getByName(dataType);
        if (!dt) {
          return false;
        }
        const field = dt.fields.getByName(fieldName);
        return field.relationship;
      }),
    allowNewRecords: new BoolType().setDisplay(
      (props = {}, __: any, propPath: any, project: any) => {
        if (!allowSubCreation) {
          return false;
        }
        const dataType = getDataType({ props });
        // @ts-expect-error TS(2769): No overload matches this call.
        const fieldName = get(props, [...initial(propPath), 'field']);
        // @ts-expect-error TS(2769): No overload matches this call.
        const hidden = get(props, [...initial(propPath), 'hidden']);
        if (!dataType || !fieldName || !!hidden) {
          return false;
        }
        const dt = project.dataTypes.getByName(dataType);
        if (!dt) {
          return false;
        }

        const field = dt.fields.getByName(fieldName);
        return field.relationship && !isMultiRelationship(field.relationship);
      },
    ),
  }).setDynamicPropShape((props = {}, itemValue, __, project) => {
    if (!allowSubCreation) {
      return {};
    }
    const dataType = getDataType({ props });
    const fieldName = get(itemValue, 'field');
    const hidden = get(itemValue, 'hidden');
    const allowNewRecords = get(itemValue, 'allowNewRecords');
    if (!dataType || !fieldName || !!hidden || !allowNewRecords) {
      return {};
    }
    const dt = project.dataTypes.getByName(dataType);
    if (!dt) {
      return {};
    }

    const field = dt.fields.getByName(fieldName);
    return {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      newRecordFields: getFieldsPropDefinition(() => field.type, false),
    };
  });

export const getFieldsPropGroup = (getDataType = defaultGetDataTypeFn) =>
  new PropGroup({
    fields: getFieldsPropDefinition(getDataType),
  });

const getApiFieldsPropDefinition = (getApiEndpoint = defaultGetApiEndpointFn) =>
  new ArrayType({
    // @ts-expect-error TS(2554): Expected 0-2 arguments, but got 3.
    param: new EndpointParamPropType(getApiEndpoint, undefined, 'edges.node'),
    hidden: new BoolType(),
    value: new StringType()
      .setDisplay((props = {}, __: any, propPath: any) => {
        // @ts-expect-error TS(2769): No overload matches this call.
        const hidden = get(props, [...initial(propPath), 'hidden']);
        return !!hidden;
      })
      .setUseRawValue(true),
    ...commonFormFields,
  });

export const getApiFieldsPropGroup = (
  getApiEndpoint = defaultGetApiEndpointFn,
) =>
  new PropGroup({
    fields: getApiFieldsPropDefinition(getApiEndpoint),
  });

export const setFormFields = (
  dataTypeName: any,
  dataTypes: any,
  updateProperty: any,
) => {
  const newDt = dataTypeName && dataTypes.getByName(dataTypeName);
  if (newDt) {
    updateProperty(
      ['fields'],
      newDt.fields
        .filter((field: any) => !isDefaultField(field))
        .map((field: any) => ({
          field: field.name,
          label: [{ text: upperFirst(field.display.toLowerCase()) }],
          placeholder: [{ text: upperFirst(field.display.toLowerCase()) }],
        }))
        .slice(0, 4),
    );
  }
};
