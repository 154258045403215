import React, { createContext, useContext } from 'react';
import useBoolLocalStorageState, {
  LocalStorageBoolean,
} from './useBoolLocalStorageState';

const darkModeContext = createContext<LocalStorageBoolean>([false, () => {}]);

const useDarkModeContext: () => LocalStorageBoolean = () =>
  useBoolLocalStorageState('darkMode.enabled', false);

export const DarkModeProvider = ({ children }: { children: any }) => {
  const value = useDarkModeContext();
  return (
    <darkModeContext.Provider value={value}>
      {children}
    </darkModeContext.Provider>
  );
};

const useDarkMode = () => {
  return useContext(darkModeContext);
};

export default useDarkMode;
