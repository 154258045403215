import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconAlertTriangle } from '@tabler/icons-react';
import classNames from 'classnames';
import gql from 'graphql-tag';
import { Button, Loader, Modal, TextInput } from '@noloco/components';
import { getDeleteQueryString } from '../../queries/project';
import { getText } from '../../utils/lang';

const DeleteItemConfirmButton = ({
  children,
  confirmDeleteText,
  className,
  dataType,
  itemId,
  onDelete,
  project,
}: any) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [deleteItem, { loading }] = useMutation(
    gql`
      ${getDeleteQueryString(dataType.name)}
    `,
    {
      context: {
        projectQuery: true,
        projectName: project.name,
      },
    },
  );

  const onClose = () => {
    setShowConfirmationModal(false);
  };

  const onConfirmDelete = () => {
    deleteItem({ variables: { id: itemId } }).then(() => {
      onClose();
      if (onDelete) {
        onDelete(itemId);
      }
    });
  };

  return (
    <>
      <Button
        className={classNames(className)}
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          setShowConfirmationModal(true);
          return false;
        }}
        variant="danger"
      >
        {children}
      </Button>
      {showConfirmationModal && (
        <Modal
          icon={<IconAlertTriangle size={18} />}
          title={getText('core.dataTypes.delete.title')}
          confirmText={
            loading ? (
              <Loader type="Bars" size="sm" className="text-white" />
            ) : (
              getText('core.dataTypes.delete.confirm')
            )
          }
          cancelText={getText('core.dataTypes.delete.cancel')}
          onClose={onClose}
          onCancel={onClose}
          onConfirm={onConfirmDelete}
          confirmDisabled={
            (confirmDeleteText && confirmText !== confirmDeleteText.trim()) ||
            loading
          }
          variant="danger"
        >
          <div className="py-1">
            {confirmDeleteText && (
              <div className="flex flex-col">
                <span className="font-medium mt-2">
                  {getText(
                    { confirmText: confirmDeleteText },
                    'core.dataTypes.delete.confirmText',
                  )}
                </span>
                <TextInput
                  className="my-3 text-lg"
                  p={{ x: 4, y: 3 }}
                  placeholder={confirmDeleteText}
                  surface="light"
                  onChange={({ target: { value } }: any) =>
                    setConfirmText(value)
                  }
                />
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeleteItemConfirmButton;
