import React, { memo, useLayoutEffect } from 'react';
import { IconListDetails } from '@tabler/icons-react';
import classNames from 'classnames';
import { Route, Switch } from 'react-router-dom';
import { LG, MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import useRouter from '../../../utils/hooks/useRouter';

const SplitLayout = memo(
  // @ts-expect-error TS(2339): Property 'mainCollection' does not exist on type '... Remove this comment to see the full error message
  ({ mainCollection, sidebarSize, recordView, routePrefix }) => {
    const {
      // @ts-expect-error TS(2339): Property 'recordId' does not exist on type '{}'.
      query: { recordId },
    } = useRouter();

    useLayoutEffect(() => {
      if (recordId) {
        setTimeout(() => {
          const recordEl = document.querySelector(`.record-${recordId}`);
          if (recordEl) {
            recordEl.scrollIntoView();
          }
        }, 400);
      }
    }, [recordId]);

    return (
      <div className="w-full flex items-start absolute inset-0">
        <div
          className={classNames(
            'flex flex-col h-full w-full mr-auto max-h-full',
            {
              'md:hidden': recordId,
              'max-w-lg xl:max-w-sm md:max-w-full ': sidebarSize === SM,
              'max-w-2xl xl:max-w-lg  md:max-w-full': sidebarSize === MD,
              'max-w-4xl xl:max-w-2xl  md:max-w-full ': sidebarSize === LG,
            },
          )}
        >
          {mainCollection}
        </div>
        <div
          className={classNames(
            'w-full flex flex-col flex-shrink h-full max-h-full overflow-y-hidden',
            {
              'md:hidden': !recordId,
            },
          )}
        >
          <Switch>
            <Route path={`${routePrefix}/view/:recordId/:tab?`}>
              {recordView}
            </Route>
            <Route>
              <div className="text-center h-full w-full flex justify-center items-center">
                <IconListDetails
                  size={96}
                  className="text-gray-700 opacity-50"
                />
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    );
  },
);

(SplitLayout as any).defaultProps = {
  sidebarSize: MD,
};

export default SplitLayout;
