import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getColorShade } from '@noloco/components';
import { darkModeColors } from '../../constants/darkModeColors';
import useDarkMode from '../../utils/hooks/useDarkMode';
import Icon from '../Icon';

const Highlights = forwardRef(
  (
    // @ts-expect-error TS(2339): Property 'highlights' does not exist on type '{}'.
    { highlights, className, editorMode, onClick, sectionWidth, theme },
    ref,
  ) => {
    const secondaryColor = theme.brandColorGroups.secondary;
    const [isDarkModeEnabled] = useDarkMode();

    const num = highlights.length;
    const useDynamicWidth = highlights.every(
      (highlight: any) => highlight.columnWidth === 3 || !highlight.columnWidth,
    );

    const isSmallContainer = !!sectionWidth && sectionWidth <= 6;

    const is3x = num % 3 === 0 || num % 5 === 0 || num % 7 === 0;
    const is4x = num % 4 === 0;
    const gridClasses = {
      'grid-cols-12 md:grid-cols-1': !useDynamicWidth,
      'grid-cols-1 md:grid-cols-1': useDynamicWidth && num === 1,
      'grid-cols-2 md:grid-cols-1':
        useDynamicWidth &&
        (num % 2 === 0 || (isSmallContainer && (is3x || is4x))),
      'grid-cols-3 md:grid-cols-1':
        !isSmallContainer && useDynamicWidth && is3x,
      'grid-cols-4 md:grid-cols-2':
        !isSmallContainer && useDynamicWidth && is4x,
      'lg:grid-cols-1': isSmallContainer,
    };

    return (
      <div
        className={classNames('grid gap-4 w-auto', gridClasses, className)}
        onClick={onClick}
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ref}
        data-testid="highlights-section"
      >
        {highlights.map((highlight: any, index: any) => (
          <div
            className={classNames(
              'flex items-start rounded-lg shadow p-6 overflow-hidden',
              `${
                isDarkModeEnabled
                  ? `${darkModeColors.surfaces.elevation1} border ${darkModeColors.borders.one}`
                  : 'bg-white'
              }`,
              !useDynamicWidth ? highlight.className : null,
              `highlight highlight-${highlight.id}`,
            )}
            key={index}
          >
            {highlight.icon && highlight.icon.name && (
              <Box
                className="p-2 rounded-lg mr-3 w-10 h-10 flex-shrink-0 mt-1"
                bg={getColorShade(secondaryColor, 600)}
                text={getColorShade(secondaryColor, 100)}
              >
                <Icon
                  icon={highlight.icon}
                  size={20}
                  className="bg-opacity-75"
                />
              </Box>
            )}
            <div className="flex flex-col justify-center overflow-hidden w-full">
              {highlight.label && (
                <span className="text-sm text-gray-400 tracking-wider mb-1 truncate">
                  {highlight.label}
                </span>
              )}
              <span
                className={`text-lg tracking-wider break-words font-medium ${
                  isDarkModeEnabled
                    ? `darkModeColors.text.secondary`
                    : 'text-gray-900'
                }`}
              >
                {highlight.text || (editorMode ? `Highlight ${index + 1}` : '')}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  },
);

export default withTheme(Highlights);
