import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  TailwindThemeProvider,
  withTheme,
} from '@darraghmckay/tailwind-react-ui';
import { IconPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import { addDataItemToCollectionCache } from '@noloco/core/src/utils/apolloCache';
import { getText } from '@noloco/core/src/utils/lang';
import pascalCase from '@noloco/core/src/utils/pascalCase';
import { getFieldReverseName } from '../../../../utils/fields';
import useFormFields from '../../../../utils/hooks/useFormFields';
import { useFormSections } from '../../../../utils/hooks/useFormSections';
import DataItemFormModal from '../DataItemFormModal';

const RelationalDataFieldCreationModal = ({
  apolloClient,
  className,
  field,
  dataTypes,
  disabled,
  is,
  formFields,
  onChange,
  onOpen,
  collectionQueryString,
  project,
  title,
  theme,
  ...rest
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const dataType = useMemo(() => dataTypes.getByName(field.type), [
    dataTypes,
    field.type,
  ]);

  const filteredFormFields = useMemo(() => {
    const fieldName = field.relatedField
      ? field.relatedField.name
      : // @ts-expect-error TS(2345): Argument of type '{ name: any; }' is not assignabl... Remove this comment to see the full error message
        getFieldReverseName(field, { name: field.type });
    return formFields.filter((formField: any) => formField.field !== fieldName);
  }, [field, formFields]);

  const fields = useFormFields(filteredFormFields, dataType, project, {
    useUrlValues: false,
  });
  const formSections = useFormSections(filteredFormFields);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
    if (onOpen) {
      onOpen();
    }
  }, [onOpen]);

  const updatedTheme = useMemo(() => {
    const themeWithDarkSelectText = set(
      'selectInput.textColor',
      'text-gray-800',
      theme,
    );
    const themeWithDarkDropzoneText = set(
      'dropzone.textColor',
      'text-gray-800',
      themeWithDarkSelectText,
    );
    const themeWithDarkText = set(
      'textInput.textColor',
      'text-gray-800',
      themeWithDarkDropzoneText,
    );

    return themeWithDarkText;
  }, [theme]);

  const onAddDataItem = useCallback(
    (newDataItem: any) => {
      const creationKey = dataType.apiName === 'user' ? 'invite' : 'create';
      // @ts-expect-error TS(2554): Expected 5-6 arguments, but got 4.
      addDataItemToCollectionCache(
        {
          [`${creationKey}${pascalCase(dataType.apiName)}`]: newDataItem,
        },
        apolloClient,
        collectionQueryString,
        dataType.apiName,
      );
      onChange(newDataItem);
      setIsOpen(false);
    },
    [apolloClient, collectionQueryString, dataType.apiName, onChange],
  );

  return (
    <TailwindThemeProvider theme={updatedTheme}>
      <Box
        className={classNames(
          className,
          'w-full flex items-center opacity-75 hover:opacity-100',
        )}
        disabled={disabled}
        onClick={handleOpen}
        {...rest}
        is={is}
      >
        <div className="w-full flex items-center">
          <IconPlus size={16} className="bg-opacity-50" />
          <span className="ml-2">
            {getText({ dataType: dataType.display }, 'core.SELECT_INPUT.new')}
          </span>
        </div>
      </Box>
      {isOpen && (
        <DataItemFormModal
          // @ts-expect-error TS(2322): Type '{ className: string; editorMode: boolean; fo... Remove this comment to see the full error message
          className="ml-16"
          editorMode={false}
          formFields={fields}
          formSections={formSections}
          id={`${dataType.id}-${field.id}`}
          onAddDataItem={onAddDataItem}
          onClose={() => setIsOpen(false)}
          dataType={dataType}
          project={project}
          title={title}
        />
      )}
    </TailwindThemeProvider>
  );
};

RelationalDataFieldCreationModal.defaultProps = {
  is: 'button',
};

export default withTheme(RelationalDataFieldCreationModal);
