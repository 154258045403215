import React, { useMemo } from 'react';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import initial from 'lodash/initial';
import { getText } from '@noloco/core/src/utils/lang';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import {
  CALENDAR,
  MAP,
  ROWS,
  TABLE,
} from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import { VIEW } from '../../../constants/elements';
import {
  CUSTOM_VISIBILITY_RULES,
  FIELD_LEVEL_PERMISSIONS,
  PREMIUM_LAYOUTS,
} from '../../../constants/features';
import { verifyIfPremium } from '../../../utils/collectionLayouts';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import { getFieldFromDependency } from '../../../utils/fields';
import getActionButtonFormFieldConfig from '../../../utils/getActionButtonFormFieldConfig';
import { useNextBackLink } from '../../../utils/hooks/useBacklink';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useMergedScope from '../../../utils/hooks/useMergedScope';
import useScopeUser from '../../../utils/hooks/useScopeUser';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { fieldPermissions } from '../../../utils/permissions';
import { isMultiField } from '../../../utils/relationships';
import { RECORD_SCOPE } from '../../../utils/scope';
import { getAllowedViewRoutePrefixForDataType } from '../../../utils/urls';
import ViewCollection from '../../ViewCollection';

const getParentValueId = (record: any, filterPath: any) => {
  if (filterPath.length === 1) {
    return record.id;
  }

  // @ts-expect-error TS(2769): No overload matches this call.
  const relatedField = get(record, initial(filterPath));
  return relatedField ? relatedField.id : undefined;
};

const getBaseFilterId = (dataList: any) =>
  get(dataList, 'filter.id', '').replace(':VIEW', '');

const getRelatedFieldDetails = (
  dataList: any,
  viewElement: any,
  record: any,
  collectionDataType: any,
  dataTypes: any,
  user: any,
  fieldPermissionsEnabled: any,
) => {
  if (viewElement.id === getBaseFilterId(dataList)) {
    const viewDataType = get(viewElement, 'props.dataList.dataType');
    const dataType = dataTypes.getByName(viewDataType);

    if (collectionDataType && dataType) {
      const filterPath = get(dataList, 'filter.path', []).split('.');
      const field = getFieldFromDependency(filterPath, dataType, dataTypes);

      if (field) {
        const parentValueId = getParentValueId(record, filterPath);

        let permissions = null;
        if (field.relatedField) {
          const relatedDataType = dataTypes.getByName(field.type);
          permissions = fieldPermissions(
            field.relatedField,
            fieldPermissionsEnabled && relatedDataType.permissionsEnabled,
            relatedDataType.permissions,
            user,
          );
        } else {
          permissions = fieldPermissions(
            field,
            fieldPermissionsEnabled && field.dataType.permissionsEnabled,
            field.dataType.permissions,
            user,
          );
        }
        const { mutationField } = getActionButtonFormFieldConfig(
          field.name,
          dataType,
          dataTypes,
        );

        return {
          field,
          dataType,
          parentValueId,
          permissions,
          mutationField,
        };
      }
    }
  }

  return {};
};

const RecordCollection = ({
  dataType,
  editorMode,
  elementPath,
  sectionPath,
  project,
  record,
  isRecordView,
  sectionId,
  recordScope,
  ...rest
}: any) => {
  const {
    calendarView,
    enableDragAndDropEdit,
    charts = [],
    coverPhoto,
    dataList,
    fields = [],
    layout: viewLayout,
    dateStart,
    dateEnd,
    groups,
    groupBy,
    groupBySort,
    groupOptions,
    limitPerGroup,
    hideEmptyGroups,
    hideNewButton,
    editRelatedRecordButtons = {},
    importButton = {},
    exportButton = {},
    map,
    newButton = {},
    newButtonText,
    newLink,
    recordTitle,
    rowLink,
    subtitle,
    title,
    emptyState,
    filters = [],
    search = {},
  } = useSectionScopeVariables(
    VIEW,
    rest,
    project,
    elementPath,
    recordScope,
    skipPropResolvingByValueIds([`${sectionId}:RECORD`, RECORD_SCOPE]),
  );

  const mergedScope = useMergedScope(recordScope);
  const user = useScopeUser();
  const viewElement = get(project.elements, elementPath);
  const dataTypeName = get(dataList, 'dataType');
  const nextBackLink = useNextBackLink(viewElement.id);

  const [isDarkModeEnabled] = useDarkMode();

  const collectionDataType = useMemo(
    () => project.dataTypes.getByName(dataTypeName),
    [dataTypeName, project.dataTypes],
  );
  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
  const rootPathname = useMemo(
    () =>
      getAllowedViewRoutePrefixForDataType(
        dataTypeName,
        project,
        user,
        mergedScope,
        customRulesEnabled,
      ),
    [customRulesEnabled, dataTypeName, mergedScope, project, user],
  );

  const nextElementPath = useMemo(
    () =>
      isRecordView
        ? [...elementPath, 'props', 'record', 'sections', ...sectionPath]
        : [...elementPath, 'props', 'sections', ...sectionPath],
    [isRecordView, elementPath, sectionPath],
  );

  const premiumLayoutsEnabled = useIsFeatureEnabled(PREMIUM_LAYOUTS);
  const layout = useMemo(
    () => verifyIfPremium(viewLayout, premiumLayoutsEnabled),
    [premiumLayoutsEnabled, viewLayout],
  );

  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);

  const { field, permissions, parentValueId, mutationField } = useMemo(
    () =>
      getRelatedFieldDetails(
        dataList,
        viewElement,
        record,
        collectionDataType,
        project.dataTypes,
        user,
        fieldPermissionsEnabled,
      ),
    [
      collectionDataType,
      dataList,
      fieldPermissionsEnabled,
      project.dataTypes,
      record,
      user,
      viewElement,
    ],
  );

  const newRecordValues = useMemo(() => {
    const baseValues = {
      _parentPage: nextBackLink,
    };

    if (
      collectionDataType &&
      field &&
      parentValueId !== undefined &&
      !hideNewButton
    ) {
      if (field.relatedField) {
        const relatedField = collectionDataType.fields.getByName(
          field.relatedField.name,
        );

        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        baseValues[`${relatedField.name}Id`] = parentValueId;
      } else {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        baseValues[`${field.reverseName}Id`] = parentValueId;
      }
    }

    return baseValues;
  }, [collectionDataType, field, hideNewButton, nextBackLink, parentValueId]);

  const updatedImportButton = useMemo(() => {
    if (!importButton || !importButton.show) {
      return importButton;
    }

    if (field && mutationField && parentValueId !== undefined) {
      const fieldIndex = importButton.fields.findIndex(
        (config: any) => config.name === mutationField.name,
      );

      const newConfig = {
        hidden: true,
        name: mutationField.name,
        value: isMultiField(mutationField) ? [parentValueId] : parentValueId,
      };

      if (fieldIndex < 0) {
        return {
          ...importButton,
          fields: [...importButton.fields, newConfig],
        };
      }

      return set(['fields', fieldIndex], newConfig, importButton);
    }

    return importButton;
  }, [field, importButton, mutationField, parentValueId]);

  if ((!dataTypeName || fields.length === 0) && !editorMode) {
    return null;
  }

  if ((!dataTypeName || fields.length === 0) && editorMode) {
    return (
      <div
        className={`border rounded-lg shadow-md flex items-center justify-center p-8 ${
          isDarkModeEnabled
            ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.two}`
            : 'bg-white border-gray-200'
        }`}
      >
        <div className="text-center w-full text-sm text-gray-600 py-20">
          {getText('elements.DETAILS.view.empty')}
        </div>
      </div>
    );
  }

  if (field && (!permissions || !permissions.read)) {
    return null;
  }

  const formatRecordScope = (record: any) => ({
    [sectionId]: {
      edges: {
        node: record,
      },
    },
  });

  if (!collectionDataType) {
    if (editorMode) {
      return (
        <div
          className={`border rounded-lg shadow-md flex items-center justify-center p-8 ${
            isDarkModeEnabled
              ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.two}`
              : 'bg-white border-gray-200'
          }`}
        >
          <div className="text-center w-full text-sm text-gray-600 py-20">
            {getText('elements.COLLECTION.invalid')}
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <ViewCollection
      calendarView={calendarView}
      className={classNames({
        'flex flex-col h-screen max-h-screen-75':
          layout === CALENDAR || layout === MAP,
        ' record-calendar': layout === CALENDAR,
        ' record-map h-full': layout === MAP,
      })}
      charts={charts}
      coverPhoto={coverPhoto}
      dataList={dataList}
      fields={fields}
      layout={layout}
      editorMode={editorMode}
      elementPath={nextElementPath}
      formatRecordScope={formatRecordScope}
      dateStart={dateStart}
      dateEnd={dateEnd}
      groups={groups}
      groupBy={groupBy}
      groupBySort={groupBySort}
      groupOptions={groupOptions}
      limitPerGroup={limitPerGroup}
      hideEmptyGroups={hideEmptyGroups}
      hideNewButton={hideNewButton}
      editRelatedRecordButtons={editRelatedRecordButtons}
      importButton={updatedImportButton}
      exportButton={exportButton}
      newRecordValues={newRecordValues}
      map={map}
      newButton={newButton}
      newButtonText={newButtonText}
      newLink={newLink}
      subtitle={subtitle}
      recordTitle={recordTitle}
      title={title}
      scope={recordScope}
      emptyState={emptyState}
      filters={filters}
      rootDataType={dataType}
      rootPathname={rootPathname}
      rowLink={rowLink}
      project={project}
      qsSuffix={sectionId}
      search={search}
      viewId={viewElement.id}
      viewRootPathname={rootPathname}
      innerClassName={classNames({
        'max-h-screen-75': layout === TABLE,
      })}
      wrapperClassName={classNames({
        'rounded-lg h-full': layout === MAP,
        'max-h-screen-75': layout === ROWS,
      })}
      enableDragAndDropEdit={enableDragAndDropEdit}
    />
  );
};

RecordCollection.defaultProps = {
  fields: [],
};

export default RecordCollection;
