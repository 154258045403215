export const WORKFLOW_FAILURE_TYPE = {
  ACTION_FAILED_TO_EXECUTE: 'ACTION.FAILED_TO_EXECUTE',
  INVALID_WEBHOOK_URL: 'WEBHOOK.INVALID_URL',
  INVALID_WEBHOOK_RESPONSE: 'WEBHOOK.INVALID_RESPONSE',
  INVALID_EMAIL_TO: 'SEND_EMAIL.INVALID_EMAIL_TO',
  INVALID_DATA_TYPE: 'MUTATION.INVALID_DATA_TYPE',
  INVALID_EMAIL_MESSAGE: 'SEND_EMAIL.INVALID_EMAIL_MESSAGE',
  INVALID_EMAIL_SUBJECT: 'SEND_EMAIL.INVALID_EMAIL_SUBJECT',
  INVALID_RECORD_ID: 'MUTATION.INVALID_RECORD_ID',
  NO_CONFIG_FOUND: 'WORKFLOW.NO_PUBLISHED_CONFIG_FOUND',
  NO_RECORD_ITEM: 'WORKFLOW.NO_RECORD_ITEM',
  WEBHOOK_REQUEST_FAILED: 'WEBHOOK.REQUEST_FAILED',
  WORKFLOW_FAILED_TO_EXECUTE: 'WORKFLOW.FAILED_TO_EXECUTE',
  UNSUPPORTED_ACTION: 'ACTION.UNSUPPORTED_ACTION',
  INVOCATION_ID_EXISTS: 'WORKFLOW.INVOCATION_ID_EXISTS',
  RECORD_NOT_FOUND: 'MUTATION.RECORD_NOT_FOUND',
};
