import React from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { ErrorText } from '@noloco/components';

const RadioInputGroup = ({
  className,
  disabled,
  name,
  options,
  onChange,
  validationError,
  value,
}: any) => (
  <div
    className={classNames(className, 'flex flex-col space-y-3')}
    role="radiogroup"
  >
    {options.map((option: any, index: any) => (
      <div className="flex items-center" key={`${option.value}-${value}`}>
        <input
          className="focus:ring-2 default:ring-2 ring-blue-300 w-5 h-5 rounded-full flex-shrink-0 disabled:opacity-75"
          id={`${name}-${option.value}`}
          disabled={disabled}
          type="radio"
          name={name}
          aria-checked={value === option.value}
          value={option.value}
          checked={value === option.value}
          onChange={() => onChange(option.value)}
          tabIndex={
            value === option.value || (isNil(value) && index === 0) ? 0 : -1
          }
        />
        <label
          htmlFor={`${name}-${option.value}`}
          className="ml-4 overflow-hidden"
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </label>
      </div>
    ))}
    {validationError && <ErrorText>{validationError}</ErrorText>}
  </div>
);

export default RadioInputGroup;
