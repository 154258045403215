import React, { useMemo } from 'react';
import classNames from 'classnames';
import withVisibilityRules from '../../../components/canvas/withVisibilityRules';
import CollectionChartWrapper from '../charts/CollectionChartWrapper';

const CollectionCharts = ({
  className,
  charts,
  dataType,
  dataTypes,
  edges,
  editorMode,
  project,
}: any) => {
  const data = useMemo(() => edges.map((edge: any) => edge.node), [edges]);

  if (charts.length === 0) {
    return null;
  }

  return (
    <div className={classNames(className, 'grid grid-cols-12 p-0 gap-4')}>
      {charts.filter(Boolean).map((chart: any) => {
        const ChartWithVisibilityRules = withVisibilityRules(
          CollectionChartWrapper,
          editorMode,
          chart.visibilityRules,
        );

        return (
          <ChartWithVisibilityRules
            className={className}
            chart={chart}
            data={data}
            dataType={dataType}
            dataTypes={dataTypes}
            key={chart.id}
            project={project}
          />
        );
      })}
    </div>
  );
};

export default CollectionCharts;
