export const permissionRuleFragment = `
id
name
description
roleIds
create
update
filter
fieldPermissions {
  id
  dataField {
    id
    name
    display
  }
  read
  update
  create
}`;

export const workflowFragment = `
id
enabled
name
description
trigger
workflow {
  id
  config
}
`;

export const fieldFragment = `
id
name
apiName
display
type
typeOptions
unique
order
relationship
reverseDisplayName
reverseName
internal
hidden
readOnly
required
options {
  id
  name
  display
  order
  color
}
source`;

export const rollupFragment = `
id
name
display
relatedField
field
aggregation
`;

export const publicProjectFragment = `
id
name
elements
settings
live
createdAt
dataTypes {
  id
  name
  apiName
  display
  internal
  fields {
    ${fieldFragment}
  }
  primaryField {
    id
    name
    apiName
  }
  rollups {
    ${rollupFragment}
  }
  workflows {
    ${workflowFragment}
  }
  permissions {
    ${permissionRuleFragment}
  }
  permissionsEnabled
  readOnly
  source {
    id
    type
    collectionType
    display
    syncEnabled
  }
}
media {
  id
  name
  fileType
  size
  uri
  mimetype
}
`;

export const PUBLIC_PROJECT_QUERY = `
  query($projectId: String, $domain: String, $published: Boolean) {
      publicProject(projectId: $projectId, domain: $domain, published: $published) {
        ${publicProjectFragment}
        integrations {
          stripe {
            id
            account {
              id
              chargesEnabled
            }
            publicKey(live: $published)
          }
          google {
            signIn {
              id
              clientId
            }
          }
        }
        apis {
          id
          name
          endpoints {
            id
            name
            method
            type
            parameters {
              id
              name
              dataType
            }
          }
        }
      }
  }
`;
