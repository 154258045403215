import React, { forwardRef } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconArrowUpRight } from '@tabler/icons-react';
import classNames from 'classnames';
import { getColorShade } from '@noloco/components';
import MarkdownText from '../../components/MarkdownText';
import VisibilityRulesWrapper from '../../components/canvas/VisibilityRulesWrapper';
import { darkModeColors } from '../../constants/darkModeColors';
import { VisibilityRules } from '../../models/Element';
import { Project } from '../../models/Project';
import { getColorByIndex } from '../../utils/colors';
import useDarkMode from '../../utils/hooks/useDarkMode';
import Icon, { IconValue } from '../Icon';
import Link from '../Link';

type QuickLink = {
  id: string;
  link: any;
  icon: IconValue;
  title: string;
  description?: string;
  visibilityRules?: VisibilityRules;
};

type QuickLinksProps = {
  dense?: boolean;
  links: QuickLink[];
  className?: string;
  editorMode: boolean;
  project: Project;
  recordScope: Record<string, any>;
  sectionWidth: 3 | 6 | 9 | 'lg' | 'xl';
  onClick?: (event: any) => void;
};

const QuickLinks = forwardRef<any, QuickLinksProps>(
  (
    {
      dense,
      links,
      className,
      editorMode,
      project,
      recordScope,
      sectionWidth,
      onClick,
    },
    ref,
  ) => {
    const [isDarkModeEnabled] = useDarkMode();
    const num = links.length;
    const totalSpaces = num === 3 ? 3 : num + 2 - 1 - ((num + 2 - 1) % 2); // 5 -> 6, 6 -> 6;

    const isSmallContainer = !!sectionWidth && sectionWidth <= 6;
    return (
      <div
        className={classNames(className, 'overflow-hidden')}
        data-testid="quick-links-section"
      >
        <div
          className={classNames(
            `grid w-auto items-center md:grid-cols-1 rounded-lg shadow overflow-hidden border ${
              isDarkModeEnabled
                ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
                : 'bg-white border-gray-100'
            }`,
            {
              'grid-cols-1': num === 1,
              'grid-cols-2':
                num % 2 === 0 ||
                (num !== 1 && num !== 3) ||
                (isSmallContainer && num !== 1),
              'grid-cols-3': num === 3 && !isSmallContainer,
              'lg:grid-cols-1': isSmallContainer,
            },
          )}
          onClick={onClick}
          ref={ref}
        >
          {links.map((link, index) => (
            <VisibilityRulesWrapper
              editorMode={editorMode}
              key={link.id}
              project={project}
              visibilityRules={link.visibilityRules}
              visibilityRulesScope={recordScope}
            >
              <Link
                // @ts-expect-error TS(2322): Type '{ children: Element; editorMode: any; link: ... Remove this comment to see the full error message
                editorMode={editorMode}
                link={link.link}
                className={classNames(
                  `flex flex-col relative  overflow-hidden group p-6 h-full quick-link ${
                    isDarkModeEnabled ? darkModeColors.borders.one : ''
                  }`,
                  `quick-link-${link.id}`,
                  {
                    'border-b': index < totalSpaces - 2 && num > 3,
                    'md:border-b': index < num - 1,
                    'border-r md:border-r-0':
                      (index % 2 === 0 && num >= 2) ||
                      (num === 3 && index === 1),
                  },
                )}
                key={index}
              >
                <div
                  className={classNames('flex', {
                    'flex-col': !dense,
                    'pr-8': dense,
                  })}
                >
                  {link.icon && link.icon.name && !link.icon.hidden && (
                    <Box
                      className={classNames(
                        'p-2 rounded-lg mr-3 w-10 h-10 flex-shrink-0',
                        { 'mb-6': !dense },
                      )}
                      bg={getColorShade(getColorByIndex(index), 100)}
                      text={getColorShade(getColorByIndex(index), 600)}
                    >
                      <Icon
                        icon={link.icon}
                        size={20}
                        className="bg-opacity-75"
                      />
                    </Box>
                  )}
                  <div className="flex flex-col">
                    <span
                      className={`text-lg  tracking-wider font-medium ${
                        isDarkModeEnabled
                          ? darkModeColors.text.primary
                          : 'text-gray-900'
                      }`}
                    >
                      {link.title || `Link ${index + 1}`}
                    </span>
                    {link.description && (
                      <MarkdownText
                        className={`text-sm mt-2 tracking-wider mb-1 ${
                          isDarkModeEnabled
                            ? darkModeColors.text.secondary
                            : 'text-gray-400'
                        }`}
                        small={true}
                      >
                        {link.description}
                      </MarkdownText>
                    )}
                  </div>
                  {link.link && (
                    <div
                      className={`absolute top-8 right-8 w-10 h-10 transition-transform transform duration-300 group-hover:translate-x-4 group-hover:-translate-y-4 ${
                        isDarkModeEnabled
                          ? darkModeColors.text.tertiary
                          : 'text-gray-200'
                      }`}
                    >
                      <IconArrowUpRight size={32} />
                    </div>
                  )}
                </div>
              </Link>
            </VisibilityRulesWrapper>
          ))}
        </div>
      </div>
    );
  },
);

export default QuickLinks;
