import React, { memo, useCallback, useMemo, useState } from 'react';
import { IconArrowLeft, IconArrowRight, IconX } from '@tabler/icons-react';
import { BaseModal } from '@noloco/components';
import ConfirmDeleteButton from '@noloco/ui/src/components/dataTable/ConfirmDeleteButton';
import useOnKeyPress from '../../../utils/hooks/useOnKeyPress';
import useRouter from '../../../utils/hooks/useRouter';
import FilePreviewElement from '../view/FilePreviewElement';
import FileDownloadButton from './FileDownloadButton';

const stopPropagation = (event: any) => event.stopPropagation();

const FilePreviewModal = memo(
  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  ({ id, files, initialIndex, onClose, onRemove, queryFileId }) => {
    useOnKeyPress('Escape', onClose);
    const { replaceQueryParams } = useRouter();
    const [localFileIndex, setFileIndex] = useState(initialIndex || 0);

    const fileIndex = useMemo(
      () =>
        !id || !queryFileId
          ? localFileIndex
          : files.findIndex((f: any) => f.id === queryFileId),
      [files, id, localFileIndex, queryFileId],
    );

    const hasPreviousFile = fileIndex > 0 && files[fileIndex - 1];
    const hasNextFile = fileIndex < files.length - 1;

    const previousFile = useCallback(() => {
      if (hasPreviousFile) {
        if (!id) {
          setFileIndex(fileIndex - 1);
        } else {
          const previousFile = files[fileIndex - 1];
          replaceQueryParams({ _fileId: previousFile.id });
        }
      }
    }, [fileIndex, files, hasPreviousFile, id, replaceQueryParams]);

    const nextFile = useCallback(() => {
      if (hasNextFile) {
        if (!id) {
          setFileIndex(fileIndex + 1);
        } else {
          const nextFile = files[fileIndex + 1];
          replaceQueryParams({ _fileId: nextFile.id });
        }
      }
    }, [fileIndex, files, hasNextFile, id, replaceQueryParams]);

    useOnKeyPress('ArrowLeft', previousFile, {
      enabled: hasPreviousFile,
    });
    useOnKeyPress('ArrowRight', nextFile, {
      enabled: hasNextFile,
    });

    const file = useMemo(() => files[fileIndex], [fileIndex, files]);

    const handleRemove = useCallback(() => {
      if (file) {
        onRemove(file.id);
      }
    }, [file, onRemove]);

    if (!file) {
      return null;
    }

    return (
      <BaseModal size="full" onClose={onClose}>
        <div
          className="flex flex-col w-full h-screen bg-white p-4 overflow-hidden"
          onClick={stopPropagation}
        >
          <div className="flex items-center w-full bg-white py-4 mb-2">
            <button
              onClick={onClose}
              className="opacity-75 hover:opacity-100 p-1 mr-4"
            >
              <IconX size={14} />
            </button>
            <div className="text-lg font-medium tracking-wider pr-4 truncate">
              {file.name || ''}
            </div>
            <div className="flex ml-auto flex-shrink-0">
              {onRemove && (
                <ConfirmDeleteButton className="mr-2" onDelete={handleRemove} />
              )}
              <FileDownloadButton className="mr-4 p-1" file={file} />
              <button
                onClick={previousFile}
                disabled={!hasPreviousFile}
                className="disabled:opacity-50 opacity-75 hover:opacity-100 p-1"
              >
                <IconArrowLeft size={20} />
              </button>
              <button
                className="disabled:opacity-50 ml-2 opacity-75 hover:opacity-100 p-1"
                onClick={nextFile}
                disabled={!hasNextFile}
              >
                <IconArrowRight size={20} />
              </button>
            </div>
          </div>
          <div className="flex items-center justify-center w-full h-full overflow-hidden relative">
            <FilePreviewElement file={file} />
          </div>
        </div>
      </BaseModal>
    );
  },
);

export default FilePreviewModal;
