export const DATE_SHORT = 'D';
export const DATE_MED = 'DD';
export const TIME = 'h:mm a';
export const TIME_24 = 'HH:mm';
export const TIME_SIMPLE = 't';
export const TIME_24_SIMPLE = 'T';
export const TIME_WITH_SECONDS = 'tt';
export const TIME_24_WITH_SECONDS = 'TT';
export const DATETIME_SHORT = 'f';
export const DATETIME_MED = 'ff';
export const ISO = 'ISO';
export const WEEKDAY = 'cccc';
export const WEEKDAY_SHORT = 'ccc';
export const DAY_SHORT = 'd';
export const DAY = 'dd';
export const MONTH = 'LLLL';
export const MONTH_SHORT = 'LLL';
export const YEAR = 'yyyy';

const dateFormatOptions = [
  DATE_SHORT,
  DATE_MED,
  TIME,
  TIME_24,
  TIME_SIMPLE,
  TIME_24_SIMPLE,
  TIME_WITH_SECONDS,
  TIME_24_WITH_SECONDS,
  DATETIME_SHORT,
  DATETIME_MED,
  WEEKDAY,
  WEEKDAY_SHORT,
  DAY_SHORT,
  DAY,
  MONTH,
  MONTH_SHORT,
  YEAR,
  ISO,
];

export default dateFormatOptions;
