import flagsmith from 'flagsmith';

export const LEGACY_IS_INTERNAL_FLAG = 'legacy-is-internal';

export const WEBSOCKET_UPDATES_ENABLED = 'websocket-updates-enabled';

export const LEGACY_UI_MODULES = 'legacy-ui-modules';

export const COMPANY_COLLECTION_ENABLED = 'company-collection-enabled';

export const RECOMMEND_DATA_TYPES = 'recommend-data-types';

export const DATA_ENVIRONMENTS = 'data_environments';

export const PWA = 'pwa';

export const CORE_MAINTENANCE_MODE = 'core-maintenance-mode';

export const API_COLLECTIONS = 'api-collections';

export const AUTO_REFRESH_DATA_TABLE = 'auto-refresh-data-table';

export const EXTERNAL_USERS_ADDON = 'external-users-addon';

export const IN_APP_NOTIFICATIONS = 'in-app-notifications';

export const XANO_ENABLED = 'xano';

const useHasFeatureFlag = (feature: any) => {
  return flagsmith.hasFeature(feature);
};

export default useHasFeatureFlag;
