export const BELOW_SOLID = 'below-solid';
export const CONNECTED: ErrorPosition = 'connected';
export const BELOW = 'below';
export const INLINE_TOOLTIP = 'inline-tooltip';
export const ICON_TOOLTIP = 'icon-tooltip';

const errorPositions = [
  BELOW_SOLID,
  CONNECTED,
  BELOW,
  INLINE_TOOLTIP,
  ICON_TOOLTIP,
];

export type ErrorPosition =
  | 'connected'
  | 'below'
  | 'inline-tooltip'
  | 'icon-tooltip';

export default errorPositions;
