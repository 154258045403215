import React, { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import min from 'lodash/min';
import { Tooltip } from '@noloco/components';
import { LG, MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import {
  CollectionLayout,
  TABLE,
  TABLE_FULL,
} from '../../constants/collectionLayouts';
import { darkModeColors } from '../../constants/darkModeColors';
import { PROGRESS_BAR } from '../../constants/elements';
import { PERCENTAGE } from '../../constants/fieldFormats';
import { FieldTypeOptions } from '../../models/DataTypeFields';
import useDarkMode from '../../utils/hooks/useDarkMode';

type CircleProps = {
  stroke: string;
  ringConfig: RingConfig;
  progress?: number;
};

type RingConfig = {
  height: number;
  width: number;
  marginTop: number;
  radius: number;
  cx: number;
  cy: number;
};

type ProgressRingConfig = {
  [key: string]: RingConfig;
};

type ProgressProps = {
  config: any;
  value: number;
  formattedValue: string | number;
  formattedMaxValue: string | number;
  primaryColor: string;
  section: string;
  typeOptions: FieldTypeOptions;
  layout: CollectionLayout;
};

const progressRingConfig: ProgressRingConfig = {
  [SM]: {
    height: 10,
    width: 10,
    marginTop: 2,
    radius: 12,
    cx: 24,
    cy: 20,
  },
  [MD]: {
    height: 16,
    width: 16,
    marginTop: 3,
    radius: 18,
    cx: 36,
    cy: 30,
  },
  [LG]: {
    height: 24,
    width: 24,
    marginTop: 6,
    radius: 30,
    cx: 60,
    cy: 50,
  },
};

const Circle = ({ stroke, ringConfig, progress = 100 }: CircleProps) => {
  const { radius, cx, cy } = ringConfig;
  const strokeDasharray = 2 * Math.PI * radius;
  const strokePercent = ((100 - progress) * strokeDasharray) / 100;

  return (
    <circle
      r={radius}
      cx={cx}
      cy={cy}
      fill="transparent"
      strokeDasharray={strokeDasharray}
      strokeDashoffset={progress ? strokePercent : 0}
      strokeWidth={4}
      className={classNames(strokePercent !== strokeDasharray ? stroke : '')}
    ></circle>
  );
};

const Progress = ({
  config,
  value,
  formattedValue,
  formattedMaxValue,
  primaryColor,
  section,
  typeOptions,
  layout,
}: ProgressProps) => {
  const [isDarkModeEnabled] = useDarkMode();
  const maxValue = get(config, 'elementConfig.maxValue', 100);
  const format = get(typeOptions, 'format', null);
  const ringSize = get(config, 'elementConfig.ringSize', MD);
  const ringConfig = progressRingConfig[ringSize];

  const progress = useMemo(
    () =>
      (((value * (format === PERCENTAGE ? 100 : 1)) / maxValue) * 100).toFixed(
        2,
      ),
    [value, format, maxValue],
  );

  if (!config.elementType) {
    return <span>{formattedValue}</span>;
  }

  return (
    <div
      className={classNames('flex items-center', {
        'justify-end': !section && (layout === TABLE || layout === TABLE_FULL),
      })}
    >
      {get(config, 'elementConfig.showNumber', false) && (
        <span className="flex items-center justify-center h-10 mr-2 text-sm">
          {formattedValue}
        </span>
      )}
      <Tooltip
        delayShow={400}
        placement="top"
        bg={
          isDarkModeEnabled
            ? darkModeColors.surfaces.elevation2LiteralColor
            : 'white'
        }
        content={
          <span
            className={isDarkModeEnabled ? darkModeColors.text.primary : ''}
          >
            {format === PERCENTAGE
              ? `${progress}%`
              : `${formattedValue} / ${formattedMaxValue} (${progress}%)`}
          </span>
        }
      >
        {config.elementType === PROGRESS_BAR ? (
          <div
            className={classNames(
              'flex items-center mt-1 rounded-full h-2 bg-gray-200',
              { 'w-3/5': !section, 'w-full': section },
            )}
          >
            <div
              className={classNames(`h-2 rounded-full bg-${primaryColor}`)}
              style={{
                width: `${min([100, progress])}%`,
              }}
            ></div>
          </div>
        ) : (
          <svg
            className={classNames(
              'flex items-center justify-center',
              `h-${ringConfig.height} w-${ringConfig.width} mt-${ringConfig.marginTop}`,
            )}
            transform="rotate(-90)"
          >
            <g>
              <Circle stroke="stroke-gray-200" ringConfig={ringConfig} />
              <Circle
                stroke={`stroke-${primaryColor}`}
                progress={min([100, progress]) as number}
                ringConfig={ringConfig}
              />
            </g>
          </svg>
        )}
      </Tooltip>
    </div>
  );
};

export default Progress;
