import React from 'react';
import { InMemoryCache } from '@apollo/client';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { StaticRouter } from 'react-router-dom';
import { AUTH_WRAPPER_ID } from '@noloco/core/src/constants/auth';
import { getDataTypesWithRelations } from '@noloco/core/src/utils/data';
import getStore from '@noloco/renderer/src/utils/store';
import App from './components/App';
import CollectionFieldExample from './components/examples/CollectionFieldExample';
import CollectionFormExample from './components/examples/CollectionFormExample';
import CollectionLayoutExample from './components/examples/CollectionLayoutExample';
import RecordActionsExample from './components/examples/RecordActionsExample';
import RecordViewExample from './components/examples/RecordViewExample';
import WorkflowsExample from './components/examples/WorkflowsExample';
import exampleBillingPlan from './example-billing-plan';
import exampleProject from './example-project';
import exampleUser from './example-user';
import getApolloClient from './utils/apolloClient';

const PROJECT = exampleProject;
PROJECT.dataTypes = getDataTypesWithRelations(PROJECT.dataTypes);

const preloadedState = {
  project: { data: PROJECT },
  billingPlan: exampleBillingPlan,
  data: {
    scope: {
      [AUTH_WRAPPER_ID]: exampleUser,
    },
  },
};
preloadedState.project.data.dataTypes = PROJECT.dataTypes;

const cache = new InMemoryCache().restore(window.__APOLLO_STATE__);

const exampleMap = {
  'collection-layout-example': CollectionLayoutExample,
  'collection-field-example': CollectionFieldExample,
  'collection-form-example': CollectionFormExample,
  'record-view-example': RecordViewExample,
  'record-actions-example': RecordActionsExample,
  'workflow-example': WorkflowsExample,
};

const client = getApolloClient(cache, PROJECT.name, false);

const LOCATION = '/?_date=2023-03-01T00%3A00%3A00.000Z&_view=month';

Object.entries(exampleMap).forEach(([elementId, Example]) => {
  const element = document.getElementById(elementId);

  if (element) {
    // If they use the same store, they can cause problems because they're all showing the same elements
    // To prevent that, each example gets its own store
    const store = getStore(preloadedState);

    ReactDOM.hydrate(
      <StaticRouter location={LOCATION}>
        <Provider store={store}>
          <App client={client} project={PROJECT}>
            <Example project={PROJECT} />
          </App>
        </Provider>
      </StaticRouter>,
      element,
    );
  }
});
