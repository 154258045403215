export const BOOL = 'BOOL';
export const FUNC = 'FUNC';
export const COMBO = 'COMBO';
export const DATA_PROP = 'DATA_PROP';
export const RAW_DATA_PROP = 'RAW_DATA_PROP';
export const DATA_TYPE_NAME = 'DATA_TYPE_NAME';
export const DATA_FIELD = 'DATA_FIELD';
export const ENDPOINT_PARAM = 'ENDPOINT_PARAM';
export const KEY_MAP = 'KEY_MAP';
export const NUMBER = 'NUMBER';
export const STRING = 'STRING';
export const GROUP = 'GROUP';
export const VARIABLE = 'VARIABLE';
export const NODE = 'NODE';
export const NESTED_PROPS = 'NESTED_PROPS';
export const ENUM = 'ENUM';
export const ARRAY = 'ARRAY';
export const CUSTOM_EXTRACTION = 'CUSTOM_EXTRACTION';

export type ElementPropTypes =
  | 'BOOL'
  | 'FUNC'
  | 'COMBO'
  | 'DATA_PROP'
  | 'RAW_DATA_PROP'
  | 'DATA_TYPE_NAME'
  | 'DATA_FIELD'
  | 'ENDPOINT_PARAM'
  | 'KEY_MAP'
  | 'NUMBER'
  | 'STRING'
  | 'GROUP'
  | 'VARIABLE'
  | 'NODE'
  | 'NESTED_PROPS'
  | 'ENUM'
  | 'ARRAY'
  | 'CUSTOM_EXTRACTION';
