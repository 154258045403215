import { useMemo } from 'react';
import { captureException } from '@sentry/react';
import { useSelector } from 'react-redux';
import { getDataFieldsScopeSelector } from '../../components/canvas/withDataFields';
import elementsConfig from '../../elements';
import { ElementPath } from '../../models/Element';
import ElementConfig from '../../models/ElementConfig';
import { Project } from '../../models/Project';
import ElementPropType from '../../models/elementPropTypes/ElementPropType';
import { editorModeSelector } from '../../selectors/elementsSelectors';
import { getDataScopeDeps } from '../data';
import { reduceDynamicPropValues } from '../elementPropResolvers';

const defaultResolveFn = (__1: any, __2: ElementPropType): boolean => true;

const getScopeDeps = (element: any) => {
  let dataScopeDeps = [];

  try {
    dataScopeDeps = getDataScopeDeps(element);
  } catch (e) {
    console.error(e);
    captureException(e);
  }

  return dataScopeDeps;
};

const useSectionScopeVariables = (
  elementType: any,
  props: any,
  project: Project,
  elementPath: ElementPath,
  defaultScope = {},
  shouldResolveValue: (
    rawPropValue: any,
    propDefinition: ElementPropType,
  ) => boolean = defaultResolveFn,
) => {
  const editorMode = useSelector(editorModeSelector);
  const element = useMemo(
    () => ({
      type: elementType,
      props,
    }),
    [elementType, props],
  );

  const dataScopeDeps = useMemo(
    () => (!editorMode ? getScopeDeps(element) : []),
    [editorMode, element],
  );

  const dataFieldsScopeSelector = useMemo(
    () =>
      getDataFieldsScopeSelector(
        dataScopeDeps,
        defaultScope,
        project,
        editorMode,
      ),
    [dataScopeDeps, defaultScope, editorMode, project],
  );

  const scope = useSelector(dataFieldsScopeSelector);

  const sectionScope = useMemo(() => ({ ...defaultScope, ...scope }), [
    defaultScope,
    scope,
  ]);

  const elementConfig = elementsConfig[element.type] || new ElementConfig();

  return reduceDynamicPropValues(
    elementConfig.props,
    props,
    sectionScope,
    element,
    project,
    elementPath,
    true,
    shouldResolveValue,
  );
};

export default useSectionScopeVariables;
