import React from 'react';
import { getTailwindClassNames } from '@darraghmckay/tailwind-react-ui';
import { captureException } from '@sentry/react';
import { IconSquareRotated } from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import initial from 'lodash/initial';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import { DateTime } from 'luxon';
import {
  dtToLocalTZMaintainWallTime,
  dtToUTCMaintainWallTime,
} from '@noloco/components';
import { getDurationFromString } from '@noloco/core/src/utils/durations';
import DataFieldIcon from '@noloco/ui/src/components/DataFieldIcon';
import actionsConfig from '../constants/actions';
import { AUTH_WRAPPER_ID } from '../constants/auth';
import { FILE, USER } from '../constants/builtInDataTypes';
import { INTERNAL } from '../constants/dataSources';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  FORMULA,
  INTEGER,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
  TEXT,
} from '../constants/dataTypes';
import { API_REQUEST } from '../constants/dataWrapperTypes';
import { DATETIME_MED, ISO } from '../constants/dateFormatOptions';
import { HOURS_MINUTES } from '../constants/durationFormatOptions';
import {
  ARRAY,
  COMBO,
  DATA_PROP,
  GROUP,
  KEY_MAP,
  NODE,
  RAW_DATA_PROP,
  STRING,
  VARIABLE,
} from '../constants/elementPropTypeTypes';
import { LIST, PAGE } from '../constants/elements';
import * as elements from '../constants/elements';
import { DATE as DATE_FORMAT } from '../constants/fieldFormats';
import * as filters from '../constants/filters';
import {
  AFTER,
  AFTER_OR_EQUAL,
  BEFORE,
  BEFORE_OR_EQUAL,
  DOES_NOT_CONTAIN,
  EMPTY,
  FALSE,
  NOT_EMPTY,
  NOT_EQUAL,
  OR,
  Operator,
  TRUE,
} from '../constants/operators';
import PRIMITIVE_DATA_TYPES from '../constants/primitiveDataTypes';
import { DATABASE, DERIVED, PAGE_FIELD } from '../constants/scopeTypes';
import elementsConfig from '../elements';
import DataTypeFields, { DataField } from '../models/DataTypeFields';
import DataTypes, { DataType } from '../models/DataTypes';
import { DepValue, Element, ElementPath } from '../models/Element';
import ElementConfig from '../models/ElementConfig';
import { Project } from '../models/Project';
import { RecordEdge } from '../models/Record';
import StateItem from '../models/StateItem';
import StringPropType from '../models/elementPropTypes/StringPropType';
import ChildElementPropType from '../models/elementPropTypes/comboProps/ChildElementPropType';
import { transformQueryArgs } from '../utils/queries';
import { findEndpoint } from './apis';
import { ensureArray } from './arrays';
import cappedMemoize from './cappedMemoize';
import { findPreviewFields } from './dataTypes';
import { getDateFromValue } from './dates';
import { getPagesConfig } from './elements';
import {
  collectionDeps,
  getFieldFromDependency,
  getFieldReverseApiName,
  getFieldReverseName,
  rollupToFakeField,
  sortFields,
} from './fields';
import { mergeFilterIntoWhere } from './filters';
import isIframeFieldAction from './isIframeFieldAction';
import { getText } from './lang';
import {
  compareValues,
  getInputTypeForOperator,
  getResultForOperator,
  shouldOperatorHaveValue,
} from './operator';
import { isOptionType } from './options';
import {
  isMultiField,
  isMultiRelationship,
  isReverseMultiRelationship,
} from './relationships';
import { RECORD_SCOPE, buildScope } from './scope';
import {
  DataItemOption,
  DataItemValueOption,
  flattenStateItem,
  formatStateItemAsOption,
} from './state';
import { transformTailwindProps } from './styles';

export const NON_COLLECTION_DATA_TYPES = [
  ...PRIMITIVE_DATA_TYPES,
  MULTIPLE_OPTION,
];

const MAX_FILTER_DEPTH = 4;

// @ts-expect-error TS(7023): 'getDefaultOptionValue' implicitly has return type... Remove this comment to see the full error message
export const getDefaultOptionValue = (value: any, options: any) => {
  const flattened = value && flattenStateItem(value);
  if (flattened) {
    for (let optionIdx = 0; optionIdx < options.length; optionIdx++) {
      const option = options[optionIdx];
      if (option.value && flattenStateItem(option.value) === flattened) {
        return option;
      } else if (option.options) {
        // @ts-expect-error TS(7022): 'nestedOption' implicitly has type 'any' because i... Remove this comment to see the full error message
        const nestedOption = getDefaultOptionValue(value, option.options);
        if (nestedOption) {
          return nestedOption;
        }
      }
    }
  }
  return null;
};

export const buildFormattedReverseRelateField = (
  relatedField: DataField,
  dataType: DataType,
  relatedType: DataType,
): DataField => {
  const name = getFieldReverseName(relatedField, dataType) as string;
  const apiName = getFieldReverseApiName(relatedField, dataType) as string;

  const isInternalSourceButReverseExternalField =
    relatedField.source !== INTERNAL && relatedType.source?.type === INTERNAL;

  return {
    id: relatedField.id,
    type: dataType.name,
    name: name,
    apiName: apiName,
    display: getText(
      {
        type: (
          relatedField.reverseDisplayName || dataType.display
        ).toLowerCase(),
      },
      'data.fields.relationship',
      relatedField.relationship!,
    ),
    hidden: relatedField.hidden,
    internal: relatedField.internal,
    readOnly: relatedField.readOnly || isInternalSourceButReverseExternalField,
    relatedField,
  };
};

export const getRelatedDataTypes = (
  dataTypes: DataTypes,
  dataTypeName: string,
) =>
  dataTypeName === FILE
    ? []
    : dataTypes.reduce((acc: any, type: any) => {
        const relatedType = dataTypes.getByName(dataTypeName);
        const relatedFields = type.fields.filter(
          (field: any) => field.type === dataTypeName && !field.relatedField,
        );

        acc = acc.concat(
          relatedFields.map((relatedField: any) =>
            buildFormattedReverseRelateField(relatedField, type, relatedType!),
          ),
        );

        return acc;
      }, []);

export const getDataTypeWithRelations = (dataTypes: any, dataType: any) =>
  set(
    'fields',
    new DataTypeFields(
      dataType.fields.concat(
        getRelatedDataTypes(dataTypes, dataType.name).filter(
          (field: any) => field.relatedField.relationship,
        ),
      ),
    ),
    dataType,
  );

type TypeOptionsConfig = {
  acceptableParentType?: string;
  includeCollections?: boolean;
  includeUniqueColumnar?: boolean;
  fieldFilter?: (field: DataField, dataType?: DataType) => boolean;
  forceRawPath?: boolean;
};

type DataPathSegment = { display: string };

const getTypeOptionsForField = (
  field: DataField,
  dataType: DataType,
  dataTypes: DataTypes,
  acceptableDataTypes: string[],
  {
    acceptableParentType,
    includeCollections,
    includeUniqueColumnar,
    forceRawPath,
  }: TypeOptionsConfig,
): ((
  scopeItem: StateItem,
  dataPath: DataPathSegment[],
) => (Omit<DataItemValueOption, 'options'> & {
  value: StateItem;
})[]) => {
  if (!field.relationship && !field.relatedField) {
    if (
      acceptableDataTypes.includes(field.type) &&
      (!acceptableParentType || dataType.name === acceptableParentType)
    ) {
      return (scopeItem: StateItem, dataPath: DataPathSegment[]) => [
        formatStateItemAsOption(
          new StateItem({
            ...scopeItem,
            display: field.display,
            dataType: field.type,
            path: safelyAppendPath(scopeItem.path, field.name),
          }),
          [...dataPath, scopeItem],
          field,
          dataType,
        ),
      ];
    }
  } else if (
    (field.relationship && !isMultiRelationship(field.relationship)) ||
    (field.relatedField &&
      !isReverseMultiRelationship(field.relatedField.relationship))
  ) {
    return (scopeItem: StateItem, dataPath: DataPathSegment[]) => {
      const nextScopeItem = new StateItem({
        ...scopeItem,
        display: field.display,
        dataType: field.type,
        path: safelyAppendPath(scopeItem.path, field.name),
      });

      return getTypeOptionsOfTypeFromParent(
        dataTypes,
        nextScopeItem,
        acceptableDataTypes,
        {
          acceptableParentType,
          forceRawPath,
          includeUniqueColumnar,
          includeCollections,
        },
        [...dataPath, scopeItem],
      );
    };
  } else if (isMultiField(field)) {
    let relatedType: DataType | undefined;
    if (includeUniqueColumnar) {
      relatedType = dataTypes.getByName(field.type);
    }

    if (
      !includeCollections &&
      !includeUniqueColumnar &&
      acceptableDataTypes.includes(DECIMAL)
    ) {
      return (scopeItem: StateItem, dataPath: DataPathSegment[]) => [
        formatStateItemAsOption(
          new StateItem({
            ...scopeItem,
            display: getText(
              { collection: field.display },
              'elements',
              elements.LIST,
              'data.count',
            ),
            dataType: DECIMAL,
            path: safelyAppendPath(scopeItem.path, `${field.name}.totalCount`),
          }),
          dataPath,
          field,
          dataType,
        ),
      ];
    }

    const optionsGenerator = (
      scopeItem: StateItem,
      dataPath: DataPathSegment[],
    ) => [
      ...(includeCollections && acceptableDataTypes.includes(field.type)
        ? [
            formatStateItemAsOption(
              {
                ...scopeItem,
                dataType: field.type,
                display: field.display,
                path: safelyAppendPath(
                  scopeItem.path,
                  `${field.name}${forceRawPath ? '' : '.edges.node'}`,
                ),
              },
              undefined,
              field,
              dataType,
            ),
          ]
        : []),
      ...(includeUniqueColumnar && relatedType
        ? (relatedType ? relatedType.fields : [])
            .filter(
              (relatedField: any) =>
                acceptableDataTypes.includes(relatedField.type) ||
                (relatedType &&
                  acceptableDataTypes.includes(relatedType.name) &&
                  relatedField.name === 'id'),
            )
            .map((relatedField: any) =>
              formatStateItemAsOption(
                new StateItem({
                  ...scopeItem,
                  display: getText(
                    { fieldName: relatedField.display },
                    'elements',
                    elements.LIST,
                    'data',
                    relatedField.relationship
                      ? 'relatedListField'
                      : 'listField',
                  ),
                  dataType: ARRAY,
                  path: safelyAppendPath(
                    scopeItem.path,
                    `${field.name}${forceRawPath ? '' : '._columns'}.${
                      relatedField.name
                    }${
                      (relatedField.relationship ||
                        relatedField.relatedField) &&
                      !forceRawPath
                        ? isMultiField(relatedField)
                          ? '._columns.id'
                          : '.id'
                        : ''
                    }`,
                  ),
                }),
                dataPath,
                field,
                dataType,
              ),
            )
        : []),
      ...(acceptableDataTypes.includes(DECIMAL)
        ? [
            formatStateItemAsOption(
              new StateItem({
                ...scopeItem,
                display: getText('elements', elements.LIST, 'data.totalCount'),
                dataType: DECIMAL,
                path: safelyAppendPath(
                  scopeItem.path,
                  `${field.name}.totalCount`,
                ),
              }),
              dataPath,
              field,
              dataType,
            ),
          ]
        : []),
    ];

    return optionsGenerator;
  }

  return () => [];
};

export const addRelatedFieldsToDataType = (
  _dataType: DataType,
  dataTypes: DataTypes,
): DataType => {
  dataTypes.forEach((dataType) => {
    dataType.fields
      .getFieldsByRelatedDataTypeName(_dataType.name)
      .forEach((field) => {
        _dataType.fields.push(
          buildFormattedReverseRelateField(field, dataType, _dataType),
        );
      });
  });

  return _dataType;
};

export const getDataTypesWithRelations = (_dataTypes: any) => {
  const dataTypes = new DataTypes([..._dataTypes]).setupSync();

  dataTypes.forEach((dataType) => {
    Object.entries(dataType.fields.dataTypeNameToRelatedDataTypesMap).forEach(
      ([dataTypeName, fields]) => {
        const relatedType = dataTypes.getByName(dataTypeName);
        if (relatedType) {
          fields.forEach((field) => {
            const reverseField = buildFormattedReverseRelateField(
              field,
              dataType,
              relatedType,
            );
            if (
              !relatedType.fields.getById(field.id) ||
              (relatedType.name === dataType.name &&
                !relatedType.fields.getByName(reverseField.name))
            ) {
              relatedType.fields.push(reverseField);
            }
          });
        }
      },
    );

    if (dataType.rollups && dataType.rollups.length > 0) {
      const fieldIds = new Set(dataType.fields.map((field) => field.id));
      for (const rollup of dataType.rollups) {
        if (!fieldIds.has(rollup.id)) {
          dataType.fields.push(rollupToFakeField(rollup, dataType, dataTypes));
        }
      }
    }
  });

  return dataTypes;
};

export const getDataTypesWithRelationsAsync = async (_dataTypes: any) => {
  const dataTypes = await new DataTypes(_dataTypes).setup();
  return getDataTypesWithRelations(dataTypes);
};

const defaultFieldFilter = () => true;

type AcceptableDataTypeOptionsGenerator = (
  scopeItem: StateItem,
  dataPath: DataPathSegment[],
) => (Omit<DataItemValueOption, 'options'> & {
  value: StateItem;
})[];

const getAcceptableFieldOptionGeneratorsFromDataType = cappedMemoize(
  (
    dataType: DataType,
    dataTypes: DataTypes,
    acceptableDataTypes: string[] = PRIMITIVE_DATA_TYPES,
    {
      acceptableParentType,
      includeCollections,
      includeUniqueColumnar,
      fieldFilter = defaultFieldFilter,
      forceRawPath,
    }: TypeOptionsConfig = {},
  ): { field: DataField; options: AcceptableDataTypeOptionsGenerator }[] => {
    const options = [];

    if (acceptableDataTypes.includes(dataType.name)) {
      options.push({
        field: dataType.fields.getByName('id'),
        options: (scopeItem: StateItem, dataPath: DataPathSegment[]) => [
          formatStateItemAsOption(
            scopeItem,
            [...dataPath, scopeItem],
            dataType.fields.getByName('id'),
            dataType,
          ),
        ],
      });
    }

    return dataType.fields
      .filter((field: any) => fieldFilter(field, dataType) && !field.hidden)
      .sort(sortFields(dataType))
      .reduce((relevantFields: any, field: DataField) => {
        const fieldOptions = getTypeOptionsForField(
          field,
          dataType,
          dataTypes,
          acceptableDataTypes,
          {
            acceptableParentType,
            includeCollections,
            includeUniqueColumnar,
            forceRawPath,
          },
        );

        relevantFields.push({ field, options: fieldOptions });

        return relevantFields;
      }, options);
  },
  {
    maxKeys: 100,
    getKey: ([dataType, dataTypes, ...rest]) => {
      const dtKey = getDataTypesKey(dataTypes);
      return `${dataType.id}:${dtKey}:${JSON.stringify(rest)}`;
    },
  },
);

const getTypeOptionsOfTypeFromDataType = cappedMemoize(
  (
    dataType: DataType,
    dataTypes: DataTypes,
    scopeItem: StateItem,
    acceptableDataTypes: string[] = PRIMITIVE_DATA_TYPES,
    config: TypeOptionsConfig = {},
    dataPath: DataPathSegment[] = [],
  ) => {
    const fieldOptionGenerators = getAcceptableFieldOptionGeneratorsFromDataType(
      dataType,
      dataTypes,
      acceptableDataTypes,
      config,
    );

    return fieldOptionGenerators.reduce(
      (
        acc: DataItemValueOption[],
        {
          field,
          options: optionGenerator,
        }: { field: DataField; options: AcceptableDataTypeOptionsGenerator },
      ) => {
        const fieldOptions = optionGenerator(scopeItem, dataPath);

        if (field.relationship || field.relatedField) {
          const optionsWithMultiFieldOptions = maybeAddOptionToList(
            field.display,
            fieldOptions,
            acc,
          );

          return optionsWithMultiFieldOptions;
        }

        return [...acc, ...fieldOptions];
      },
      [],
    );
  },
  {
    maxKeys: 1000,
    getKey: ([dataType, dataTypes, ...rest]) => {
      const dtKey = getDataTypesKey(dataTypes);
      return `${dataType.id}:${dtKey}:${JSON.stringify(rest)}`;
    },
  },
);

export const getTypeOptionsOfTypeFromParent = cappedMemoize(
  (
    dataTypes: DataTypes,
    scopeItem: StateItem,
    acceptableDataTypes: string[] = PRIMITIVE_DATA_TYPES,
    configOptions: TypeOptionsConfig = {},
    dataPath: DataPathSegment[] = [],
  ) => {
    if (
      !scopeItem ||
      dataPath.length >= MAX_FILTER_DEPTH ||
      scopeItem.source !== DATABASE
    ) {
      return [];
    }

    const dataType = dataTypes.getByName(scopeItem.dataType);

    if (!dataType) {
      return [];
    }

    return getTypeOptionsOfTypeFromDataType(
      dataType,
      dataTypes,
      scopeItem,
      acceptableDataTypes,
      configOptions,
      dataPath,
    );
  },
  {
    maxKeys: 300,
    getKey: ([dataTypes, ...rest]) => {
      const dtKey = getDataTypesKey(dataTypes);
      return `${dtKey}:${JSON.stringify(rest)}`;
    },
  },
);

export const getDataTypeByName = (dataTypes: any, name: any) =>
  dataTypes.getByName(name) || dataTypes.getByApiName(name);

const maybeAddOptionToList = (label: any, options: any, list: any) =>
  options.length === 0
    ? list
    : [
        ...list,
        {
          label,
          icon: <IconSquareRotated size={16} />,
          options: options,
        },
      ];

export const safelyAppendPath = (path: any, nextPath: any) =>
  !path || path === '' ? nextPath : `${path}.${nextPath}`;

const maybeAddStateItem = (
  stateItem: StateItem,
  dataPath: { display: string }[],
  dataItems: StateItem[],
  field?: DataField,
  dataType?: DataType,
) => {
  const dataItem = formatStateItemAsOption(
    stateItem,
    dataPath,
    field,
    dataType,
  );
  const exists = dataItems.find(
    (item: any) =>
      item.value &&
      item.value.path === dataItem.value.path &&
      item.value.id === dataItem.value.id,
  );

  return exists ? dataItems : [...dataItems, dataItem];
};

export const getCollectionOptionsOfTypeFromParent = (
  dataTypes: any,
  scopeItem: any,
  dataTypeName: any,
  dataPath = [],
) => {
  if (dataPath.length >= MAX_FILTER_DEPTH || scopeItem.source !== DATABASE) {
    return [];
  }

  const dataType = dataTypes.getByName(scopeItem.dataType);
  if (!dataType) {
    return [];
  }

  if (dataPath.length === 0) {
    // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    dataPath.push(scopeItem);
  }

  return dataType.fields.reduce((relevantFields: any, field: any) => {
    if (!field.relatedField && !field.relationship) {
      return relevantFields;
    }

    if (field.relationship) {
      const isMultiRel = isMultiRelationship(field.relationship);
      if (field.type === FILE && (dataTypeName !== FILE || !isMultiRel)) {
        return relevantFields;
      }

      if ((!dataTypeName || field.type === dataTypeName) && isMultiRel) {
        return maybeAddStateItem(
          new StateItem({
            ...scopeItem,
            display: field.display,
            dataType: field.type,
            path: safelyAppendPath(scopeItem.path, field.name),
          }),
          dataPath,
          relevantFields,
          field,
          dataType,
        );
      }

      if (isMultiRelationship(field.relationship)) {
        return relevantFields;
      }

      const nextScopeItem = new StateItem({
        ...scopeItem,
        display: field.display,
        dataType: field.type,
        path: safelyAppendPath(scopeItem.path, field.name),
      });

      const relatedOptions = getCollectionOptionsOfTypeFromParent(
        dataTypes,
        nextScopeItem,
        dataTypeName,
        // @ts-expect-error TS(2322): Type 'StateItem' is not assignable to type 'never'... Remove this comment to see the full error message
        [...dataPath, nextScopeItem],
      );

      return maybeAddOptionToList(
        field.display,
        relatedOptions,
        relevantFields,
      );
    }

    if (
      field.relatedField &&
      (!dataTypeName || field.type === dataTypeName) &&
      isReverseMultiRelationship(field.relatedField.relationship)
    ) {
      return maybeAddStateItem(
        new StateItem({
          ...scopeItem,
          display: field.display,
          dataType: field.type,
          path: safelyAppendPath(scopeItem.path, field.name),
        }),
        dataPath,
        relevantFields,
        field,
        dataType,
      );
    }

    if (
      field.type === 'file' ||
      isReverseMultiRelationship(field.relatedField.relationship)
    ) {
      return relevantFields;
    }

    const relatedOptions = getCollectionOptionsOfTypeFromParent(
      dataTypes,
      new StateItem({
        ...scopeItem,
        display: field.display,
        dataType: field.type,
        path: safelyAppendPath(scopeItem.path, field.name),
      }),
      dataTypeName,
      // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
      [...dataPath, scopeItem],
    );

    return maybeAddOptionToList(field.display, relatedOptions, relevantFields);
  }, []);
};

const getActionDataItems = (actions: any) =>
  ensureArray(actions).reduce((depsAcc: any, action: any) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const actionConfig = actionsConfig[action.type];
    if (!actionConfig) {
      return depsAcc;
    }

    return [...depsAcc, ...actionConfig.getDataItems(action)];
  }, []);

const getConditionDataItems = (conditionsObject = {}) => {
  const items: any = [];
  // @ts-expect-error TS(2345): Argument of type '({ conditions }: { conditions: a... Remove this comment to see the full error message
  Object.values(conditionsObject).forEach(({ conditions }) =>
    ensureArray(conditions).forEach((andCondition: any) =>
      ensureArray(andCondition).forEach((condition: any) => {
        if (condition.field) {
          items.push(condition.field);
        }

        ensureArray(condition.value).forEach((valueItem: any) => {
          if (valueItem.data) {
            items.push(valueItem.data);
          }
        });
      }),
    ),
  );
  return items;
};

const extractConditionDependencies = (conditionsObject = {}) =>
  getConditionDataItems(conditionsObject);

export const getVisibilityCustomRulesDataItems = (customRules: any[] = []) => {
  if (!customRules || !Array.isArray(customRules)) {
    return [];
  }

  const items: any = [];
  customRules.forEach((andCondition: any[]) =>
    ensureArray(andCondition).forEach((condition: any) => {
      if (condition.field) {
        items.push(condition.field);
      }
      ensureArray(condition.value).forEach((valueItem: any) => {
        if (valueItem.data) {
          items.push(valueItem.data);
        }
      });
    }),
  );
  return items;
};

export const getDataItemsForPropsShape = (
  propsShape: any,
  elementBaseProps: any,
  element: any,
  dataTypes = [],
  includeSelf = true,
) => {
  let dataItems: any = [];
  const handleDataItemProp = (dataItem: any) => {
    if (dataItem) {
      dataItems.push(dataItem);
    }
  };

  const handleDataStringProp = (dataProp: any) => {
    if (Array.isArray(dataProp)) {
      dataProp.forEach(({ data }) => {
        if (data) {
          handleDataItemProp(data);
        }
      });
    }
  };

  Object.entries(propsShape || {}).forEach(([propName, propDefinition]) => {
    if (elementBaseProps) {
      const prop = elementBaseProps[propName];
      if ((propDefinition as any).extractPropTypesDependencies) {
        dataItems = (propDefinition as any).extractPropTypesDependencies(
          prop,
          dataItems,
          elementBaseProps,
          propsShape,
          element,
          dataTypes,
          includeSelf,
        );
      } else {
        switch ((propDefinition as any).type) {
          case RAW_DATA_PROP:
          case DATA_PROP: {
            if (prop) {
              handleDataItemProp(prop);
            }
            break;
          }
          case ARRAY: {
            if (!Array.isArray(prop)) {
              break;
            }
            const arrayDeps = ensureArray(prop).reduce(
              (nestedDeps, nestedBaseProps) => [
                ...nestedDeps,
                ...getDataItemsForPropsShape(
                  (propDefinition as any).shape,
                  nestedBaseProps,
                  element,
                  dataTypes,
                  includeSelf,
                ),
              ],
              [],
            );
            dataItems = [...dataItems, ...arrayDeps];
            break;
          }
          case NODE: {
            if (!Array.isArray(prop)) {
              break;
            }
            const arrayDeps = ensureArray(prop).reduce(
              (nestedDeps, nestedElement) => [
                ...nestedDeps,
                ...findDependencies(nestedElement, dataTypes, false),
              ],
              [],
            );
            dataItems = [...dataItems, ...arrayDeps];
            break;
          }
          case COMBO: {
            if (propDefinition instanceof ChildElementPropType) {
              const comboDeps = findDependencies(prop, dataTypes, false);
              dataItems = [...dataItems, ...comboDeps];
              break;
            }
            const comboDeps = getDataItemsForPropsShape(
              (propDefinition as any).shape,
              prop,
              element,
              dataTypes,
              includeSelf,
            );
            dataItems = [...dataItems, ...comboDeps];
            break;
          }
          case KEY_MAP: {
            if (prop && typeof prop === 'object') {
              const keyMapDeps = Object.values(prop).reduce(
                (acc, propValue) => [
                  // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
                  ...acc,
                  ...getDataItemsForPropsShape(
                    (propDefinition as any).shape,
                    propValue,
                    element,
                    dataTypes,
                    includeSelf,
                  ),
                ],
                [],
              );
              // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
              dataItems = [...dataItems, ...keyMapDeps];
            }
            break;
          }
          case GROUP: {
            const comboDeps = getDataItemsForPropsShape(
              (propDefinition as any).shape,
              elementBaseProps,
              element,
              dataTypes,
              includeSelf,
            );
            dataItems = [...dataItems, ...(comboDeps || [])];
            break;
          }
          case VARIABLE: {
            const comboDeps = getDataItemsForPropsShape(
              {
                label: new StringPropType(),
                value: (propDefinition as any).propType,
              },
              prop,
              element,
              dataTypes,
              includeSelf,
            );
            dataItems = [...dataItems, ...comboDeps];
            break;
          }
          case STRING: {
            handleDataStringProp(prop);
            break;
          }
          default:
            break;
        }
      }
    }
  });

  return dataItems;
};

const extractPropTypesDependencies = (
  propsShape: any,
  elementBaseProps: any,
  element: any,
  dataTypes: any,
  includeSelf = true,
) => {
  try {
    let dependencies = getDataItemsForPropsShape(
      propsShape,
      elementBaseProps,
      element,
      dataTypes,
      includeSelf,
    );

    if (elementBaseProps) {
      Object.entries(propsShape || {})
        .map(([propKey, propDefinition]) => {
          if ((propDefinition as any).type === NODE) {
            return elementBaseProps[propKey];
          }
          return null;
        })
        .filter(Boolean)
        .forEach((prop) => {
          if (prop && Array.isArray(prop)) {
            const nestedDeps = prop.reduce(
              (nestedDepsAcc, propChild) => [
                ...nestedDepsAcc,
                ...findDependencies(propChild, dataTypes),
              ],
              [],
            );
            dependencies = [...dependencies, ...nestedDeps];
          }
        });
    }

    return dependencies;
  } catch (e) {
    captureException(e);
    console.error(e);
    return [];
  }
};

export const extractDataListDependencies = (
  element: Element | undefined,
  listProps = {},
  deps: DepValue[] = [],
  dataTypes: DataTypes = new DataTypes([]),
  includeSelf = true,
) => {
  let dependencies = [...deps];
  const defaultFilter = (listProps as any).filter;

  const filterHasValidResult = (customFilter: any) =>
    customFilter.result && Array.isArray(customFilter.result);

  const pushDependencyForCustomFilter = (customFilter: any) =>
    customFilter.result
      .filter((segment: any) => segment.data)
      .forEach((segment: any) => {
        dependencies.push(segment.data);
      });

  if (listProps && (listProps as any).customFilters) {
    ensureArray((listProps as any).customFilters).forEach(
      (customFilter: any) => {
        if (filterHasValidResult(customFilter)) {
          pushDependencyForCustomFilter(customFilter);
        } else if (
          customFilter.branches &&
          Array.isArray(customFilter.branches)
        ) {
          customFilter.branches.forEach((filterBranch: any) => {
            if (filterBranch.filters && Array.isArray(filterBranch.filters)) {
              filterBranch.filters.forEach((filter: any) => {
                if (filterHasValidResult(filter)) {
                  pushDependencyForCustomFilter(filter);
                }
              });
            }
          });
        }
      },
    );
  }

  if (
    listProps &&
    (listProps as any).dataSource === API_REQUEST &&
    (listProps as any).endpoint
  ) {
    Object.values((listProps as any).endpoint.params || {}).forEach(
      (paramValue) => {
        if (paramValue && Array.isArray(paramValue)) {
          paramValue
            .filter((paramValueItem) => paramValueItem.data)
            .forEach((paramDataItem) => {
              dependencies.push(paramDataItem.data);
            });
        }
      },
    );
  }

  if (
    defaultFilter &&
    defaultFilter.id &&
    (listProps as any).dataSource !== API_REQUEST
  ) {
    // Because "COLLECTION"s have props that resolve to row items we need to proxy those here
    // by re-finding the deps for itself, but making sure we don't infinitely loop
    const selfDeps =
      element && element.type !== LIST && includeSelf
        ? findDependentValues(element.id, element, dataTypes, false)
        : [];
    const nestedValues = ensureArray(element?.children).reduce(
      (nestedAcc: any, child: any) => [
        ...nestedAcc,
        ...findDependentValues((element as Element).id, child, dataTypes),
      ],
      selfDeps,
    );

    const nestedDeps = nestedValues.map(
      (nestedStateItem: any) =>
        new StateItem({
          ...nestedStateItem,
          id: defaultFilter.id,
          path: safelyAppendPath(defaultFilter.path, nestedStateItem.path),
        }),
    );
    // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
    const { dataType, limit, orderBy } = listProps;

    // @ts-expect-error TS2554: Expected 3-4 arguments, but got 1.
    const resolvedLimit = limit ? resolveDataValue(limit) : null;
    const queryArgs = {
      ...(orderBy ? { orderBy } : {}),
      ...(resolvedLimit ? { first: resolvedLimit } : {}),
    };

    const dataTypesMap = new DataTypes(dataTypes);

    const collectionDataType = dataTypesMap.getByName(dataType);
    if (!collectionDataType) {
      return dependencies;
    }

    const collectionField = getFieldFromDependency(
      defaultFilter.path.split('.'),
      collectionDataType,
      dataTypesMap,
    );

    if (!collectionField || !isMultiField(collectionField)) {
      return dependencies;
    }

    dependencies.push(
      new StateItem({
        ...defaultFilter,
        dataType,
        args: queryArgs,
      }),
    );

    // To ensure that deeply nested collections always request the ID and UUID of the parent-type object
    const parentPath = initial(defaultFilter.path.split('.')).join('.');
    ['id', 'uuid'].forEach((path) => {
      dependencies.push(
        new StateItem({
          ...defaultFilter,
          dataType: TEXT,
          path: safelyAppendPath(parentPath, path),
        }),
      );
    });

    dependencies = dependencies.concat(nestedDeps);
  }

  if (
    defaultFilter &&
    (listProps as any).dataType
    // (defaultFilter.id === parentId ||
    //   (!defaultFilter.id && element.id === parentId))
  ) {
    ['id', 'uuid'].forEach((path) => {
      dependencies.push(
        new StateItem({
          ...defaultFilter,
          dataType: TEXT,
          path: safelyAppendPath(defaultFilter.path, `edges.node.${path}`),
        }),
      );
    });
  }

  return dependencies;
};

// @ts-expect-error TS(7023): 'getDeepestDataProperty' implicitly has return typ... Remove this comment to see the full error message
const getDeepestDataProperty = (dataItem: any) =>
  dataItem.property ? getDeepestDataProperty(dataItem.property) : dataItem;

export const getDataItemDataType = (dataItem: any) =>
  dataItem ? (getDeepestDataProperty(dataItem) || {}).dataType : null;

export const getDataTypesKey: (dataTypes: DataTypes) => string = cappedMemoize(
  (dataTypes) => `${Date.now()}:${dataTypes.length}`,
  {
    maxKeys: 2,
    // This should rarely change so no need to stringify it
    getKey: (dataTypes) => dataTypes,
  },
);

export const findDependencies = cappedMemoize(
  (element, dataTypes, includeSelf) => {
    if (!element) {
      return [];
    }

    const elementConfig = elementsConfig[element.type];

    if (!elementConfig) {
      return [];
    }

    let dependencies: any = [];
    dependencies = dependencies.concat(
      extractPropTypesDependencies(
        elementConfig.props,
        element.props,
        element,
        dataTypes,
        includeSelf,
      ),
    );

    dependencies = dependencies.concat(
      extractConditionDependencies(element.conditions),
    );

    const customRules = get(element, 'visibilityRules.customRules');
    if (customRules) {
      const customRuleDataItems = getVisibilityCustomRulesDataItems(
        customRules,
      );
      dependencies = dependencies.concat(customRuleDataItems);
    }

    if (element.type === LIST && element.props.filter) {
      try {
        dependencies = extractDataListDependencies(
          element,
          element.props,
          dependencies,
          dataTypes,
          includeSelf,
        );
      } catch (e) {
        captureException(e);
        console.error(e);
      }
    }

    return [
      ...dependencies,
      ...ensureArray(element.children).reduce(
        (depAcc: any, child: any) => [
          ...depAcc,
          ...findDependencies(child, dataTypes),
        ],
        [],
      ),
    ];
  },
  {
    maxKeys: 350,
    getKey: ([element, dataTypes, includeSelf]) => {
      const dtKey = getDataTypesKey(dataTypes);
      return JSON.stringify({ element, dtKey, includeSelf });
    },
  },
);

export const findDependentValues = (
  parentId: string,
  element: Element,
  dataTypes: DataTypes,
  includeSelf = true,
) =>
  findDependentValuesByParentIds([parentId], element, dataTypes, includeSelf);

export const findDependentValuesByParentIds = cappedMemoize(
  (parentIds, element, dataTypes, includeSelf = true) => {
    if (!element) {
      return [];
    }

    const elementConfig = elementsConfig[element.type];

    let dependencies = findDependencies(element, dataTypes, includeSelf).filter(
      (dataItem: any) => dataItem && parentIds.includes(dataItem.id),
    );

    if (!elementConfig) {
      return [];
    }

    if (
      element.type === PAGE &&
      parentIds.includes(element.id) &&
      element.props.dataType &&
      element.props.dataProperty
    ) {
      ['id', 'uuid'].forEach((path) => {
        dependencies.push(
          // @ts-expect-error TS(2345): Argument of type '{ id: any; dataType: string; sou... Remove this comment to see the full error message
          new StateItem({
            id: element.id,
            dataType: TEXT,
            source: DATABASE,
            path,
          }),
        );
      });
    }

    return dependencies;
  },
  {
    maxKeys: 350,
    getKey: ([parentIds, element, dataTypes, includeSelf]) => {
      const dtKey = getDataTypesKey(dataTypes);
      return JSON.stringify({ parentIds, element, dtKey, includeSelf });
    },
  },
);

export const mapDepPathToApiPath = (
  dependencyPath: any,
  dataType: any,
  dataTypes: any,
) => {
  return dependencyPath.reduce((newPath: any, pathSlice: any, index: any) => {
    if (['_columns', 'edges', 'node'].includes(pathSlice)) {
      return [...newPath, pathSlice];
    }

    const field = getFieldFromDependency(
      dependencyPath.slice(0, index + 1),
      dataType,
      dataTypes,
    );
    if (field && field.apiName && !field.apiName.includes('.')) {
      return [...newPath, field.apiName];
    }

    return [...newPath, pathSlice];
  }, []);
};

const findElementById = (elementId: any, elements = []) => {
  let q = [...elements];

  while (q.length > 0) {
    const el = q.shift();
    if (!el) {
      return null;
    }

    if (
      (el as any).id === elementId ||
      `${(el as any).id}:VIEW` === elementId
    ) {
      return el;
    }

    if ((el as any).children && Array.isArray((el as any).children)) {
      // @ts-expect-error TS(2769): No overload matches this call.
      q = q.concat([...(el as any).children]);
    }
  }

  return null;
};

const getElementDatatypeName = (element: any) => {
  switch (element.type) {
    case elements.CHART:
    case elements.COLLECTION:
    case elements.VIEW:
      return get(element, 'props.dataList.dataType');
    case elements.LIST:
    case elements.FORM_V2:
    case elements.PAGE:
      return get(element, 'props.dataType');
    default:
      return null;
  }
};

const getValuePathRootDataType = (
  valuePath: any,
  elements: any,
  dataTypes: any,
) => {
  const rootId = first(valuePath);
  if (rootId === AUTH_WRAPPER_ID) {
    return dataTypes.getByName(USER);
  }

  const valuePathElement = findElementById(rootId, elements);

  if (!valuePathElement) {
    return null;
  }

  const dataTypeName = getElementDatatypeName(valuePathElement);

  if (!dataTypeName) {
    return null;
  }

  return dataTypes.getByName(dataTypeName);
};

export const convertValuePathToApiPath = (valuePath: any, project: Project) => {
  if (!project) {
    return valuePath;
  }

  const { isV2, pagesPath } = getPagesConfig(
    project.elements,
    project.settings,
  );
  const elements = isV2 ? project.elements : get(project, pagesPath);

  const rootDataType = getValuePathRootDataType(
    valuePath,
    elements,
    project.dataTypes,
  );

  if (!rootDataType) {
    return valuePath;
  }

  const mappedValuePath = [
    first(valuePath),
    ...mapDepPathToApiPath(valuePath.slice(1), rootDataType, project.dataTypes),
  ];

  return mappedValuePath;
};

const setDefaultDependenciesAtPath = (object: any, path: string[] = []) => {
  const objectWithId = set([...path, 'id'], true, object);

  if (path.length === 0) {
    return objectWithId;
  }

  return set([...path, 'uuid'], true, objectWithId);
};

export const transformDepsToQueryObject = (
  dataType: any,
  dataTypesWithRelations: any,
  dependencies: any,
  pathPrefix = [],
) =>
  dependencies.reduce((queryObject: any, dep: any) => {
    const field = getFieldFromDependency(
      dep.path.split('.'),
      dataType,
      dataTypesWithRelations,
    );

    if (!field) {
      return queryObject;
    }

    const cleanDepPath = dep.path.replace(/_columns/g, 'edges.node').split('.');

    const apiDepPath = mapDepPathToApiPath(
      cleanDepPath,
      dataType,
      dataTypesWithRelations,
    );

    const splitPath = [...pathPrefix, ...apiDepPath];
    const isRelationshipField = !!(field.relationship || field.relatedField);

    const valueIsAlreadySet =
      get(queryObject, splitPath) &&
      (!isRelationshipField ||
        get(queryObject, [
          ...splitPath,
          ...(isMultiField(field) ? ['edges', 'node', 'id'] : ['id']),
        ]));

    if (!valueIsAlreadySet) {
      if (!isRelationshipField) {
        const object = set(splitPath, true, queryObject);
        if (collectionDeps.includes(last(splitPath))) {
          return object;
        }

        const initialSplitPath = initial(splitPath);
        const initialObject =
          first(initialSplitPath) === 'edges'
            ? object
            : setDefaultDependenciesAtPath(object);

        const objectWithId = initialSplitPath.reduce(
          (intermediateObject, _, index) => {
            const child = splitPath[index + 1];

            if (['edges', 'node'].includes(child)) {
              return intermediateObject;
            }

            const intermediatePath = [...splitPath.slice(0, index + 1)];
            return setDefaultDependenciesAtPath(
              intermediateObject,
              intermediatePath,
            );
          },
          initialObject,
        );
        return set([...initial(splitPath), 'uuid'], true, objectWithId);
      }

      if (isRelationshipField) {
        if (!isMultiField(field)) {
          return set(
            splitPath,
            { id: true, uuid: true, ...get(queryObject, splitPath, {}) },
            queryObject,
          );
        } else {
          return set(
            splitPath,
            {
              edges: {
                node: {
                  id: true,
                  uuid: true,
                  ...get(queryObject, [...splitPath, 'edges', 'node'], {}),
                },
              },
              pageInfo: {
                startCursor: true,
                endCursor: true,
                hasNextPage: true,
                hasPreviousPage: true,
              },
              ...(dep.args ? { __args: transformQueryArgs(dep.args) } : {}),
            },
            queryObject,
          );
        }
      }
    } else if (dep.args) {
      return set(
        splitPath,
        {
          ...get(queryObject, splitPath, {}),
          __args: transformQueryArgs(dep.args),
        },
        queryObject,
      );
    }

    return queryObject;
  }, {});

// @ts-expect-error TS(7023): 'getApiDataShapeCollectionOptions' implicitly has ... Remove this comment to see the full error message
const getApiDataShapeCollectionOptions = (dataShape: any, path = []) =>
  // @ts-expect-error TS(2769): No overload matches this call.
  Object.entries(dataShape).reduce((options, [key, value]) => {
    if (value && Array.isArray(value)) {
      return [
        ...options,
        {
          label: [...path, key].join(' > '),
          value: {
            path: ['data', ...path, key].join('.'),
          },
        },
      ];
    }

    if (value && typeof value === 'object') {
      return [
        ...options,
        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
        ...getApiDataShapeCollectionOptions(value, [...path, key]),
      ];
    }

    return options;
  }, []);

export const getAPIEndpointCollectionOptions = (
  apiId: any,
  endpointId: any,
  project: any,
) => {
  const { endpoint } = findEndpoint(project.apis, apiId, endpointId);
  if (!endpoint) {
    return [];
  }

  if (endpoint.dataShape && Array.isArray(endpoint.dataShape)) {
    return [
      {
        label: 'Data',
        value: {
          path: 'data',
        },
      },
    ];
  }

  return getApiDataShapeCollectionOptions(endpoint.dataShape);
};

const defaultShouldOnlyIncludeRelatedId = () => false;

export const getDataTypeFieldOptions = (
  dataType: any,
  project: any,
  {
    onlyRelatedId = false,
    fieldFilter = defaultFieldFilter,
    stateId = RECORD_SCOPE,
    shouldOnlyIncludeRelatedId = defaultShouldOnlyIncludeRelatedId,
  } = {},
) => {
  const recordConditionFieldOptions = dataType.fields
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
    .filter((field: any) => fieldFilter(field, dataType))
    .map((field: any) => {
      const label = (
        <div className="flex items-center">
          <DataFieldIcon field={field} size={14} />
          <span className="ml-2">{field.display}</span>
        </div>
      );

      const fieldOption = {
        label,
        plainLabel: field.display,
        buttonLabel: field.display,
        value: new StateItem({
          id: stateId,
          path: field.name,
          dataType: field.type,
          display: field.display,
          source: DATABASE,
        }),
        field,
      };

      if (
        (!field.relationship && !field.relatedField) ||
        (onlyRelatedId && isMultiField(field)) ||
        // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
        (onlyRelatedId && shouldOnlyIncludeRelatedId(field))
      ) {
        return fieldOption;
      }

      let relatedType = null;

      if (field.relationship) {
        relatedType = project.dataTypes.getByName(field.type);
      }

      if (field.relatedField) {
        relatedType = project.dataTypes.getByName(field.type);
      }

      if (relatedType) {
        return {
          label,
          plainLabel: field.display,
          options: [
            ...(onlyRelatedId ? [fieldOption] : []),
            {
              label: `${field.name} values`,
              heading: true,
              options: relatedType.fields
                .filter(
                  (rField: any) =>
                    !rField.relationship &&
                    !rField.relatedField &&
                    (rField.name !== 'id' || !onlyRelatedId),
                )
                .map((rField: any) => ({
                  label: (
                    <div className="flex items-center">
                      <DataFieldIcon field={rField} size={14} />
                      <span className="ml-2">{rField.display}</span>
                    </div>
                  ),

                  plainLabel: rField.display,

                  value: new StateItem({
                    id: stateId,
                    path: `${field.name}.${
                      isMultiField(field) ? '_columns.' : ''
                    }${rField.name}`,
                    dataType: isMultiField(field) ? ARRAY : rField.type,
                    display: field.display,
                    source: DATABASE,
                  }),
                })),
            },
          ],
          field,
        };
      }

      return null;
    })
    .filter(Boolean);

  return {
    label: getText('elements.VIEW.fields.conditions.fieldValues'),
    options: recordConditionFieldOptions,
  };
};

export const getDataCollectionOptionsOfType = (
  project: any,
  elementPath: any,
  dataType: any,
  defaultFilterOptions = [],
  additionalContext = {},
) => {
  const dataTypes = project.dataTypes;
  const context = {
    dataTypes,
    collections: true,
    acceptableDataTypes: [dataType],
    getDataTypeOptions: (scopeItem: any) =>
      getCollectionOptionsOfTypeFromParent(dataTypes, scopeItem, dataType),
    ...additionalContext,
  };

  const options = [
    ...defaultFilterOptions,
    ...buildScope(project, elementPath, false, false, context),
  ];

  if (options.length === 0) {
    return [
      { label: getText('contentEditor.options.noOptions'), disabled: true },
    ];
  }

  return options;
};

export const getOptionScopeItemsForOptionField = (
  field: DataField,
  dataType: DataType,
): DataItemOption & { options: DataItemOption[] } => ({
  label: field.display,
  heading: true,
  options: (field.options ?? [])
    .filter((option: any) => option.display)
    .map((option: any) =>
      formatStateItemAsOption(
        new StateItem({
          id: SINGLE_OPTION,
          path: `${dataType.name}.${field.name}.${option.name}.name`,
          source: DERIVED,
          dataType: SINGLE_OPTION,
          display: option.display,
        }),
        [],
        field,
        dataType,
      ),
    ),
});

const getRelativeDateOptions = cappedMemoize(
  () =>
    Object.entries({
      today: ['now', 'start', 'end'],
      week: ['start', 'end'],
      month: ['start', 'end'],
      year: ['start', 'end'],
    }).map(([dateOption, options]) => ({
      label: getText('contentEditor.values', DATE, dateOption),
      options: options.map((option) =>
        formatStateItemAsOption(
          new StateItem({
            id: 'values',
            path: `${DATE}.${dateOption}.${option}`,
            source: DERIVED,
            dataType: DATE,
            display: getText('contentEditor.values', DATE, dateOption, option),
          }),
        ),
      ),
    })),
  { maxKeys: 1 },
);

export const getRawValueOptionFromDataTypes = (
  acceptableDataTypes: any,
  { dateHelp }: { dateHelp?: string } = {},
) => {
  const rawValues = [];
  if (acceptableDataTypes.includes(BOOLEAN)) {
    ['true', 'false'].forEach((boolValue) => {
      rawValues.push(
        formatStateItemAsOption(
          new StateItem({
            id: 'values',
            path: `${BOOLEAN}.${boolValue}`,
            source: DERIVED,
            dataType: BOOLEAN,
            display: getText('contentEditor.values', BOOLEAN, boolValue),
          }),
        ),
      );
    });
  }

  if (acceptableDataTypes.includes(DATE)) {
    rawValues.push({
      help: dateHelp,
      label: getText('contentEditor.values', DATE, 'dates'),
      options: getRelativeDateOptions(),
    });
  }

  rawValues.push(
    formatStateItemAsOption(
      new StateItem({
        id: 'values',
        path: 'OTHER.empty',
        source: DERIVED,
        dataType: INTEGER,
        display: getText('contentEditor.values.OTHER.empty'),
      }),
    ),
  );

  return rawValues;
};

export const expandDataTypes = (dataType: any) => {
  switch (dataType) {
    case DECIMAL:
    case INTEGER:
    case TEXT:
      return [DECIMAL, INTEGER, TEXT];
    default:
      return [dataType];
  }
};

export const getDataTypeOptionsOfType = (
  project: any,
  elementPath: any,
  dataType = PRIMITIVE_DATA_TYPES,
  {
    includeSelf,
    includeUniqueColumnar,
    acceptableParentType,
    onlyIncludeSelf,
  }: any = {},
) =>
  getDataTypeOptionsOfTypes(project, elementPath, expandDataTypes(dataType), {
    includeSelf,
    acceptableParentType,
    includeUniqueColumnar,
    onlyIncludeSelf,
  });

export const getAuthUserTypeOptionsOfTypes = (
  project: any,
  acceptableDataTypes = PRIMITIVE_DATA_TYPES,
  {
    acceptableParentType,
    includeCollections,
    includeUniqueColumnar = false,
  }: any = {},
) => {
  return getTypeOptionsOfTypeFromParent(
    project.dataTypes,
    new StateItem({
      dataType: 'user',
      id: AUTH_WRAPPER_ID,
      path: '',
      source: DATABASE,
      display: getText('elements.PAGE.state.user'),
    }),
    acceptableDataTypes,
    { includeCollections, acceptableParentType, includeUniqueColumnar },
  );
};

export const getDataTypeOptionsOfTypes = (
  project: Project,
  elementPath: ElementPath,
  acceptableDataTypes = PRIMITIVE_DATA_TYPES,
  {
    acceptableParentType,
    includeCollections,
    includeSelf,
    onlyIncludeSelf = false,
    includeUniqueColumnar = false,
    flatRawValuesOnly = false,
    fieldFilter = defaultFieldFilter,
    forceRawPath,
  }: any = {},
) => {
  const dataTypes = project.dataTypes;
  const context = {
    dataTypes,
    getDataTypeOptions: (scopeItem: any) =>
      getTypeOptionsOfTypeFromParent(
        dataTypes,
        scopeItem,
        acceptableDataTypes,
        {
          includeCollections,
          acceptableParentType,
          includeUniqueColumnar,
          fieldFilter,
          forceRawPath,
        },
      ),
    acceptableDataTypes,
    includeCollections,
  };
  const scope = buildScope(
    project,
    elementPath,
    includeSelf || onlyIncludeSelf,
    onlyIncludeSelf,
    context,
  );

  if (onlyIncludeSelf || flatRawValuesOnly) {
    if (scope) {
      if (scope.options) {
        return scope.options;
      }

      return Array.isArray(scope) ? scope : [scope];
    }

    return [];
  }

  const optionGroups = [];

  if (scope.length > 0) {
    optionGroups.push({
      label: getText('contentEditor.groups.data'),
      options: scope,
      heading: true,
    });
  }

  const rawValues = getRawValueOptionFromDataTypes(acceptableDataTypes);

  if (rawValues.length > 0) {
    optionGroups.push({
      label: getText('contentEditor.groups.values'),
      options: rawValues,
      heading: true,
    });
  }

  return optionGroups;
};

export const getDataItemType = (dataItem = {}) => (dataItem as any).dataType;

export const formatBooleanValue = (value: any) => {
  if (value === true || value === false) {
    return value;
  }

  if (value && String(value).toLowerCase() === 'true') {
    return true;
  }
  if (value && String(value).toLowerCase() === 'false') {
    return false;
  }

  return Boolean(value);
};

export const formatValueForField = (
  value: any,
  field: any,
  display = false,
  displayOptions = {},
) => {
  if (isNil(value)) {
    return null;
  }

  const { type, options = [] } = field;

  if (type === DATE) {
    const dateFieldFormat = get(field, 'typeOptions.format');
    const timeZone = get(field, 'typeOptions.timeZone');

    let parsedDate = getDateFromValue(value);
    const isDateOnly = dateFieldFormat === DATE_FORMAT;

    if (!parsedDate) {
      return null;
    }

    if (!display) {
      return parsedDate.toJSDate();
    }

    if (isDateOnly) {
      parsedDate = parsedDate.toUTC();
    }

    if (timeZone) {
      parsedDate = parsedDate.setZone(timeZone);
    }

    const defaultFormat =
      dateFieldFormat === DATE_FORMAT
        ? DateTime.DATE_SHORT
        : DateTime.DATETIME_SHORT;

    // @ts-expect-error TS(2339): Property 'options' does not exist on type '{}'.
    const { options: { format = defaultFormat } = {} } = displayOptions;
    if (format === ISO) {
      return parsedDate.toISO();
    }
    return parsedDate.toLocaleString(format);
  }

  if (type === DURATION) {
    const parsedDuration = getDurationFromString(value);

    if (!parsedDuration || !parsedDuration.isValid) {
      return null;
    }

    if (!display) {
      return parsedDuration;
    }

    // @ts-expect-error TS(2339): Property 'options' does not exist on type '{}'.
    const { options: { format = HOURS_MINUTES } = {} } = displayOptions;
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    return parsedDuration.toLocaleString(format);
  }

  if (type === BOOLEAN) {
    const formattedValue = formatBooleanValue(value);

    if (display) {
      return getText('values.BOOLEAN', formattedValue);
    }
    return formattedValue;
  }

  if (type === DECIMAL) {
    return parseFloat(value);
  }

  if (type === INTEGER) {
    return parseInt(value, 10);
  }

  if (type === SINGLE_OPTION) {
    const option = options.find(
      (op: any) => op.name === value || op.display === value,
    );
    if (!option) {
      return null;
    }

    return display ? option.display : option.name;
  }

  if (type === MULTIPLE_OPTION) {
    const splitValue = String(value)
      .split(',')
      .map((s) => s.trim())
      .filter(Boolean);

    const valueOptions = options.filter(
      (op: any) =>
        splitValue.includes(op.name) || splitValue.includes(op.display),
    );

    if (display) {
      return valueOptions.map((o: any) => o.display).join(', ');
    }

    return valueOptions.map((o: any) => o.name);
  }

  return String(value);
};

const findOptionFieldByValuePath = (
  dataTypes: any,
  valuePath: any,
  optionType = SINGLE_OPTION,
) => {
  for (let i = 0; i < dataTypes.length; i++) {
    const dataType = dataTypes[i];
    for (let j = 0; j < dataType.fields.length; j++) {
      const field = dataType.fields[j];
      if (last(valuePath) === field.name && field.type === optionType) {
        return { field, dataType };
      }
    }
  }
  return {};
};

export const formatValue = (
  value: any,
  dataItemType: any,
  dataItem = {},
  rawValue = true,
  dataTypes: any,
  valuePath = [],
) => {
  if (dataItemType === DATE) {
    if (rawValue) {
      return value;
    }

    if (value) {
      const parsedDate = getDateFromValue(value);
      if (parsedDate) {
        // @ts-expect-error TS(2339): Property 'options' does not exist on type '{}'.
        const { options: { format = DATETIME_MED } = {} } = dataItem;
        if (format === ISO) {
          return parsedDate.toISO();
        }
        return parsedDate.toLocaleString(format);
      }
    }
  }

  if (dataItemType === DURATION) {
    if (rawValue) {
      return value;
    }

    if (value) {
      const parsedDuration = getDurationFromString(value);
      // @ts-expect-error TS(2576): Property 'invalid' does not exist on type 'Duratio... Remove this comment to see the full error message
      if (parsedDuration && !parsedDuration.invalid) {
        // @ts-expect-error TS(2339): Property 'options' does not exist on type '{}'.
        const { options: { format = HOURS_MINUTES } = {} } = dataItem;
        // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
        return parsedDuration.toLocaleString(format);
      }
    }
  }

  if (dataItemType === BOOLEAN) {
    const formattedValue = formatBooleanValue(value);
    if (!rawValue) {
      return getText('values.BOOLEAN', formattedValue);
    }
    return formattedValue;
  }

  if (dataItemType === DECIMAL) {
    if (isNil(value)) {
      return null;
    }
    return parseFloat(value);
  }

  if (dataItemType === INTEGER) {
    if (isNil(value)) {
      return null;
    }
    return parseInt(value, 10);
  }

  if (dataItemType === SINGLE_OPTION) {
    if (rawValue || !value) {
      return value;
    }
    if (dataTypes) {
      const { field } = findOptionFieldByValuePath(
        dataTypes,
        valuePath,
        SINGLE_OPTION,
      );
      if (field) {
        const option = field.options.find(({ name }: any) => name === value);
        if (option) {
          return option.display;
        }
      }
    }
  }

  if (dataItemType === MULTIPLE_OPTION) {
    if (rawValue || !value) {
      return value;
    }
    if (dataTypes) {
      const { field } = findOptionFieldByValuePath(
        dataTypes,
        valuePath,
        MULTIPLE_OPTION,
      );
      if (field) {
        const options = field.options.filter(({ name }: any) =>
          value.includes(name),
        );

        return options.map((option: any) => option.display).join(', ');
      }
    }
  }

  if (dataItemType !== ARRAY) {
    if (value && Array.isArray(value)) {
      if (value.length > 0 && typeof value[0] === 'object' && value[0].id) {
        return value.map(({ id }) => parseInt(id, 10));
      } else if (value.length === 0) {
        return undefined;
      }
    }

    if (value && typeof value === 'object' && value.id) {
      return value.id;
    }

    if (value && typeof value === 'object' && value.edges) {
      if (Array.isArray(value.edges)) {
        return value.edges
          .map((edge: RecordEdge) => edge.node?.id)
          .filter(Boolean)
          .map((id: string) => parseInt(id, 10));
      }
    }
  }

  if (!rawValue && Array.isArray(value)) {
    return value.join(', ');
  }

  return value === undefined ? null : value;
};

const getValueFromSources = (valuePath: any, dataSources: any) => {
  for (let i = 0; i < dataSources.length; i++) {
    const value = get(dataSources[i], valuePath);
    if (value !== undefined) {
      return value;
    }
  }

  return undefined;
};

export const isPrimitiveType = (dataType: any) =>
  PRIMITIVE_DATA_TYPES.includes(dataType);

export const getFullValuePath = (scopeItem: any) => {
  const { id, path } = scopeItem;
  return [id, path].filter(Boolean).join('.');
};

export const resolveSingleDataItem = (
  dataItem: any,
  scope: any,
  project: any,
  rawValues = false,
) => {
  if (!dataItem.data) {
    return null;
  }

  const dataItemType = getDataItemType(dataItem.data);

  const valuePathStr = getFullValuePath(dataItem.data);
  const valuePath = valuePathStr.split('.');
  const mappedValuePath = convertValuePathToApiPath(valuePath, project);
  const value = getValueFromSources(mappedValuePath, [scope]);
  if (value === undefined) {
    return undefined;
  }

  return formatValue(
    value,
    dataItemType,
    dataItem,
    rawValues,
    project.dataTypes,
    // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
    valuePath,
  );
};

export const resolveStyledContentItems = (
  dataValue: any,
  scope: any,
  project: any,
) => {
  return !Array.isArray(dataValue)
    ? dataValue
    : dataValue
        .map((dataItem) => {
          if (dataItem.text) {
            return dataItem;
          }

          if (dataItem.data) {
            const text = resolveSingleDataItem(dataItem, scope, project, false);
            return {
              text,
              styleProps: dataItem.styleProps,
            };
          }
          return null;
        })
        .filter((item) => item && item.text !== undefined && item.text !== null)
        .reduce(
          (spanAcc, { text = '', styleProps = {} }, index) => [
            ...spanAcc,
            ...String(text)
              .split('\n')
              .map((textPart, partIndex) => (
                <>
                  {partIndex !== 0 && <br />}
                  {textPart && (
                    <span
                      key={`${index}-${partIndex}-${textPart}`}
                      className={classNames(
                        getTailwindClassNames(
                          transformTailwindProps(styleProps),
                        ),
                      )}
                    >
                      {textPart}
                    </span>
                  )}
                </>
              )),
          ],
          [],
        );
};

const formatDataValueSegment = (
  dataItem: any,
  scope: any,
  project: any,
  rawValues: any,
) => {
  if (dataItem.text) {
    return dataItem.text;
  }

  if (dataItem.data) {
    return resolveSingleDataItem(dataItem, scope, project, rawValues);
  }
  return '';
};

const MAX_NUM_LENGTH = String(Number.MAX_SAFE_INTEGER).length;

export const resolveDataValue = (
  dataValue: any,
  scope: any,
  project: any,
  rawValues = false,
) => {
  if (!Array.isArray(dataValue)) {
    if (typeof dataValue === 'object') {
      return null;
    }
    return dataValue;
  }

  let value;
  if (dataValue.length === 1) {
    value = formatDataValueSegment(dataValue[0], scope, project, rawValues);
  } else {
    value = dataValue
      .map((dataItem) =>
        formatDataValueSegment(dataItem, scope, project, rawValues),
      )
      .join('');
  }

  if (!value) {
    return value;
  }

  const stringValue = String(value).trim();

  if (
    !isNaN(value) &&
    !Array.isArray(value) &&
    stringValue !== '' &&
    stringValue.length < MAX_NUM_LENGTH &&
    typeof value !== 'boolean' &&
    !stringValue.startsWith('+')
  ) {
    return parseFloat(value);
  }

  return value;
};

// @ts-expect-error TS(7023): 'getDataDepsFromValue' implicitly has return type ... Remove this comment to see the full error message
const getDataDepsFromValue = (value: any) => {
  if (!value || !Array.isArray(value)) {
    return [];
  }
  return value
    .filter(({ data }) => !!data)
    .filter(
      (item) =>
        item.data.source !== PAGE_FIELD &&
        isNotListDep(item.data) &&
        item.data.id,
    )
    .reduce((itemAcc, item) => {
      if (item.data.dataType === FORMULA) {
        return [...itemAcc, ...getDataDepsFromValue(item.formula)];
      }
      return [...itemAcc, item.data];
    }, []);
};

const isNotListDep = (value: any) =>
  !value.path || !value.path.startsWith('edges.node');

// @ts-expect-error TS(7023): 'getDataScopeDepsFromPropsShape' implicitly has re... Remove this comment to see the full error message
const getDataScopeDepsFromPropsShape = (props: any, propsShape: any) =>
  Object.entries(propsShape)
    .reduce((depsAcc, [propKey, propDefinition]) => {
      const propValue = props && props[propKey];
      if (!propValue && (propDefinition as any).type !== GROUP) {
        return depsAcc;
      }

      if ((propDefinition as any).extractPropTypesDependencies) {
        return (propDefinition as any).extractPropTypesDependencies(
          propValue,
          depsAcc,
          props,
          propsShape,
          null,
        );
      }

      switch ((propDefinition as any).type) {
        case RAW_DATA_PROP:
        case DATA_PROP: {
          return propValue.source !== PAGE_FIELD &&
            propValue.id &&
            isNotListDep(propValue)
            ? [...depsAcc, propValue]
            : depsAcc;
        }
        case ARRAY: {
          if (!Array.isArray(propValue)) {
            return depsAcc;
          }

          // @ts-expect-error TS(7022): 'nestedDeps' implicitly has type 'any' because it ... Remove this comment to see the full error message
          const nestedDeps = propValue.reduce(
            (nestedDepsAcc, nestedProps) => [
              ...nestedDepsAcc,
              ...getDataScopeDepsFromPropsShape(
                nestedProps,
                (propDefinition as any).shape,
              ),
            ],
            [],
          );
          return [...depsAcc, ...nestedDeps];
        }
        case VARIABLE: {
          // @ts-expect-error TS(7022): 'nestedDeps' implicitly has type 'any' because it ... Remove this comment to see the full error message
          const nestedDeps = getDataScopeDepsFromPropsShape(propValue, {
            label: new StringPropType(),
            value: (propDefinition as any).propType,
          });
          return [...depsAcc, ...nestedDeps];
        }
        case GROUP: {
          // @ts-expect-error TS(7022): 'nestedDeps' implicitly has type 'any' because it ... Remove this comment to see the full error message
          const nestedDeps = getDataScopeDepsFromPropsShape(
            props,
            (propDefinition as any).shape,
          );
          return [...depsAcc, ...nestedDeps];
        }
        case COMBO: {
          // @ts-expect-error TS(7022): 'nestedDeps' implicitly has type 'any' because it ... Remove this comment to see the full error message
          const nestedDeps = getDataScopeDepsFromPropsShape(
            propValue,
            (propDefinition as any).shape,
          );
          return [...depsAcc, ...nestedDeps];
        }
        case KEY_MAP: {
          if (typeof propValue === 'object') {
            return Object.values(propValue).reduce(
              (acc, nestedPropValue) => [
                // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
                ...acc,
                ...getDataScopeDepsFromPropsShape(
                  nestedPropValue,
                  (propDefinition as any).shape,
                ),
              ],
              depsAcc,
            );
          }

          return depsAcc;
        }
        case STRING:
          return [...depsAcc, ...getDataDepsFromValue(propValue)];
        default:
          return depsAcc;
      }
    }, [])
    .filter(Boolean);

export const getDataScopeDeps = (element: any) => {
  if (!element) {
    return [];
  }

  const { props: propsConfig = {} } =
    elementsConfig[element.type] || new ElementConfig();
  if (!element.props && !element.conditions) {
    return [];
  }

  return [
    ...getDataScopeDepsFromPropsShape(element.props, propsConfig),
    ...getConditionDataItems(element.conditions),
    ...getVisibilityCustomRulesDataItems(
      get(element, 'visibilityRules.customRules', []),
    ),
    ...getActionDataItems(element.actions),
  ];
};

export const getAdditionalFieldsForForm = (
  fields: any,
  type = elements.FORM_SECTION,
) =>
  getDataScopeDeps({
    type,
    props: { fields },
  })
    .filter((dep) => dep.id === RECORD_SCOPE)
    .reduce((acc, dep) => {
      if (dep.source === DATABASE && dep.path.includes('.')) {
        const [dataType, field] = dep.path.split('.');
        const existingEntry = acc[dataType] ?? {};
        if (!existingEntry[field]) {
          return {
            ...acc,
            [dataType]: { ...existingEntry, [field]: true },
          };
        }
      }
      return acc;
    }, {});

export const formatWhereConditionField = (field: any, result: any) => {
  if (
    (field.type === INTEGER || field.type === DECIMAL) &&
    !isNil(result) &&
    !Array.isArray(result)
  ) {
    return parseFloat(result);
  }

  return result;
};

export const findFieldFromFilterName = (
  fieldFilterName: string,
  dataType: DataType | undefined,
) =>
  dataType &&
  dataType.fields.find((field) => {
    if (field.relationship) {
      return `${field.name}Id` === fieldFilterName;
    }

    if (field.relatedField) {
      return `${field.relatedField.reverseName}Id` === fieldFilterName;
    }

    return field.name === fieldFilterName;
  });

export const formatWhereCondition = (whereCondition: any, dataType: any) => {
  const { field: fieldName, operator, result, branches } = whereCondition;

  if (operator === OR) {
    return {
      field: OR,
      operator: OR,
      branches: ensureArray(branches).map((branch: any) => branch.filters),
    };
  }

  const field = findFieldFromFilterName(fieldName, dataType);

  if (!field) {
    return null;
  }
  return {
    field: fieldName,
    operator,
    ...(getInputTypeForOperator(operator)
      ? {
          result: formatWhereConditionField(
            field,
            getResultForOperator(
              operator,
              result !== '' ? result : undefined,
              field,
            ),
          ),
        }
      : {}),
  };
};

export const filterWhereCondition = (whereCondition: any) => {
  if (!whereCondition) {
    return false;
  }
  const { operator, result, branches } = whereCondition;
  return (
    (operator === OR && Array.isArray(branches)) ||
    !shouldOperatorHaveValue(operator) ||
    !isNil(result)
  );
};

const getEquivalentFilter = (operator: Operator, result: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const equivalentFilter = filters[operator];
  if (equivalentFilter) {
    return { equivalentFilter, not: false, result };
  }

  switch (operator) {
    case TRUE:
      return { equivalentFilter: filters.EQUAL, not: false, result: true };
    case FALSE:
      return { equivalentFilter: filters.EQUAL, not: false, result: false };
    case EMPTY:
      return { equivalentFilter: filters.EQUAL, not: false, result: null };
    case NOT_EMPTY:
      return { equivalentFilter: filters.EQUAL, not: true, result: null };
    case NOT_EQUAL:
      return { equivalentFilter: filters.EQUAL, not: true, result };
    case DOES_NOT_CONTAIN:
      return { equivalentFilter: filters.CONTAINS, not: true, result };
    case AFTER:
      return { equivalentFilter: filters.GREATER, not: false, result };
    case BEFORE:
      return { equivalentFilter: filters.LESS, not: false, result };
    case AFTER_OR_EQUAL:
      return { equivalentFilter: filters.GREATER_OR_EQUAL, not: false, result };
    case BEFORE_OR_EQUAL:
      return { equivalentFilter: filters.LESS_OR_EQUAL, not: false, result };
    default:
      return { equivalentFilter: null, not: false, result };
  }
};

const formatFilter = (filter: any, existingWhere = {}) => {
  const { field } = filter;

  const { equivalentFilter, not, result } = getEquivalentFilter(
    filter.operator,
    filter.result,
  );

  if (!equivalentFilter) {
    return existingWhere;
  }

  return {
    [field]: mergeFilterIntoWhere(existingWhere, equivalentFilter, not, result),
  };
};

// @ts-expect-error TS(7006): Parameter 'where' implicitly has an 'any' type.
const reduceFilters = (where, filter) => ({
  ...formatFilter(filter, where),
});

const reduceOrFilter = (where: any, dataType: any, orFilter: any) => {
  const mergedOr: any = {
    OR: ensureArray(orFilter.branches)
      .map((branch: any) => formatFilterAndMergeCustomFilters(branch, dataType))
      .filter((filter: any) => Object.keys(filter).length > 0),
  };

  if (mergedOr.OR.length > 0) {
    return { ...where, ...mergedOr };
  }

  return where;
};

export const formatFilterAndMergeCustomFilters = (
  customFilters: any[],
  dataType: any,
  currentWhere = {},
) =>
  ensureArray(customFilters)
    .map((customFilter: any) => formatWhereCondition(customFilter, dataType))
    .filter(filterWhereCondition)
    .reduce((whereAcc: any, filter: any) => {
      if (filter.operator === OR) {
        return reduceOrFilter(whereAcc, dataType, filter);
      }

      const currentWhereForField = get(whereAcc, [filter.field], {});

      const mergedValue = [filter].reduce(reduceFilters, currentWhereForField);

      return { ...whereAcc, ...mergedValue };
    }, currentWhere);

// @ts-expect-error TS(7023): 'reduceQueryObjectToDeps' implicitly has return ty... Remove this comment to see the full error message
export const reduceQueryObjectToDeps = (
  id: any,
  rootPath: any,
  queryObject: any,
) =>
  // @ts-expect-error TS(2769): No overload matches this call.
  Object.entries(queryObject).reduce((acc, [key, value]) => {
    if (value === true) {
      return [
        ...acc,
        // @ts-expect-error TS(2345): Argument of type '{ id: any; path: any; dataType: ... Remove this comment to see the full error message
        new StateItem({
          id: id,
          path: safelyAppendPath(rootPath, key),
          dataType: TEXT,
          source: DATABASE,
        }),
      ];
    }

    return [
      ...acc,
      ...reduceQueryObjectToDeps(id, safelyAppendPath(rootPath, key), value),
    ];
  }, []);

export const appendInitialDepPath = (depPath: any, dep: any) => {
  if (!dep) {
    return depPath;
  }

  const initalDepPath = dep.path
    .replace(/^edges\.node\./, '')
    .split('.')
    .slice(0, -1);

  if (initalDepPath.length === 0) {
    return depPath;
  }

  return `${depPath}${initalDepPath.join('.')}.`;
};

const getFieldsFromActionButton = (actionButton: any) => {
  const { actions = [] } = actionButton;

  return actions
    .filter((action: any) =>
      isIframeFieldAction(action) ? action.iframe.field : action.field,
    )
    .map((action: any) => ({
      name: isIframeFieldAction(action) ? action.iframe.field : action.field,
    }));
};

export const extractFieldsFromActionButtons = (actionButtons: any) =>
  actionButtons.reduce(
    (fieldsAcc: any, actionButton: any) => [
      ...fieldsAcc,
      ...getFieldsFromActionButton(actionButton),
    ],
    [],
  );

export const generateDepForField = (
  // @ts-expect-error TS(7006): Parameter 'elementId' implicitly has an 'any' type... Remove this comment to see the full error message
  elementId,
  // @ts-expect-error TS(7006): Parameter 'prefix' implicitly has an 'any' type.
  prefix,
  // @ts-expect-error TS(7006): Parameter 'field' implicitly has an 'any' type.
  field,
  // @ts-expect-error TS(7006): Parameter 'pathPrefix' implicitly has an 'any' typ... Remove this comment to see the full error message
  pathPrefix,
  // @ts-expect-error TS(7006): Parameter 'subFieldPath' implicitly has an 'any' t... Remove this comment to see the full error message
  subFieldPath,
) => ({
  id: elementId,
  path: `${prefix}${field.name}${pathPrefix}.${subFieldPath}`,
});

export const getDepsForField = (
  field: any,
  dataTypes: any,
  elementId: any,
  prefix = '',
) => {
  if (!field) {
    return [];
  }

  if (field.relationship || field.relatedField) {
    const relatedType = dataTypes.getByName(field.type);
    if (relatedType) {
      const pathPrefix = isMultiField(field) ? '.edges.node' : '';

      if (field.type === FILE) {
        return relatedType.fields
          .filter((relatedField: any) => !relatedField.relationship)
          .map((relatedField: any) =>
            generateDepForField(
              elementId,
              prefix,
              field,
              pathPrefix,
              relatedField.apiName,
            ),
          );
      }

      const { imageField, textFields } = findPreviewFields(
        relatedType.fields,
        relatedType,
      );

      const relatedFieldDeps = [
        'id',
        'uuid',
        ...textFields.map((field) => field.apiName),
      ].map((relatedFieldName) =>
        generateDepForField(
          elementId,
          prefix,
          field,
          pathPrefix,
          relatedFieldName,
        ),
      );

      if (imageField && !isMultiField(imageField)) {
        relatedFieldDeps.push(
          generateDepForField(
            elementId,
            prefix,
            field,
            pathPrefix,
            `${imageField.apiName}.url`,
          ),
        );
      }

      return relatedFieldDeps;
    }
  } else {
    if (isOptionType(field.type) && field.options.length === 0) {
      return [];
    }

    return [
      {
        id: elementId,
        path: `${prefix}${field.name}`,
        source: DATABASE,
        dataType: field.type,
      },
    ];
  }

  return [];
};

export const getDepsForConditions = (
  conditions: any,
  dataType: any,
  dataTypes: any,
  elementId: any,
  prefix = '',
) =>
  ensureArray(conditions).reduce(
    (acc: any, andCondition: any) =>
      ensureArray(andCondition).reduce((subAcc: any, condition: any) => {
        if (!condition.field || !condition.field.path) {
          return subAcc;
        }

        const splitPath = condition.field.path.split('.');

        const lastFieldInPath = getFieldFromDependency(
          splitPath,
          dataType,
          dataTypes,
        );

        if (lastFieldInPath) {
          const appendPeriod = splitPath.length > 1;
          const fieldPathPrefix = `${initial(splitPath).join('.')}${
            appendPeriod ? '.' : ''
          }`;

          const depsForField = getDepsForField(
            lastFieldInPath,
            dataTypes,
            elementId,
            safelyAppendPath(prefix, fieldPathPrefix),
          );

          return [...subAcc, ...depsForField];
        }

        return subAcc;
      }, acc),
    [],
  );

export const getDateForFilter = (
  dateValue: any,
  dateFieldFormat: any,
  timeZone: any,
) => {
  if (typeof dateValue === 'string' && dateValue.includes('T')) {
    if (dateFieldFormat === DATE_FORMAT) {
      // Return only the date portion of the date time
      return dateValue.split('T')[0];
    }

    if (timeZone === 'UTC') {
      // Replace any Timezone information with Z offset timezone
      return dateValue.replace(/(Z|([+-]\d\d:\d\d))$/, 'Z');
    }
  }

  return dateValue;
};

const formatRawValueForComparison = (
  rawValue: any,
  conditionField: any,
  dataType: any,
  project: any,
) => {
  if (!dataType || !rawValue || !conditionField) {
    return rawValue;
  }

  const splitPath = conditionField.path.split('.');
  const field = getFieldFromDependency(splitPath, dataType, project.dataTypes);

  if (field) {
    if (field.type === DATE) {
      const dateFieldFormat = get(field, 'typeOptions.format');
      const timeZone = get(field, 'typeOptions.timeZone');
      const rawDateValue = getDateForFilter(
        rawValue,
        dateFieldFormat,
        timeZone,
      );
      const dtFromInput = getDateFromValue(rawDateValue, {
        zone: timeZone,
      });

      if (dateFieldFormat === DATE_FORMAT || timeZone) {
        return dtFromInput
          ? dtToUTCMaintainWallTime(
              dtFromInput,
              dateFieldFormat !== DATE_FORMAT,
            )
          : null;
      }

      return dtFromInput
        ? dtToLocalTZMaintainWallTime(dtFromInput, true)
        : null;
    }

    if (
      (field.relationship || field.relatedField) &&
      typeof rawValue === 'object' &&
      !Array.isArray(rawValue)
    ) {
      if (isMultiField(field)) {
        return get(rawValue, 'edges', []).map((edge: any) => edge.node.id);
      } else {
        return get(rawValue, 'id');
      }
    }
  }

  return rawValue;
};

export const conditionIsTrue = (
  condition: any,
  scope: any,
  project: any,
  dataType: any,
) => {
  let conditionFieldValue = resolveSingleDataItem(
    { data: condition.field, options: {} },
    scope,
    project,
    true,
  );
  let conditionValue = resolveDataValue(condition.value, scope, project, true);

  const formattedConditionFieldValue = formatRawValueForComparison(
    conditionFieldValue,
    condition.field,
    dataType,
    project,
  );

  const formattedConditionValue = formatRawValueForComparison(
    conditionValue,
    condition.field,
    dataType,
    project,
  );

  const result = compareValues(
    formattedConditionFieldValue,
    condition.operator,
    formattedConditionValue,
  );

  return {
    condition,
    resolvedValues: {
      fieldValue: formattedConditionFieldValue,
      value: formattedConditionValue,
    },
    result,
  };
};

export const conditionsAreMet = (
  conditions: any,
  scope: any,
  project: any,
  dataType: any,
) => {
  if (!conditions) {
    return true;
  }

  return ensureArray(conditions).some((andConditions: any) =>
    ensureArray(andConditions).every(
      (condition: any) =>
        conditionIsTrue(condition, scope, project, dataType).result,
    ),
  );
};

export const failedConditions = (
  andConditions: any,
  scope: any,
  project: any,
  dataType: any,
) => {
  if (!andConditions) {
    return [];
  }

  return ensureArray(andConditions)
    .map((condition: any) => {
      const result = conditionIsTrue(condition, scope, project, dataType);

      if (result.result) {
        return null;
      } else {
        return result;
      }
    })
    .filter(Boolean);
};
