import React from 'react';

const CalendarLabel = ({ label }: any) => {
  return (
    <div className="py-3 text-gray-600 tracking-wider font-medium text-xs">
      {label}
    </div>
  );
};

export default CalendarLabel;
