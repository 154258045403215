import React, { forwardRef } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import * as Icons from '@tabler/icons-react';

const DEFAULT_ICON_NAME = 'Bulb';

export type IconValue = {
  name: string;
  hidden?: boolean;
};

const iconLookup: Record<string, any> = Object.entries(Icons).reduce(
  (acc, [key, value]) => ({ ...acc, [key]: value }),
  {},
);

const Icon = forwardRef(
  (
    {
      className,
      icon,
      onClick,
      size,
      style,
    }: {
      className: string;
      icon: IconValue | undefined;
      onClick?: ((arg: Event) => any) | undefined;
      size?: string | number;
      style?: string;
    },
    ref,
  ) => {
    const { name = DEFAULT_ICON_NAME } = icon || {};
    const indexableName = `Icon${name}`;

    const IconComponent = iconLookup[indexableName];
    if (!name || !IconComponent) {
      return null;
    }

    return (
      <Box
        is="span"
        className={className}
        block={true}
        onClick={onClick}
        ref={ref}
        style={style}
      >
        <IconComponent width="100%" size={size} height="100%" />
      </Box>
    );
  },
);

Icon.defaultProps = {
  className: '',
  icon: { name: DEFAULT_ICON_NAME },
};

export default Icon;
