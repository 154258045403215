import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { dtToLocalTZMaintainWallTime } from '@noloco/components';
import { formatLocalDateTimeResult } from '@noloco/components/src/components/input/DatePickerPopover';
import useLocale, {
  useLocales,
} from '@noloco/components/src/utils/hooks/useLocale';
import { Views, dateFnsLocalizer } from '@noloco/react-big-calendar';
import { UPDATE } from '../../../constants/actionTypes';
import { GANTT, TIMELINE } from '../../../constants/collectionLayouts';
import { DATE } from '../../../constants/dataTypes';
import { DATE as DATE_FORMAT } from '../../../constants/fieldFormats';
import { DataField } from '../../../models/DataTypeFields';
import { RecordEdge } from '../../../models/Record';
import { GroupByWithField } from '../../../models/View';
import { getMutationQueryString } from '../../../queries/project';
import { queryStateSelector } from '../../../selectors/queriesSelectors';
import { updateCacheItem } from '../../../utils/apolloCache';
import { lookupOfArray } from '../../../utils/arrays';
import { getColorByIndex } from '../../../utils/colors';
import { formatDisplayField } from '../../../utils/dataTypes';
import { getPrimaryField, sortOptions } from '../../../utils/fields';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useRouter from '../../../utils/hooks/useRouter';
import CollectionCalendar from './CollectionCalendar';
import CollectionTimeline from './CollectionTimeline';

type DateValues = {
  end?: Date;
  endRawIso?: string | null;
  hasEnd?: boolean;
  start?: Date;
  startRawIso?: string | null;
};

type EventUpdate = {
  event: Event;
  start: Date;
  end: Date;
  groupBy?: { field: any; color: string };
}[];

export type ID = string | number;

export type Dependent = {
  id: ID;
  start: number;
  end: number;
};

export type Event = {
  id: string;
  start: Date;
  startRawIso: Date;
  hasEnd: boolean;
  end: Date;
  endRawIso: Date;
  title: string;
  record: any;
  rootPathname: string;
  dateStartField: DataField;
  dateEndField: DataField;
  color: string;
  index: number;
  event?: EventUpdate[];
  predecessors?: Dependent[];
  successors?: Dependent[];
  itemProps?: any;
};

const defaultViews = [Views.MONTH, Views.WEEK, Views.WORK_WEEK, Views.DAY];

const getDateValue = (
  edge: RecordEdge,
  dateField: DataField | null,
): Date | null => {
  if (!dateField) {
    return null;
  }

  const rawDateString = get(edge, ['node', dateField.apiName])?.toString();

  if (!rawDateString) {
    return null;
  }

  let dateTime = DateTime.fromISO(rawDateString);

  if (!dateTime.isValid) {
    return null;
  }

  const format = get(dateField, 'typeOptions.format');
  const timeZone = get(dateField, 'typeOptions.timeZone');

  if (format === DATE_FORMAT) {
    dateTime = dateTime.toUTC();
    dateTime = dtToLocalTZMaintainWallTime(dateTime, false);
  } else if (timeZone) {
    dateTime = dateTime.setZone(timeZone);
    dateTime = dtToLocalTZMaintainWallTime(dateTime, true);
  }
  return dateTime.toJSDate();
};

const getEventDateRawIso = (
  edge: RecordEdge,
  dateField: DataField,
): string | null => {
  if (!dateField) {
    return null;
  }

  const rawDateString = get(edge, ['node', dateField.apiName]) as string;

  if (!rawDateString) {
    return null;
  }

  return rawDateString;
};

const getDateValues = (
  edge: RecordEdge,
  dateStartField: DataField,
  dateEndField: DataField,
): DateValues => {
  const dates: DateValues = {};
  const startDate = getDateValue(edge, dateStartField);

  if (startDate) {
    dates.start = startDate;
    dates.startRawIso = getEventDateRawIso(edge, dateStartField);

    const endDate = getDateValue(edge, dateEndField);
    dates.hasEnd = !!endDate;
    if (endDate) {
      dates.end = endDate;
      dates.endRawIso = getEventDateRawIso(edge, dateEndField);
      const typeOptionsFormat = get(dateEndField, 'typeOptions.format');
      if (typeOptionsFormat === DATE_FORMAT) {
        dates.end = DateTime.fromJSDate(endDate).endOf('day').toJSDate();
      }
    } else {
      dates.end = DateTime.fromJSDate(startDate).plus({ hours: 1 }).toJSDate();
    }
  }

  return dates;
};

const getEventGroupColor = (node: any, groupByDep: any, groupColorMap: any) => {
  const groupByPath = groupByDep.path.replace(/^edges\.node\./g, '');
  const groupValue = get(node, groupByPath);
  return groupColorMap[groupValue];
};

const formatDateValueForUpdate = (date: Date, dateField: DataField) =>
  formatLocalDateTimeResult(
    DateTime.fromJSDate(date),
    dateField?.typeOptions?.format !== DATE_FORMAT,
    dateField?.typeOptions?.timeZone,
  ).toISO();

const CollectionEvents = ({
  calendarView,
  enableDragAndDropEdit,
  canUpdate,
  className,
  date,
  dataType,
  dateStart,
  dateStartField,
  dateEndField,
  elementId,
  ganttDependency,
  edges,
  groupByFields,
  loading,
  nodeQueryObject,
  project,
  qsSuffix,
  recordTitleField,
  rootPathname,
  Row,
  rowLink,
  layout,
  theme,
}: any) => {
  const locale = useLocale();
  const locales = useLocales();
  const [isDarkModeEnabled] = useDarkMode();
  const { query, variables } = useSelector(queryStateSelector(elementId)) || {};

  const {
    pushQueryParams,
    // @ts-expect-error TS(2537): Type '{}' has no matching index signature for type... Remove this comment to see the full error message
    query: { [`_view${qsSuffix}`]: viewParam },
  } = useRouter();

  const [view, setView] = useState(viewParam);

  // This is required to "stage" changes to the view
  // because sometimes view and date changes get triggered at the same time
  useEffect(() => {
    if (viewParam !== view) {
      pushQueryParams({ [`_view${qsSuffix}`]: view });
    }
  }, [pushQueryParams, qsSuffix, view, viewParam]);

  const groupByField: GroupByWithField | undefined = useMemo(
    () => (groupByFields.length > 0 ? groupByFields[0] : undefined),
    [groupByFields],
  );

  const groupByFieldColorMap = useMemo(() => {
    if (
      !groupByField ||
      !groupByField.dataField ||
      !groupByField.dataField.options
    ) {
      return {};
    }

    return sortOptions(groupByField.dataField!.options).reduce(
      (acc, option, index) => ({
        ...acc,
        [option.name]: option.color || getColorByIndex(index),
      }),
      {},
    );
  }, [groupByField]);

  const localizer = useMemo(
    () =>
      dateFnsLocalizer({
        format: (date: any, dateFormat: any) => {
          return format(date, dateFormat, { locale });
        },
        parse,
        startOfWeek: () => startOfWeek(new Date(), { locale }),
        getDay,
        locales,
      }),
    [locale, locales],
  );

  const titleField = useMemo(() => {
    if (!dataType) {
      return null;
    }

    if (recordTitleField) {
      const field = dataType.fields.getByName(recordTitleField);

      if (field && !field.relationship) {
        return field;
      }
    }

    return getPrimaryField(dataType);
  }, [dataType, recordTitleField]);

  const canUpdateEventDates = useMemo(
    () =>
      canUpdate &&
      !!dateStartField &&
      dateStart &&
      dateStartField.type === DATE &&
      dateStart.path.replace(/^edges\.node\./, '').split('.').length === 1,
    [canUpdate, dateStart, dateStartField],
  );

  const updateQueryString = useMemo(
    () =>
      gql`
        ${getMutationQueryString(
          UPDATE,
          dataType.name,
          dataType.fields,
          nodeQueryObject || {
            id: true,
            uuid: true,
          },
        )}
      `,
    [dataType.name, dataType.fields, nodeQueryObject],
  );

  const queryOptionsObj = useMemo(
    () => ({
      context: {
        projectQuery: true,
        projectName: project.name,
      },
    }),
    [project.name],
  );

  const [updateDataItem, { client }] = useMutation(updateQueryString, {
    fetchPolicy: 'no-cache',
    ...queryOptionsObj,
  });

  const [edgesState, setEdgesState] = useState<RecordEdge[]>(edges);
  useEffect(
    () =>
      setEdgesState((prevEdgesState) => {
        const edgeLookup = lookupOfArray(prevEdgesState, 'node.id');

        return [
          ...prevEdgesState,
          ...edges.filter((edge: RecordEdge) => !edgeLookup[edge.node.id]),
        ];
      }),
    [edges],
  );

  const events = useMemo(() => {
    if (loading) {
      return [];
    }

    return edgesState
      .map((edge: RecordEdge, index: number) => ({
        id: get(edge, 'node.id'),
        ...getDateValues(edge, dateStartField, dateEndField),
        title:
          (titleField &&
            formatDisplayField(
              titleField,
              get(edge, ['node', titleField.apiName]),
            )) ||
          get(edge, 'node.uuid'),
        record: edge.node,
        rootPathname,
        dateStartField,
        dateEndField,
        color:
          groupByField &&
          groupByField.field &&
          getEventGroupColor(
            edge.node,
            groupByField.field,
            groupByFieldColorMap,
          ),
        index,
      }))
      .filter((event) => event.id);
  }, [
    loading,
    edgesState,
    dateStartField,
    dateEndField,
    titleField,
    rootPathname,
    groupByField,
    groupByFieldColorMap,
  ]);

  const [draftEvents, setDraftEvents] = useState(events);
  useEffect(() => setDraftEvents(events), [events]);

  const getOriginalEventFromEdge = useCallback(
    (event) => {
      const edge = edgesState.find(
        (edge: RecordEdge) => edge.node.id === event.event.id,
      )?.node;
      const { start, end, groupBy } = event;

      return {
        ...edge,
        [dateStartField.apiName]: formatDateValueForUpdate(
          start,
          dateStartField,
        ),
        [dateEndField.apiName]: formatDateValueForUpdate(end, dateEndField),
        ...(groupBy ? { ...groupBy.field } : {}),
      };
    },
    [edgesState, dateStartField, dateEndField],
  );

  const updateEdges = useCallback(
    (events: EventUpdate) => {
      const updatedEdges = events.map((event) =>
        get(
          updateCacheItem(
            getOriginalEventFromEdge(event),
            client,
            gql`
              ${query}
            `,
            dataType.name,
            variables,
          ),
          dataType.name,
          null,
        ),
      );

      setEdgesState((edges: RecordEdge[]) =>
        edges.map((edge) => {
          const node = updatedEdges.find(
            (updatedEdge) => updatedEdge.id === edge.node.id,
          );

          return { ...edge, ...(node ? { node } : {}) };
        }),
      );
    },
    [getOriginalEventFromEdge, client, query, dataType.name, variables],
  );

  const updateEvents = useCallback(
    async (events: EventUpdate) => {
      if (!dateStartField) {
        return null;
      }

      updateEdges(events);
      Promise.all(
        events.map((eventUpdate) =>
          updateDataItem({
            variables: {
              id: eventUpdate.event.id,
              [dateStartField.apiName]: formatDateValueForUpdate(
                eventUpdate.start,
                dateStartField,
              ),
              ...(dateEndField
                ? {
                    [dateEndField.apiName]: formatDateValueForUpdate(
                      eventUpdate.end,
                      dateEndField,
                    ),
                  }
                : {}),
              ...(eventUpdate.groupBy ? { ...eventUpdate.groupBy.field } : {}),
            },
          }),
        ),
      );
    },
    [dateEndField, dateStartField, updateDataItem, updateEdges],
  );

  const updateEvent = useCallback(
    (event, start, end, _groupBy?: { field: any; color: string }) =>
      updateEvents([{ event, start, end, groupBy: _groupBy }]),
    [updateEvents],
  );

  const currentView = useMemo(() => view || calendarView || Views.MONTH, [
    view,
    calendarView,
  ]);

  if (layout === TIMELINE || layout === GANTT) {
    return (
      <CollectionTimeline
        canUpdateEventDates={canUpdateEventDates}
        currentView={currentView}
        date={date}
        dateEndField={dateEndField}
        dateStartField={dateStartField}
        defaultViews={defaultViews}
        draftEvents={draftEvents}
        enableDragAndDropEdit={enableDragAndDropEdit}
        dataType={dataType}
        ganttDependency={ganttDependency}
        groupByField={groupByField ? groupByField.dataField : undefined}
        isDarkModeEnabled={isDarkModeEnabled}
        layout={layout}
        loading={loading}
        localizer={localizer}
        project={project}
        qsSuffix={qsSuffix}
        Row={Row}
        rowLink={rowLink}
        setView={setView}
        theme={theme}
        updateEvent={updateEvent}
        updateEvents={updateEvents}
      />
    );
  }

  return (
    <CollectionCalendar
      canUpdateEventDates={canUpdateEventDates}
      className={className}
      currentView={currentView}
      date={date}
      dateStartField={dateStartField}
      defaultViews={defaultViews}
      draftEvents={draftEvents}
      enableDragAndDropEdit={enableDragAndDropEdit}
      loading={loading}
      localizer={localizer}
      project={project}
      qsSuffix={qsSuffix}
      Row={Row}
      rowLink={rowLink}
      setView={setView}
      updateEvent={updateEvent}
    />
  );
};

export default withTheme(CollectionEvents);
