import PRIMITIVE_DATA_TYPES from '../..//constants/primitiveDataTypes';
import { MULTIPLE_OPTION } from '../../constants/dataTypes';
import { getPreviewableFieldsQueryObject } from '../../queries/data';
import {
  getVisibilityCustomRulesDataItems,
  reduceQueryObjectToDeps,
} from '../../utils/data';
import { getDataListState } from '../../utils/elementState';

export const extractCustomRulesPropDeps = (prop: any, dependencies: any) => {
  const customRuleDataItems = getVisibilityCustomRulesDataItems(prop);

  dependencies = dependencies.concat(customRuleDataItems);

  return dependencies;
};

export const extractRawDataPropDeps = (
  prop: any,
  dataItems = [],
  __2: any,
  __3: any,
  __4: any,
  dataTypes: any,
) => {
  if (!dataTypes || !prop || !prop.dataType) {
    return dataItems;
  }

  if (
    PRIMITIVE_DATA_TYPES.includes(prop.dataType) ||
    prop.dataType === MULTIPLE_OPTION
  ) {
    return [...dataItems, prop];
  }

  const dataType = dataTypes.getByName(prop.dataType);

  if (!dataType) {
    return dataItems;
  }

  const previewFields = getPreviewableFieldsQueryObject(dataType.fields);

  return [
    ...dataItems,
    ...reduceQueryObjectToDeps(prop.id, prop.path, previewFields),
  ];
};

export const deriveListInputState = (
  { id, props }: any,
  project: any,
  elementPath: any,
  context: any,
) => {
  let state: any = [];

  state = state.concat(getDataListState(id, props.dataList, project, context));

  return state;
};
