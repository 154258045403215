import { uniq } from 'lodash';
import camelCase from 'lodash/camelCase';
import first from 'lodash/first';
import get from 'lodash/get';
import initial from 'lodash/initial';
import isArray from 'lodash/isArray';
import startCase from 'lodash/startCase';
import {
  ARRAY,
  CURSOR,
  Endpoint,
  EndpointDataField,
  EndpointDataFieldOption,
  EndpointDataFieldType,
  NESTED,
  OFFSET,
  PAGE,
} from '../constants/apis';
import dataTypes, {
  BOOLEAN,
  DATE,
  DECIMAL,
  DataFieldType,
  INTEGER,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
  TEXT,
} from '../constants/dataTypes';
import { GET, POST } from '../constants/endpointMethodTypes';
import { QUERY } from '../constants/endpointTypes';
import { UNFORMATTED_NUMBER } from '../constants/fieldFormats';
import PRIMITIVE_DATA_TYPES from '../constants/primitiveDataTypes';
import { API } from '../constants/scopeTypes';
import StateItem from '../models/StateItem';
import pascalCase from './pascalCase';
import { formatStateItemAsOption } from './state';

const getCollectionScopeFromDataShape = (
  id: string,
  endpoint: any,
  name: string,
  dataShape: any,
  dataPath: string[] = [],
): any[] | Record<any, any> | null => {
  if (Array.isArray(dataShape)) {
    if (dataShape.length > 0) {
      return formatStateItemAsOption(
        new StateItem({
          id,
          path: [...dataPath, name].join('.'),
          source: API,
          dataType: `ENDPOINT:${endpoint.id}`,
          display: name,
        }),
        [
          ...dataPath.map((pathKey) => ({ display: pathKey })),
          { display: name },
        ],
      );
    }
  }

  if (typeof dataShape === 'object') {
    const options = Object.entries(dataShape)
      .reduce((scopeAcc, [key, value]) => {
        const valueScopeItem = getCollectionScopeFromDataShape(
          id,
          endpoint,
          key,
          value,
          [...dataPath, name],
        );
        if (valueScopeItem) {
          return [...scopeAcc, valueScopeItem];
        }
        return scopeAcc;
      }, [] as any[])
      .filter(Boolean);

    if (dataPath.length === 0) {
      return options;
    }

    return {
      label: name,
      options,
    };
  }

  return null;
};

const getDataTypeScopeFromDataShape = (
  id: string,
  name: string,
  dataShape: any,
  acceptableDataTypes: DataFieldType[],
  dataPath: any[] = [],
): any[] | Record<any, any> | null => {
  if (Array.isArray(dataShape) && dataShape.length === 0) {
    return null;
  }

  if (typeof dataShape === 'object') {
    const options = Object.entries(dataShape)
      .reduce((scopeAcc, [key, value]) => {
        const valueScopeItem = getDataTypeScopeFromDataShape(
          id,
          key,
          value,
          acceptableDataTypes,
          [...dataPath, name],
        ) as Record<any, any>;
        if (valueScopeItem) {
          return [
            ...scopeAcc,
            {
              ...valueScopeItem,
              label: key === '0' ? 'first' : valueScopeItem.label,
            },
          ];
        }
        return scopeAcc;
      }, [] as any[])
      .filter(Boolean);

    if (dataPath.length === 0) {
      return options;
    }

    return {
      label: name,
      options,
    };
  }

  if (acceptableDataTypes.includes(dataShape)) {
    return formatStateItemAsOption(
      new StateItem({
        id,
        path: [...dataPath, name].join('.'),
        source: API,
        dataType: dataShape,
        display: name,
      }),
      [...dataPath.map((pathKey) => ({ display: pathKey })), { display: name }],
    );
  }

  return null;
};

export const getApiEndpointDataTypeOptions = (
  id: string,
  endpoint: any,
  acceptableDataTypes: DataFieldType[] = PRIMITIVE_DATA_TYPES,
  baseDataPath: any[] = [],
) => {
  const scope = [];

  if (acceptableDataTypes.includes(INTEGER)) {
    scope.push(
      new StateItem({
        id,
        path: 'status',
        source: API,
        display: 'Status',
        dataType: INTEGER,
      }),
    );
  }

  if (endpoint.dataShape) {
    const dataShapeScope = getDataTypeScopeFromDataShape(
      id,
      'data',
      endpoint.dataShape,
      acceptableDataTypes,
      baseDataPath,
    );
    if (dataShapeScope && Array.isArray(dataShapeScope)) {
      scope.push({
        label: 'Data',
        options: dataShapeScope.filter(Boolean),
      });
    } else if (dataShapeScope) {
      scope.push({
        label: 'Data',
        options: [dataShapeScope],
      });
    }
  }

  return scope;
};

export const getApiEndpointCollectionOptions = (id: string, endpoint: any) => {
  const scope = [];

  if (endpoint.dataShape) {
    const dataShapeScope = getCollectionScopeFromDataShape(
      id,
      endpoint,
      'data',
      endpoint.dataShape,
      [],
    );
    if (dataShapeScope && Array.isArray(dataShapeScope)) {
      scope.push({
        label: 'Data',
        options: dataShapeScope.filter(Boolean),
      });
    } else if (dataShapeScope) {
      scope.push({
        label: 'Data',
        options: [dataShapeScope],
      });
    }
  }

  return scope;
};

export const getApiEndpointQueryName = (api: any, endpoint: any) =>
  `${camelCase(api.name)}${pascalCase(endpoint.name)}`;

export const findEndpoint = (
  apis: any[],
  apiId: string,
  endpointId: string,
) => {
  if (!apiId || !endpointId) {
    return { api: null, endpoint: null };
  }

  const api = apis.find(({ id }) => id === apiId);

  if (!api) {
    return { api: null, endpoint: null };
  }

  return {
    api,
    endpoint: api.endpoints.find(({ id }: { id: string }) => id === endpointId),
  };
};

const getTypeForValue = (value: any): EndpointDataFieldType => {
  switch (typeof value) {
    case 'boolean':
      return BOOLEAN;
    case 'number':
      return Number.isInteger(value) ? INTEGER : DECIMAL;
    case 'string':
      if (Date.parse(value)) {
        return DATE;
      } else {
        return TEXT;
      }
    default:
      return TEXT;
  }
};

const filterToDefinedValues = (value: any) =>
  value !== undefined && value !== null;

const getTypeForValues = (values: any[]): EndpointDataFieldType => {
  const definedValues = values.filter(filterToDefinedValues);
  const firstDefinedValue = first(definedValues);

  if (firstDefinedValue === undefined) {
    return TEXT;
  }

  if (isArray(firstDefinedValue)) {
    return ARRAY;
  }

  if (typeof firstDefinedValue === 'object') {
    return NESTED;
  }

  return definedValues.map(getTypeForValue).reduce((acc, value) => {
    if (acc !== value) {
      return TEXT;
    }

    return acc;
  });
};

export const getPathBeyondId = (
  path: string[],
  idField: string | null | undefined,
) => {
  if (!idField) {
    return [];
  }

  const idPath = initial(idField.split('.'));

  return path.reduceRight((acc: string[], value, index) => {
    if (value !== get(idPath, [index])) {
      return [value, ...acc];
    }

    return acc;
  }, []);
};

const toTruthyStrings = (value: any) => !!value && typeof value === 'string';

export const getSampleOptions = (
  type: string,
  sample: any[],
): EndpointDataFieldOption[] | undefined => {
  if (!dataTypes.includes(type as DataFieldType)) {
    return;
  }

  if (type === SINGLE_OPTION) {
    return uniq(sample.filter(toTruthyStrings)).map((display: string) => ({
      display,
    }));
  }

  if (type === MULTIPLE_OPTION) {
    return sample
      .reduce((acc: string[], value: any) => {
        if (!isArray(value)) {
          return acc;
        }

        return uniq([...acc, ...value.filter(toTruthyStrings)]);
      }, [])
      .map((display: string) => ({ display }));
  }
};

export const buildFieldTypeOptions = (
  idField: string | null,
  field: EndpointDataField,
) => {
  switch (field.type) {
    case INTEGER:
      if (idField && field.path.join('.') === idField) {
        return { format: UNFORMATTED_NUMBER };
      } else {
        return null;
      }
    default:
      return null;
  }
};

export const buildFields = (
  path: string[],
  records: Record<string, any>[],
  idField?: string | null,
) => {
  const sampleRecord = first(records);
  if (!sampleRecord) {
    return null;
  }

  const displayPath = getPathBeyondId(path, idField);

  return Object.keys(sampleRecord).map(
    (key) =>
      ({
        apiName: key,
        display: [...displayPath, key].map(startCase).join(' > '),
        name: key,
        path: [...path, key],
        type: getTypeForValues(records.map((record) => get(record, key))),
      } as EndpointDataField),
  );
};

export const toInput = (endpoint: Endpoint) => ({
  body: endpoint.body,
  display: endpoint.display,
  headers: endpoint.headers.filter(({ key, value }) => !!key && !!value),
  method: endpoint.method,
  path: endpoint.path,
  pagination: endpoint.pagination,
  parameters: endpoint.parameters
    .filter(({ key, value }) => !!key && !!value)
    .map(({ key, value }) => ({
      name: key,
      testValue: value,
      dataType: TEXT,
    })),
  type: QUERY,
});

export const toPlaceholder = (placeholder: string) => `{{${placeholder}}}`;

export const placeholderExists = (endpoint: Endpoint) => (
  placeholder: string,
) =>
  endpoint.parameters.some(
    ({ value }) => value === toPlaceholder(placeholder),
  ) ||
  (endpoint.method !== GET &&
    endpoint.body &&
    endpoint.body.includes(toPlaceholder(placeholder)));

const findPaths = (predicate: (data: any) => boolean) => (
  data: any,
  path: string[] = [],
): string[][] => {
  if (!data) {
    return [];
  }

  if (predicate(data)) {
    return [path];
  }

  if (typeof data !== 'object' || isArray(data)) {
    return [];
  }

  return Object.entries(data)
    .map(([key, value]) => findPaths(predicate)(value, [...path, key]))
    .filter((childPath) => childPath !== null)
    .reduce((acc, childPath) => [...acc, ...childPath], []);
};

export const findArrayPaths = findPaths((data: any) => isArray(data));
export const findStringPaths = findPaths(
  (data: any) => typeof data === 'string',
);

const checkPaths = (
  path: string[],
  otherPath: string[],
  shortCircuit: (a: string[], b: string[]) => boolean,
) => {
  if (shortCircuit(path, otherPath)) {
    return false;
  }

  return path.every((element, index) => element === otherPath[index]);
};

const isPrefix = (path: string[], otherPath: string[]) =>
  checkPaths(path, otherPath, (a, b) => b.length <= a.length);
const isEqual = (path: string[], otherPath: string[]) =>
  checkPaths(path, otherPath, (a, b) => b.length !== a.length);

export const getExcludedFields = (
  includedFields: EndpointDataField[],
  records: any[] | null,
  path: string[] = [],
): EndpointDataField[] => {
  if (!records) {
    return [];
  }

  const fields = buildFields(path, records);
  if (!fields) {
    return [];
  }

  return fields.reduce((acc: EndpointDataField[], field: EndpointDataField) => {
    if (
      includedFields.some((includedField) =>
        isEqual(field.path, includedField.path),
      )
    ) {
      return acc;
    }

    const childIncludedFields = includedFields.filter((includedField) =>
      isPrefix(field.path, includedField.path),
    );
    if (childIncludedFields.length > 0) {
      return [
        ...acc,
        ...getExcludedFields(
          childIncludedFields,
          records.map((record) => get(record, [field.name])),
          field.path,
        ),
      ];
    }

    return [...acc, field];
  }, []);
};

export const isEndpointValid = (endpoint: Endpoint) => {
  if (!endpoint.display) {
    return false;
  }

  // Configuration
  if (!endpoint.path || (endpoint.path === POST && !endpoint.body)) {
    return false;
  }

  // Pagination
  if (endpoint.pagination === CURSOR && !placeholderExists(endpoint)(CURSOR)) {
    return false;
  }
  if (endpoint.pagination === OFFSET && !placeholderExists(endpoint)(OFFSET)) {
    return false;
  }
  if (
    endpoint.pagination === PAGE &&
    (!placeholderExists(endpoint)(PAGE) || endpoint.firstPage === null)
  ) {
    return false;
  }

  // Format
  if (!endpoint.idField || !endpoint.resultPath) {
    return false;
  }

  // Fields
  if (
    !endpoint.fields ||
    endpoint.fields.length === 0 ||
    endpoint.fields.some(({ type }) => type === ARRAY || type === NESTED)
  ) {
    return false;
  }

  return true;
};
