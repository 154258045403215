import React from 'react';
import { IconHelp } from '@tabler/icons-react';
import classNames from 'classnames';
import Popover from './Popover';

type OwnProps = {
  children: React.ReactNode;
  className?: string;
  placement?: string;
  size?: number;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof HelpTooltip.defaultProps;

// @ts-expect-error TS(7022): 'HelpTooltip' implicitly has type 'any' because it... Remove this comment to see the full error message
const HelpTooltip = ({ children, className, placement, size }: Props) => (
  <Popover
    placement={placement}
    trigger="hover"
    content={<div className="max-w-xs text-sm text-black p-1">{children}</div>}
  >
    <div className={classNames(className)}>
      <IconHelp size={size} />
    </div>
  </Popover>
);

HelpTooltip.defaultProps = {
  className: 'text-white hover:text-gray-500',
  placement: 'top',
  size: 16,
};

export default HelpTooltip;
