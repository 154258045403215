import React from 'react';
import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Button, DatePickerPopover, Loader, Popover } from '@noloco/components';
import { Navigate } from '@noloco/react-big-calendar';
import { darkModeColors } from '../../../../constants/darkModeColors';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import { getText } from '../../../../utils/lang';

const LANG_KEY = 'elements.COLLECTION.calendar';

const CalendarToolbar = ({
  date,
  label,
  localizer: { messages },
  loading,
  onNavigate,
  onView,
  view,
  views,
}: any) => {
  const [isDarkModeEnabled] = useDarkMode();
  return (
    <div className="px-8 mt-2 mb-4 flex items-center">
      <DatePickerPopover
        clearable={false}
        onChange={(val: any) => onNavigate(Navigate.DATE, val.toJSDate())}
        value={date ? DateTime.fromJSDate(date) : null}
      >
        {() => (
          <div className="group flex items-center">
            <h2 className="tracking-wider text-lg cursor-pointer">{label}</h2>
            <IconChevronDown
              className="opacity-50 ml-2 hidden group-hover:block"
              size={20}
            />
          </div>
        )}
      </DatePickerPopover>
      <div className="flex items-center ml-auto">
        <div className="flex items-center mr-4">
          {loading && <Loader className="mr-4" size="sm" />}
          <button
            className={`p-1.5 border rounded-tl-lg rounded-bl-lg ${
              isDarkModeEnabled
                ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.two} hover:${darkModeColors.surfaces.elevation2}`
                : 'bg-white border-gray-300 hover:bg-gray-100'
            }`}
            type="button"
            onClick={() => onNavigate(Navigate.PREVIOUS)}
            aria-label={messages?.previous}
          >
            <IconChevronLeft className="my-px opacity-75" size={15} />
          </button>
          <button
            className={`p-1.5 border border-l-0 rounded-tr-lg rounded-br-lg ${
              isDarkModeEnabled
                ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.two} hover:${darkModeColors.surfaces.elevation2}`
                : 'bg-white border-gray-300 hover:bg-gray-100'
            }`}
            type="button"
            onClick={() => onNavigate(Navigate.NEXT)}
            aria-label={messages?.next}
          >
            <IconChevronRight className="my-px opacity-75" size={15} />
          </button>
          <Button
            variant="secondary"
            className="flex items-center ml-2"
            onClick={() => onNavigate(Navigate.TODAY)}
            isDarkModeEnabled={isDarkModeEnabled}
          >
            <span>{getText(LANG_KEY, 'today')}</span>
          </Button>
        </div>
        <Popover
          closeOnClick={true}
          content={
            <div className="flex flex-col text-left w-40 p-2">
              {views.map((viewName: any) => (
                <button
                  className={classNames(
                    `px-4 py-3 rounded-lg text-left text-sm`,
                    {
                      [`hover:bg-gray-600 ${darkModeColors.text.primary}`]: isDarkModeEnabled,
                      'hover:bg-gray-200': !isDarkModeEnabled,
                    },
                  )}
                  key={viewName}
                  onClick={() => onView(viewName)}
                >
                  <span>{getText(LANG_KEY, 'views', viewName)}</span>
                </button>
              ))}
            </div>
          }
          className="overflow-hidden bg-white"
          p={0}
          rounded="lg"
          placement="bottom-end"
          shadow="lg"
          showArrow={false}
        >
          <Button variant="secondary" className="flex items-center">
            <span>{getText(LANG_KEY, 'views', view)}</span>
            <IconChevronDown className="ml-4 opacity-50" size={14} />
          </Button>
        </Popover>
      </div>
    </div>
  );
};

export default CalendarToolbar;
