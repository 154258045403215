import React, { forwardRef, useMemo, useState } from 'react';
import { Flex, NavToggle, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { HeaderNav } from '../navigation';
import { LEFT } from '../navigation/headerNavTypes';
import { CONSTRAINED, FULL_WIDTH } from './appLayoutTypes';

type Props = {
  className?: string;
  Logo?: React.ReactNode;
  ProfilePicture?: React.ReactNode;
  SearchInput?: React.ReactNode;
  Sidebar?: React.ReactNode;
  Nav?: React.ReactNode;
  screen?: boolean | string;
  type?: any; // TODO: PropTypes.oneOf(appLayoutTypes)
  navType?: any; // TODO: PropTypes.oneOf(headerNavTypes)
  theme: {};
};

const AppLayout = forwardRef<any, Props>(
  (
    {
      children,
      Logo,
      navType,
      // @ts-expect-error TS(2339): Property 'navColor' does not exist on type 'Props'... Remove this comment to see the full error message
      navColor,
      Nav,
      ProfilePicture,
      SearchInput,
      Sidebar,
      screen,
      type,
      theme,
      ...rest
    },
    ref,
  ) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebarOpen = () => setSidebarOpen((isOpen) => !isOpen);

    const body = useMemo(() => {
      switch (type) {
        case CONSTRAINED: {
          return (
            <div className="flex flex-grow justify-center overflow-auto">
              <div className="flex h-full sm:w-full md:w-11/12 w-3/5">
                {Sidebar && (
                  <div
                    className={classNames(
                      'flex w-56 md:mt-4 md:mr-0 mt-4 mr-4',
                      {
                        'md:hidden': !sidebarOpen,
                      },
                    )}
                  >
                    {/* @ts-expect-error TS(2604): JSX element type 'Sidebar' does not have any const... Remove this comment to see the full error message */}
                    <Sidebar />
                  </div>
                )}
                <div className="w-full md:mt-4">
                  <div className="mb:mb-4 w-full">{children}</div>
                </div>
              </div>
            </div>
          );
        }
        default:
        case FULL_WIDTH: {
          return (
            <div className="flex flex-grow h-full overflow-hidden">
              {Sidebar && (
                <div
                  className={classNames('flex w-64 overflow-auto shadow-md', {
                    'md:hidden': !sidebarOpen,
                  })}
                >
                  {/* @ts-expect-error TS(2604): JSX element type 'Sidebar' does not have any const... Remove this comment to see the full error message */}
                  <Sidebar />
                </div>
              )}
              <div className="flex flex-1 overflow-auto">{children}</div>
            </div>
          );
        }
      }
    }, [children, sidebarOpen, Sidebar, type]);

    const LogoNode = useMemo(
      () => () => (
        <div className="flex items-center">
          {Sidebar && (
            <NavToggle
              className="mr-2 hidden md:block"
              header={{ style: {}, onToggle: toggleSidebarOpen }}
            />
          )}
          {/* @ts-expect-error TS(2604): JSX element type 'Logo' does not have any construc... Remove this comment to see the full error message */}
          {Logo && <Logo />}
        </div>
      ),
      [Logo, Sidebar],
    );

    return (
      <Flex
        col={true}
        minH="screen"
        leading="normal"
        overflow="hidden"
        h="full"
        font={(theme as any).text.family.body}
        text={[
          (theme as any).text.size.body[1],
          (theme as any).textColors.body,
        ]}
        w="full"
        ref={ref}
        {...rest}
      >
        <HeaderNav
          className={`bg-${navColor}`}
          screen={screen}
          Nav={Nav}
          Logo={LogoNode}
          SearchInput={SearchInput}
          ProfilePicture={ProfilePicture}
          navType={navType}
        />
        {body}
      </Flex>
    );
  },
);

AppLayout.defaultProps = {
  className: '',
  screen: 'lg',
  navType: LEFT,
  // @ts-expect-error TS(2322): Type '{ className: string; screen: string; navType... Remove this comment to see the full error message
  navColor: 'blue-500',
  type: FULL_WIDTH,
  navOptions: [],
};

export default withTheme(AppLayout);
