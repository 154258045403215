import { EnumType } from 'json-to-graphql-query';

export const transformQueryArg = (key: string, value: any) => {
  switch (key) {
    case 'orderBy': {
      return { [key]: { ...value, direction: new EnumType(value.direction) } };
    }
    default: {
      return { [key]: value };
    }
  }
};

export const transformQueryArgs = (queryArgs: Record<any, any>) => {
  if (Object.keys(queryArgs).length === 0) {
    return undefined;
  }

  return Object.entries(queryArgs).reduce(
    (newArgs, [key, value]) => ({
      ...newArgs,
      ...transformQueryArg(key, value),
    }),
    {},
  );
};
