import elementsConfig from '../../elements';
import ElementConfig from '../../models/ElementConfig';
import { reduceDynamicPropValues } from '../elementPropResolvers';

const useScopeVariables = (
  scope: any,
  element: any,
  project: any,
  elementPath: any,
) => {
  const props = element.props;
  const elementConfig = elementsConfig[element.type] || new ElementConfig();

  return reduceDynamicPropValues(
    elementConfig.props,
    props,
    scope,
    element,
    project,
    elementPath,
    true,
  );
};

export default useScopeVariables;
