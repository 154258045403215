const exampleProject = {
  id: 4910,
  name: 'noloco-projmgmt-template',
  elements: [
    {
      id: 'I-DBmGA_e',
      type: 'VIEW',
      props: {
        name: 'All Projects by Status',
        icon: {
          name: 'LayoutKanban',
        },
        routePath: 'project',
        layout: 'BOARD',
        dataList: {
          dataSource: 'INTERNAL',
          dataType: 'project',
          limit: [
            {
              text: '30',
            },
          ],
          showPagination: true,
        },
        charts: [
          {
            id: 'NuIMvA39Ga',
            chartType: 'bar',
            series: [
              {
                id: 'ID8-SnIUZm',
                yAxisValue: {
                  id: 'I-DBmGA_e:VIEW',
                  path: 'id',
                  source: 'DATABASE',
                  dataType: 'INTEGER',
                  display: 'id',
                },
              },
            ],
            xAxisValue: {
              id: 'I-DBmGA_e:VIEW',
              path: 'projectLead',
              source: 'DATABASE',
              dataType: 'user',
              display: 'Project Lead',
            },
            aggregation: 'COUNT',
            title: 'Projects Per Project Lead',
          },
          {
            id: 'emCk1rP8t',
            chartType: 'area',
            series: [
              {
                id: 'ID8-SnIUZm',
                yAxisValue: {
                  id: 'I-DBmGA_e:VIEW',
                  path: 'totalBillableHours',
                  source: 'DATABASE',
                  dataType: 'DECIMAL',
                  display: 'Total Billable hours',
                },
                label: 'Billable Hours',
              },
              {
                id: 'NvpGkU86a',
                yAxisValue: {
                  id: 'I-DBmGA_e:VIEW',
                  path: 'workItemsCollection.totalCount',
                  source: 'DATABASE',
                  dataType: 'DECIMAL',
                  display: 'work items collection count',
                },
                label: 'Work Items',
              },
            ],
            xAxisValue: {
              id: 'I-DBmGA_e:VIEW',
              path: 'projectStartDate',
              source: 'DATABASE',
              dataType: 'DATE',
              display: 'Project start date',
            },
            aggregation: 'SUM',
            title: 'Billable Hours by Project Start Week',
            timePeriod: 'week',
          },
          {
            id: 'qA1mnXvYM',
            chartType: 'bar',
            series: [
              {
                id: 'ID8-SnIUZm',
                yAxisValue: {
                  id: 'I-DBmGA_e:VIEW',
                  path: 'id',
                  source: 'DATABASE',
                  dataType: 'INTEGER',
                  display: 'id',
                },
              },
            ],
            xAxisValue: {
              id: 'I-DBmGA_e:VIEW',
              path: 'projectStartDate',
              source: 'DATABASE',
              dataType: 'DATE',
              display: 'Project start date',
            },
            aggregation: 'COUNT',
            title: 'Projects By Week',
            timePeriod: 'week',
          },
          {
            id: 'ZSJnihkzH',
            chartType: 'pie',
            series: [
              {
                id: 'ID8-SnIUZm',
                yAxisValue: {
                  id: 'I-DBmGA_e:VIEW',
                  path: 'id',
                  source: 'DATABASE',
                  dataType: 'INTEGER',
                  display: 'id',
                },
                label: 'Projects per Account',
              },
            ],
            xAxisValue: {
              id: 'I-DBmGA_e:VIEW',
              path: 'clientAccount',
              source: 'DATABASE',
              dataType: 'account',
              display: 'Client Account',
            },
            aggregation: 'COUNT',
            title: 'Billable Hours by Project Start Week',
            timePeriod: 'week',
          },
        ],

        dateEnd: {
          id: 'I-DBmGA_e:VIEW',
          path: 'projectEndDate',
          source: 'DATABASE',
          dataType: 'DATE',
          display: 'Project end date',
        },
        dateStart: {
          id: 'I-DBmGA_e:VIEW',
          path: 'projectStartDate',
          source: 'DATABASE',
          dataType: 'DATE',
          display: 'Project start date',
        },
        fields: [
          {
            name: 'projectName',
            label: {
              value: 'Project Name',
            },
          },
          {
            name: 'projectLead',
            label: {
              value: 'Lead',
            },
          },
          {
            name: 'projectStartDate',
            label: {
              value: 'Start date',
            },
          },
          {
            name: 'clientAccount',
            label: {
              value: 'Client Account',
            },
          },

          {
            name: 'workItemsCollection',
            label: {
              value: 'Work items',
            },
            columnWidth: 12,
          },
        ],
        map: {
          latitude: {
            id: 'I-DBmGA_e:VIEW',
            path: 'projectLead.id',
            source: 'DATABASE',
            dataType: 'INTEGER',
            display: 'id',
          },
          longitude: {
            id: 'I-DBmGA_e:VIEW',
            path: 'id',
            source: 'DATABASE',
            dataType: 'INTEGER',
            display: 'id',
          },
        },
        title: [
          {
            text: '📂 All Projects',
          },
        ],
        record: {
          title: 'projectName',
          sections: [
            {
              id: '7rOvpfqXTK',
              type: 'STAGES',
              props: {
                stages: {
                  id: 'I-DBmGA_e:VIEW',
                  path: 'status',
                },
              },
              meta: {
                userGenerated: false,
                createdAt: '2023-03-02T15:34:44.515Z',
              },
            },
            {
              id: 'o-JEmOiu4H',
              type: 'HIGHLIGHTS',
              props: {
                fields: [
                  {
                    name: 'projectStartDate',
                    label: {
                      value: 'Start date',
                    },
                    columnWidth: 6,
                  },
                  {
                    name: 'projectEndDate',
                    label: {
                      value: 'End date',
                    },
                    columnWidth: 6,
                  },
                ],
              },
              meta: {
                userGenerated: false,
                createdAt: '2023-03-02T15:34:44.515Z',
              },
            },
            {
              id: 'gcjVQuZx8-',
              type: 'DETAILS',
              props: {
                fields: [
                  {
                    name: 'clientAccount',
                    label: {
                      value: 'Client Account',
                    },
                    columnWidth: 6,
                  },
                  {
                    name: 'primaryContact',
                    label: {
                      value: 'Primary Contact',
                    },
                    columnWidth: 6,
                  },
                  {
                    name: 'status',
                    label: {
                      value: 'Status',
                    },
                    columnWidth: 3,
                  },
                  {
                    name: 'totalBillableHours',
                    label: {
                      value: 'Billable Hours',
                    },
                    columnWidth: 3,
                  },
                  {
                    name: 'totalBilled',
                    label: {
                      value: 'Hours Billed',
                    },
                    columnWidth: 3,
                  },
                ],
              },
              meta: {
                userGenerated: false,
                createdAt: '2023-03-02T15:34:44.515Z',
              },
            },
            {
              type: 'TITLE',
              id: 'vh3tZ0ZkH',
              tab: null,
              props: {
                title: {
                  value: [
                    {
                      data: {
                        id: 'I-DBmGA_e:VIEW',
                        path: 'projectName',
                        source: 'DATABASE',
                        dataType: 'TEXT',
                        display: 'Project Name',
                      },
                      options: {},
                    },
                  ],
                },
                subtitle: {
                  value: [
                    { text: '**Started on:** ' },
                    {
                      data: {
                        id: 'I-DBmGA_e:VIEW',
                        path: 'projectStartDate',
                        source: 'DATABASE',
                        dataType: 'DATE',
                        display: 'Project start date',
                      },
                      options: {},
                    },
                    { text: '\n\n**Lead:** ' },
                    {
                      data: {
                        id: 'I-DBmGA_e:VIEW',
                        path: 'projectLead.firstName',
                        source: 'DATABASE',
                        dataType: 'TEXT',
                        display: 'first name',
                      },
                      options: {},
                    },
                    { text: ' ' },
                    {
                      data: {
                        id: 'I-DBmGA_e:VIEW',
                        path: 'projectLead.lastName',
                        source: 'DATABASE',
                        dataType: 'TEXT',
                        display: 'last name',
                      },
                      options: {},
                    },
                    { text: ' (' },
                    {
                      data: {
                        id: 'I-DBmGA_e:VIEW',
                        path: 'projectLead.email',
                        source: 'DATABASE',
                        dataType: 'TEXT',
                        display: 'email',
                      },
                      options: {},
                    },
                    { text: ' ) ' },
                  ],
                },
              },
            },
            {
              type: 'COLLECTION',
              id: 'n1-5XAoqU',
              tab: null,
              props: {
                dataList: {
                  limit: [{ text: '10' }],
                  showPagination: true,
                  customFilters: [],
                  dataSource: 'INTERNAL',
                  dataType: 'workItems',
                  filter: {
                    id: 'I-DBmGA_e:VIEW',
                    path: 'workItemsCollection',
                    source: 'DATABASE',
                    dataType: 'workItems',
                    display: 'work items collection',
                  },
                },
                layout: 'TABLE',
                fields: [
                  {
                    name: 'notes',
                    label: { value: 'Notes' },
                    columnWidth: 12,
                    elementType: 'b',
                  },
                  { name: 'hourlyRate', label: { value: 'Hourly rate' } },
                  { name: 'billableHours', label: { value: 'Billable hours' } },
                  { name: 'endedAt', label: { value: 'Ended At' } },
                  { name: 'totalBilled', label: { value: 'Total Billed' } },
                  { name: 'startedAt', label: { value: 'Started At' } },
                  { name: 'done', label: { value: 'Done' } },
                  {
                    name: 'workPerformed',
                    label: { value: 'Work performed' },
                    columnWidth: 6,
                  },
                ],
                newButtonText: [{ text: 'Add Work Item' }],
              },
            },
            {
              type: 'COLLECTION',
              id: 'WSt0AjA93',
              tab: null,
              props: {
                layout: 'CHARTS',
                dataList: {
                  customFilters: [],
                  dataSource: 'INTERNAL',
                  dataType: 'workItems',
                  filter: {
                    id: 'I-DBmGA_e:VIEW',
                    path: 'workItemsCollection',
                    source: 'DATABASE',
                    dataType: 'workItems',
                    display: 'work items collection',
                  },
                },
                charts: [
                  {
                    id: '7AI9I8C8zZ',
                    chartType: 'pie',
                    series: [
                      {
                        id: 'inGQ-68uGL',
                        yAxisValue: {
                          id: 'WSt0AjA93',
                          path: 'edges.node.billableHours',
                          source: 'DATABASE',
                          dataType: 'INTEGER',
                          display: 'Billable hours',
                        },
                      },
                    ],
                    xAxisValue: {
                      id: 'WSt0AjA93',
                      path: 'edges.node.worker',
                      source: 'DATABASE',
                      dataType: 'user',
                      display: 'Worker',
                    },
                    aggregation: 'SUM',
                    title: 'Billable hours by employee',
                  },
                  {
                    id: '1Mr1H1N4a',
                    chartType: 'bar',
                    series: [
                      {
                        id: 'inGQ-68uGL',
                        yAxisValue: {
                          id: 'WSt0AjA93',
                          path: 'edges.node.billableHours',
                          source: 'DATABASE',
                          dataType: 'INTEGER',
                          display: 'Billable hours',
                        },
                      },
                    ],
                    xAxisValue: {
                      id: 'WSt0AjA93',
                      path: 'edges.node.startedAt',
                      source: 'DATABASE',
                      dataType: 'DATE',
                      display: 'Started At',
                    },
                    aggregation: 'SUM',
                    title: 'Billable hours by week',
                    timePeriod: 'week',
                  },
                ],
                fields: [
                  {
                    name: 'notes',
                    label: { value: 'Notes' },
                    columnWidth: 12,
                    elementType: 'b',
                  },
                ],
              },
            },
          ],
          comments: {
            show: true,
          },
        },
        new: {
          title: [
            {
              text: 'Add a new Project',
            },
          ],
          fields: [
            {
              name: 'projectName',
              label: 'Name',
              required: true,
              validationRules: [
                {
                  field: {
                    dataType: 'DATE',
                    id: 'RECORD_SCOPE',
                    path: 'projectName.length',
                  },
                  operator: 'GREATER',
                  value: [
                    {
                      text: '3',
                    },
                  ],
                },
              ],
            },
            {
              name: 'status',
              label: 'Status',
              defaultValue: [
                {
                  data: {
                    id: 'SINGLE_OPTION',
                    path: 'project.status.PLANNING.name',
                  },
                },
              ],
            },
            {
              name: 'projectLead',
              label: 'Lead',
            },
            {
              name: 'projectStartDate',
              label: 'Start date',
              required: true,
            },
            {
              name: 'projectEndDate',
              label: 'End date',
              helpText: [
                {
                  text: 'Choose a date after the start date',
                },
              ],
              validationRules: [
                {
                  field: {
                    dataType: 'DATE',
                    id: 'RECORD_SCOPE',
                    path: 'projectEndDate',
                  },
                  operator: 'AFTER',
                  value: [
                    {
                      data: {
                        id: 'RECORD_SCOPE',
                        path: 'projectStartDate',
                        source: 'DATABASE',
                        dataType: 'DATE',
                        display: 'start date',
                      },
                      options: {},
                    },
                  ],
                },
              ],
            },
            {
              name: 'clientAccount',
              label: 'Client Account',
            },
          ],
        },
        parentPage: 'shhevkxU-',
        subtitle: [
          {
            text:
              'Drag & drop Kanban board displaying all projects by their status 📊',
          },
        ],
        groupBy: {
          id: 'I-DBmGA_e:VIEW',
          path: 'status',
          source: 'DATABASE',
          dataType: 'SINGLE_OPTION',
          display: 'Status',
        },
        filters: [],
      },
      meta: {
        createdAt: '2023-03-02T15:34:44.515Z',
      },
    },
  ],
  settings: {
    title: 'Project Management Template',
    description:
      'Enable your teams to effectively manage internal resources and execute client projects on time. ',
    theme: {
      brandColorGroups: {
        secondary: 'pink',
        success: 'green',
        warning: 'orange',
        danger: 'red',
        primary: 'pink',
      },
    },
    flags: {
      v2: true,
    },
    logo: {
      mediaId: 5467,
      type: 'UPLOAD',
    },
    authLogo: {
      mediaId: 5468,
      type: 'UPLOAD',
    },
    apiKeys: {
      googleMaps: 'AIzaSyCFU7-pJfUAwCGgGf76cgRcungTVGXsRPE',
    },
    socialMediaDesc:
      'Enable your teams to effectively manage internal resources and execute client projects on time. ',
  },
  live: true,
  createdAt: '2023-03-01T16:44:22.334Z',
  dataTypes: [
    {
      id: 39091,
      name: 'user',
      apiName: 'user',
      display: 'User',
      internal: false,
      fields: [
        {
          id: 10001,
          name: 'id',
          apiName: 'id',
          display: 'id',
          type: 'INTEGER',
          typeOptions: {},
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
          dbKey: 'id',
        },
        {
          id: 10002,
          name: 'uuid',
          apiName: 'uuid',
          display: 'uuid',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10003,
          name: 'createdAt',
          apiName: 'createdAt',
          display: 'created at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10004,
          name: 'updatedAt',
          apiName: 'updatedAt',
          display: 'updated at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10005,
          name: 'email',
          apiName: 'email',
          display: 'email',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10006,
          name: 'firstName',
          apiName: 'firstName',
          display: 'first name',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10007,
          name: 'lastName',
          apiName: 'lastName',
          display: 'last name',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10008,
          name: 'company',
          apiName: 'company',
          display: 'company',
          type: 'company',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'Users',
          reverseName: 'users',
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10009,
          name: 'profilePicture',
          apiName: 'profilePicture',
          display: 'profile picture',
          type: 'file',
          typeOptions: {},
          unique: false,
          relationship: 'ONE_TO_ONE',
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10010,
          name: 'isInternal',
          apiName: 'isInternal',
          display: 'is internal',
          type: 'BOOLEAN',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10011,
          name: 'role',
          apiName: 'role',
          display: 'role',
          type: 'role',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'Users',
          reverseName: 'users',
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
          dbKey: 'fields.role',
        },
        {
          id: 10012,
          name: 'invitationToken',
          apiName: 'invitationToken',
          display: 'invitation token',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10014,
          name: 'isActive',
          apiName: 'isActive',
          display: 'is active',
          type: 'BOOLEAN',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10015,
          name: 'lastActiveAt',
          apiName: 'lastActiveAt',
          display: 'last active at',
          type: 'DATE',
          typeOptions: {
            format: 'dateTime',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624244,
          type: 'project',
          name: 'projectsCollection',
          apiName: 'projectsCollection',
          display: 'projects collection',
          hidden: false,
          internal: false,
          readOnly: false,
          relatedField: {
            id: 624244,
            name: 'projectLead',
            apiName: 'projectLead',
            display: 'Project Lead',
            type: 'user',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'Projects',
            reverseName: 'projects',
            internal: false,
            hidden: false,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
        {
          id: 624252,
          type: 'workItems',
          name: 'workItemsCollection',
          apiName: 'workItemsCollection',
          display: 'work items collection',
          hidden: false,
          internal: false,
          readOnly: false,
          relatedField: {
            id: 624252,
            name: 'worker',
            apiName: 'worker',
            display: 'Worker',
            type: 'user',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'Work Items',
            reverseName: 'workItems',
            internal: false,
            hidden: false,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
        {
          id: 10083,
          type: 'comment',
          name: 'commentsCollection',
          apiName: 'commentsCollection',
          display: 'comments collection',
          hidden: true,
          internal: true,
          readOnly: true,
          relatedField: {
            id: 10083,
            name: 'author',
            apiName: 'author',
            display: 'author',
            type: 'user',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'comments',
            reverseName: 'comments',
            internal: true,
            hidden: true,
            readOnly: true,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
        {
          id: 10086,
          type: 'comment',
          name: 'commentsMentionedInCollection',
          apiName: 'commentsMentionedInCollection',
          display: 'comments mentioned in collection',
          hidden: true,
          internal: true,
          readOnly: true,
          relatedField: {
            id: 10086,
            name: 'mentionedUsers',
            apiName: 'mentionedUsers',
            display: 'mentioned users',
            type: 'user',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_MANY',
            reverseDisplayName: 'comments mentioned in',
            reverseName: 'commentsMentionedIn',
            internal: true,
            hidden: true,
            readOnly: true,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
        {
          id: 11286,
          type: 'comment',
          name: 'nolocoCommentsCollection',
          apiName: 'nolocoCommentsCollection',
          display: 'noloco comments collection',
          hidden: true,
          internal: true,
          readOnly: false,
          relatedField: {
            id: 11286,
            name: 'userRecord',
            apiName: 'userRecord',
            display: 'User record',
            type: 'user',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'noloco comments',
            reverseName: 'nolocoComments',
            internal: true,
            hidden: true,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
        {
          id: 10051,
          type: 'message',
          name: 'sentMessagesCollection',
          apiName: 'sentMessagesCollection',
          display: 'sent messages collection',
          hidden: true,
          internal: true,
          readOnly: false,
          relatedField: {
            id: 10051,
            name: 'sender',
            apiName: 'sender',
            display: 'sender',
            type: 'user',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'sent messages',
            reverseName: 'sentMessages',
            internal: true,
            hidden: true,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
        {
          id: 10069,
          type: 'onboardingTask',
          name: 'completedOnboardingTasksCollection',
          apiName: 'completedOnboardingTasksCollection',
          display: 'completed onboarding tasks collection',
          hidden: true,
          internal: true,
          readOnly: false,
          relatedField: {
            id: 10069,
            name: 'usersCompleted',
            apiName: 'usersCompleted',
            display: 'users completed',
            type: 'user',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_MANY',
            reverseDisplayName: 'completed onboarding tasks',
            reverseName: 'completedOnboardingTasks',
            internal: true,
            hidden: true,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
        {
          id: 10059,
          type: 'sharedFile',
          name: 'createdFilesCollection',
          apiName: 'createdFilesCollection',
          display: 'created files collection',
          hidden: true,
          internal: true,
          readOnly: false,
          relatedField: {
            id: 10059,
            name: 'creator',
            apiName: 'creator',
            display: 'creator',
            type: 'user',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'created files',
            reverseName: 'createdFiles',
            internal: true,
            hidden: true,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
      ],
      primaryField: null,
      rollups: [],
      workflows: [],
      permissions: [],
      permissionsEnabled: false,
      readOnly: false,
      source: {
        id: null,
        type: 'INTERNAL',
        collectionType: null,
        display: null,
        syncEnabled: null,
      },
    },
    {
      id: 39093,
      name: 'project',
      apiName: 'project',
      display: 'Project',
      internal: false,
      fields: [
        {
          id: 10001,
          name: 'id',
          apiName: 'id',
          display: 'id',
          type: 'INTEGER',
          typeOptions: {},
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10002,
          name: 'uuid',
          apiName: 'uuid',
          display: 'uuid',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10003,
          name: 'createdAt',
          apiName: 'createdAt',
          display: 'created at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10004,
          name: 'updatedAt',
          apiName: 'updatedAt',
          display: 'updated at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 623729,
          name: 'status',
          apiName: 'status',
          display: 'Status',
          type: 'SINGLE_OPTION',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [
            {
              id: 2797335,
              name: 'IN_PROGRESS',
              display: 'In Progress',
              order: 2,
              color: 'teal',
            },
            {
              id: 2797334,
              name: 'KICKOFF',
              display: 'Kickoff',
              order: 0,
              color: 'blue',
            },
            {
              id: 2797336,
              name: 'PLANNING',
              display: 'Planning',
              order: 1,
              color: 'yellow',
            },
            {
              id: 2797848,
              name: 'MONITORING_COMPLETE',
              display: 'Monitoring / Complete',
              order: 4,
              color: null,
            },
            {
              id: 2797849,
              name: 'DELAYED',
              display: 'Delayed',
              order: 5,
              color: 'red',
            },
          ],
          source: 'INTERNAL',
        },
        {
          id: 624244,
          name: 'projectLead',
          apiName: 'projectLead',
          display: 'Project Lead',
          type: 'user',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'Projects',
          reverseName: 'projects',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624246,
          name: 'projectStartDate',
          apiName: 'projectStartDate',
          display: 'Project start date',
          type: 'DATE',
          typeOptions: {
            format: 'date',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624264,
          name: 'clientAccount',
          apiName: 'clientAccount',
          display: 'Client Account',
          type: 'account',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'Projects',
          reverseName: 'projects',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624265,
          name: 'primaryContact',
          apiName: 'primaryContact',
          display: 'Primary Contact',
          type: 'contact',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'Projects',
          reverseName: 'projects',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624273,
          name: 'projectName',
          apiName: 'projectName',
          display: 'Project Name',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624292,
          name: 'projectEndDate',
          apiName: 'projectEndDate',
          display: 'Project end date',
          type: 'DATE',
          typeOptions: {
            format: 'date',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 197,
          name: 'totalBillableHours',
          apiName: 'totalBillableHours',
          display: 'Total Billable hours',
          rollup: {
            id: 197,
            name: 'totalBillableHours',
            display: 'Total Billable hours',
            relatedField: 'workItemsCollection',
            field: 'billableHours',
            aggregation: 'SUM',
          },
          readOnly: true,
          source: 'INTERNAL',
          type: 'DECIMAL',
        },
        {
          id: 198,
          name: 'totalBilled',
          apiName: 'totalBilled',
          display: 'Total Billed',
          rollup: {
            id: 198,
            name: 'totalBilled',
            display: 'Total Billed',
            relatedField: 'workItemsCollection',
            field: 'totalBilled',
            aggregation: 'SUM',
          },
          readOnly: true,
          source: 'INTERNAL',
          type: 'DECIMAL',
        },
        {
          id: 624253,
          type: 'workItems',
          name: 'workItemsCollection',
          apiName: 'workItemsCollection',
          display: 'work items collection',
          hidden: false,
          internal: false,
          readOnly: false,
          relatedField: {
            id: 624253,
            name: 'project',
            apiName: 'project',
            display: 'Project',
            type: 'project',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'Work Items',
            reverseName: 'workItems',
            internal: false,
            hidden: false,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
        {
          id: 11288,
          type: 'comment',
          name: 'nolocoCommentsCollection',
          apiName: 'nolocoCommentsCollection',
          display: 'noloco comments collection',
          hidden: true,
          internal: true,
          readOnly: false,
          relatedField: {
            id: 11288,
            name: 'projectRecord',
            apiName: 'projectRecord',
            display: 'Project record',
            type: 'project',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'noloco comments',
            reverseName: 'nolocoComments',
            internal: true,
            hidden: true,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
      ],
      primaryField: null,
      rollups: [
        {
          id: 197,
          name: 'totalBillableHours',
          display: 'Total Billable hours',
          relatedField: 'workItemsCollection',
          field: 'billableHours',
          aggregation: 'SUM',
        },
        {
          id: 198,
          name: 'totalBilled',
          display: 'Total Billed',
          relatedField: 'workItemsCollection',
          field: 'totalBilled',
          aggregation: 'SUM',
        },
      ],
      workflows: [
        {
          id: 4467,
          enabled: true,
          name: 'Project completed email workflow #1',
          description: null,
          trigger: 'UPDATE',
          workflow: {
            id: '6u7yE47Fc',
            config: {
              actions: [
                {
                  id: 'EkJ52x0hY',
                  type: 'FILTER',
                  props: {
                    conditions: [
                      [
                        {
                          id: 'iRg_UsU9Jd',
                          field: {
                            id: '6u7yE47Fc',
                            path: 'status',
                            source: 'DATABASE',
                            dataType: 'SINGLE_OPTION',
                            display: 'Status',
                          },
                          operator: 'EQUAL',
                          result: null,
                          value: [
                            {
                              data: {
                                id: 'SINGLE_OPTION',
                                path: 'project.status.MONITORING_COMPLETE.name',
                              },
                            },
                          ],
                        },
                      ],
                    ],
                  },
                },
                {
                  id: '6eD2XU9j9',
                  type: 'SEND_EMAIL',
                  props: {
                    subject: [
                      {
                        text: 'Project Completed',
                      },
                    ],
                    to: [
                      {
                        data: {
                          id: '6u7yE47Fc',
                          path: 'primaryContact.emailAddress',
                          source: 'DATABASE',
                          dataType: 'TEXT',
                          display: 'Email Address',
                        },
                        options: {},
                      },
                    ],
                    message: [
                      {
                        text: 'Hi ',
                      },
                      {
                        data: {
                          id: '6u7yE47Fc',
                          path: 'primaryContact.fullName',
                          source: 'DATABASE',
                          dataType: 'TEXT',
                          display: 'Full Name',
                        },
                        options: {},
                      },
                      {
                        text: ', \\n',
                      },
                      {
                        text: '\\n',
                      },
                      {
                        text:
                          'We are happy to inform you that all the work has been completed for your contracted project. An invoice will be sent shortly.\\n',
                      },
                      {
                        text: '\\n',
                      },
                      {
                        text:
                          'We hope you are satisfied with the services provided.\\n',
                      },
                      {
                        text: '\\n',
                      },
                      {
                        text:
                          'Please reply to this email if you have any feedback to pass on to the team here.\\n',
                      },
                      {
                        text: '\\n',
                      },
                      {
                        text: 'Kind regards,\\n',
                      },
                      {
                        text: '\\n',
                      },
                      {
                        data: {
                          id: '6u7yE47Fc',
                          path: 'projectLead.firstName',
                          source: 'DATABASE',
                          dataType: 'TEXT',
                          display: 'first name',
                        },
                        options: {},
                      },
                      {
                        text: ' ',
                      },
                      {
                        data: {
                          id: '6u7yE47Fc',
                          path: 'projectLead.lastName',
                          source: 'DATABASE',
                          dataType: 'TEXT',
                          display: 'last name',
                        },
                        options: {},
                      },
                      {
                        text: ' \\n',
                      },
                      {
                        text: 'Email: ',
                      },
                      {
                        data: {
                          id: '6u7yE47Fc',
                          path: 'projectLead.email',
                          source: 'DATABASE',
                          dataType: 'TEXT',
                          display: 'email',
                        },
                        options: {},
                      },
                    ],
                  },
                },
              ],
              fields: ['status'],
            },
          },
        },
      ],
      permissions: [],
      permissionsEnabled: false,
      readOnly: false,
      source: {
        id: null,
        type: 'INTERNAL',
        collectionType: null,
        display: null,
        syncEnabled: null,
      },
    },
    {
      id: 39118,
      name: 'workItems',
      apiName: 'workItems',
      display: 'Work Items',
      internal: false,
      fields: [
        {
          id: 10001,
          name: 'id',
          apiName: 'id',
          display: 'id',
          type: 'INTEGER',
          typeOptions: {},
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10002,
          name: 'uuid',
          apiName: 'uuid',
          display: 'uuid',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10003,
          name: 'createdAt',
          apiName: 'createdAt',
          display: 'created at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10004,
          name: 'updatedAt',
          apiName: 'updatedAt',
          display: 'updated at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624249,
          name: 'workPerformed',
          apiName: 'workPerformed',
          display: 'Work performed',
          type: 'TEXT',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624250,
          name: 'done',
          apiName: 'done',
          display: 'Done',
          type: 'BOOLEAN',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624251,
          name: 'attachments',
          apiName: 'attachments',
          display: 'Attachments',
          type: 'file',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_MANY',
          reverseDisplayName: 'workItems',
          reverseName: 'workItems',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624252,
          name: 'worker',
          apiName: 'worker',
          display: 'Worker',
          type: 'user',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'Work Items',
          reverseName: 'workItems',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624253,
          name: 'project',
          apiName: 'project',
          display: 'Project',
          type: 'project',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'Work Items',
          reverseName: 'workItems',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624254,
          name: 'billableHours',
          apiName: 'billableHours',
          display: 'Billable hours',
          type: 'INTEGER',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624255,
          name: 'hourlyRate',
          apiName: 'hourlyRate',
          display: 'Hourly rate',
          type: 'DECIMAL',
          typeOptions: {
            format: 'currency',
            precision: 2,
            symbol: '$',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624256,
          name: 'totalBilled',
          apiName: 'totalBilled',
          display: 'Total Billed',
          type: 'INTEGER',
          typeOptions: {
            format: 'currency',
            formula: 'SUM({{billableHours}}*{{hourlyRate}}) ',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624259,
          name: 'startedAt',
          apiName: 'startedAt',
          display: 'Started At',
          type: 'DATE',
          typeOptions: {
            format: 'dateTime',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624260,
          name: 'endedAt',
          apiName: 'endedAt',
          display: 'Ended At',
          type: 'DATE',
          typeOptions: {
            format: 'dateTime',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624261,
          name: 'notes',
          apiName: 'notes',
          display: 'Notes',
          type: 'TEXT',
          typeOptions: {
            format: 'multiline',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 11289,
          type: 'comment',
          name: 'nolocoCommentsCollection',
          apiName: 'nolocoCommentsCollection',
          display: 'noloco comments collection',
          hidden: true,
          internal: true,
          readOnly: false,
          relatedField: {
            id: 11289,
            name: 'workItemsRecord',
            apiName: 'workItemsRecord',
            display: 'Work Items record',
            type: 'workItems',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'noloco comments',
            reverseName: 'nolocoComments',
            internal: true,
            hidden: true,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
      ],
      primaryField: null,
      rollups: [],
      workflows: [],
      permissions: [],
      permissionsEnabled: false,
      readOnly: false,
      source: {
        id: null,
        type: 'INTERNAL',
        collectionType: null,
        display: null,
        syncEnabled: null,
      },
    },
    {
      id: 39119,
      name: 'contact',
      apiName: 'contact',
      display: 'Contact',
      internal: false,
      fields: [
        {
          id: 10001,
          name: 'id',
          apiName: 'id',
          display: 'id',
          type: 'INTEGER',
          typeOptions: {},
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10002,
          name: 'uuid',
          apiName: 'uuid',
          display: 'uuid',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10003,
          name: 'createdAt',
          apiName: 'createdAt',
          display: 'created at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10004,
          name: 'updatedAt',
          apiName: 'updatedAt',
          display: 'updated at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624263,
          name: 'account',
          apiName: 'account',
          display: 'Account',
          type: 'account',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'Contacts',
          reverseName: 'contacts',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624266,
          name: 'firstName',
          apiName: 'firstName',
          display: 'First Name',
          type: 'TEXT',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624267,
          name: 'lastName',
          apiName: 'lastName',
          display: 'Last Name',
          type: 'TEXT',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624268,
          name: 'emailAddress',
          apiName: 'emailAddress',
          display: 'Email Address',
          type: 'TEXT',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624269,
          name: 'fullName',
          apiName: 'fullName',
          display: 'Full Name',
          type: 'TEXT',
          typeOptions: {
            formula: 'CONCAT({{firstName}}, " ",{{lastName}})',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624270,
          name: 'title',
          apiName: 'title',
          display: 'Title',
          type: 'TEXT',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624271,
          name: 'notes',
          apiName: 'notes',
          display: 'Notes',
          type: 'TEXT',
          typeOptions: {
            format: 'multiline',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624272,
          name: 'phone',
          apiName: 'phone',
          display: 'Phone',
          type: 'INTEGER',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624265,
          type: 'project',
          name: 'projectsCollection',
          apiName: 'projectsCollection',
          display: 'projects collection',
          hidden: false,
          internal: false,
          readOnly: false,
          relatedField: {
            id: 624265,
            name: 'primaryContact',
            apiName: 'primaryContact',
            display: 'Primary Contact',
            type: 'contact',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'Projects',
            reverseName: 'projects',
            internal: false,
            hidden: false,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
        {
          id: 11290,
          type: 'comment',
          name: 'nolocoCommentsCollection',
          apiName: 'nolocoCommentsCollection',
          display: 'noloco comments collection',
          hidden: true,
          internal: true,
          readOnly: false,
          relatedField: {
            id: 11290,
            name: 'contactRecord',
            apiName: 'contactRecord',
            display: 'Contact record',
            type: 'contact',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'noloco comments',
            reverseName: 'nolocoComments',
            internal: true,
            hidden: true,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
      ],
      primaryField: {
        id: 624269,
        name: 'fullName',
        apiName: 'fullName',
      },
      rollups: [],
      workflows: [],
      permissions: [],
      permissionsEnabled: false,
      readOnly: false,
      source: {
        id: null,
        type: 'INTERNAL',
        collectionType: null,
        display: null,
        syncEnabled: null,
      },
    },
    {
      id: 39120,
      name: 'account',
      apiName: 'account',
      display: 'Account',
      internal: false,
      fields: [
        {
          id: 10001,
          name: 'id',
          apiName: 'id',
          display: 'id',
          type: 'INTEGER',
          typeOptions: {},
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10002,
          name: 'uuid',
          apiName: 'uuid',
          display: 'uuid',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10003,
          name: 'createdAt',
          apiName: 'createdAt',
          display: 'created at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10004,
          name: 'updatedAt',
          apiName: 'updatedAt',
          display: 'updated at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624262,
          name: 'name',
          apiName: 'name',
          display: 'Name',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: '',
          internal: false,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 624264,
          type: 'project',
          name: 'projectsCollection',
          apiName: 'projectsCollection',
          display: 'projects collection',
          hidden: false,
          internal: false,
          readOnly: false,
          relatedField: {
            id: 624264,
            name: 'clientAccount',
            apiName: 'clientAccount',
            display: 'Client Account',
            type: 'account',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'Projects',
            reverseName: 'projects',
            internal: false,
            hidden: false,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
        {
          id: 624263,
          type: 'contact',
          name: 'contactsCollection',
          apiName: 'contactsCollection',
          display: 'contacts collection',
          hidden: false,
          internal: false,
          readOnly: false,
          relatedField: {
            id: 624263,
            name: 'account',
            apiName: 'account',
            display: 'Account',
            type: 'account',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'Contacts',
            reverseName: 'contacts',
            internal: false,
            hidden: false,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
        {
          id: 11291,
          type: 'comment',
          name: 'nolocoCommentsCollection',
          apiName: 'nolocoCommentsCollection',
          display: 'noloco comments collection',
          hidden: true,
          internal: true,
          readOnly: false,
          relatedField: {
            id: 11291,
            name: 'accountRecord',
            apiName: 'accountRecord',
            display: 'Account record',
            type: 'account',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'noloco comments',
            reverseName: 'nolocoComments',
            internal: true,
            hidden: true,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
          },
        },
      ],
      primaryField: null,
      rollups: [],
      workflows: [],
      permissions: [],
      permissionsEnabled: false,
      readOnly: false,
      source: {
        id: null,
        type: 'INTERNAL',
        collectionType: null,
        display: null,
        syncEnabled: null,
      },
    },
    {
      id: 1012,
      name: 'comment',
      apiName: 'comment',
      display: 'Comment',
      internal: true,
      fields: [
        {
          id: 10001,
          name: 'id',
          apiName: 'id',
          display: 'id',
          type: 'INTEGER',
          typeOptions: {},
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10002,
          name: 'uuid',
          apiName: 'uuid',
          display: 'uuid',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10003,
          name: 'createdAt',
          apiName: 'createdAt',
          display: 'created at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10004,
          name: 'updatedAt',
          apiName: 'updatedAt',
          display: 'updated at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10082,
          name: 'text',
          apiName: 'text',
          display: 'text',
          type: 'TEXT',
          typeOptions: {
            format: 'multiline',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10083,
          name: 'author',
          apiName: 'author',
          display: 'author',
          type: 'user',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'comments',
          reverseName: 'comments',
          internal: true,
          hidden: true,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10084,
          name: 'attachments',
          apiName: 'attachments',
          display: 'attachments',
          type: 'file',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_MANY',
          reverseDisplayName: 'comments',
          reverseName: 'comments',
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10085,
          name: 'internal',
          apiName: 'internal',
          display: 'internal',
          type: 'BOOLEAN',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10086,
          name: 'mentionedUsers',
          apiName: 'mentionedUsers',
          display: 'mentioned users',
          type: 'user',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_MANY',
          reverseDisplayName: 'comments mentioned in',
          reverseName: 'commentsMentionedIn',
          internal: true,
          hidden: true,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 11286,
          name: 'userRecord',
          apiName: 'userRecord',
          display: 'User record',
          type: 'user',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'noloco comments',
          reverseName: 'nolocoComments',
          internal: true,
          hidden: true,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 11287,
          name: 'companyRecord',
          apiName: 'companyRecord',
          display: 'Company record',
          type: 'company',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'noloco comments',
          reverseName: 'nolocoComments',
          internal: true,
          hidden: true,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 11288,
          name: 'projectRecord',
          apiName: 'projectRecord',
          display: 'Project record',
          type: 'project',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'noloco comments',
          reverseName: 'nolocoComments',
          internal: true,
          hidden: true,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 11289,
          name: 'workItemsRecord',
          apiName: 'workItemsRecord',
          display: 'Work Items record',
          type: 'workItems',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'noloco comments',
          reverseName: 'nolocoComments',
          internal: true,
          hidden: true,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 11290,
          name: 'contactRecord',
          apiName: 'contactRecord',
          display: 'Contact record',
          type: 'contact',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'noloco comments',
          reverseName: 'nolocoComments',
          internal: true,
          hidden: true,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 11291,
          name: 'accountRecord',
          apiName: 'accountRecord',
          display: 'Account record',
          type: 'account',
          typeOptions: {},
          unique: false,
          relationship: 'MANY_TO_ONE',
          reverseDisplayName: 'noloco comments',
          reverseName: 'nolocoComments',
          internal: true,
          hidden: true,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
      ],
      primaryField: null,
      rollups: [],
      workflows: [],
      permissions: [],
      permissionsEnabled: false,
      readOnly: false,
      source: {
        id: null,
        type: 'INTERNAL',
        collectionType: null,
        display: null,
        syncEnabled: null,
      },
    },
    {
      id: 1002,
      name: 'file',
      apiName: 'file',
      display: 'File',
      internal: false,
      fields: [
        {
          id: 10001,
          name: 'id',
          apiName: 'id',
          display: 'id',
          type: 'INTEGER',
          typeOptions: {},
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10002,
          name: 'uuid',
          apiName: 'uuid',
          display: 'uuid',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10003,
          name: 'createdAt',
          apiName: 'createdAt',
          display: 'created at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10004,
          name: 'updatedAt',
          apiName: 'updatedAt',
          display: 'updated at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10017,
          name: 'name',
          apiName: 'name',
          display: 'name',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10018,
          name: 'size',
          apiName: 'size',
          display: 'size',
          type: 'INTEGER',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10019,
          name: 'fileType',
          apiName: 'fileType',
          display: 'file type',
          type: 'FILE_TYPE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10020,
          name: 'mimetype',
          apiName: 'mimetype',
          display: 'mimetype',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10021,
          name: 'url',
          apiName: 'url',
          display: 'url',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10022,
          name: 'encoding',
          apiName: 'encoding',
          display: 'encoding',
          type: 'TEXT',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
      ],
      primaryField: null,
      rollups: [],
      workflows: [],
      permissions: [],
      permissionsEnabled: false,
      readOnly: false,
      source: {
        id: null,
        type: 'INTERNAL',
        collectionType: null,
        display: null,
        syncEnabled: null,
      },
    },

    {
      id: 1004,
      name: 'role',
      apiName: 'role',
      display: 'Role',
      internal: false,
      fields: [
        {
          id: 10001,
          name: 'id',
          apiName: 'id',
          display: 'id',
          type: 'INTEGER',
          typeOptions: {},
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
          dbKey: 'id',
        },
        {
          id: 10002,
          name: 'uuid',
          apiName: 'uuid',
          display: 'uuid',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: true,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10003,
          name: 'createdAt',
          apiName: 'createdAt',
          display: 'created at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10004,
          name: 'updatedAt',
          apiName: 'updatedAt',
          display: 'updated at',
          type: 'DATE',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10025,
          name: 'name',
          apiName: 'name',
          display: 'name',
          type: 'TEXT',
          typeOptions: {
            format: 'singleLine',
          },
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10026,
          name: 'referenceId',
          apiName: 'referenceId',
          display: 'reference id',
          type: 'TEXT',
          typeOptions: {},
          unique: true,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10027,
          name: 'internal',
          apiName: 'internal',
          display: 'internal',
          type: 'BOOLEAN',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10028,
          name: 'dataAdmin',
          apiName: 'dataAdmin',
          display: 'data admin',
          type: 'BOOLEAN',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10029,
          name: 'builder',
          apiName: 'builder',
          display: 'builder',
          type: 'BOOLEAN',
          typeOptions: {},
          unique: false,
          relationship: null,
          reverseDisplayName: null,
          reverseName: null,
          internal: true,
          hidden: false,
          readOnly: false,
          required: false,
          options: [],
          source: 'INTERNAL',
        },
        {
          id: 10011,
          type: 'user',
          name: 'usersCollection',
          apiName: 'usersCollection',
          display: 'users collection',
          hidden: false,
          internal: true,
          readOnly: false,
          relatedField: {
            id: 10011,
            name: 'role',
            apiName: 'role',
            display: 'role',
            type: 'role',
            typeOptions: {},
            unique: false,
            relationship: 'MANY_TO_ONE',
            reverseDisplayName: 'Users',
            reverseName: 'users',
            internal: true,
            hidden: false,
            readOnly: false,
            required: false,
            options: [],
            source: 'INTERNAL',
            dbKey: 'fields.role',
          },
        },
      ],
      primaryField: null,
      rollups: [],
      workflows: [],
      permissions: [],
      permissionsEnabled: false,
      readOnly: false,
      source: {
        id: null,
        type: 'INTERNAL',
        collectionType: null,
        display: null,
        syncEnabled: null,
      },
    },
  ],
  media: [
    {
      id: 5467,
      name: 'infinity_white_rounded.svg',
      fileType: 'IMAGE',
      size: 1316,
      uri: 'https://media.noloco.app/5467-infinity_white_rounded.svg',
      mimetype: 'image/svg+xml',
    },
    {
      id: 5468,
      name: 'infinity_black_rounded.svg',
      fileType: 'IMAGE',
      size: 1318,
      uri: 'https://media.noloco.app/5468-infinity_black_rounded.svg',
      mimetype: 'image/svg+xml',
    },
  ],
  integrations: {
    stripe: null,
    google: {
      signIn: null,
    },
  },
  apis: [],
};

export default exampleProject;
