import React from 'react';
import RegisterForm from './RegisterForm';
import SplitLayout from './SplitLayout';

const SplitRegister = ({
  children,
  disabled,
  errors,
  logoUrl,
  emailLabel,
  footer,
  onSubmit,
  buttonText,
  confirmPasswordLabel,
  passwordLabel,
  loginText,
  titleText,
  hideEmail,
  email,
  password,
  confirmPassword,
  setEmail,
  setPassword,
  splitImageUrl,
  setConfirmPassword,
  emailErrorMessage,
  passwordErrorMessage,
  confirmPasswordErrorMessage,
  surface,
  splitChildren,
  socialLogins,
}: any) => {
  return (
    <SplitLayout
      errors={errors}
      logoUrl={logoUrl}
      onSubmit={onSubmit}
      splitChildren={splitChildren}
      subTitleText={loginText}
      titleText={titleText}
      splitImageUrl={splitImageUrl}
    >
      <RegisterForm
        disabled={disabled}
        emailLabel={emailLabel}
        buttonText={buttonText}
        confirmPasswordLabel={confirmPasswordLabel}
        passwordLabel={passwordLabel}
        email={email}
        footer={footer}
        hideEmail={hideEmail}
        password={password}
        confirmPassword={confirmPassword}
        setEmail={setEmail}
        setPassword={setPassword}
        setConfirmPassword={setConfirmPassword}
        emailErrorMessage={emailErrorMessage}
        passwordErrorMessage={passwordErrorMessage}
        confirmPasswordErrorMessage={confirmPasswordErrorMessage}
        surface={surface}
        socialLogins={socialLogins}
      >
        {children}
      </RegisterForm>
    </SplitLayout>
  );
};

export default SplitRegister;
