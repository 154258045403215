export type PasswordValidationType = 'length' | 'numbers' | 'case' | 'symbol';
export const LENGTH: PasswordValidationType = 'length';
export const NUMBERS: PasswordValidationType = 'numbers';
export const CASE: PasswordValidationType = 'case';
export const SYMBOL: PasswordValidationType = 'symbol';

const passwordValidationTypes: PasswordValidationType[] = [
  LENGTH,
  NUMBERS,
  CASE,
  SYMBOL,
];

export default passwordValidationTypes;
