export const AIRTABLE: SourceType = 'AIRTABLE';
export const API: SourceType = 'API';
export const GOOGLE_SHEETS: SourceType = 'GOOGLE_SHEETS';
export const MYSQL: SourceType = 'MYSQL';
export const POSTGRES: SourceType = 'POSTGRES';
export const XANO: SourceType = 'XANO';
export const INTERNAL: DataSourceType = 'INTERNAL';

// Potential Candidates for future
export const FIREBASE = 'FIREBASE';
export const SALESFORCE = 'SALESFORCE';

export const API_KEY_REGEX = /key[a-zA-Z0-9]+/;
export const SHARING_LINK_REGEX = /https:\/\/(www\.)?airtable\.com\/(shr[a-zA-Z0-9]+)\/?/;

export type SourceType =
  | 'AIRTABLE'
  | 'API'
  | 'GOOGLE_SHEETS'
  | 'MYSQL'
  | 'POSTGRES'
  | 'XANO';

export type DataSourceType = SourceType | 'INTERNAL' | 'XANO';

export type DataSourceTypeType = 'TABLE' | 'CUSTOM_QUERY';

export const TABLE: DataSourceTypeType = 'TABLE';
export const CUSTOM_QUERY: DataSourceTypeType = 'CUSTOM_QUERY';
