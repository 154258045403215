import get from 'lodash/get';
import BaseArrayTypeMap from './BaseArrayTypeMap';
import FieldPermissions from './FieldPermissions';
import { Permission } from './Permission';

class DataTypePermissions extends BaseArrayTypeMap<Permission> {
  roleMap: Record<string | number, Permission[]>;

  constructor(permissions: Permission[]) {
    if (permissions instanceof DataTypePermissions) {
      //Will copy maps for permissions in constructor & reuse current instances of DataTypePermissions
      super(permissions);
      this.roleMap = permissions.roleMap;
    } else if (typeof permissions !== 'number') {
      const permissionsWithFieldPermissions = permissions.map((permission) => ({
        ...permission,
        fieldPermissions: new FieldPermissions(
          permission.fieldPermissions || [],
        ),
      }));
      super(permissionsWithFieldPermissions);
      this.roleMap = {};
    } else {
      super(permissions);
      this.roleMap = {};
    }
  }

  _mapEntry(permission: Permission) {
    super._mapEntry(permission);
    if (this.idMap) {
      permission.roleIds.forEach((roleId) => {
        if (this.roleMap[roleId]) {
          this.roleMap[roleId] = [...this.roleMap[roleId], permission];
        } else {
          this.roleMap[roleId] = [permission];
        }
      });
    }
  }

  getByRoleId(roleId: string | number): Permission[] {
    if (!this.idMap) {
      this._mapEntries();
    }
    return get(this.roleMap, roleId, []);
  }
}

export default DataTypePermissions;
