import React, { useCallback, useEffect, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconChevronLeft, IconChevronRight, IconX } from '@tabler/icons-react';
import range from 'lodash/range';
import { DateTime } from 'luxon';
import BaseDatePicker from 'react-datepicker';
import {
  dtToLocalTZMaintainWallTime,
  dtToTzMaintainWallTime,
  dtToUTCMaintainWallTime,
} from '../../utils/dateTimeConversions';
import useLocale from '../../utils/hooks/useLocale';
import { Popover } from '../popover';
import { listBoxStyles } from '../select/selectStyles';
import { ROUNDED_LARGE } from './inputStyles';
import themeStyles from './inputTheme';

const getYear = (date: any) => (date ? date.getFullYear() : null);
const getMonth = (date: any) => (date ? date.getMonth() : null);

const years = range(1900, 2100);

const months = range(12);

const getLocalDate = (dateTime: any) =>
  dtToLocalTZMaintainWallTime(dateTime, false);

const formatDateTimeValue = (
  dateValue: any,
  selectTime: any,
  timeZone: any,
) => {
  if (!dateValue) {
    return null;
  }

  if (selectTime) {
    if (timeZone) {
      return dateValue.setZone(timeZone);
    }

    return dateValue;
  }

  const dateTime = dateValue.toUTC();
  const localDateTime = getLocalDate(dateTime);

  return localDateTime;
};

export const formatLocalDateTimeResult = (
  localDateTime: any,
  selectTime: any,
  timeZone: any,
): DateTime => {
  if (selectTime) {
    if (timeZone) {
      return dtToTzMaintainWallTime(localDateTime, timeZone, true);
    }

    return localDateTime.toUTC();
  }

  return dtToUTCMaintainWallTime(localDateTime, false);
};

const formatDateTimeResult = (
  dateValue: any,
  selectTime: any,
  timeZone: any,
) => {
  if (!dateValue) {
    return null;
  }

  const localDateTime = DateTime.fromJSDate(dateValue);
  return formatLocalDateTimeResult(localDateTime, selectTime, timeZone);
};

const formatDateTimeRangeResult = (dates: any, selectTime: any) => {
  const [start, end] = dates;

  return [
    // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
    formatDateTimeResult(start, selectTime),
    // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
    formatDateTimeResult(end, selectTime),
  ];
};

const convertValueToDate = (dateTime: any, selectTime: any, timeZone: any) => {
  if (!dateTime) {
    return null;
  }

  if (selectTime) {
    if (timeZone) {
      dateTime = dtToLocalTZMaintainWallTime(dateTime, true);
    }

    return dateTime.toJSDate();
  }

  return getLocalDate(dateTime).toJSDate();
};

const getStartDate = (
  value: any,
  selectsRange: any,
  selectTime: any,
  timeZone: any,
) =>
  selectsRange
    ? // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
      value && formatDateTimeValue(value.start, false)
    : formatDateTimeValue(value, selectTime, timeZone);

const getEndDate = (
  value: any,
  selectsRange: any,
  selectTime: any,
  timeZone: any,
) =>
  selectsRange
    ? // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
      value && formatDateTimeValue(value.end, false)
    : formatDateTimeValue(value, selectTime, timeZone);

type OwnDatePickerPopoverProps = {
  clearable?: boolean;
  value?: any; // TODO: PropTypes.instanceOf(DateTime)
  placeholder?: React.ReactNode | string;
  isRange?: boolean;
  style?: any; // TODO: oneOfWithDefault(inputStyles)
  selectTime?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'DatePickerPopoverProps' circularly ref... Remove this comment to see the full error message
type DatePickerPopoverProps = OwnDatePickerPopoverProps &
  typeof DatePickerPopover.defaultProps;

// @ts-expect-error TS(7022): 'DatePickerPopover' implicitly has type 'any' beca... Remove this comment to see the full error message
const DatePickerPopover = ({
  bg,
  borderColor,
  children,
  clearable,
  disabled,
  isRange,
  onBlur,
  onChange,
  open,
  selectTime,
  style,
  surface,
  timeZone,
  theme,
  value,
}: DatePickerPopoverProps) => {
  const locale = useLocale();
  const selectsRange = !selectTime && isRange;
  const [startDate, setStartDate] = useState(
    getStartDate(value, selectsRange, selectTime, timeZone),
  );

  const [endDate, setEndDate] = useState(
    getEndDate(value, selectsRange, selectTime, timeZone),
  );

  useEffect(() => {
    setStartDate(getStartDate(value, selectsRange, selectTime, timeZone));
    setEndDate(getEndDate(value, selectsRange, selectTime, timeZone));
  }, [selectTime, selectsRange, timeZone, value]);

  let bgValue =
    (bg !== 'transparent' && bg) || theme?.surfaceColors[surface] || 'white';

  let borderColorValue = borderColor || theme?.borderColors[surface];

  const handleBlur = useCallback(
    (isOpen: any) => {
      if (!isOpen && onBlur) {
        if (selectsRange) {
          onBlur({
            start: startDate,
            end: endDate,
          });
        } else {
          onBlur(startDate);
        }
      }
    },
    [endDate, onBlur, selectsRange, startDate],
  );

  const handleChange = useCallback(
    (dates: any) => {
      if (selectsRange) {
        const [start, end] = formatDateTimeRangeResult(dates, false);
        setStartDate(start);
        setEndDate(end);
        if (onChange) {
          onChange({
            start: start,
            end: end,
          });
        }
      } else {
        const result = formatDateTimeResult(dates, selectTime, timeZone);
        setStartDate(result);
        if (onChange) {
          onChange(result);
        }
      }
    },
    [selectsRange, onChange, selectTime, timeZone],
  );

  return (
    <Popover
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      {...themeStyles(borderColorValue)[style]}
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      {...listBoxStyles[style]}
      bg={surface || theme?.surfaceColors[surface] ? bgValue : undefined}
      disabled={disabled}
      closeOnOutsideClick={true}
      onOpenChange={handleBlur}
      content={
        <div
          className={`datepicker ${surface || theme?.datePicker?.surface}`}
          data-testid="date-picker-popover"
        >
          <BaseDatePicker
            showTimeSelect={selectTime}
            startDate={
              isRange
                ? startDate && convertValueToDate(startDate, false, timeZone)
                : undefined
            }
            endDate={
              isRange
                ? // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
                  endDate && convertValueToDate(endDate, false)
                : undefined
            }
            selected={
              startDate && convertValueToDate(startDate, selectTime, timeZone)
            }
            locale={locale.code}
            onChange={handleChange}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }: any) => (
              <div
                className={`flex justify-center mb-2 text-sm ${
                  theme?.datePicker?.textColor
                    ? theme.datePicker.textColor
                    : 'text-gray-500 '
                }`}
              >
                <button
                  className="mr-2 bg-transparent"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <IconChevronLeft size={16} />
                </button>
                <select
                  className="mr-2 bg-transparent"
                  data-testid="date-picker-years"
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <select
                  className="bg-transparent"
                  value={getMonth(date)}
                  data-testid="date-picker-months"
                  onChange={({ target: { value } }) =>
                    changeMonth(parseInt(value, 10))
                  }
                >
                  {months.map((option, monthIndex) => (
                    <option key={option} value={option}>
                      {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                      {locale.localize.month(monthIndex)}
                    </option>
                  ))}
                </select>
                <button
                  className="ml-2"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <IconChevronRight size={16} />
                </button>
                {clearable && (
                  <button
                    className="ml-2 mr-4"
                    onClick={() => handleChange(isRange ? [null, null] : null)}
                  >
                    <IconX size={16} />
                  </button>
                )}
              </div>
            )}
            inline={true}
            selectsRange={selectsRange}
          />
        </div>
      }
      isOpen={open}
      trigger={open ? 'none' : 'click'}
      showArrow={false}
      placement="bottom-start"
    >
      {children({ startDate, endDate, selectsRange, selectTime })}
    </Popover>
  );
};

DatePickerPopover.defaultProps = {
  clearable: true,
  isRange: false,
  inline: false,
  style: ROUNDED_LARGE,
  selectTime: false,
  theme: 'default',
};

export default withTheme(DatePickerPopover);
