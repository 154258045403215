import set from 'lodash/fp/set';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import {
  ARRAY,
  COMBO,
  DATA_PROP,
  GROUP,
  KEY_MAP,
  STRING,
  VARIABLE,
} from '../constants/elementPropTypeTypes';
import { CONTENT } from '../constants/elements';
import ElementPropType from '../models/elementPropTypes/ElementPropType';
import StringType from '../models/elementPropTypes/StringPropType';
import {
  resolveDataValue,
  resolveSingleDataItem,
  resolveStyledContentItems,
} from './data';
import { getText } from './lang';

export const resolvePropDataValue = (
  rawValue: any,
  propPath: any,
  scope: any,
  elementType: any,
  project: any,
  rawValues: any,
) => {
  return elementType !== CONTENT || !propPath.includes('items')
    ? resolveDataValue(rawValue, scope, project, rawValues)
    : resolveStyledContentItems(rawValue, scope, project);
};

export const resolveDataPropDataValue = (
  rawValue: any,
  scope: any,
  project: any,
  rawValues: any,
) => resolveSingleDataItem({ data: rawValue }, scope, project, rawValues);

// @ts-expect-error TS(7023): 'resolveDynamicPropValue' implicitly has return ty... Remove this comment to see the full error message
export const resolveDynamicPropValue = (
  rawValue: any,
  propKey: any,
  propDefinition: any,
  scope: any,
  element: any,
  project: any,
  elementPath: any,
  forceResolve: any,
  shouldResolveValue: any,
) => {
  switch (propDefinition.type) {
    case STRING: {
      return resolvePropDataValue(
        rawValue,
        [propKey],
        scope,
        element.type,
        project,
        propDefinition.rawValue,
      );
    }
    case DATA_PROP: {
      return resolveDataPropDataValue(rawValue, scope, project, true);
    }
    case ARRAY: {
      return rawValue && Array.isArray(rawValue)
        ? rawValue.map((arrayPropItem, itemIndex) => {
            return reduceDynamicPropValues(
              propDefinition.mergeDynamicPropsShape(
                element.props,
                get(rawValue, [itemIndex]),
                null,
                project,
              ),
              get(rawValue, [itemIndex]),
              scope,
              element,
              project,
              elementPath,
              forceResolve,
              shouldResolveValue,
            );
          })
        : rawValue;
    }
    case COMBO: {
      const resolvedComboProps: any = reduceDynamicPropValues(
        propDefinition.shape,
        rawValue || {},
        scope,
        element,
        project,
        elementPath,
        forceResolve,
        shouldResolveValue,
      );

      return propDefinition.resolve(
        resolvedComboProps,
        project,
        scope,
        false,
        elementPath,
      );
    }
    case KEY_MAP: {
      if (!rawValue) {
        return rawValue;
      }
      return Object.entries(rawValue).reduce((mapAcc, [key, mapValue]) => {
        const resolvedComboProps: any = reduceDynamicPropValues(
          propDefinition.shape,
          mapValue || {},
          scope,
          element,
          project,
          elementPath,
          forceResolve,
          shouldResolveValue,
        );

        return {
          ...mapAcc,
          [key]: propDefinition.resolve(
            resolvedComboProps,
            project,
            scope,
            false,
            elementPath,
          ),
        };
      }, {});
    }
    case GROUP: {
      return reduceDynamicPropValues(
        propDefinition.shape,
        rawValue,
        scope,
        element,
        project,
        elementPath,
        forceResolve,
        shouldResolveValue,
      );
    }
    case VARIABLE: {
      const val = rawValue || {};
      const resolvedValue: any = reduceDynamicPropValues(
        { value: propDefinition.propType },
        val,
        scope,
        element,
        project,
        elementPath,
        forceResolve,
        shouldResolveValue,
      );

      const resolvedLabel: any = reduceDynamicPropValues(
        { label: new StringType() },
        val,
        scope,
        element,
        project,
        elementPath,
        forceResolve,
        shouldResolveValue,
      );

      return {
        hidden: val.hidden,
        value:
          resolvedValue.value !== undefined
            ? resolvedValue.value
            : propDefinition.placeholder(0),
        label:
          resolvedLabel.label || getText('core.COLLECTION.vars.labelField'),
      };
    }
    default:
      return rawValue;
  }
};

// Sometimes a value that's resolved as an array, like an array of MULTI_OPTION values
// Could be confused as a raw StringProp value that is yet to be resolved
// This check ensures that we only resolve such values if some of the values in the array have
// data or text props
const isResolvedArrayValue = (rawValue: any, propDefinition: any) =>
  propDefinition.type === STRING &&
  rawValue &&
  Array.isArray(rawValue) &&
  rawValue.length > 0 &&
  !rawValue.some(
    (dataItem) =>
      typeof dataItem === 'object' &&
      (!isNil(dataItem.data) || !isNil(dataItem.text)),
  );

const defaultShouldResolveValue: (
  rawPropValue: any,
  propDefinition: ElementPropType,
) => boolean = () => true;

// @ts-expect-error TS(7023): 'reduceDynamicPropValues' implicitly has return ty... Remove this comment to see the full error message
export const reduceDynamicPropValues = (
  propsShape: any,
  elementBaseProps: any,
  scope: any,
  element: any,
  project: any,
  elementPath: any,
  forceResolve = false,
  shouldResolveValue: (
    rawPropValue: any,
    propDefinition: ElementPropType,
  ) => boolean = defaultShouldResolveValue,
) =>
  Object.entries(propsShape || {}).reduce(
    (elementProps, [propKey, propDefinition]) => {
      const rawValue = get(elementProps, [propKey]);

      if (
        (!(propDefinition as any).automaticallyResolve && !forceResolve) ||
        (shouldResolveValue &&
          // @ts-expect-error TS(2554): Expected 0 arguments, but got 3.
          !shouldResolveValue(rawValue, propDefinition, propKey)) ||
        isResolvedArrayValue(rawValue, propDefinition)
      ) {
        return set([propKey], rawValue, elementProps);
      }

      // @ts-expect-error TS(7022): 'resolvedValue' implicitly has type 'any' because ... Remove this comment to see the full error message
      const resolvedValue = resolveDynamicPropValue(
        (propDefinition as any).type === GROUP ? elementProps : rawValue,
        propKey,
        propDefinition,
        scope,
        element,
        project,
        elementPath,
        forceResolve,
        shouldResolveValue,
      );

      if ((propDefinition as any).type === GROUP) {
        return resolvedValue;
      }

      return set([propKey], resolvedValue, elementProps);
    },
    elementBaseProps,
  );

export const skipPropResolvingByValueIds = (idsToSkip: string[] = []) => (
  rawPropValue: any,
  propDefinition: ElementPropType,
) => {
  if (rawPropValue === undefined) {
    return true;
  }

  if (propDefinition.type === STRING && Array.isArray(rawPropValue)) {
    return !rawPropValue.some((dataItem) =>
      idsToSkip.includes(get(dataItem, 'data.id')),
    );
  }

  if (propDefinition.type === DATA_PROP && rawPropValue) {
    return !idsToSkip.includes(get(rawPropValue, 'id'));
  }

  return true;
};
