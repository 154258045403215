import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import {
  CALENDAR,
  GANTT,
  TABLE,
  TABLE_FULL,
  TIMELINE,
} from '../../../constants/collectionLayouts';
import { ACTION_BUTTONS } from '../../../constants/elements';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import useRemoveRecordFromCollection from '../../../utils/hooks/useRemoveRecordFromCollection';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { RECORD_SCOPE } from '../../../utils/scope';
import Checkbox from '../../Checkbox';
import {
  COLLECTION_ACTION_BUTTON_CELL_STYLES,
  COLLECTION_ACTION_BUTTON_STYLES,
  COLLECTION_ACTION_BUTTON_WRAPPER_STYLES,
} from '../Collection';
import ActionButton from './ActionButton';
import RemoveRelatedRecordButton from './RemoveRelatedRecordButton';

const preventRecordClick = (event: any) => {
  event.stopPropagation();
  event.preventDefault();
};

const CollectionRecordActionButtons = ({
  actionButtons,
  collectionId,
  dataList,
  dataType,
  editRelatedRecordButtons,
  editorMode,
  elementPath,
  formatRecordScope,
  record,
  rootDataType,
  layout,
  parentScope,
  project,
  bulkActionsEnabled = false,
  isRowChecked = false,
  elementId,
  handleCheckboxChange,
}: any) => {
  const removeRecordFromCollection = useRemoveRecordFromCollection(
    collectionId,
  );
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const actionButtonClassNames = COLLECTION_ACTION_BUTTON_STYLES[layout];
  const actionButtonContainerClassNames =
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    COLLECTION_ACTION_BUTTON_CELL_STYLES[layout];
  const actionButtonWrapperClassNames =
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    COLLECTION_ACTION_BUTTON_WRAPPER_STYLES[layout];

  const recordScope = useMemo(
    () => ({
      ...parentScope,
      ...formatRecordScope(record),
    }),
    [formatRecordScope, record, parentScope],
  );

  const { actionButtons: parsedActionButtons } = useSectionScopeVariables(
    ACTION_BUTTONS,
    { actionButtons },
    project,
    elementPath,
    recordScope,
    skipPropResolvingByValueIds([RECORD_SCOPE]),
  );

  const handleDeleteRecord = useCallback(
    (record: any, onNext: any) => {
      removeRecordFromCollection(record.id);
      onNext();
    },
    [removeRecordFromCollection],
  );

  const Component = layout === TABLE || layout === TABLE_FULL ? 'td' : 'div';

  return (
    <Component
      className={actionButtonContainerClassNames}
      onClick={preventRecordClick}
    >
      <div
        className={classNames(
          actionButtonWrapperClassNames,
          'hidden group-hover:flex',
        )}
      >
        {bulkActionsEnabled &&
          ![TABLE, TABLE_FULL, CALENDAR, TIMELINE, GANTT].includes(layout) && (
            <div
              className={classNames(
                'flex justify-center items-center text-center bg-gray-700 rounded-lg shadow-lg',
                actionButtonClassNames,
              )}
            >
              <Checkbox
                className="flex w-3 h-3 m-2"
                size="sm"
                checked={isRowChecked}
                value={isRowChecked}
                elementId={elementId}
                onChange={handleCheckboxChange}
              />
            </div>
          )}
        {parsedActionButtons.map((actionButton: any, index: any) => (
          <div className={actionButtonClassNames} key={actionButton.id}>
            <ActionButton
              actionButton={actionButton}
              dataType={dataType}
              editorMode={editorMode}
              index={index}
              isCollection={true}
              project={project}
              onDeleteRecord={handleDeleteRecord}
              record={record}
              recordScope={recordScope}
              size={SM}
            />
          </div>
        ))}
        {editRelatedRecordButtons.show && (
          <div className={actionButtonClassNames} key="remove-related-record">
            <RemoveRelatedRecordButton
              buttonText={get(editRelatedRecordButtons, 'unlinkButtonText')}
              dataList={dataList}
              dataType={dataType}
              project={project}
              record={record}
              rootDataType={rootDataType}
              scope={recordScope}
              variant="secondary"
            />
          </div>
        )}
      </div>
    </Component>
  );
};

export default CollectionRecordActionButtons;
