import React from 'react';
import classNames from 'classnames';
import { Popover, getColorShade } from '@noloco/components';
import { darkModeColors } from '../../../constants/darkModeColors';
import { Event } from './CollectionEvents';
import CollectionEventPopoverContent from './calendar/CollectionEventPopoverContent';

type ItemContext = {
  dimensions: any;
  useResizeHandle: boolean;
  title: string;
  canMove: boolean;
  canResizeLeft: boolean;
  canResizeRight: boolean;
  selected: boolean;
  dragging: null;
  dragStart: null;
  dragTime: null;
  dragGroupDelta: null;
  resizing: null;
  resizeEdge: null;
  resizeStart: null;
  resizeTime: null;
  width: number;
};

type CollectionTimelineItemProps = {
  item: Event;
  itemContext: ItemContext;
  getItemProps: any;
  getResizeProps: any;
  primaryColor: string;
  isDarkModeEnabled: boolean;
  recordRowLink: string;
  Row: React.ReactNode;
};

const CollectionTimelineItem = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
  primaryColor,
  isDarkModeEnabled,
  recordRowLink,
  Row,
}: CollectionTimelineItemProps) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

  return (
    <div
      {...getItemProps(item.itemProps)}
      style={{
        ...getItemProps(item.itemProps).style,
        background: '',
        border: '',
        borderRightWidth: '',
        borderLeftWidth: '',
        cursor: 'pointer',
      }}
      className={classNames(
        `flex items-center truncate pl-2 border-2 rounded-lg`,
        {
          [`bg-${getColorShade(item.color, 600)} border-${getColorShade(
            item.color,
            800,
          )}`]: item.color,
          [`bg-${getColorShade(item.color, 700)}`]:
            item.color && itemContext.selected,
          [`bg-${getColorShade(primaryColor, 600)} border-${getColorShade(
            primaryColor,
            700,
          )}`]: !item.color && !itemContext.selected,
          [`bg-${getColorShade(primaryColor, 700)} border-${getColorShade(
            primaryColor,
            600,
          )}`]: !item.color && itemContext.selected,
        },
      )}
      id={`gantt-event-${item.id}-end`}
    >
      <Popover
        className="overflow-hidden"
        p={{ x: 0, y: 0 }}
        placement={'right'}
        trigger={'none'}
        isOpen={itemContext.selected}
        disabled={itemContext.dragging || itemContext.resizing}
        closeOnOutsideClick={true}
        bg={
          isDarkModeEnabled
            ? darkModeColors.surfaces.elevation1LiteralColor
            : undefined
        }
        content={
          <CollectionEventPopoverContent
            event={item}
            isDarkModeEnabled={isDarkModeEnabled}
            recordRowLink={recordRowLink}
            Row={Row}
          />
        }
      >
        <div className="w-full overflow-hidden">
          {itemContext.selected && itemContext.useResizeHandle && (
            <span
              {...leftResizeProps}
              style={{
                ...leftResizeProps.style,
                cursor: 'col-resize',
              }}
              className="flex items-center justify-center"
            />
          )}
          <span
            className="rct-item-content pr-2"
            style={{ maxHeight: `${itemContext.dimensions.height}` }}
          >
            {itemContext.title}
          </span>
          {itemContext.selected && itemContext.useResizeHandle && (
            <span
              {...rightResizeProps}
              style={{
                ...rightResizeProps.style,
                cursor: 'col-resize',
              }}
              className="flex items-center justify-center"
            />
          )}
        </div>
      </Popover>
      <div
        id={`gantt-event-${item.id}-start`}
        className="absolute right-0 top-0 w-6 h-full"
      />
    </div>
  );
};

export default CollectionTimelineItem;
