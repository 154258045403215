import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconCircleCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import uniqBy from 'lodash/fp/uniqBy';
import get from 'lodash/get';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {
  Button,
  ErrorText,
  Loader,
  SelectInput,
  TextInput,
  getColorShade,
  validationBorder,
} from '@noloco/components';
import { MD, XS } from '@noloco/components/src/constants/tShirtSizes';
import { getText } from '@noloco/core/src/utils/lang';
import { darkModeColors } from '../../../../constants/darkModeColors';
import { NUMERIC_DATATYPES } from '../../../../constants/dataTypes';
import { LIST } from '../../../../constants/inputTypes';
import { CONTAINS, EQUAL, IN } from '../../../../constants/operators';
import { getDataTypeByName } from '../../../../utils/data';
import { getPrimaryField } from '../../../../utils/fields';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import useDataTypeQuery from '../../../../utils/hooks/useDataTypeQuery';
import RelatedCellItem from '../RelatedCellItem';
import RelationalDataFieldCreationModal from './RelationalDataFieldCreationModal';

const filterOptions = (searchValue: any) => (option: any) => {
  if (!searchValue) {
    return option.value !== null;
  }

  const lowerCaseSearchValue = searchValue.toLowerCase();

  return (
    option.value !== null &&
    (String(option.value).toLowerCase().includes(lowerCaseSearchValue) ||
      option.rawValue.toLowerCase().includes(lowerCaseSearchValue))
  );
};

const RECORDS_PER_PAGE = 50;

const RelationalDataFieldInput = ({
  additionalFields,
  allowNewRecords,
  onlyAllowNewRecords,
  autoHydrateValue,
  authQuery,
  children,
  className,
  field,
  filter,
  newRecordFormFields,
  newRecordFormTitle,
  dataTypes,
  disabled,
  onChange,
  id,
  inline,
  Input,
  inputType,
  optionDisabled,
  renderLabel,
  resultFilter,
  multiple,
  projectName,
  placeholder,
  project,
  validationError,
  value,
  surface,
  theme,
  footer,
}: any) => {
  const [localOptions, setLocalOptions] = useState([]);
  const [localSearchValue, setLocalSearchValue] = useState('');
  const [isOpen, setIsOpen] = useState(inputType === LIST && !inline);
  const [isDarkModeEnabled] = useDarkMode();

  const debouncedSetLocalSearchValue = useMemo(
    () => debounce(setLocalSearchValue, 500),
    [setLocalSearchValue],
  );

  const dataType = useMemo(() => getDataTypeByName(dataTypes, field.type), [
    dataTypes,
    field.type,
  ]);

  const primaryField = useMemo(() => dataType && getPrimaryField(dataType), [
    dataType,
  ]);

  const additionalFieldsWithArgs = useMemo(() => {
    let additionalFieldArgs = set(
      '__args.first',
      RECORDS_PER_PAGE,
      additionalFields,
    );
    additionalFieldArgs = set(
      '__variables.after',
      'String',
      additionalFieldArgs,
    );

    if (localSearchValue && primaryField) {
      additionalFieldArgs = set(
        `__args.where`,
        [
          ...get(additionalFieldArgs, '__args.where', []),
          {
            field: primaryField.apiName,
            operator: NUMERIC_DATATYPES.includes(primaryField.type)
              ? EQUAL
              : CONTAINS,
            result: localSearchValue,
          },
        ],
        additionalFieldArgs,
      );
    }

    return additionalFieldArgs;
  }, [additionalFields, localSearchValue, primaryField]);

  const queryOptions = useMemo(
    () => ({
      context: { authQuery },
      ssr: false,
      skip: onlyAllowNewRecords || !isOpen,
      variables: { after: null },
      nextFetchPolicy: 'cache-first',
    }),
    [authQuery, onlyAllowNewRecords, isOpen],
  );

  const {
    client: apolloClient,
    pageInfo,
    loading,
    edges,
    data,
    queryString,
    valuePath,
    fetchMore,
  } = useDataTypeQuery(
    field.type,
    dataTypes,
    projectName,
    additionalFieldsWithArgs,
    queryOptions,
    filter,
  );

  const additionalValueFields = useMemo(() => {
    if (value) {
      const valueIn = multiple
        ? value.edges.map((edge: any) => edge.node.id)
        : [value.id];

      if (valueIn.length === 0) {
        return {};
      }

      return set(
        `__args.where`,
        [
          {
            field: 'id',
            operator: IN,
            result: valueIn,
          },
        ],
        additionalFields,
      );
    }

    return {};
  }, [additionalFields, multiple, value]);

  const currentValue = useMemo(
    () =>
      multiple
        ? get(value, 'edges', []).map((edge: any) => edge.node.id)
        : value && typeof value === 'object'
        ? get(value, 'id')
        : value,
    [multiple, value],
  );

  const valueQueryOptions = useMemo(
    () => ({
      context: { authQuery },
      ssr: false,
      skip: multiple ? currentValue.length === 0 : !currentValue,
    }),
    [authQuery, currentValue, multiple],
  );

  const { loading: valueLoading, edges: rawValueEdges } = useDataTypeQuery(
    field.type,
    dataTypes,
    projectName,
    additionalValueFields,
    valueQueryOptions,
    filter,
  );

  useEffect(() => {
    if (autoHydrateValue && value) {
      const isPrefilledValue = multiple
        ? value.edges.every((edge: any) => Object.keys(edge.node).length === 1)
        : Object.keys(value).length === 1;
      if (isPrefilledValue && rawValueEdges && rawValueEdges.length > 0) {
        const valueIn = multiple
          ? value.edges.map((edge: any) => edge.node.id)
          : [value.id];
        const values = rawValueEdges.filter((edge: any) =>
          valueIn.includes(edge.node.id),
        );
        if (!multiple) {
          const firstValue = first(values);
          if (firstValue) {
            onChange((firstValue as any).node);
          }
        } else if (values.length === valueIn.length) {
          onChange({ edges: values });
        }
      }
    }
  }, [autoHydrateValue, multiple, onChange, rawValueEdges, value]);

  const valueEdges = useMemo(() => {
    // Use the items value while the network values load
    if ((!rawValueEdges || rawValueEdges.length === 0) && value) {
      if (multiple && value.edges) {
        return value.edges;
      }

      return [{ node: value }];
    }

    return rawValueEdges;
  }, [multiple, rawValueEdges, value]);

  const handleOpenChange = useCallback(
    (nextIsOpen: any) => {
      if (nextIsOpen) {
        setLocalOptions(edges);
      }
      setIsOpen(nextIsOpen);
    },
    [edges],
  );

  const hasMore = get(pageInfo, 'hasNextPage');

  const handleFetchMore = useCallback(() => {
    fetchMore({
      variables: { after: pageInfo.endCursor },
      updateQuery: (previousResults, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResults;
        }
        const newDataWithEdges = set(
          `${valuePath}.edges`,
          [...edges, ...get(fetchMoreResult, `${valuePath}.edges`, [])],
          data,
        );

        return set(
          `${valuePath}.pageInfo`,
          get(fetchMoreResult, `${valuePath}.pageInfo`, {}),
          newDataWithEdges,
        );
      },
    });
  }, [data, edges, fetchMore, pageInfo, valuePath]);

  const [loaderRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasMore,
    onLoadMore: () => {
      handleFetchMore();
    },
    disabled: inputType !== LIST || inline,
    rootMargin: '0px 0px 100px 0px',
  });

  const onChangeSearchValue = useCallback(
    ({ target }: any) => debouncedSetLocalSearchValue(target.value),
    [debouncedSetLocalSearchValue],
  );

  const { surface: themeSurface = 'default' } = theme.selectInput || {};
  const listBgValue = theme.surfaceColors[surface || themeSurface];
  const primaryColor = theme.brandColors.primary;

  const options = useMemo(() => {
    if (!edges) {
      return [];
    }

    let baseOptions = isOpen ? edges : localOptions;

    if (valueEdges) {
      baseOptions = [...baseOptions, ...valueEdges];
    }

    return uniqBy('value', [
      ...(value !== null && value !== undefined
        ? [
            {
              label: getText('core.dataTypes.none'),
              value: null,
            },
          ]
        : []),
      ...baseOptions
        .filter((edge: any) => resultFilter(edge.node))
        .map((edge: any) => {
          const isDisabled = optionDisabled(edge.node);
          return {
            value: edge.node.id,
            disabled: isDisabled,
            objectValue: edge.node,
            label: renderLabel(field, edge.node, isDisabled, dataTypes),
            plainLabel: `${field.name}-${edge.node.id}`,
            rawValue: Object.entries(edge.node)
              .filter(
                ([key, v]) => key !== '__typename' && typeof v !== 'object',
              )
              .map((n) => n[1])
              .join(' '),
          };
        }),
    ]);
  }, [
    edges,
    isOpen,
    localOptions,
    valueEdges,
    value,
    resultFilter,
    optionDisabled,
    renderLabel,
    field,
    dataTypes,
  ]);

  const handleChange = useCallback(
    (newRelationValue: any) => {
      setLocalSearchValue('');
      if (!newRelationValue) {
        return onChange(null);
      }

      const edgeValues = uniqBy('node.id', [...edges, ...(valueEdges || [])]);

      if (!multiple) {
        const newRelationEdge = edgeValues.find(
          (edge) => edge.node.id === newRelationValue,
        );

        onChange(newRelationEdge ? newRelationEdge.node : null);
      } else {
        if (newRelationValue.includes(null)) {
          return onChange(null);
        }
        const newRelationEdges = edgeValues.filter((edge) =>
          newRelationValue.includes(edge.node.id),
        );

        const newCollection = set(['edges'], newRelationEdges, value);
        onChange(newCollection);
      }
    },
    [edges, multiple, onChange, value, valueEdges],
  );

  const handleRecordCreation = useCallback(
    (newRecord: any) => {
      if (!multiple) {
        onChange(newRecord);
      } else {
        onChange({
          edges: [...get(value, 'edges', []), { node: newRecord }],
        });
      }
    },
    [multiple, onChange, value],
  );

  const handleInlineListChange = useCallback(
    (changedId: any) => {
      if (!multiple) {
        handleChange(changedId);
      } else if (!currentValue) {
        handleChange([changedId]);
      } else if (currentValue.includes(changedId)) {
        handleChange(currentValue.filter((v: any) => v !== changedId));
      } else {
        handleChange([...currentValue, changedId]);
      }
    },
    [currentValue, handleChange, multiple],
  );

  const allowRecordCreation =
    allowNewRecords && newRecordFormFields && newRecordFormFields.length > 0;

  if (inputType === LIST && !inline) {
    const listOptions = onlyAllowNewRecords
      ? options.filter((option) => currentValue.includes(option.value))
      : options.filter(filterOptions(localSearchValue));

    return (
      <>
        <div
          className={classNames(
            className,
            `flex flex-col rounded-lg ${
              isDarkModeEnabled
                ? darkModeColors.surfaces.elevation1
                : 'bg-white'
            }`,
            validationBorder(validationError, theme),
            {
              'border border-gray-100':
                !onlyAllowNewRecords || currentValue.length > 0,
            },
          )}
          id={id}
          data-testid={id}
        >
          <div
            className={classNames('flex items-center space-x-2', {
              'border-b':
                listOptions.length > 0 &&
                (!onlyAllowNewRecords || currentValue.length > 0),
              hidden: options.length === 0 && !allowRecordCreation,
              'p-4': !onlyAllowNewRecords || currentValue.length > 0,
            })}
          >
            {!onlyAllowNewRecords &&
              (options.length > 0 || loading || valueLoading) && (
                <TextInput
                  p={{ x: 3, y: 1.5 }}
                  className="w-full"
                  onChange={onChangeSearchValue}
                  placeholder={getText('core.SELECT_INPUT.search')}
                  value={localSearchValue}
                />
              )}
            {allowRecordCreation && (
              <RelationalDataFieldCreationModal
                apolloClient={apolloClient}
                field={field}
                formFields={newRecordFormFields}
                title={newRecordFormTitle}
                dataTypes={dataTypes}
                disabled={disabled}
                is={Button}
                size="md"
                variant="secondary"
                onChange={handleRecordCreation}
                collectionQueryString={queryString}
                project={project}
              />
            )}
          </div>
          {(listOptions.length > 0 || loading) && (
            <div
              className={classNames(
                'flex flex-col space-y-3 overflow-y-auto rounded-lg max-h-96',
                {
                  'p-4': !onlyAllowNewRecords || currentValue.length > 0,
                },
              )}
              role={!multiple ? 'radiogroup' : 'group'}
            >
              {listOptions.map((option, index) => {
                const checked =
                  multiple && currentValue
                    ? currentValue.includes(option.value)
                    : currentValue === option.value;

                const name = `${field.name}-${option.value}`;
                return (
                  <div
                    className={classNames(
                      'border border-gray-300 rounded-lg flex items-center justify-between cursor-pointer p-3 text-sm',
                      {
                        [`border-gray-300 hover:border-${getColorShade(
                          primaryColor,
                          400,
                        )}`]: !checked,
                        [`ring-2 ring-${getColorShade(
                          primaryColor,
                          300,
                        )}`]: checked,
                      },
                    )}
                    aria-checked={checked}
                    aria-labelledby={name}
                    role={!multiple ? 'radio' : 'checkbox'}
                    tabIndex={
                      checked ||
                      ((multiple ? currentValue.length === 0 : !currentValue) &&
                        index === 0)
                        ? 0
                        : -1
                    }
                    onClick={() => handleInlineListChange(option.value)}
                    key={option.value}
                  >
                    <label htmlFor={name}>{option.label}</label>
                    {checked && (
                      <Box
                        text={getColorShade(primaryColor, 500)}
                        className="flex-shrink-0"
                      >
                        <IconCircleCheck size={22} />
                      </Box>
                    )}
                  </div>
                );
              })}
              {(hasMore || loading) && (
                <div
                  className="w-full py-4 flex justify-center"
                  ref={loaderRef}
                >
                  <Loader size="sm" />
                </div>
              )}
            </div>
          )}
          {listOptions.length === 0 &&
            options.length > 0 &&
            localSearchValue &&
            !loading && (
              <p className="p-4 text-center break-all">
                {getText(
                  { search: localSearchValue },
                  'core.SELECT_INPUT.empty',
                )}
              </p>
            )}
          {options.length === 0 && !allowRecordCreation && !loading && (
            <p className="p-4 text-center">
              {getText(
                { search: localSearchValue },
                'core.SELECT_INPUT.noResults',
              )}
            </p>
          )}
        </div>
        {validationError && (
          <ErrorText className="text-left">{validationError}</ErrorText>
        )}
      </>
    );
  }

  return (
    <Input
      className={className}
      name={`${field.name}-relational-input`}
      options={options}
      hideOptions={onlyAllowNewRecords}
      hideDropdownIndicator={onlyAllowNewRecords}
      disabled={disabled}
      data-testid={id}
      id={id}
      value={currentValue || null}
      multiple={multiple}
      onChange={handleChange}
      onOpenChange={handleOpenChange}
      onSearchChange={debouncedSetLocalSearchValue}
      loading={loading || valueLoading}
      bg={inline ? 'transparent' : undefined}
      border={inline ? 0 : undefined}
      searchable={!onlyAllowNewRecords}
      hasMore={hasMore}
      onFetchMore={handleFetchMore}
      listBg={inline ? listBgValue : undefined}
      size={inline ? XS : MD}
      placeholder={placeholder}
      validationError={validationError}
      footer={
        <>
          {footer && (
            <div
              className={classNames({
                'mb-2 pb-2 border-b border-gray-500': allowRecordCreation,
              })}
            >
              {footer}
            </div>
          )}
          {allowRecordCreation && (
            <RelationalDataFieldCreationModal
              apolloClient={apolloClient}
              field={field}
              formFields={newRecordFormFields}
              title={newRecordFormTitle}
              dataTypes={dataTypes}
              disabled={disabled}
              onChange={handleRecordCreation}
              collectionQueryString={queryString}
              project={project}
            />
          )}
        </>
      }
      surface={surface}
      open={isOpen}
    >
      {children}
    </Input>
  );
};

RelationalDataFieldInput.defaultProps = {
  autoHydrateValue: false,
  inline: false,
  resultFilter: () => true,
  optionDisabled: () => false,
  Input: SelectInput,
  renderLabel: (field: any, value: any, _isDisabled: any, dataTypes: any) => (
    <RelatedCellItem
      field={field}
      value={value}
      dataTypes={dataTypes}
      single={true}
    />
  ),
  multiple: false,
  surface: 'dark',
};

export default withTheme(RelationalDataFieldInput);
