import React, { useCallback, useState } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconDownload } from '@tabler/icons-react';
import classNames from 'classnames';
import { Loader } from '@noloco/components';
import { downloadFileFromUrl } from '../../../utils/files';

const FileDownloadButton = ({
  children,
  className,
  disabled,
  file,
  is,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const onDownload = useCallback(() => {
    setIsLoading(true);
    downloadFileFromUrl(file.url, file.name).finally(() => setIsLoading(false));
  }, [file.name, file.url]);

  return (
    <Box
      className={classNames(
        className,
        'group disabled:opacity50 flex items-center justify-center',
      )}
      disabled={disabled || !file || !file.url}
      onClick={isLoading ? undefined : onDownload}
      is={is}
    >
      {isLoading ? (
        <Loader size="sm" />
      ) : (
        <IconDownload
          className="opacity-75 group-hover:opacity-100"
          size={16}
        />
      )}
      {children}
    </Box>
  );
};

FileDownloadButton.defaultProps = {
  is: 'button',
};

export default FileDownloadButton;
