import React, { forwardRef, memo, useMemo } from 'react';
import { withTailwind } from '@darraghmckay/tailwind-react-ui';
import { ErrorBoundary } from '@sentry/react';
import classNames from 'classnames';
import merge from 'lodash/fp/merge';
import omit from 'lodash/fp/omit';
import PropTypes from 'prop-types';
import { MD, SM, XL } from '../../constants/screens';
import elementsConfig from '../../elements';
import ElementConfig from '../../models/ElementConfig';
import cappedMemoize from '../../utils/cappedMemoize';
import { reduceDynamicPropValues } from '../../utils/elementPropResolvers';
import { elementType } from '../../utils/propTypes';
import { transformTailwindProps } from '../../utils/styles';
import withActionHandler from './withActionHandler';
import withDataFields from './withDataFields';
import withVisibilityRules from './withVisibilityRules';

const getElementPath = cappedMemoize(
  (elementPath, prop, index) =>
    [...elementPath, prop, index].filter((p) => p !== null),
  { maxKeys: 300 },
);

const ignoredStyles = ['column', 'align', 'justify'];
const ignoreList = [
  ...ignoredStyles,
  ...[MD, SM, XL].reduce(
    // @ts-expect-error TS(2769): No overload matches this call.
    (styleAcc, screen) => [
      ...styleAcc,
      ...ignoredStyles.map((s) => `${s}-${screen}`),
    ],
    [],
  ),
];

const transformResponsiveProps = (props: any, screenSize: any) => {
  const transformedProps = transformTailwindProps(props);
  return Object.entries(transformedProps).reduce((acc, [propName, value]) => {
    if (value === undefined) {
      return acc;
    }
    return {
      ...acc,
      [`${propName}-${screenSize}`]: value,
    };
  }, {});
};

const splitResponsiveProps = (props = {}) => {
  // @ts-expect-error TS(2339): Property 'sm' does not exist on type '{}'.
  const { sm = {}, md = {}, xl = {}, ...baseProps } = props;

  return {
    ...baseProps,
    ...transformResponsiveProps(sm, SM),
    ...transformResponsiveProps(md, MD),
    ...transformResponsiveProps(xl, XL),
  };
};

const omitUnwantedProps = (props: any) => omit(['computedMatch'], props);

const Element = memo(
  forwardRef((xProps, ref) => {
    const {
      // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
      className,
      // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type '{}'.
      editorMode,
      // @ts-expect-error TS(2339): Property 'dataScopeFields' does not exist on type ... Remove this comment to see the full error message
      dataScopeFields,
      // @ts-expect-error TS(2339): Property 'element' does not exist on type '{}'.
      element,
      // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{}'... Remove this comment to see the full error message
      elementPath,
      // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type '{}'.
      isSelected,
      // @ts-expect-error TS(2339): Property 'loading' does not exist on type '{}'.
      loading,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type '{}'.
      onClick,
      // @ts-expect-error TS(2339): Property 'onMouseOver' does not exist on type '{}'... Remove this comment to see the full error message
      onMouseOver,
      // @ts-expect-error TS(2339): Property 'onMouseOut' does not exist on type '{}'.
      onMouseOut,
      // @ts-expect-error TS(2339): Property 'handleAction' does not exist on type '{}... Remove this comment to see the full error message
      handleAction,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
      project,
      // @ts-expect-error TS(2339): Property 'ChildWrapper' does not exist on type '{}... Remove this comment to see the full error message
      ChildWrapper,
      // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
      scope,
      // @ts-expect-error TS(2339): Property 'screen' does not exist on type '{}'.
      screen,
      ...restProps
    } = xProps;
    const elementConfig = elementsConfig[element.type] || new ElementConfig();

    const id = `element-${element.id}`;

    const { component, hidden, props = {} } = elementConfig;

    const combinedScope = useMemo(() => merge(scope, dataScopeFields), [
      dataScopeFields,
      scope,
    ]);

    const children = useMemo(
      () =>
        element.children &&
        Array.isArray(element.children) &&
        element.children.length > 0
          ? element.children.map((child: any, index: any) => {
              const WrappedChildWrapper = withDataFields(
                ChildWrapper,
                child,
                project,
                editorMode,
              );

              return (
                <WrappedChildWrapper
                  element={child}
                  key={child.id}
                  elementPath={getElementPath(elementPath, 'children', index)}
                  handleAction={handleAction}
                  loading={loading}
                  project={project}
                  screen={screen}
                  scope={scope}
                />
              );
            })
          : null,
      [
        element.children,
        ChildWrapper,
        project,
        editorMode,
        elementPath,
        handleAction,
        loading,
        screen,
        scope,
      ],
    );

    if (!component || !elementConfig) {
      return null;
    }

    let Component = !hidden
      ? withTailwind(component, {
          ignore: ignoreList,
        })
      : component;

    Component = withVisibilityRules(
      Component,
      editorMode,
      element.visibilityRules,
    );

    Component.displayName = `${element.type} - ${element.id}`;

    const defaultConfigProps = Object.keys(props).reduce(
      (acc, prop) => ({
        ...acc,
        ...(props[prop].default !== undefined
          ? { [prop]: props[prop].default }
          : {}),
      }),
      {},
    );

    const statefulProps = splitResponsiveProps(element.props);
    const componentProps = {
      ...component.defaultProps,
      ...defaultConfigProps,
      ...statefulProps,
      ...omitUnwantedProps(restProps),
    };

    const transformedProps = reduceDynamicPropValues(
      elementConfig.props,
      componentProps,
      combinedScope,
      element,
      project,
      elementPath,
      false,
    );

    if (element.actions && element.actions.length > 0) {
      Component = withActionHandler(Component, element, project);
    }

    const elementChildren = children;

    return (
      // @ts-expect-error TS(2769): No overload matches this call.
      <ErrorBoundary>
        <Component
          {...transformTailwindProps(transformedProps)}
          className={classNames(
            id,
            {
              'cursor-pointer': editorMode,
            },
            className,
          )}
          editorMode={editorMode}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          isSelected={isSelected}
          elementId={element.id}
          key={element.id}
          elementPath={elementPath}
          loading={loading}
          project={project}
          ref={ref}
          scope={scope}
        >
          {elementChildren}
        </Component>
      </ErrorBoundary>
    );
  }),
);

(Element as any).propTypes = {
  element: elementType.isRequired,
  isSelected: PropTypes.bool,
  editorMode: PropTypes.bool,
  elementPath: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  loading: PropTypes.bool,
  ChildWrapper: PropTypes.object,
  handleAction: PropTypes.func,
  scope: PropTypes.object.isRequired,
};

(Element as any).defaultProps = {
  editorMode: false,
  isSelected: false,
  ChildWrapper: Element,
  scope: {},
};

Element.displayName = 'Element';

export default Element;
