import React, { forwardRef, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import useReadFileInputAttachments from '../../utils/hooks/useReadFileInputAttachments';

type ChildrenProps = {
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
};

type Props = {
  acceptedMimetypes?: string[];
  children: ({
    isDragActive,
    isDragAccept,
    isDragReject,
  }: ChildrenProps) => any;
  className?: string;
  id?: any;
  maxFiles?: number;
  maxSize?: number;
  onChange: (fileorFiles: any) => any;
  noClick?: boolean;
  noDrag?: boolean;
  disabled?: boolean;
};

const BaseDropzone = forwardRef<any, Props>(
  (
    {
      acceptedMimetypes,
      children,
      className,
      disabled,
      id,
      onChange,
      maxFiles,
      maxSize,
      noClick,
      noDrag,
    },
    ref,
  ) => {
    const readFileInputAttachments = useReadFileInputAttachments();
    const onDrop = useCallback(
      (acceptedFiles: any) => {
        readFileInputAttachments(acceptedFiles).then((files) => {
          if (maxFiles === 1 && files.length > 0) {
            if (onChange) {
              onChange(files[0]);
            }
          } else {
            if (onChange && files.length > 0) {
              onChange([...files]);
            }
          }
        });
      },
      [maxFiles, onChange, readFileInputAttachments],
    );
    const {
      isDragActive,
      isDragAccept,
      isDragReject,
      getRootProps,
      getInputProps,
    } = useDropzone({
      accept: acceptedMimetypes,
      // @ts-expect-error TS(2345): Argument of type '{ accept: string[] | undefined; ... Remove this comment to see the full error message
      id,
      disabled,
      onDrop,
      noClick,
      noDrag,
      maxFiles,
      maxSize,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      multiple: maxFiles > 1,
    });

    const inputProps = useMemo(() => getInputProps(), [getInputProps]);
    const rootProps = useMemo(() => getRootProps(), [getRootProps]);

    return (
      <div
        {...rootProps}
        id={id}
        ref={ref}
        className={classNames(
          'dropzone cursor-pointer focus-within:ring-2',
          className,
        )}
      >
        <input {...inputProps} />
        {children({
          isDragActive,
          isDragAccept,
          isDragReject,
        })}
      </div>
    );
  },
);

BaseDropzone.defaultProps = {
  className: '',
  disabled: false,
  noClick: false,
  noDrag: false,
  maxFiles: 1,
  maxSize: 50000000,
};

export default BaseDropzone;
