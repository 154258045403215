const exampleUser = {
  id: '9',
  uuid: 'reccx1l727ylhj6rz5q',
  firstName: 'Demo',
  lastName: 'User',
  email: 'demo@noloco.io',
  createdAt: '2023-05-11T13:49:46.061Z',
  updatedAt: '2023-05-11T13:49:46.061Z',
  _nolocoUserId: null,
  profilePicture: null,
  company: null,
  isInternal: true,
  role: {
    name: 'Manager',
    referenceId: '1WQP4o-Uc',
    internal: true,
    dataAdmin: false,
    builder: false,
    id: '3',
    uuid: 'rec4i1d26cler8m6jr',
    createdAt: '2023-05-11T13:49:46.061Z',
    updatedAt: '2023-05-11T13:49:46.061Z',
    __typename: 'Role',
  },
  loading: false,
};

export default exampleUser;
