import React, { useMemo, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconFilter } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Theme, getColorShade } from '@noloco/components';
import { TEXT } from '../../../constants/dataTypes';
import { BETWEEN } from '../../../constants/operators';
import { DataField } from '../../../models/DataTypeFields';
import { Project } from '../../../models/Project';
import { RecordValue } from '../../../models/Record';
import { FilterField } from '../../../models/View';
import CollectionFilterMobileSidebar from './CollectionFilterMobileSidebar';
import CollectionFilterWrapper from './CollectionFilterWrapper';

const searchProxyField = { name: '_q', apiName: '_q', type: TEXT };

type CollectionFiltersProps = {
  validFilters: { field: DataField; config: FilterField }[];
  filterValues: Record<string, RecordValue>;
  project: Project;
  search?: { placeholder?: string };
  searchEnabled: boolean;
  theme: Theme;
  updateFilterValue: (
    field: DataField,
    newValue: RecordValue,
    config: FilterField,
  ) => void;
};

const CollectionFilters = ({
  filterValues,
  project,
  updateFilterValue,
  search,
  searchEnabled,
  validFilters,
  theme,
}: CollectionFiltersProps) => {
  const [mobileSidebarVisible, setMobileSidebarVisible] = useState(false);
  const primaryColor = theme.brandColorGroups.primary;

  const hasSearch = !!search && searchEnabled;

  const filtersWithSearch = useMemo(() => {
    if (hasSearch) {
      return [
        {
          field: searchProxyField as DataField,
          config: { placeholder: search.placeholder || '' } as FilterField,
        },
        ...validFilters,
      ];
    }

    return validFilters;
  }, [search, hasSearch, validFilters]);

  const additionalFiltersHaveValues = useMemo(
    () =>
      validFilters.some(({ field }) => filterValues[field.name] !== undefined),
    [filterValues, validFilters],
  );

  return (
    <>
      {filtersWithSearch.length > 0 && (
        <button
          onClick={() => setMobileSidebarVisible(true)}
          className={classNames(
            'hidden sm:flex items-center justify-center w-10 relative p-2 h-10 rounded-lg border',
            {
              [`bg-${getColorShade(
                primaryColor,
                200,
              )} bg-opacity-50 border-${getColorShade(
                primaryColor,
                400,
              )}`]: additionalFiltersHaveValues,
              'bg-white dark:bg-gray-700': additionalFiltersHaveValues,
            },
          )}
        >
          <IconFilter size={18} className="opacity-75" />
        </button>
      )}
      {filtersWithSearch.map(({ config, field }: any, index: number) => (
        <CollectionFilterWrapper
          className={classNames(
            'collection-filter',
            `collection-filter-${field.id}`,
            {
              'my-1': validFilters.length > 2,
              'w-1/6':
                config.filterOperator === BETWEEN && validFilters.length > 2,
              'w-1/3':
                config.filterOperator === BETWEEN && validFilters.length <= 2,
              'sm:hidden': index >= (hasSearch ? 1 : 0),
            },
          )}
          config={config}
          key={get(field, 'name')}
          field={field}
          project={project}
          updateFilterValue={updateFilterValue}
          value={filterValues[field.apiName]}
        />
      ))}
      {mobileSidebarVisible && (
        <CollectionFilterMobileSidebar
          filters={filtersWithSearch}
          filterValues={filterValues}
          project={project}
          onClose={() => setMobileSidebarVisible(false)}
          updateFilterValue={updateFilterValue}
        />
      )}
    </>
  );
};

export default withTheme(CollectionFilters) as React.FC<
  Omit<CollectionFiltersProps, 'theme'>
>;
