import React, { FC, forwardRef, useCallback, useMemo, useState } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import { Button, Tooltip } from '@noloco/components';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { shouldRenderComponent } from '../components/canvas/withVisibilityRules';
import { ONE_CLICK } from '../constants/actionButtons';
import {
  BOARD,
  CALENDAR,
  CHARTS,
  COLUMNS,
  CollectionLayout,
  EVENT_BASED_LAYOUTS,
  GANTT,
  MAP,
  ROWS,
  SPLIT,
  TABLE,
  TABLE_FULL,
  TIMELINE,
} from '../constants/collectionLayouts';
import {
  DECIMAL,
  INTEGER,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
  TEXT,
} from '../constants/dataTypes';
import { CUSTOM_VISIBILITY_RULES } from '../constants/features';
import { ConditionOperator } from '../constants/operators';
import { OrderByDirection } from '../constants/orderByDirections';
import { DataField } from '../models/DataTypeFields';
import { DataType } from '../models/DataTypes';
import {
  ActionButton,
  DepValue,
  Element,
  ElementPath,
  VisibilityRules,
} from '../models/Element';
import { PermissionValue } from '../models/Permission';
import { Project } from '../models/Project';
import { BaseRecord, RecordEdge } from '../models/Record';
import {
  CollectionField,
  DataList,
  FilterField,
  GroupByWithField,
  ImportField,
} from '../models/View';
import { setSelectedElement } from '../reducers/elements';
import { canBeCardHeroImage } from '../utils/fields';
import { formatFilterValueForQueryString } from '../utils/filters';
import { useActionButtons } from '../utils/hooks/useActionButtons';
import { useAddView } from '../utils/hooks/useAddView';
import { useNextBackLink } from '../utils/hooks/useBacklink';
import useBreakpoints from '../utils/hooks/useBreakpoints';
import useCollectionQuery from '../utils/hooks/useCollectionQuery';
import useDarkMode from '../utils/hooks/useDarkMode';
import useRemoveRecordFromCollection from '../utils/hooks/useRemoveRecordFromCollection';
import useRouter from '../utils/hooks/useRouter';
import useScopeUser from '../utils/hooks/useScopeUser';
import { getText } from '../utils/lang';
import { formatQueryString, getViewRoutePrefixForViewId } from '../utils/urls';
import { COLLECTION_WRAPPER_STYLES } from './sections/Collection';
import Title from './sections/Title';
import BulkActionsBar from './sections/collections/BulkActionsBar';
import CollectionDataBody from './sections/collections/CollectionDataBody';
import CollectionFilters from './sections/collections/CollectionFilters';
import AddRelatedRecordButton from './sections/view/AddRelatedRecordButton';
import CollectionActionButtons from './sections/view/CollectionActionButtons';
import ExportButton from './sections/view/ExportButton';
import ImportButton from './sections/view/ImportButton';
import MobileActionButtonsWrapper from './sections/view/MobileActionButtonsWrapper';

const buildQueryStringParamValueForFilter = (
  field: DataField,
  newValue: any,
  config: FilterField,
) => {
  const defaultFilterValue = get(config, 'defaultValue');
  const hasDefaultFilter = defaultFilterValue !== undefined;

  const removedDefaultFilterValueFromSingleOptionFieldWithMultiSelect =
    field.type === SINGLE_OPTION && hasDefaultFilter && newValue?.length === 0;

  const removedDefaultFilterValueFromNumberField =
    (field.type === INTEGER || field.type === DECIMAL) &&
    hasDefaultFilter &&
    newValue === undefined;

  if (removedDefaultFilterValueFromNumberField) {
    return null;
  }

  const removedDefaultFilterValueFromTextField =
    field.type === TEXT && hasDefaultFilter && newValue === undefined;

  const removedDefaultFilterValueFromMultiOptionField =
    field.type === MULTIPLE_OPTION &&
    hasDefaultFilter &&
    (newValue?.includes(null) || newValue?.length === 0);

  const removedAllFilterValueFromMultiOptionFieldWithOutDefault =
    field.type === MULTIPLE_OPTION &&
    !hasDefaultFilter &&
    (newValue?.includes(null) || newValue?.length === 0);

  if (removedAllFilterValueFromMultiOptionFieldWithOutDefault) {
    return undefined;
  }

  const removedDefaultFilterFromField =
    field.type !== TEXT && hasDefaultFilter && newValue === null;

  if (
    removedDefaultFilterValueFromTextField ||
    removedDefaultFilterValueFromMultiOptionField ||
    removedDefaultFilterFromField ||
    removedDefaultFilterValueFromSingleOptionFieldWithMultiSelect
  ) {
    newValue = null;
  } else if (!hasDefaultFilter && newValue === null) {
    newValue = undefined;
  }

  if (field.relationship && hasDefaultFilter && newValue === null) {
    return null;
  }

  return newValue === null &&
    !removedDefaultFilterValueFromTextField &&
    !removedDefaultFilterFromField &&
    !removedDefaultFilterValueFromMultiOptionField &&
    !removedDefaultFilterValueFromSingleOptionFieldWithMultiSelect
    ? undefined
    : formatFilterValueForQueryString(field, newValue, config);
};

type ViewCollectionBodyProps = {
  calendarDate: Date;
  calendarView: string;
  canGroup: boolean;
  charts: any[];
  className: string;
  coverPhoto: { src: string };
  customFilters: {
    operator: ConditionOperator;
    result: any;
    field: DataField;
  }[];
  dateEndField: DataField;
  dateStart?: DepValue;
  dateStartField: DataField;
  EmptyState: FC;
  enableDragAndDropEdit: boolean;
  exportButtonText: string;
  filterValues: Record<string, any>;
  ganttDependency?: DepValue;
  groupByFields: GroupByWithField[];
  groupOptions: {
    [key: string]: {
      hidden: boolean;
    };
  };
  importButtonText: string;
  importFields?: ImportField[];
  innerClassName: string;
  limitPerGroup: number;
  map?: {
    latitude: DepValue;
    longitude: DepValue;
  };
  mapLatField: DataField;
  mapLngField: DataField;
  hideEmptyGroups: boolean;
  newButtonText: string;
  onClick: () => void;
  record: BaseRecord;
  rootDataType: DataType;
  rowLink: string;
  title: string;
  subtitle: string;
  wrapperClassName: string;
  search: {
    enabled: boolean;
    placeholder: string;
  };
  recordTitle: string;
  actionButtons: ActionButton[];
  additionalDeps: DepValue[];
  after?: string;
  before?: string;
  dataList: DataList;
  dataType: DataType;
  combinedFilters: any[];
  editRelatedRecordButtons: { show?: boolean };
  editorMode: boolean;
  element: Element;
  elementPath: ElementPath;
  fieldConfigs: { config: CollectionField; field: DataField }[];
  formatRecordScope: (record: BaseRecord) => Record<string, any>;
  hideNewButton: boolean;
  layout: CollectionLayout;
  mergedScope: Record<string, any>;
  newButton: {
    visibilityRules?: VisibilityRules;
  };
  newLink: string | null;
  newRecordValues?: Record<string, any>;
  nextBackLink: any[] | null;
  qsOrderBy?: string;
  qsDirection?: OrderByDirection;
  qsSuffix: string;
  permissions: PermissionValue;
  project: Project;
  recordActionButtons: ActionButton[];
  scope: Record<string, any>;
  searchEnabled: boolean;
  showImportButton: boolean;
  showExportButton: boolean;
  validFilters: {
    config: FilterField;
    field: DataField;
  }[];
  viewId: string;
  viewRootPathname: boolean;
};

const ViewCollectionBody = forwardRef(
  (
    {
      actionButtons,
      additionalDeps,
      after,
      before,
      calendarDate,
      calendarView,
      canGroup,
      charts,
      className,
      combinedFilters,
      coverPhoto,
      customFilters,
      dataList,
      dataType,
      dateEndField,
      dateStart,
      dateStartField,
      editorMode,
      editRelatedRecordButtons,
      element,
      elementPath,
      EmptyState,
      enableDragAndDropEdit,
      exportButtonText,
      fieldConfigs,
      filterValues,
      formatRecordScope,
      ganttDependency,
      groupByFields,
      groupOptions,
      hideEmptyGroups,
      hideNewButton,
      importButtonText,
      importFields,
      innerClassName,
      layout,
      limitPerGroup,
      map,
      mapLatField,
      mapLngField,
      mergedScope,
      newButton,
      newButtonText,
      newLink,
      newRecordValues,
      nextBackLink,
      onClick,
      permissions,
      project,
      qsDirection,
      qsOrderBy,
      qsSuffix,
      record,
      recordActionButtons,
      recordTitle,
      rootDataType,
      rowLink,
      scope,
      search,
      searchEnabled,
      showExportButton,
      showImportButton,
      title,
      subtitle,
      validFilters,
      viewId,
      viewRootPathname,
      wrapperClassName,
    }: ViewCollectionBodyProps,
    ref: any,
  ) => {
    const { sm: isSmScreen } = useBreakpoints();
    const { query: queryParams, pushQueryParams } = useRouter();
    const currentUser = useScopeUser();
    const [isDarkModeEnabled] = useDarkMode();
    const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);

    const newButtonVisible = useMemo(
      () =>
        !hideNewButton &&
        (editorMode ||
          shouldRenderComponent(
            currentUser,
            newButton.visibilityRules ?? {},
            project,
            mergedScope,
            customRulesEnabled,
          )),
      [
        currentUser,
        customRulesEnabled,
        editorMode,
        hideNewButton,
        mergedScope,
        newButton.visibilityRules,
        project,
      ],
    );

    const differentNewLink = !!newLink && newLink !== viewId && newLink !== '#';

    const nextNewLinkBackLink = useNextBackLink(viewId, differentNewLink);

    const newButtonLink = useMemo(() => {
      if (newLink === '#') {
        return '#';
      }

      if (newLink === undefined) {
        if (!viewRootPathname) {
          return '#';
        }

        return `${viewRootPathname}/new?${queryString.stringify(
          newRecordValues ?? {},
        )}`;
      }

      const customRootPathName = getViewRoutePrefixForViewId(newLink, project);
      if (!customRootPathName) {
        return null;
      }

      return `${customRootPathName}/new?${queryString.stringify({
        ...newRecordValues,
        _parentPage: nextNewLinkBackLink,
      })}`;
    }, [
      newLink,
      newRecordValues,
      nextNewLinkBackLink,
      project,
      viewRootPathname,
    ]);

    const removeRecordFromCollection = useRemoveRecordFromCollection(
      element.id,
    );
    const [selectedRows, setSelectedRows] = useState<BaseRecord[]>([]);
    const { activeActionItem, queueAction } = useActionButtons();

    const dispatch = useDispatch();
    const onAddView = useAddView(project);

    const onAddMissingView = useCallback(() => {
      if (dataType) {
        onAddView(dataType);
        dispatch(setSelectedElement([]));
      }
    }, [dataType, onAddView, dispatch]);

    const showEditRelatedRecordButton = useMemo(
      () =>
        editRelatedRecordButtons.show &&
        permissions.update &&
        !!get(dataList, ['filter', 'path']),
      [dataList, editRelatedRecordButtons.show, permissions.update],
    );

    const orderBy = useMemo(() => {
      if (!qsDirection && !qsOrderBy) {
        return get(dataList, 'orderBy');
      }

      return {
        field: qsOrderBy,
        direction: qsDirection,
      };
    }, [dataList, qsDirection, qsOrderBy]);

    const handleSetOrderBy = useCallback(
      (field: any, direction: any) => {
        if (orderBy) {
          if (
            orderBy.field === field.apiName &&
            orderBy.direction === direction
          ) {
            return pushQueryParams({
              [`_orderBy${qsSuffix}`]: undefined,
              [`_direction${qsSuffix}`]: undefined,
            });
          }
        }

        pushQueryParams({
          [`_orderBy${qsSuffix}`]: field.apiName,
          [`_direction${qsSuffix}`]: direction,
        });
      },
      [orderBy, pushQueryParams, qsSuffix],
    );

    const setPaginationQuery = useCallback(
      (paginationQuery: any) => {
        pushQueryParams({
          [`_after${qsSuffix}`]: paginationQuery.after,
          [`_before${qsSuffix}`]: paginationQuery.before,
        });
      },
      [pushQueryParams, qsSuffix],
    );

    const maxStickyColumnIndex = useMemo(
      () =>
        (layout === TABLE || layout === TABLE_FULL) &&
        get(first(fieldConfigs), 'config.sticky')
          ? 0
          : null,
      [fieldConfigs, layout],
    );
    const showCardHeroImage = useMemo(() => {
      const firstField = first(fieldConfigs);
      return (
        firstField &&
        canBeCardHeroImage(0, firstField.field, layout) &&
        get(firstField.config, 'heroImage')
      );
    }, [fieldConfigs, layout]);

    const transformRecordScope = useCallback(
      (currentScope: any, record: any) => ({
        ...currentScope,
        [`${element.id}:RECORD`]: record,
        [`${element.id}:VIEW`]: record,
      }),
      [element.id],
    );

    const updateFilterValue = useCallback(
      (field, newValue, config = null) =>
        pushQueryParams({
          [`${field.name}${qsSuffix}`]: buildQueryStringParamValueForFilter(
            field,
            newValue,
            config,
          ),
          [`_after${qsSuffix}`]: undefined,
          [`_before${qsSuffix}`]: undefined,
        }),
      [pushQueryParams, qsSuffix],
    );

    const showTableSummary = useMemo(
      () =>
        (layout === TABLE || layout === TABLE_FULL) &&
        fieldConfigs.some(({ config }: any) => !!config.groupSummary),
      [fieldConfigs, layout],
    );

    const recordQueryString = useMemo(() => {
      let qs = null;
      if (layout === SPLIT) {
        qs = queryString.stringify(
          validFilters.reduce(
            (qaAcc, { field }) => {
              const fieldKey = `${field.apiName}${qsSuffix}`;
              // @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
              const filterValue = queryParams[fieldKey];
              if (filterValue !== undefined) {
                return {
                  ...qaAcc,
                  [fieldKey]: filterValue,
                };
              }

              return qaAcc;
            },
            {
              [`_after${qsSuffix}`]: after,
              [`_before${qsSuffix}`]: before,
              [`_orderBy${qsSuffix}`]: qsOrderBy,
              [`_direction${qsSuffix}`]: qsDirection,
              _parentPage: nextBackLink,
            },
          ),
        );
      } else {
        if (nextBackLink) {
          qs = queryString.stringify({ _parentPage: nextBackLink });
        }
      }

      return formatQueryString(qs);
    }, [
      after,
      before,
      layout,
      nextBackLink,
      qsDirection,
      qsOrderBy,
      qsSuffix,
      queryParams,
      validFilters,
    ]);

    const {
      edges,
      rawData,
      pageInfo,
      totalCount,
      nodeQueryObject,
      loading,
      error,
      parentValuePath,
    } = useCollectionQuery(dataType.name, project, element, elementPath, {
      additionalDeps,
      after,
      before,
      autoRefresh: layout !== CHARTS && !!dataList.limit,
      countOnly: false,
      customFilters: combinedFilters,
      filter: dataList.filter,
      limit: ![...EVENT_BASED_LAYOUTS, CHARTS].includes(layout)
        ? dataList.limit
        : undefined,
      orderBy,
    });

    const bulkActionsEnabled = get(element, 'props.bulkActionsEnabled', false);
    const setSelectAllRows = useCallback(
      (selectAll) =>
        setSelectedRows((rows) =>
          selectAll
            ? edges.map((edge: RecordEdge) => edge.node)
            : edges.length === rows.length
            ? []
            : rows,
        ),
      [edges],
    );

    const allRowsSelected = useMemo(
      () => edges.length > 0 && edges.length === selectedRows.length,
      [edges, selectedRows],
    );

    const recordsSelected = useMemo(
      () => selectedRows.length > 0 || allRowsSelected,
      [selectedRows, allRowsSelected],
    );

    const recordScope = useCallback(
      (record) => ({ ...scope, ...formatRecordScope(record) }),
      [scope, formatRecordScope],
    );

    const oneClickActionButtons = useMemo(() => {
      if (loading || selectedRows.length === 0) {
        return [];
      }

      return recordActionButtons.filter(
        (recordActionButton) =>
          recordActionButton.execution === ONE_CLICK &&
          selectedRows.every((selectedRow) =>
            shouldRenderComponent(
              currentUser,
              recordActionButton.visibilityRules ?? {},
              project,
              recordScope(selectedRow),
              customRulesEnabled,
            ),
          ),
      );
    }, [
      loading,
      selectedRows,
      recordActionButtons,
      currentUser,
      customRulesEnabled,
      project,
      recordScope,
    ]);

    const onDeleteRecord = useCallback(
      (record: BaseRecord, onNext: any) => {
        removeRecordFromCollection(record.id);
        setSelectedRows((selectedRows: BaseRecord[]) =>
          selectedRows.filter((selectedRow) => selectedRow.id !== record.id),
        );
        onNext();
      },
      [removeRecordFromCollection],
    );

    const handleBulkActionButtonClick = useCallback(
      (actionButton) =>
        edges
          .filter((edge: RecordEdge) =>
            selectedRows.find((row) => row.id === edge.node.id),
          )
          .map((edge: RecordEdge) =>
            queueAction({
              executionId: shortid.generate(),
              executionType: ONE_CLICK,
              action: actionButton,
              dataType,
              record: edge.node,
              scope: recordScope(edge.node),
              onDeleteRecord,
            }),
          ),
      [selectedRows, dataType, edges, recordScope, onDeleteRecord, queueAction],
    );
    const additionalFiltersPerLine = searchEnabled ? 1 : 2;
    const filtersOnNewLine =
      validFilters.length > additionalFiltersPerLine ||
      actionButtons.length >= additionalFiltersPerLine;

    const showMobileActionButtons =
      (isSmScreen && actionButtons && actionButtons.length > 0) ||
      showExportButton ||
      (showImportButton && permissions.create);

    return (
      <div
        className={classNames(
          className,
          'collection-view',
          `collection-view-${layout.toLowerCase()}`,
        )}
        ref={ref}
        onClick={onClick}
      >
        {(title ||
          subtitle ||
          coverPhoto ||
          validFilters.length > 0 ||
          permissions.create) && (
          <Title
            subtitle={{
              hidden: !subtitle,
              value: subtitle,
            }}
            title={{
              hidden: !title,
              value: title,
            }}
            coverPhoto={{
              hidden: !coverPhoto,
              value: coverPhoto,
            }}
            className={classNames(filtersOnNewLine ? 'mb-2' : 'mb-4', {
              'px-8 sm:px-4': [
                TABLE_FULL,
                SPLIT,
                MAP,
                CALENDAR,
                TIMELINE,
                GANTT,
                BOARD,
              ].includes(layout),
              'px-4': layout === ROWS || layout === TABLE,
            })}
          >
            {(!filtersOnNewLine || isSmScreen) && (
              <CollectionFilters
                filterValues={filterValues}
                project={project}
                search={search}
                searchEnabled={searchEnabled}
                validFilters={validFilters}
                updateFilterValue={updateFilterValue}
              />
            )}
            {!isSmScreen && actionButtons && (
              <CollectionActionButtons
                actionButtons={actionButtons}
                dataType={dataType}
                editorMode={editorMode}
                project={project}
                scope={scope}
                viewRootPathname={viewRootPathname}
              />
            )}
            {!isSmScreen && showExportButton && (
              <ExportButton
                additionalDeps={additionalDeps}
                dataList={dataList}
                dataType={dataType}
                customFilters={customFilters}
                elementPath={elementPath}
                fieldConfigs={fieldConfigs}
                project={project}
                fileNamePrefix={title}
                text={exportButtonText}
              />
            )}
            {!isSmScreen && showImportButton && permissions.create && (
              <ImportButton
                buttonText={importButtonText}
                dataType={dataType}
                fields={importFields}
                project={project}
                variant="secondary"
              />
            )}
            <div className="flex items-center">
              {permissions.create &&
                (viewRootPathname || editorMode) &&
                newButtonVisible &&
                (layout !== CHARTS || hideNewButton === false) && (
                  <Tooltip
                    content={
                      <div
                        className={classNames('flex flex-col', {
                          dark: isDarkModeEnabled,
                        })}
                      >
                        <div className="p-4 dark:text-white">
                          <span>
                            {getText(
                              { dataType: dataType.display },
                              'elements.VIEW.buttons.newButton.noView.description',
                            )}
                          </span>
                        </div>
                        <div className="flex justify-end py-2 px-4 bg-gray-50 dark:bg-gray-600 dark:border-t dark:border-gray-600">
                          <Button
                            size="sm"
                            className="ml-auto"
                            onClick={onAddMissingView}
                            variant="secondary"
                          >
                            {getText(
                              'elements.VIEW.buttons.newButton.noView.cta',
                            )}
                          </Button>
                        </div>
                      </div>
                    }
                    offset={[0, 0]}
                    p={0}
                    placement="bottom-end"
                    disabled={!editorMode || viewRootPathname}
                  >
                    <Link
                      to={newButtonLink as string}
                      className={classNames('whitespace-nowrap new-button', {
                        'opacity-50': !viewRootPathname,
                      })}
                      onClick={() => null}
                    >
                      <Button
                        className={classNames(
                          'flex items-center space-x-2 rounded-tl-lg rounded-bl-lg h-8',
                          {
                            'rounded-tr-lg rounded-br-lg': !showEditRelatedRecordButton,
                            'sm:rounded-tr-none sm:rounded-br-none': showMobileActionButtons,
                          },
                        )}
                        rounded={false}
                      >
                        <IconPlus size={14} className="opacity-75" />
                        <span>
                          {newButtonText ||
                            getText(
                              { dataType: dataType.display },
                              'core.COLLECTION.form.new',
                            )}
                        </span>
                      </Button>
                    </Link>
                  </Tooltip>
                )}
              {showMobileActionButtons && (
                <MobileActionButtonsWrapper
                  rounded={!showEditRelatedRecordButton}
                  newButtonVisible={newButtonVisible}
                >
                  {showExportButton && (
                    <ExportButton
                      additionalDeps={additionalDeps}
                      buttonType="text"
                      dataList={dataList}
                      dataType={dataType}
                      customFilters={customFilters}
                      elementPath={elementPath}
                      fieldConfigs={fieldConfigs}
                      project={project}
                      fileNamePrefix={title}
                      text={exportButtonText}
                    />
                  )}
                  {showImportButton && permissions.create && (
                    <ImportButton
                      buttonText={importButtonText}
                      buttonType="text"
                      dataType={dataType}
                      fields={importFields}
                      project={project}
                      variant="secondary"
                    />
                  )}
                  <CollectionActionButtons
                    actionButtons={actionButtons}
                    buttonType="text"
                    dataType={dataType}
                    editorMode={editorMode}
                    project={project}
                    scope={scope}
                    viewRootPathname={viewRootPathname}
                  />
                </MobileActionButtonsWrapper>
              )}
              {showEditRelatedRecordButton && (
                <AddRelatedRecordButton
                  config={get(editRelatedRecordButtons, ['config'])}
                  dataList={dataList}
                  dataType={dataType}
                  newButtonVisible={newButtonVisible}
                  project={project}
                  rootDataType={rootDataType}
                  scope={scope}
                  surface="light"
                />
              )}
            </div>
          </Title>
        )}
        {filtersOnNewLine && !isSmScreen && (
          <div
            className={classNames('flex items-end justify-end flex-wrap my-3', {
              'px-4': layout === TABLE_FULL,
            })}
          >
            <CollectionFilters
              filterValues={filterValues}
              project={project}
              search={search}
              searchEnabled={searchEnabled}
              validFilters={validFilters}
              updateFilterValue={updateFilterValue}
            />
          </div>
        )}
        {bulkActionsEnabled && recordsSelected && (
          <BulkActionsBar
            layout={layout}
            activeActionItem={activeActionItem}
            elementPath={elementPath}
            handleBulkActionButtonClick={handleBulkActionButtonClick}
            oneClickActionButtons={oneClickActionButtons}
            project={project}
            scope={mergedScope}
            selectedRows={selectedRows}
            setSelectAllRows={setSelectAllRows}
            setSelectedRows={setSelectedRows}
          />
        )}
        <Box
          className={classNames(
            COLLECTION_WRAPPER_STYLES[
              layout as keyof typeof COLLECTION_WRAPPER_STYLES
            ](isDarkModeEnabled as boolean) ||
              COLLECTION_WRAPPER_STYLES[ROWS](isDarkModeEnabled as boolean),
            {
              'flex-col': layout === COLUMNS && canGroup,
            },
            wrapperClassName,
          )}
        >
          <CollectionDataBody
            actionButtons={recordActionButtons}
            additionalDeps={additionalDeps}
            after={after}
            before={before}
            calendarDate={calendarDate}
            calendarView={calendarView}
            charts={charts}
            customFilters={combinedFilters}
            dateStart={dateStart}
            dateStartField={dateStartField}
            dateEndField={dateEndField}
            ganttDependency={ganttDependency}
            dataList={dataList}
            dataType={dataType}
            editRelatedRecordButtons={editRelatedRecordButtons}
            editorMode={editorMode}
            EmptyState={EmptyState}
            element={element}
            elementPath={elementPath}
            fieldConfigs={fieldConfigs}
            groupByFields={groupByFields}
            groupOptions={groupOptions}
            formatRecordScope={formatRecordScope}
            handleSetOrderBy={handleSetOrderBy}
            hideEmptyGroups={hideEmptyGroups}
            layout={layout}
            limitPerGroup={limitPerGroup}
            innerClassName={innerClassName}
            map={map}
            mapLatField={mapLatField}
            mapLngField={mapLngField}
            maxStickyColumnIndex={maxStickyColumnIndex}
            orderBy={orderBy}
            permissions={permissions}
            project={project}
            qsSuffix={qsSuffix}
            recordConfig={record}
            recordTitle={recordTitle}
            recordQueryString={recordQueryString}
            rootDataType={rootDataType}
            rowLink={rowLink}
            scope={mergedScope}
            setPaginationQuery={setPaginationQuery}
            showCardHeroImage={showCardHeroImage}
            showTableSummary={showTableSummary}
            transformRecordScope={transformRecordScope}
            viewRootPathname={viewRootPathname}
            enableDragAndDropEdit={enableDragAndDropEdit}
            edges={edges}
            rawData={rawData}
            pageInfo={pageInfo}
            totalCount={totalCount}
            nodeQueryObject={nodeQueryObject}
            loading={loading}
            error={error}
            parentValuePath={parentValuePath}
            bulkActionsEnabled={bulkActionsEnabled}
            allRowsSelected={allRowsSelected}
            setSelectAllRows={setSelectAllRows}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </Box>
      </div>
    );
  },
);

export default ViewCollectionBody;
