import gql from 'graphql-tag';
import { DataType } from '../models/DataTypes';

const COMMENT_FRAGMENT = `
id
uuid
text
createdAt
updatedAt
author {
  id
  uuid
  email
  firstName
  lastName
  profilePicture {
    id
    mimetype
    fileType
    url
  }
}
attachments {
  edges {
    node {
      id
      name
      mimetype
      fileType
      url
    }
  }
}
mentionedUsers {
  totalCount
  edges {
    node {
      id
      uuid
      email
      firstName
      lastName
      profilePicture {
        id
        mimetype
        fileType
        url
      }
    }
  }
}`;

export const NOLOCO_COMMENTS_COLLECTION = 'nolocoCommentsCollection';

export const getCommentsQuery = (dataType: DataType, limit: number) => gql`
    query ${dataType.apiName}Comments($uuid: String!, $after: String, $where: CommentWhereInput) {
        ${dataType.apiName}(uuid: $uuid) {
          id
          uuid
          ${NOLOCO_COMMENTS_COLLECTION}(
            orderBy: {field: "createdAt", direction: DESC},
            after: $after,
            first: ${limit},
            where: $where
          ) {
            totalCount
            edges {
              node {
                ${COMMENT_FRAGMENT}
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }   
        }
    }
`;

export const getCreateCommentMutation = (dataType: DataType) => gql`
    mutation createComment($recordId: ID!, $text: String, $attachments: [Upload!]) {
        createComment(text: $text, attachments: $attachments, ${dataType.apiName}RecordId : $recordId) {
          ${COMMENT_FRAGMENT}
        }
    }
`;
