import React from 'react';
import classNames from 'classnames';
import { AutoSizedTextInput, Switch } from '@noloco/components';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import DataFieldIcon from '@noloco/ui/src/components/DataFieldIcon';

type FieldItemEditorProps = {
  children?: JSX.Element | boolean;
  className?: string;
  fieldName: string;
  enabled: boolean;
  disabled: boolean;
  onChangeLabel?: (label: string) => void;
  onChangeSwitch?: (value: boolean) => void;
  type: string;
};

const FieldItemEditor = ({
  children,
  className,
  fieldName,
  enabled,
  disabled,
  onChangeLabel,
  onChangeSwitch,
  type,
}: FieldItemEditorProps) => (
  <div
    className={classNames(
      className,
      'flex flex-col px-3 py-1.5 w-full border rounded-lg max-w-40 bg-white',
      {
        'opacity-50 text-gray-500': disabled,
      },
    )}
  >
    <div className="flex items-center">
      <DataFieldIcon size={16} field={{ type } as DataField} />
      <span className="font-medium text-sm ml-2">
        {enabled && onChangeLabel ? (
          <AutoSizedTextInput
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => onChangeLabel(value)}
            value={fieldName}
          />
        ) : (
          fieldName
        )}
      </span>
      <Switch
        value={enabled}
        disabled={disabled}
        className="ml-auto"
        onChange={onChangeSwitch}
        size="sm"
      />
    </div>
    {children}
  </div>
);

export default FieldItemEditor;
