export const RADIO = 'RADIO';
export const CHECKBOX = 'CHECKBOX';
export const COLORED_OPTIONS = 'COLORED_OPTIONS';
export const LIST = 'LIST';
export const DATE_AND_TIME = 'DATE_AND_TIME';
export const DATE = 'DATE';
export const MARKDOWN = 'MARKDOWN';

const inputTypes = [
  RADIO,
  CHECKBOX,
  COLORED_OPTIONS,
  LIST,
  DATE_AND_TIME,
  DATE,
  MARKDOWN,
];

export type InputType =
  | 'RADIO'
  | 'CHECKBOX'
  | 'COLORED_OPTIONS'
  | 'LIST'
  | 'DATE_AND_TIME'
  | 'DATE'
  | 'MARKDOWN';

export default inputTypes;
