import {
  IconBrandStripe,
  IconClick,
  IconEdit,
  IconFileCode,
  IconLocation,
  IconSquarePlus,
  IconSquareX,
} from '@tabler/icons-react';
import curry from 'lodash/curry';
import { ActionButtonActionConfig } from '../models/ActionConfig';
import DataTypes from '../models/DataTypes';
import { Action } from '../models/Element';
import StateItem from '../models/StateItem';
import LinkPropType from '../models/elementPropTypes/comboProps/LinkPropType';
import { getDataTypeStateOptions } from '../utils/actions';
import {
  getDataItemsForPropsShape,
  isPrimitiveType,
  safelyAppendPath,
} from '../utils/data';
import {
  CREATE,
  DELETE,
  IFRAME,
  NAVIGATE,
  ON_DEMAND,
  OPEN_STRIPE_PORTAL,
  UPDATE,
} from './actionTypes';
import { INTEGER } from './dataTypes';

export const CONFETTI = 'confetti';

const getDataTypeMutationScope = (action: Action, dataTypes: DataTypes) => {
  const { dataType: dataTypeName } = action;
  const dataType = dataTypeName && dataTypes.getByName(dataTypeName);
  if (dataType) {
    return getDataTypeStateOptions(action.id, dataType);
  }
  return [];
};

const getDataTypeActionDataItems = curry(
  (dataFieldName: keyof Action, action: Action) => {
    const fields = action[dataFieldName] || {};
    return Object.values(fields)
      .map((field) => {
        if (isPrimitiveType((field as any).dataType)) {
          return field;
        }
        return new StateItem({
          ...field,
          path: safelyAppendPath((field as any).path, 'id'),
          dataType: INTEGER,
        });
      })
      .filter(Boolean);
  },
);

const getFieldDataTypeActionDataItems = getDataTypeActionDataItems('fields');

const getDataTypeMutationDataItems = (action: Action) =>
  getFieldDataTypeActionDataItems(action);

const actions = {
  [CREATE]: new ActionButtonActionConfig({
    Icon: IconSquarePlus,
    deriveScope: getDataTypeMutationScope,
    getDataItems: getDataTypeMutationDataItems,
  }),
  [UPDATE]: new ActionButtonActionConfig({
    Icon: IconEdit,
    deriveScope: getDataTypeMutationScope,
    getDataItems: getDataTypeMutationDataItems,
  }),
  [DELETE]: new ActionButtonActionConfig({
    Icon: IconSquareX,
    deriveScope: getDataTypeMutationScope,
  }),
  [ON_DEMAND]: new ActionButtonActionConfig({
    Icon: IconClick,
  }),
  [IFRAME]: new ActionButtonActionConfig({
    Icon: IconFileCode,
  }),
  [OPEN_STRIPE_PORTAL]: new ActionButtonActionConfig({
    Icon: IconBrandStripe,
  }),

  [NAVIGATE]: new ActionButtonActionConfig({
    Icon: IconLocation,
    getDataItems: (action) => {
      return getDataItemsForPropsShape(
        {
          navigate: new LinkPropType(),
        },
        action,
        {},
        [],
      );
    },
  }),
};

export default actions;
