import React, { useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import { DateTime, Settings } from 'luxon';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, RatingInput, Theme, getColorShade } from '@noloco/components';
import InlineAutoForm from '@noloco/core/src/elements/sections/forms/InlineAutoForm';
import { getDateFromValue } from '@noloco/core/src/utils/dates';
import { getText } from '@noloco/core/src/utils/lang';
import { isMultiField } from '@noloco/core/src/utils/relationships';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import MarkdownText from '../../../components/MarkdownText';
import {
  CollectionLayout,
  ROWS,
  SPLIT,
  TABLE,
  TABLE_FULL,
} from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  INTEGER,
  MULTIPLE_OPTION,
  NUMERIC_DATATYPES,
  SINGLE_OPTION,
} from '../../../constants/dataTypes';
import {
  BUTTON,
  FIELD_CELL,
  IMAGE,
  LINK,
  MARKDOWN,
  TEXT,
} from '../../../constants/elements';
import { CUSTOM_VISIBILITY_RULES } from '../../../constants/features';
import {
  CONDENSED_RELATIONSHIP,
  COUNT,
  JSON_FORMAT,
} from '../../../constants/fieldDisplayTypes';
import {
  CURRENCY,
  DATE as DATE_FORMAT,
  PERCENTAGE,
  RATING,
  UNFORMATTED_NUMBER,
} from '../../../constants/fieldFormats';
import { DEFAULT_PRECISION } from '../../../constants/numbers';
import { BOLD, H1, H2, H3, H4 } from '../../../constants/textTypes';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import { Project } from '../../../models/Project';
import { BaseRecord, RecordValue } from '../../../models/Record';
import { FormFieldConfig } from '../../../models/View';
import { scopeSelector } from '../../../selectors/dataSelectors';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { getOrBuildFormFieldsConfigForType } from '../../../utils/hooks/useFormFields';
import useScopeUser from '../../../utils/hooks/useScopeUser';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import {
  formatPercentageForDisplay,
  getFieldPrecision,
} from '../../../utils/numbers';
import { getAllowedViewRoutePrefixForDataType } from '../../../utils/urls';
import Progress from '../Progress';
import OptionBadge from './OptionBadge';
import RelatedCellItem from './RelatedCellItem';

export const getFormFieldConfig = (
  field: any,
  project: any,
  user: any,
  fieldPermissionsEnabled: any,
  fieldConfig: FormFieldConfig | undefined,
): FormFieldConfig => {
  const allowNewRecords =
    fieldConfig?.allowNewRecords && (field.relationship || field.relatedField);
  const relatedType =
    allowNewRecords && project.dataTypes.getByName(field.type);
  const {
    fields: newRecordFields,
    title: newRecordTitle,
  }: any = allowNewRecords
    ? getOrBuildFormFieldsConfigForType(
        relatedType,
        project,
        user,
        fieldPermissionsEnabled,
        fieldConfig?.newRecordForm,
      )
    : {};

  const config = fieldConfig || ({} as FormFieldConfig);

  return {
    ...config,
    required: config.required,
    placeholder: !field.relationship
      ? config.placeholder || ''
      : config.placeholder,
    allowNewRecords,
    newRecordFields,
    newRecordTitle,
    fieldObj: field,
  } as FormFieldConfig & { newRecordFields: FormFieldConfig[] };
};

export const formatValue = (
  value: RecordValue,
  field: DataField,
  config: any,
) => {
  const { typeOptions: { format } = {}, type } = field;

  if (type === INTEGER || type === DECIMAL) {
    const maximumFractionDigits = getFieldPrecision(
      type,
      field.typeOptions!,
      DEFAULT_PRECISION,
    );
    const minimumFractionDigits = maximumFractionDigits;

    if (format === PERCENTAGE) {
      return `${formatPercentageForDisplay(
        value as number,
        minimumFractionDigits,
      )}%`;
    }

    const roundedValue = round(value as number, maximumFractionDigits);

    const localeStringOptions = {
      maximumFractionDigits,
      minimumFractionDigits,
    };

    if (format === CURRENCY) {
      return `${get(
        field,
        'typeOptions.symbol',
        '$',
      )}${roundedValue.toLocaleString(
        Settings.defaultLocale,
        localeStringOptions,
      )}`;
    }

    if (format === UNFORMATTED_NUMBER) {
      return roundedValue;
    }

    if (roundedValue > 9999) {
      return roundedValue.toLocaleString(
        Settings.defaultLocale,
        localeStringOptions,
      );
    }

    return roundedValue.toFixed(minimumFractionDigits);
  }

  if (type === DATE) {
    let dateValue = getDateFromValue(value);
    const baseFormat =
      format === DATE_FORMAT || config.format === DATE_FORMAT
        ? DateTime.DATE_SHORT
        : DateTime.DATETIME_SHORT;
    if (dateValue) {
      if (format === DATE_FORMAT) {
        dateValue = dateValue.toUTC();
      }

      const timeZone = get(field, 'typeOptions.timeZone');
      if (timeZone) {
        dateValue = dateValue.setZone(timeZone);
      }

      return dateValue
        .toLocaleString(config.dateFormat || baseFormat)
        .replace(',', '');
    }
  }

  return value;
};

const getSafeJSON = (text: string) => {
  try {
    const formattedJSON = JSON.parse(text);
    return JSON.stringify(formattedJSON, undefined, 2);
  } catch {
    return text;
  }
};

const stopPropagation = (e: any) => e.stopPropagation();

const renderElementType = (formattedValue: any, config: any, theme: any) => {
  if (!config.elementType) {
    return formattedValue;
  }

  if (config.elementType === BOLD) {
    return <strong className="font-semibold my-0">{formattedValue}</strong>;
  }

  if (config.elementType === H1) {
    return (
      <h1 className="text-3xl font-black my-0 leading-none pb-2">
        {formattedValue}
      </h1>
    );
  }

  if (config.elementType === H2) {
    return (
      <h2 className="text-2xl font-bold my-0 leading-snug">{formattedValue}</h2>
    );
  }

  if (config.elementType === H3) {
    return (
      <h3 className="text-xl font-semibold my-0 leading-normal">
        {formattedValue}
      </h3>
    );
  }

  if (config.elementType === H4) {
    return (
      <h4 className="text-lg font-semibold my-0 leading-normal">
        {formattedValue}
      </h4>
    );
  }

  if (config.elementType === JSON_FORMAT) {
    return (
      <div className="font-mono tracking-wider">
        {getSafeJSON(formattedValue)}
      </div>
    );
  }

  const linkContent =
    get(config, 'elementConfig.text') ||
    getText('elements.VIEW.fields.elementType.LINK.default');

  if (config.elementType === LINK) {
    const primaryColor = get(theme, 'brandColorGroups.primary');
    if (first(formattedValue) === '/') {
      return (
        <Link
          to={formattedValue}
          className={`text-${getColorShade(
            primaryColor,
            500,
          )} hover:underline hover:text-${getColorShade(primaryColor, 600)}`}
        >
          {linkContent}
        </Link>
      );
    }

    return (
      <a
        href={formattedValue}
        onClick={stopPropagation}
        {...(get(config, 'elementConfig.newTab')
          ? { target: '_blank', rel: 'noopener noreferrer' }
          : {})}
        className={`text-${getColorShade(
          primaryColor,
          500,
        )} hover:underline hover:text-${getColorShade(primaryColor, 600)}`}
      >
        {linkContent}
      </a>
    );
  }

  if (config.elementType === BUTTON) {
    if (first(formattedValue) === '/') {
      return (
        <Link to={formattedValue}>
          <Button className="whitespace-nowrap">{linkContent}</Button>
        </Link>
      );
    }

    return (
      <Button
        is="a"
        className="whitespace-nowrap"
        href={formattedValue}
        onClick={stopPropagation}
        {...(get(config, 'elementConfig.newTab')
          ? { target: '_blank', rel: 'noopener noreferrer' }
          : {})}
      >
        {linkContent}
      </Button>
    );
  }

  if (config.elementType === MARKDOWN) {
    return <MarkdownText>{formattedValue}</MarkdownText>;
  }

  if (config.elementType === IMAGE) {
    return (
      <img
        src={formattedValue}
        alt={get(config, 'elementConfig.alt')}
        className="w-full rounded-lg overflow-hidden"
      />
    );
  }

  return formattedValue;
};

const RelatedFieldCell = ({
  backLink,
  condensed,
  config,
  field,
  value,
  project,
}: any) => {
  const user = useScopeUser();
  const scope = useSelector(scopeSelector);
  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
  const recordLinkRoot = useMemo(
    () =>
      config.elementType !== TEXT
        ? getAllowedViewRoutePrefixForDataType(
            field.type,
            project,
            user,
            scope,
            customRulesEnabled,
          )
        : null,
    [config.elementType, customRulesEnabled, field.type, project, scope, user],
  );
  return (
    <RelatedCellItem
      className="max-w-full"
      condensed={condensed}
      innerClassName=""
      backLink={backLink}
      field={field}
      recordLinkRoot={recordLinkRoot}
      value={value}
      dataTypes={project.dataTypes}
      elementType={config.elementType}
    />
  );
};

export const ReadOnlyFieldCellValue = ({
  backLink,
  field,
  value,
  config,
  layout,
  project,
  theme,
  section,
}: any) => {
  if (!field.relationship && !field.relatedField) {
    if (isNil(value)) {
      return <span className="text-gray-500">--</span>;
    }

    if (field.type === SINGLE_OPTION) {
      const option = field.options.find(({ name }: any) => name === value);
      if (option) {
        return <OptionBadge className="mr-auto" option={option} />;
      }
    }

    if (field.type === MULTIPLE_OPTION) {
      return (
        <div className="max-w-xl flex flex-wrap overflow-hidden">
          {value.map((rawOption: any) => {
            const option = field.options.find(
              ({ name }: any) => name === rawOption,
            );
            if (option) {
              return (
                <OptionBadge
                  className="mr-2 my-1"
                  key={option.name}
                  m={{}}
                  option={option}
                />
              );
            }

            return null;
          })}
        </div>
      );
    }

    if (field.type === BOOLEAN) {
      return value ? (
        <IconCheck size={20} />
      ) : (
        <span className="text-gray-500">--</span>
      );
    }

    if (field.typeOptions?.format === RATING) {
      return (
        <RatingInput
          disabled={true}
          maxRating={get(field.typeOptions, 'max')}
          value={value}
        />
      );
    }

    const formattedValue = formatValue(value, field, config);

    if (
      (layout === TABLE || layout === TABLE_FULL) &&
      (!config.elementType ||
        [MARKDOWN, LINK, BOLD, H1, H2, H3].includes(config.elementType))
    ) {
      const elementResult = renderElementType(formattedValue, config, theme);
      return (
        <div
          className={classNames('max-w-full whitespace-pre-wrap break-words', {
            'h-full': config.truncate,
          })}
        >
          <span
            className={classNames('block', {
              'h-full truncate': config.truncate,
            })}
          >
            {elementResult}
          </span>
          <span
            aria-hidden="true"
            className="block invisible h-0 whitespace-nowrap overflow-hidden"
          >
            {elementResult}
          </span>
        </div>
      );
    }

    if (NUMERIC_DATATYPES.includes(field.type)) {
      return (
        <Progress
          config={config}
          value={value}
          formattedMaxValue={
            formatValue(
              get(config, 'elementConfig.maxValue', 100),
              field,
              config,
            ) as number
          }
          formattedValue={formattedValue as number}
          primaryColor={get(theme, 'brandColors.primary')}
          section={section}
          typeOptions={field.typeOptions}
          layout={layout}
        />
      );
    }

    return (
      <span className="whitespace-pre-wrap break-words">
        {renderElementType(formattedValue, config, theme)}
      </span>
    );
  }

  if (
    isNil(value) ||
    (isMultiField(field) && get(value, 'edges', []).length === 0)
  ) {
    return <span className="text-gray-500">--</span>;
  }

  if (isMultiField(field) && config.elementType === COUNT) {
    return (
      <span className="whitespace-pre-wrap break-words">
        {get(value, 'edges', []).length}
      </span>
    );
  }

  return (
    <RelatedFieldCell
      backLink={backLink}
      condensed={
        isMultiField(field) && config.elementType === CONDENSED_RELATIONSHIP
      }
      config={config}
      field={field}
      value={value}
      project={project}
    />
  );
};

type InlineFieldAutoFormProps = {
  backLink?: string[];
  field: DataField;
  dataType: DataType;
  layout: CollectionLayout;
  project: Project;
  resolvedConfig: FormFieldConfig;
  scope: Record<string, any>;
  skipResolvingForValueIds: string[];
  theme: Theme;
  transformScope: (scope: Record<string, any>) => Record<string, any>;
  value: RecordValue;
  bulkActionsEnabled?: boolean;
  isRowChecked?: boolean;
  selectedRows?: BaseRecord[];
};

const InlineFieldAutoForm = ({
  backLink,
  field,
  dataType,
  layout,
  project,
  resolvedConfig,
  scope,
  theme,
  value,
  bulkActionsEnabled,
  isRowChecked,
  selectedRows,
}: InlineFieldAutoFormProps) => (
  <InlineAutoForm
    className={classNames('w-full max-w-full', {
      'p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700':
        field.type !== BOOLEAN,
    })}
    dataType={dataType}
    elementId="id"
    field={field}
    scope={scope}
    surface="light"
    ReadOnlyCell={() => (
      <ReadOnlyFieldCellValue
        backLink={backLink}
        field={field}
        layout={layout}
        value={value}
        config={resolvedConfig}
        project={project}
        theme={theme}
      />
    )}
    project={project}
    bulkActionsEnabled={bulkActionsEnabled}
    isRowChecked={isRowChecked}
    selectedRows={selectedRows}
  />
);

const FieldCellValue = ({
  backLink,
  config,
  dataType,
  field,
  layout,
  permissions,
  record,
  showInput,
  value,
  project,
  skipResolvingForValueIds = [],
  transformScope = identity,
  theme,
  section,
  bulkActionsEnabled,
  isRowChecked,
  selectedRows,
}: any) => {
  const scope = useMemo(
    () =>
      transformScope(
        {
          id: { edges: { node: record } },
        },
        record,
      ),
    [transformScope, record],
  );

  const resolvedConfig = useSectionScopeVariables(
    FIELD_CELL,
    config,
    project,
    [],
    scope,
  );

  const showInlineForm = useMemo(
    () =>
      showInput && permissions.update && !field.readOnly && !config.elementType,
    [config.elementType, field.readOnly, permissions.update, showInput],
  );

  if (!permissions.read) {
    return null;
  }

  if (showInlineForm) {
    return (
      <InlineFieldAutoForm
        field={field}
        dataType={dataType}
        layout={layout}
        project={project}
        resolvedConfig={resolvedConfig}
        scope={scope}
        skipResolvingForValueIds={skipResolvingForValueIds}
        theme={theme}
        transformScope={transformScope}
        value={value}
        bulkActionsEnabled={bulkActionsEnabled}
        isRowChecked={isRowChecked}
        selectedRows={selectedRows}
      />
    );
  }

  return (
    <ReadOnlyFieldCellValue
      backLink={backLink}
      field={field}
      layout={layout}
      value={value}
      config={resolvedConfig}
      project={project}
      theme={theme}
      section={section}
    />
  );
};

const FieldCell = ({
  backLink,
  className,
  config,
  dataType,
  field,
  transformScope,
  layout,
  permissions,
  value,
  record,
  showInput,
  showLabel,
  project,
  theme,
  readOnly,
  skipResolvingForValueIds,
  section = null,
  bulkActionsEnabled,
  isRowChecked,
  selectedRows,
}: any) => {
  const isTable = layout === TABLE || layout === TABLE_FULL;

  const [isDarkModeEnabled] = useDarkMode();

  const textColor = isDarkModeEnabled
    ? darkModeColors.text.secondary
    : 'text-gray-500';

  const alignRight =
    isTable && (field.type === DECIMAL || field.type === INTEGER);
  const columnWidth =
    config.columnWidth || (config.columnSpan ? config.columnSpan * 4 : null);
  const isGridLayout = layout === ROWS || layout === SPLIT;

  return (
    <div
      className={classNames(className, 'max-w-full w-full', {
        'md:col-span-12': isGridLayout,
        'col-span-3 lg:col-span-6':
          isGridLayout &&
          (columnWidth === 3 || (!columnWidth && layout === ROWS)),
        'col-span-4 lg:col-span-6':
          isGridLayout &&
          (columnWidth === 4 || (!columnWidth && layout === SPLIT)),
        'col-span-6': isGridLayout && columnWidth === 6,
        'col-span-8': isGridLayout && columnWidth === 8,
        'col-span-9': isGridLayout && columnWidth === 9,
        'col-span-12': isGridLayout && columnWidth === 12,
        'mr-auto': !alignRight,
      })}
      data-testid="field-cell"
    >
      <div
        className={classNames(className, { 'w-full text-right': alignRight })}
      >
        {showLabel && config.label && !config.label.hidden && (
          <span
            className={classNames(
              'font-medium text-xs mb-2 tracking-wider',
              textColor,
            )}
            data-testid="field-cell-label"
          >
            {config.label.value}
          </span>
        )}
        <FieldCellValue
          backLink={backLink}
          config={config}
          dataType={dataType}
          field={field}
          layout={layout}
          permissions={permissions}
          showInput={(showInput || config.editInline) && !readOnly}
          record={record}
          value={value}
          showLabel={showLabel}
          project={project}
          theme={theme}
          skipResolvingForValueIds={skipResolvingForValueIds}
          transformScope={transformScope}
          section={section}
          bulkActionsEnabled={bulkActionsEnabled}
          isRowChecked={isRowChecked}
          selectedRows={selectedRows}
        />
      </div>
    </div>
  );
};

FieldCell.defaultProps = {
  columns: 2,
  skipResolvingForValueIds: [],
};

export default withTheme(FieldCell);
