import React, { forwardRef } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { getColorShade } from '@noloco/components';
import { darkModeColors } from '../../../../constants/darkModeColors';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import useSelectRecordOnCmdClick from '../../../../utils/hooks/useSelectRecordOnCmdClick';
import RowFieldCell from './RowFieldCell';

const RowRecordLayout = forwardRef<HTMLDivElement, any>(
  (
    {
      actionButtons,
      className,
      dataType,
      elementId,
      fieldConfigs,
      layout,
      record,
      project,
      rowLink,
      transformRecordScope,
      bulkActionsEnabled = false,
      isRowChecked = false,
      handleCheckboxChange,
      selectedRows,
      theme,
      ...rest
    },
    ref,
  ) => {
    const Row = rowLink ? Link : 'div';
    const [isDarkModeEnabled] = useDarkMode();
    const selectRecordOnCmdClick = useSelectRecordOnCmdClick(
      handleCheckboxChange,
      isRowChecked,
      selectedRows,
    );

    const primaryColor = theme.brandColors.primary;

    return (
      <Row
        to={rowLink}
        onClick={selectRecordOnCmdClick}
        className={classNames(
          className,
          `px-6 py-3 flex flex-col items-center w-full hover:${
            isDarkModeEnabled
              ? darkModeColors.surfaces.elevation2
              : 'bg-gray-100'
          } hover:bg-opacity-50 relative group text-sm`,
          {
            [`bg-${getColorShade(
              primaryColor,
              100,
            )} bg-opacity-75 dark:bg-${getColorShade(
              primaryColor,
              900,
            )}`]: isRowChecked,
          },
        )}
        data-testid="collection-record"
        ref={ref}
        {...rest}
      >
        <div className="w-full grid grid-cols-12 gap-x-6 gap-y-4 sm:pl-0 my-4">
          {fieldConfigs.map(
            ({ field, parent, parentFieldType, config, permissions }: any) => (
              <RowFieldCell
                // @ts-expect-error TS(2322): Type '{ config: any; dataType: any; elementId: any... Remove this comment to see the full error message
                config={config}
                dataType={parentFieldType || dataType}
                elementId={elementId}
                field={field}
                key={`${field.name}:${(parentFieldType || dataType).name}`}
                parent={parent}
                layout={layout}
                permissions={permissions}
                record={record}
                project={project}
                transformRecordScope={transformRecordScope}
                bulkActionsEnabled={bulkActionsEnabled}
                isRowChecked={isRowChecked}
                selectedRows={selectedRows}
              />
            ),
          )}
        </div>
        {actionButtons}
      </Row>
    );
  },
);

export default withTheme(RowRecordLayout);
