import React, { memo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { TailwindThemeProvider } from '@darraghmckay/tailwind-react-ui';
import { ErrorBoundary } from '@sentry/react';
import T from 'i18n-react';
import { Provider as AlertProvider } from 'react-alert';
import 'react-datepicker/dist/react-datepicker.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'simplebar/dist/simplebar.min.css';
import { Notification as AlertNotification } from '@noloco/components';
import '@noloco/components/src/index.css';
import { LocaleProvider } from '@noloco/components/src/utils/hooks/useLocale';
import coreTexts from '@noloco/core/src/lang/en';
import useAppTheme from '@noloco/core/src/utils/hooks/useAppTheme';
import '@noloco/renderer/src/styles/index.scss';
import ProjectErrorMessage from '@noloco/ui/src/components/ProjectErrorMessage';
import uiTexts from '@noloco/ui/src/lang/en';
import websiteExamplesText from '../lang/en';
import '../styles/index.css';
import '../styles/tailwind.generated.css';

T.setTexts(
  {
    ...coreTexts,
    ...uiTexts,
    ...websiteExamplesText,
  },
  { MDFlavor: 1 },
);

const App = memo(({ children, client, project }) => {
  const projectTheme = useAppTheme(project.settings);

  return (
    <React.StrictMode>
      <ApolloProvider client={client}>
        <LocaleProvider>
          <DndProvider backend={HTML5Backend}>
            <AlertProvider template={AlertNotification} offset="6px 18px">
              <ErrorBoundary fallback={ProjectErrorMessage}>
                <TailwindThemeProvider theme={projectTheme}>
                  {children}
                </TailwindThemeProvider>
              </ErrorBoundary>
            </AlertProvider>
          </DndProvider>
        </LocaleProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
});

App.defaultProps = {
  ssrMode: false,
};

export default App;
