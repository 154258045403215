import { useCallback, useEffect, useRef, useState } from 'react';

const useHover = (
  disabled = false,
  { preventDefault, stopPropagation }: any,
) => {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = useCallback(
    (event: any) => {
      if (preventDefault) {
        event.preventDefault();
      }
      if (stopPropagation) {
        event.stopPropagation();
      }
      setValue(true);
    },
    [preventDefault, stopPropagation],
  );
  const handleMouseOut = useCallback(() => setValue(false), [setValue]);

  useEffect(() => {
    if (!disabled) {
      const node = ref.current;
      if (node) {
        (node as any).addEventListener('mouseover', handleMouseOver);
        (node as any).addEventListener('mouseout', handleMouseOut);
        return () => {
          (node as any).removeEventListener('mouseover', handleMouseOver);
          (node as any).removeEventListener('mouseout', handleMouseOut);
          ref.current = null;
          setValue(false);
        };
      }
    }
  }, [disabled, handleMouseOut, handleMouseOver]);

  return [ref, value];
};

export default useHover;
