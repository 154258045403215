export const ROWS = 'ROWS';
export const COLUMNS = 'COLUMNS';
export const CARDS = 'CARDS';
export const TABLE = 'TABLE';
export const TABLE_FULL = 'TABLE_FULL';
export const BOARD = 'BOARD';
export const SPLIT = 'SPLIT';
export const SINGLE_RECORD = 'SINGLE_RECORD';
export const CONVERSATION = 'CONVERSATION';
export const CALENDAR = 'CALENDAR';
export const TIMELINE = 'TIMELINE';
export const GANTT = 'GANTT';
export const CHARTS = 'CHARTS';
export const MAP = 'MAP';

const collectionLayouts = [
  ROWS,
  COLUMNS,
  CARDS,
  TABLE,
  TABLE_FULL,
  CONVERSATION,
  BOARD,
  CALENDAR,
  TIMELINE,
  GANTT,
  CHARTS,
  MAP,
];

export const EVENT_BASED_LAYOUTS = [TIMELINE, GANTT, CALENDAR];

export type CollectionLayout =
  | 'ROWS'
  | 'COLUMNS'
  | 'CARDS'
  | 'TABLE'
  | 'TABLE_FULL'
  | 'CONVERSATION'
  | 'BOARD'
  | 'SPLIT'
  | 'CALENDAR'
  | 'TIMELINE'
  | 'GANTT'
  | 'CHARTS'
  | 'MAP'
  | 'SINGLE_RECORD';

export default collectionLayouts;
