import React, { forwardRef, useCallback, useMemo } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import {
  IconAlertOctagon,
  IconAlertTriangle,
  IconCircleCheck,
  IconInfoCircle,
  IconX,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { DANGER, INFO, PRIMARY, WARNING } from '../../constants/variants';

const variantIcon = (type: any) =>
  ({
    [DANGER]: IconAlertOctagon,
    [WARNING]: IconAlertTriangle,
    [INFO]: IconInfoCircle,
    [PRIMARY]: IconCircleCheck,
  }[type] || IconCircleCheck);

const Notification = forwardRef(
  // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
  ({ className, style, options, message, close, theme }, ref) => {
    const color = useMemo(
      () =>
        ({
          [DANGER]: theme.brandColors[DANGER],
          [WARNING]: theme.brandColors[WARNING],
          [INFO]: theme.brandColors.primary,
          [PRIMARY]: theme.brandColors.success,
        }[options.type] || theme.brandColors.primary),
      [options.type, theme.brandColors],
    );

    const Icon = variantIcon(options.type);

    const onClick = useCallback(() => {
      if (!options.onClick) {
        return;
      }

      options.onClick();
    }, [options]);

    const onClose = useCallback(
      (e) => {
        e.stopPropagation();

        if (options.onDismiss) {
          options.onDismiss();
        }

        close();
      },
      [close, options],
    );

    return (
      // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
      <div ref={ref} style={style}>
        <div
          className={classNames(
            className,
            'rounded-lg bg-white shadow-lg border p-3 flex w-full max-w-sm',
            options.text ? 'items-start' : 'items-center',
            {
              'cursor-pointer': options.onClick,
            },
          )}
          onClick={onClick}
        >
          <Box className="flex flex-shrink-0" text={color}>
            {options.icon ? (
              <img
                alt="Notification icon"
                className="h-6 w-6 rounded-full"
                src={options.icon}
              />
            ) : (
              <Icon size={24} />
            )}
          </Box>
          <div className="flex flex-grow flex-col mx-3 mt-.5">
            <span className="font-medium text-sm">{message}</span>
            {options.text && (
              <span className="text-gray-600 font-light whitespace-pre-wrap text-xs">
                {options.text}
              </span>
            )}
          </div>
          {close && (
            <button
              className="flex flex-shrink-0 text-gray-500"
              onClick={onClose}
            >
              <IconX size={16} />
            </button>
          )}
        </div>
      </div>
    );
  },
);

export default withTheme(Notification);
