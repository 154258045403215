import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getColorShade } from '../../utils/colors';
import { ROUNDED_FULL } from '../button/buttonStyles';
import {
  BOLD,
  BORDERED,
  FULL_BACKGROUND,
  FULL_BACKGROUND_BORDER,
  ROUNDED,
} from './verticalNavTypes';

export const typeStyles = (colorGroup: any) => ({
  [FULL_BACKGROUND]: {
    p: { x: 3, y: 0.5 },
    'bg-hocus': getColorShade(colorGroup, 600),
    text: getColorShade(colorGroup, 200),
  },

  [FULL_BACKGROUND_BORDER]: {
    p: { x: 3, y: 0.5 },
    'bg-hocus': getColorShade(colorGroup, 600),
    text: getColorShade(colorGroup, 200),
  },

  [ROUNDED]: {
    rounded: 'lg',
    m: { x: 3, y: 0.5 },
    p: { x: 3, y: 0.5 },
    'bg-hocus': getColorShade(colorGroup, 600),
    text: getColorShade(colorGroup, 200),
  },

  [ROUNDED_FULL]: {
    rounded: 'full',
    m: { x: 3, y: 0.5 },
    p: { x: 3, y: 0.5 },
    'bg-hocus': getColorShade(colorGroup, 600),
    text: ['xs', getColorShade(colorGroup, 200)],
  },

  [BOLD]: {
    p: { x: 3, y: 2 },
    'bg-hocus': getColorShade(colorGroup, 600),
    text: getColorShade(colorGroup, 200),
  },

  [BORDERED]: {
    p: { x: 3, y: 3 },
    borderB: 2,
    'bg-hocus': getColorShade(colorGroup, 600),
    text: getColorShade(colorGroup, 200),
  },
});

const activeTypeStyles = (color: any) => ({
  [FULL_BACKGROUND]: {
    bg: getColorShade(color, 900),
    'bg-hocus': 'gray',
    text: 'white',
  },

  [FULL_BACKGROUND_BORDER]: {
    bg: getColorShade(color, 900),
    border: 'white',
    borderL: 4,
    p: { x: 5, y: 0.5 },
    'bg-hocus': 'gray',
    text: 'white',
  },

  [ROUNDED]: {
    bg: getColorShade(color, 900),
    'bg-hocus': undefined,
    rounded: 'lg',
    text: 'white',
  },

  [ROUNDED_FULL]: {
    bg: getColorShade(color, 900),
    'bg-hocus': undefined,
    rounded: 'full',
    text: 'white',
  },

  [BOLD]: { font: 'bold', text: 'white' },
  [BORDERED]: { bg: getColorShade(color, 900), text: 'white' },
});

type VerticalNavItemProps = {
  active?: boolean;
  className?: string;
  type?: any; // TODO: PropTypes.oneOf(verticalNavTypes)
  theme: {};
};

const VerticalNavItem = forwardRef<any, VerticalNavItemProps>(
  (
    // @ts-expect-error TS(2339): Property 'children' does not exist on type 'Vertic... Remove this comment to see the full error message
    { active, children, onClick, className, is, to, type, theme, ...rest },
    ref,
  ) => {
    const primaryColor = (theme as any).brandColorGroups.primary;
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const styles = typeStyles(primaryColor)[type];
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const activeStyles = active ? activeTypeStyles(primaryColor)[type] : {};
    return (
      <Box
        className={classNames(className, 'cursor-pointer')}
        onClick={onClick}
        ref={ref}
        is={is}
        to={to}
        {...styles}
        {...activeStyles}
        {...rest}
      >
        {children}
      </Box>
    );
  },
);

VerticalNavItem.defaultProps = {
  active: false,
  className: '',
  // @ts-expect-error TS(2322): Type '{ active: false; className: string; is: stri... Remove this comment to see the full error message
  is: 'a',
  type: FULL_BACKGROUND,
};

export default withTheme(VerticalNavItem);
