// TEXT
export const SINGLE_LINE_TEXT: FieldFormat = 'singleLine';
export const MULTILINE_TEXT: FieldFormat = 'multiline';

// NUMBERS (INTEGER / DECIMAL)
export const PERCENTAGE: FieldFormat = 'percentage';
export const CURRENCY: FieldFormat = 'currency';
export const UNFORMATTED_NUMBER: FieldFormat = 'unformatted-number';
export const RATING: FieldFormat = 'rating';

// DATES
export const DATE: FieldFormat = 'date';
export const DATE_TIME: FieldFormat = 'dateTime';

export type FieldFormat =
  | 'singleLine'
  | 'multiline'
  | 'percentage'
  | 'currency'
  | 'unformatted-number'
  | 'rating'
  | 'date'
  | 'dateTime';
