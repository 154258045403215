import React, { forwardRef } from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';
import caretSizes from './caretSizes';

const Option = forwardRef(
  (
    {
      // @ts-expect-error TS(2339): Property 'active' does not exist on type '{}'.
      active,
      // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
      className,
      // @ts-expect-error TS(2339): Property 'hasChildOptions' does not exist on type ... Remove this comment to see the full error message
      hasChildOptions,
      // @ts-expect-error TS(2339): Property 'size' does not exist on type '{}'.
      size,
      // @ts-expect-error TS(2339): Property 'option' does not exist on type '{}'.
      option,
      // @ts-expect-error TS(2339): Property 'minW' does not exist on type '{}'.
      minW,
      // @ts-expect-error TS(2339): Property 'selected' does not exist on type '{}'.
      selected = false,
      // @ts-expect-error TS(2339): Property 'useButtonLabel' does not exist on type '... Remove this comment to see the full error message
      useButtonLabel = false,
      // @ts-expect-error TS(2339): Property 'coloredOptionType' does not exist on type '... Remove this comment to see the full error message
      coloredOptionType = false,
      ...rest
    },
    ref,
  ) =>
    // @ts-expect-error TS(2322): Type 'false | Element' is not assignable to type '... Remove this comment to see the full error message
    (!hasChildOptions || option.options.length > 0) && (
      <div
        className={classNames(
          className,
          'flex flex-col w-100 cursor-pointer select-none relative max-w-full rounded-lg',
          minW,
          {
            'text-bold': selected && !coloredOptionType,
            'font-medium bg-blue-400 bg-opacity-75': active,
            'text-blue-800': active && !coloredOptionType,
            'opacity-75 cursor-not-allowed': option.disabled,
          },
        )}
        onClick={option.onClick ? () => option.onClick(option) : undefined}
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ref}
        {...rest}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center max-w-full">
            {option.icon && <span className="mr-2">{option.icon}</span>}
            <div className="flex flex-col max-w-full">
              <span
                className={classNames(
                  'block truncate flex items-center',
                  selected && !coloredOptionType
                    ? 'font-semibold'
                    : 'font-normal',
                  { 'text-xs uppercase text-gray-400': option.heading },
                )}
                data-testid="select-option-label"
              >
                {(useButtonLabel && option.buttonLabel) ||
                  option.label ||
                  option.value}
              </span>
              {option.help && (
                <span
                  className="font-normal block truncate text-sm mt-1"
                  data-testid="select-option-help"
                >
                  {option.help}
                </span>
              )}
            </div>
          </div>
          {hasChildOptions && !option.heading && (
            <span className="pl-2 pointer-events-none">
              <IconChevronRight size={caretSizes[size]} />
            </span>
          )}
        </div>
      </div>
    ),
);

export default Option;
