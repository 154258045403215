import { IconSquare, Icon as IconType } from '@tabler/icons-react';
import DataTypes, { DataType } from './DataTypes';
import { Action, DepValue } from './Element';
import StateItem from './StateItem';
import { WorkflowAction } from './Workflow';

export type WorkflowScopeOption = {
  value?: StateItem;
  label: string;
  buttonLabel?: string;
  options?: WorkflowScopeOption[];
};

type DeriveScope<ActionType> = (
  action: ActionType,
  dataTypes: DataTypes,
  index: number,
) => WorkflowScopeOption[];

type GetDataItems<ActionType> = (actionType: ActionType) => DepValue[];

interface ActionConfigConstructor<ActionType> {
  deriveScope?: DeriveScope<ActionType>;
  Icon?: IconType;
  getDataItems?: GetDataItems<ActionType>;
}

class ActionConfig<ActionType> {
  Icon: IconType;
  deriveScope: DeriveScope<ActionType>;
  getDataItems: (action: ActionType) => DepValue[];

  constructor({
    deriveScope = () => [],
    getDataItems = () => [],
    Icon = IconSquare,
  }: ActionConfigConstructor<ActionType> = {}) {
    this.Icon = Icon;
    this.deriveScope = deriveScope;
    this.getDataItems = getDataItems;
  }
}

type DeriveScopeDataType = (
  action: WorkflowAction,
  dataTypes: DataTypes,
  index: number,
) => DataType | undefined;

type WorkflowActionConfigConstructor = ActionConfigConstructor<
  WorkflowAction
> & {
  deriveScopeDataType?: DeriveScopeDataType;
};

export class WorkflowActionConfig extends ActionConfig<WorkflowAction> {
  deriveScopeDataType: DeriveScopeDataType;

  constructor({
    deriveScopeDataType = () => undefined,
    ...rest
  }: WorkflowActionConfigConstructor) {
    super(rest);
    this.deriveScopeDataType = deriveScopeDataType;
  }
}
export class ActionButtonActionConfig extends ActionConfig<Action> {}

export default ActionConfig;
