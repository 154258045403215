import React, { useMemo } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { Loader } from '@noloco/components';
import useAuthWrapper from '@noloco/core/src/utils/hooks/useAuthWrapper';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { CUSTOM_VISIBILITY_RULES } from '../constants/features';
import { Element } from '../models/Element';
import { scopeSelector } from '../selectors/dataSelectors';
import {
  editingPageSelector,
  editorModeSelector,
} from '../selectors/elementsSelectors';
import { lookupOfArray } from '../utils/arrays';
import { getText } from '../utils/lang';
import { getVisibleRoutes } from '../utils/navigation';
import { replaceDoubleSlashes } from '../utils/pages';

type Props = {
  children?: React.ReactNode;
  [restProps: string]: any;
};

const PageSwitch = ({ children, project }: Props) => {
  // This could be a risk, but it solves the issue of figuring out which sub-routes to render
  const scope = useSelector(scopeSelector);

  const elementMap = useMemo(
    () => lookupOfArray<Element>(project.elements, 'id'),
    [project.elements],
  );

  const editorMode = useSelector(editorModeSelector);
  const editingPage = useSelector(editingPageSelector);
  const { fetchedUser, user, loading } = useAuthWrapper();

  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);

  const childRoutes = useMemo(
    () =>
      getVisibleRoutes(
        React.Children.toArray(children) as any,
        elementMap,
        fetchedUser,
        project,
        user,
        scope,
        editorMode,
        customRulesEnabled,
      ),
    [
      children,
      customRulesEnabled,
      editorMode,
      elementMap,
      fetchedUser,
      project,
      scope,
      user,
    ],
  );

  const firstRoute = useMemo(
    () =>
      childRoutes.find(
        ({ element, parentPageId }) =>
          !get(element, 'props.dataType') &&
          element.id !== 'PROFILE' &&
          !parentPageId,
      ),
    [childRoutes],
  );

  const firstRoutePath = useMemo(
    () =>
      firstRoute && firstRoute.path
        ? `${
            firstRoute.path.indexOf('/') !== 0 ? '/' : ''
          }${replaceDoubleSlashes(firstRoute.path)}${document.location.search}`
        : undefined,
    [firstRoute],
  );

  if (loading) {
    return (
      <div className="h-screen text-gray-800 dark:text-white w-full flex items-center justify-center">
        <Loader size="lg" />
      </div>
    );
  }

  return (
    <Switch>
      {childRoutes
        .filter((route) => !route.parentPageId)
        .map((route) => (
          <Route
            // To ensure that on editing page URL path, the app doesn't redirect to the first page.
            path={editorMode && route.id === editingPage ? '' : route.path}
            exact={route.path === '/'}
            key={route.id}
          >
            <Switch>
              {childRoutes
                .filter((childRoute) => childRoute.parentPageId === route.id)
                .map((childRoute) => (
                  <Route
                    exact={false}
                    key={childRoute.id}
                    path={
                      editorMode && childRoute.id === editingPage
                        ? ''
                        : childRoute.path
                    }
                  >
                    {childRoute.child as any}
                  </Route>
                ))}
              <Route>{route.child as any}</Route>
            </Switch>
          </Route>
        ))}
      {firstRoutePath && <Redirect to={firstRoutePath} />}
      {!firstRoute && (
        <Route>
          <div className="w-full text-center p-32">
            {getText('errors.pageNotFound')}
          </div>
        </Route>
      )}
    </Switch>
  );
};

PageSwitch.defaultProps = {
  children: undefined,
};

export default PageSwitch;
