import React, { forwardRef, useCallback, useState } from 'react';
import { IconArrowLeft } from '@tabler/icons-react';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';
import useScopeUser from '../utils/hooks/useScopeUser';
import { isInternal } from '../utils/user';

const InternalLayoutWrapper = forwardRef(
  (
    {
      // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
      className,
      children,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type '{}'.
      onClick,
      // @ts-expect-error TS(2339): Property 'headerContent' does not exist on type '{... Remove this comment to see the full error message
      headerContent,
      // @ts-expect-error TS(2339): Property 'innerClassName' does not exist on type '... Remove this comment to see the full error message
      innerClassName,
      // @ts-expect-error TS(2339): Property 'sidebarContent' does not exist on type '... Remove this comment to see the full error message
      sidebarContent,
    },
    ref,
  ) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const user = useScopeUser();

    const handleOnClick = useCallback(
      (event: any) => {
        event.stopPropagation();
        if (onClick) {
          onClick(event);
        }
      },
      [onClick],
    );

    return (
      <div
        className={classNames(
          className,
          'absolute top-0 bottom-0 left-0 right-0 w-full bg-gray-50 max-h-screen h-screen sm:h-auto overflow-hidden flex flex-grow',
        )}
        onClick={handleOnClick}
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ref}
      >
        {isInternal(user) && (
          <>
            {/* @ts-expect-error TS2786: 'SimpleBar' cannot be used as a JSX component. */}
            <SimpleBar
              className={classNames(
                'flex flex-col flex-shrink-0 bg-white w-80 sm:w-full border-r border-l h-full overflow-y-auto overflow-x-hidden',
                { 'sm:hidden': !showSidebar },
              )}
              onClick={() => setShowSidebar(false)}
            >
              <div className="flex flex-col">{sidebarContent}</div>
            </SimpleBar>
          </>
        )}
        <div
          className={classNames(
            'flex flex-col flex-grow overflow-x-hidden',
            {
              'border-r': isInternal(user),
            },
            innerClassName,
          )}
        >
          {isInternal(user) && (
            <div className="bg-white px-6 py-4 w-full shadow border-b flex items-center">
              <div
                className="mb-auto mt-2 text-gray-800 p-2 hidden sm:flex mr-4"
                onClick={() => setShowSidebar(true)}
              >
                <IconArrowLeft size={16} />
              </div>
              {headerContent}
            </div>
          )}
          {children}
        </div>
      </div>
    );
  },
);

export default InternalLayoutWrapper;
