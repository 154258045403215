import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import useAuthWrapper from '@noloco/core/src/utils/hooks/useAuthWrapper';
import { getText } from '@noloco/core/src/utils/lang';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { ROWS } from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import { User } from '../../../models/User';
import { conditionsAreMet } from '../../../utils/data';
import { getRootFieldDataType } from '../../../utils/fields';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useMergedScope from '../../../utils/hooks/useMergedScope';
import { mapFieldsWithPermissionsAndConfig } from '../../../utils/permissions';
import Highlights from '../Highlights';
import FieldCell from '../collections/FieldCell';

const RecordHighlights = ({
  backLink,
  columns,
  dataType,
  editorMode,
  fields,
  project,
  record,
  rootField,
  recordScope,
  sectionWidth,
}: any) => {
  const { user } = useAuthWrapper();

  const [isDarkModeEnabled] = useDarkMode();

  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);

  const rootDataType = useMemo(
    () => getRootFieldDataType(rootField, dataType, project.dataTypes),
    [dataType, project.dataTypes, rootField],
  );

  const fieldConfigs = useMemo(
    () =>
      mapFieldsWithPermissionsAndConfig(
        fields,
        rootDataType,
        user as User,
        project.dataTypes,
        fieldPermissionsEnabled,
      ),
    [fieldPermissionsEnabled, fields, project.dataTypes, rootDataType, user],
  );

  const rootFieldObject = useMemo(
    () => rootField && dataType.fields.getByName(rootField),
    [dataType.fields, rootField],
  );

  const valuePrefix = useMemo(
    () => (rootFieldObject ? [rootFieldObject.apiName] : []),
    [rootFieldObject],
  );

  const scope = useMergedScope(recordScope);
  const transformRecordScope = useCallback(
    (currentScope: any) => ({
      ...currentScope,
      ...scope,
    }),
    [scope],
  );

  const highlights = useMemo(
    () =>
      fieldConfigs
        .filter(
          ({ config }) =>
            !(config as any).conditions ||
            conditionsAreMet(
              (config as any).conditions,
              scope,
              project,
              rootDataType,
            ),
        )
        .map(({ field, permissions, config }) => ({
          id: (config as any).id || `field_${field.id}`,
          columnWidth: (config as any).columnWidth,
          className: classNames('md:col-span-1', {
            'col-span-3 lg:col-span-6':
              (config as any).columnWidth === 3 || !(config as any).columnWidth,
            'col-span-4 lg:col-span-6': (config as any).columnWidth === 4,
            'col-span-6': (config as any).columnWidth === 6,
            'col-span-8 lg:col-span-12': (config as any).columnWidth === 8,
            'col-span-9 lg:col-span-12': (config as any).columnWidth === 9,
            'col-span-12': (config as any).columnWidth === 12,
          }),
          label: (config as any).label.hidden
            ? null
            : (config as any).label.value,
          text: (
            <FieldCell
              backLink={backLink}
              columns={columns}
              dataType={rootDataType}
              showLabel={false}
              config={config}
              field={field}
              layout={ROWS}
              className="flex flex-col"
              permissions={permissions}
              record={record}
              value={get(record, [...valuePrefix, field.apiName])}
              project={project}
              key={field.name}
              section={true}
              transformScope={transformRecordScope}
            />
          ),
        })),
    [
      fieldConfigs,
      scope,
      project,
      rootDataType,
      backLink,
      columns,
      record,
      valuePrefix,
      transformRecordScope,
    ],
  );

  if (highlights.length === 0 && !editorMode) {
    return null;
  }

  if (highlights.length === 0 && editorMode) {
    return (
      <div
        className={`border rounded-lg shadow-md flex items-center justify-center p-8 ${
          isDarkModeEnabled
            ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.two}`
            : 'bg-white border-gray-200'
        }`}
      >
        <div className="text-center w-full text-gray-600 py-24">
          {getText('elements.DETAILS.view.empty')}
        </div>
      </div>
    );
  }

  return (
    <Highlights
      highlights={highlights}
      editorMode={false}
      sectionWidth={sectionWidth}
    />
  );
};

RecordHighlights.defaultProps = {
  fields: [],
};

export default RecordHighlights;
