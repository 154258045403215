import React, { forwardRef } from 'react';
import { Text as BaseText, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { Button, getColorShade } from '@noloco/components';
import { BUTTON, LINK } from '@noloco/core/src/constants/elements';
import { SPAN } from '@noloco/core/src/constants/textTypes';

const Text = forwardRef(
  // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
  ({ className, children, onClick, type, style, attributes, theme }, ref) => {
    const primaryColor = theme.brandColors.primary;

    const textItem = (
      <BaseText
        ref={ref}
        {...attributes}
        className={classNames(className, {
          [`text-${getColorShade(
            primaryColor,
            500,
          )} hover:underline hover:text-${getColorShade(primaryColor, 700)}`]:
            type === LINK,
        })}
        onClick={onClick}
        is={!type || type === LINK || type === BUTTON ? SPAN : type}
        style={style}
      >
        {children}
      </BaseText>
    );

    if (type === BUTTON) {
      return <Button className="w-full whitespace-nowrap">{textItem}</Button>;
    }

    return textItem;
  },
);

Text.propTypes = BaseText.propTypes;

Text.defaultProps = {
  // @ts-expect-error TS(2322): Type '{ type: string; }' is not assignable to type... Remove this comment to see the full error message
  type: 'span',
};

export default withTheme(Text);
