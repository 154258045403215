import { DateTime, DateTimeOptions } from 'luxon';
import DATE_FORMATS from '../constants/dateTimeFormats';

export const YEAR_MONTH_DATE_FORMAT = 'yyyy-MM-dd';
export const YMD_TIME_FORMAT = `${YEAR_MONTH_DATE_FORMAT}-HH-mm`;

export const getDateFromValue = (
  value: any,
  options?: DateTimeOptions,
): DateTime | null => {
  for (let d = 0; d < DATE_FORMATS.length; d++) {
    try {
      const parsedDate = DATE_FORMATS[d](value, options);
      if (parsedDate.isValid) {
        return parsedDate;
      }
    } catch {
      // We don't do anything to explicitly handle
    }
  }

  return null;
};
