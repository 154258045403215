const exampleBillingPlan = {
  data: {
    billingPlan: {
      type: 'ENTERPRISE',
      team: {
        name: 'noloco',
        id: '1',
        __typename: 'NolocoTeam',
      },
      createdAt: '2021-11-10T09:32:38.836Z',
      status: 'active',
      addons: {
        externalUsers: null,
        __typename: 'Addons',
      },
    },
  },
};

export default exampleBillingPlan;
