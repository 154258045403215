import React, { forwardRef, useMemo } from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { connect } from 'react-redux';
import { API_REQUEST } from '../constants/dataWrapperTypes';
import { QUERY } from '../constants/endpointTypes';
import { Element, ElementPath } from '../models/Element';
import { Project } from '../models/Project';
import useCollectionQuery from '../utils/hooks/useCollectionQuery';
import useMergedScope from '../utils/hooks/useMergedScope';
import { getText } from '../utils/lang';
import { flattenStateItem } from '../utils/state';
import { DataGetQueryWrapper, getVariables } from './DataWrapper';

type InternalDataListWrapperProps = {
  additionalDeps?: any[];
  after?: any;
  autoRefresh: boolean;
  before?: any;
  countOnly?: boolean;
  children: (...args: any[]) => any;
  customFilters?: any[];
  dataType: string;
  element: Element;
  elementPath: ElementPath;
  filter?: any;
  limit?: number;
  orderBy?: {
    direction?: any; // TODO: PropTypes.oneOf(orderByDirections)
    field?: string;
  };
  project: Project;
};

const InternalDataListWrapper = ({
  additionalDeps,
  after,
  before,
  autoRefresh,
  children,
  countOnly,
  dataType,
  element,
  elementPath,
  filter,
  customFilters,
  project,
  limit,
  orderBy,
}: InternalDataListWrapperProps) => {
  const {
    edges,
    listDataType,
    rawData,
    nodes,
    pageInfo,
    totalCount,
    nodeQueryObject,
    connection,
    loading,
    error,
    parentValuePath,
    valuePath,
    skip,
  } = useCollectionQuery(dataType, project, element, elementPath, {
    additionalDeps,
    after,
    before,
    autoRefresh,
    countOnly,
    customFilters,
    filter,
    limit,
    orderBy,
  });

  if (!dataType || !listDataType) {
    return children({
      loading: false,
      limit,
      edges,
      totalCount,
      pageInfo: { hasNextPage: false },
      parentValuePath,
      nodeQueryObject,
      nodes,
    });
  }

  if (skip) {
    return (
      <div className="p-4 bg-red-500 rounded w-full text-white my-4 col-span-3 md:col-span-0">
        {error ||
          getText(
            { dataType },
            'errors',
            listDataType ? 'list' : 'typeNotFound',
          )}
      </div>
    );
  }
  return children({
    edges,
    rawData,
    nodes,
    pageInfo,
    totalCount,
    nodeQueryObject,
    connection,
    loading,
    error,
    valuePath,
  });
};

const sliceEdges = (slicedData: any) =>
  slicedData.map((edge: any) => ({
    data: edge,
  }));

const ApiRequestChildren = forwardRef(
  // @ts-expect-error TS(2339): Property 'children' does not exist on type '{}'.
  ({ children, data, dataPath, limit, loading }, ref) => {
    const slicedData = useMemo(() => (limit ? data.slice(0, limit) : data), [
      data,
      limit,
    ]);
    const edges = useMemo(() => sliceEdges(slicedData), [slicedData]);
    // @ts-expect-error this expression is not callable
    return children({
      ref,
      edges,
      nodes: edges,
      connection: null,
      loading: loading,
      error: null,
      valuePath: dataPath,
    });
  },
);

const ApiRequestDataListWrapper = forwardRef(
  (
    // @ts-expect-error TS(2339): Property 'combinedScope' does not exist on type '{... Remove this comment to see the full error message
    { combinedScope, children, elementId, endpoint, filter, limit, project },
    ref,
  ) => {
    const getMockData = () =>
      [...Array(limit ? Math.min(limit, 20) : 6)].map((__, i) => ({
        node: { id: i },
      }));

    if (filter && filter.path !== undefined) {
      const dataPath = flattenStateItem(filter);
      const data = get(combinedScope, dataPath);

      // If the data is already in scope
      if (data && Array.isArray(data)) {
        return (
          <ApiRequestChildren
            // @ts-expect-error TS(2322): Type '{ data: any[]; children: any; loading: boole... Remove this comment to see the full error message
            data={data}
            children={children}
            loading={false}
            limit={limit}
            ref={ref}
            dataPath={dataPath}
          />
        );
      }

      // We need to fetch the data
      if (endpoint.api && endpoint.endpoint) {
        const variablesAreValid = endpoint.endpoint.parameters.every(
          (param: any) => {
            const v = endpoint.params[param.name] || param.testValue;
            return !isNil(v) && (typeof v !== 'number' || !isNaN(v));
          },
        );

        if (variablesAreValid) {
          const variables = getVariables(
            endpoint.endpoint.parameters,
            endpoint.params,
          );

          if (endpoint.endpoint.type === QUERY) {
            return (
              // @ts-expect-error TS(2322): Type '{ children: ({ loading, data: apiData }: any... Remove this comment to see the full error message
              <DataGetQueryWrapper
                api={endpoint.api}
                elementId={elementId}
                params={endpoint.params}
                endpoint={endpoint.endpoint}
                variables={variables}
                projectName={project.name}
              >
                {({ loading, data: apiData }: any) => {
                  const queryData = get(apiData, filter.path);
                  return (
                    <ApiRequestChildren
                      // @ts-expect-error TS(2322): Type '{ data: any; children: any; loading: any; li... Remove this comment to see the full error message
                      data={queryData || getMockData()}
                      children={children}
                      loading={loading}
                      limit={limit}
                      ref={ref}
                      dataPath={filter.path}
                    />
                  );
                }}
              </DataGetQueryWrapper>
            );
          }
        }
      }
    }

    return (
      <ApiRequestChildren
        // @ts-expect-error TS(2322): Type '{ data: { node: { id: number; }; }[]; childr... Remove this comment to see the full error message
        data={getMockData()}
        children={children}
        limit={limit}
        ref={ref}
        dataPath={''}
      />
    );
  },
);

type DataListWrapperProps = {
  additionalDeps?: any[];
  after?: any;
  autoRefresh: boolean;
  before?: any;
  countOnly?: boolean;
  children: (...args: any[]) => any;
  customFilters?: any[];
  dataSource: string;
  dataType: string;
  elementId?: string;
  elementPath: ElementPath;
  endpoint?: any;
  filter?: any;
  limit?: number;
  orderBy?: {
    direction?: any; // TODO: PropTypes.oneOf(orderByDirections)
    field?: string;
  };
  project: Project;
  scope: any;
};

const DataListWrapper = ({
  additionalDeps,
  after,
  before,
  autoRefresh,
  children,
  countOnly,
  dataType,
  dataSource,
  elementPath,
  endpoint,
  filter,
  customFilters,
  project,
  limit,
  scope,
  orderBy,
}: DataListWrapperProps) => {
  const element = get(project.elements, elementPath, {});

  const combinedScope = useMergedScope(scope);

  if (dataSource === API_REQUEST) {
    return (
      // @ts-expect-error TS(2322): Type '{ children: (...args: any[]) => any; combine... Remove this comment to see the full error message
      <ApiRequestDataListWrapper
        combinedScope={combinedScope}
        elementId={element.id}
        endpoint={endpoint}
        filter={filter}
        limit={limit}
        project={project}
      >
        {children}
      </ApiRequestDataListWrapper>
    );
  }

  return (
    <InternalDataListWrapper
      additionalDeps={additionalDeps}
      after={after}
      before={before}
      countOnly={countOnly}
      element={element}
      autoRefresh={autoRefresh}
      dataType={dataType}
      elementPath={elementPath}
      filter={filter}
      customFilters={customFilters}
      project={project}
      limit={limit}
      orderBy={orderBy}
    >
      {children}
    </InternalDataListWrapper>
  );
};

DataListWrapper.defaultProps = {
  additionalDeps: [],
  autoRefresh: false,
  countOnly: false,
  customFilters: [],
};

const mapStateToProps = ({ queries }: any) => ({
  queries,
});

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(DataListWrapper);
