import { DateTime, DateTimeOptions } from 'luxon';

const MIN_SUPPORTED_TS_S: number = 200000000; //  Monday 3 May 1976 19:33:20
const MIN_SUPPORTED_TS_MS: number = 200000000000; //  Monday 3 May 1976 19:33:20

const isoWrapper = (
  dateTimeFunc: (value: string, options: DateTimeOptions) => DateTime,
) => (data: any, options: any): DateTime => {
  if (typeof data !== 'string' || !data.includes('-')) {
    return DateTime.invalid('Invalid ISO DateTime');
  }

  return dateTimeFunc(data, options);
};

const minTimeWrapper = (
  dateTimeFunc: (value: number, options: DateTimeOptions) => DateTime,
  minTs: number,
) => (data: any, options: DateTimeOptions): DateTime => {
  if (!data || typeof data !== 'number' || data < minTs) {
    return DateTime.invalid('Invalid number DateTime');
  }

  return dateTimeFunc(data, options);
};

const fromDateWithoutHyphens = (data: any): DateTime => {
  const date = DateTime.fromFormat(String(data), 'yyyyLLdd');
  // We need to put some sensible constrains on this so random numbers aren't interpreted as dates
  if (!date.isValid || date.year < 1976 || date.year > 2080) {
    return DateTime.invalid('Invalid number');
  }

  return date;
};

const fromEuropeanDateString = (data: any): DateTime => {
  const date = DateTime.fromFormat(
    String(data).replace(/\//g, '-'),
    'dd-LL-yyyy',
  );

  if (!date.isValid) {
    return DateTime.invalid('Invalid number');
  }

  return date;
};

const fromJSDateString = (data: any): DateTime => {
  const date = new Date(data);
  return DateTime.fromJSDate(date);
};

const DATE_FORMATS = [
  isoWrapper(DateTime.fromISO),
  minTimeWrapper(DateTime.fromMillis, MIN_SUPPORTED_TS_MS),
  minTimeWrapper(DateTime.fromSeconds, MIN_SUPPORTED_TS_S),
  DateTime.fromRFC2822,
  isoWrapper(DateTime.fromSQL),
  fromDateWithoutHyphens,
  DateTime.fromJSDate,
  fromJSDateString,
  fromEuropeanDateString,
];

export default DATE_FORMATS;
