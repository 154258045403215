import React, { forwardRef } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { darkModeColors } from '../../../../constants/darkModeColors';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import BulkActionCheckboxCell from '../BulkActionCheckboxCell';
import TableFieldCell from './TableFieldCell';

const TableRecordLayout = forwardRef<HTMLTableRowElement, any>(
  (
    {
      actionButtons,
      className,
      dataType,
      elementId,
      fieldConfigs,
      maxStickyColumnIndex,
      record,
      project,
      rowLink,
      transformRecordScope,
      columnWidths = {},
      bulkActionsEnabled = false,
      isRowChecked = false,
      handleCheckboxChange,
      selectedRows,
      theme,
      ...rest
    },
    ref,
  ) => {
    const [isDarkModeEnabled] = useDarkMode();

    return (
      <tr
        className={classNames(
          className,
          `text-xs w-full px-6 hover:${
            isDarkModeEnabled
              ? darkModeColors.surfaces.elevation2
              : 'bg-gray-100'
          } hover:bg-opacity-25 relative group`,
        )}
        data-testid="collection-record"
        ref={ref}
        {...rest}
      >
        {bulkActionsEnabled && (
          <BulkActionCheckboxCell
            elementId={elementId}
            handleCheckboxChange={handleCheckboxChange}
            isRowChecked={isRowChecked}
            isSticky={!isNil(maxStickyColumnIndex)}
            theme={theme}
          />
        )}
        {fieldConfigs.map(
          (
            { field, parent, parentFieldType, config, permissions }: any,
            index: any,
          ) => (
            <TableFieldCell
              // @ts-expect-error TS(2322): Type '{ config: any; dataType: any; elementId: any... Remove this comment to see the full error message
              config={config}
              dataType={parentFieldType || dataType}
              elementId={elementId}
              isFirst={index === 0}
              isLast={index === fieldConfigs.length - 1}
              isSticky={
                !isNil(maxStickyColumnIndex) && index <= maxStickyColumnIndex
              }
              field={field}
              key={`${field.name}:${(parentFieldType || dataType).name}`}
              parent={parent}
              permissions={permissions}
              record={record}
              project={project}
              rowLink={rowLink}
              transformRecordScope={transformRecordScope}
              width={columnWidths[index]}
              bulkActionsEnabled={bulkActionsEnabled}
              isRowChecked={isRowChecked}
              handleCheckboxChange={handleCheckboxChange}
              selectedRows={selectedRows}
              theme={theme}
            />
          ),
        )}
        {actionButtons}
      </tr>
    );
  },
);

export default withTheme(TableRecordLayout);
