import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

const useImperativeQuery = (query: any, queryOptions: any) => {
  const client = useApolloClient();

  return [
    useCallback(
      (options: any) =>
        client.query({
          query,
          ...queryOptions,
          ...options,
        }),
      [client, queryOptions, query],
    ),
    {
      client,
    },
  ];
};

export default useImperativeQuery;
