import { ApolloClient } from '@apollo/client';

// Demo user in noloco-projmgmt-template.noloco.co/
const DEMO_USER_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjksImVtYWlsIjoiZGVtb0Bub2xvY28uaW8iLCJwcm9qZWN0Ijoibm9sb2NvLXByb2ptZ210LXRlbXBsYXRlIiwiaWF0IjoxNjgzODE1NTExfQ.r5drBNAj9mlkU-SoBLeP2nXwdZWpOacytbdJdSVlEKY';

const getApolloClient = (cache, projectName, ssrMode) => {
  return new ApolloClient({
    ssrMode,
    uri: `https://api.portals.noloco.io/data/${projectName}`,
    headers: {
      'x-noloco-project': projectName,
      authorization: `Bearer ${DEMO_USER_TOKEN}`,
    },
    cache,
  });
};

export default getApolloClient;
