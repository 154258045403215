import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import first from 'lodash/first';
import { Theme, Tooltip, getColorShade } from '@noloco/components';
import { MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import { darkModeColors } from '../../../constants/darkModeColors';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useWindowSize from '../../../utils/hooks/useWindowSize';

const scaleMetric = (value: number, max: number) => (value / max) * 100;

const getGaugeSize = (radius: number, percent: number) => {
  const size = radius < 100 ? SM : MD;
  const containerSize = radius * 2 + (size === MD ? 100 : 50);
  const circumference = 2 * Math.PI * radius;
  const arcLength = circumference * 0.8;
  const valueLength = arcLength * (percent / 100);

  return {
    containerSize,
    circumference,
    arcLength,
    size,
    valueLength,
  };
};

const BASE_RADIUS = 150;

const GaugeChart = ({
  data,
  fills,
  max = 100,
  primaryAxisFormatter,
  xAxisLabel,
  theme,
}: {
  data: { x: number | null };
  fills: string[];
  max: number;
  primaryAxisFormatter: (x: number) => any;
  xAxisLabel: any | undefined;
  theme: Theme;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const [radius, setRadius] = useState(BASE_RADIUS);
  const [isDarkModeEnabled] = useDarkMode();

  const percent = useMemo(() => scaleMetric(data.x ?? 0, max), [data.x, max]);
  const {
    containerSize,
    circumference,
    arcLength,
    size,
    valueLength,
  } = useMemo(() => getGaugeSize(radius, percent), [percent, radius]);

  const setRadiusSize = useCallback((size) => {
    if (
      wrapperRef &&
      wrapperRef.current &&
      wrapperRef.current.parentElement &&
      size
    ) {
      const parentWidth = wrapperRef.current.parentElement.clientWidth;

      setRadius((currentRadius) =>
        Math.min(
          (parentWidth - (currentRadius > 100 ? 100 : 50)) / 2,
          BASE_RADIUS,
        ),
      );
    }
  }, []);

  useLayoutEffect(() => {
    setRadiusSize(windowSize);
  }, [setRadiusSize, windowSize]);

  return (
    <div
      ref={wrapperRef}
      className="mx-auto relative"
      style={{ height: containerSize, width: containerSize }}
    >
      <svg className="w-full h-full" style={{ transform: `rotateZ(126.8deg)` }}>
        <circle
          className="fill-none"
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth={size === MD ? 20 : 10}
          stroke={first(fills)}
          strokeOpacity={isDarkModeEnabled ? 0.4 : 0.2}
          strokeLinecap="round"
          strokeDasharray={`${arcLength},${circumference}`}
        ></circle>
        <circle
          className="fill-none"
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth={size === MD ? 30 : 15}
          strokeLinecap="round"
          stroke={first(fills)}
          strokeDasharray={`${valueLength},${circumference}`}
        ></circle>
      </svg>
      <div className="flex flex-col space-y-2 px-20 py-20 text-center absolute top-0 left-0 right-0 bottom-0 items-center justify-center">
        <Tooltip
          content={
            <span
              className={isDarkModeEnabled ? darkModeColors.text.primary : ''}
            >
              {primaryAxisFormatter(data.x ?? 0)}
            </span>
          }
          bg={
            isDarkModeEnabled
              ? getColorShade(theme.brandColors.primary, 800)
              : undefined
          }
        >
          <span
            className={classNames(
              'tracking-wider font-medium truncate w-32',
              {
                'text-2xl': size === MD,
                'text-lg': size === SM,
              },
              isDarkModeEnabled ? 'text-gray-100' : 'text-gray-600',
            )}
          >
            {primaryAxisFormatter(data.x ?? 0)}
          </span>
        </Tooltip>
        {xAxisLabel && (
          <span
            className={classNames(
              'tracking-wider font-medium',
              {
                'text-xl': size === MD,
                'text-base': size === SM,
              },
              isDarkModeEnabled ? 'text-gray-200' : 'text-gray-500',
            )}
          >
            {xAxisLabel}
          </span>
        )}
      </div>
    </div>
  );
};

export default GaugeChart;
