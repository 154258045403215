import React, { memo, useMemo } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Loader } from '@noloco/components';
import Element from '@noloco/core/src/components/canvas/Element';
import withDataFields from '@noloco/core/src/components/canvas/withDataFields';
import PageSwitch from '@noloco/core/src/elements/PageSwitch';
import { editorModeSelector } from '@noloco/core/src/selectors/elementsSelectors';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import cappedMemoize from '@noloco/core/src/utils/cappedMemoize';
import useScopeUser from '@noloco/core/src/utils/hooks/useScopeUser';
import ElementWrapper from './ElementWrapper';

export const getElementPath = cappedMemoize(
  (baseElementPath, index) => [...baseElementPath, index],
  { maxKeys: 100 },
);
const EMPTY_SCOPE = {};

export const ElementRenderer = ({
  elementPath,
  editorMode,
  element,
  index,
  project,
  scope = EMPTY_SCOPE,
}: any) => {
  const ElementWithDataFields = withDataFields(
    !editorMode ? Element : ElementWrapper,
    element,
    project,
    editorMode,
  );

  const elPath = useMemo(() => elementPath || getElementPath([], index), [
    elementPath,
    index,
  ]);

  return (
    <ElementWithDataFields
      element={element}
      key={[index].join('.')}
      elementPath={elPath}
      project={project}
      scope={scope}
    />
  );
};

const ProjectRenderer = memo(() => {
  const project = useSelector(projectDataSelector);
  const user = useScopeUser();
  const editorMode = useSelector(editorModeSelector);

  const isV2 = get(project, 'settings.flags.v2', false);
  const hasUser = user && user.id;

  const elements = useMemo(
    () =>
      hasUser
        ? project.elements.map((element: any, index: any) => (
            <ElementRenderer
              editorMode={editorMode}
              element={element}
              key={[index].join('.')}
              index={index}
              project={project}
              scope={EMPTY_SCOPE}
            />
          ))
        : [],
    [editorMode, hasUser, project],
  );

  if (!hasUser) {
    return (
      <div className="flex justify-center items-center w-full h-screen p-8">
        <Loader type="Bars" />
      </div>
    );
  }

  if (isV2) {
    return <PageSwitch project={project}>{elements}</PageSwitch>;
  }

  return <React.Fragment>{elements}</React.Fragment>;
});

export default ProjectRenderer;
