import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import gql from 'graphql-tag';
import camelCase from 'lodash/camelCase';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { getApiRequestQueryString } from '../../../queries/project';
import { getApiEndpointQueryName } from '../../../utils/apis';
import DataFieldInput from './DataFieldInput';

const DefaultHelpText = ({ config }: any) => (
  <span className="mb-2 -mt-2 text-sm font-light tracking-wide whitespace-pre-wrap">
    {config.helpText}
  </span>
);

const AutoApiForm = memo(
  ({
    // @ts-expect-error TS(2339): Property 'api' does not exist on type '{}'.
    api,
    // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
    className,
    // @ts-expect-error TS(2339): Property 'innerClassName' does not exist on type '... Remove this comment to see the full error message
    innerClassName,
    children,
    // @ts-expect-error TS(2339): Property 'endpoint' does not exist on type '{}'.
    endpoint,
    // @ts-expect-error TS(2339): Property 'fields' does not exist on type '{}'.
    fields,
    // @ts-expect-error TS(2339): Property 'Label' does not exist on type '{}'.
    Label,
    // @ts-expect-error TS(2339): Property 'HelpText' does not exist on type '{}'.
    HelpText,
    // @ts-expect-error TS(2339): Property 'onLoadingChange' does not exist on type ... Remove this comment to see the full error message
    onLoadingChange,
    // @ts-expect-error TS(2339): Property 'onError' does not exist on type '{}'.
    onError,
    // @ts-expect-error TS(2339): Property 'onSuccess' does not exist on type '{}'.
    onSuccess,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
    project,
    // @ts-expect-error TS(2339): Property 'surface' does not exist on type '{}'.
    surface,
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [shouldSubmit, setShouldSubmit] = useState(false);
    const [dataItem, setDataItem] = useState({});

    useEffect(() => {
      if (onLoadingChange) {
        onLoadingChange(isLoading);
      }
    }, [onLoadingChange, isLoading]);

    const getQueryVariables = useCallback(() => {
      const checkRequired = (newValue: any, field: any, config: any) => {
        if (
          (newValue === undefined || newValue === null) &&
          (field.required || config.required)
        ) {
          throw new Error(`${config.label || field.display} is required`);
        }
      };

      try {
        if (dataItem) {
          return fields
            .filter((field: any) => !field.relatedField)
            .reduce((dataAcc: any, { param, config }: any) => {
              const newValue = config.hidden
                ? config.value
                : // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  dataItem[param.name];
              checkRequired(newValue, param, config);

              return set([camelCase(param.name)], newValue, dataAcc);
            }, {});
        }
      } catch (e) {
        if (onError) {
          onError(e);
        }
      }
      return null;
    }, [dataItem, fields, onError]);

    const apiRequestQueryString = useMemo(
      () => gql`
        ${getApiRequestQueryString(api, endpoint)}
      `,
      [api, endpoint],
    );

    const queryOptions = {
      context: {
        projectQuery: true,
        projectName: project.name,
      },
    };
    const [executeApiRequest] = useMutation(
      apiRequestQueryString,
      queryOptions,
    );

    const onSubmit = useCallback(
      (event: any) => {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }

        if (isLoading) {
          return;
        }

        const variables = getQueryVariables();
        if (variables) {
          setIsLoading(true);
          executeApiRequest({ variables })
            .then((updatedData) => {
              let item = null;
              console.log(updatedData);
              const newItem = get(updatedData, [
                'data',
                getApiEndpointQueryName(api, endpoint),
                'data',
              ]);
              setDataItem({});

              if (onSuccess && newItem) {
                onSuccess(item);
              }
            })
            .catch((e) => {
              console.error(e);
              if (onError) {
                onError(e);
              }
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      },
      [
        api,
        endpoint,
        executeApiRequest,
        getQueryVariables,
        isLoading,
        onError,
        onSuccess,
      ],
    );

    const updateDataItemField = (field: any) => (value: any) => {
      setDataItem(set([field.name], value, dataItem));
    };

    useEffect(() => {
      if (shouldSubmit) {
        setShouldSubmit(false);
        // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
        onSubmit();
      }
    }, [onSubmit, shouldSubmit]);

    return (
      <form className={className} onSubmit={onSubmit}>
        {fields
          .filter(({ config }: any) => !config.hidden)
          .map(({ param, config }: any) => ({
            param,
            config,
            field: { ...param, type: param.dataType },
          }))
          .map(({ field, config }: any) => (
            <div
              className={classNames('flex flex-col', innerClassName)}
              key={`input-${field.name}-${get(dataItem, 'id')}`}
            >
              {config.label && (
                <Label config={config} field={field} htmlFor={field.name} />
              )}
              {config.helpText && <HelpText config={config} field={field} />}
              <DataFieldInput
                // @ts-expect-error TS(2322): Type '{ id: string; canEdit: boolean; dataTypes: n... Remove this comment to see the full error message
                id={`input-${field.name}-${get(dataItem, 'id')}`}
                canEdit={!config.readOnly}
                dataTypes={[]}
                field={field}
                inline={false}
                onBlur={() => null}
                onChange={updateDataItemField(field)}
                allowNewRecords={config.allowNewRecords}
                newRecordFormFields={
                  config.allowNewRecords ? config.newRecordFields : undefined
                }
                placeholder={config.placeholder}
                value={get(dataItem, [field.name])}
                projectName={project.name}
                project={project}
                surface={surface}
                ReadOnlyCell={null}
              />
            </div>
          ))}
        {children}
      </form>
    );
  },
);

(AutoApiForm as any).propTypes = {};

(AutoApiForm as any).defaultProps = {
  inline: false,
  HelpText: DefaultHelpText,
};

export default AutoApiForm;
