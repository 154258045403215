import React, { useMemo, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames';
import get from 'lodash/get';
import { FILE } from '../../../../constants/builtInDataTypes';
import {
  CollectionLayout,
  TABLE,
  TABLE_FULL,
} from '../../../../constants/collectionLayouts';
import { darkModeColors } from '../../../../constants/darkModeColors';
import { DECIMAL, INTEGER } from '../../../../constants/dataTypes';
import { OrderByDirection } from '../../../../constants/orderByDirections';
import { DataField } from '../../../../models/DataTypeFields';
import { PageInfo, RecordEdge } from '../../../../models/Record';
import { CollectionField } from '../../../../models/View';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import { FieldConfig } from '../../../../utils/permissions';
import { PAGINATION_STYLES } from '../../Collection';
import { variables } from '../CollectionRecord';
import CollectionTableHead from '../CollectionTableHead';
import TableSummaryFooter from '../TableSummaryFooter';
import RowPagination from '../pagination/RowPagination';

/*
 Sources: 
 - https://tanstack.com/table/v8/docs/examples/react/virtualized-rows
 - https://tanstack.com/virtual/v3/docs/examples/react/dynamic
*/

export type RecordLayoutByIndexProps = {
  index: number;
  'data-index': number;
  columnWidths: Record<number, number>;
  ref: React.Ref<HTMLTableRowElement>;
};

const GROUP_HEIGHT_ESTIMATE = 28;
const DEFAULT_IS_GROUP = () => false;
const DEFAULT_GET_STICKY_INDEXES = () => [];

export type TableLayoutProps = {
  className?: string;
  count: number;
  edges: RecordEdge[];
  fieldConfigs: FieldConfig<CollectionField>[];
  handleSetOrderBy: (field: DataField, direction: OrderByDirection) => void;
  layout: CollectionLayout;
  orderBy: { field: string; direction: OrderByDirection };
  maxStickyColumnIndex: number;
  pageInfo: PageInfo;
  RecordLayoutByIndex: React.FC<RecordLayoutByIndexProps>;
  showPagination: boolean;
  showTableSummary: boolean;
  setPaginationQuery: (paginationArgs: {
    after?: string;
    before?: string;
  }) => void;
  totalCount: number;
  getStickyIndexesForIndex?: (index: number) => number[];
  isGroup?: (index: number) => boolean;
  bulkActionsEnabled?: boolean;
  allRowsSelected?: boolean;
  setSelectAllRows?: (allRowsSelected: boolean) => void;
};

const TableLayout = ({
  className,
  count,
  edges,
  fieldConfigs,
  handleSetOrderBy,
  layout,
  orderBy,
  maxStickyColumnIndex,
  pageInfo,
  RecordLayoutByIndex,
  setPaginationQuery,
  showPagination,
  showTableSummary,
  totalCount,
  getStickyIndexesForIndex = DEFAULT_GET_STICKY_INDEXES,
  isGroup = DEFAULT_IS_GROUP,
  bulkActionsEnabled = false,
  allRowsSelected = false,
  setSelectAllRows,
}: TableLayoutProps) => {
  const [isDarkModeEnabled] = useDarkMode();
  const parentRef = React.useRef<HTMLTableElement>(null);
  const [columnWidths, setColumnWidths] = useState<Record<number, number>>({});

  const estimatedSize = useMemo(
    () =>
      fieldConfigs.some((fieldConfig) => fieldConfig.field.type === FILE)
        ? 80
        : 35,
    [fieldConfigs],
  );

  const virtualizer = useVirtualizer({
    count,
    horizontal: false,
    getScrollElement: () => parentRef.current,
    estimateSize: (index) =>
      isGroup(index) ? GROUP_HEIGHT_ESTIMATE : estimatedSize,
    overscan: 10,
  });

  const items = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();

  const stickyIndexes = useMemo(
    () => (items.length > 0 ? getStickyIndexesForIndex(items[0].index) : []),
    [getStickyIndexesForIndex, items],
  );

  // This padding makes sure the table's scrollbar is the right size
  // despite only displaying a subset of elements
  const paddingTop = get(items, [0, 'start'], 0);
  const paddingBottom =
    items.length > 0 ? totalSize - get(items, [items.length - 1, 'end'], 0) : 0;

  const headerAdditionalElements = useMemo(
    () =>
      fieldConfigs.map(({ field, config }: any) => ({
        label: config.label && !config.label.hidden ? config.label.value : '',
        onSort: !field.relationship
          ? (newSortDirection: any) => {
              handleSetOrderBy(field, newSortDirection);
            }
          : undefined,
        alignRight: field.type === DECIMAL || field.type === INTEGER,
        isSortActive:
          !field.relationship && get(orderBy, 'field') === field.name,
      })),
    [fieldConfigs, handleSetOrderBy, orderBy],
  );

  return (
    <>
      <div
        className={classNames(className, 'w-full h-full overflow-y-auto')}
        ref={parentRef}
      >
        <table
          className={classNames(
            `min-w-full divide-y w-full relative ${
              isDarkModeEnabled ? darkModeColors.divides.two : 'divide-gray-200'
            }`,
          )}
        >
          <CollectionTableHead
            maxStickyColumnIndex={maxStickyColumnIndex}
            sticky={layout === TABLE_FULL || layout === TABLE}
            additionalElements={headerAdditionalElements}
            orderBy={orderBy}
            variables={variables}
            columnWidths={columnWidths}
            setColumnWidths={setColumnWidths}
            bulkActionsEnabled={bulkActionsEnabled}
            allRowsSelected={allRowsSelected}
            setSelectAllRows={setSelectAllRows}
          />
          <tbody
            className={`divide-y ${
              isDarkModeEnabled
                ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.divides.two}`
                : 'divide-gray-200 bg-white'
            }`}
          >
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {items.length > 0 &&
              stickyIndexes.length > 0 &&
              stickyIndexes.map((stickyIndex) => (
                <RecordLayoutByIndex
                  key={stickyIndex}
                  data-index={stickyIndex}
                  index={stickyIndex}
                  columnWidths={columnWidths}
                  ref={(node) => {
                    virtualizer.measureElement(node);
                    return node;
                  }}
                />
              ))}
            {items.map((virtualRow) => (
              <RecordLayoutByIndex
                key={virtualRow.key}
                data-index={virtualRow.index}
                index={virtualRow.index}
                columnWidths={columnWidths}
                ref={(node) => {
                  virtualizer.measureElement(node);
                  return node;
                }}
              />
            ))}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
            {showTableSummary && (
              <TableSummaryFooter
                className="h-6 bottom-0"
                fieldConfigs={fieldConfigs}
                edges={edges}
                bulkActionsEnabled={bulkActionsEnabled}
              />
            )}
          </tbody>
        </table>
        {showPagination && (
          <RowPagination
            className={classNames(
              'border-t',
              {
                'bottom-0': layout === TABLE_FULL,
              },
              PAGINATION_STYLES[TABLE_FULL](isDarkModeEnabled),
            )}
            pageInfo={pageInfo}
            totalCount={totalCount}
            setPaginationQuery={setPaginationQuery}
            currentLength={edges.length}
          />
        )}
      </div>
    </>
  );
};

export default TableLayout;
