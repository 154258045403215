export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const NAVIGATE = 'NAVIGATE';
export const INVITE_USER = 'INVITE';
export const OPEN_STRIPE_PORTAL = 'OPEN_STRIPE_PORTAL';
export const API_REQUEST = 'API_REQUEST';
export const ON_DEMAND = 'ON_DEMAND';
export const IFRAME = 'IFRAME';

const actionTypes = [
  API_REQUEST,
  CREATE,
  UPDATE,
  DELETE,
  OPEN_STRIPE_PORTAL,
  INVITE_USER,
];

export type ActionType =
  | 'CREATE'
  | 'UPDATE'
  | 'DELETE'
  | 'ON_DEMAND'
  | 'NAVIGATE'
  | 'IFRAME';

export default actionTypes;
