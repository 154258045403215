import React from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';

const CurrentTimeIndicator = ({ position, theme }: any) => {
  const themedBackgroundColour = `bg-${theme.brandColors.secondary}`;

  return (
    <div
      className="absolute l-0 -mt-1.5 pointer-events-none r-0 w-full z-10"
      style={{ top: `${position}%` }}
    >
      <div className="flex">
        <div
          className={classNames(
            'flex h-3 -ml-1.5 rounded-full w-3',
            themedBackgroundColour,
          )}
        />
        <div
          className={classNames('flex-1 h-0.5 my-auto', themedBackgroundColour)}
        />
      </div>
    </div>
  );
};

export default withTheme(CurrentTimeIndicator);
