import React, { useCallback, useMemo, useState } from 'react';
import { IconFileDownload } from '@tabler/icons-react';
import gql from 'graphql-tag';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import Papa from 'papaparse';
import { Button, Loader } from '@noloco/components';
import { FILE } from '../../../constants/builtInDataTypes';
import { formatValueForField } from '../../../utils/data';
import { getPreviewFieldsForField } from '../../../utils/dataTypes';
import { YMD_TIME_FORMAT } from '../../../utils/dates';
import { downloadCsvStringAsFile } from '../../../utils/files';
import { useGraphQlErrorAlert } from '../../../utils/hooks/useAlerts';
import useDataListQueryObject from '../../../utils/hooks/useDataListQueryObject';
import useImperativeQuery from '../../../utils/hooks/useImperitiveQuery';
import { getText } from '../../../utils/lang';
import { isMultiField } from '../../../utils/relationships';

const LANG_KEY = 'elements.VIEW.buttons.exportButton';

const ExportButton = ({
  additionalDeps,
  buttonType,
  queryContext = {},
  customFilters,
  dataList,
  dataType,
  elementPath,
  fieldConfigs,
  project,
  text,
  fileNamePrefix,
  ...rest
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const errorAlert = useGraphQlErrorAlert();

  // @ts-expect-error TS(2339): Property 'variables' does not exist on type '{}'.
  const { variables, query, valuePath } = useDataListQueryObject(
    dataType.name,
    project,
    elementPath,
    {
      additionalDeps,
      ...dataList,
      customFilters: [...customFilters, ...(dataList.customFilters || [])],
      limit: undefined,
    },
  );

  const gqlQueryString = useMemo(
    () =>
      gql`
        ${query}
      `,
    [query],
  );

  const [exportData] = useImperativeQuery(gqlQueryString, {
    variables,
    fetchPolicy: 'no-cache',
    context: { projectQuery: true, projectName: project.name, ...queryContext },
    errorPolicy: 'all',
  });

  const onClick = useCallback(() => {
    setIsLoading(true);
    // @ts-expect-error TS(2349): This expression is not callable.
    exportData()
      .then(({ errors, data }: any) => {
        if (!data) {
          return errorAlert(getText(LANG_KEY, 'error'), {
            graphQLErrors: errors,
          });
        }

        const nodes = get(data, [...valuePath.split('.'), 'edges'], []).map(
          (edge) => (edge as any).node,
        );
        const columns = fieldConfigs.reduce(
          (acc: any, { field, config, parent }: any) => {
            const fieldPath = [
              ...(parent ? [parent.apiName] : []),
              field.apiName,
            ];
            const label = config.label.value || field.apiName;
            if (!field.relationship && !field.relatedField) {
              return [
                ...acc,
                {
                  label,
                  getValue: (node: any) =>
                    formatValueForField(get(node, fieldPath), field, true),
                },
              ];
            }
            const isMultiRelationship = isMultiField(field);
            if (field.type === FILE) {
              return [
                ...acc,
                {
                  label,
                  getValue: (node: any) => {
                    if (isMultiRelationship) {
                      return get(node, [...fieldPath, 'edges'], [])
                        .map((edge) => (edge as any).node.url)
                        .filter(Boolean)
                        .join(',');
                    }
                    return get(node, [...fieldPath, 'url']);
                  },
                },
              ];
            }
            const previewFields = getPreviewFieldsForField(
              field,
              project.dataTypes,
            );
            if (!previewFields) {
              return acc;
            }
            return [
              ...acc,
              {
                label,
                getValue: (node: any) => {
                  if (isMultiRelationship) {
                    return get(node, [...fieldPath, 'edges'], [])
                      .map((edge) =>
                        previewFields.textFields
                          .map((textField) =>
                            get(edge, ['node', textField.apiName], ''),
                          )
                          .join(' '),
                      )
                      .filter(Boolean)
                      .join(',');
                  }
                  return previewFields.textFields
                    .map((textField) =>
                      get(node, [...fieldPath, textField.apiName], ''),
                    )
                    .join(' ');
                },
              },
              {
                label: `${field.display} ID`,
                getValue: (node: any) => {
                  if (isMultiRelationship) {
                    return get(node, [...fieldPath, 'edges'], [])
                      .map((edge) => get(edge, ['node', 'id']))
                      .filter(Boolean)
                      .join(',');
                  }
                  return get(node, [...fieldPath, 'id']);
                },
              },
            ];
          },
          [],
        );
        const rows = nodes.map((node) =>
          columns.reduce(
            (nodeAcc: any, { label, getValue }: any) =>
              set([label], getValue(node), nodeAcc),
            {},
          ),
        );
        const rowsCSV = Papa.unparse(rows);
        downloadCsvStringAsFile(
          rowsCSV,
          `${fileNamePrefix ? `${fileNamePrefix}-` : ''}${getText(
            LANG_KEY,
            'filename',
          )}-${DateTime.now().toFormat(YMD_TIME_FORMAT)}`,
        );
      })
      .catch((e: any) => {
        errorAlert(getText(LANG_KEY, 'error'), e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    errorAlert,
    exportData,
    fieldConfigs,
    fileNamePrefix,
    project.dataTypes,
    valuePath,
  ]);

  return (
    <Button
      className="flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap export-button"
      type={buttonType}
      variant="secondary"
      disabled={isLoading}
      onClick={onClick}
      {...rest}
    >
      {isLoading ? (
        <Loader size="sm" />
      ) : (
        <IconFileDownload className="opacity-75" size={16} />
      )}
      <span>{text || getText(LANG_KEY, 'defaultText')}</span>
    </Button>
  );
};

export default ExportButton;
