export const ROUNDED_SMALL = 'rounded-small';
export const ROUNDED_LARGE = 'rounded-large';
export const ROUNDED_FULL = 'rounded-full';
export const SQUARE = 'square';
export const THICK_BORDER = 'thick-border';
export const NO_BORDER = 'no-border';
export const INSET_SHADOW = 'inset-shadow';
export const BOX_SHADOW = 'box-shadow';
export const BOTTOM_BORDER = 'bottom-border';

const inputStyles = [
  ROUNDED_SMALL,
  ROUNDED_LARGE,
  ROUNDED_FULL,
  SQUARE,
  THICK_BORDER,
  NO_BORDER,
  INSET_SHADOW,
  BOX_SHADOW,
  BOTTOM_BORDER,
];

export type InputStyle =
  | 'rounded-small'
  | 'rounded-large'
  | 'rounded-full'
  | 'square'
  | 'thick-border'
  | 'no-border'
  | 'inset-shadow'
  | 'box-shadow'
  | 'bottom-border';

export default inputStyles;
