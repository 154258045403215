import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@noloco/components';
import elementConfigs from '@noloco/core/src/elements';
import { darkModeColors } from '../constants/darkModeColors';
import {
  CHART,
  COLLECTION,
  DETAILS,
  DIVIDER,
  FILE_GALLERY,
  HIGHLIGHTS,
  IFRAME,
  MARKDOWN,
  QUICK_LINKS,
  STAGES,
  TITLE,
  VIDEO,
} from '../constants/elements';
import { canBeStages } from '../utils/fields';
import useDarkMode from '../utils/hooks/useDarkMode';
import { getText } from '../utils/lang';

const elements = [
  TITLE,
  COLLECTION,
  DETAILS,
  HIGHLIGHTS,
  VIDEO,
  IFRAME,
  STAGES,
  CHART,
  QUICK_LINKS,
  MARKDOWN,
  FILE_GALLERY,
  DIVIDER,
];

const RecordViewSectionOptions = ({ dataType, disabled, onSelect }: any) => {
  const isEnabled = useCallback(
    (element: any) => {
      if (element === STAGES) {
        return dataType.fields.some(canBeStages);
      }

      return true;
    },
    [dataType.fields],
  );

  const [isDarkModeEnabled] = useDarkMode();

  return (
    <div
      className="my-8 w-full flex items-center justify-center border-1 mx-auto cursor-pointer"
      onClick={(e) => {
        if (!disabled) {
          e.stopPropagation();
        }
      }}
    >
      <div
        className={`w-full max-w-6xl mx-auto h-full flex items-center justify-center border-4 rounded-lg border-dashed bg:opacity-25 p-20 ${
          isDarkModeEnabled ? darkModeColors.borders.two : ''
        }`}
      >
        <div className="w-full max-w-2xl grid grid-cols-2 gap-4 md:grid-cols-1">
          {elements.map((element) => {
            const { Icon } = elementConfigs[element];
            const enabled = isEnabled(element);

            return (
              <Tooltip
                content={
                  <span
                    className={
                      isDarkModeEnabled ? darkModeColors.text.primary : ''
                    }
                  >
                    {getText('elements', element, 'disabled')}
                  </span>
                }
                disabled={enabled}
                key={element}
              >
                <div className="flex">
                  <button
                    className={classNames(
                      `flex px-3 py-2 rounded-lg text-left w-full ${
                        isDarkModeEnabled
                          ? darkModeColors.surfaces.elevation2
                          : 'bg-gray-100'
                      }`,
                      {
                        [`hover:shadow-lg hover:${
                          isDarkModeEnabled
                            ? darkModeColors.surfaces.elevation3
                            : 'bg-white'
                        }`]: enabled,
                      },
                    )}
                    disabled={!enabled}
                    onClick={() => onSelect(element)}
                  >
                    <Icon
                      className="opacity-50 mr-3 mt-1 flex-shrink-0"
                      size={16}
                    />
                    <div className="flex flex-col">
                      <span
                        className={classNames('font-medium text-sm', {
                          [`${
                            isDarkModeEnabled
                              ? darkModeColors.text.tertiary
                              : 'text-gray-400'
                          }`]: !enabled,
                        })}
                      >
                        {getText(
                          'elements',
                          element,
                          element === MARKDOWN ? 'sectionLabel' : 'label',
                        )}
                      </span>
                      <span
                        className={classNames('text-xs', {
                          [`${
                            isDarkModeEnabled
                              ? darkModeColors.text.secondary
                              : 'text-gray-600'
                          }`]: enabled,
                          [`${
                            isDarkModeEnabled
                              ? darkModeColors.text.tertiary
                              : 'text-gray-400'
                          }`]: !enabled,
                        })}
                      >
                        {getText('elements', element, 'description')}
                      </span>
                    </div>
                  </button>
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RecordViewSectionOptions;
