import React from 'react';
import classNames from 'classnames';
import { Badge, getColorShade } from '@noloco/components';
import { getColorByIndex } from '../../../utils/colors';

const OptionBadge = ({ className, m, option }: any) => (
  <Badge
    className={classNames(className, 'h-auto break-words leading-normal')}
    color={getColorShade(option.color || getColorByIndex(option.order), 400)}
    m={m}
  >
    {option.display}
  </Badge>
);

export default OptionBadge;
