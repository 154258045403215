import React, { useCallback, useMemo, useState } from 'react';
import {
  IconCalendar,
  IconChartHistogram,
  IconLayoutGrid,
  IconLayoutKanban,
  IconLayoutRows,
  IconMap2,
  IconTable,
  IconTimeline,
} from '@tabler/icons-react';
import classNames from 'classnames';
import {
  BOARD,
  CALENDAR,
  CARDS,
  CHARTS,
  MAP,
  ROWS,
  TABLE,
  TABLE_FULL,
  TIMELINE,
} from '@noloco/core/src/constants/collectionLayouts';
import ViewCollection from '@noloco/core/src/elements/ViewCollection';
import { Project } from '@noloco/core/src/models/Project';
import { WEBSITE_EXAMPLES, trackEvent } from '@noloco/core/src/utils/analytics';
import { getText } from '@noloco/core/src/utils/lang';
import BaseExample from '../BaseExample';

const LANG_KEY = 'websiteExamples.collectionLayout';

const EMPTY_ARRAY: any[] = [];
const EMPTY_OBJECT: Record<any, any> = {};

const voidFn = () => {};

type Layout =
  | 'TABLE'
  | 'BOARD'
  | 'ROWS'
  | 'CARDS'
  | 'CHARTS'
  | 'MAP'
  | 'CALENDAR'
  | 'TIMELINE';

const LAYOUTS: Layout[] = [
  TABLE,
  BOARD,
  ROWS,
  CARDS,
  CHARTS,
  MAP,
  CALENDAR,
  TIMELINE,
];

const LayoutIcon = ({ layout }: { layout: Layout }) => {
  const Icon = {
    [ROWS]: IconLayoutRows,
    [CARDS]: IconLayoutGrid,
    [TABLE]: IconTable,
    [TABLE_FULL]: IconTable,
    [BOARD]: IconLayoutKanban,
    [CHARTS]: IconChartHistogram,
    [MAP]: IconMap2,
    [CALENDAR]: IconCalendar,
    [TIMELINE]: IconTimeline,
  }[layout];

  return <Icon className="flex-shrink-0" size={20} />;
};

const EMPTY_STATE = {
  title: {
    value: 'Nothing to see here',
  },
  subtitle: {
    value: null,
  },
  image: {
    hidden: true,
  },
};

const CollectionLayoutExample = ({ project }: { project: Project }) => {
  const formatRecordScope = (record: any) => ({
    [`${element.id}:VIEW`]: record,
  });

  const [layout, setLayout] = useState(TABLE);

  const element = project.elements[0];

  const onChangeLayout = useCallback((newLayout: string) => {
    setLayout(newLayout);
    trackEvent(WEBSITE_EXAMPLES.LAYOUT_CHANGED, 'layout', newLayout);
  }, []);

  const {
    charts,
    dateEnd,
    dateStart,
    fields,
    filters = EMPTY_ARRAY,
    dataList,
    groupBy,
    record,
    map,
  } = element.props;

  const hydratedDataList = useMemo(
    () => ({
      ...dataList,
      showPagination: false,
      limit: 10,
    }),
    [dataList],
  );

  const collectionFields = useMemo(
    () => fields.slice(0, layout === MAP ? 2 : undefined),
    [fields, layout],
  );

  return (
    <BaseExample>
      <div className="flex flex-col text-center items-center mb-8">
        <h2 className="text-3xl font-medium mb-3">
          {getText(LANG_KEY, 'title')}
        </h2>
        <p className="text-base mb-8">{getText(LANG_KEY, 'subtitle')}</p>
        <div className="grid grid-cols-4 md:grid-cols-2 gap-4 mx-auto max-w-screen-md w-full">
          {LAYOUTS.map((layoutOption) => (
            <button
              className={classNames(
                'flex items-center rounded-lg border space-x-2 px-3 py-2 bg-white hover:ring-2 hover:ring-pink-200 ring-pink-400 w-full h-full',
                { 'ring-2': layout === layoutOption },
              )}
              key={layoutOption}
              onClick={() => onChangeLayout(layoutOption)}
            >
              <LayoutIcon layout={layoutOption} />
              {
                <span>
                  {getText('elements.COLLECTION.layout', layoutOption)}
                </span>
              }
            </button>
          ))}
        </div>
      </div>
      <ViewCollection
        actionButtons={EMPTY_ARRAY}
        charts={charts}
        className={classNames('website-example z-10', {
          'h-screen max-h-screen-50':
            layout === CALENDAR || layout === TIMELINE || layout === MAP,
        })}
        dataList={hydratedDataList}
        dateEnd={layout === TIMELINE ? dateEnd : undefined}
        dateStart={dateStart}
        emptyState={EMPTY_STATE}
        fields={collectionFields}
        formatRecordScope={formatRecordScope}
        layout={layout}
        groupBy={layout === BOARD ? groupBy : undefined}
        editorMode={false}
        elementPath={[0]}
        hideNewButton={true}
        map={map}
        scope={EMPTY_OBJECT}
        filters={filters}
        record={record}
        rootPathname="/"
        project={project}
        viewId={element.id}
        viewRootPathname="/"
        enableDragAndDropEdit={false}
        onUpdateProject={voidFn}
        track={false}
      />
    </BaseExample>
  );
};

export default CollectionLayoutExample;
