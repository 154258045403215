import React, { forwardRef, useState } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getColorShade } from '@noloco/components';
import { getText } from '@noloco/core/src/utils/lang';
import { CREATE, UPDATE } from '../../constants/actionTypes';
import Icon from '../Icon';
import SectionButton from './SectionButton';
import Title from './Title';
import AutoApiForm from './forms/AutoApiForm';

const Label = ({ config, field }: any) => (
  <label className="mb-2 font-medium text-lg" htmlFor={field.name}>
    {config.label}
  </label>
);

type ApiFormProps = {};

const ApiForm = forwardRef<any, ApiFormProps>(
  (
    {
      // @ts-expect-error TS(2339): Property 'className' does not exist on type 'ApiFo... Remove this comment to see the full error message
      className,
      // @ts-expect-error TS(2339): Property 'endpoint' does not exist on type 'ApiFor... Remove this comment to see the full error message
      endpoint,
      // @ts-expect-error TS(2339): Property 'fields' does not exist on type 'ApiFormP... Remove this comment to see the full error message
      fields,
      // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type 'ApiF... Remove this comment to see the full error message
      editorMode,
      // @ts-expect-error TS(2339): Property 'errorMessage' does not exist on type 'Ap... Remove this comment to see the full error message
      errorMessage,
      // @ts-expect-error TS(2339): Property 'successMessage' does not exist on type '... Remove this comment to see the full error message
      successMessage,
      // @ts-expect-error TS(2339): Property 'onSuccess' does not exist on type 'ApiFo... Remove this comment to see the full error message
      onSuccess,
      // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type 'ApiFor... Remove this comment to see the full error message
      subtitle,
      // @ts-expect-error TS(2339): Property 'title' does not exist on type 'ApiFormPr... Remove this comment to see the full error message
      title,
      // @ts-expect-error TS(2339): Property 'submitButton' does not exist on type 'Ap... Remove this comment to see the full error message
      submitButton,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'ApiForm... Remove this comment to see the full error message
      onClick,
      // @ts-expect-error TS(2339): Property 'type' does not exist on type 'ApiFormPro... Remove this comment to see the full error message
      type,
      // @ts-expect-error TS(2339): Property 'which' does not exist on type 'ApiFormPr... Remove this comment to see the full error message
      which,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type 'ApiForm... Remove this comment to see the full error message
      project,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'ApiFormPr... Remove this comment to see the full error message
      theme,
    },
    ref,
  ) => {
    const errorColor = theme.brandColorGroups.warning;
    const successColor = theme.brandColorGroups.success;

    const [loading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = useState(false);

    const onError = (formError: any) => {
      setSuccess(false);
      if (typeof formError === 'object') {
        if (formError.graphQLErrors && formError.graphQLErrors.length > 0) {
          setErrors(formError.graphQLErrors.map((er: any) => er.message));
        } else if (formError.message) {
          // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
          setErrors([formError.message]);
        } else {
          // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
          setErrors([String(formError)]);
        }
      } else {
        // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
        setErrors([String(formError)]);
      }
    };

    const handleOnSuccess = (dataItem: any) => {
      setErrors(null);
      setSuccess(true);
      if (onSuccess) {
        onSuccess(dataItem);
      }
    };

    const fieldConfigs =
      endpoint &&
      endpoint.endpoint &&
      fields &&
      fields
        .map((paramConfig: any) => ({
          param: endpoint.endpoint.parameters.find(
            (param: any) => param.name === paramConfig.param,
          ),

          config: paramConfig,
        }))
        .filter((fieldConfig: any) => fieldConfig.param);

    return (
      <div
        className={classNames(
          'flex flex-col max-w-lg w-full mx-auto py-4',
          className,
        )}
        ref={ref}
        onClick={onClick}
      >
        {(title || subtitle) && (
          <Title
            subtitle={{
              hidden: !subtitle,
              value: subtitle,
            }}
            title={{
              hidden: !title,
              value: title,
            }}
            className="mb-6"
          />
        )}
        {editorMode &&
          (!endpoint || !endpoint.endpoint || fieldConfigs.length === 0) && (
            <Box
              bg={getColorShade(errorColor, '400')}
              className="p-4 rounded-lg flex mb-6 mt-2 items-center text-white"
            >
              <div className="flex flex-col font-medium text-lg">
                {getText('elements.FORMS.api.configure')}
              </div>
            </Box>
          )}
        {errors && (
          <Box
            bg={getColorShade(errorColor, '400')}
            className="p-4 rounded-lg flex mb-6 mt-2 items-center text-white"
          >
            {errorMessage.icon && errorMessage.icon.name && (
              <Icon
                icon={errorMessage.icon}
                className="w-8 h-10 opacity-50 mr-4"
              />
            )}
            <div className="flex flex-col">
              <span className="font-medium text-lg">
                {errorMessage.message}
              </span>
              <div className="flex flex-col space-y-2">
                {(errors as any).map((error: any) => (
                  <span key={error}>{error}</span>
                ))}
              </div>
            </div>
          </Box>
        )}
        {success && (
          <Box
            bg={getColorShade(successColor, '400')}
            className="p-4 rounded-lg flex items-center text-white mb-2"
          >
            {successMessage.icon && successMessage.icon.name && (
              <Icon
                icon={successMessage.icon}
                className="w-8 h-10 opacity-50 mr-4"
              />
            )}
            <span className="font-medium text-lg">
              {successMessage.message}
            </span>
          </Box>
        )}
        {endpoint && endpoint.endpoint && (
          // @ts-expect-error TS(2322): Type '{ children: any; className: string; innerCla... Remove this comment to see the full error message
          <AutoApiForm
            className="flex flex-col"
            innerClassName="mb-5"
            project={project}
            Label={Label}
            onError={onError}
            onSuccess={handleOnSuccess}
            api={endpoint.api}
            endpoint={endpoint.endpoint}
            fields={fieldConfigs}
            onLoadingChange={setIsLoading}
            surface="light"
          >
            {submitButton && (
              <div className="my-6">
                <SectionButton
                  // @ts-expect-error TS(2322): Type '{ disabled: boolean; loading: boolean; butto... Remove this comment to see the full error message
                  disabled={loading || (type === UPDATE && !which)}
                  loading={loading}
                  button={submitButton}
                  submitFormOnClick={true}
                  placeholder="Submit"
                />
              </div>
            )}
          </AutoApiForm>
        )}
      </div>
    );
  },
);

ApiForm.defaultProps = {
  // @ts-expect-error TS(2322): Type '{ dataType: string; fields: never[]; type: s... Remove this comment to see the full error message
  dataType: 'user',
  fields: [],
  type: CREATE,
  submitButton: {
    text: 'submit',
  },
};

export default withTheme(ApiForm);
