import React from 'react';
import { IconSquare, Icon as IconType } from '@tabler/icons-react';
import { getText } from '../utils/lang';
import { Element } from './Element';
import ElementPropType from './elementPropTypes/ElementPropType';
import ImagePropType from './elementPropTypes/comboProps/ImagePropType';

const baseProps = {
  bgImage: new ImagePropType().setIsStyleProp(true),
};

const defaultLabelFormatter = ({ type, props }: Element) =>
  (props && props.name) || getText('elements', type, 'label');

const identity = (element: Element): Element => element;
const trueFilter = () => true;

type ComponentType =
  | React.ComponentType
  | React.ForwardRefExoticComponent<any>
  | null;

class ElementConfig {
  component?: ComponentType;
  hidden: boolean;
  content: boolean;
  section: boolean;
  canHaveChildren: boolean;
  props: Record<any, ElementPropType>;
  defaultProps: Record<any, any>;
  styleable: boolean;
  Icon: IconType;
  PropsEditor?: React.ReactElement;
  deriveState: () => any[];
  allowedChildTypes?: any[];
  formatLabel: (element: Element) => any;
  cloneTransformation: (props: any, idMap?: Record<string, string>) => Element;
  filterChildren: (el: Element) => boolean;

  constructor({
    component = null,
    content = false,
    section = false,
    canHaveChildren = true,
    deriveState = () => [],
    hidden = false,
    props = {},
    defaultProps = {},
    Icon = IconSquare,
    PropsEditor = undefined,
    styleable = true,
    allowedChildTypes = undefined,
    formatLabel = defaultLabelFormatter,
    cloneTransformation = identity,
    filterChildren = trueFilter,
  }: {
    component?: ComponentType;
    content?: boolean;
    section?: boolean;
    canHaveChildren?: boolean;
    deriveState?: (...varArgs: any[]) => any[];
    hidden?: boolean;
    props?: any;
    defaultProps?: any;
    Icon?: IconType;
    PropsEditor?: any;
    styleable?: boolean;
    allowedChildTypes?: any;
    formatLabel?: (el: Element) => string;
    cloneTransformation?: (el: Element) => Element;
    filterChildren?: (el: Element) => boolean;
  } = {}) {
    this.component = component;
    this.hidden = hidden;
    this.content = content;
    this.section = section;
    this.canHaveChildren = canHaveChildren && !content;
    this.props = {
      ...baseProps,
      ...props,
    };
    this.defaultProps = defaultProps;
    this.styleable = styleable;
    this.Icon = Icon;
    this.PropsEditor = PropsEditor;
    this.deriveState = deriveState;
    this.allowedChildTypes = allowedChildTypes;
    this.formatLabel = formatLabel;
    this.cloneTransformation = cloneTransformation;
    this.filterChildren = filterChildren;
  }

  getLabel(element: Element) {
    return this.formatLabel(element);
  }
}

export default ElementConfig;
