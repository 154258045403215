import * as elementTypes from '../../../constants/elements';
import { getText } from '../../../utils/lang';
import ComboType from '../ComboPropType';
import EnumType from '../EnumPropType';
import NestedPropsPropType from '../NestedPropsPropType';

const SUPPORTED_ELEMENTS = [
  elementTypes.TEXT,
  elementTypes.LINK,
  elementTypes.BUTTON,
  elementTypes.MARKDOWN,
  elementTypes.BADGE,
  elementTypes.IMAGE,
  elementTypes.VIDEO,
  elementTypes.YOUTUBE_VIDEO,
  elementTypes.IFRAME,
  elementTypes.RECORD,
];

class ChildElementPropType extends ComboType {
  constructor(propMapper?: ((props: any) => any) | undefined) {
    super(
      {
        type: new EnumType(SUPPORTED_ELEMENTS).setFormatLabel((val) =>
          getText('elements', val, 'label'),
        ),
        props: new NestedPropsPropType(),
      },
      undefined,
      propMapper,
    );
  }

  hasTextChild(elementType: string) {
    return [elementTypes.LINK].includes(elementType);
  }
}

export default ChildElementPropType;
