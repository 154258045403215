import { useCallback, useEffect, useRef } from 'react';

const useRefCallback = (value: any, deps = []) => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const result = useCallback((...args: any[]) => {
    ref.current(...args);
  }, []);

  return result;
};

export default useRefCallback;
