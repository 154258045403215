import get from 'lodash/get';
import { ENDPOINT_PARAM } from '../../constants/elementPropTypeTypes';
import ElementPropType from './ElementPropType';

class EndpointParamPropType extends ElementPropType {
  filter: any;
  getApiEndpoint: any;
  constructor(
    getApiEndpoint = (element: any) => get(element, ['props.endpoint']),
    filter = () => true,
  ) {
    super(ENDPOINT_PARAM);
    this.getApiEndpoint = getApiEndpoint;
    this.filter = filter;
  }
}

export default EndpointParamPropType;
