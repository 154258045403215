import { FILE } from '../constants/builtInDataTypes';
import { DataSourceType } from '../constants/dataSources';
import { DataFieldType } from '../constants/dataTypes';
import { Relationship } from '../constants/relationships';
import {
  getBaseFieldReverseName,
  getFieldKey,
  getFieldReverseName,
} from '../utils/fields';
import ProjectArrayTypeMap, {
  DataFieldIdentifier,
  DataTypeName,
} from './ProjectArrayTypeMap';
import { Rollup } from './Rollup';

export type DataFieldOption = {
  id: string | number;
  name: string;
  display: string;
  color: string;
  order: number;
};

export interface BaseDataField extends DataFieldIdentifier {
  display: string;
  type: string;
  reverseName?: string | undefined | null;
  reverseDisplayName?: string | undefined | null;
  relatedField?: BaseDataField;
  relationship?: string | null;
  fieldKey?: string;
  isReverse?: boolean;
}

export type FieldTypeOptions = {
  max?: number;
  format?: string;
  precision?: number;
  symbol?: string;
  timeZone?: string;
  formula?: string;
};

export interface DataField extends BaseDataField {
  type: DataFieldType | string;
  order?: number | null;
  relationship?: Relationship | null;
  relatedField?: DataField;
  required?: boolean;
  unique?: boolean;
  internal?: boolean;
  hidden?: boolean;
  readOnly?: boolean;
  options?: DataFieldOption[];
  typeOptions?: FieldTypeOptions;
  rollup?: Rollup;
  source?: DataSourceType;
}

export class DataFieldArray<
  T extends BaseDataField & { relatedField?: T }
> extends ProjectArrayTypeMap<T> {
  dataQueryNameMap: Record<string, T>;
  whereFilterNameMap: Record<string, T>;
  relatedFields: T[];
  dataTypeNameToRelatedDataTypesMap: Record<string, T[]>;

  constructor(arrayOrLength?: T[] | number) {
    super(arrayOrLength);

    if (arrayOrLength instanceof DataFieldArray) {
      this.dataQueryNameMap = arrayOrLength.dataQueryNameMap || {};
      this.whereFilterNameMap = arrayOrLength.whereFilterNameMap || {};
      this.dataTypeNameToRelatedDataTypesMap =
        arrayOrLength.dataTypeNameToRelatedDataTypesMap || {};
      this.relatedFields = arrayOrLength.relatedFields || [];
    } else {
      this.dataQueryNameMap = {};
      this.whereFilterNameMap = {};
      this.dataTypeNameToRelatedDataTypesMap = {};
      this.relatedFields = [];
    }
  }

  private generateReverseField = (type: DataTypeName, relatedField: T): T => {
    const fieldKey = getFieldReverseName(relatedField, {
      apiName: type,
      id: -1,
      name: type,
    });

    return {
      ...relatedField,
      fieldKey,
      isReverse: true,
    };
  };

  _mapEntry(field: T) {
    super._mapEntry(field);

    if (!this.dataQueryNameMap) {
      return;
    }

    if (field.relatedField || field.relationship) {
      this.relatedFields.push(field);
    }

    if (field.relatedField) {
      const type = field.type;
      const fieldKey = getFieldReverseName(field.relatedField, {
        apiName: type,
        id: -1,
        name: type,
      });

      if (fieldKey) {
        this.dataQueryNameMap[fieldKey] = this.generateReverseField(
          type,
          field.relatedField,
        );
      }

      const whereFieldKey = getBaseFieldReverseName(
        field.relatedField as DataField,
        { name: field.type, apiName: field.type } as any,
      );
      const idKey = getFieldKey(field as DataField);
      this.whereFilterNameMap[whereFieldKey] = field;
      this.whereFilterNameMap[idKey] = field;
    } else {
      const dataQueryName = `${field.apiName}${field.relationship ? 'Id' : ''}`;

      this.dataQueryNameMap[dataQueryName] = field;
      this.whereFilterNameMap[dataQueryName] = field;
      if (field.relationship) {
        this.dataQueryNameMap[field.apiName] = field;
        this.whereFilterNameMap[field.apiName] = field;
      }
    }

    if (field.relationship && field.type !== FILE) {
      const relatedDataTypesMapUninitialized =
        this.dataTypeNameToRelatedDataTypesMap[field.type] === undefined;

      if (relatedDataTypesMapUninitialized) {
        this.dataTypeNameToRelatedDataTypesMap[field.type] = [];
      }

      if (!this.dataTypeNameToRelatedDataTypesMap[field.type].includes(field)) {
        this.dataTypeNameToRelatedDataTypesMap[field.type].push(field);
      }
    }
  }

  getByDataQueryName(fieldName: string) {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.dataQueryNameMap ? this.dataQueryNameMap[fieldName] : undefined;
  }

  getByWhereFilterName(fieldName: string) {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.whereFilterNameMap
      ? this.whereFilterNameMap[fieldName]
      : undefined;
  }

  getFieldsByRelatedDataTypeName(relatedDTName: string) {
    if (!this.idMap) {
      this._mapEntries();
    }
    return this.dataTypeNameToRelatedDataTypesMap[relatedDTName] || [];
  }

  getFieldsWithRelations() {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.relatedFields;
  }
}

class DataTypeFields extends DataFieldArray<DataField> {}

export default DataTypeFields;
