import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Calendar } from '@noloco/react-big-calendar';
import withDragAndDrop from '@noloco/react-big-calendar/lib/addons/dragAndDrop';
import { DATE } from '../../../constants/fieldFormats';
import useRouter from '../../../utils/hooks/useRouter';
import CalendarCurrentTimeIndicator from './calendar/CalendarCurrentTimeIndicator';
import CalendarDateCellWrapper from './calendar/CalendarDateCellWrapper';
import CalendarDayColumnWrapper from './calendar/CalendarDayColumnWrapper';
import CalendarEvent from './calendar/CalendarEvent';
import CalendarEventWrapper from './calendar/CalendarEventWrapper';
import CalendarLabel from './calendar/CalendarHeader';
import CalendarTimeSlotWrapper from './calendar/CalendarTimeSlotWrapper';
import CalendarToolbar from './calendar/CalendarToolbar';

const DnDCalendar = withDragAndDrop(Calendar);
const STEP = 15;

const CollectionCalendar = ({
  canUpdateEventDates,
  className,
  currentView,
  date,
  dateStartField,
  defaultViews,
  draftEvents,
  enableDragAndDropEdit,
  loading,
  localizer,
  project,
  qsSuffix,
  Row,
  rowLink,
  setView,
  updateEvent,
}: any) => {
  const { pushQueryParams } = useRouter();

  const components = useMemo(
    () => ({
      dateCellWrapper: CalendarDateCellWrapper,
      dayColumnWrapper: CalendarDayColumnWrapper,
      eventWrapper: (props: any) => (
        <CalendarEventWrapper
          {...props}
          Row={Row}
          rowLink={rowLink}
          project={project}
        />
      ),
      timeSlotWrapper: CalendarTimeSlotWrapper,
      event: CalendarEvent,
      day: {
        header: CalendarLabel,
      },
      week: {
        header: CalendarLabel,
      },
      work_week: {
        header: CalendarLabel,
      },
      month: {
        header: CalendarLabel,
      },
      toolbar: (props: any) => <CalendarToolbar {...props} loading={loading} />,
      currentTimeIndicator: CalendarCurrentTimeIndicator,
    }),
    [Row, loading, project, rowLink],
  );

  const handleEventDrop = useCallback(
    ({ event, start, end }: any) => updateEvent(event, start, end),
    [updateEvent],
  );

  const handleEventResize = useCallback(
    ({ event, start, end }: any) => updateEvent(event, start, end),
    [updateEvent],
  );

  const handleNavigationChange = useCallback(
    (nextDate: any) =>
      pushQueryParams({
        [`_date${qsSuffix}`]: nextDate.toISOString(),
      }),
    [pushQueryParams, qsSuffix],
  );

  return (
    <div className={classNames('h-full', className)}>
      <DnDCalendar
        components={components}
        date={date ? new Date(date) : undefined}
        localizer={localizer}
        loading={loading}
        events={draftEvents}
        step={STEP}
        allDayAccessor={() =>
          get(dateStartField, 'typeOptions.format') === DATE
        }
        startAccessor="start"
        endAccessor="end"
        draggableAccessor={() => enableDragAndDropEdit && canUpdateEventDates}
        resizableAccessor={(event: any) => canUpdateEventDates && event.hasEnd}
        onEventDrop={handleEventDrop}
        onEventResize={handleEventResize}
        onNavigate={handleNavigationChange}
        onView={setView}
        view={currentView}
        views={defaultViews}
      />
    </div>
  );
};

export default CollectionCalendar;
