import { useMemo } from 'react';
import {
  LG,
  MD,
  SM,
  XL,
  XS,
} from '@noloco/components/src/constants/tShirtSizes';
import useWindowSize from './useWindowSize';

type Breakpoints = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
};

const breakpoints: Breakpoints = {
  [XS as 'xs']: 0,
  [SM as 'sm']: 478,
  [MD as 'md']: 767,
  [LG as 'lg']: 1024,
  [XL as 'xl']: 1279,
};

type BreakpointsResult = {
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
};

const useBreakpoints = (): BreakpointsResult => {
  const { width } = useWindowSize();

  const breakpointStatus = useMemo(
    () => ({
      sm: !!width && width < breakpoints[SM],
      md: !!width && width < breakpoints[MD],
      lg: !!width && width < breakpoints[LG],
      xl: !!width && width < breakpoints[XL],
    }),
    [width],
  );

  return breakpointStatus;
};

export default useBreakpoints;
