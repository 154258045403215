import set from 'lodash/fp/set';
import get from 'lodash/get';
import last from 'lodash/last';
import { SelectOption } from '@noloco/components/src/components/select/SelectBase';
import { ActionType } from '../constants/actionTypes';
import { DECIMAL, DataFieldType, INTEGER } from '../constants/dataTypes';
import PRIMITIVE_DATA_TYPES from '../constants/primitiveDataTypes';
import { DATABASE } from '../constants/scopeTypes';
import { WorkflowActionType } from '../constants/workflowActionTypes';
import ActionConfig, {
  WorkflowActionConfig,
  WorkflowScopeOption,
} from '../models/ActionConfig';
import DataTypes from '../models/DataTypes';
import { BaseAction } from '../models/Element';
import StateItem from '../models/StateItem';
import { WorkflowAction } from '../models/Workflow';
import cappedMemoize from './cappedMemoize';
import {
  getCollectionOptionsOfTypeFromParent,
  getDataTypesKey,
  getTypeOptionsOfTypeFromParent,
  isPrimitiveType,
  resolveSingleDataItem,
  safelyAppendPath,
} from './data';
import { getText } from './lang';
import { isMultiRelationship } from './relationships';

type ActionConfigMap = Record<
  ActionType | WorkflowActionType,
  ActionConfig<BaseAction>
>;

const filterScopeOptions = (
  scopeOptions: WorkflowScopeOption[],
  acceptableDataTypes?: string[],
): WorkflowScopeOption[] => {
  if (!acceptableDataTypes) {
    return scopeOptions;
  }

  const expectingRelationshipType =
    acceptableDataTypes.length === 1 &&
    !PRIMITIVE_DATA_TYPES.includes(acceptableDataTypes[0] as DataFieldType);

  return scopeOptions.reduce((options, scopeOption) => {
    if (scopeOption.value) {
      if (
        acceptableDataTypes.includes(scopeOption.value.dataType) ||
        (expectingRelationshipType &&
          last(scopeOption.value.path.split('.')) === 'id')
      ) {
        options.push(scopeOption);
        return options;
      }
    }

    if (scopeOption.options) {
      const childOptions = filterScopeOptions(
        scopeOption.options,
        acceptableDataTypes,
      );
      if (childOptions.length > 0) {
        options.push({
          ...scopeOption,
          options: childOptions,
        });

        return options;
      }
    }

    return options;
  }, [] as WorkflowScopeOption[]);
};

export const getWorkflowActionScopeFieldOptionsOfType = cappedMemoize(
  (
    previousActions: WorkflowAction[],
    dataTypes: DataTypes,
    config: Record<WorkflowActionType, WorkflowActionConfig>,
    typeToFind: string,
  ) =>
    previousActions.reduce(
      (scopeOptions: SelectOption[], action: WorkflowAction, index: number) => {
        if (!action || !action.type) {
          return scopeOptions;
        }

        const actionConfig = config[action.type];
        const actionDataType = actionConfig.deriveScopeDataType(
          action,
          dataTypes,
          index,
        );

        if (!actionDataType) {
          return scopeOptions;
        }

        const label = getText({ step: index + 1 }, 'actions.step');

        const actionOptions = getFullDataTypeStateOptionsOfType(
          action.id,
          actionDataType,
          dataTypes,
          typeToFind,
        ).map((option: any) => ({
          ...option,
          buttonLabel: [label, option.buttonLabel].join(' > '),
        }));

        if (actionOptions.length === 0) {
          return scopeOptions;
        }

        scopeOptions.push({
          label,
          help: getText('actions.types', action.type, 'label'),
          options: actionOptions,
        });

        return scopeOptions;
      },
      [],
    ),
  {
    maxKeys: 200,
    getKey: ([previousActions, dataTypes, ...rest]) => {
      const dtKey = getDataTypesKey(dataTypes);
      return JSON.stringify({ previousActions, dtKey, rest });
    },
  },
);

export const buildActionScope = cappedMemoize(
  <T extends BaseAction>(
    previousActions: T[],
    dataTypes: DataTypes,
    config: ActionConfigMap,
    acceptableDataTypes?: string[],
  ) =>
    previousActions.reduce((scope: any, action: T, index: number) => {
      if (!action || !action.type) {
        return scope;
      }

      const actionConfig = config[action.type];
      const actionScope = filterScopeOptions(
        actionConfig.deriveScope(action, dataTypes, index),
        acceptableDataTypes,
      );

      if (actionScope.length === 0) {
        return scope;
      }

      const label = getText({ step: index + 1 }, 'actions.step');
      return [
        ...scope,
        {
          label,
          help: getText('actions.types', action.type, 'label'),
          options: actionScope.map((option: WorkflowScopeOption) => {
            if (option.buttonLabel) {
              return option;
            }

            return {
              ...option,
              buttonLabel: [label, option.label].join(' > '),
            };
          }),
        },
      ];
    }, []),
  {
    maxKeys: 200,
    getKey: ([previousActions, dataTypes, ...rest]) => {
      const dtKey = getDataTypesKey(dataTypes);
      return JSON.stringify({ previousActions, dtKey, rest });
    },
  },
);

export const getFullDataTypeStateOptions = cappedMemoize(
  (id, dataType, dataTypes) =>
    getTypeOptionsOfTypeFromParent(
      dataTypes,
      new StateItem({
        id,
        path: '',
        source: DATABASE,
        dataType: dataType.name,
        display: dataType.display,
      }),
    ),
  { maxKeys: 200 },
);

export const getFullDataTypeStateOptionsOfType = cappedMemoize(
  (id, dataType, dataTypes, typeToFind) => {
    const stateItem = new StateItem({
      id,
      path: '',
      source: DATABASE,
      dataType: dataType.name,
      display: dataType.display,
    });

    const typeOptions = getTypeOptionsOfTypeFromParent(dataTypes, stateItem, [
      typeToFind,
    ]);

    const collectionOptions = getCollectionOptionsOfTypeFromParent(
      dataTypes,
      stateItem,
      typeToFind,
    );

    // Merge the two lists based on 'label'
    return collectionOptions.reduce((options: any, option: any) => {
      const index = options.findIndex((op: any) => op.label === option.label);
      if (index < 0) {
        return [...options, option];
      }

      if (!option.options) {
        return set(
          [index, 'options'],
          [...get(options, [index, 'options'], []), option],
          options,
        );
      }

      return set(
        [index, 'options'],
        [
          ...get(options, [index, 'options'], []),
          ...get(option, 'options', []),
        ],
        options,
      );
    }, typeOptions);
  },
  {
    maxKeys: 200,
  },
);

export const getFullDataTypeCollectionOptions = cappedMemoize(
  (id, dataType, dataTypes, restrictToType) =>
    getCollectionOptionsOfTypeFromParent(
      dataTypes,
      new StateItem({
        id,
        path: '',
        source: DATABASE,
        dataType: dataType.name,
        display: dataType.display,
      }),
      restrictToType,
    ),
  { maxKeys: 200 },
);

export const getDataTypeStateOptions = (id: any, dataType: any) =>
  dataType.fields
    .filter(
      ({ relationship, relatedField }: any) => !relationship && !relatedField,
    )
    .map(
      (field: any) =>
        new StateItem({
          id,
          path: field.name,
          source: DATABASE,
          dataType: field.type,
          display: field.display,
        }),
    );

const transformActionValue = (value: any, field: any) => {
  const isMultiple = isMultiRelationship(field.relationship);
  if (
    !Array.isArray(value) &&
    typeof value === 'object' &&
    value.id &&
    field.type !== 'file'
  ) {
    value = value.id;
  }
  if (field.type === DECIMAL) {
    if (isMultiple) {
      if (Array.isArray(value)) {
        return value.map(parseFloat);
      }
      return [value.map(parseFloat)];
    }
    return parseFloat(value);
  }
  if (field.type === INTEGER) {
    if (isMultiple) {
      if (Array.isArray(value)) {
        return value.map((val) => parseInt(val, 10));
      }
      return [parseInt(value, 10)];
    }
    return parseInt(value, 10);
  }

  if (field.type === 'file') {
    const containsAtLeastOneElement = Array.isArray(value) && value.length > 0;
    if (containsAtLeastOneElement && Array.isArray(value[0])) {
      const files = value.map((fileAr: any) => fileAr[0]);
      return isMultiple ? files : files[0];
    }
    if (containsAtLeastOneElement) {
      return isMultiple ? [value[0]] : value[0];
    }
    return undefined;
  }

  if (isMultiple) {
    if (Array.isArray(value)) {
      return value.map((val) => parseInt(val, 10));
    }
    return [parseInt(value, 10)];
  }

  return value;
};

export const resolveFieldActionDataItem = (
  rawDataItem: any,
  field: any,
  scope: any,
  project: any,
) => {
  const isMultiple = isMultiRelationship(field.relationship);
  const dataItem = {
    data:
      (isPrimitiveType(field.type) || field.type === 'file' || isMultiple) &&
      field.name !== 'id'
        ? rawDataItem
        : {
            ...rawDataItem,
            path: safelyAppendPath(rawDataItem.path, 'id'),
            dataType: INTEGER,
          },
  };

  if (!dataItem) {
    return undefined;
  }

  const resolvedValue = resolveSingleDataItem(dataItem, scope, project, true);
  if (!resolvedValue) {
    return undefined;
  }

  return transformActionValue(resolvedValue, field);
};
