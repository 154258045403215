import React, { useCallback, useMemo, useState } from 'react';
import { TABLE } from '@noloco/core/src/constants/dataSources';
import { DATE, TEXT } from '@noloco/core/src/constants/dataTypes';
import ViewCollection from '@noloco/core/src/elements/ViewCollection';
import { Project } from '@noloco/core/src/models/Project';
import { CollectionField } from '@noloco/core/src/models/View';
import { WEBSITE_EXAMPLES, trackEvent } from '@noloco/core/src/utils/analytics';
import { getText } from '@noloco/core/src/utils/lang';
import BaseExample from '../BaseExample';
import FieldItemEditor from '../FieldItemEditor';

const LANG_KEY = 'websiteExamples.collectionField';

const EMPTY_ARRAY: any[] = [];
const EMPTY_OBJECT: Record<any, any> = {};

const voidFn = () => {};

const FIELD_TO_EDIT = 'projectLead';

const CollectionFieldExample = ({ project }: { project: Project }) => {
  const formatRecordScope = (record: any) => ({
    [`${element.id}:VIEW`]: record,
  });

  const [fieldLabel, setFieldLabel] = useState('Lead');
  const [showField, setShowField] = useState(true);

  const onToggleShowField = useCallback((show: boolean) => {
    setShowField(show);
    trackEvent(WEBSITE_EXAMPLES.COLLECTION_FIELD_TOGGLED, 'show', show);
  }, []);

  const onChangeFieldLabel = useCallback((label: string) => {
    setFieldLabel(label);
    trackEvent(WEBSITE_EXAMPLES.COLLECTION_LABEL_CHANGED);
  }, []);

  const element = project.elements[0];

  const { fields, filters = EMPTY_ARRAY, dataList, record } = element.props;

  const hydratedDataList = useMemo(
    () => ({
      ...dataList,
      showPagination: false,
      limit: 5,
    }),
    [dataList],
  );

  const tableFields = useMemo(() => {
    if (!showField) {
      return fields.filter(
        (field: CollectionField) => field.name !== FIELD_TO_EDIT,
      );
    }

    return fields.map((field: CollectionField) => {
      if (field.name !== FIELD_TO_EDIT) {
        return field;
      }

      return {
        ...field,
        label: {
          value: fieldLabel,
        },
      };
    });
  }, [fieldLabel, fields, showField]);

  return (
    <BaseExample showGradient={false}>
      <div className="flex md:flex-col md:justify-center items-center">
        <div className="flex flex-col justify-center items-center mb-8 flex-shrink-0 w-2/5 md:w-full space-y-3 px-4">
          <div className="flex flex-col">
            <h2 className="text-3xl font-medium mb-3">
              {getText(LANG_KEY, 'title')}
            </h2>
            <p className="text-base mb-8">{getText(LANG_KEY, 'subtitle')}</p>
          </div>
          <FieldItemEditor
            fieldName="Project Name"
            type={TEXT}
            enabled={true}
            disabled={true}
          />
          <FieldItemEditor
            fieldName={fieldLabel}
            type="lead"
            enabled={true}
            disabled={false}
            onChangeLabel={onChangeFieldLabel}
            onChangeSwitch={onToggleShowField}
          />
          <FieldItemEditor
            fieldName="Notes"
            type={TEXT}
            enabled={false}
            disabled={true}
          />
          <FieldItemEditor
            fieldName="Start Date"
            type={DATE}
            enabled={true}
            disabled={true}
          />
        </div>
        <ViewCollection
          actionButtons={EMPTY_ARRAY}
          charts={EMPTY_ARRAY}
          className="website-example ml-8 md:ml-0 max-w-full"
          dataList={hydratedDataList}
          fields={tableFields}
          formatRecordScope={formatRecordScope}
          layout={TABLE}
          editorMode={false}
          elementPath={[0]}
          hideNewButton={true}
          scope={EMPTY_OBJECT}
          filters={filters}
          record={record}
          rootPathname="/"
          project={project}
          viewId={element.id}
          viewRootPathname="/"
          enableDragAndDropEdit={false}
          onUpdateProject={voidFn}
          track={false}
        />
      </div>
    </BaseExample>
  );
};

export default CollectionFieldExample;
