import { useCallback } from 'react';
import { getText } from '../lang';
import { useGraphQlErrorAlert } from './useAlerts';

const useActionsWithHiddenInputs = (
  createMutation: () => Promise<any>,
  updateMutation: () => Promise<any>,
  onNext: any,
) => {
  const networkErrorAlert = useGraphQlErrorAlert();

  const onCreate = useCallback(
    (action: any, setIsLoading: any) => {
      setIsLoading(true);

      return createMutation()
        .then((result) => {
          setIsLoading(false);
          onNext(result);
        })
        .catch((e) => {
          console.error('Error running one-click create action', e);
          networkErrorAlert(getText('elements.VIEW.actionButtons.error'), e);
        });
    },
    [createMutation, networkErrorAlert, onNext],
  );

  const onUpdate = useCallback(
    (action: any, setIsLoading: any) => {
      setIsLoading(true);

      return updateMutation()
        .then((result) => {
          setIsLoading(false);
          onNext(result);
        })
        .catch((e) => {
          console.error('Error running one-click update action', e);
          networkErrorAlert(getText('elements.VIEW.actionButtons.error'), e);
        });
    },
    [networkErrorAlert, onNext, updateMutation],
  );

  return {
    onCreate,
    onUpdate,
  };
};

export default useActionsWithHiddenInputs;
