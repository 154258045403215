export const ID_FIELDS = ['id', 'uuid'];
export const DEFAULT_FIELDS = [
  ...ID_FIELDS,
  'createdAt',
  'updatedAt',
  'externalHash',
];

export const ID_ID = 10001;
export const UUID_ID = 10002;
export const CREATED_AT_ID = 10003;
export const UPDATED_AT_ID = 10004;
export const EXTERNAL_HASH_ID = 10016;

export const ID_FIELD_IDS = [ID_ID, UUID_ID];

export const DEFAULT_FIELD_IDS = [
  ...ID_FIELD_IDS,
  CREATED_AT_ID,
  UPDATED_AT_ID,
  10005,
  10006,
  10007,
  10008,
  10009,
  10010,
  10011,
  10012,
  10013,
  10014,
  10015,
  EXTERNAL_HASH_ID,
  10057,
  10058,
  10059,
];
