import React, { useMemo } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import loadable from '@loadable/component';
import { getInputStyles } from '@noloco/components/src/components/input/TextInput';
import { ROUNDED_LARGE } from '@noloco/components/src/components/input/inputStyles';

const AsyncRichTextEditor = loadable(() =>
  import('../../../components/richTextEditor/RichTextEditor'),
);

const RichTextInput = ({
  disabled,
  onChange,
  placeholder,
  surface,
  theme,
  value,
}: any) => {
  const inputStyles = useMemo(
    () => getInputStyles({ disabled, theme, surface, style: ROUNDED_LARGE }),
    [disabled, surface, theme],
  );

  return (
    <Box
      className="py-1.5 px-1.5"
      is={AsyncRichTextEditor}
      {...inputStyles}
      onChange={onChange}
      placeholder={placeholder}
      richTextControls={true}
      shouldFocus={false}
      value={value}
    />
  );
};

export default withTheme(RichTextInput);
