import React from 'react';
import { useDispatch } from 'react-redux';
import { setNewElementPath } from '@noloco/core/src/reducers/elements';
import { getText } from '../utils/lang';

const DummyBlock = ({ onClick, disabled, elementPath }: any) => {
  const dispatch = useDispatch();
  return (
    <div className="my-4 p-8 w-full flex items-center justify-center border-1 mx-auto h-64 cursor-pointer text-center">
      <div
        onClick={(e) => {
          if (!disabled) {
            e.stopPropagation();
            if (onClick) {
              onClick(e);
            }
            if (elementPath) {
              dispatch(setNewElementPath(elementPath));
            }
          }
        }}
        className="w-full h-full flex items-center justify-center border-4 rounded-lg border-dashed hover:bg-gray-100 bg:opacity-25 p-20"
      >
        {getText('elements.empty')}
      </div>
    </div>
  );
};

export default DummyBlock;
