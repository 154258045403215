import React, { forwardRef, memo } from 'react';
import { TABS, VIEW } from '@noloco/core/src/constants/elements';
import WithUpdateProjectHooks from './WithUpdateProjectHooks';

const elementWrappers = {
  [TABS]: WithUpdateProjectHooks,
  [VIEW]: WithUpdateProjectHooks,
};

const ElementEditorWrapper = memo(
  // @ts-expect-error TS(2345): Argument of type '({ children, element, elementPat... Remove this comment to see the full error message
  forwardRef(({ children, element, elementPath, project }, ref) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const Wrapper = elementWrappers[element.type];
    if (Wrapper) {
      return (
        <Wrapper
          elementId={element.id}
          elementPath={elementPath}
          project={project}
          ref={ref}
          {...element.props}
        >
          {children}
        </Wrapper>
      );
    }

    return React.Children.map(children, (child) =>
      // @ts-expect-error TS(2769): No overload matches this call.
      React.cloneElement(child, {
        ref,
      }),
    );
  }),
);

(ElementEditorWrapper as any).propTypes = {};

export default ElementEditorWrapper;
