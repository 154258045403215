import React, { memo, useCallback, useMemo, useState } from 'react';
import loadable from '@loadable/component';
import { IconPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import {
  LINK,
  PLAIN,
  PREVIEW_MODAL,
} from '../../../constants/fileFieldElementTypes';
import useRouter from '../../../utils/hooks/useRouter';
import DropzonePreview from './DropzonePreview';

const FilePreviewModal = loadable(() => import('./FilePreviewModal'));

const FilePreview = memo(
  ({
    // @ts-expect-error TS(2339): Property 'elementType' does not exist on type '{}'... Remove this comment to see the full error message
    elementType,
    // @ts-expect-error TS(2339): Property 'files' does not exist on type '{}'.
    files,
    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
    id,
    // @ts-expect-error TS(2339): Property 'isMultiple' does not exist on type '{}'.
    isMultiple,
    // @ts-expect-error TS(2339): Property 'maxH' does not exist on type '{}'.
    maxH,
    // @ts-expect-error TS(2339): Property 'onChange' does not exist on type '{}'.
    onChange,
    // @ts-expect-error TS(2339): Property 'onRemove' does not exist on type '{}'.
    onRemove,
    // @ts-expect-error TS(2339): Property 'placeholder' does not exist on type '{}'... Remove this comment to see the full error message
    placeholder,
    // @ts-expect-error TS(2339): Property 'readOnly' does not exist on type '{}'.
    readOnly,
    // @ts-expect-error TS(2339): Property 'surface' does not exist on type '{}'.
    surface,
  }) => {
    const [localOpenIndex, setOpenIndex] = useState(null);
    const disableModal = elementType && elementType !== PREVIEW_MODAL;
    const {
      replaceQueryParams,
      // @ts-expect-error TS(2339): Property '_fileId' does not exist on type '{}'.
      query: { _fileId, _fileField },
    } = useRouter();

    const FileElement = elementType === LINK ? 'a' : 'div';

    const filteredFiles = useMemo(() => (files ? files.filter(Boolean) : []), [
      files,
    ]);

    const openIndex = useMemo(
      () =>
        localOpenIndex ||
        (_fileField === String(id) &&
          filteredFiles.findIndex((file: any) => file.id === _fileId)),
      [_fileField, _fileId, filteredFiles, id, localOpenIndex],
    );

    const getOnFileClick = useCallback(
      (index: any) => {
        if (elementType === PLAIN) {
          return undefined;
        }

        return (event: any) => {
          event.stopPropagation();

          if (!disableModal) {
            event.preventDefault();

            if (id) {
              const file = files[index];
              if (file) {
                return replaceQueryParams({
                  _fileId: file.id,
                  _fileField: id,
                });
              }
            }

            return setOpenIndex(index);
          }
        };
      },
      [disableModal, elementType, files, id, replaceQueryParams],
    );

    const onClose = useCallback(() => {
      setOpenIndex(null);
      replaceQueryParams({
        _fileId: undefined,
        _fileField: undefined,
      });
    }, [replaceQueryParams]);

    if (filteredFiles.length === 0 && !readOnly) {
      return (
        <DropzonePreview
          id={id}
          elementType={elementType}
          loading={false}
          placeholder={placeholder}
          mediaItem={null}
          maxH={maxH}
          showIcon={false}
          onRemove={() => onChange(null)}
        />
      );
    }

    if (!isMultiple) {
      const firstFile = first(files);
      return (
        <>
          <FileElement
            className="flex items-center rounded-lg"
            target={elementType === LINK ? '_blank' : undefined}
            href={elementType === LINK ? (firstFile as any).url : undefined}
            onClick={getOnFileClick(0)}
          >
            <DropzonePreview
              id={id}
              className={classNames('rounded-lg overflow-hidden', {
                'hover:ring-2 hover:ring-opacity-50 hover:ring-gray-400 cursor-pointer':
                  elementType !== PLAIN,
              })}
              loading={false}
              placeholder={placeholder}
              mediaItem={firstFile}
              maxH="20"
              disabled={readOnly}
              showIcon={false}
              onRemove={() => onChange(null)}
              surface={surface}
            />
          </FileElement>
          {openIndex >= 0 && openIndex !== false && !disableModal && (
            <FilePreviewModal
              // @ts-expect-error TS(2322): Type '{ id: any; onClose: () => void; onRemove: ((... Remove this comment to see the full error message
              id={id}
              onClose={onClose}
              onRemove={
                !readOnly && onChange ? () => onChange(null) : undefined
              }
              initialIndex={openIndex}
              files={filteredFiles}
              surface={surface}
              queryFileId={_fileId}
            />
          )}
        </>
      );
    }

    return (
      <div className="flex flex-wrap w-full">
        {files.map((file: any, index: any) => (
          <FileElement
            className={classNames(
              'm-2 overflow-hidden flex items-center rounded-lg',
              {
                'hover:ring-2 hover:ring-opacity-50 hover:ring-gray-400 cursor-pointer':
                  elementType !== PLAIN,
              },
            )}
            target={elementType === LINK ? '_blank' : undefined}
            href={elementType === LINK ? file.url : undefined}
            onClick={getOnFileClick(index)}
            key={file.id}
          >
            <DropzonePreview
              id={id}
              loading={false}
              className="max-w-full"
              placeholder={placeholder}
              mediaItem={file}
              maxH={maxH}
              showIcon={false}
              disabled={true}
              onRemove={() => onChange(null)}
              surface={surface}
            />
          </FileElement>
        ))}
        {files.length > 0 && !readOnly && (
          <div
            className={classNames(
              'm-2 rounded-lg  w-full h-8 flex items-center justify-center sticky z-10 bottom-2',
              {
                'bg-gray-100 hover:bg-gray-200': surface !== 'dark',
                'bg-gray-800 hover:bg-gray-900': surface === 'dark',
              },
            )}
          >
            <IconPlus size={14} />
          </div>
        )}
        {files.length === 0 && !readOnly && (
          <DropzonePreview
            id={id}
            loading={false}
            placeholder={placeholder}
            mediaItem={null}
            maxH={maxH}
            showIcon={false}
            onRemove={() => null}
            surface={surface}
          />
        )}
        {openIndex >= 0 && openIndex !== false && !disableModal && (
          <FilePreviewModal
            // @ts-expect-error TS(2322): Type '{ id: any; onClose: () => void; onRemove: an... Remove this comment to see the full error message
            id={id}
            onClose={onClose}
            onRemove={!readOnly ? onRemove : undefined}
            initialIndex={openIndex}
            files={filteredFiles}
            queryFileId={_fileId}
          />
        )}
      </div>
    );
  },
);

(FilePreview as any).defaultProps = {
  maxH: 20,
};

export default FilePreview;
