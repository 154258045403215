import { DateTime } from 'luxon';
import {
  AccountPlan,
  BUSINESS,
  ENTERPRISE,
  EXPERT,
  FREE,
  PRO,
  PROFESSIONAL,
  SCALE,
  STARTER,
  TEAM,
} from './accountPlans';

/**
 * Features can be on/off, limited to some number/duration or unlimited.
 */
export type FeatureValue = boolean | number | undefined;

export interface PlanConfig {
  // Apps
  projects?: number;
  testProjects?: number;
  projectVersions: boolean;

  // Users
  builders?: number;
  teamMembers?: number;
  activeUsers?: number;
  externalUsers?: number | boolean;

  // Data
  records?: number;
  sourceSyncing: boolean;
  prioritySourcesSyncing: boolean;
  filesGb?: number;
  dataEnvironments: boolean;

  // User Access
  userRoles?: number;
  conditionalUserListRoles: boolean;
  fieldPermissions: boolean;
  customVisbilityRules: boolean;
  customFieldVisibility: boolean;

  // Other Features
  fieldValidationRules: boolean;
  premiumLayouts: boolean;

  // Branding
  customDomain: boolean;
  whiteLabel: boolean;
  smtp: boolean;

  // Workflows
  workflowRuns?: number;
  activeWorkflows?: number;

  // Technical Features
  api: boolean;
  sdk: boolean;
  customCode: boolean;

  // Security Features
  sso: boolean;
  disablePasswordLogin: boolean;
  ['2fa']: boolean;

  // Support
  dataConsultation: boolean;
  chat: boolean;
  privateSlack: boolean;
  accountManager: boolean;
}

export type Feature = keyof PlanConfig;
export type PlanConfigs = { [T in AccountPlan]?: PlanConfig };

// Apps
export const LIVE_APPS: Feature = 'projects';
export const TEST_APPS: Feature = 'testProjects';
export const APP_VERSIONS: Feature = 'projectVersions';

// Users
export const BUILDERS: Feature = 'builders';
export const TEAM_MEMBERS: Feature = 'teamMembers';
export const ACTIVE_USERS: Feature = 'activeUsers';
export const EXTERNAL_USERS: Feature = 'externalUsers';

// Data
export const DATABASE_RECORDS: Feature = 'records';
export const AUTOMATIC_SOURCE_SYNCING: Feature = 'sourceSyncing';
export const PRIORITY_SOURCE_SYNCING: Feature = 'prioritySourcesSyncing';
export const FILE_STORAGE: Feature = 'filesGb';
export const DATA_ENVIRONMENTS: Feature = 'dataEnvironments';

// User Access
export const USER_ROLES: Feature = 'userRoles';
export const CONDITIONAL_USER_LIST_ROLES = 'conditionalUserListRoles';
export const FIELD_LEVEL_PERMISSIONS: Feature = 'fieldPermissions';
export const CUSTOM_VISIBILITY_RULES: Feature = 'customVisbilityRules';
export const CONDITIONAL_FIELD_VISIBILITY: Feature = 'customFieldVisibility';

// Other Features
export const PREMIUM_LAYOUTS: Feature = 'premiumLayouts';
export const FIELD_VALIDATION_RULES: Feature = 'fieldValidationRules';

// Branding
export const CUSTOM_DOMAIN: Feature = 'customDomain';
export const WHITE_LABEL: Feature = 'whiteLabel';
export const SMTP: Feature = 'smtp';

// Workflows
export const WORKFLOW_RUNS: Feature = 'workflowRuns';
export const ACTIVE_WORKFLOWS: Feature = 'activeWorkflows';

// Technical Features
export const API_ACCESS: Feature = 'api';
export const PYTHON_SDK: Feature = 'sdk';
export const CUSTOM_CODE: Feature = 'customCode';

// Security Features
export const SINGLE_SIGN_ON: Feature = 'sso';
export const DISABLE_PASSWORD_LOGIN: Feature = 'disablePasswordLogin';
export const TWO_FACTOR_AUTHENTICATION: Feature = '2fa';

// Support
export const DATA_CONSULTATION: Feature = 'dataConsultation';
export const LIVE_CHAT: Feature = 'chat';
export const PRIVATE_SLACK_CHANNEL: Feature = 'privateSlack';
export const ACCOUNT_MANAGER: Feature = 'accountManager';

export const FREE_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: false,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: 3,
  [EXTERNAL_USERS]: false,

  // Data
  [DATABASE_RECORDS]: undefined,
  [AUTOMATIC_SOURCE_SYNCING]: false,
  [PRIORITY_SOURCE_SYNCING]: false,
  [FILE_STORAGE]: 1,
  [DATA_ENVIRONMENTS]: false,

  // User Access
  [USER_ROLES]: 2,
  [CONDITIONAL_USER_LIST_ROLES]: false,
  [FIELD_LEVEL_PERMISSIONS]: false,
  [CUSTOM_VISIBILITY_RULES]: false,
  [CONDITIONAL_FIELD_VISIBILITY]: false,

  // Other Features
  [FIELD_VALIDATION_RULES]: false,
  [PREMIUM_LAYOUTS]: false,

  // Branding
  [CUSTOM_DOMAIN]: false,
  [WHITE_LABEL]: false,
  [SMTP]: false,

  // Workflows
  [WORKFLOW_RUNS]: 500,
  [ACTIVE_WORKFLOWS]: undefined,

  // Technical Features
  [API_ACCESS]: false,
  [PYTHON_SDK]: false,
  [CUSTOM_CODE]: false,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: false,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,
};

const withFreePlan = (planConfigs: PlanConfigs): PlanConfigs => ({
  ...planConfigs,
  [FREE]: FREE_PLAN,
});

/**
 * The ENTERPRISE plan is not truly unlimited, most of the usage limits will be
 * set to some custom value in TeamPlan.customConfig, however for completeness
 * they are defaulted to unlimited here.
 */
export const ENTERPRISE_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: true,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: undefined,
  [EXTERNAL_USERS]: false,

  // Data
  [DATABASE_RECORDS]: undefined,
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: true,
  [FILE_STORAGE]: undefined,
  [DATA_ENVIRONMENTS]: true,

  // User Access
  [USER_ROLES]: undefined,
  [CONDITIONAL_USER_LIST_ROLES]: true,
  [FIELD_LEVEL_PERMISSIONS]: true,
  [CUSTOM_VISIBILITY_RULES]: true,
  [CONDITIONAL_FIELD_VISIBILITY]: true,

  // Other Features
  [FIELD_VALIDATION_RULES]: true,
  [PREMIUM_LAYOUTS]: true,

  // Branding
  [CUSTOM_DOMAIN]: true,
  [WHITE_LABEL]: true,
  [SMTP]: true,

  // Workflows
  [WORKFLOW_RUNS]: undefined,
  [ACTIVE_WORKFLOWS]: undefined,

  // Technical Features
  [API_ACCESS]: true,
  [PYTHON_SDK]: true,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: true,
  [DISABLE_PASSWORD_LOGIN]: true,
  [TWO_FACTOR_AUTHENTICATION]: true,

  // Support
  [DATA_CONSULTATION]: true,
  [LIVE_CHAT]: true,
  [PRIVATE_SLACK_CHANNEL]: true,
  [ACCOUNT_MANAGER]: true,
};

/**
 * The EXPERT plan is not an advertised plan, instead when experts want to work
 * with Noloco for clients we give them ENTERPRISE access without priority
 * source syncing.
 */
export const EXPERT_PLAN = {
  ...ENTERPRISE_PLAN,
  [LIVE_APPS]: 0,
  [PRIORITY_SOURCE_SYNCING]: false,
};

const withCustomPlans = (planConfigs: PlanConfigs): PlanConfigs => ({
  ...planConfigs,
  [ENTERPRISE]: ENTERPRISE_PLAN,
  [EXPERT]: EXPERT_PLAN,
});

// Legacy plans.

export const STARTER_LEGACY_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: false,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: undefined,
  [EXTERNAL_USERS]: false,

  // Data
  [DATABASE_RECORDS]: undefined,
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: false,
  [FILE_STORAGE]: undefined,
  [DATA_ENVIRONMENTS]: false,

  // User Access
  [USER_ROLES]: undefined,
  [CONDITIONAL_USER_LIST_ROLES]: false,
  [FIELD_LEVEL_PERMISSIONS]: false,
  [CUSTOM_VISIBILITY_RULES]: false,
  [CONDITIONAL_FIELD_VISIBILITY]: false,

  // Other Features
  [FIELD_VALIDATION_RULES]: false,
  [PREMIUM_LAYOUTS]: false,

  // Branding
  [CUSTOM_DOMAIN]: false,
  [WHITE_LABEL]: false,
  [SMTP]: false,

  // Workflows
  [WORKFLOW_RUNS]: undefined,
  [ACTIVE_WORKFLOWS]: undefined,

  // Technical Features
  [API_ACCESS]: false,
  [PYTHON_SDK]: false,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: false,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,
};

export const PROFESSIONAL_LEGACY_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: false,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: undefined,
  [EXTERNAL_USERS]: false,

  // Data
  [DATABASE_RECORDS]: undefined,
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: false,
  [FILE_STORAGE]: undefined,
  [DATA_ENVIRONMENTS]: false,

  // User Access
  [USER_ROLES]: undefined,
  [CONDITIONAL_USER_LIST_ROLES]: false,
  [FIELD_LEVEL_PERMISSIONS]: false,
  [CUSTOM_VISIBILITY_RULES]: false,
  [CONDITIONAL_FIELD_VISIBILITY]: false,

  // Other Features
  [FIELD_VALIDATION_RULES]: false,
  [PREMIUM_LAYOUTS]: false,

  // Branding
  [CUSTOM_DOMAIN]: true,
  [WHITE_LABEL]: true,
  [SMTP]: false,

  // Workflows
  [WORKFLOW_RUNS]: undefined,
  [ACTIVE_WORKFLOWS]: undefined,

  // Technical Features
  [API_ACCESS]: false,
  [PYTHON_SDK]: false,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: false,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,
};

export const TEAM_LEGACY_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: false,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: undefined,
  [EXTERNAL_USERS]: false,

  // Data
  [DATABASE_RECORDS]: undefined,
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: false,
  [FILE_STORAGE]: undefined,
  [DATA_ENVIRONMENTS]: false,

  // User Access
  [USER_ROLES]: undefined,
  [CONDITIONAL_USER_LIST_ROLES]: true,
  [FIELD_LEVEL_PERMISSIONS]: true,
  [CUSTOM_VISIBILITY_RULES]: true,
  [CONDITIONAL_FIELD_VISIBILITY]: true,

  // Other Features
  [FIELD_VALIDATION_RULES]: true,
  [PREMIUM_LAYOUTS]: true,

  // Branding
  [CUSTOM_DOMAIN]: true,
  [WHITE_LABEL]: true,
  [SMTP]: false,

  // Workflows
  [WORKFLOW_RUNS]: undefined,
  [ACTIVE_WORKFLOWS]: undefined,

  // Technical Features
  [API_ACCESS]: true,
  [PYTHON_SDK]: false,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: true,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,
};

export const LEGACY_PLAN_CONFIG: PlanConfigs = withCustomPlans({
  [STARTER]: STARTER_LEGACY_PLAN,
  [PROFESSIONAL]: PROFESSIONAL_LEGACY_PLAN,
  [TEAM]: TEAM_LEGACY_PLAN,
});

// Usage-based plans introduced in September 2022.

export const TEAM_USAGE_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: 1,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: false,

  // Users
  [BUILDERS]: 2,
  [TEAM_MEMBERS]: 10,
  [ACTIVE_USERS]: 100,
  [EXTERNAL_USERS]: false,

  // Data
  [DATABASE_RECORDS]: 30000,
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: false,
  [FILE_STORAGE]: 10,
  [DATA_ENVIRONMENTS]: false,

  // User Access
  [USER_ROLES]: 6,
  [CONDITIONAL_USER_LIST_ROLES]: true,
  [FIELD_LEVEL_PERMISSIONS]: true,
  [CUSTOM_VISIBILITY_RULES]: true,
  [CONDITIONAL_FIELD_VISIBILITY]: true,

  // Branding
  [CUSTOM_DOMAIN]: true,
  [WHITE_LABEL]: true,
  [SMTP]: false,

  // Other Features
  [FIELD_VALIDATION_RULES]: true,
  [PREMIUM_LAYOUTS]: true,

  // Workflows
  [WORKFLOW_RUNS]: 1000,
  [ACTIVE_WORKFLOWS]: 10,

  // Technical Features
  [API_ACCESS]: true,
  [PYTHON_SDK]: false,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: true,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,
};

export const SCALE_USAGE_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: 1,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: true,

  // Users
  [BUILDERS]: 3,
  [TEAM_MEMBERS]: 20,
  [ACTIVE_USERS]: 350,
  [EXTERNAL_USERS]: false,

  // Data
  [DATABASE_RECORDS]: 75000,
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: true,
  [FILE_STORAGE]: 20,
  [DATA_ENVIRONMENTS]: false,

  // User Access
  [USER_ROLES]: undefined,
  [CONDITIONAL_USER_LIST_ROLES]: true,
  [FIELD_LEVEL_PERMISSIONS]: true,
  [CUSTOM_VISIBILITY_RULES]: true,
  [CONDITIONAL_FIELD_VISIBILITY]: true,

  // Other Features
  [FIELD_VALIDATION_RULES]: true,
  [PREMIUM_LAYOUTS]: true,

  // Branding
  [CUSTOM_DOMAIN]: true,
  [WHITE_LABEL]: true,
  [SMTP]: true,

  // Workflows
  [WORKFLOW_RUNS]: 5000,
  [ACTIVE_WORKFLOWS]: 30,

  // Technical Features
  [API_ACCESS]: true,
  [PYTHON_SDK]: true,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: true,
  [LIVE_CHAT]: true,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,
};

export const USAGE_BASED_PLAN_CONFIG: PlanConfigs = withFreePlan(
  withCustomPlans({
    [TEAM]: TEAM_USAGE_PLAN,
    [SCALE]: SCALE_USAGE_PLAN,
  }),
);

export const USAGE_BASED_PLAN_EPOCH: DateTime = DateTime.fromISO('2022-09-01', {
  zone: 'utc',
});

// Seat-based plans introduced in November 2022.

export const PRO_SEAT_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: false,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: undefined,
  [EXTERNAL_USERS]: true,

  // Data
  [DATABASE_RECORDS]: undefined,
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: false,
  [FILE_STORAGE]: 10,
  [DATA_ENVIRONMENTS]: false,

  // User Access
  [USER_ROLES]: 6,
  [CONDITIONAL_USER_LIST_ROLES]: true,
  [FIELD_LEVEL_PERMISSIONS]: false,
  [CUSTOM_VISIBILITY_RULES]: true,
  [CONDITIONAL_FIELD_VISIBILITY]: true,

  // Branding
  [CUSTOM_DOMAIN]: false,
  [WHITE_LABEL]: false,
  [SMTP]: false,

  // Other Features
  [FIELD_VALIDATION_RULES]: true,
  [PREMIUM_LAYOUTS]: true,

  // Workflows
  [WORKFLOW_RUNS]: 3000,
  [ACTIVE_WORKFLOWS]: undefined,

  // Technical Features
  [API_ACCESS]: true,
  [PYTHON_SDK]: false,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: true,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,
};

export const BUSINESS_SEAT_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: true,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: undefined,
  [EXTERNAL_USERS]: true,

  // Data
  [DATABASE_RECORDS]: undefined,
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: true,
  [FILE_STORAGE]: 20,
  [DATA_ENVIRONMENTS]: false,

  // User Access
  [USER_ROLES]: undefined,
  [CONDITIONAL_USER_LIST_ROLES]: true,
  [FIELD_LEVEL_PERMISSIONS]: true,
  [CUSTOM_VISIBILITY_RULES]: true,
  [CONDITIONAL_FIELD_VISIBILITY]: true,

  // Branding
  [CUSTOM_DOMAIN]: true,
  [WHITE_LABEL]: true,
  [SMTP]: false,

  // Other Features
  [FIELD_VALIDATION_RULES]: true,
  [PREMIUM_LAYOUTS]: true,

  // Workflows
  [WORKFLOW_RUNS]: 10000,
  [ACTIVE_WORKFLOWS]: undefined,

  // Technical Features
  [API_ACCESS]: true,
  [PYTHON_SDK]: true,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: true,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,
};

export const SEAT_BASED_PLAN_CONFIG: PlanConfigs = withFreePlan(
  withCustomPlans({
    [PRO]: PRO_SEAT_PLAN,
    [BUSINESS]: BUSINESS_SEAT_PLAN,
  }),
);

export type CustomPlanConfig = {
  [T in Feature]?: FeatureValue;
};
