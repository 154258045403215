import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import shortId from 'shortid';
import { useUpdateProperty } from '@noloco/ui/src/utils/hooks/projectHooks';
import { CHART, COLLECTION } from '../../constants/elements';
import { Element, ElementPath, ViewTab } from '../../models/Element';
import { Project } from '../../models/Project';
import {
  setSelectedElement,
  setSelectedSectionPath,
} from '../../reducers/elements';
import { getDefaultPropsForSectionType } from '../sections';

const useAddSection = (
  project: Project,
  sections: Element[],
  elementPath: ElementPath,
  sectionPath: ElementPath,
  selectedTab: ViewTab | null = null,
) => {
  const dispatch = useDispatch();
  const [updateProperty] = useUpdateProperty(elementPath, project);

  const addSection = useCallback(
    (element: string): Element => {
      const section = {
        type: element === CHART ? COLLECTION : element,
        id: shortId.generate(),
        tab: selectedTab ? (selectedTab as any).id : null,
        props: getDefaultPropsForSectionType(element),
      };
      updateProperty(sectionPath, [...sections, section]);

      dispatch(setSelectedElement(elementPath));
      dispatch(setSelectedSectionPath([sections.length]));

      return section;
    },
    [updateProperty, sectionPath, sections, selectedTab, elementPath, dispatch],
  );

  return [addSection];
};

export default useAddSection;
