import * as aggeregationTypes from '../constants/aggregationTypes';
import {
  ChartAggregation,
  MAXIMUM,
  MINIMUM,
} from '../constants/chartAggregations';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  DataFieldType,
  INTEGER,
  TEXT,
} from '../constants/dataTypes';
import DataTypes, { DataType } from '../models/DataTypes';
import { Rollup } from '../models/Rollup';
import { isMultiField } from './relationships';

const getAggregationFieldTypeFromFieldType = (
  fieldType: DataFieldType,
): DataFieldType => {
  switch (fieldType) {
    case DATE:
      return DATE;
    case DURATION:
      return DURATION;
    case TEXT:
    case DECIMAL:
    case INTEGER:
    default:
      return DECIMAL;
  }
};

export const getAggregationFieldType = (
  rollup: Rollup,
  dataType: DataType,
  dataTypes: DataTypes,
) => {
  if (!dataType) {
    return DECIMAL;
  }

  const sourceField = dataType.fields.getByName(rollup.relatedField);
  if (!sourceField) {
    return DECIMAL;
  }

  if (rollup.aggregation === aggeregationTypes.COUNT) {
    return INTEGER;
  }

  const sourceDataType = dataTypes.getByName(sourceField.type);

  if (!sourceDataType) {
    return DECIMAL;
  }

  const rollupField = sourceDataType.fields.getByName(rollup.field);

  if (rollup.aggregation === aggeregationTypes.CONCATENATE) {
    return TEXT;
  }

  if (
    [aggeregationTypes.OR, aggeregationTypes.AND].includes(rollup.aggregation)
  ) {
    return BOOLEAN;
  }

  if (sourceField && isMultiField(sourceField)) {
    if (rollupField) {
      return getAggregationFieldTypeFromFieldType(
        rollupField.type as DataFieldType,
      );
    }
  }

  return DECIMAL;
};

export const validAggregationsForFieldType = (
  fieldType: DataFieldType,
): aggeregationTypes.Aggregation[] => {
  switch (fieldType) {
    case DECIMAL:
    case INTEGER:
    case TEXT:
      return [
        aggeregationTypes.SUM,
        aggeregationTypes.COUNT,
        aggeregationTypes.MAX,
        aggeregationTypes.MIN,
        aggeregationTypes.AVERAGE,
        aggeregationTypes.CONCATENATE,
      ];
    case DATE:
      return [
        aggeregationTypes.COUNT,
        aggeregationTypes.MAX,
        aggeregationTypes.MIN,
      ];
    case DURATION:
      return [
        aggeregationTypes.SUM,
        aggeregationTypes.COUNT,
        aggeregationTypes.MAX,
        aggeregationTypes.MIN,
        aggeregationTypes.AVERAGE,
      ];
    case BOOLEAN:
      return [aggeregationTypes.OR, aggeregationTypes.AND];
    default:
      return [];
  }
};

export const aggregateData = (
  data: any[],
  aggregation: aggeregationTypes.Aggregation | ChartAggregation,
): number | null => {
  if (data.length === 0) {
    return aggregation === aggeregationTypes.COUNT ? 0 : null;
  }

  switch (aggregation) {
    case aggeregationTypes.COUNT:
      return data.length;
    case aggeregationTypes.AVERAGE:
      return (aggregateData(data, aggeregationTypes.SUM) ?? 0) / data.length;
    case MINIMUM:
    case aggeregationTypes.MIN:
      return data.reduce((min, value) => Math.min(min, value), data[0]);
    case MAXIMUM:
    case aggeregationTypes.MAX:
      return data.reduce((max, value) => Math.max(max, value), data[0]);
    default:
    case aggeregationTypes.SUM:
      return data.reduce((sum, value) => sum + value, 0);
  }
};
