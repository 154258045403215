const en = {
  websiteExamples: {
    collectionLayout: {
      title: 'Choose the layout that works best for your data',
      subtitle:
        'Noloco supports all the following collection layout options out of the box. You just have to choose which works best for your data',
    },
    collectionField: {
      title: 'Customize your layouts as much as you need',
      subtitle:
        'Choose which fields are in your view, change the label, the order, the way the field is displayed and much more, with ease.',
    },
    forms: {
      title: 'Ready-made forms to collect the data you need',
      subtitle:
        "For each of your data tables, Noloco automatically creates a form to add new records. Configure your form's fields and add powerful logic in minutes.",
    },
    recordView: {
      title: 'Customize each record view',
      subtitle:
        'Drill down into individual rows and customize a page that gives your team the most useful information at their fingertips',
    },
    actionButton: {
      title: 'Complete tasks quicker with action buttons',
      subtitle:
        'Add action buttons to record pages and collection views to reduce your team’s manual workload. Create, update or delete a record, navigate to a URL or page, or even run an on-demand workflow. You decide',
    },
    workflows: {
      title: 'Save your team hours with workflows',
      subtitle:
        'Use workflows to trigger the sending of automated emails or webhooks to integrate with third party services like Zapier or Make. Add conditional logic so that workflows only run when certain conditions are met - like when a field gets updated to a particular value.',
      trigger: {
        label: 'Trigger',
        type: 'When a project is **Created**',
      },
      email: {
        label: 'Send an email',
        description: 'Email the client that the project has started',
      },
      update: {
        label: 'Update a record',
        description: 'Update the project to be in progress',
      },
      webhook: {
        label: 'Trigger a webhook',
        description: 'Run project kickoff sequence in Zapier',
      },
    },
  },
};

export default en;
