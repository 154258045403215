import { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { UPDATE } from '../../constants/actionTypes';
import { getMutationQueryString } from '../../queries/project';
import { getFieldFromDependency } from '../fields';
import { getFieldKey } from '../fields';
import {
  isMultiRelationship,
  isReverseMultiRelationship,
} from '../relationships';

export const useUpdateRelatedRecordCollection = (
  dataList: any,
  project: any,
  rootDataType: any,
  dataType: any,
) => {
  const dataListField = useMemo(
    () =>
      getFieldFromDependency(
        get(dataList, ['filter', 'path']).split('.'),
        rootDataType,
        project.dataTypes,
      ),
    [dataList, rootDataType, project.dataTypes],
  );

  const isReverseMulti = useMemo(() => {
    // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
    if (dataListField.relatedField) {
      // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
      return isMultiRelationship(dataListField.relatedField.relationship);
    } else {
      // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
      return isReverseMultiRelationship(dataListField.relationship);
    }
    // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
  }, [dataListField.relatedField, dataListField.relationship]);

  const dataTypeField = useMemo(() => {
    // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
    if (dataListField.relatedField) {
      // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
      return dataListField.relatedField;
    }

    return dataType.fields.find(
      // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
      ({ relatedField }: any) => get(relatedField, ['id']) === dataListField.id,
    );
    // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
  }, [dataListField.id, dataType.fields, dataListField.relatedField]);
  const dataTypeFieldKey = useMemo(() => getFieldKey(dataTypeField), [
    dataTypeField,
  ]);

  const updateMutationQueryString = useMemo(
    () =>
      gql`
        ${getMutationQueryString(UPDATE, dataType.apiName, [dataTypeField])}
      `,
    [dataType.apiName, dataTypeField],
  );
  const mutationOptions = useMemo(
    () => ({
      context: {
        authQuery: null,
        projectQuery: true,
        projectName: project.name,
      },
    }),
    [project.name],
  );
  const [update, { loading }] = useMutation(
    updateMutationQueryString,
    mutationOptions,
  );

  return {
    dataTypeField,
    fieldApiArgName: dataTypeFieldKey,
    isMultiRelationship: isReverseMulti,
    relationalDataField: dataListField,
    update,
    updateLoading: loading,
  };
};
