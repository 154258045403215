import {
  DataFieldType,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
} from '../constants/dataTypes';
import { DataField } from '../models/DataTypeFields';

export const hasNullOption = (field: DataField): boolean =>
  field.type === SINGLE_OPTION && !field.required;

export const isOptionType = (fieldType: DataFieldType | string): boolean =>
  fieldType === SINGLE_OPTION || fieldType === MULTIPLE_OPTION;

export const isOptionValid = (option: any) => option && option.display;
