import validator from 'validator';
import passwordValidationTypes, {
  CASE,
  LENGTH,
  NUMBERS,
  SYMBOL,
} from '../components/auth/passwordValidationTypes';

const emailRegex = /^([a-zA-Z0-9_\-.+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,}|[0-9]{2,})(]?)$/;

export const validationChecks = {
  [LENGTH]: (str: string) => str.length >= 8,
  [NUMBERS]: (str: string) => /\d/.test(str),
  [CASE]: (str: string) => /[A-Z]/.test(str) && /[a-z]/.test(str),
  [SYMBOL]: (str: string) => /[^a-zA-Z0-9]/.test(str),
};

export const failedPasswordChecks = (password: string) =>
  passwordValidationTypes.filter(
    (checkName) => !validationChecks[checkName](password),
  );

export const isEmailValid = (email?: string) =>
  email && email.length >= 6 && emailRegex.test(email);

export const getValidationBorderColor = (theme: {
  brandColors: Record<string, string>;
}) => theme.brandColors['secondary'];

export const validationBorder = (
  validationError: string,
  theme: { brandColors: Record<string, string> },
) => ({
  [`ring ring-${getValidationBorderColor(theme)}`]: validationError,
});

export const isDomainValid = (domain: string) => validator.isFQDN(domain);
