import React, { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Loader } from '@noloco/components';
import { SECONDARY } from '@noloco/components/src/constants/variants';
import FeatureLockedButton from '@noloco/ui/src/components/FeatureLockedButton';
import { useFeatureUsage } from '@noloco/ui/src/utils/hooks/useFeatureUsage';
import { CREATE } from '../../../constants/actionTypes';
import { DATABASE_RECORDS } from '../../../constants/features';
import { getText } from '../../../utils/lang';
import Icon from '../../Icon';

const ActionButtonButton = ({
  actionButton,
  buttonType,
  disabled,
  editorMode,
  index,
  isLoading,
  onClick,
  size,
  ...rest
}: any) => {
  const currentNumber = useFeatureUsage(DATABASE_RECORDS);
  const buttonCreatesRecords = useMemo(
    () =>
      !!get(actionButton, 'actions', []).find(
        ({ type }: any) => type === CREATE,
      ),
    [actionButton],
  );

  return (
    <FeatureLockedButton
      {...rest}
      checkEnabled={buttonCreatesRecords}
      className={classNames(
        'flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap action-button',
        `action-button-${actionButton.id}`,
      )}
      compactBadge={true}
      data-testid="action-button"
      currentNumber={currentNumber}
      disabled={editorMode || disabled}
      feature={DATABASE_RECORDS}
      key={actionButton.id}
      type={buttonType}
      variant={actionButton.appearance || SECONDARY}
      onClick={onClick}
      size={size}
      tooltipPlacement="bottom"
    >
      {isLoading && <Loader size="xs" />}
      {!isLoading && (
        <>
          {actionButton.icon && actionButton.icon.name && (
            <Icon icon={actionButton.icon} className="opacity-75 w-5 h-5" />
          )}
          <span>
            {actionButton.buttonText ||
              getText(
                { index: index + 1 },
                'elements.VIEW.actionButtons.defaultButtonText',
              )}
          </span>
        </>
      )}
    </FeatureLockedButton>
  );
};

export default ActionButtonButton;
