import { useMemo } from 'react';
import gql from 'graphql-tag';
import camelCase from 'lodash/camelCase';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import initial from 'lodash/initial';
import { useSelector } from 'react-redux';
import { LIST, PAGE, VIEW } from '../../constants/elements';
import { getPreviewableFieldsQueryObject } from '../../queries/data';
import {
  BLANK_COLLECTION_QUERY_STRING,
  getCollectionDataQueryString,
  getPageQueryString,
  getQueryVariableArgs,
} from '../../queries/project';
import { queriesSelector } from '../../selectors/queriesSelectors';
import {
  formatFilterAndMergeCustomFilters,
  formatWhereCondition,
  getDataTypeByName,
} from '../data';
import { transformQueryArgs } from '../queries';
import useCacheQuery from './useCacheQuery';

const getQueryString = (
  relatedDataType: any,
  additionalFields: any,
  filter: any,
  queries: any,
) => {
  if (!relatedDataType) {
    return {
      queryString: gql`
        ${BLANK_COLLECTION_QUERY_STRING}
      `,
      valuePath: '',
    };
  }
  const queryObject = getPreviewableFieldsQueryObject(relatedDataType.fields);
  const { __args, __variables, ...restAdditionalFields } = additionalFields;

  if (!filter || !filter.id || (filter.id && !queries[filter.id])) {
    const queryString = getCollectionDataQueryString(relatedDataType.apiName, {
      __args,
      __variables,
      edges: {
        node: {
          ...queryObject,
          ...restAdditionalFields,
        },
      },
      totalCount: true,
      pageInfo: {
        hasNextPage: true,
        endCursor: true,
      },
    });

    return {
      queryString: gql`
        ${queryString}
      `,
      valuePath: `${camelCase(relatedDataType.apiName)}Collection`,
    };
  }

  // The filter should not start with `edges.node.` because it will be used to get
  // A sub-collection of single record, thus can't start with edges.node
  const valuePath = filter.path.replace(/^edges\.node\./, '');
  const {
    dataType: parentDataType,
    dataProperty,
    variables = {},
    type,
    valuePath: parentValuePath = '',
  } = queries[filter.id];

  const parentPath = (parentValuePath + valuePath).split('.');

  const { where: parentWhere = [] } = variables;

  let queryObjectWithNestedIds = set(
    [...parentPath, 'edges', 'node'],
    queryObject,
    {},
  );

  // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type '{}'.
  queryObjectWithNestedIds = initial(parentPath).reduce(
    (updatedObject, __, pathSliceIndex) =>
      set(
        parentPath.slice(0, pathSliceIndex + 1),
        {
          id: true,
          uuid: true,
          ...get(updatedObject, parentPath.slice(0, pathSliceIndex + 1)),
        },
        // @ts-expect-error TS(2769): No overload matches this call.
        updatedObject,
      ),
    queryObjectWithNestedIds,
  );

  if (__args && Object.keys(__args).length > 0) {
    queryObjectWithNestedIds = set(
      [...parentPath, '__args'],
      transformQueryArgs(__args),
      queryObjectWithNestedIds,
    );
  }

  if (__variables && Object.keys(__variables).length > 0) {
    queryObjectWithNestedIds = set(
      ['__variables'],
      __variables,
      queryObjectWithNestedIds,
    );

    queryObjectWithNestedIds = set(
      [...parentPath, '__args'],
      {
        ...get(queryObjectWithNestedIds, [...parentPath, '__args'], {}),
        ...getQueryVariableArgs(__variables),
      },
      queryObjectWithNestedIds,
    );
  }

  if (!parentValuePath && parentPath.length === 1) {
    queryObjectWithNestedIds = {
      id: true,
      uuid: true,
      ...queryObjectWithNestedIds,
    };
  }

  let gqlQueryString;
  if (type === PAGE || type === VIEW) {
    gqlQueryString = getPageQueryString(
      parentDataType,
      variables[dataProperty]
        ? { [dataProperty]: variables[dataProperty] }
        : undefined,
      queryObjectWithNestedIds,
    );
  } else if (type === LIST) {
    gqlQueryString = getCollectionDataQueryString(
      relatedDataType.name,
      queryObjectWithNestedIds,
      { where: parentWhere.map(formatWhereCondition) },
    );
  }

  return {
    queryString: gql`
      ${gqlQueryString}
    `,
    valuePath: `${parentDataType}.${parentPath.join('.')}`,
  };
};

const useDataTypeQuery = (
  typeApiNameOrName: any,
  dataTypes: any,
  projectName: any,
  additionalFields = {},
  queryOptions = {},
  filter: any,
) => {
  const queries = useSelector(queriesSelector);
  const dataType = useMemo(
    () => getDataTypeByName(dataTypes, typeApiNameOrName),
    [dataTypes, typeApiNameOrName],
  );

  const additionalFieldsWithFilters = useMemo(() => {
    if (
      !dataType ||
      !(additionalFields as any).__args ||
      !(additionalFields as any).__args.where
    ) {
      return additionalFields;
    }

    const whereFilters = formatFilterAndMergeCustomFilters(
      (additionalFields as any).__args.where,
      dataType,
    );

    return set('__args.where', whereFilters, additionalFields);
  }, [additionalFields, dataType]);

  const { valuePath, queryString } = useMemo(
    () =>
      getQueryString(dataType, additionalFieldsWithFilters, filter, queries),
    [additionalFieldsWithFilters, filter, queries, dataType],
  );

  const { loading, data, ...restResult } = useCacheQuery(queryString, {
    errorPolicy: 'all',
    ...queryOptions,
    context: {
      projectQuery: true,
      projectName,
      ...(queryOptions as any).context,
    },
    // Only merge this in if there's no data type
    ...(!dataType ? { skip: true } : {}),
  });

  if (restResult.error) {
    console.log('useDataTypeQuery Error', restResult.error);
  }

  const pageInfo = get(data, `${valuePath}.pageInfo`, {});
  const totalCount = get(data, `${valuePath}.totalCount`, null);
  const edges = get(data, `${valuePath}.edges`, []);
  const nodes = edges.map((edge: any) => edge.node);

  return {
    ...restResult,
    pageInfo,
    totalCount,
    loading,
    edges,
    data,
    dataType,
    nodes,
    valuePath,
    queryString,
  };
};

export default useDataTypeQuery;
