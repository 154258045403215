import React, { forwardRef, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { LG, MD, SM, ShirtSize, XL } from '../../constants/tShirtSizes';

type Props = {
  children?: string | React.ReactNode;
  open?: boolean;
  size?: ShirtSize;
};

const BasePanel = forwardRef<any, Props>(
  // @ts-expect-error TS(2345): Argument of type '({ children, className, open, si... Remove this comment to see the full error message
  ({ children, className, open, size, ...rest }, ref) => {
    const [localOpen, setLocalOpen] = useState(open);
    useEffect(() => {
      if (open !== localOpen) {
        setLocalOpen(open);
      }
    }, [localOpen, open]);

    const root = document.querySelector('#root');

    return (
      localOpen &&
      ReactDOM.createPortal(
        <Transition
          show={true}
          enter="ease-out duration-300"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in duration-300"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
          role="dialog"
          aria-modal="true"
          className={classNames(
            'fixed inset-y-0 right-0 z-50 transform transition-all overflow-y-auto w-full',
            {
              'max-w-sm': size === SM,
              'max-w-md': size === MD,
              'max-w-lg': size === LG,
              'max-w-xl': size === XL,
            },
            className,
          )}
          ref={ref}
          {...rest}
        >
          {children}
        </Transition>,
        // @ts-expect-error TS(2345): Argument of type 'Element | null' is not assignabl... Remove this comment to see the full error message
        root,
      )
    );
  },
);

BasePanel.defaultProps = {
  open: false,
  size: MD,
};

export default BasePanel;
