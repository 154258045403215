import React, { forwardRef } from 'react';
import classNames from 'classnames';

const getIframeProps = (source: any) => {
  if (!source) {
    return {
      src: '',
    };
  }

  if (source.includes('<iframe')) {
    const propsMatch = source.match(/(([\S]+)="([^"]+)")/g);

    if (propsMatch) {
      return propsMatch.slice(1).reduce((propAcc: any, propString: any) => {
        const [key, valueString] = propString.split(/=(.*)/s);
        const value = valueString.replace(/"/g, '');
        return {
          ...propAcc,
          [key]: value,
        };
      }, {});
    }
  }

  return { src: source };
};

type IframeProps = {
  className?: string;
  source?: string;
  title?: string;
};

const Iframe = forwardRef<any, IframeProps>((
  // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Ifra... Remove this comment to see the full error message
  { className, isSelected, editorMode, fullScreen, source, title, onClick },
  ref,
) => (
  <div
    className={classNames(className, {
      relative: !fullScreen,
      'absolute top-0 bottom-0 left-0 right-0 bg-gray-50': fullScreen,
      'w-full max-w-full': !fullScreen,
    })}
    ref={ref}
  >
    {!!source && (
      <iframe
        frameBorder="0"
        {...getIframeProps(source)}
        style={{}}
        height="100%"
        title={title}
        width="100%"
      />
    )}
    {!isSelected && editorMode && (
      <div
        className="w-full h-full absolute top-0 left-0 right-0 bottom-0"
        onClick={onClick}
      />
    )}
  </div>
));

Iframe.defaultProps = {
  className: '',
  source: 'https://example.com/',
};

export default Iframe;
