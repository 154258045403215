import { DataSourceType, DataSourceTypeType } from '../constants/dataSources';
import DataTypeFields, { DataField } from './DataTypeFields';
import DataTypePermissions from './DataTypePermissions';
import ProjectArrayTypeMap, { DataTypeIdentifier } from './ProjectArrayTypeMap';
import { Rollup } from './Rollup';
import { Workflow } from './Workflow';

export interface BaseDataSource {
  id: number | null;
  display: string | null;
  type: DataSourceType;
  collectionType?: DataSourceTypeType;
  connection?: any;
}

export interface DataSource extends BaseDataSource {
  id: number;
  display: string;
}

export interface BaseDataType extends DataTypeIdentifier {
  fields: any;
  permissions?: DataTypePermissions;
}

export interface DataType extends BaseDataType {
  display: string;
  fields: DataTypeFields;
  permissionsEnabled: boolean;
  primaryField: Pick<DataField, 'id' | 'name' | 'apiName'> | null;
  readOnly?: boolean;
  internal: boolean;
  rollups: Rollup[];
  permissions: DataTypePermissions;
  source: BaseDataSource;
  workflows: Workflow[];
}

export class DataTypeArray<T extends BaseDataType> extends ProjectArrayTypeMap<
  T
> {
  constructor(dataTypes?: T[] | number | DataTypeArray<T>) {
    if (dataTypes instanceof DataTypeArray) {
      //Will copy maps for dataTypes in constructor & reuse current instances of DataTypeFields
      super(dataTypes);
    } else if (dataTypes !== undefined && typeof dataTypes !== 'number') {
      const dataTypesWithFields = dataTypes.map(DataTypeArray.formatDataType);
      super(dataTypesWithFields);
    } else {
      super(dataTypes);
    }
  }

  static formatDataType<D extends BaseDataType>(dataType: D) {
    return {
      ...dataType,
      fields: new DataTypeFields(dataType.fields),
      permissions: new DataTypePermissions(dataType.permissions || []),
    };
  }

  async setup() {
    await this.asyncSetup();
    await Promise.all(this.map((dataType) => dataType.fields.asyncSetup()));
    await Promise.all(
      this.map((dataType) => {
        if (dataType.permissions) {
          return dataType.permissions.asyncSetup();
        }

        return Promise.resolve();
      }),
    );

    return this;
  }
  setupSync() {
    this.forEach((dataType) => dataType.fields.syncSetup());

    this.forEach((dataType) => {
      if (dataType.permissions) {
        return dataType.permissions.syncSetup();
      }
    });

    super.syncSetup();

    return this;
  }
}

class DataTypes extends DataTypeArray<DataType> {}

export default DataTypes;
