import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { DateTime, DateTimeUnit } from 'luxon';
import Timeline, {
  CustomMarker,
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
} from 'react-calendar-timeline/lib';
import 'react-calendar-timeline/lib/Timeline.css';
import { Xwrapper, useXarrow } from 'react-xarrows';
import { Views } from '@noloco/react-big-calendar';
import { GANTT, ROWS } from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import {
  getFieldFromDependency,
  getFieldReverseName,
} from '../../../utils/fields';
import { getDependencies } from '../../../utils/ganttDependencies';
import useRecordRowLink from '../../../utils/hooks/useRecordRowLink';
import useRouter from '../../../utils/hooks/useRouter';
import { Dependent, Event, ID } from './CollectionEvents';
import CollectionGantt from './CollectionGantt';
import CollectionTimelineItem from './CollectionTimelineItem';
import CalendarToolbar from './calendar/CalendarToolbar';

type ViewConfig = {
  [key: string]: {
    unit: DateTimeUnit;
    minimumZoom: number;
    maximumZoom: number;
    dragSnap: number;
    buffer: number;
  };
};

type LabelFormats = {
  [key: string]: string;
};

type TimelineProps = {
  visibleTimeStart: number;
  visibleTimeEnd: number;
  minZoom: number;
  maxZoom: number;
  dragSnap: number;
  buffer: number;
};

type TimelineGroup = {
  id: number;
  name: string;
  display: string;
  order: number;
  color: string;
};

const FORWARD = 'forward';
const BACKWARD = 'backward';
const LINE_HEIGHT = 50;
const SIDEBAR_WIDTH = 150;
type Direction = typeof FORWARD | typeof BACKWARD;

const BOTH = 'both';
const DAY = 24 * 60 * 60 * 1000;
const MIN_ZOOM = 7 * DAY;
const MAX_ZOOM = 30 * DAY;
const DRAG_SNAP = DAY / 2;
const BUFFER = 3;

const keys = {
  groupIdKey: 'id',
  groupTitleKey: 'display',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start',
  itemTimeEndKey: 'end',
  groupLabelKey: 'title',
};

const defaultGroup = [{ id: 0 }];
const viewConfig: ViewConfig = {
  [Views.MONTH]: {
    unit: 'month',
    minimumZoom: 7 * DAY,
    maximumZoom: 30 * DAY,
    dragSnap: DAY / 2,
    buffer: 3,
  },
  [Views.WEEK]: {
    unit: 'week',
    minimumZoom: DAY,
    maximumZoom: 7 * DAY,
    dragSnap: 60 * 60 * 1000,
    buffer: 4,
  },
  [Views.WORK_WEEK]: {
    unit: 'week',
    minimumZoom: DAY,
    maximumZoom: 5 * DAY,
    dragSnap: 60 * 60 * 1000,
    buffer: 4,
  },
  [Views.DAY]: {
    unit: 'day',
    minimumZoom: 6 * 60 * 60 * 1000,
    maximumZoom: DAY,
    dragSnap: 15 * 60 * 1000,
    buffer: 15,
  },
};

const labelFormats: LabelFormats = {
  [Views.MONTH]: 'D',
  [Views.WEEK]: 'D ddd',
  [Views.WORK_WEEK]: 'D ddd',
  [Views.DAY]: 'HH:mm',
};

const formatMap = (start: DateTime, end?: DateTime) => ({
  [Views.MONTH]: start.toLocaleString({
    month: 'long',
    year: 'numeric',
  }),
  [Views.WEEK]: `${start.toLocaleString({
    month: 'long',
    day: '2-digit',
  })} - ${end?.toLocaleString({ month: 'long', day: '2-digit' })}`,
  [Views.WORK_WEEK]: `${start.toLocaleString({
    month: 'long',
    day: '2-digit',
  })} - ${end?.toLocaleString({ month: 'long', day: '2-digit' })}`,
  [Views.DAY]: start.toLocaleString({
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  }),
});

const GroupRenderer = ({ group }: any) => (
  <div className="pl-2 text-gray-500 truncate">{group.display}</div>
);

const CollectionTimeline = ({
  canUpdateEventDates,
  currentView,
  date,
  dateEndField,
  dateStartField,
  defaultViews,
  draftEvents,
  enableDragAndDropEdit,
  dataType,
  ganttDependency,
  groupByField,
  isDarkModeEnabled,
  layout,
  loading,
  localizer,
  project,
  qsSuffix,
  Row,
  rowLink,
  setView,
  theme,
  updateEvent,
  updateEvents,
}: any) => {
  const { pushQueryParams } = useRouter();
  const updateXarrow = useXarrow();
  const defaultDate = useMemo(
    () => (date ? DateTime.fromISO(date) : DateTime.now()),
    [date],
  );
  const unit = useMemo(() => (currentView === Views.DAY ? 'hour' : 'day'), [
    currentView,
  ]);
  const labelFormat = useMemo(() => labelFormats[currentView], [currentView]);
  const ganttDependencyField = useMemo(
    () =>
      ganttDependency &&
      getFieldFromDependency(
        ganttDependency.path.split('.'),
        dataType,
        project.dataTypes,
      ),
    [dataType, ganttDependency, project.dataTypes],
  );
  const isGanttViewEnabled = useMemo(
    () => layout === GANTT && ganttDependencyField,
    [layout, ganttDependencyField],
  );
  const [showArrows, setShowArrows] = useState<boolean>(isGanttViewEnabled);
  const [timelineProps, setTimelineProps] = useState<TimelineProps>({
    visibleTimeStart: defaultDate.startOf('month').valueOf(),
    visibleTimeEnd: defaultDate.plus({ months: 1 }).valueOf(),
    minZoom: MIN_ZOOM,
    maxZoom: MAX_ZOOM,
    dragSnap: DRAG_SNAP,
    buffer: BUFFER,
  });
  const [itemSelected, setItemSelected] = useState<{ rootPathname?: string }>();
  const recordRowLink = useRecordRowLink(
    rowLink,
    project,
    ROWS,
    itemSelected && itemSelected.rootPathname,
    get(itemSelected, 'record.uuid'),
  ) as string;

  const label = useMemo(() => {
    const start = DateTime.fromMillis(timelineProps.visibleTimeStart);
    const end = DateTime.fromMillis(timelineProps.visibleTimeEnd);

    return formatMap(start, end)[currentView];
  }, [
    currentView,
    timelineProps.visibleTimeStart,
    timelineProps.visibleTimeEnd,
  ]);

  const setTimelinePropsState = useCallback(
    (currentView: string, props: TimelineProps) => {
      const { unit, minimumZoom, maximumZoom, dragSnap, buffer } = viewConfig[
        currentView
      ];

      const startTime = DateTime.fromMillis(props.visibleTimeStart).startOf(
        unit,
      );

      const endTime = startTime.plus(
        currentView === Views.WORK_WEEK ? { day: 5 } : { [unit]: 1 },
      );

      return {
        minZoom: minimumZoom,
        maxZoom: maximumZoom,
        visibleTimeStart: startTime.valueOf(),
        visibleTimeEnd: endTime.valueOf(),
        dragSnap,
        buffer,
      };
    },
    [],
  );

  useEffect(
    () =>
      setTimelineProps((props) => setTimelinePropsState(currentView, props)),
    [currentView, setTimelinePropsState],
  );

  const overlappingEvents = useMemo(() => {
    const events = new Set();

    if (!groupByField) {
      for (let i = 0; i < draftEvents.length; i++) {
        for (let j = i + 1; j < draftEvents.length; j++) {
          const eventA = draftEvents[i];
          const eventB = draftEvents[j];

          if (
            (eventA.start <= eventB.end && eventA.start >= eventB.start) ||
            (eventB.start <= eventA.end && eventB.start >= eventA.start)
          ) {
            events.add([eventA.id, eventB.id]);
          }
        }
      }
    }

    const uniqueOverlappingEvents = new Set<string>(
      ([...events] as string[]).flat(),
    );

    return [...uniqueOverlappingEvents];
  }, [groupByField, draftEvents]);

  const groups = useMemo(() => {
    if (groupByField) {
      return groupByField.options;
    }

    if (overlappingEvents.length > 0) {
      return overlappingEvents
        .map((_: any, index: number) => ({
          id: index,
        }))
        .concat(
          overlappingEvents.length === 1
            ? [{ id: overlappingEvents.length }]
            : [],
        );
    }

    return defaultGroup;
  }, [groupByField, overlappingEvents]);

  const ganttDependencyReverseFieldName = useMemo(
    () =>
      ganttDependencyField &&
      (getFieldReverseName(ganttDependencyField, dataType) as string),
    [ganttDependencyField, dataType],
  );

  const events = useMemo(
    () =>
      draftEvents.map((draftEvent: Event) => {
        let groupId = 0;
        if (groupByField) {
          groupId = groupByField.options.find(
            (group: TimelineGroup) =>
              group.name === draftEvent.record[groupByField.name],
          )?.id;
        } else if (overlappingEvents.length > 0) {
          const overlappingEventIndex = overlappingEvents.findIndex(
            (e: any) => e === draftEvent.id,
          );

          if (overlappingEventIndex >= 0) {
            groupId = overlappingEventIndex;
          }
        }

        let predecessors: Dependent[] = [];
        let successors: Dependent[] = [];

        if (isGanttViewEnabled && ganttDependencyField) {
          const dependencies = getDependencies(
            draftEvent,
            ganttDependencyField.relationship,
            ganttDependencyField.name,
            ganttDependencyReverseFieldName,
            dateStartField.name,
            dateEndField.name,
          );
          predecessors = get(dependencies, 'predecessors', []);
          successors = get(dependencies, 'successors', []);
        }

        return {
          ...draftEvent,
          group: groupId,
          canResize: canUpdateEventDates && draftEvent.hasEnd ? BOTH : false,
          ...(isGanttViewEnabled
            ? {
                predecessors,
                successors,
              }
            : {}),
        };
      }),
    [
      overlappingEvents,
      draftEvents,
      groupByField,
      isGanttViewEnabled,
      ganttDependencyField,
      ganttDependencyReverseFieldName,
      dateStartField,
      dateEndField,
      canUpdateEventDates,
    ],
  );

  const handleItemResize = useCallback(
    (itemId: ID, time: number, edge: 'left' | 'right') => {
      const event = events.find((event: Event) => event.id === itemId);
      const newTime = new Date(time);
      let { start, end } = event;

      start = edge === 'left' ? newTime : event.start;
      end = edge === 'left' ? event.end : newTime;

      setShowArrows(true);
      return updateEvent(event, start, end);
    },
    [events, updateEvent],
  );

  const getNewStartAndEndTime = useCallback(
    (
      direction: Direction,
      startTime: number,
      endTime: number,
      interval: number,
    ) => {
      let start: number = startTime;
      let end: number = startTime;

      if (direction === FORWARD) {
        start = DateTime.fromMillis(endTime).toMillis();
        end = DateTime.fromMillis(start + interval).toMillis();
      } else {
        end = DateTime.fromMillis(startTime).toMillis();
        start = DateTime.fromMillis(end - interval).toMillis();
      }

      return {
        newStartTime: start,
        newEndTime: end,
      };
    },
    [],
  );

  const manageInvalidGanttDependencies = useCallback(
    (predecessors, successors, startTime, interval, eventStartTime) => {
      const endTime = startTime + interval;
      let invalidGanttDependencies: Event[] = [];

      const eventsBecomingInvalidDueToPredecessor = predecessors.filter(
        (predecessor: Event) => startTime < predecessor?.end,
      );
      const eventsBecomingInvalidDueToSuccessor = successors.filter(
        (successor: Event) => startTime + interval > successor?.start,
      );

      const eventsBecomingInvalid = [
        ...eventsBecomingInvalidDueToPredecessor,
        ...eventsBecomingInvalidDueToSuccessor,
      ];

      if (eventsBecomingInvalid.length > 0) {
        const direction = startTime - eventStartTime > 0 ? FORWARD : BACKWARD;

        invalidGanttDependencies = eventsBecomingInvalid
          .map((ev: Event) => {
            const event = events.find((event: Event) => event.id === ev.id);
            const { predecessors = [], successors = [] } = event;
            const interval = event.end - event.start;
            const { newStartTime, newEndTime } = getNewStartAndEndTime(
              direction,
              startTime,
              endTime,
              interval,
            );

            return manageInvalidGanttDependencies(
              predecessors,
              successors,
              newStartTime,
              interval,
              event.start,
            ).concat({
              event,
              start: DateTime.fromMillis(newStartTime).toJSDate(),
              end: DateTime.fromMillis(newEndTime).toJSDate(),
            } as Event);
          })
          .reduce((acc, curr) => acc.concat(curr), []);
      }

      return invalidGanttDependencies;
    },
    [events, getNewStartAndEndTime],
  );

  const handleItemMove = useCallback(
    async (itemId: ID, startTime: number, group: number) => {
      const primary = events.find((event: Event) => event.id === itemId);
      const interval = primary.end - primary.start;
      const { predecessors = [], successors = [] } = primary;
      const newStart = DateTime.fromMillis(startTime).toJSDate();
      const newEnd = DateTime.fromMillis(startTime + interval).toJSDate();
      const fromGroup = groupByField && primary.record[groupByField.name];
      const toGroup = groups[group].name;
      let dependentsToUpdate: Event[] = [];

      if (
        isGanttViewEnabled &&
        (predecessors.length > 0 || successors.length > 0)
      ) {
        dependentsToUpdate = manageInvalidGanttDependencies(
          predecessors,
          successors,
          startTime,
          interval,
          primary.start,
        );
      }

      setShowArrows(true);
      return updateEvents([
        {
          event: primary,
          start: newStart,
          end: newEnd,
          groupBy:
            groupByField && fromGroup !== toGroup
              ? {
                  field: { [groupByField.name]: toGroup },
                  color: get(groups, [group, 'color'], null),
                }
              : null,
        },
        ...dependentsToUpdate,
      ]);
    },
    [
      events,
      groups,
      isGanttViewEnabled,
      manageInvalidGanttDependencies,
      updateEvents,
      groupByField,
    ],
  );

  const handleTimeChange = useCallback(
    (
      visibleTimeStart: number,
      visibleTimeEnd: number,
      updateScrollCanvas: (
        visibleTimeStart: number,
        visibleTimeEnd: number,
      ) => void,
    ) => {
      setTimelineProps((props) => ({
        ...props,
        visibleTimeStart,
        visibleTimeEnd,
      }));
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    },
    [setTimelineProps],
  );

  const handleItemSelect = useCallback(
    (itemId: ID) => {
      const event = events.find((event: Event) => event.id === itemId);
      setItemSelected(event);
    },
    [events],
  );

  const handleBoundsChange = useCallback(
    (canvasTimeStart: number, canvasTimeEnd: number) => {
      const date = (canvasTimeStart + canvasTimeEnd) / 2;
      updateXarrow();
      return pushQueryParams({
        [`_date${qsSuffix}`]: DateTime.fromMillis(date).toUTC().toISO(),
      });
    },
    [pushQueryParams, updateXarrow, qsSuffix],
  );

  const handleNavigationChange = useCallback(
    (action: string, toDate?: Date) => {
      const { visibleTimeStart, visibleTimeEnd } = timelineProps;
      const { unit, minimumZoom, maximumZoom, dragSnap, buffer } = viewConfig[
        currentView
      ];
      const endTimeValue =
        currentView === Views.WORK_WEEK ? { days: 5 } : { [unit]: 1 };

      let timeStart = DateTime.now()
        .plus({ milliseconds: 1000 })
        .startOf('month');
      let timeEnd = timeStart.endOf('month');

      if (toDate) {
        timeStart = DateTime.fromJSDate(toDate).startOf(unit);
        timeEnd = timeStart.plus(endTimeValue);
      } else {
        switch (action) {
          case 'NEXT':
            timeStart = DateTime.fromMillis(visibleTimeEnd)[
              currentView === Views.WORK_WEEK ? 'endOf' : 'startOf'
            ](unit);
            timeEnd = timeStart.plus(endTimeValue);
            break;

          case 'PREV':
            timeEnd = DateTime.fromMillis(visibleTimeStart).startOf(unit);
            timeStart = timeEnd.minus(endTimeValue);
            break;

          case 'TODAY':
          default:
            timeStart = DateTime.now()
              .plus({ milliseconds: 1000 })
              .startOf(unit);
            timeEnd = timeStart.plus(endTimeValue);
            break;
        }
      }

      setTimelineProps({
        visibleTimeStart: timeStart.valueOf(),
        visibleTimeEnd: timeEnd.valueOf(),
        minZoom: minimumZoom,
        maxZoom: maximumZoom,
        dragSnap,
        buffer,
      });

      updateXarrow();

      return pushQueryParams({
        [`_date${qsSuffix}`]: timeStart.toUTC().toISO(),
      });
    },
    [currentView, timelineProps, updateXarrow, pushQueryParams, qsSuffix],
  );

  const itemRenderer = useCallback(
    ({ item, itemContext, getItemProps, getResizeProps }) => (
      <CollectionTimelineItem
        item={item}
        itemContext={itemContext}
        getItemProps={getItemProps}
        getResizeProps={getResizeProps}
        primaryColor={theme.brandColorGroups.primary}
        isDarkModeEnabled={isDarkModeEnabled}
        recordRowLink={recordRowLink}
        Row={Row}
      />
    ),
    [theme.brandColorGroups.primary, recordRowLink, Row, isDarkModeEnabled],
  );

  const handleItemDrag = useCallback(() => setShowArrows(false), [
    setShowArrows,
  ]);

  const Component = useMemo(() => (isGanttViewEnabled ? Xwrapper : 'div'), [
    isGanttViewEnabled,
  ]);

  const todayMarkerHeight = useMemo(() => {
    if (!loading && events.length > 0) {
      return groups.length * LINE_HEIGHT;
    }
  }, [loading, groups, events]);

  return (
    <>
      <CalendarToolbar
        localizer={localizer}
        label={label}
        date={date ? new Date(date) : undefined}
        onNavigate={handleNavigationChange}
        onView={setView}
        view={currentView}
        views={defaultViews}
        loading={loading}
      />
      {events.length > 0 && todayMarkerHeight && (
        <Component className="w-full h-full flex flex-col flex-grow">
          {events.length > 0 && isGanttViewEnabled && (
            <CollectionGantt
              events={events}
              showArrows={showArrows}
              theme={theme}
            />
          )}
          <Timeline
            keys={keys}
            groups={groups}
            items={events}
            className="h-full w-full flex flex-col select-none"
            canMove={enableDragAndDropEdit && canUpdateEventDates}
            sidebarWidth={groupByField ? SIDEBAR_WIDTH : 0}
            lineHeight={LINE_HEIGHT}
            canChangeGroup={!!groupByField}
            stackItems={true}
            itemTouchSendsClick={true}
            useResizeHandle={true}
            itemRenderer={itemRenderer}
            groupRenderer={GroupRenderer}
            horizontalLineClassNamesForGroup={() =>
              isDarkModeEnabled ? ['rct-hl-dark'] : ['rct-hl-light']
            }
            onItemResize={handleItemResize}
            onItemMove={handleItemMove}
            onTimeChange={handleTimeChange}
            onItemSelect={handleItemSelect}
            onBoundsChange={handleBoundsChange}
            onItemDrag={handleItemDrag}
            {...timelineProps}
          >
            <TimelineHeaders
              className="sticky"
              calendarHeaderStyle={{ border: 0 }}
              style={{ border: 0 }}
            >
              <SidebarHeader>
                {({ getRootProps }: any) => (
                  <div
                    className={classNames(
                      `flex items-center justify-center text-${theme.textColors.body}`,
                      {
                        [`bg-${theme.surfaceColors.dark} ${darkModeColors.borders.two}`]: isDarkModeEnabled,
                        [`bg-${theme.surfaceColors.light}`]: !isDarkModeEnabled,
                        'border-2': groupByField,
                      },
                    )}
                    {...getRootProps()}
                  />
                )}
              </SidebarHeader>
              <DateHeader
                unit="month"
                labelFormat="MMMM, YYYY"
                intervalRenderer={({
                  getIntervalProps,
                  intervalContext,
                }: any) => (
                  <div
                    {...getIntervalProps()}
                    className={classNames(
                      `h-full flex items-center justify-center border-2 border-r-0 text-${theme.textColors.body}`,
                      isDarkModeEnabled
                        ? `bg-${theme.surfaceColors.dark} ${darkModeColors.borders.two}`
                        : `bg-${theme.surfaceColors.light}`,
                    )}
                  >
                    {intervalContext.intervalText}
                  </div>
                )}
              />
              <CustomMarker date={Date.now()}>
                {({ styles }: any) => (
                  <div
                    style={{
                      ...styles,
                      width: '2px',
                      background: '',
                      height: todayMarkerHeight,
                    }}
                    className={classNames(
                      `bg-${theme.brandColors.primary} opacity-50`,
                    )}
                  />
                )}
              </CustomMarker>
              <DateHeader
                unit={unit}
                labelFormat={labelFormat}
                intervalRenderer={({
                  getIntervalProps,
                  intervalContext,
                }: any) => (
                  <div
                    {...getIntervalProps()}
                    className={classNames(
                      `h-full pl-2 flex items-center border-b-2 text-${theme.textColors.body}`,
                      isDarkModeEnabled
                        ? `bg-${theme.surfaceColors.dark} ${darkModeColors.borders.two}`
                        : `bg-${theme.surfaceColors.light}`,
                    )}
                  >
                    {intervalContext.intervalText}
                  </div>
                )}
              />
            </TimelineHeaders>
          </Timeline>
        </Component>
      )}
    </>
  );
};

export default CollectionTimeline;
