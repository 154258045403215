import React, { useCallback, useMemo, useState } from 'react';
import { IconAlertTriangle, IconEdit } from '@tabler/icons-react';
import first from 'lodash/first';
import kebabCase from 'lodash/kebabCase';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Loader, Tooltip } from '@noloco/components';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { shouldRenderComponent } from '../../../components/canvas/withVisibilityRules';
import { darkModeColors } from '../../../constants/darkModeColors';
import { RECORD_VIEW } from '../../../constants/elements';
import { CUSTOM_VISIBILITY_RULES } from '../../../constants/features';
import { setIsEditingData } from '../../../reducers/data';
import { setSelectedSectionPath } from '../../../reducers/elements';
import { isEditingDataSelector } from '../../../selectors/dataSelectors';
import { editorModeSelector } from '../../../selectors/elementsSelectors';
import useAddSection from '../../../utils/hooks/useAddSection';
import { useBackLink } from '../../../utils/hooks/useBacklink';
import useBreakpoints from '../../../utils/hooks/useBreakpoints';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useScopeUser from '../../../utils/hooks/useScopeUser';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import useSelectedTab from '../../../utils/hooks/useSelectedTab';
import RecordViewSectionOptions from '../../RecordViewSectionOptions';
import MobileActionButtonsWrapper from './MobileActionButtonsWrapper';
import RecordActionButtons from './RecordActionButtons';
import RecordViewSections from './RecordViewSections';
import RecordViewTabs from './RecordViewTabs';
import RecordViewTitle from './RecordViewTitle';

const LANG_KEY = 'elements.VIEW.edit';

const RecordViewBody = ({
  actionButtons,
  classNames,
  comments,
  data,
  dataType,
  element,
  elementPath,
  hideEditButton,
  editButtonText,
  doneButtonText,
  editButtonVisibilityRules,
  icon,
  isSplitLayout,
  loading,
  name,
  pageId,
  permissions,
  project,
  recordId,
  recordScope,
  refetch,
  rootPathname,
  scope,
  sections,
  tabs,
  title,
  subtitle,
  hideBreadcrumbs,
}: any) => {
  const currentUser = useScopeUser();

  const [isDarkModeEnabled] = useDarkMode();
  const { sm: isSmScreen } = useBreakpoints();

  const dispatch = useDispatch();
  const [editSectionsLoading, setEditSectionsLoading] = useState({});
  const [sectionError, setSectionError] = useState({});
  const {
    pathname,
    location,
    // @ts-expect-error TS(2339): Property 'recordId' does not exist on type '{}'.
    query: { recordId: queryRecordId, tab },
  } = useRouter();

  const {
    editButtonText: resolvedEditButtonText,
    doneButtonText: resolvedDoneButtonText,
    subtitle: resolvedSubtitle,
  } = useSectionScopeVariables(
    RECORD_VIEW,
    {
      editButtonText,
      doneButtonText,
      subtitle,
    },
    project,
    elementPath,
    recordScope,
  );

  const onError = useCallback((sectionId: string) => {
    setSectionError((currentSectionsError) => ({
      ...currentSectionsError,
      [sectionId]: true,
    }));
  }, []);

  const onLoadingChange = useCallback((sectionId: any, nextLoading: any) => {
    setEditSectionsLoading((currentEditSectionsLoading) => ({
      ...currentEditSectionsLoading,
      [sectionId]: nextLoading,
    }));
    setSectionError((currentSectionsError) => ({
      ...currentSectionsError,
      [sectionId]: false,
    }));
  }, []);

  const isEditLoading = useMemo(
    () => Object.values(editSectionsLoading).some(Boolean),
    [editSectionsLoading],
  );

  const hasError = useMemo(() => Object.values(sectionError).some(Boolean), [
    sectionError,
  ]);

  const backLink = useBackLink(project);

  const editorMode = useSelector(editorModeSelector);
  const isEditingData = useSelector(isEditingDataSelector(element.id));

  const onChangeEditingData = useCallback(
    (newIsEditingData: any) => {
      dispatch(setIsEditingData({ id: element.id, value: newIsEditingData }));

      if (!newIsEditingData && refetch) {
        refetch();
      }
    },
    [dispatch, element.id, refetch],
  );

  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
  const visibilityRulesScope = useMemo(() => ({ ...scope, ...recordScope }), [
    recordScope,
    scope,
  ]);
  const editButtonVisible = useMemo(
    () =>
      !hideEditButton &&
      (editorMode ||
        shouldRenderComponent(
          currentUser,
          editButtonVisibilityRules,
          project,
          visibilityRulesScope,
          customRulesEnabled,
        )),
    [
      currentUser,
      customRulesEnabled,
      editButtonVisibilityRules,
      editorMode,
      hideEditButton,
      project,
      visibilityRulesScope,
    ],
  );
  const visibleTabs = useMemo(
    () =>
      editorMode
        ? tabs
        : tabs.filter((recordTab: any) => {
            const shouldRender = shouldRenderComponent(
              currentUser,
              recordTab.visibilityRules,
              project,
              visibilityRulesScope,
              customRulesEnabled,
            );

            return shouldRender;
          }),
    [
      currentUser,
      customRulesEnabled,
      editorMode,
      project,
      tabs,
      visibilityRulesScope,
    ],
  );

  const selectedTab = useSelectedTab(tabs, visibleTabs);

  const onCloseSectionEditor = useCallback(() => {
    dispatch(setSelectedSectionPath([]));
  }, [dispatch]);

  const [addSection] = useAddSection(
    project,
    sections,
    elementPath,
    ['record', 'sections'],
    selectedTab,
  );

  if (tabs.length > 1 && visibleTabs.length > 0 && !loading) {
    // If we have more than one tab, and at least one visible tab, redirect the URL to that tab
    if (!tab) {
      return (
        <Redirect
          to={`${pathname}/${kebabCase((first as any)(visibleTabs).title)}${
            location.search || ''
          }`}
        />
      );
    } else if (selectedTab && kebabCase(selectedTab.title) !== tab) {
      // If the tab in the URL doesn't match the one we're rendering, redirect to it
      const updatedPathname = pathname.replace(
        new RegExp(`${tab}$`),
        kebabCase(selectedTab.title),
      );
      return <Redirect to={`${updatedPathname}${location.search || ''}`} />;
    }
  }

  return (
    <>
      <div
        className={`sticky overflow-hidden px-3 top-0 border-b z-30 flex flex-col w-full ${
          isDarkModeEnabled
            ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
            : 'bg-white'
        }`}
        onClick={editorMode ? onCloseSectionEditor : undefined}
      >
        <RecordViewTitle
          backLink={backLink}
          classNames={classNames}
          comments={comments}
          data={data}
          dataType={dataType}
          icon={icon}
          isSplitLayout={isSplitLayout}
          loading={loading}
          name={name}
          rootPathname={rootPathname}
          title={title}
          subtitle={resolvedSubtitle}
          hideBreadcrumbs={hideBreadcrumbs}
        >
          {!isSmScreen && (
            <RecordActionButtons
              actionButtons={actionButtons}
              backLink={backLink}
              dataType={dataType}
              editorMode={editorMode}
              elementPath={elementPath}
              project={project}
              record={data}
              recordScope={recordScope}
              rootPathname={rootPathname}
            />
          )}
          <div className="flex items-center justify-center">
            {editButtonVisible && permissions.update && (
              <Button
                className={classNames(
                  'flex items-center space-x-2 rounded-tl-lg rounded-bl-lg rounded-tr-lg rounded-br-lg',
                  {
                    'sm:rounded-tr-none sm:rounded-br-none':
                      actionButtons && actionButtons.length > 0,
                  },
                )}
                disabled={isEditLoading}
                type={isEditingData ? 'outline' : 'filled'}
                rounded={false}
                onClick={() => onChangeEditingData(!isEditingData)}
              >
                {isEditLoading ? (
                  <Loader size="xs" />
                ) : hasError && isEditingData ? (
                  <Tooltip
                    content={getText(LANG_KEY, 'unsavedChanged')}
                    placement="top-end"
                    bg="white"
                  >
                    <span>
                      <IconAlertTriangle size={18} className="opacity-75" />
                    </span>
                  </Tooltip>
                ) : (
                  <IconEdit size={14} className="opacity-75" />
                )}
                <span>
                  {isEditingData
                    ? resolvedDoneButtonText || getText(LANG_KEY, 'done')
                    : resolvedEditButtonText || getText(LANG_KEY, 'edit')}
                </span>
              </Button>
            )}
            {isSmScreen && actionButtons && actionButtons.length > 0 && (
              <MobileActionButtonsWrapper
                newButtonVisible={editButtonVisible && permissions.update}
                type={isEditingData ? 'outline' : 'filled'}
              >
                <RecordActionButtons
                  actionButtons={actionButtons}
                  backLink={backLink}
                  buttonType="text"
                  dataType={dataType}
                  editorMode={editorMode}
                  elementPath={elementPath}
                  project={project}
                  record={data}
                  recordScope={recordScope}
                  rootPathname={rootPathname}
                />
              </MobileActionButtonsWrapper>
            )}
          </div>
        </RecordViewTitle>
        <RecordViewTabs
          editorMode={editorMode}
          project={project}
          queryRecordId={queryRecordId}
          recordId={recordId}
          recordScope={recordScope}
          rootPathname={rootPathname}
          tab={tab}
          visibleTabs={visibleTabs}
        />
      </div>
      <div
        className="mt-6 px-2 flex flex-wrap gap-y-4 flex-grow w-full"
        data-testid="record-view-body"
      >
        {!loading && (
          <RecordViewSections
            data={data}
            dataType={dataType}
            editorMode={editorMode}
            elementPath={elementPath}
            isEditingData={isEditingData}
            onError={onError}
            onLoadingChange={onLoadingChange}
            pageId={pageId}
            project={project}
            isRecordView={true}
            recordScope={recordScope}
            rootPathname={rootPathname}
            sections={sections}
            selectedTab={selectedTab}
            tabs={tabs}
            visibleTabs={visibleTabs}
          />
        )}
        {loading && (
          <div className="flex items-center justify-center py-36 w-full">
            <Loader />
          </div>
        )}
      </div>
      {editorMode && (
        <RecordViewSectionOptions dataType={dataType} onSelect={addSection} />
      )}
    </>
  );
};

export default RecordViewBody;
