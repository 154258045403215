import React, { memo, useMemo } from 'react';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { DateTime } from 'luxon';
import { Tooltip } from '@noloco/components';
import Avatar from '../../../components/Avatar';
import MarkdownText from '../../../components/MarkdownText';
import { COMMENT_MENTION_REGEX } from '../../../constants/comments';
import { darkModeColors } from '../../../constants/darkModeColors';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { getFullName } from '../../../utils/user';
import FilePreview from '../forms/FilePreview';

const Mention = ({ id, mentionedUsers }: any) => {
  const user = useMemo(
    () =>
      get(mentionedUsers, 'edges', []).find(
        (userEdge: any) => userEdge.node.id === String(id),
      ),
    [id, mentionedUsers],
  );

  if (!user) {
    return null;
  }

  return (
    <span className="rounded bg-blue-200 p-0.5 mr-0.5">
      {getFullName(user.node)}
    </span>
  );
};

const formatCommentTime = (createdAt: any, isDarkModeEnabled: boolean) => {
  const createdAtDt = DateTime.fromISO(createdAt);
  const relativeTime = createdAtDt.toRelativeCalendar();
  const time =
    relativeTime === 'today'
      ? `${createdAtDt.toLocaleString(DateTime.TIME_24_SIMPLE)} • `
      : '';
  return (
    <Tooltip
      placement="top-end"
      content={
        <span className={isDarkModeEnabled ? darkModeColors.text.primary : ''}>
          {createdAtDt.toLocaleString(DateTime.DATETIME_SHORT)}
        </span>
      }
      bg={isDarkModeEnabled ? '' : 'white'}
    >
      <span className="cursor-default">
        {time}
        {/* @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message */}
        {upperFirst(relativeTime)}
      </span>
    </Tooltip>
  );
};

const convertMentionsToComponents = (commentText: any) =>
  commentText.replace(COMMENT_MENTION_REGEX, (_0: any, _1: any, id: any) => {
    return `<Mention id={${id}} />`;
  });

// @ts-expect-error TS(2339): Property 'comment' does not exist on type '{}'.
const RecordComment = memo(({ comment }) => {
  const { author, attachments, createdAt, mentionedUsers, text } = comment;
  const [isDarkModeEnabled] = useDarkMode();
  const fileAttachments = get(attachments, 'edges', []).map(
    (edge: any) => edge.node,
  );

  const mentionOverrideConfig = useMemo(
    () => ({
      Mention: {
        component: ({ id }: any) => (
          <Mention id={id} mentionedUsers={mentionedUsers} />
        ),
      },
    }),
    [mentionedUsers],
  );

  return (
    <div className="flex flex-col">
      <div className="flex items-center w-full">
        {author && <Avatar initialsSize="text-xs" user={author} size={6} />}
        {author && (
          <span className="font-medium text-sm ml-2">
            {author.firstName || ''} {author.lastName || ''}
          </span>
        )}
        <div className="ml-auto text-xs text-gray-500">
          {formatCommentTime(createdAt, isDarkModeEnabled as boolean)}
        </div>
      </div>
      {text && (
        <div
          className={`text-sm mt-2 ${
            isDarkModeEnabled ? darkModeColors.text.primary : 'text-gray-700'
          }`}
        >
          <MarkdownText
            disabledHeadings={true}
            overrides={mentionOverrideConfig}
          >
            {convertMentionsToComponents(text)}
          </MarkdownText>
        </div>
      )}
      {fileAttachments.length > 0 && (
        <div className="flex items-stretch my-2">
          <FilePreview
            // @ts-expect-error TS(2322): Type '{ id: any; files: any; isMultiple: boolean; ... Remove this comment to see the full error message
            id={comment.uuid}
            files={fileAttachments}
            isMultiple={true}
            readOnly={true}
            maxH={10}
            surface="light"
          />
        </div>
      )}
    </div>
  );
});

export default RecordComment;
