import { useCallback } from 'react';
import { getText } from '../lang';
import { formatUrl } from '../urls';
import { useErrorAlert } from './useAlerts';

export const NEW_TAB = true;

export const useOpenUrl = () => {
  const errorAlert = useErrorAlert();

  const openUrl = useCallback(
    (href: string, inNewTab: boolean) => {
      const formattedUrl = formatUrl(href);

      if (inNewTab) {
        const newTab = window.open(formattedUrl, '_blank');

        if (newTab) {
          newTab.focus();
        } else {
          errorAlert(getText('errors.popUpBlocker'));
        }
      } else {
        window.location.assign(formattedUrl);
      }
    },
    [errorAlert],
  );

  return openUrl;
};
