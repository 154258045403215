import React, { forwardRef, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { LG, MD, SM, XL } from '../../constants/tShirtSizes';

const FULL = 'full';

type Props = {
  children?: string | React.ReactNode;
  closeOnOutsideClick?: boolean;
  contextual?: boolean;
  onClose?: (...args: any[]) => any;
  open?: boolean;
  rootSelector?: string;
  size?: any; // TODO: PropTypes.oneOf([...tShirtSizes, FULL])
};

const BaseModal = forwardRef<any, Props>(
  // @ts-expect-error TS(2345): Argument of type '({ className, children, contextu... Remove this comment to see the full error message
  (
    {
      // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
      className,
      children,
      contextual,
      closeOnOutsideClick,
      open,
      onClose,
      size,
      rootSelector,
      ...rest
    },
    ref,
  ) => {
    const [localOpen, setLocalOpen] = useState(open);

    useEffect(() => {
      if (open !== localOpen) {
        setLocalOpen(open);
      }
    }, [localOpen, open]);

    const handleClose = (event: any) => {
      if (onClose) {
        event.stopPropagation();
        onClose(event);
      }
      setLocalOpen(false);
    };
    const root =
      // @ts-expect-error TS(2769): No overload matches this call.
      typeof document !== 'undefined' && document.querySelector(rootSelector);

    return (
      localOpen &&
      root &&
      ReactDOM.createPortal(
        <div
          className={classNames(
            contextual ? 'absolute' : 'fixed',
            'z-50 inset-0 overflow-y-auto',
            className,
          )}
          ref={ref}
          {...rest}
        >
          <div
            className={classNames(
              'flex justify-center items-center text-center',
              {
                'min-h-screen': !contextual,
                'min-h-full': contextual,
                'px-4 sm:py-0 sm:px-2': size !== FULL,
              },
            )}
          >
            <Transition
              show={true}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className={classNames(
                contextual ? 'absolute' : 'fixed',
                'inset-0 transition-opacity',
              )}
            >
              <div
                className="absolute inset-0 bg-gray-700 opacity-75"
                onClick={closeOnOutsideClick ? handleClose : undefined}
              />
            </Transition>
            <Transition
              show={true}
              enter="ease-out duration-300"
              enterFrom="opacity-0 sm:translate-y-4 translate-y-0 scale-95"
              enterTo="opacity-100 sm:translate-y-0 scale-100"
              leave="transition ease-in duration-100"
              leaveFrom="ease-in duration-200"
              leaveTo="opacity-0 sm:translate-y-4 translate-y-0 scale-95"
              className={classNames(
                'flex flex-col align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all w-full',
                {
                  'sm:my-0 max-h-screen-90 rounded-lg': size !== FULL,
                  'max-w-md': size === SM,
                  'max-w-xl': size === MD,
                  'max-w-3xl': size === LG,
                  'max-w-screen-lg': size === XL,
                },
              )}
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              {children}
            </Transition>
          </div>
        </div>,
        root,
      )
    );
  },
);

BaseModal.defaultProps = {
  closeOnOutsideClick: true,
  contextual: false,
  open: true,
  size: MD,
  rootSelector: '#root',
};

export default BaseModal;
