import React, { useCallback, useMemo, useState } from 'react';
import { IconCircleCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import {
  CHART,
  COLLECTION,
  DETAILS,
  HIGHLIGHTS,
  STAGES,
  TITLE,
} from '@noloco/core/src/constants/elements';
import elementConfigs from '@noloco/core/src/elements';
import RecordView from '@noloco/core/src/elements/sections/view/RecordView';
import { Element } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { User } from '@noloco/core/src/models/User';
import { WEBSITE_EXAMPLES, trackEvent } from '@noloco/core/src/utils/analytics';
import useSetBaseScope from '@noloco/core/src/utils/hooks/useSetBaseScope';
import { getText } from '@noloco/core/src/utils/lang';
import { dataTypePermissions } from '@noloco/core/src/utils/permissions';
import exampleUser from '../../example-user';
import BaseExample from '../BaseExample';

const LANG_KEY = 'websiteExamples.recordView';

const RECORD_UUID = 'rec1fz326jler5uoyn';

const useUpdateProperty = () => {};

const EMPTY_SCOPE = {};

const SECTIONS = [HIGHLIGHTS, DETAILS, TITLE, STAGES, COLLECTION, CHART];
const sectionIds: Record<string, string> = {
  [HIGHLIGHTS]: 'o-JEmOiu4H',
  [STAGES]: '7rOvpfqXTK',
  [DETAILS]: 'gcjVQuZx8-',
  [TITLE]: 'vh3tZ0ZkH',
  [COLLECTION]: 'n1-5XAoqU',
  [CHART]: 'WSt0AjA93',
};

const SectionIcon = ({ section }: { section: string }) => {
  const { Icon } = elementConfigs[section];
  return <Icon className="flex-shrink-0" size={20} />;
};

const RecordViewExample = ({ project }: { project: Project }) => {
  useSetBaseScope(project.dataTypes);

  const [enabledSections, setEnabledSections] = useState([HIGHLIGHTS, DETAILS]);

  const element = project.elements[0];
  const dataType = project.dataTypes.getByName(
    element.props.dataList.dataType,
  )!;

  const permissions = useMemo(
    () => dataTypePermissions(dataType, exampleUser as User),
    [dataType],
  );

  const {
    name,
    record: { sections, title },
  } = element.props;

  const onToggleSection = useCallback(
    (section: string) => {
      const isEnabled = enabledSections.includes(section);
      trackEvent(WEBSITE_EXAMPLES.RECORD_SECTION_TOGGLED, 'section', section);

      if (isEnabled) {
        setEnabledSections(enabledSections.filter((s) => s !== section));
      } else {
        setEnabledSections([section, ...enabledSections]);
      }
    },
    [enabledSections],
  );

  const enabledSectionIds = useMemo(
    () => enabledSections.map((type) => sectionIds[type]),
    [enabledSections],
  );

  const viewSections = useMemo(
    () =>
      sections
        .filter((section: Element) => enabledSectionIds.includes(section.id))
        .sort(
          (sectionA: Element, sectionB: Element) =>
            enabledSectionIds.indexOf(sectionA.id) -
            enabledSectionIds.indexOf(sectionB.id),
        ),
    [enabledSectionIds, sections],
  );

  return (
    <BaseExample showGradient={true}>
      <div className="flex md:flex-col items-center overflow-hidden">
        <div className="flex flex-col w-2/5 md:w-full px-4 flex-shrink-0">
          <div className="flex flex-col ">
            <h2 className="text-3xl font-medium mb-3">
              {getText(LANG_KEY, 'title')}
            </h2>
            <p className="text-base mb-8">{getText(LANG_KEY, 'subtitle')}</p>
          </div>
          <div className="grid grid-cols-2 gap-3 justify-center items-center mb-8">
            {SECTIONS.map((section) => (
              <button
                className={classNames(
                  'flex sm:flex-col sm:justify-center items-center rounded-lg border px-3 py-2 bg-white hover:ring-2 hover:ring-pink-200 ring-pink-400 w-full mx-auto h-full',
                  { 'ring-2': enabledSections.includes(section) },
                )}
                key={section}
                disabled={
                  enabledSections.length === 2 &&
                  enabledSections.includes(section)
                }
                onClick={() => onToggleSection(section)}
              >
                <SectionIcon section={section} />
                <div className="flex flex-col text-left ml-3 mr-auto sm:ml-0 sm:mr-0">
                  <span className="text-sm">
                    {getText('elements', section, 'label')}
                  </span>
                  <span className="text-xs sm:hidden">
                    {getText('elements', section, 'description')}
                  </span>
                </div>
                <div className="w-6 ml-3 md:hidden">
                  {enabledSections.includes(section) && (
                    <IconCircleCheck
                      size={24}
                      className="text-pink-500 flex-shrink-0"
                    />
                  )}
                </div>
              </button>
            ))}
          </div>
        </div>
        <RecordView
          // @ts-expect-error record view has no typing
          title={title}
          sections={viewSections}
          className="overflow-hidden max-h-screen-75 border rounded-lg"
          dataType={dataType}
          element={element}
          elementPath={[0]}
          hideEditButton={true}
          name={name}
          permissions={permissions}
          project={project}
          rootPathname="#"
          recordId={RECORD_UUID}
          scope={EMPTY_SCOPE}
          isSplitLayout={false}
          useUpdateProperty={useUpdateProperty}
        />
      </div>
    </BaseExample>
  );
};

export default RecordViewExample;
