import { partionedForEach } from '../utils/paritionedLoops';

interface Identifier {
  id: number;
}

class BaseArrayTypeMap<T extends Identifier> extends Array<T> {
  idMap: null | Record<string | number, T>;

  constructor(arrayOrLength?: Number | T[] | BaseArrayTypeMap<T>) {
    if (arrayOrLength === undefined) {
      super();
    } else if (typeof arrayOrLength === 'number') {
      super(arrayOrLength);
    } else {
      super(...(arrayOrLength as T[]));
    }

    if (arrayOrLength instanceof BaseArrayTypeMap) {
      //Avoid re-calculating these maps if already populated
      this.idMap = arrayOrLength.idMap;
    } else {
      this.idMap = null;
    }
  }

  async asyncSetup() {
    if (this.idMap) {
      return this;
    }

    await this._mapEntriesAsync();
    return this;
  }

  syncSetup() {
    if (this.idMap) {
      return this;
    }

    this._mapEntries();
    return this;
  }

  _mapEntry(entry: T) {
    if (this.idMap) {
      this.idMap[entry.id] = entry;
    }
  }

  async _mapEntriesAsync() {
    this.idMap = {};

    await partionedForEach(this as T[], (entry) => this._mapEntry(entry), {});
  }

  _mapEntries() {
    this.idMap = {};

    this.forEach((entry: T) => this._mapEntry(entry));
  }

  getById(id: string | number) {
    if (!this.idMap) {
      this._mapEntries();
    }
    return this.idMap ? this.idMap[id] : undefined;
  }

  concat(arg: any[]) {
    const concatResult = super.concat(arg);
    if (this.idMap) {
      arg.forEach((entry: T) => {
        this._mapEntry(entry);
      });
    }
    return concatResult;
  }

  entries() {
    return super.entries();
  }

  every(
    predicate: (value: T, index: number, array: T[]) => unknown,
    thisArg?: any,
  ): boolean;
  every<S extends T>(
    predicate: (value: T, index: number, array: T[]) => value is S,
    thisArg?: any,
  ) {
    return super.every(predicate, thisArg);
  }

  filter(
    predicate: (value: T, index: number, array: T[]) => unknown,
    thisArg?: any,
  ) {
    return super.filter(predicate, thisArg);
  }

  findIndex(
    predicate: (value: T, index: number, obj: T[]) => unknown,
    thisArg?: any,
  ) {
    return super.findIndex(predicate, thisArg);
  }

  find(
    predicate: (value: T, index: number, obj: T[]) => unknown,
    thisArg?: any,
  ): T | undefined {
    return super.find(predicate, thisArg);
  }

  forEach(callbackFn: (value: T, index: number, obj: T[]) => unknown) {
    return super.forEach(callbackFn);
  }

  includes(searchElement: T) {
    return super.includes(searchElement);
  }

  join(...args: any[]) {
    return super.join(...args);
  }

  map<U>(
    callbackfn: (value: T, index: number, array: T[]) => U,
    thisArg?: any,
  ) {
    return super.map(callbackfn, thisArg);
  }

  push(...items: T[]) {
    const pushResult = super.push(...items);
    if (this.idMap) {
      items.forEach((entry: T) => {
        this._mapEntry(entry);
      });
    }
    return pushResult;
  }

  slice(start?: number, end?: number) {
    return super.slice(start, end);
  }

  sort(compareFn?: (a: T, b: T) => number) {
    return super.sort(compareFn);
  }
}

export default BaseArrayTypeMap;
