import type { PropertyPath } from 'lodash';
import get from 'lodash/get';

type Key = string | number;

/**
 * Reduces an array to a map-like object with a supplied transformer to enable fast lookups.
 */
export const transformedLookup: <T, U>(
  array: T[],
  entryTransformer: (entry: T) => Record<Key, U>,
) => Record<Key, U> = (array, entryTransformer) =>
  array.reduce(
    (acc, entry) => ({
      ...acc,
      ...entryTransformer(entry),
    }),
    {},
  );

/**
 * Reduces an array to a map-like object to enable fast lookups by an arbitrary key derived from each entry.
 */
export const formattedLookup: <T, U>(
  array: T[],
  pathKey: PropertyPath,
  formatValue: (entry: T) => U,
) => Record<Key, U> = (array, pathKey, getValue) =>
  transformedLookup(array, (entry) => ({
    [get(entry, pathKey)]: getValue(entry),
  }));

/**
 * Reduces an array to a map-like object to enable fast lookups by a key on its entries.
 */
export const lookupOfArray: <T>(
  array: T[],
  pathKey: PropertyPath,
) => Record<Key, T> = (array, pathKey: PropertyPath) =>
  formattedLookup(array, pathKey, (entry) => entry);

export const ensureArray = <T>(value: T | T[] | null | undefined): T[] => {
  if (Array.isArray(value)) {
    return value;
  }

  if (value === null || value === undefined) {
    return [];
  }

  return [value];
};
