import React, { forwardRef } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { BORDERED, FULL_BACKGROUND } from './verticalNavTypes';

type Props = {
  className?: string;
  type?: any; // TODO: PropTypes.oneOf(verticalNavTypes)
};

const VerticalNav = forwardRef<any, Props>(
  ({ children, className, type, ...rest }, ref) => {
    return (
      <Box
        className={classNames(className, 'flex flex-col')}
        ref={ref}
        {...rest}
      >
        {React.Children.map(
          children,
          (child, index) =>
            child &&
            React.cloneElement(child as any, {
              borderB:
                index !== (children as any[]).length - 1 && type === BORDERED,
              type,
            }),
        )}
      </Box>
    );
  },
);

VerticalNav.defaultProps = {
  className: '',
  type: FULL_BACKGROUND,
};

export default VerticalNav;
