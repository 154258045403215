import { useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { DataType } from '../../models/DataTypes';
import { Action } from '../../models/Element';
import { Project } from '../../models/Project';
import { reduceFieldsToQueryObject } from '../../queries/data';
import { MutationType, getMutationQueryString } from '../../queries/project';
import pascalCase from '../pascalCase';
import useActionButtonFormFields from './useActionButtonFormFields';
import useAutoFormConfig from './useAutoFormConfig';
import useAutoFormVariables from './useAutoFormVariables';

const DEFAULT_NODE_QUERY = {
  id: true,
  uuid: true,
  createdAt: true,
  updatedAt: true,
};

const useActionWithHiddenInputsMutation = (
  action: Action,
  dataType: DataType,
  mutationType: MutationType,
  project: Project,
  record: any,
) => {
  const apolloClient = useApolloClient();
  const { field, formFields } = action || {};

  const { actionDataType, fields, recordId } = useActionButtonFormFields(
    dataType,
    field,
    formFields,
    project,
    record,
    mutationType,
  );

  const dt = useMemo(
    () => actionDataType && project.dataTypes.getByName(actionDataType.name),
    [actionDataType, project.dataTypes],
  );
  const { dataTypeWithRelations, fieldConfigs } = useAutoFormConfig(
    dt,
    fields,
    project,
    recordId,
  );

  const mutationQueryObject = useMemo(
    () =>
      fieldConfigs
        .map((config) => config.field)
        .reduce(
          reduceFieldsToQueryObject(project.dataTypes, {
            includeCollections: true,
            includeNestedFields: true,
            includeHidden: true,
          }),
          DEFAULT_NODE_QUERY,
        ),
    [fieldConfigs, project.dataTypes],
  );

  // @ts-expect-error TS(2554): Expected 8-11 arguments, but got 4.
  const { getQueryVariables } = useAutoFormVariables(
    project,
    dataType,
    fieldConfigs,
    mutationType,
  );

  // @ts-expect-error TS(2554): Expected 3 arguments, but got 1.
  const { variables } = getQueryVariables({ id: recordId || undefined });

  const mutation = gql`
    ${getMutationQueryString(
      mutationType,
      dataTypeWithRelations.name,
      dataTypeWithRelations.fields,
      mutationQueryObject,
    )}
  `;

  const context = useMemo(
    () => ({
      projectQuery: true,
      projectName: project.name,
    }),
    [project.name],
  );

  const mutationWithResponse = useCallback(
    () =>
      apolloClient.mutate({ mutation, variables, context }).then(({ data }) => {
        if (data && dt) {
          return get(
            data,
            `${mutationType.toLowerCase()}${pascalCase(dt.apiName)}`,
          );
        }

        return undefined;
      }),
    [apolloClient, context, dt, mutation, mutationType, variables],
  );

  return mutationWithResponse;
};

export default useActionWithHiddenInputsMutation;
