import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import {
  BOARD,
  CALENDAR,
  CARDS,
  CHARTS,
  COLUMNS,
  GANTT,
  MAP,
  ROWS,
  SINGLE_RECORD,
  SPLIT,
  TABLE,
  TABLE_FULL,
  TIMELINE,
} from '@noloco/core/src/constants/collectionLayouts';
import OnboardingModal from '@noloco/ui/src/components/onboarding/OnboardingModal';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { darkModeColors } from '../constants/darkModeColors';
import { FOLDER } from '../constants/elements';
import { PREMIUM_LAYOUTS } from '../constants/features';
import { User } from '../models/User';
import { setSelectedElement } from '../reducers/elements';
import { scopeSelector } from '../selectors/dataSelectors';
import { isSelectedSelector } from '../selectors/elementsSelectors';
import { verifyIfPremium } from '../utils/collectionLayouts';
import { getPagesConfig } from '../utils/elements';
import {
  useErrorAlert,
  useRemoveAllAlerts,
  useSuccessAlert,
} from '../utils/hooks/useAlerts';
import useAuthWrapper from '../utils/hooks/useAuthWrapper';
import useDarkMode from '../utils/hooks/useDarkMode';
import useIsWindowOnline from '../utils/hooks/useIsWIndowOnline';
import useRouter from '../utils/hooks/useRouter';
import { getText } from '../utils/lang';
import { dataTypePermissions } from '../utils/permissions';
import ViewCollection from './ViewCollection';
import SplitLayout from './sections/collections/SplitLayout';
import NewForm from './sections/view/NewForm';
import RecordView from './sections/view/RecordView';
import ViewBreadcrumbs from './sections/view/ViewBreadcrumbs';

const EMPTY_SUCCESS_MESSAGE = { message: '', icon: '' };
const LANG_KEY = 'settings.pwa';

const View = memo(
  forwardRef(
    (
      {
        // @ts-expect-error TS(2339): Property 'actionButtons' does not exist on type '{... Remove this comment to see the full error message
        actionButtons = [],
        // @ts-expect-error TS(2339): Property 'calendarView' does not exist on type '{}... Remove this comment to see the full error message
        calendarView,
        // @ts-expect-error TS(2339): Property 'enableDragAndDropEdit' does not exist on... Remove this comment to see the full error message
        enableDragAndDropEdit,
        // @ts-expect-error TS(2339): Property 'charts' does not exist on type '{}'.
        charts = [],
        // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
        className,
        // @ts-expect-error TS(2339): Property 'coverPhoto' does not exist on type '{}'.
        coverPhoto,
        // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
        dataType,
        // @ts-expect-error TS(2339): Property 'dataList' does not exist on type '{}'.
        dataList,
        // @ts-expect-error TS(2339): Property 'fields' does not exist on type '{}'.
        fields = [],
        // @ts-expect-error TS(2339): Property 'layout' does not exist on type '{}'.
        layout: viewLayout,
        // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type '{}'.
        editorMode,
        // @ts-expect-error TS(2339): Property 'exportButton' does not exist on type '{}... Remove this comment to see the full error message
        exportButton,
        // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{}'... Remove this comment to see the full error message
        elementPath,
        // @ts-expect-error TS(2339): Property 'dateStart' does not exist on type '{}'.
        dateStart,
        // @ts-expect-error TS(2339): Property 'dateEnd' does not exist on type '{}'.
        dateEnd,
        // @ts-expect-error TS(2339): Property 'ganttDependency' does not exist on type '{}'.
        ganttDependency,
        // @ts-expect-error TS(2339): Property 'groups' does not exist on type '{}'.
        groups,
        // @ts-expect-error TS(2339): Property 'groupBy' does not exist on type '{}'.
        groupBy,
        // @ts-expect-error TS(2339): Property 'groupBySort' does not exist on type '{}... Remove this comment to see the full error message
        groupBySort,
        // @ts-expect-error TS(2339): Property 'groupOptions' does not exist on type '{}... Remove this comment to see the full error message
        groupOptions,
        // @ts-expect-error TS(2339): Property 'icon' does not exist on type '{}'.
        icon,
        // @ts-expect-error TS(2339): Property 'importButton' does not exist on type '{}... Remove this comment to see the full error message
        importButton,
        // @ts-expect-error TS(2339): Property 'hideEmptyGroups' does not exist on type ... Remove this comment to see the full error message
        hideEmptyGroups,
        // @ts-expect-error TS(2339): Property 'hideNewButton' does not exist on type '{... Remove this comment to see the full error message
        hideNewButton,
        // @ts-expect-error TS(2339): Property 'inOnboarding' does not exist on type '{... Remove this comment to see the full error message
        inOnboarding,
        // @ts-expect-error TS(2339): Property 'limitPerGroup' does not exist on type '{... Remove this comment to see the full error message
        limitPerGroup,
        // @ts-expect-error TS(2339): Property 'map' does not exist on type '{}'.
        map,
        // @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'.
        name,
        // @ts-expect-error TS(2339): Property 'newButton' does not exist on type '{... Remove this comment to see the full error message
        newButton = {},
        // @ts-expect-error TS(2339): Property 'newButtonText' does not exist on type '{... Remove this comment to see the full error message
        newButtonText,
        // @ts-expect-error TS(2339): Property 'newLink' does not exist on type '{}'.
        newLink,
        // @ts-expect-error TS(2339): Property 'new' does not exist on type '{}'.
        new: newProps = {},
        // @ts-expect-error TS(2339): Property 'record' does not exist on type '{}'.
        record = {},
        // @ts-expect-error TS(2339): Property 'recordTitle' does not exist on type '{}'.
        recordTitle,
        // @ts-expect-error TS(2339): Property 'rowLink' does not exist on type '{}'.
        rowLink,
        // @ts-expect-error TS(2339): Property 'search' does not exist on type '{}'.
        search,
        // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type '{}'.
        subtitle,
        // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
        title,
        // @ts-expect-error TS(2339): Property 'onClick' does not exist on type '{}'.
        onClick,
        // @ts-expect-error TS(2339): Property 'emptyState' does not exist on type '{}'.
        emptyState,
        // @ts-expect-error TS(2339): Property 'routePath' does not exist on type '{}'.
        routePath,
        // @ts-expect-error TS(2339): Property 'filters' does not exist on type '{}'.
        filters = [],
        // @ts-expect-error TS(2339): Property 'parentPage' does not exist on type '{}'.
        parentPage,
        // @ts-expect-error TS(2339): Property 'sidebarSize' does not exist on type '{}'... Remove this comment to see the full error message
        sidebarSize,
        // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
        project,
        // @ts-expect-error TS(2339): Property 'useUpdateProperty' does not exist on typ... Remove this comment to see the full error message
        useUpdateProperty,
        // @ts-expect-error TS(2339): Property 'hideBreadcrumbs' does not exist on typ... Remove this comment to see the full error message
        hideBreadcrumbs,
      },
      ref,
    ) => {
      // This could be a risk, but it solves the issue of figuring out which sub-routes to render
      const scope = useSelector(scopeSelector);
      const [isDarkModeEnabled] = useDarkMode();

      const { match } = useRouter();
      const { user } = useAuthWrapper();
      const isSelected = useSelector(isSelectedSelector(elementPath));
      const dispatch = useDispatch();
      const element = useMemo(() => get(project.elements, elementPath, {}), [
        elementPath,
        project.elements,
      ]);

      const formatRecordScope = (record: any) => ({
        [`${element.id}:VIEW`]: record,
      });

      const permissions = useMemo(
        () => dataTypePermissions(dataType, user as User),
        [dataType, user],
      );

      const handleOnClickOverlay = useCallback(
        (event: any) => {
          event.stopPropagation();
          dispatch(setSelectedElement(elementPath));
        },
        [dispatch, elementPath],
      );

      const topLevelPages = useMemo(() => {
        const { pagesPath } = getPagesConfig(
          project.elements,
          project.settings,
        );

        return pagesPath.length > 0
          ? get(project.elements, pagesPath, [])
          : project.elements;
      }, [project]);

      const parentPageElement = useMemo(
        () =>
          parentPage &&
          topLevelPages.find((page: any) => page.id === parentPage),
        [parentPage, topLevelPages],
      );

      const { routePrefix, viewRoutePrefix } = useMemo(() => {
        if (parentPageElement) {
          const parentIsFolder = parentPageElement.type === FOLDER;
          const basePrefix = `/${parentPageElement.props.routePath}/${routePath}`;
          const prefix = `/${parentPageElement.props.routePath}`;
          return {
            routePrefix: basePrefix,
            viewRoutePrefix: parentIsFolder ? basePrefix : prefix,
            baseRoutePrefix: basePrefix,
          };
        }

        const route = routePath ? `/${routePath}` : match.path;
        return {
          routePrefix: route,
          viewRoutePrefix: route,
        };
      }, [match.path, parentPageElement, routePath]);

      const premiumLayoutsEnabled = useIsFeatureEnabled(PREMIUM_LAYOUTS);
      const layout = useMemo(
        () => verifyIfPremium(viewLayout, premiumLayoutsEnabled),
        [premiumLayoutsEnabled, viewLayout],
      );

      const isSingleRecordLayout = layout === SINGLE_RECORD;
      const isCalendarLayout = layout === CALENDAR;
      const isTimelineOrGanttLayout = layout === TIMELINE || layout === GANTT;
      const isMapLayout = layout === MAP;
      const isSplitLayout = layout === SPLIT;
      const isFullScreenLayout =
        isCalendarLayout ||
        isTimelineOrGanttLayout ||
        isMapLayout ||
        layout === ROWS ||
        layout === TABLE ||
        layout === TABLE_FULL ||
        (layout === BOARD && groupBy) ||
        isSplitLayout;

      const mainCollection = (
        <div
          className={classNames(
            'w-full flex flex-col',
            {
              relative:
                layout !== TABLE_FULL &&
                layout !== BOARD &&
                !isCalendarLayout &&
                !isMapLayout,
              'max-w-screen-lg':
                layout === CARDS ||
                layout === ROWS ||
                layout === COLUMNS ||
                layout === CHARTS,
              'max-w-screen-xl px-4 sm:px-2': layout === TABLE,
              'overflow-hidden absolute inset-0':
                isFullScreenLayout && !inOnboarding,
              [`${
                isDarkModeEnabled
                  ? `${darkModeColors.surfaces.elevation0} ${darkModeColors.borders.one}`
                  : 'bg-white border border-gray-200'
              } h-full`]: isSplitLayout,
              'mt-8 mb-6 sm:mt-3 sm:mb-3': hideBreadcrumbs,
            },
            `view-${element.id}`,
          )}
          data-testid="view-collection"
        >
          {!hideBreadcrumbs && !inOnboarding && !isSingleRecordLayout && (
            <ViewBreadcrumbs
              className={classNames(
                'flex mt-8 mb-6 sm:mt-3 sm:mb-3 sm:text-xs',
                layout === BOARD || layout === TABLE_FULL || isMapLayout
                  ? 'mx-8 sm:mx-4'
                  : 'mx-4',
              )}
              additionalLinks={
                parentPageElement
                  ? [
                      {
                        to: `/${routePath}`,
                        name,
                      },
                    ]
                  : []
              }
              icon={
                parentPageElement ? get(parentPageElement, 'props.icon') : icon
              }
              name={
                parentPageElement ? get(parentPageElement, 'props.name') : name
              }
              rootPathname={routePrefix}
            />
          )}
          <ViewCollection
            actionButtons={actionButtons}
            calendarView={calendarView}
            charts={charts}
            className={classNames(className, 'mt-0', {
              'mx-4 pb-16': !isFullScreenLayout,
              'mx-4': layout === ROWS,
              'flex flex-col flex-grow overflow-hidden': isFullScreenLayout,
            })}
            coverPhoto={coverPhoto}
            dataList={dataList}
            fields={fields}
            formatRecordScope={formatRecordScope}
            layout={layout}
            editorMode={editorMode}
            elementPath={elementPath}
            exportButton={exportButton}
            dateStart={dateStart}
            dateEnd={dateEnd}
            ganttDependency={ganttDependency}
            groups={groups}
            groupBy={groupBy}
            groupBySort={groupBySort}
            groupOptions={groupOptions}
            hideEmptyGroups={hideEmptyGroups}
            hideNewButton={hideNewButton}
            importButton={importButton}
            limitPerGroup={limitPerGroup}
            icon={icon}
            map={map}
            name={name}
            newButton={newButton}
            newButtonText={newButtonText}
            newLink={newLink}
            sidebarSize={sidebarSize}
            subtitle={subtitle}
            title={title}
            scope={scope}
            onClick={onClick}
            emptyState={emptyState}
            filters={filters}
            record={record}
            recordTitle={recordTitle}
            rootPathname={routePrefix}
            rowLink={rowLink}
            search={search}
            useUpdateProperty={useUpdateProperty}
            project={project}
            viewId={element.id}
            viewRootPathname={viewRoutePrefix}
            /* Not all views have a enableDragAndDropEdit prop, we want to default to drag and drop enabled if they don't */
            enableDragAndDropEdit={enableDragAndDropEdit ?? true}
            track={true}
          />
          {editorMode && !isSelected && (
            <div
              className="absolute inset-x-0 inset-y-0"
              onClick={handleOnClickOverlay}
            />
          )}
        </div>
      );

      const recordView = (
        <RecordView
          {...record}
          className={classNames({
            'overflow-y-auto': isSplitLayout,
          })}
          onClick={onClick}
          dataType={dataType}
          element={element}
          elementPath={elementPath}
          icon={icon}
          name={name}
          permissions={permissions}
          project={project}
          rootPathname={routePrefix}
          scope={scope}
          isSplitLayout={isSplitLayout}
          useUpdateProperty={useUpdateProperty}
          ref={ref}
        />
      );

      return (
        <Route path={routePrefix}>
          <Switch>
            {permissions.create &&
              (!parentPageElement || parentPageElement.type === FOLDER) && (
                <Route path={`${viewRoutePrefix}/new`}>
                  <NewForm
                    {...newProps}
                    onClick={onClick}
                    icon={icon}
                    name={name}
                    dataType={dataType}
                    elementPath={elementPath}
                    project={project}
                    ref={ref}
                    rootPathname={routePrefix}
                    viewRootPathname={viewRoutePrefix}
                    showBreadcrumbs={true}
                    redirectOnSuccess={true}
                    hideFormOnSuccess={false}
                    successMessage={EMPTY_SUCCESS_MESSAGE}
                  />
                </Route>
              )}
            {(!parentPageElement || parentPageElement.type === FOLDER) &&
              !isSplitLayout && (
                <Route path={`${viewRoutePrefix}/view/:recordId/:tab?`}>
                  {recordView}
                </Route>
              )}
            {isSplitLayout && (
              <Route path={`${viewRoutePrefix}/(view)?/:recordId?/:tab?`}>
                <SplitLayout
                  // @ts-expect-error TS(2322): Type '{ mainCollection: Element; sidebarSize: any;... Remove this comment to see the full error message
                  mainCollection={mainCollection}
                  sidebarSize={sidebarSize}
                  recordView={recordView}
                  routePrefix={routePrefix}
                />
              </Route>
            )}
            {!isSplitLayout && <Route>{mainCollection}</Route>}
          </Switch>
        </Route>
      );
    },
  ),
);

// @ts-expect-error TS(2339): Property 'dataList' does not exist on type '{}'.
const ViewWrapper = memo(({ dataList, editorMode, project, ...rest }) => {
  const {
    // @ts-expect-error TS(2339): Property '__onboardingModal' does not exist on type '{}... Remove this comment to see the full error message
    query: { __onboardingModal = 'false' },
    replaceQueryParams,
  } = useRouter();
  const errorAlert = useErrorAlert();
  const successAlert = useSuccessAlert();
  const removeAllAlerts = useRemoveAllAlerts();

  const dataType = useMemo(
    () => project.dataTypes.getByName(dataList.dataType),
    [dataList.dataType, project.dataTypes],
  );

  const handleConnectionChange = useCallback(
    (isOnline) => {
      removeAllAlerts();

      if (isOnline) {
        successAlert(getText(LANG_KEY, 'online.title'));
      } else {
        errorAlert(
          getText(LANG_KEY, 'offline.title'),
          getText(LANG_KEY, 'offline.subtitle'),
          { timeout: 0 },
        );
      }
    },
    [errorAlert, removeAllAlerts, successAlert],
  );

  useIsWindowOnline(handleConnectionChange);

  if (dataType) {
    return (
      <>
        <View
          // @ts-expect-error TS(2322): Type '{ dataType: any; dataList: any; editorMode: ... Remove this comment to see the full error message
          dataType={dataType}
          dataList={dataList}
          editorMode={editorMode}
          project={project}
          {...rest}
        />
        {__onboardingModal === 'true' && (
          <OnboardingModal replaceQueryParams={replaceQueryParams} />
        )}
      </>
    );
  }

  if (editorMode) {
    return (
      <div className="flex items-center justify-center p-8 m-8 bg-white border border-gray-200 rounded-lg shadow-md w-full">
        <div className="w-full py-24 text-center text-gray-600">
          {getText('elements.VIEW.error')}
        </div>
      </div>
    );
  }

  return null;
});

(View as any).propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
};

(View as any).defaultProps = {
  className: '',
};

View.displayName = 'View';

export default ViewWrapper;
