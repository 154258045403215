import React, { useCallback, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import shortid from 'shortid';
import { SECONDARY } from '@noloco/components/src/constants/variants';
import VisibilityRulesWrapper from '../../../components/canvas/VisibilityRulesWrapper';
import { MODAL, ONE_CLICK } from '../../../constants/actionButtons';
import { useActionButtons } from '../../../utils/hooks/useActionButtons';
import useRouter from '../../../utils/hooks/useRouter';
import ActionButtonButton from './ActionButtonButton';

const getButtonsStyles = (isCollection: boolean, isSecondary: boolean) => ({
  ...(isCollection && isSecondary ? { border: [true, 'gray-400'] } : {}),
  ...(isCollection ? { shadow: 'lg' } : {}),
});

const ActionButton = ({
  actionButton,
  buttonType,
  buttonStyles,
  defaultExecution = MODAL,
  disabled,
  editorMode,
  index,
  size,
}: any) => {
  const execution = get(actionButton, 'execution', defaultExecution);

  return (
    <ActionButtonButton
      actionButton={actionButton}
      buttonType={buttonType}
      disabled={disabled}
      editorMode={editorMode}
      index={index}
      isLoading={execution === ONE_CLICK}
      size={size}
      {...buttonStyles}
    />
  );
};

const ActionButtonWrapper = ({
  actionButton,
  buttonType,
  dataType,
  defaultExecution = MODAL,
  editorMode,
  index,
  isCollection,
  project,
  onDeleteRecord,
  record,
  recordScope,
  size,
}: any) => {
  const {
    // @ts-expect-error TS(2339): Property 'button' does not exist on type '{}'.
    query: { button, _recordId },
    pushQueryParams,
  } = useRouter();
  const { activeActionItem, queueAction } = useActionButtons();

  const isSecondary = useMemo(
    () => get(actionButton, 'appearance', SECONDARY) === SECONDARY,
    [actionButton],
  );

  const buttonStyles = useMemo(
    () => getButtonsStyles(isCollection, isSecondary),
    [isCollection, isSecondary],
  );

  const onStart = useCallback(() => {
    queueAction({
      executionId: shortid.generate(),
      executionType: get(actionButton, 'execution', defaultExecution),
      action: actionButton,
      dataType,
      record,
      scope: recordScope,
      onDeleteRecord,
    });
  }, [
    actionButton,
    dataType,
    defaultExecution,
    onDeleteRecord,
    queueAction,
    record,
    recordScope,
  ]);

  useEffect(() => {
    // We still want to support triggering action buttons from the URL
    // because people may rely on that
    const isActiveInUrl =
      !!(
        button &&
        button === actionButton.id &&
        (!isCollection || (record && _recordId === record.uuid))
      ) &&
      (!isCollection || record);

    if (isActiveInUrl) {
      pushQueryParams({
        button: undefined,
        step: undefined,
        ...(isCollection && record ? { _recordId: undefined } : {}),
      });
      onStart();
    }
  }, [
    _recordId,
    actionButton.id,
    button,
    isCollection,
    onStart,
    pushQueryParams,
    record,
  ]);

  const isActive = useMemo(
    () =>
      activeActionItem &&
      activeActionItem.action.id === actionButton.id &&
      (!activeActionItem.record ||
        !record ||
        activeActionItem.record.uuid === record.uuid),
    [actionButton.id, activeActionItem, record],
  );

  if (!isActive) {
    return (
      <VisibilityRulesWrapper
        editorMode={editorMode}
        project={project}
        visibilityRules={actionButton.visibilityRules}
        visibilityRulesScope={recordScope}
      >
        <ActionButtonButton
          actionButton={actionButton}
          buttonType={buttonType}
          disabled={!get(actionButton, ['actions', 0])}
          editorMode={editorMode}
          index={index}
          isLoading={false}
          onClick={onStart}
          size={size}
          {...buttonStyles}
        />
      </VisibilityRulesWrapper>
    );
  }

  return (
    <ActionButton
      actionButton={actionButton}
      buttonType={buttonType}
      buttonStyles={buttonStyles}
      dataType={dataType}
      disabled={isActive}
      defaultExecution={defaultExecution}
      editorMode={editorMode}
      index={index}
      size={size}
    />
  );
};

export default ActionButtonWrapper;
