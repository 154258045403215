import React, { createContext, useContext, useEffect, useMemo } from 'react';
import EventEmitter from 'events';
import debounce from 'lodash/debounce';
import { BASE_WS_URI } from '../../constants/auth';
import { useAuth } from './useAuth';
import useWebsocketWithHeartbeat from './useWebsocketWithHeartbeat';

const useInvalidateProjectDataWebsocket = (
  projectName: string,
  eventEmitter: EventEmitter,
) => {
  const { token } = useAuth();

  const onMessage = useMemo(
    () =>
      debounce(() => eventEmitter.emit('invalidate'), 2000, {
        leading: true,
        trailing: true,
      }),
    [eventEmitter],
  );

  useWebsocketWithHeartbeat(
    `${BASE_WS_URI}/project/${projectName}/data?authToken=${token}`,
    {
      onMessage,
      share: true,
      shouldReconnect: () => true,
      reconnectAttempts: Infinity,
      retryOnError: true,
      onOpen: () => {
        console.info('[Invalidate Project Data Webhook] Status: connected');
      },
      onClose: () => {
        console.info('[Invalidate Project Data Webhook] Status: disconnected');
      },
      onError: (e) => {
        console.error('[Invalidate Project Data Webhook] Error:', e);
      },
    },
    !!token,
  );
};

const invalidateProjectDataContext = createContext<EventEmitter | undefined>(
  undefined,
);

export const ProvideInvalidateProjectData = ({
  children,
  projectName,
}: any) => {
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  eventEmitter.setMaxListeners(30);

  useInvalidateProjectDataWebsocket(projectName, eventEmitter);

  return (
    <invalidateProjectDataContext.Provider value={eventEmitter}>
      {children}
    </invalidateProjectDataContext.Provider>
  );
};

export const useInvalidateProjectData = (onInvalidate: any) => {
  const eventEmitter = useContext(invalidateProjectDataContext);

  useEffect(() => {
    if (eventEmitter && onInvalidate) {
      eventEmitter.on('invalidate', onInvalidate);

      return () => {
        eventEmitter.removeListener('invalidate', onInvalidate);
      };
    }
  }, [eventEmitter, onInvalidate]);
};
