import React, { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import { DateTime } from 'luxon';
import {
  Badge,
  ErrorText,
  FormattedNumberInput,
  InlineFormattedNumberInput,
  RatingInput,
  SelectInput,
  Switch,
  TextArea,
  TextInput,
  getColorShade,
} from '@noloco/components';
import { MD, XS } from '@noloco/components/src/constants/tShirtSizes';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  INTEGER,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import RelationalDataFieldInput from '@noloco/core/src/elements/sections/collections/filters/RelationalDataFieldInput';
import { getText } from '@noloco/core/src/utils/lang';
import {
  isMultiRelationship,
  isReverseMultiRelationship,
} from '@noloco/core/src/utils/relationships';
import DatePicker from '../../../components/DatePicker';
import Dropzone from '../../../components/dropzone/Dropzone';
import { FILE } from '../../../constants/builtInDataTypes';
import TYPES_WITHOUT_MUTATIONS from '../../../constants/dataTypesWithoutMutations';
import {
  CURRENCY,
  DATE as DATE_FORMAT,
  PERCENTAGE,
  RATING,
  SINGLE_LINE_TEXT,
  UNFORMATTED_NUMBER,
} from '../../../constants/fieldFormats';
import {
  CHECKBOX,
  COLORED_OPTIONS,
  MARKDOWN,
  RADIO,
} from '../../../constants/inputTypes';
import { getColorByIndex } from '../../../utils/colors';
import {
  durationToString,
  getDurationFromString,
} from '../../../utils/durations';
import { sortOptions } from '../../../utils/fields';
import {
  formatPercentageForDisplay,
  formatPercentageForInput,
  getFieldPrecision,
} from '../../../utils/numbers';
import { hasNullOption } from '../../../utils/options';
import Checkbox from '../../Checkbox';
import RadioInputGroup from '../../Radio';
import FilePreview from './FilePreview';
import RichTextInput from './RichTextInput';

const stopPropagation = (event: any) => event.stopPropagation();

const formatDateValue = (dateValue: any, format: any) => {
  if (format === DATE_FORMAT) {
    return dateValue.toUTC().set({ hour: 0, minute: 0, second: 0 }).toISO();
  }

  return dateValue.toUTC().toISO();
};

const formatTextInputOnBlur = (fieldType: any, value: any) => {
  if (fieldType === DURATION) {
    const duration = getDurationFromString(value);
    return !isNil(duration) && duration.isValid
      ? durationToString(duration)
      : '';
  }

  return value;
};

const formatTextInput = (fieldType: any, value: any, typeOptions = {}) => {
  if (fieldType === TEXT) {
    return value;
  }

  if (!value) {
    return value;
  }

  let nextValue = value;

  // @ts-expect-error TS(2339): Property 'format' does not exist on type '{}'.
  const { format } = typeOptions;
  const fieldPrecision = getFieldPrecision(fieldType, typeOptions);

  if (format === PERCENTAGE) {
    nextValue = formatPercentageForInput(nextValue, fieldPrecision);
  }

  return nextValue;
};

const formatNumberValue = (value: any, type: any, typeOptions = {}) => {
  if (isNil(value) || value === '') {
    return value;
  }

  // @ts-expect-error TS(2339): Property 'format' does not exist on type '{}'.
  const { format } = typeOptions;
  const fieldPrecision = getFieldPrecision(type, typeOptions);

  if (format === PERCENTAGE) {
    return formatPercentageForDisplay(value, fieldPrecision);
  }

  if (isUndefined(fieldPrecision)) {
    return value;
  }

  return value.toFixed(fieldPrecision);
};

const DataFieldInput = memo(
  ({
    // @ts-expect-error TS(2339): Property 'allowNewRecords' does not exist on type ... Remove this comment to see the full error message
    allowNewRecords = false,
    // @ts-expect-error TS(2339): Property 'onlyAllowNewRecords' does not exist on t... Remove this comment to see the full error message
    onlyAllowNewRecords = false,
    // @ts-expect-error TS(2339): Property 'autoFocus' does not exist on type '{}'.
    autoFocus,
    // @ts-expect-error TS(2339): Property 'authQuery' does not exist on type '{}'.
    authQuery,
    // @ts-expect-error TS(2339): Property 'canEdit' does not exist on type '{}'.
    canEdit,
    // @ts-expect-error TS(2339): Property 'customFilters' does not exist on type '{... Remove this comment to see the full error message
    customFilters,
    // @ts-expect-error TS(2339): Property 'collectionFilter' does not exist on type... Remove this comment to see the full error message
    collectionFilter,
    // @ts-expect-error TS(2339): Property 'dataTypes' does not exist on type '{}'.
    dataTypes,
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type '{}'.
    disabled,
    // @ts-expect-error TS(2339): Property 'field' does not exist on type '{}'.
    field,
    // @ts-expect-error TS(2339): Property 'placeholder' does not exist on type '{}'... Remove this comment to see the full error message
    placeholder,
    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
    id,
    // @ts-expect-error TS(2339): Property 'inline' does not exist on type '{}'.
    inline,
    // @ts-expect-error TS(2339): Property 'inputType' does not exist on type '{}'.
    inputType,
    // @ts-expect-error TS(2339): Property 'newRecordFormFields' does not exist on t... Remove this comment to see the full error message
    newRecordFormFields,
    // @ts-expect-error TS(2339): Property 'newRecordFormTitle' does not exist on ty... Remove this comment to see the full error message
    newRecordFormTitle,
    // @ts-expect-error TS(2339): Property 'orderBy' does not exist on type '{}'.
    orderBy,
    // @ts-expect-error TS(2339): Property 'onBlur' does not exist on type '{}'.
    onBlur,
    // @ts-expect-error TS(2339): Property 'onChange' does not exist on type '{}'.
    onChange,
    // @ts-expect-error TS(2339): Property 'onRemoveFile' does not exist on type '{}... Remove this comment to see the full error message
    onRemoveFile,
    // @ts-expect-error TS(2339): Property 'projectName' does not exist on type '{}'... Remove this comment to see the full error message
    projectName,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
    project,
    // @ts-expect-error TS(2339): Property 'selectMultiple' does not exist on type '... Remove this comment to see the full error message
    selectMultiple,
    // @ts-expect-error TS(2339): Property 'surface' does not exist on type '{}'.
    surface,
    // @ts-expect-error TS(2339): Property 'validationError' does not exist on type ... Remove this comment to see the full error message
    validationError,
    // @ts-expect-error TS(2339): Property 'value' does not exist on type '{}'.
    value,
    // @ts-expect-error TS(2339): Property 'ReadOnlyCell' does not exist on type '{}... Remove this comment to see the full error message
    ReadOnlyCell,
    // @ts-expect-error TS(2339): Property 'required' does not exist  on type '{}... Remove this comment to see the full error message
    required,
    // @ts-expect-error TS(2339): Property 'additionalRelatedFields' does not exist ... Remove this comment to see the full error message
    additionalRelatedFields,
    // @ts-expect-error TS(2339): Property 'canBulkUpdate' does not exist ... Remove this comment to see the full error message
    canBulkUpdate,
    // @ts-expect-error TS(2339): Property 'updateAllRecords' does not exist ... Remove this comment to see the full error message
    updateAllRecords,
    // @ts-expect-error TS(2339): Property 'setUpdateAllRecords' does not exist ... Remove this comment to see the full error message
    setUpdateAllRecords,
  }) => {
    const handleRemoveFile = useCallback(
      (fileId: any) => {
        if (canEdit && onRemoveFile) {
          onRemoveFile(fileId);
          onBlur();
        }
      },
      [canEdit, onBlur, onRemoveFile],
    );

    const footer = useMemo(
      () =>
        canBulkUpdate && (
          <div className="flex items-center justify-between w-full">
            <label
              className={classNames({
                'text-gray-200': surface === 'dark',
                'text-gray-700': surface === 'light',
              })}
            >
              {getText('elements.VIEW.display.bulkActions.updateAll')}
            </label>
            <Switch
              size="sm"
              value={updateAllRecords}
              onChange={(value: boolean) => setUpdateAllRecords(value)}
            />
          </div>
        ),
      [canBulkUpdate, surface, setUpdateAllRecords, updateAllRecords],
    );

    if (canEdit) {
      const onChangeWithOnBlur = (...args: any[]) => {
        onChange(...args, updateAllRecords);
        onBlur();
      };

      const formatOnBlur = (field: any) => (event: any) => {
        const originalValue = String(event.target.value);
        event.target.value = formatTextInputOnBlur(
          field.type,
          event.target.value,
          // @ts-expect-error TS(2554): Expected 2 arguments, but got 3.
          field.typeOptions,
        );
        if (originalValue !== event.target.value) {
          onChange(event.target.value);
        }
        onBlur(event);
      };

      const { format } = field.typeOptions || {};

      if (field.type === BOOLEAN) {
        if (inputType === RADIO && !inline) {
          const booleanOptions = [false, true].map((v) => ({
            value: v,
            label: getText('editor.boolean', v),
          }));
          const name = `${id}-${field.name}-bool-radio`;
          return (
            <RadioInputGroup
              disabled={disabled}
              options={booleanOptions}
              name={name}
              validationError={validationError}
              value={value}
              onChange={onChangeWithOnBlur}
            />
          );
        }

        return (
          <Checkbox
            className={classNames(
              'flex mx-auto',
              { 'my-1': inline },
              { 'my-2 w-full': !inline },
              surface !== 'dark' ? 'text-gray-500' : 'text-gray-900',
            )}
            size="md"
            checked={value}
            disabled={disabled}
            validationError={validationError}
            value={value}
            onClick={stopPropagation}
            onChange={({ target: { checked } }: any) => {
              onChange(checked);
              onBlur();
            }}
            elementId={id}
          />
        );
      }

      if (field.type === DURATION) {
        return (
          <TextInput
            id={id}
            autoFocus={autoFocus}
            disabled={disabled}
            onBlur={formatOnBlur(field)}
            // @ts-expect-error TS(7031): Binding element 'val' implicitly has an 'any' type... Remove this comment to see the full error message
            onChange={({ target: { value: val } }) => onChange(val)}
            validationError={validationError}
            value={value}
            placeholder={placeholder || ''}
            type="text"
            surface={surface}
            {...(inline
              ? { bg: 'transparent', border: false, 'ring-focus': false }
              : {})}
          />
        );
      }

      if (field.type === TEXT) {
        const isMultiline = format !== SINGLE_LINE_TEXT;

        if (isMultiline && inputType === MARKDOWN) {
          return (
            <RichTextInput
              onChange={onChange}
              disabled={disabled}
              value={value}
              placeholder={placeholder || ''}
              surface={surface}
            />
          );
        }

        const rows = String(value || '')
          .split('\n')
          .reduce(
            (rowAcc, line) => rowAcc + 1 + Math.floor(line.length / 40),
            0,
          );
        const TextInputComponent = isMultiline ? TextArea : TextInput;

        return (
          <TextInputComponent
            id={id}
            data-testid={id}
            autoFocus={autoFocus}
            disabled={disabled}
            onBlur={onBlur}
            // @ts-expect-error TS(7031): Binding element 'val' implicitly has an 'any' type... Remove this comment to see the full error message
            onChange={({ target: { value: val } }) =>
              onChange(formatTextInput(field.type, val, field.typeOptions))
            }
            validationError={validationError}
            value={value}
            placeholder={placeholder || ''}
            inline={inline}
            type={isMultiline ? 'textarea' : 'text'}
            minRows={isMultiline && !inline ? 2 : undefined}
            rows={Math.max(rows, 1)}
            surface={surface}
            {...(inline
              ? { bg: 'transparent', border: false, 'ring-focus': false }
              : {})}
          />
        );
      }

      if ([INTEGER, DECIMAL].includes(field.type)) {
        const NumberInputComponent = inline
          ? InlineFormattedNumberInput
          : FormattedNumberInput;

        const numberValue = formatNumberValue(
          Number(value),
          field.type,
          field.typeOptions,
        );

        if (format === RATING) {
          return (
            <>
              <RatingInput
                disabled={disabled}
                maxRating={get(field.typeOptions, 'max')}
                onChange={(val: number | null) => onChange(val)}
                value={numberValue}
              />
              {validationError && (
                <ErrorText className="text-left mt-2">
                  {validationError}
                </ErrorText>
              )}
            </>
          );
        }

        return (
          <NumberInputComponent
            id={id}
            autoFocus={autoFocus}
            disabled={disabled}
            onBlur={onBlur}
            onChange={(val: any) => {
              onChange(formatTextInput(field.type, val, field.typeOptions));
            }}
            validationError={validationError}
            value={!isNil(value) ? Number(numberValue) : null}
            placeholder={placeholder || ''}
            decimalScale={getFieldPrecision(field.type, field.typeOptions)}
            thousandSeparator={
              format === CURRENCY ||
              (format !== UNFORMATTED_NUMBER &&
                !isNil(value) &&
                numberValue > 9999)
            }
            p={!inline ? { x: 3, y: 1.5 } : undefined}
            prefix={
              format === CURRENCY
                ? get(field.typeOptions, 'symbol', '$')
                : undefined
            }
            suffix={format === PERCENTAGE ? '%' : undefined}
            surface={surface}
          />
        );
      }

      if (field.type === DATE) {
        return (
          <DatePicker
            id={id}
            autoFocus={autoFocus}
            disabled={disabled}
            selectTime={inputType !== DATE && format !== DATE_FORMAT}
            {...(inline
              ? {
                  border: 0,
                  bg: 'transparent',
                  p: { x: 0, y: 0, l: 2 },
                  text: ['xs', surface === 'dark' ? 'gray-200' : 'gray-700'],
                  h: 8,
                }
              : {})}
            // @ts-expect-error TS(2322): Type '{ inline: any; validationError: any; value: ... Remove this comment to see the full error message
            inline={inline}
            validationError={validationError}
            value={value ? DateTime.fromISO(value) : null}
            onBlur={onBlur}
            onChange={(val: any) =>
              onChange(val ? formatDateValue(val, format) : null)
            }
            surface={surface}
            placeholder={placeholder || ''}
            timeZone={get(field, 'typeOptions.timeZone')}
            w="full"
          />
        );
      }

      if (field.type === SINGLE_OPTION || field.type === MULTIPLE_OPTION) {
        const nullOption = {
          color: 'gray',
          display: getText('core.SELECT_INPUT.none'),
          name: null,
        };

        const sortedOptions = sortOptions([
          ...(!isNil(value) &&
          hasNullOption({ ...field, required: field.required || required })
            ? [nullOption]
            : []),
          ...field.options,
        ]).map((option) => ({
          label: option.display,
          value: option.name,
          color: option.color,
        }));

        const getOptionLabel = (option: any, index: number) => (
          <Badge
            className="overflow-hidden"
            color={getColorShade(option.color || getColorByIndex(index), 400)}
          >
            {option.label}
          </Badge>
        );

        if (field.type === SINGLE_OPTION && inputType === RADIO && !inline) {
          const name = `${id}-${field.name}-radio`;
          const radioOptions = sortedOptions
            .filter((option) => option.value !== null)
            .map((option, index) => ({
              ...option,
              label: getOptionLabel(option, index),
            }));
          return (
            <RadioInputGroup
              disabled={disabled}
              options={radioOptions}
              name={name}
              validationError={validationError}
              value={value}
              onChange={onChangeWithOnBlur}
            />
          );
        }

        if (
          field.type === MULTIPLE_OPTION &&
          inputType === CHECKBOX &&
          !inline
        ) {
          const name = `${id}-${field.name}-radio`;
          return (
            <div className="flex flex-col space-y-3" role="radiogroup">
              {sortedOptions.map((option, index) => {
                const checked = value && value.includes(option.value);

                const onCheckboxChange = () => {
                  if (checked) {
                    onChangeWithOnBlur(
                      value.filter((v: any) => v !== option.value),
                    );
                  } else if (!value) {
                    onChangeWithOnBlur([option.value]);
                  } else {
                    onChangeWithOnBlur([...value, option.value]);
                  }
                };

                return (
                  <Checkbox
                    key={option.value}
                    disabled={disabled}
                    className="flex items-center text-blue-400 focus:ring-2 default:ring-2 ring-blue-300"
                    id={`${name}-${option.value}`}
                    name={name}
                    aria-checked={checked}
                    validationError={validationError}
                    value={option.value}
                    checked={checked}
                    onChange={onCheckboxChange}
                    tabIndex={
                      value === option.value ||
                      ((isNil(value) || value.length === 0) && index === 0)
                        ? 0
                        : -1
                    }
                  >
                    {getOptionLabel(option, index)}
                  </Checkbox>
                );
              })}
            </div>
          );
        }

        const selectInputOptions = sortedOptions.map((option, index) => ({
          ...option,
          ...(inputType === COLORED_OPTIONS
            ? {
                bg: getColorShade(
                  option.color || getColorByIndex(index),
                  surface === 'dark' ? 700 : 200,
                ),
                label: getOptionLabel(option, index),
              }
            : {}),
        }));

        return (
          <SelectInput
            id={id}
            bg={inline ? 'transparent' : undefined}
            border={inline ? 0 : undefined}
            disabled={disabled}
            inline={inline}
            options={selectInputOptions}
            searchable={true}
            onChange={onChangeWithOnBlur}
            placeholder={
              placeholder ||
              getText(
                { dataType: field.display },
                'core.SELECT_INPUT.placeholder',
              )
            }
            multiple={field.type === MULTIPLE_OPTION}
            surface={surface}
            validationError={validationError}
            size={inline ? XS : MD}
            value={value}
            footer={footer}
            coloredOptionType={inputType === COLORED_OPTIONS}
          />
        );
      }

      if (
        (field.relationship ||
          (field.relatedField &&
            !TYPES_WITHOUT_MUTATIONS.includes(field.type))) &&
        field.type !== FILE
      ) {
        const additionalFields = {
          __args: {
            ...(orderBy ? { orderBy } : {}),
            ...(customFilters ? { where: customFilters } : {}),
          },
          ...(additionalRelatedFields[field.name] ?? {}),
        };

        return (
          <RelationalDataFieldInput
            id={id}
            additionalFields={additionalFields}
            authQuery={authQuery}
            autoHydrateValue={true}
            allowNewRecords={allowNewRecords}
            onlyAllowNewRecords={onlyAllowNewRecords}
            disabled={disabled}
            newRecordFormFields={newRecordFormFields}
            newRecordFormTitle={newRecordFormTitle}
            field={field}
            filter={collectionFilter}
            dataTypes={dataTypes}
            inline={inline}
            inputType={inputType}
            multiple={
              isMultiRelationship(field.relationship) ||
              (field.relatedField &&
                isReverseMultiRelationship(field.relatedField.relationship)) ||
              selectMultiple
            }
            projectName={projectName}
            placeholder={placeholder}
            value={value}
            validationError={validationError}
            onChange={onChangeWithOnBlur}
            isPlayground={false}
            surface={surface}
            project={project}
            footer={footer}
          />
        );
      }

      if (field.type === FILE) {
        const isMultiFile = isMultiRelationship(field.relationship);
        return (
          <>
            <Dropzone
              id={id}
              className={'mb-2'}
              disabled={disabled}
              onChange={onChangeWithOnBlur}
              maxFiles={isMultiFile ? 40 : 1}
              p={3}
              surface={surface}
              validationError={validationError}
            >
              <FilePreview
                // @ts-expect-error TS(2322): Type '{ id: any; files: any; placeholder: any; isM... Remove this comment to see the full error message
                id={field.id}
                files={
                  isMultiFile
                    ? get(value, 'edges', []).map((edge: any) => edge.node)
                    : [value]
                }
                placeholder={placeholder}
                isMultiple={isMultiFile}
                readOnly={false}
                surface={surface}
                onChange={onChangeWithOnBlur}
                onRemove={handleRemoveFile}
              />
            </Dropzone>
            {validationError && <ErrorText>{validationError}</ErrorText>}
          </>
        );
      }
    }

    return (
      <div className="rounded-lg overflow-x-auto py-1.5">
        <ReadOnlyCell
          id={id}
          dataTypes={dataTypes}
          field={field}
          inline={inline}
          projectName={projectName}
          value={value}
          project={project}
          surface={surface}
        />
      </div>
    );
  },
);

(DataFieldInput as any).defaultProps = {
  onBlur: () => null,
  surface: 'dark',
  additionalRelatedFields: {},
};

DataFieldInput.displayName = 'DataFieldInput';

export default DataFieldInput;
