import React from 'react';
import { IconAlertTriangle } from '@tabler/icons-react';
import { getText } from '@noloco/core/src/utils/lang';

const ProjectErrorMessage = () => (
  <div className="w-full bg-gray-100 h-screen flex items-center justify-center text-gray-800 p-8">
    <IconAlertTriangle size={48} />
    <div className="flex flex-col ml-6">
      <h1 className="text-lg">{getText('errors.errorBoundary.title')}</h1>
      <p className="text-gray-600">
        {getText('errors.errorBoundary.subtitle')}
      </p>
    </div>
  </div>
);

export default ProjectErrorMessage;
