import React, { useCallback } from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Tooltip } from '@noloco/components';
import MarkdownText from '../../../components/MarkdownText';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import CollectionFilter from './CollectionFilter';

const HelpTextTooltip = ({
  placement,
  helpText,
}: {
  placement: 'top' | 'right';
  helpText: string;
}) => {
  const [isDarkModeEnabled] = useDarkMode();
  return (
    <Tooltip
      delayShow={400}
      content={
        <span className={`${isDarkModeEnabled ? 'text-white' : ''}`}>
          <MarkdownText small={true} markdownText={helpText} />
        </span>
      }
      placement={placement}
    >
      <span className={`${placement === 'right' ? 'pl-1 mt-1.5' : 'pl-1'} `}>
        <IconInfoCircle
          size={placement === 'right' ? 20 : 18}
          className="text-gray-500"
        />
      </span>
    </Tooltip>
  );
};

const CollectionFilterWrapper = ({
  className,
  config,
  field,
  project,
  updateFilterValue,
  value,
}: any) => {
  const onChange = useCallback(
    (newValue: any) => updateFilterValue(field, newValue, config),
    [field, updateFilterValue, config],
  );

  const shouldShowLabel =
    config.label && !config.label.hidden && config.label.value;

  const helpText = get(config, 'helpText');
  const shouldShowHelpText = helpText !== undefined;

  return (
    <div
      className={classNames(className, 'mx-2')}
      data-testid="collection-filters"
    >
      {shouldShowLabel && (
        <div className="flex w-full pb-1">
          <label className="text-gray-400 font-medium uppercase text-xs pt-0.5">
            {config.label.value}
          </label>
          {shouldShowHelpText && (
            <HelpTextTooltip placement="top" helpText={helpText} />
          )}
        </div>
      )}
      <div className="flex sm:w-full">
        <CollectionFilter
          project={project}
          filter={config}
          value={value}
          field={field}
          onChange={onChange}
        />
        {!shouldShowLabel && shouldShowHelpText && (
          <HelpTextTooltip placement="right" helpText={helpText} />
        )}
      </div>
    </div>
  );
};

export default CollectionFilterWrapper;
