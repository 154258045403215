import { useCallback, useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import fpGet from 'lodash/fp/get';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { DATE, DECIMAL, DURATION, INTEGER, TEXT } from '../constants/dataTypes';
import { DataField } from '../models/DataTypeFields';
import { clearFieldValues, setFieldValue } from '../reducers/formFields';
import debounceByFirstArg from './debounceByFirstArg';
import throttleByFirstArg from './throttleByFirstArg';

const formFieldSelector = (state: Record<any, any>) => state.formFields;

const NEW_ID = 'NEW';

export const DEBOUNCED_TYPES = [TEXT, DURATION, DECIMAL, INTEGER, DATE];

const getDebounceKey = fpGet('id');

const useFormFieldsState = (
  dataTypeName: string,
  recordId: string | number | undefined,
  debounceUpdates?: boolean,
) => {
  const id = recordId || NEW_ID;
  const dispatch = useDispatch();

  const recordSelector = useMemo(
    () =>
      createSelector([formFieldSelector], (formFields) =>
        get(formFields, [dataTypeName, id]),
      ),
    [dataTypeName, id],
  );

  const valuesSelector = useMemo(
    () => createSelector([recordSelector], (record) => get(record, 'values')),
    [recordSelector],
  );

  const formState = useSelector(valuesSelector);
  const setFormFieldValue = useCallback(
    (
      field: DataField,
      value: any,
      callback?: (field: DataField, value: any) => any,
    ) => {
      dispatch(
        setFieldValue({ dataTypeName, id, fieldName: field.apiName, value }),
      );

      if (callback) {
        callback(field, value);
      }
    },
    [dataTypeName, dispatch, id],
  );

  const clearFormFieldValues = useCallback(
    (fieldNames: string[], updateTime: number) => {
      dispatch(clearFieldValues({ dataTypeName, id, fieldNames, updateTime }));
    },
    [dataTypeName, dispatch, id],
  );

  const debouncedSetFormFieldValue = useMemo(
    () =>
      debounceByFirstArg(
        setFormFieldValue,
        1000,
        { trailing: true },
        getDebounceKey,
      ),
    [setFormFieldValue],
  );

  const throttleSetFormFieldValue = useMemo(
    () =>
      throttleByFirstArg(
        setFormFieldValue,
        400,
        { leading: true },
        getDebounceKey,
      ),
    [setFormFieldValue],
  );

  const onSetFormValue = useCallback(
    (
      field: DataField,
      value: any,
      callback?: (field: DataField, value: any) => any,
    ) => {
      if (DEBOUNCED_TYPES.includes(field.type) && debounceUpdates) {
        debouncedSetFormFieldValue(field, value, callback);
        // Write to redux without the callback that triggers a network save
        throttleSetFormFieldValue(field, value);
      } else {
        setFormFieldValue(field, value, callback);
      }
    },
    [
      debounceUpdates,
      debouncedSetFormFieldValue,
      setFormFieldValue,
      throttleSetFormFieldValue,
    ],
  );

  return [formState, onSetFormValue, clearFormFieldValues];
};

export default useFormFieldsState;
