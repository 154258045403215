import React, { useCallback, useMemo, useState } from 'react';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import {
  AutoSizedTextInput,
  Badge,
  Switch,
  TextArea,
} from '@noloco/components';
import { DATE, TEXT } from '@noloco/core/src/constants/dataTypes';
import NewForm from '@noloco/core/src/elements/sections/view/NewForm';
import { Project } from '@noloco/core/src/models/Project';
import { CollectionField } from '@noloco/core/src/models/View';
import { WEBSITE_EXAMPLES, trackEvent } from '@noloco/core/src/utils/analytics';
import useSetBaseScope from '@noloco/core/src/utils/hooks/useSetBaseScope';
import { getText } from '@noloco/core/src/utils/lang';
import BaseExample from '../BaseExample';
import FieldItemEditor from '../FieldItemEditor';

const LANG_KEY = 'websiteExamples.forms';

const EMPTY_SUCCESS_MESSAGE = { message: '', icon: '' };

const FIELD_TO_EDIT = 'projectLead';

const CollectionFormExample = ({ project }: { project: Project }) => {
  useSetBaseScope(project.dataTypes);

  const [fieldLabel, setFieldLabel] = useState('Lead');
  const [showField, setShowField] = useState(true);
  const [requiredField, setRequiredField] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [helpText, setHelpText] = useState('');

  const onToggleShowField = useCallback((show: boolean) => {
    setShowField(show);
    trackEvent(WEBSITE_EXAMPLES.FORM_FIELD_TOGGLED, 'show', show);
  }, []);

  const onChangeFieldLabel = useCallback((label: string) => {
    setFieldLabel(label);
    trackEvent(WEBSITE_EXAMPLES.FORM_LABEL_CHANGED);
  }, []);

  const onChangeRequired = useCallback((required: boolean) => {
    setRequiredField(required);
    trackEvent(WEBSITE_EXAMPLES.FORM_REQUIRED_TOGGLED, 'value', required);
  }, []);

  const element = project.elements[0];
  const dataType = project.dataTypes.getByName(element.props.dataList.dataType);

  const {
    name,
    new: { fields },
  } = element.props;

  const formFields = useMemo(() => {
    if (!showField) {
      return fields.filter(
        (field: CollectionField) => field.name !== FIELD_TO_EDIT,
      );
    }

    return fields.map((field: CollectionField) => {
      if (field.name !== FIELD_TO_EDIT) {
        return field;
      }

      return {
        ...field,
        label: fieldLabel,
        required: requiredField,
        ...(helpText ? { helpText: [{ text: helpText }] } : {}),
        ...(filterValue
          ? {
              customFilters: [
                {
                  field: 'email',
                  operator: 'CONTAINS',
                  result: [
                    {
                      text: filterValue,
                    },
                  ],
                  id: 'za2oSukcu',
                },
              ],
            }
          : {}),
      };
    });
  }, [fieldLabel, fields, filterValue, helpText, requiredField, showField]);

  return (
    <BaseExample showGradient={true}>
      <div className="flex md:flex-col items-center">
        <div className="flex flex-col justify-center items-center mb-8 flex-shrink-0 w-2/5 md:w-full space-y-3 px-4">
          <div className="flex flex-col">
            <h2 className="text-3xl font-medium mb-3">
              {getText(LANG_KEY, 'title')}
            </h2>
            <p className="text-base mb-8">{getText(LANG_KEY, 'subtitle')}</p>
          </div>
          <FieldItemEditor
            fieldName="Status"
            type={TEXT}
            enabled={true}
            disabled={true}
          />
          <FieldItemEditor
            fieldName={fieldLabel}
            type="lead"
            enabled={showField}
            disabled={false}
            onChangeLabel={onChangeFieldLabel}
            onChangeSwitch={onToggleShowField}
          >
            {showField && (
              <div className="flex flex-col pl-6 mt-3 space-y-3">
                <div className="flex items-center justify-between">
                  <span className="text-xs font-medium">Required</span>
                  <Switch
                    size="sm"
                    value={requiredField}
                    onChange={onChangeRequired}
                  />
                </div>
                <div className="flex flex-col md:hidden">
                  <span className="text-xs font-medium">Help Text</span>
                  <TextArea
                    className="w-full mt-2"
                    p={1}
                    value={helpText}
                    size="sm"
                    type="textarea"
                    rows={2}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) =>
                      setHelpText(value)
                    }
                    placeholder=""
                  />
                </div>
                <div className="flex flex-col justify-between md:hidden">
                  <span className="text-xs font-medium">Filter</span>
                  <div className="flex w-full items-center space-x-2 text-xs">
                    <Badge
                      className="flex-shrink-0 space-x-2 flex items-center"
                      color="pink"
                      m={0}
                    >
                      <span>Lead</span>
                      <IconArrowNarrowRight size={14} />
                      <span>Email</span>
                    </Badge>
                    <span>contains</span>
                    <div className="p-1 rounded-lg bg-gray-100">
                      <AutoSizedTextInput
                        p={1}
                        value={filterValue}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) =>
                          setFilterValue(value)
                        }
                        placeholder="Filter..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </FieldItemEditor>
          <FieldItemEditor
            className="md:hidden"
            fieldName="Notes"
            type={TEXT}
            enabled={false}
            disabled={true}
          />
          <FieldItemEditor
            className="md:hidden"
            fieldName="Start Date"
            type={DATE}
            enabled={true}
            disabled={true}
          />
        </div>
        <NewForm
          // @ts-expect-error new form has no typing
          fields={formFields}
          title="Add a new Project"
          onClick={undefined}
          icon={null}
          name={name}
          dataType={dataType}
          elementPath={[0]}
          project={project}
          rootPathname="#"
          viewRootPathname="#"
          showBreadcrumbs={false}
          redirectOnSuccess={false}
          hideFormOnSuccess={true}
          successMessage={EMPTY_SUCCESS_MESSAGE}
        />
      </div>
    </BaseExample>
  );
};

export default CollectionFormExample;
