import React, { useState } from 'react';
import { Loader, Modal } from '@noloco/components';
import { CREATE, UPDATE } from '../../../constants/actionTypes';
import { getText } from '../../../utils/lang';
import FormSection from '../FormSection';

type Props = {};

const DataItemFormModal = ({
  // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
  className,
  // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type 'Prop... Remove this comment to see the full error message
  editorMode,
  // @ts-expect-error TS(2339): Property 'formFields' does not exist on type 'Prop... Remove this comment to see the full error message
  formFields,
  // @ts-expect-error TS(2339): Property 'formSections' does not exist on type 'Prop... Remove this comment to see the full error message
  formSections,
  // @ts-expect-error TS(2339): Property 'id' does not exist on type 'Props'.
  id,
  // @ts-expect-error TS(2339): Property 'onAddDataItem' does not exist on type 'P... Remove this comment to see the full error message
  onAddDataItem,
  // @ts-expect-error TS(2339): Property 'onClose' does not exist on type 'Props'.
  onClose,
  // @ts-expect-error TS(2339): Property 'dataType' does not exist on type 'Props'... Remove this comment to see the full error message
  dataType,
  // @ts-expect-error TS(2339): Property 'itemId' does not exist on type 'Props'.
  itemId,
  // @ts-expect-error TS(2339): Property 'project' does not exist on type 'Props'.
  project,
  // @ts-expect-error TS(2339): Property 'title' does not exist on type 'Props'.
  title,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal
      className={className}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() => {
        const form = document.querySelector(`.element-${id}-form form`);
        if (form) {
          form.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true }),
          );
        }
      }}
      title={
        title ||
        getText(
          { dataType: dataType.display },
          'core.COLLECTION.form',
          itemId ? 'edit' : 'new',
        )
      }
      canCancel={!isLoading}
      confirmDisabled={isLoading}
      confirmText={
        isLoading ? <Loader size="sm" /> : getText('core.COLLECTION.form.save')
      }
      variant="primary"
    >
      <FormSection
        className={`element-${id}-form`}
        dataType={dataType.name}
        fields={formFields}
        sections={formSections}
        editorMode={editorMode}
        type={itemId ? UPDATE : CREATE}
        which={itemId}
        onLoadingChange={setIsLoading}
        onSuccess={onAddDataItem}
        successMessage={{
          message: getText(
            { dataType: dataType.display },
            'core.COLLECTION.form.success',
          ),
        }}
        errorMessage={{
          message: getText(
            { dataType: dataType.display },
            'core.COLLECTION.form.error',
          ),
        }}
        project={project}
      />
    </Modal>
  );
};

export default DataItemFormModal;
