import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconCheck, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import { useDispatch } from 'react-redux';
import {
  Loader,
  Popover,
  Surface,
  Switch,
  Theme,
  getColorShade,
} from '@noloco/components';
import { DECIMAL, DURATION, INTEGER } from '../../../constants/dataTypes';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import { Project } from '../../../models/Project';
import { BaseRecord } from '../../../models/Record';
import { FormConfigWithField } from '../../../models/View';
import { clearFieldValues } from '../../../reducers/formFields';
import { useAutoForm } from '../../../utils/hooks/useAutoForm';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useOnKeyPress from '../../../utils/hooks/useOnKeyPress';
import usePrevious from '../../../utils/hooks/usePrevious';
import { getText } from '../../../utils/lang';
import { DEBOUNCED_TYPES } from '../../../utils/useFormFieldsState';

type Props = {
  className?: string;
  dataType: DataType;
  elementId: string;
  field: DataField;
  onClick?: () => void;
  onLoadingChange?: (nextLoading: boolean) => void;
  project: Project;
  scope: Record<string, any>;
  surface: Surface;
  ReadOnlyCell: any;
  theme: Theme;
  bulkActionsEnabled?: boolean;
  isRowChecked?: boolean;
  selectedRows?: BaseRecord[];
};

const InlineAutoForm: React.FunctionComponent<Props> = memo(
  ({
    className,
    dataType,
    elementId,
    field,
    onClick,
    onLoadingChange,
    project,
    scope,
    surface,
    ReadOnlyCell,
    theme,
    bulkActionsEnabled,
    isRowChecked,
    selectedRows,
  }) => {
    const ref = useRef();
    const dispatch = useDispatch();
    const [isDarkModeEnabled] = useDarkMode();
    const [popoverIsOpen, setPopoverIsOpen] = useState(false);
    const [updateAllRecords, setUpdateAllRecords] = useState(false);

    const requiresPopover = field && DEBOUNCED_TYPES.includes(field.type);

    const onCancel = useCallback(
      (event) => {
        event.stopPropagation();
        event.preventDefault();
        dispatch(
          clearFieldValues({
            dataTypeName: dataType.name,
            id: get(scope, [elementId, 'edges', 'node', 'id']),
            fieldNames: [field.name],
            updateTime: Date.now(),
          }),
        );
        setPopoverIsOpen(false);
      },
      [dataType.name, dispatch, elementId, field.name, scope],
    );

    const onSuccess = useCallback(() => {
      setPopoverIsOpen(false);
    }, []);

    useOnKeyPress('Escape', onCancel, {
      enabled: popoverIsOpen && requiresPopover,
    });

    const onFocus = useCallback(
      (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (onClick) {
          onClick();
        }

        if (requiresPopover) {
          setPopoverIsOpen(true);
        }
      },
      [onClick, requiresPopover],
    );

    const {
      isLoading,
      formFields,
      hasChangedMap,
      onSubmit,
      renderFormField,
    } = useAutoForm();

    const previousLoading = usePrevious(isLoading);
    useEffect(() => {
      if (
        onLoadingChange &&
        previousLoading !== undefined &&
        previousLoading !== isLoading
      ) {
        onLoadingChange(isLoading);
      }
    }, [onLoadingChange, isLoading, previousLoading]);

    const configWithField = useMemo<FormConfigWithField | undefined>(
      () => formFields.find((formField) => formField.field.name === field.name),
      [field.name, formFields],
    );

    const canBulkUpdate = useMemo(
      () =>
        bulkActionsEnabled &&
        isRowChecked &&
        selectedRows &&
        selectedRows.length > 1,
      [bulkActionsEnabled, isRowChecked, selectedRows],
    );

    const onClickSubmit = useCallback(
      (event: any) =>
        onSubmit(event, updateAllRecords).then(() => {
          onSuccess();
        }),
      [onSubmit, onSuccess, updateAllRecords],
    );

    const onUpdateAllRecords = useCallback(
      (event) => {
        event.stopPropagation();
        event.preventDefault();

        setUpdateAllRecords(!updateAllRecords);
      },
      [updateAllRecords],
    );

    useOnKeyPress('u', onUpdateAllRecords, {
      ctrlKey: true,
      enabled: requiresPopover ? popoverIsOpen && canBulkUpdate : canBulkUpdate,
    });

    if (!configWithField) {
      console.error(
        `Config for field ${field.name} not found - have you setup an AutoFormProvider ?`,
      );
    }

    const primaryColor = theme.brandColors.primary;

    const disabled = useMemo(
      () => Object.values(hasChangedMap).filter(Boolean).length === 0,
      [hasChangedMap],
    );

    const form = useMemo<any>(
      () =>
        configWithField &&
        field && (
          <form
            className="w-full max-h-full overflow-x-hidden p-px flex items-start max-w-full"
            onSubmit={onClickSubmit}
          >
            <div
              className={classNames('w-full rounded-lg', {
                'bg-gray-100': requiresPopover && !isDarkModeEnabled,
                'bg-gray-800': requiresPopover && isDarkModeEnabled,
              })}
            >
              {renderFormField(
                configWithField,
                updateAllRecords,
                setUpdateAllRecords,
              )}
            </div>
            {requiresPopover && (
              <div className="ml-3 flex items-center space-x-1 mt-1">
                <button
                  className={classNames(
                    'rounded-full text-gray-400 p-1',
                    `hover:text-${getColorShade(primaryColor, 500)}`,
                  )}
                  type="button"
                  disabled={isLoading}
                  onClick={onCancel}
                >
                  <IconX size={16} />
                </button>
                <button
                  className={classNames(
                    'rounded-full p-1',
                    isLoading
                      ? `text-${getColorShade(primaryColor, 500)}`
                      : 'text-white',
                    {
                      [`bg-${getColorShade(primaryColor, 500)}`]: !isLoading,
                      [`hover:bg-${getColorShade(
                        primaryColor,
                        600,
                      )}`]: !isLoading,
                    },
                  )}
                  disabled={isLoading}
                  onClick={disabled ? onCancel : undefined}
                  type="submit"
                >
                  {isLoading ? <Loader size="xs" /> : <IconCheck size={16} />}
                </button>
              </div>
            )}
          </form>
        ),
      [
        configWithField,
        disabled,
        field,
        isLoading,
        onCancel,
        onClickSubmit,
        primaryColor,
        renderFormField,
        requiresPopover,
        isDarkModeEnabled,
        updateAllRecords,
      ],
    );

    const value = useMemo(
      () => get(scope, [elementId, 'edges', 'node', field.apiName]),
      [elementId, field.apiName, scope],
    );

    if (!requiresPopover) {
      return form;
    }

    return (
      <div
        className={classNames(
          className,
          'flex flex-shrink-0 overflow-x-hidden',
          {
            'justify-end': [DECIMAL, INTEGER, DURATION].includes(
              get(field, 'type'),
            ),
            'opacity-50': popoverIsOpen,
          },
        )}
        onClick={onFocus}
        // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        ref={ref}
      >
        <Popover
          content={
            <div
              className="flex flex-col text-sm min-w-64 max-w-sm"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex items-center">{form}</div>
              {canBulkUpdate && (
                <>
                  <hr
                    className={classNames('my-3', {
                      'bg-gray-500': !isDarkModeEnabled,
                      'bg-gray-800': isDarkModeEnabled,
                    })}
                  />
                  <div className="flex items-center w-full mr-auto p-1.5">
                    <label
                      className={classNames('mr-4', {
                        'text-gray-200': isDarkModeEnabled,
                        'text-gray-700': !isDarkModeEnabled,
                      })}
                    >
                      {getText('elements.VIEW.display.bulkActions.updateAll')}
                    </label>
                    <Switch
                      size="sm"
                      className="ml-4"
                      value={updateAllRecords}
                      onChange={(value: boolean) => setUpdateAllRecords(value)}
                    />
                  </div>
                </>
              )}
            </div>
          }
          disabled={!configWithField}
          closeOnOutsideClick={true}
          onOpenChange={setPopoverIsOpen}
          isOpen={popoverIsOpen}
          trigger="none"
          offset={[-16, -16]}
          placement="bottom-start"
          showArrow={false}
          onClick={(e: any) => e.stopPropagation()}
          popoverClassName={classNames('sm:max-w-screen border-2 rounded-lg', {
            'border-gray-100': !isDarkModeEnabled,
            'border-gray-600': isDarkModeEnabled,
          })}
          surface={surface}
          {...(surface === 'dark'
            ? { bg: 'brand-darkest', border: [true, 'brand-light'] }
            : {})}
        >
          <div className="w-0 h-full" />
        </Popover>
        {!isNil(value) ? (
          <ReadOnlyCell
            id={`input-${field.name}-${get(scope, [
              elementId,
              'edges',
              'node',
              'id',
            ])}`}
            dataTypes={project.dataTypes}
            field={field}
            projectName={project.name}
            value={value}
            project={project}
            surface={surface}
          />
        ) : (
          <div className="opacity-75">--</div>
        )}
      </div>
    );
  },
);

InlineAutoForm.displayName = 'InlineAutoForm';

(InlineAutoForm as any).defaultProps = {
  inline: true,
  surface: 'light',
  transformRecordScope: identity,
};

export default withTheme(InlineAutoForm) as React.FunctionComponent<
  Omit<Props, 'theme'>
>;
