import { VariableType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import camelCase from 'lodash/camelCase';
import { CREATE, DELETE, INVITE_USER, UPDATE } from '../constants/actionTypes';
import * as dataTypes from '../constants/dataTypes';
import TYPES_WITHOUT_MUTATIONS from '../constants/dataTypesWithoutMutations';
import { QUERY } from '../constants/endpointTypes';
import {
  MANY_TO_MANY,
  MANY_TO_ONE,
  ONE_TO_MANY,
  ONE_TO_ONE,
} from '../constants/relationships';
import { DataField } from '../models/DataTypeFields';
import DataTypes, { DataType } from '../models/DataTypes';
import { Rollup } from '../models/Rollup';
import { getAggregationFieldType } from '../utils/aggregationDataTypes';
import { getApiEndpointQueryName } from '../utils/apis';
import cappedMemoize from '../utils/cappedMemoize';
import { getFieldReverseMutationInputName } from '../utils/fields';
import { isOptionType } from '../utils/options';
import pascalCase from '../utils/pascalCase';
import { transformQueryArgs } from '../utils/queries';
import { isMultiField } from '../utils/relationships';

export const BLANK_QUERY_STRING = `query currentUser {
  currentUser {
    user {
      id
    }
  }
}`;

export const BLANK_COLLECTION_QUERY_STRING = `query userCollection {
  userCollection {
    edges {
      node {
        id
      }
    }
  }
}`;

export const clientUserFragment = `
  id
  uuid
  firstName
  lastName
  email
  isInternal
  profilePicture {
    id
    uuid
    url
  }
`;

const dataFieldTypeToGraphQLType: Record<string, string> = {
  [dataTypes.TEXT]: 'String',
  [dataTypes.DATE]: 'DateTime',
  [dataTypes.INTEGER]: 'Int',
  [dataTypes.DECIMAL]: 'Float',
  [dataTypes.DURATION]: 'Duration',
  [dataTypes.FILE_TYPE]: 'FileType',
  [dataTypes.BOOLEAN]: 'Boolean',
  [dataTypes.SINGLE_OPTION]: 'ENUM',
  [dataTypes.MULTIPLE_OPTION]: 'ENUM',
};

const modifyIfRequired = (isRequired: boolean) => (type: string) =>
  isRequired ? `${type}!` : type;

export const getGraphQlFieldType = (
  dataTypeName: string,
  field: DataField,
  useSingular?: boolean,
  isRequired?: boolean,
): string => {
  const withRequired = modifyIfRequired(isRequired as boolean);

  if (isOptionType(field.type as dataTypes.DataFieldType)) {
    const name = `${pascalCase(dataTypeName)}${pascalCase(field.apiName)}`;
    return field.type === dataTypes.MULTIPLE_OPTION && !useSingular
      ? withRequired(`[${withRequired(name)}]`)
      : withRequired(name);
  }

  if (field.name === 'id') {
    return withRequired('ID');
  }

  if (field.relationship || field.relatedField) {
    return withRequired(isMultiField(field) ? '[ID!]' : 'ID');
  }

  return withRequired(dataFieldTypeToGraphQLType[field.type]);
};

export const getGraphQlAggregationType = (
  rollup: Rollup,
  dataType: DataType,
  dataTypes: DataTypes,
): string => {
  const fieldType = getAggregationFieldType(rollup, dataType, dataTypes);
  return dataFieldTypeToGraphQLType[fieldType];
};

const getQueryVariables = (
  variables: Record<any, any>,
  baseVariables: Record<any, any> = {},
): Record<any, any> | undefined =>
  Object.keys(variables).length > 0 || Object.keys(baseVariables).length > 0
    ? Object.entries(variables).reduce(
        (varAcc, [argumentName, argumentType]) => ({
          ...varAcc,
          [argumentName]: argumentType,
        }),
        baseVariables,
      )
    : undefined;

export const getQueryVariableArgs = (
  variables: Record<any, any>,
  baseVariables: Record<any, any> = {},
): Record<any, VariableType> | undefined =>
  Object.keys(variables).length > 0 || Object.keys(baseVariables).length > 0
    ? Object.keys(variables).reduce(
        (varAcc, argumentName) => ({
          ...varAcc,
          [argumentName]: new VariableType(argumentName),
        }),
        Object.keys(baseVariables).reduce(
          (baseVarAcc, varKey) => ({
            ...baseVarAcc,
            [varKey]: new VariableType(varKey),
          }),
          {},
        ),
      )
    : undefined;

const mergeQueryVairablesAndArgs = (
  variables: Record<any, any>,
  queryArgs: Record<any, any>,
): Record<any, any> | undefined => {
  if (
    Object.keys(variables).length === 0 &&
    Object.keys(queryArgs).length === 0
  ) {
    return undefined;
  }

  return {
    ...getQueryVariableArgs(variables),
    ...transformQueryArgs(queryArgs),
  };
};

export const getCollectionDataQueryString = (
  dataType: string,
  { __args = {}, __variables = {}, ...otherFields }: Record<any, any> = {},
  queryArgs: Record<any, any> = {},
): string => `
    ${jsonToGraphQLQuery({
      [`query ${dataType}Collection`]: {
        __variables: getQueryVariables(__variables),
        [`${dataType}Collection`]: {
          __args: mergeQueryVairablesAndArgs(__variables, {
            ...__args,
            ...queryArgs,
          }),
          ...otherFields,
        },
      },
    })}
  `;

export const getDataQueryString = (
  dataType: string,
  { __args = {}, __variables = {}, ...otherFields }: Record<any, any> = {},
  queryArgs: Record<any, any> = {},
): string =>
  `
    ${jsonToGraphQLQuery({
      [`query ${dataType}`]: {
        __variables: getQueryVariables(__variables),
        [dataType]: {
          __args: transformQueryArgs({ ...__args, ...queryArgs }),
          id: true,
          ...otherFields,
        },
      },
    })}
  `;

export type MutationType = 'CREATE' | 'UPDATE' | 'DELETE' | 'INVITE';

export const buildDataTypeMutationQueryString = (
  mutationType: MutationType,
  baseVariables: Record<any, any> = {},
) => (
  dataType: string,
  fieldArguments: Record<any, any>,
  otherFields: Record<any, any>,
): string => {
  const mutationName = `${mutationType.toLowerCase()}${pascalCase(dataType)}`;
  const mutationBuilder = buildMutationQueryString(baseVariables);
  return mutationBuilder(mutationName, fieldArguments, otherFields);
};

export const buildGqlString = (queryType = 'mutation') => (
  baseVariables = {},
) => (
  queryName: string,
  fieldArguments: Record<any, any>,
  otherFields: Record<any, any> = {},
): string =>
  `${jsonToGraphQLQuery(
    {
      [`${queryType} ${queryName}`]: {
        __variables: getQueryVariables(fieldArguments, baseVariables),
        [`${queryName}`]: {
          __args: getQueryVariableArgs(fieldArguments, baseVariables),
          id: true,
          uuid: true,
          ...otherFields,
        },
      },
    },
    { pretty: true },
  )}`;

export const buildMutationQueryString = buildGqlString('mutation');
export const buildQueryString = buildGqlString('query');

export const getCreationQueryString = buildDataTypeMutationQueryString(CREATE);
export const getInviteUserQueryString = buildDataTypeMutationQueryString(
  INVITE_USER,
);

export const INVITE_EMAIL_QUERY = `
mutation inviteUser($email: String!) {
  inviteUser(email: $email) {
    id
    uuid
    __typename
  }
}`;

export const INVITE_PENDING_USERS_QUERY = `
  mutation invitePendingUsers {
    invitePendingUsers {
      totalCount
    }
  }
`;

export const getUpdateQueryString = buildDataTypeMutationQueryString(UPDATE, {
  id: 'ID!',
});

export const getDeleteQueryString = (dataType: string) =>
  `mutation delete${pascalCase(dataType)}($id: ID!) {
    delete${pascalCase(dataType)}(id: $id) {
      id
      uuid
    }
  }
  `;

export const getCurrentUserQueryString = (otherFields: Record<any, any>) =>
  `${jsonToGraphQLQuery({
    'query currentUser': {
      currentUser: {
        id: true,
        user: {
          id: true,
          uuid: true,
          ...otherFields,
        },
      },
    },
  })}`;

export const getCurrentUserTokenQueryString = () =>
  `${jsonToGraphQLQuery({
    'query currentUser': {
      currentUser: {
        id: true,
        token: true,
      },
    },
  })}`;

export const getGhostUserQueryString = (
  otherFields: Record<any, any>,
  ghostUserId: string | number,
) =>
  `${jsonToGraphQLQuery({
    'query ghostUser': {
      ghostUser: {
        __args: { userId: ghostUserId },
        id: true,
        user: {
          id: true,
          uuid: true,
          ...otherFields,
        },
      },
    },
  })}`;

export const getGhostUserTokenQueryString = (
  ghostUserId: string | number | null,
) =>
  `${jsonToGraphQLQuery({
    'query ghostUser': {
      ghostUser: {
        __args: { userId: ghostUserId },
        id: true,
        token: true,
      },
    },
  })}`;

export const getPageQueryString = (
  dataType: string,
  queryArgs: Record<any, any> | undefined,
  otherFields: Record<any, any> = {},
): string => getDataQueryString(dataType, otherFields, queryArgs);

export const getDataTypeFieldsArgs = (
  dataTypeApiName: string,
  fields: DataField[],
  enforceRequired = false,
): Record<any, any> =>
  fields.reduce((fieldAcc, field) => {
    if (field.name === 'id' || field.name === 'uuid' || field.readOnly) {
      return fieldAcc;
    }

    const withRequired = modifyIfRequired(!!field?.required && enforceRequired);

    if (field.relationship) {
      const isMultiRelationship =
        field.relationship === ONE_TO_MANY ||
        field.relationship === MANY_TO_MANY;
      const isFileType = field.type === 'file';
      if (isFileType) {
        if (fieldAcc[field.apiName]) {
          return fieldAcc;
        }

        return {
          ...fieldAcc,
          [field.apiName]: withRequired(
            isMultiRelationship ? '[Upload!]' : 'Upload',
          ),
          [`${field.apiName}Id`]: withRequired(
            isMultiRelationship ? '[ID!]' : 'ID',
          ),
        };
      }

      if (fieldAcc[`${field.apiName}Id`]) {
        return fieldAcc;
      }
      return {
        ...fieldAcc,
        [`${field.apiName}Id`]: withRequired(
          isMultiRelationship ? '[ID!]' : 'ID',
        ),
      };
    }

    if (field.relatedField) {
      if (TYPES_WITHOUT_MUTATIONS.includes(field.type)) {
        return fieldAcc;
      }

      const fieldReverseApiName = getFieldReverseMutationInputName(
        field.relatedField,
        {
          apiName: field.type,
        } as DataType,
      );
      if (field.relatedField.relationship === ONE_TO_ONE) {
        if (fieldAcc[fieldReverseApiName]) {
          return fieldAcc;
        }

        return {
          ...fieldAcc,
          [fieldReverseApiName]: withRequired('ID'),
        };
      }

      const isMulti =
        field.relatedField.relationship === MANY_TO_MANY ||
        field.relatedField.relationship === MANY_TO_ONE;

      if (fieldAcc[fieldReverseApiName]) {
        return fieldAcc;
      }

      return {
        ...fieldAcc,
        [fieldReverseApiName]: withRequired(isMulti ? '[ID!]' : 'ID'),
      };
    }

    if (fieldAcc[field.apiName]) {
      return fieldAcc;
    }

    if (
      (field.type === dataTypes.SINGLE_OPTION ||
        field.type === dataTypes.MULTIPLE_OPTION) &&
      field.options?.length === 0
    ) {
      return fieldAcc;
    }

    return {
      ...fieldAcc,
      [field.apiName]: getGraphQlFieldType(
        dataTypeApiName,
        field,
        false,
        field.required && enforceRequired,
      ),
    };
  }, {} as Record<any, any>);

export const getMutationQueryString = cappedMemoize(
  (
    actionType: MutationType,
    dataType: string,
    fields: DataField[] = [],
    restQueryObject: Record<any, any> = {},
  ): string | undefined => {
    if (
      actionType === CREATE ||
      actionType === UPDATE ||
      actionType === INVITE_USER
    ) {
      const args = getDataTypeFieldsArgs(
        dataType,
        fields,
        actionType !== UPDATE,
      );

      if (actionType === INVITE_USER) {
        return getInviteUserQueryString(dataType, args, restQueryObject);
      }

      if (actionType === UPDATE) {
        return getUpdateQueryString(dataType, args, restQueryObject);
      }

      return getCreationQueryString(dataType, args, restQueryObject);
    } else if (actionType === DELETE) {
      return getDeleteQueryString(dataType);
    }
  },
  {
    maxKeys: 50,
  },
);

export const getImportQueryString = (dataType: DataType): string => {
  const typeName = pascalCase(dataType.apiName);
  return `mutation import${typeName}(
    $file: Upload!,
    $fields: ${typeName}ImportFields!
    $values: ${typeName}Input
  ) {
    import${typeName}(file: $file, fields: $fields, values: $values)
  }`;
};

const subscriptionCheckoutQueryStringBuilder = buildMutationQueryString({
  planId: 'ID!',
  successUrl: 'String!',
  cancelUrl: 'String!',
});

const stripeCustomerPortalQueryStringBuilder = buildMutationQueryString({
  returnUrl: 'String!',
});

const subscriptionCheckoutFields = {
  uuid: false,
  successUrl: true,
};

export const getSubscriptionMutationQueryString = () => {
  const queryString = subscriptionCheckoutQueryStringBuilder(
    'createSubscriptionCheckoutSession',
    {},
    subscriptionCheckoutFields,
  );
  return queryString;
};

export const getStripeCustomerPortalQueryString = () => {
  const queryString = stripeCustomerPortalQueryStringBuilder(
    'createCustomerPortalSession',
    {},
    subscriptionCheckoutFields,
  );
  return queryString;
};

export const getApiRequestQueryString = (api: any, endpoint: any): string => {
  const queryName = getApiEndpointQueryName(api, endpoint);
  const queryTypeBuilder =
    endpoint.type === QUERY
      ? buildQueryString({})
      : buildMutationQueryString({});
  const parameterArguments = endpoint.parameters.reduce(
    (paramAcc: Record<any, any>, param: any) => ({
      ...paramAcc,
      [camelCase(param.name)]: `${getGraphQlFieldType('', {
        type: param.dataType,
      } as DataField)}!`,
    }),
    {} as Record<any, any>,
  );

  return queryTypeBuilder(queryName, parameterArguments, {
    id: false,
    uuid: false,
    status: true,
    data: true,
  });
};

const invoiceQueryFragment = {
  id: true,
  createdAt: true,
  accountCustomerId: true,
  customerEmail: true,
  currency: true,
  description: true,
  collectionMethod: true,
  autoAdvance: true,
  daysUntilDue: true,
  dueDate: true,
  status: true,
  paid: true,
  url: true,
  pdf: true,
  total: true,
  isSubscription: true,
  lineItemsCollection: {
    edges: {
      node: {
        id: true,
        amount: true,
        description: true,
        quantity: true,
      },
    },
    totalCount: true,
  },
};

const subscriptionQueryFragment = {
  id: true,
  name: true,
  amount: true,
  interval: true,
  quantity: true,
  currency: true,
  currentPeriodStart: true,
  currentPeriodEnd: true,
  status: true,
  createdAt: true,
  accountCustomerId: true,
};

export const getInvoiceQuery = (customerFragment: Record<any, any>): string =>
  buildQueryString({})(
    'invoice',
    {
      id: 'ID!',
    },
    {
      ...invoiceQueryFragment,
      id: undefined,
      uuid: undefined,
      customer: customerFragment,
    },
  );

export const getCustomerInvoiceCollectionQuery = (
  customerFragment: Record<any, any>,
): string =>
  buildQueryString({})(
    'customerInvoiceCollection',
    {},
    {
      id: undefined,
      uuid: undefined,
      edges: {
        node: {
          ...invoiceQueryFragment,
          customer: customerFragment,
        },
      },
      totalCount: true,
    },
  );

export const getInvoiceCollectionQuery = (
  customerFragment: Record<any, any>,
): string =>
  buildQueryString({})(
    'invoiceCollection',
    { where: 'InvoiceWhereInput' },
    {
      id: undefined,
      uuid: undefined,
      edges: {
        node: {
          ...invoiceQueryFragment,
          customer: customerFragment,
        },
      },
      totalCount: true,
    },
  );

export const getSubscriptionCollectionQuery = (
  customerFragment: Record<any, any>,
): string =>
  buildQueryString({})(
    'subscriptionCollection',
    { where: 'SubscriptionWhereInput' },
    {
      id: undefined,
      uuid: undefined,
      edges: {
        node: {
          ...subscriptionQueryFragment,
          customer: customerFragment,
        },
      },
      totalCount: true,
    },
  );

export const getFinalizeInvoiceQuery = (
  customerFragment: Record<any, any>,
): string =>
  buildMutationQueryString({})(
    'finalizeInvoice',
    { id: 'ID!' },
    {
      ...invoiceQueryFragment,
      customer: customerFragment,
    },
  );

export const messageFragment = `
  id
  uuid
  text
  createdAt
  sender {
    id
    uuid
    firstName
    lastName
    isInternal
    profilePicture {
      id
      uuid
      url
    }
    role {
      id
      uuid
      name
      internal
    }
  }
  attachment {
    id
    uuid
    name
    url
    fileType
    mimetype
  }
`;

export const MESSAGE_COLLECTION_QUERY = `
  query messageCollection($conversationId: ID!, $after: String) {
    messageCollection(after: $after, where: { conversationId: { equals: $conversationId} }, orderBy: { field: "createdAt", direction: DESC}, first: 30) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasNextPage
      }
      edges {
        node {
          ${messageFragment}
        }
      }
    }
  }
`;

export const CREATE_MESSAGE_MUTATION = `
  mutation createMessage($text: String, $conversationId: ID!, $attachment: Upload) {
    createMessage(text: $text, conversationId: $conversationId, attachment: $attachment) {
      ${messageFragment}
    }
  }
`;

export const MESSAGE_ADDED_SUBSCRIPTION = `
  subscription OnMessageAdded($projectName: String!, $vars: JSON!) {
    messageAdded(projectName: $projectName, dataType: "message", vars: $vars) 
  }
`;

export const sharedFileFragment = `
  id
  uuid
  name
  createdAt
  creator {
    id
    uuid
    firstName
    lastName
    isInternal
    profilePicture {
      id
      uuid
      url
    }
  }
  source,
  parent {
    id
    uuid
    name
  }
  file {
    id
    uuid
    name
    url
    mimetype
    fileType
  }
`;

export const SHARED_FILE_COLLECTION_QUERY = `
  query sharedFileCollection($companyId: ID!, $folderId: ID, $after: String) {
    sharedFileCollection(after: $after, where: { companyId: { equals: $companyId }, parentId: { equals: $folderId } }, orderBy: { field: "createdAt", direction: DESC}, first: 30) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasNextPage
      }
      edges {
        node {
          ${sharedFileFragment}
        }
      }
    }
  }
`;

export const SHARED_FILE_QUERY = `
  query sharedFile($folderId: ID) {
    sharedFile(id: $folderId) {
      ${sharedFileFragment}
    }
  }
`;

export const CREATE_SHARED_FILE_MUTATION = `
  mutation createSharedFile($companyId: ID!, $folderId: ID, $file: Upload, $name: String!) {
    createSharedFile(companyId: $companyId, parentId: $folderId, file: $file, name: $name) {
      ${sharedFileFragment}
    }
  }
`;

export const getUpdateFormMutation = (
  additionalFields: Record<any, any>,
): string =>
  buildMutationQueryString({})(
    'updateForm',
    { id: 'ID!', form: 'FormInput!' },
    {
      ...additionalFields,
    },
  );

export const getUpdateFormResponsesMutation = (
  additionalFields: Record<any, any>,
): string =>
  buildMutationQueryString({})(
    'updateFormResponses',
    { submissionId: 'ID!', responses: '[ResponseInput!]!' },
    {
      ...additionalFields,
    },
  );

export const getFormQuery = (additionalFields: Record<any, any>): string =>
  buildQueryString({})(
    'form',
    { id: 'ID!' },
    {
      ...additionalFields,
    },
  );

export const getFormSubmissionQuery = (
  additionalFields: Record<any, any>,
): string =>
  buildQueryString({})(
    'formSubmission',
    { id: 'ID!' },
    {
      ...additionalFields,
    },
  );

export const PORTAL_USER_FRAGMENT = {
  id: true,
  firstName: true,
  lastName: true,
  email: true,
  profilePicture: {
    id: true,
    url: true,
    fileType: true,
  },
};

export const UPDATE_SHARED_FILE_MUTATION = `
  mutation updateSharedFile($id: ID!, $name: String!) {
    updateSharedFile(id: $id, name: $name) {
      ${sharedFileFragment}
    }
  }
`;

export const DELETE_SHARED_FILE_MUTATION = `
  mutation deleteSharedFile($id: ID!) {
    deleteSharedFile(id: $id) {
      ${sharedFileFragment}
    }
  }
`;

const ONBOARDING_TASK_FRAGMENT = `
  id
  uuid
  title
  description
  url
  type
  completed
`;

export const ONBOARDING_TASK_COLLECTION_QUERY = `
  query onboardingTaskCollection {
    onboardingTaskCollection(orderBy: { field: "createdAt", direction: ASC}) {
      edges {
        node {
          ${ONBOARDING_TASK_FRAGMENT}
        }
      }
    }
  }
`;

export const UPDATE_ONBOARDING_TASKS_MUTATION = `
  mutation updateOnboardingTasks($tasks: [OnboardingTaskInput!]!) {
    updateOnboardingTasks(tasks: $tasks) {
      ${ONBOARDING_TASK_FRAGMENT}
    }
  }
`;

export const TOGGLE_ONBOARDING_TASK_COMPLETE_MUTATION = `
  mutation updateOnboardingTask($id: ID!, $userIds: [ID!], $companyIds: [ID!]) {
    updateOnboardingTask(id: $id, usersCompletedId: $userIds, companiesCompletedId: $companyIds) {
      ${ONBOARDING_TASK_FRAGMENT}
    }
  }
`;

export const getCollectionCsvExportQueryString = (
  dataTypeName: string,
): string => `
  query ${dataTypeName}CsvExport {
    ${dataTypeName}CsvExport {
      base64
    }
  }
`;

export const RUN_WORKFLOW = `
  mutation runWorkflow($workflowId: String!, $recordId: ID!) {
    runWorkflow(workflowId:$workflowId, recordId: $recordId)
  }
`;

export const DEMO_USER_TOKEN_QUERY_STRING = `
  query demoUser {
    demoUser {
      token
    }
  }
`;
