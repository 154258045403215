import { useSelector } from 'react-redux';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import { isTrialActive } from '@noloco/core/src/utils/billing';
import { isFeatureEnabled } from '@noloco/core/src/utils/features';
import { useFeatureUsage } from './useFeatureUsage';

const useIsFeatureEnabled = (feature: any, options = {}) => {
  const billingPlan = useSelector(billingPlanSelector);
  const currentUsage = useFeatureUsage(feature);
  const hasActiveTrial = isTrialActive(billingPlan);

  const blockTrial = (options as any)?.blockTrial === true;

  if (blockTrial && hasActiveTrial) {
    return billingPlan?.customConfig?.[feature] === true;
  }

  return isFeatureEnabled(billingPlan, feature, currentUsage);
};

export default useIsFeatureEnabled;
