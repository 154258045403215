import React, { memo, useCallback, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import loadable from '@loadable/component';
import classNames from 'classnames';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import colors from 'tailwindcss/colors';
import { Loader, RatingInput } from '@noloco/components';
import {
  AREA,
  BAR,
  FUNNEL,
  GAUGE,
  LINE,
  PIE,
  RADAR,
  STATISTIC,
} from '@noloco/core/src/constants/chartTypes';
import {
  DATE as DATE_FORMAT,
  RATING,
} from '@noloco/core/src/constants/fieldFormats';
import { DESC } from '@noloco/core/src/constants/orderByDirections';
import { findPreviewFields } from '@noloco/core/src/utils/dataTypes';
import { getDateFromValue } from '@noloco/core/src/utils/dates';
import { Aggregation, COUNT, SUM } from '../constants/aggregationTypes';
import { DATE, DECIMAL, INTEGER, SINGLE_OPTION } from '../constants/dataTypes';
import {
  DAY as DAY_FORMAT,
  MONTH_SHORT as MONTH_FORMAT,
  YEAR as YEAR_FORMAT,
} from '../constants/dateFormatOptions';
import PRIMITIVE_DATA_TYPES from '../constants/primitiveDataTypes';
import {
  AUTO,
  DAY,
  MONTH,
  QUARTER,
  WEEK,
  YEAR,
} from '../constants/timePeriods';
import { DataField } from '../models/DataTypeFields';
import { DataType } from '../models/DataTypes';
import { RecordValue } from '../models/Record';
import StateItem from '../models/StateItem';
import { aggregateData } from '../utils/aggregationDataTypes';
import {
  getFieldFromAxisValuePath,
  getFieldPathFromPath,
  isMetricChart,
} from '../utils/charts';
import { getColorByIndex, getPrimaryColorGroup } from '../utils/colors';
import { safelyAppendPath } from '../utils/data';
import DataListWrapper from './DataListWrapper';
import GaugeChart from './sections/charts/GaugeChart';
import StatisticChart from './sections/charts/StatisticChart';
import { ChartSeries } from './sections/charts/chartTypes';
import { formatValue } from './sections/collections/FieldCell';

export const sampleData = [...Array(12)].map((__, index) => ({
  y: index * Math.ceil(Math.random() * 10),
  x: new Date(new Date().setMonth(index)).getTime(),
  g: `Group ${index + 1}`,
}));

export const metricData = [...Array(12)].map((__, index) => ({
  x: index,
  g: `Group ${index + 1}`,
}));

export const sampleXValue = { path: 'edges.x', dataType: DATE };
export const sampleXMetricValue = { path: 'edges.x', dataType: INTEGER };
export const sampleYValue = { path: 'edges.y', dataType: DECIMAL };

const formatDatum = (type: any, value: any) => {
  if (!value || value === 'Unset') {
    return value;
  }

  if (type === DATE) {
    const dateValue = getDateFromValue(value);
    // @ts-expect-error TS(2576): Property 'invalid' does not exist on type 'DateTim... Remove this comment to see the full error message
    if (dateValue && !dateValue.invalid) {
      return dateValue.toJSDate();
    }
    return null;
  }

  const maybeNumber = parseFloat(value);
  if (!isNaN(maybeNumber)) {
    return maybeNumber;
  }

  return value;
};

// @ts-expect-error TS(7031): Binding element 'xA' implicitly has an 'any' type.
const numericSort = ({ x: xA }, { x: xB }) => xA - xB;

const getDateTimePeriod = (timePeriod: any) => {
  if (timePeriod === AUTO) {
    return 'minute';
  }

  return timePeriod || DAY;
};

const aggregateSeries = (series: any, values: any, aggregation: any, x: any) =>
  series.reduce(
    // @ts-expect-error TS(7006): Parameter 'seriesAcc' implicitly has an 'any' type... Remove this comment to see the full error message
    (seriesAcc, chartSeries) => ({
      ...seriesAcc,

      [chartSeries.id]:
        aggregateData(
          values.map((value: any) => value[chartSeries.id]),
          aggregation,
        ) || 0,
    }),
    {
      x,
    },
  );

type MetricData = {
  x: number | null;
};

type SeriesData = {
  x: string | number;
  [y: string]: string | number;
}[];

const transformRawMetricData = (
  data: { x: RecordValue }[],
  xAxisValue: StateItem,
  dataType: DataType,
  aggregation: Aggregation = SUM,
): MetricData => {
  let filteredData = data.map((item) => item.x).filter((x) => !isNil(x));
  const xAxisField = getFieldFromAxisValuePath(xAxisValue.path, dataType);

  if (xAxisField && xAxisField.type === DATE) {
    filteredData.map((x) => DateTime.fromISO(x as string).toMillis());
  }

  const metric = aggregateData(filteredData, aggregation);

  return { x: metric };
};

const transformRawData = (
  data: any,
  xAxisValue: any,
  dataType: any,
  timePeriod: any,
  aggregation: any,
  sortOptions: any,
  series: any,
): SeriesData => {
  const xAxisType = xAxisValue.dataType;
  if (xAxisType === DATE) {
    const dateData = data.reduce((commonDates: any, datum: any) => {
      const dateValue = DateTime.fromJSDate(datum.x);

      if (!dateValue.isValid) {
        return commonDates;
      }
      const dateTimePeriod = getDateTimePeriod(timePeriod);

      const dateStr = DateTime.fromJSDate(datum.x)
        .toUTC()
        .startOf(dateTimePeriod)
        .toISO();

      if (commonDates[dateStr]) {
        return set([dateStr, commonDates[dateStr].length], datum, commonDates);
      }

      return set([dateStr], [datum], commonDates);
    }, {});

    return Object.entries(dateData)
      .map(([dateStr, values]) =>
        aggregateSeries(
          series,
          values,
          aggregation,
          DateTime.fromISO(dateStr).toUTC().toMillis(),
        ),
      )
      .sort(numericSort)
      .map((datum) => ({
        ...datum,
        x: datum.x !== null ? DateTime.fromMillis(datum.x).toJSDate() : datum.x,
      }));
  }

  const groupedData = data.reduce((commonValues: any, datum: any) => {
    const keyStr =
      PRIMITIVE_DATA_TYPES.includes(xAxisType) || !xAxisValue.path
        ? String(datum.x)
        : get(datum, 'x.id');
    if (commonValues[keyStr]) {
      return set([keyStr, commonValues[keyStr].length], datum, commonValues);
    }

    return set([keyStr], [datum], commonValues);
  }, {});

  const reducedData = Object.entries(groupedData).map(([keyStr, values]) =>
    aggregateSeries(series, values, aggregation, keyStr),
  );

  if (sortOptions) {
    const valudIdMap = data.reduce((idMap: any, datum: any) => {
      const keyStr = get(datum, 'x.id');
      return set([keyStr], datum.x, idMap);
    }, {});

    return reducedData.sort(({ primary: primaryA }, { primary: primaryB }) => {
      const { direction, field } = sortOptions;
      const valueA = get(valudIdMap, [primaryA, field], null);
      const valueB = get(valudIdMap, [primaryB, field], null);

      if (!valueA && !valueB) {
        return 0;
      }

      if (!valueA && valueB) {
        return 1;
      }

      if (valueA && !valueB) {
        return -1;
      }

      if (!direction || direction === DESC) {
        return valueB > valueA ? 1 : -1;
      }

      return valueB > valueA ? -1 : 1;
    });
  }

  if (xAxisType === DECIMAL || xAxisType === INTEGER) {
    return reducedData
      .filter((datum) => datum.x !== 'null')
      .map((datum) => ({
        ...datum,
        x: parseFloat(datum.x),
      }))
      .sort(numericSort);
  }

  if (dataType && xAxisType === SINGLE_OPTION) {
    const xAxisField = getFieldFromAxisValuePath(xAxisValue.path, dataType);
    if (xAxisField && xAxisField.type === SINGLE_OPTION) {
      const optionOrderMap = xAxisField.options?.reduce((map, option) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        map[option.name] = option.order;
        return map;
      }, {});

      return reducedData.sort(
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        (valueA, valueB) => optionOrderMap[valueA.x] - optionOrderMap[valueB.x],
      );
    }
  }

  const firstSeries = first(series);
  const sortedData = reducedData.sort(
    ({ [(firstSeries as any).id]: yA }, { [(firstSeries as any).id]: yB }) =>
      yB - yA,
  );

  return sortedData;
};

const recordLabelFormatter = (
  axisValuePath: any,
  edges: any,
  relatedDataType: any,
  id: any,
) => {
  const { textFields } = findPreviewFields(
    relatedDataType.fields,
    relatedDataType,
  );

  const idEdge = edges.find(
    (edge: any) =>
      (axisValuePath
        ? get(edge, `${getFieldPathFromPath(axisValuePath)}.id`)
        : String(edge.id)) === id,
  );

  if (textFields.length === 0 || !idEdge) {
    return id;
  }

  const fieldPath = axisValuePath
    ? `${getFieldPathFromPath(axisValuePath)}.`
    : '';

  return textFields
    .map((textField) => get(idEdge, `${fieldPath}${textField.name}`))
    .join(' ');
};

const periodsWithDays = [AUTO, WEEK, DAY];

const getChartLabelFromDate = (dateTime: any, timePeriod: any) => {
  switch (timePeriod) {
    case WEEK:
      return dateTime.weekNumber;

    case MONTH:
      return `${dateTime.monthLong}, ${dateTime.year}`;

    case QUARTER: {
      const quarterStart = dateTime.startOf('quarter').monthShort;
      const quarterEnd = dateTime.endOf('quarter').monthShort;

      return `${quarterStart} - ${quarterEnd}, ${dateTime.year}`;
    }
    case YEAR:
      return dateTime.year;

    default:
      return dateTime.toLocaleString();
  }
};

const axisFormatter = (
  axisValue: any,
  dataType: any,
  dataTypes: any,
  edges: any,
  timePeriod?: any,
) => (value: any) => {
  if (!axisValue || !axisValue.dataType) {
    return (value: any) => value;
  }

  const axisType = axisValue.dataType;

  if (dataType && axisType === SINGLE_OPTION) {
    const axisField = getFieldFromAxisValuePath(axisValue.path, dataType);
    if (axisField && axisField.type === SINGLE_OPTION) {
      const option = axisField.options?.find((op) => op.name === value);
      return option ? option.display : value;
    }
  }

  if (PRIMITIVE_DATA_TYPES.includes(axisType)) {
    if (dataType) {
      const axisField = getFieldFromAxisValuePath(axisValue.path, dataType);

      if (axisField) {
        if (value !== null && axisField.typeOptions?.format === RATING) {
          return (
            <RatingInput
              className="my-2"
              disabled={true}
              maxRating={get(axisField.typeOptions, 'max')}
              value={value}
            />
          );
        }

        return value !== null
          ? formatValue(value, axisField, {
              format: axisField.type === DATE ? DATE_FORMAT : undefined,
              dateFormat: `${
                periodsWithDays.includes(timePeriod) ? DAY_FORMAT : ''
              } ${MONTH_FORMAT} ${YEAR_FORMAT}`,
            })
          : null;
      }
    } else if (axisType === DATE) {
      const formattedDate = formatValue(value, { type: DATE } as DataField, {});
      const dateTime = DateTime.fromFormat(
        formattedDate as string,
        'dd/MM/yyyy HH:mm',
      );

      return getChartLabelFromDate(dateTime, timePeriod);
    }
    return value;
  }

  const relatedDataType = dataTypes.getByName(axisType);
  if (!relatedDataType) {
    return value;
  }

  return recordLabelFormatter(axisValue.path, edges, relatedDataType, value);
};

const fillShades = [600, 800, 400, 200, 700];

const AsyncXYChart = loadable(() => import('./sections/charts/XYChart'));

const AsyncPieChart = loadable(() =>
  import('./sections/charts/PieChartWithLegend'),
);

const AsyncFunnelChart = loadable(() =>
  import('./sections/charts/FunnelChart'),
);

const AsyncRadarChart = loadable(() => import('./sections/charts/RadarChart'));

const chartComponents = {
  [AREA]: AsyncXYChart,
  [LINE]: AsyncXYChart,
  [BAR]: AsyncXYChart,
  [PIE]: AsyncPieChart,
  [FUNNEL]: AsyncFunnelChart,
  [RADAR]: AsyncRadarChart,
  [STATISTIC]: StatisticChart,
  [GAUGE]: GaugeChart,
};

export const ChartData = memo(
  ({
    // @ts-expect-error TS(2339): Property 'aggregation' does not exist on type '{}'... Remove this comment to see the full error message
    aggregation,
    // @ts-expect-error TS(2339): Property 'chartId' does not exist on type '{}'.
    chartId,
    // @ts-expect-error TS(2339): Property 'chartType' does not exist on type '{}'.
    chartType,
    // @ts-expect-error TS(2339): Property 'timePeriod' does not exist on type '{}'.
    timePeriod,
    // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
    dataType,
    // @ts-expect-error TS(2339): Property 'dataTypes' does not exist on type '{}'.
    dataTypes,
    // @ts-expect-error TS(2339): Property 'max' does not exist on type '{}'.
    max,
    // @ts-expect-error TS(2339): Property 'nodes' does not exist on type '{}'.
    nodes,
    // @ts-expect-error TS(2339): Property 'series' does not exist on type '{}'.
    series = [],
    // @ts-expect-error TS(2339): Property 'sortOptions' does not exist on type '{}'... Remove this comment to see the full error message
    sortOptions,
    // @ts-expect-error TS(2339): Property 'theme' does not exist on type '{}'.
    theme,
    // @ts-expect-error TS(2339): Property 'useOptionColors' does not exist on type ... Remove this comment to see the full error message
    useOptionColors,
    // @ts-expect-error TS(2339): Property 'xAxisLabel' does not exist on type '{}'.
    xAxisLabel,
    // @ts-expect-error TS(2339): Property 'xAxisValue' does not exist on type '{}'.
    xAxisValue,
    // @ts-expect-error TS(2339): Property 'yAxisLabel' does not exist on type '{}'.
    yAxisLabel,
  }) => {
    const xAxisType = xAxisValue.dataType;
    const primaryAxisFormatter = useMemo(
      () => axisFormatter(xAxisValue, dataType, dataTypes, nodes, timePeriod),
      [xAxisValue, dataType, dataTypes, nodes, timePeriod],
    );

    const formatSeriesValue = useCallback(
      (chartSeries: ChartSeries, value: number) =>
        axisFormatter(
          get(chartSeries, ['yAxisValue']),
          dataType,
          dataTypes,
          nodes,
        )(value),
      [dataType, dataTypes, nodes],
    );

    const secondaryAxisFormatter = useMemo(() => {
      if (aggregation === COUNT) {
        return identity;
      }

      return axisFormatter(
        get(series, [0, 'yAxisValue']),
        dataType,
        dataTypes,
        nodes,
      );
    }, [aggregation, series, dataType, dataTypes, nodes]);

    const xAxisField = useMemo(
      () => xAxisValue && getFieldFromAxisValuePath(xAxisValue.path, dataType),
      [dataType, xAxisValue],
    );

    const primaryColorGroup = useMemo(() => getPrimaryColorGroup(theme), [
      theme,
    ]);

    const cellFillsMap = useMemo(() => {
      if (useOptionColors && xAxisField && xAxisField.type === SINGLE_OPTION) {
        const nullColor = getColorByIndex(xAxisField.options.length).split(
          '-',
        )[0];
        return xAxisField.options.reduce(
          // @ts-expect-error TS(7006): Parameter 'mapAcc' implicitly has an 'any' type.
          (mapAcc, option) => ({
            ...mapAcc,

            [option.name]:
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              colors[
                option.color || getColorByIndex(option.order).split('-')[0]
              ][400],
          }),
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          { null: colors[nullColor][400] },
        );
      }

      return null;
    }, [useOptionColors, xAxisField]);

    const fills = useMemo(
      () => fillShades.map((shade) => primaryColorGroup[shade]),
      [primaryColorGroup],
    );

    const data = useMemo(() => {
      const rawData = nodes.map((edge: any) =>
        series.reduce(
          // @ts-expect-error TS(7006): Parameter 'seriesAcc' implicitly has an 'any' type... Remove this comment to see the full error message
          (seriesAcc, chartSeries) => ({
            ...seriesAcc,

            [chartSeries.id]: chartSeries.yAxisValue
              ? formatDatum(
                  chartSeries.yAxisValue.dataType,
                  get(
                    edge,
                    getFieldPathFromPath(chartSeries.yAxisValue.path),
                    0,
                  ),
                )
              : null,
          }),
          {
            x: formatDatum(
              xAxisValue.dataType,
              get(edge, getFieldPathFromPath(xAxisValue.path || 'id'), 0),
            ),
          },
        ),
      );

      if (isMetricChart(chartType)) {
        return transformRawMetricData(
          rawData,
          xAxisValue,
          dataType,
          aggregation,
        );
      }

      return transformRawData(
        rawData,
        xAxisValue,
        dataType,
        timePeriod,
        aggregation,
        sortOptions,
        series,
      );
    }, [
      nodes,
      chartType,
      xAxisValue,
      dataType,
      timePeriod,
      aggregation,
      sortOptions,
      series,
    ]);

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const ChartComponent = chartComponents[chartType || LINE];

    return (
      <div
        className={classNames(
          'w-full min-w-0 relative',
          {
            'h-96': !isMetricChart(chartType),
            'flex h-full': isMetricChart(chartType),
            'items-end': chartType === STATISTIC,
            'items-center': chartType === GAUGE,
          },
          `chart-${chartId}`,
        )}
      >
        <ChartComponent
          chartId={chartId}
          cellFillsMap={cellFillsMap}
          chartType={chartType}
          data={data}
          fills={fills}
          max={max}
          primaryAxisFormatter={primaryAxisFormatter}
          formatSeriesValue={formatSeriesValue}
          secondaryAxisFormatter={secondaryAxisFormatter}
          series={series}
          xAxisLabel={xAxisLabel}
          xAxisType={xAxisType}
          yAxisLabel={yAxisLabel}
          theme={theme}
        />
      </div>
    );
  },
);

type ChartProps = {};

const Chart = ({
  // @ts-expect-error TS(2339): Property 'aggregation' does not exist on type 'Cha... Remove this comment to see the full error message
  aggregation,
  // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Chart... Remove this comment to see the full error message
  className,
  // @ts-expect-error TS(2339): Property 'chartId' does not exist on type 'Chart... Remove this comment to see the full error message
  chartId,
  // @ts-expect-error TS(2339): Property 'chartType' does not exist on type 'Chart... Remove this comment to see the full error message
  chartType,
  // @ts-expect-error TS(2339): Property 'timePeriod' does not exist on type 'Char... Remove this comment to see the full error message
  timePeriod,
  // @ts-expect-error TS(2339): Property 'dataList' does not exist on type 'ChartP... Remove this comment to see the full error message
  dataList,
  // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type 'Char... Remove this comment to see the full error message
  editorMode,
  // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type 'Cha... Remove this comment to see the full error message
  elementPath,
  // @ts-expect-error TS(2339): Property 'emptyState' does not exist on type 'Char... Remove this comment to see the full error message
  emptyState,
  // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'ChartPr... Remove this comment to see the full error message
  onClick,
  // @ts-expect-error TS(2339): Property 'project' does not exist on type 'ChartPr... Remove this comment to see the full error message
  project,
  // @ts-expect-error TS(2339): Property 'scope' does not exist on type 'ChartProp... Remove this comment to see the full error message
  scope,
  // @ts-expect-error TS(2339): Property 'sortBy' does not exist on type 'ChartPro... Remove this comment to see the full error message
  sortBy,
  // @ts-expect-error TS(2339): Property 'sortDirection' does not exist on type 'C... Remove this comment to see the full error message
  sortDirection,
  // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type 'ChartP... Remove this comment to see the full error message
  subtitle,
  // @ts-expect-error TS(2339): Property 'title' does not exist on type 'ChartProp... Remove this comment to see the full error message
  title,
  // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'ChartProp... Remove this comment to see the full error message
  theme,
  // @ts-expect-error TS(2339): Property 'xAxisValue' does not exist on type 'Char... Remove this comment to see the full error message
  xAxisValue,
  // @ts-expect-error TS(2339): Property 'yAxisValue' does not exist on type 'Char... Remove this comment to see the full error message
  yAxisValue,
}: ChartProps) => {
  const EmptyState = useMemo(
    () => () => (
      <div className="w-full p-16 flex items-center justify-center flex-col text-center">
        <h2 className="font-medium text-base">{emptyState.title.value}</h2>
        {!emptyState.image.hidden && emptyState.image.value.src && (
          <img
            className="rounded-lg w-full max-w-xs mt-4"
            src={emptyState.image.value.src}
            alt={emptyState.title.value}
          />
        )}
      </div>
    ),
    [emptyState],
  );

  const titleRow = useMemo(
    () =>
      (title || subtitle) && (
        <div className="flex items-center mb-2">
          <div className="flex flex-col">
            {title && <h1 className="font-medium text-base">{title}</h1>}
            {subtitle && <p className="font-base mt-1 text-base">{subtitle}</p>}
          </div>
        </div>
      ),
    [subtitle, title],
  );

  const sortOptions = useMemo(
    () =>
      xAxisValue &&
      chartType === BAR &&
      !PRIMITIVE_DATA_TYPES.includes(xAxisValue.dataType) &&
      sortBy &&
      sortDirection
        ? {
            field: sortBy,
            direction: sortDirection,
          }
        : null,
    [chartType, sortBy, sortDirection, xAxisValue],
  );

  const additionalDeps = useMemo(
    () =>
      sortOptions
        ? [
            {
              ...xAxisValue,
              path: safelyAppendPath(xAxisValue.path, sortOptions.field),
            },
          ]
        : [],
    [sortOptions, xAxisValue],
  );

  if (!xAxisValue || !yAxisValue) {
    return (
      <div className={classNames(className, 'w-full p-8')} onClick={onClick}>
        {titleRow}
        <div className="bg-white rounded-lg shadow-md p-8 w-full">
          <ChartData
            // @ts-expect-error TS(2322): Type '{ aggregation: any; chartType: any; dataType... Remove this comment to see the full error message
            chartId={chartId}
            aggregation={aggregation}
            chartType={chartType || LINE}
            dataTypes={project.dataTypes}
            timePeriod={timePeriod || WEEK}
            nodes={sampleData}
            id="SAMPLE"
            theme={theme}
            xAxisValue={sampleXValue}
            series={[{ id: 'sample', yAxisValue: sampleYValue }]}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(className, 'w-full p-8')} onClick={onClick}>
      {titleRow}
      <div className="bg-white rounded-lg shadow-md p-8 w-full">
        <DataListWrapper
          additionalDeps={additionalDeps}
          project={project}
          elementPath={elementPath}
          editorMode={editorMode}
          EmptyState={EmptyState}
          scope={scope}
          {...dataList}
        >
          {({ loading, nodes, rawData, error }) => {
            if (loading) {
              return (
                <div className="flex justify-center items-center w-full p-8">
                  <Loader type="Bars" size="sm" />
                </div>
              );
            }
            if (error && !rawData) {
              console.log(error);
              return (
                <div className="flex justify-center items-center w-full p-8">
                  <em>Something went wrong</em>
                </div>
              );
            }

            if (nodes.length === 0) {
              return <EmptyState />;
            }

            return (
              <ChartData
                // @ts-expect-error TS(2322): Type '{ aggregation: any; chartType: any; dataType... Remove this comment to see the full error message
                chartId={chartId}
                aggregation={aggregation}
                chartType={chartType}
                dataTypes={project.dataTypes}
                timePeriod={timePeriod}
                nodes={nodes}
                sortOptions={sortOptions}
                theme={theme}
                xAxisValue={xAxisValue}
                series={[{ id: 'default', yAxisValue }]}
              />
            );
          }}
        </DataListWrapper>
      </div>
    </div>
  );
};

export default withTheme(Chart);
