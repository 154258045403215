import { Icon as IconType } from '@tabler/icons-react';
import { NODE } from '../../constants/elementPropTypeTypes';
import { Element } from '../Element';
import ElementPropType from './ElementPropType';

class NodeType extends ElementPropType {
  defaultNodeShape?: Element;
  Icon: IconType | null;
  showInTree: boolean;
  constructor(
    defaultNodeShape?: Element,
    { Icon = null }: { Icon?: IconType | null } = {},
  ) {
    super(NODE);
    this.defaultNodeShape = defaultNodeShape;
    this.Icon = Icon;
    this.showInTree = true;
  }

  setShowInTree(showInTree: boolean) {
    this.showInTree = showInTree;
    return this;
  }
}

export default NodeType;
