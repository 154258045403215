import React, { useState } from 'react';
import { IconTrash, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import { getText } from '@noloco/core/src/utils/lang';

const ConfirmDeleteButton = ({ className, onDelete }: any) => {
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);

  return (
    <div className={classNames(className, 'flex items-center space-x-1')}>
      {showConfirmRemove && (
        <span>{getText('data.dataTypes.workflows.confirmRemove')}</span>
      )}
      <button
        onClick={
          showConfirmRemove && onDelete
            ? () => onDelete()
            : () => setShowConfirmRemove(true)
        }
        className="p-1 opacity-50 hover:opacity-100"
      >
        <IconTrash size={16} />
      </button>
      {showConfirmRemove && (
        <button
          onClick={() => setShowConfirmRemove(false)}
          className="p-1 opacity-50 hover:opacity-100"
        >
          <IconX size={16} />
        </button>
      )}
    </div>
  );
};

export default ConfirmDeleteButton;
