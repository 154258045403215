import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import identity from 'lodash/identity';
import throttle from 'lodash/throttle';
import { useSelector } from 'react-redux';
import { BOARD, CollectionLayout } from '../../constants/collectionLayouts';
import {
  DATE,
  NUMERIC_DATATYPES,
  SINGLE_OPTION,
} from '../../constants/dataTypes';
import { DATE as DATE_FORMAT } from '../../constants/fieldFormats';
import { UPDATE } from '../../constants/workflowTriggerTypes';
import {
  Group,
  GroupByConfig,
  getGroupByDepPaths,
} from '../../elements/sections/Collection';
import { DataField } from '../../models/DataTypeFields';
import { DataType } from '../../models/DataTypes';
import { BaseRecord, RecordEdge, RecordValue } from '../../models/Record';
import {
  CollectionField,
  DataListFilter,
  GroupByWithField,
} from '../../models/View';
import { QueryObject } from '../../queries/data';
import { getMutationQueryString } from '../../queries/project';
import { projectDataSelector } from '../../selectors/projectSelectors';
import SafeStorage from '../SafeStorage';
import { getDateFromValue } from '../dates';
import { FieldConfig, fieldPermissions } from '../permissions';
import usePrevious from './usePrevious';
import useScopeUser from './useScopeUser';

const LEADING_EDGE_NODES_REGEX = /^(edges\.node\.)?/;

export type CollectionGroupsConfig = {
  customFilters: DataListFilter[];
  dataType: DataType;
  edges: RecordEdge[];
  elementId: string;
  enableDragAndDropEdit: boolean;
  fields: FieldConfig<CollectionField>[];
  groupByFields: GroupByWithField[];
  groupOptions: Record<string, { collapsed?: boolean; hidden?: boolean }>;
  hideEmptyGroups: boolean;
  layout: CollectionLayout;
  limitPerGroup?: number;
  nodeQueryObject: QueryObject;
};

const formatGroupValue = (
  value: string | null,
  field: DataField,
): RecordValue => {
  if (value === null) {
    return null;
  }

  if (field.type === DATE) {
    const date = getDateFromValue(value);
    if (date?.isValid) {
      return date.toISO();
    }

    return null;
  }

  if (NUMERIC_DATATYPES.includes(field.type)) {
    return Number(value);
  }

  return value;
};

const groupEdges = (
  edges: RecordEdge[],
  key: string,
  labelPathFixed: string,
  renderLabel: (val: RecordValue) => any = identity,
  groupValue: (val: RecordValue) => RecordValue = identity,
  initialGroups: Omit<Group, 'depth' | 'id'>[] = [],
): Group[] =>
  edges.reduce((groupsAcc: any, dataRow: any, index: any) => {
    const groupByValue = groupValue(get(dataRow, key));

    const groupIndex = groupsAcc.findIndex((g: any) => g.key === groupByValue);
    if (groupIndex < 0) {
      return [
        ...groupsAcc,
        {
          id: groupByValue,
          key: groupByValue,
          rawValue: get(dataRow, labelPathFixed),
          label: renderLabel(get(dataRow, labelPathFixed)),
          rows: [{ ...dataRow, index }],
        },
      ];
    }

    return set(
      [groupIndex, 'rows'],
      [...groupsAcc[groupIndex].rows, { ...dataRow, index }],
      groupsAcc,
    );
  }, initialGroups);

const getGroupsFromEdgesForLevel = (
  edges: RecordEdge[],
  groupByConfig: GroupByConfig,
) => {
  const {
    key: keyPath,
    label: labelPath,
    groupValue = identity,
    renderLabel = identity,
    sortBy,
    initialGroups,
  } = groupByConfig;
  const key = keyPath.replace(LEADING_EDGE_NODES_REGEX, 'node.');
  const labelPathFixed = labelPath.replace(LEADING_EDGE_NODES_REGEX, 'node.');
  const groups = groupEdges(
    edges,
    key,
    labelPathFixed,
    renderLabel,
    groupValue,
    initialGroups,
  );

  if (sortBy) {
    groups.sort(sortBy);
  }

  return groups;
};

const getGroupsFromEdges = (
  edges: RecordEdge[],
  groupByConfigs: GroupByConfig[],
  parentGroup?: Group,
): Group[] => {
  const [groupByConfig, ...restConfigs] = groupByConfigs;
  const groups = getGroupsFromEdgesForLevel(edges, groupByConfig).map(
    (group) => ({
      ...group,
      id: parentGroup ? `${parentGroup.id}.${group.key}` : group.key,
      depth: parentGroup ? parentGroup.depth + 1 : 0,
    }),
  );

  if (restConfigs.length > 0) {
    return groups.map((group) => {
      const subGroups = getGroupsFromEdges(
        group.rows ?? [],
        restConfigs,
        group,
      ).map((subGroup) => ({
        ...subGroup,
        id: `${group.id}.${subGroup.key}`,
        depth: group.depth + 1,
      }));

      group.groups = subGroups;
      group.rows = undefined;
      return group;
    });
  }

  return groups;
};

const filterGroups = (
  group: Group,
  hideEmptyGroups: boolean,
  groupOptions: any = {},
  field?: DataField,
) => {
  if (hideEmptyGroups && (group.groups ?? group.rows ?? []).length === 0) {
    return false;
  }

  const optionConfig = groupOptions[group.key];
  if (field && field.type === SINGLE_OPTION && optionConfig) {
    return !get(optionConfig, 'hidden', false);
  }

  return true;
};

const getVisibleGroups = (
  groups: Group[],
  hideEmptyGroups: boolean,
  groupOptions: Record<string, { hidden?: boolean }> = {},
  field?: DataField,
): Group[] =>
  groups
    .filter((group) =>
      filterGroups(group, hideEmptyGroups, groupOptions, field),
    )
    .map((group) => {
      if (group.groups) {
        const subGroups = getVisibleGroups(group.groups, hideEmptyGroups);
        return {
          ...group,
          groups: subGroups,
        };
      }

      return group;
    });

const getGroupsById = (
  groups: Group[],
  parents: Group[] = [],
): Record<string, Group[]> =>
  groups.reduce(
    (acc, group) => ({
      ...acc,
      [group.id]: [...parents, group],
      ...(group.groups ? getGroupsById(group.groups, [...parents, group]) : []),
    }),
    {},
  );

const getCollapsedStateKey = (groupKey: any, elementId: any) =>
  `group.${elementId}.${groupKey}.collapse`;

const getInitialCollapsedState = (
  groupKeys: string[],
  groupOptions: any,
  elementId: any,
) =>
  groupKeys.reduce(
    (groupsAcc, groupKey) => ({
      ...groupsAcc,

      [groupKey]: new SafeStorage().getBoolean(
        getCollapsedStateKey(groupKey, elementId),
        get(groupOptions, [groupKey, 'collapsed'], false),
      ),
    }),
    {},
  );

type CollectionGroupsResult = {
  numFields: number;
  canUpdateViaDrag: boolean;
  groupOpenStates: Record<string, boolean>;
  groupCollapsedStates: Record<string, boolean>;
  toggleGroupState: (groupKey: string) => () => void;
  toggleGroupCollapsedState: (groupKey: string) => () => void;
  handleCardDrop: (droppedRecord: BaseRecord, droppedGroup: Group) => void;
  isLimited: boolean;
  firstSummaryIndex: number;
  visibleGroups: Group[];
};

const useCollectionGroups = ({
  customFilters,
  dataType,
  edges,
  elementId,
  enableDragAndDropEdit,
  fields,
  groupByFields,
  groupOptions,
  hideEmptyGroups,
  layout,
  limitPerGroup,
  nodeQueryObject,
}: CollectionGroupsConfig): CollectionGroupsResult => {
  const project = useSelector(projectDataSelector);
  const user = useScopeUser();
  const [draftEdges, setDraftEdges] = useState(edges);
  const numFields = fields.length;

  useEffect(() => {
    setDraftEdges(edges);
  }, [customFilters, edges]);

  const groupConfigs = useMemo(
    () =>
      groupByFields.map((group) =>
        getGroupByDepPaths(
          group.field,
          group.dataField,
          group.sort,
          dataType,
          project.dataTypes,
        ),
      ),
    [dataType, groupByFields, project.dataTypes],
  );

  const groupPermissions = useMemo(
    () =>
      groupByFields.map((group) =>
        dataType.fields.getById(group.dataField.id)
          ? fieldPermissions(
              group.dataField,
              dataType.permissionsEnabled,
              dataType.permissions,
              user,
            )
          : { read: true, update: true },
      ),
    [
      dataType.fields,
      dataType.permissions,
      dataType.permissionsEnabled,
      groupByFields,
      user,
    ],
  );

  const canUpdateViaDrag = useMemo(
    () =>
      enableDragAndDropEdit &&
      groupConfigs.every(
        (groupConfig, index) =>
          !!groupConfig.field &&
          groupPermissions[index].update &&
          (groupConfig.field.type !== DATE ||
            get(groupConfig.field, ['typeOptions', 'format']) ===
              DATE_FORMAT) &&
          groupByFields[index].field.path
            .replace(LEADING_EDGE_NODES_REGEX, '')
            .split('.').length === 1,
      ),
    [enableDragAndDropEdit, groupByFields, groupConfigs, groupPermissions],
  );

  const updateQueryString = useMemo(
    () =>
      gql`
        ${getMutationQueryString(
          UPDATE,
          dataType.name,
          dataType.fields,
          nodeQueryObject || {
            id: true,
            uuid: true,
          },
        )}
      `,
    [dataType.name, dataType.fields, nodeQueryObject],
  );

  const queryOptionsObj = useMemo(
    () => ({
      context: {
        projectQuery: true,
        projectName: project.name,
      },
    }),
    [project.name],
  );

  const [updateDataItem] = useMutation(updateQueryString, queryOptionsObj);
  const throttledUpdateDataItem = useMemo(() => throttle(updateDataItem, 500), [
    updateDataItem,
  ]);

  const groups = useMemo(() => getGroupsFromEdges(draftEdges, groupConfigs), [
    draftEdges,
    groupConfigs,
  ]);

  const groupsById = useMemo(() => getGroupsById(groups), [groups]);

  const [groupOpenStates, setGroupOpenStates] = useState({});
  const groupKeys = useMemo(() => Object.keys(groupsById), [groupsById]);

  const [groupCollapsedStates, setGroupCollapsedStates] = useState(
    getInitialCollapsedState(groupKeys, groupOptions, elementId),
  );

  const previousGroupKeys = usePrevious(groupKeys);

  useEffect(() => {
    if (
      previousGroupKeys &&
      previousGroupKeys.join('.') !== groupKeys.join('.')
    ) {
      setGroupCollapsedStates(
        getInitialCollapsedState(groupKeys, groupOptions, elementId),
      );
    }
  }, [elementId, groupKeys, groupOptions, previousGroupKeys]);

  const toggleGroupState = useCallback(
    (groupId: string) => () => {
      setGroupOpenStates((currentGroupOpenStates) => ({
        ...currentGroupOpenStates,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [groupId]: !currentGroupOpenStates[groupId],
      }));
    },
    [],
  );

  const toggleGroupCollapsedState = useCallback(
    (groupId: string) => () => {
      const storage = new SafeStorage();
      const localStorageKey = getCollapsedStateKey(groupId, elementId);

      setGroupCollapsedStates((currentGroupCollapsedStates: any) => {
        storage.set(localStorageKey, !currentGroupCollapsedStates[groupId]);
        return {
          ...currentGroupCollapsedStates,
          [groupId]: !currentGroupCollapsedStates[groupId],
        };
      });
    },
    [elementId],
  );

  const handleCardDrop = useCallback(
    (draggedRecord: BaseRecord, droppedGroup: Group) =>
      setDraftEdges((currentDraftEdges: RecordEdge[]) => {
        const recordIndex = currentDraftEdges.findIndex(
          (edge: any) => edge.node.id === draggedRecord.id,
        );

        const updateGroups = groupsById[droppedGroup.id];

        if (!updateGroups) {
          return currentDraftEdges;
        }

        const groupKeys = updateGroups.map((group, index) => {
          const field = groupByFields[index];

          const value = formatGroupValue(group.key, field.dataField);
          const fieldKeyPath = field.dataField.relationship
            ? [field.dataField.name, 'id']
            : [field.dataField.name];

          const fieldKey: string = field.dataField.relationship
            ? `${field.dataField.name}Id`
            : field.dataField.name;
          return { value, fieldKeyPath, fieldKey };
        }, {});

        const update = groupKeys.reduce((updateAcc, { fieldKey, value }) => {
          updateAcc[fieldKey] = value;
          return updateAcc;
        }, {} as Record<string, RecordValue>);

        throttledUpdateDataItem({
          variables: {
            id: draggedRecord.id,
            ...update,
          },
        });

        return groupKeys.reduce(
          (updatedEdges, { fieldKeyPath, value }) =>
            set([recordIndex, 'node', ...fieldKeyPath], value, updatedEdges),
          currentDraftEdges,
        ) as RecordEdge[];
      }),
    [groupByFields, groupsById, throttledUpdateDataItem],
  );

  const isLimited = !!limitPerGroup && layout === BOARD;
  const firstSummaryIndex = useMemo(() => {
    const summaryIndex = fields.findIndex(
      ({ config }: any, idx: any) => idx > 0 && !!config.groupSummary,
    );
    return summaryIndex < 0 ? fields.length : summaryIndex;
  }, [fields]);

  const visibleGroups = useMemo(
    () =>
      getVisibleGroups(
        groups,
        hideEmptyGroups,
        groupOptions,
        groupByFields[0].dataField,
      ),
    [groupByFields, groupOptions, groups, hideEmptyGroups],
  );

  return {
    numFields,
    canUpdateViaDrag,
    groupOpenStates,
    groupCollapsedStates,
    toggleGroupState,
    toggleGroupCollapsedState,
    handleCardDrop,
    isLimited,
    firstSummaryIndex,
    visibleGroups,
  };
};
export default useCollectionGroups;
