import get from 'lodash/get';
import initial from 'lodash/initial';
import {
  COLLECTION,
  DETAILS,
  FILE_GALLERY,
  HIGHLIGHTS,
  IFRAME,
  STAGES,
  TITLE,
  VIDEO,
  VIEW,
} from '../constants/elements';
import { DATABASE } from '../constants/scopeTypes';
import DataTypes, { DataType } from '../models/DataTypes';
import { Element } from '../models/Element';
import { User } from '../models/User';
import {
  extractFieldsFromActionButtons,
  findDependentValues,
  getDepsForConditions,
  getDepsForField,
} from './data';
import { getFieldByName, getRootFieldDataType } from './fields';
import { isOptionType } from './options';
import { mapFieldsWithPermissionsAndConfig } from './permissions';
import { RECORD_SCOPE } from './scope';

export const getDepsForFieldConfig = (
  field: any,
  config: any,
  elementId: any,
  dataType: any,
  dataTypes: any,
) => {
  let deps: any = [];

  if (field.relationship && config.customFilters) {
    config.customFilters.forEach((customFilter: any) => {
      if (customFilter.result && Array.isArray(customFilter.result)) {
        customFilter.result
          .filter(
            (segment: any) => segment.data && segment.data.id === RECORD_SCOPE,
          )
          .forEach((segment: any) => {
            deps.push({ ...segment.data, id: elementId, source: DATABASE });
          });
      }
    });
  }

  if (isOptionType(field.type) && config.optionsConfig) {
    deps = Object.values(config.optionsConfig).reduce((acc, optionConfig) => {
      if (!optionConfig || !(optionConfig as any).conditions) {
        return acc;
      }
      return [
        // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
        ...acc,
        ...getDepsForConditions(
          (optionConfig as any).conditions,
          dataType,
          dataTypes,
          elementId,
        ),
      ];
    }, deps);
  }

  return deps;
};

export const getDepsFromSection = (
  section: any,
  dataType: any,
  dataTypes: any,
  user: any,
  elementId: any,
  fieldPermissionsEnabled: any,
) => {
  if (!section) {
    return [];
  }

  const { type, props = {} } = section;

  switch (type) {
    case COLLECTION: {
      const collectionFilter = get(props, 'dataList.filter.path', '').split(
        '.',
      );

      if (collectionFilter && collectionFilter.length > 1) {
        const depPath = initial(collectionFilter).join('.');
        return [
          {
            id: elementId,
            path: `${depPath}.id`,
          },
          {
            id: elementId,
            path: `${depPath}.uuid`,
          },
        ];
      }

      return [];
    }
    case IFRAME:
    case VIDEO: {
      if (props && props.type === 'field' && props.field) {
        const field = dataType.fields.getByName(props.field);
        if (field) {
          return getDepsForField(field, dataTypes, elementId);
        }
      }
      return [];
    }
    case FILE_GALLERY: {
      if (props && props.field) {
        const field = dataType.fields.getByName(props.field);
        if (field) {
          return getDepsForField(field, dataTypes, elementId);
        }
      }
      return [];
    }
    case STAGES: {
      if (props.stages) {
        const field = dataType.fields.getByName(props.stages.path);
        if (!field) {
          return [];
        }

        return [
          props.stages,
          ...getDepsForFieldConfig(
            field,
            props,
            elementId,
            dataType,
            dataTypes,
          ),
        ];
      }

      return [];
    }
    case TITLE:
    case HIGHLIGHTS:
    case DETAILS: {
      const actionButtons = get(props, 'actionButtons', []);
      const fields = get(props, 'fields', []);
      const rootField = get(props, 'rootField');
      const rootFieldObject = getFieldByName(rootField, dataType);
      const rootDataType = getRootFieldDataType(rootField, dataType, dataTypes);

      return mapFieldsWithPermissionsAndConfig(
        [...fields, ...extractFieldsFromActionButtons(actionButtons)],
        rootDataType,
        user,
        dataTypes,
        fieldPermissionsEnabled,
        // @ts-expect-error TS(2769): No overload matches this call.
      ).reduce((depAcc, { config, field }) => {
        if (field) {
          return [
            ...depAcc,
            ...getDepsForField(
              field,
              dataTypes,
              elementId,
              rootFieldObject ? `${rootFieldObject.apiName}.` : undefined,
            ),
            ...getDepsForFieldConfig(
              field,
              config,
              elementId,
              dataType,
              dataTypes,
            ),
            ...((config as any).conditions
              ? getDepsForConditions(
                  (config as any).conditions,
                  dataType,
                  dataTypes,
                  elementId,
                )
              : []),
          ];
        }
        return depAcc;
      }, []);
    }
    default:
      return [];
  }
};

export const extractDepsFromSections = (
  sections: any,
  dataType: any,
  dataTypes: any,
  user: any,
  elementId: any,
  fieldPermissionsEnabled: any,
) =>
  sections
    .filter(Boolean)
    .reduce(
      (depsAcc: any, section: any) => [
        ...depsAcc,
        ...getDepsFromSection(
          section,
          dataType,
          dataTypes,
          user,
          elementId,
          fieldPermissionsEnabled,
        ),
      ],
      [],
    );

export const getDepsForViewSections = (
  element: Element,
  mappedSections: any,
  dataTypes: DataTypes,
  dataType: DataType,
  user: User,
  actionButtons = [],
  tabs = [],
  title = null,
  fieldPermissionsEnabled = true,
) => {
  const viewId = `${element.id}:VIEW`;
  let deps = findDependentValues(
    viewId,
    {
      id: viewId,
      children: mappedSections,
      props: {
        record: {
          subtitle: get(element, 'props.record.subtitle'),
          editButtonText: get(element, 'props.record.editButtonText'),
          doneButtonText: get(element, 'props.record.doneButtonText'),
          editButton: get(element, 'props.record.editButton'),
          actionButtons: actionButtons.filter(
            (actionButton) =>
              // Note: !== false is required as by default this will be undefined and still be displayed.
              get(actionButton, ['display', 'record']) !== false,
          ),
          tabs,
        },
      },
      type: VIEW,
    },
    dataTypes,
  );

  const sectionDeps = extractDepsFromSections(
    mappedSections,
    dataType,
    dataTypes,
    user,
    element.id,
    fieldPermissionsEnabled,
  );

  deps = deps.concat(sectionDeps);

  return mapFieldsWithPermissionsAndConfig(
    [
      ...(title
        ? [
            {
              name: title,
            },
          ]
        : []),
      ...extractFieldsFromActionButtons(actionButtons),
    ],
    dataType,
    user,
    dataTypes,
    fieldPermissionsEnabled,
  )
    .reduce((depAcc, { field }) => {
      if (field) {
        return [...depAcc, ...getDepsForField(field, dataTypes, element.id)];
      }
      return depAcc;
    }, deps)
    .map((dep: any) => ({
      ...dep,
      path: dep.path.replace(/^edges\.node\./, ''),
    }));
};
