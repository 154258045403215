import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
// eslint-disable-next-line no-restricted-imports
import useDarkMode from '@noloco/core/src/utils/hooks/useDarkMode';
import { PRIMARY } from '../../constants/variants';
import { getColorShade } from '../../utils/colors';
import { oneOfVariants } from '../../utils/propTypes';
import {
  ROUNDED_FULL,
  ROUNDED_LARGE,
  SHADOW,
  SQUARE,
  UPPERCASE,
} from '../button/buttonStyles';
import { FILLED, OUTLINE, THICK_OUTLINE } from './badgeTypes';

export const typeStyles = (color: string, useDarkModeColors: boolean) => {
  const bgOrOutlineColor = getColorShade(color, useDarkModeColors ? 700 : 200);
  const baseStyles = {
    bg: bgOrOutlineColor,
    text: [getColorShade(color, useDarkModeColors ? 200 : 700), 'xs'],
  };

  return {
    default: baseStyles,
    [FILLED]: baseStyles,
    [OUTLINE]: {
      ...baseStyles,
      bg: 'transparent',
      border: [true, bgOrOutlineColor],
    },
    [THICK_OUTLINE]: {
      ...baseStyles,
      bg: 'transparent',
      border: [2, bgOrOutlineColor],
    },
  };
};

export const themeStyles = {
  default: { rounded: true },
  [ROUNDED_LARGE]: { rounded: 'lg' },
  [ROUNDED_FULL]: { rounded: 'full' },
  [SQUARE]: { rounded: false },
  [UPPERCASE]: { uppercase: true },
  [SHADOW]: { rounded: true, shadow: true },
};

type BadgeProps = {
  ignoreDarkMode?: boolean;
  className?: string;
  type?: any; // TODO: oneOfWithDefault(buttonTypes)
  style?: any; // TODO: oneOfWithDefault(buttonStyles)
  theme: {};
  // @ts-expect-error TS(2749): 'oneOfVariants' refers to a value, but is being us... Remove this comment to see the full error message
  variant?: oneOfVariants;
};

const Badge = forwardRef<any, BadgeProps>(
  (
    {
      ignoreDarkMode,
      // @ts-expect-error TS(2339): Property 'color' does not exist on type 'BadgeProp... Remove this comment to see the full error message
      color,
      children,
      className,
      style,
      type,
      theme,
      variant,
      ...rest
    },
    ref,
  ) => {
    const badgeColor = color || (theme as any).brandColors[variant];
    const [isDarkModeEnabled] = useDarkMode();
    const useDarkModeColors = !ignoreDarkMode && isDarkModeEnabled;

    return (
      <Box
        className={classNames(
          'inline-flex flex-col items-center justify-center whitespace-normal max-w-full',
          className,
        )}
        is="span"
        ref={ref}
        p={{ x: 3, y: 0 }}
        m={{ r: 'auto' }}
        {...rest}
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        {...themeStyles[style]}
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        {...typeStyles(badgeColor, useDarkModeColors as boolean)[type]}
      >
        <div className="flex items-center justify-center">{children}</div>
        <span
          aria-hidden="true"
          className="block invisible h-0 whitespace-nowrap overflow-hidden"
        >
          {children}
        </span>
      </Box>
    );
  },
);

Badge.defaultProps = {
  className: '',
  type: 'default',
  style: 'default',
  variant: PRIMARY,
};

export default withTheme(Badge);
