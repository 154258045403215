import { useMemo } from 'react';
import { DataType } from '../../models/DataTypes';
import { FormConfigWithField, Section } from '../../models/View';
import { getDataTypeQueryObject } from '../../queries/data';
import { getPageQueryString } from '../../queries/project';

type AutoFormConfig = {
  dataTypeName: string;
  dataTypeWithRelations: DataType;
  fieldConfigs: FormConfigWithField[];
  itemQueryString: string;
  sectionConfigs: Section[];
};

const useAutoFormConfig = (
  dataType: any,
  fields: any,
  project: any,
  recordId: any,
  sections?: any,
): AutoFormConfig => {
  const dataTypeName = useMemo(() => dataType && dataType.name, [dataType]);

  const itemQueryString = useMemo(
    () =>
      dataType &&
      getPageQueryString(
        dataType.name,
        { id: recordId },
        getDataTypeQueryObject(dataType, project.dataTypes, {
          includeCollections: true,
          includeNestedFields: true,
          includeHidden: true,
        }),
      ),
    [dataType, project.dataTypes, recordId],
  );

  const fieldConfigs = useMemo(
    () =>
      dataType &&
      fields
        .map((fieldConfig: any) => ({
          field: dataType.fields.getByName(fieldConfig.field),
          config: fieldConfig,
        }))
        .filter((fieldConfig: any) => fieldConfig.field),
    [dataType, fields],
  );

  const sectionConfigs = useMemo(
    () =>
      sections &&
      sections.map((sectionConfig: any) => ({
        config: {
          conditions: sectionConfig.conditions,
          helpText: sectionConfig.helpText,
          label: sectionConfig.label,
          name: sectionConfig.name,
          placeholder: sectionConfig.placeholder,
          value: sectionConfig.value,
        },

        section: {
          fields: sectionConfig.fields,
          id: sectionConfig.id,
        },
      })),
    [sections],
  );

  return {
    dataTypeName,
    dataTypeWithRelations: dataType,
    fieldConfigs,
    itemQueryString,
    sectionConfigs,
  };
};

export default useAutoFormConfig;
