import React, { useCallback, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { Box } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import Markdown from 'markdown-to-jsx';
import { Theme } from '@noloco/components';
import { getColorShade } from '@noloco/components/src/utils/colors';
import Checkbox from '../elements/Checkbox';
import { formatRichTextBulletsAndLists } from '../utils/markdown';
import { getLinkProps } from '../utils/urls';

const TARGET_BLANK = '{target=_blank}';
const BASE_SIZES = {
  h1: 'text-3xl',
  h2: 'text-2xl',
  h3: 'text-xl',
};
const SMALL_SIZES = {
  h1: 'text-2xl',
  h2: 'text-xl',
  h3: 'text-lg',
};

// @ts-expect-error TS(7006): Parameter 'primaryColor' implicitly has an 'any' t... Remove this comment to see the full error message
const elementClassNames = (primaryColor, sizes) => ({
  h1: classNames('font-black mb-6 mt-0 leading-none', sizes.h1),
  h2: classNames('font-bold mb-4 mt-0 leading-snug', sizes.h2),
  h3: classNames('font-semibold mb-2 mt-6 leading-normal', sizes.h3),

  p: 'font-base my-2 leading-normal',
  blockquote:
    'font-base border-gray-200 border-l-2 pl-4 italic mb-3 mt-4 leading-relaxed',
  ol: 'list-decimal pl-5',
  ul: 'list-disc pl-5',
  input: '-ml-5 mr-2',

  code: classNames(
    `font-mono text-${getColorShade(primaryColor, 700)} bg-${getColorShade(
      primaryColor,
      100,
    )} rounded p-0.5 tracking-wider`,
  ),
});

const TRAILING_SPACE_REGEX = /\n([^\n\S]+)/g;
const replaceTrailingSpaceFollowingNewLine = (string: string) =>
  string.replace(TRAILING_SPACE_REGEX, '\n');

const Paragraph = ({
  className,
  children,
}: {
  className: string;
  children: any;
}) => (
  <p className={className}>
    {children &&
      children.map((componentOrString: any) =>
        componentOrString && typeof componentOrString === 'string'
          ? replaceTrailingSpaceFollowingNewLine(componentOrString)
          : componentOrString,
      )}
  </p>
);

const ListItem = ({ children, ...props }: any) => {
  const isCheckList = React.Children.toArray(children).some(
    (child) => get(child, 'props.type', null) === 'checkbox',
  );

  return (
    <li
      {...props}
      className={classNames({
        'list-none my-1': isCheckList,
      })}
    >
      {children}
    </li>
  );
};

const Pre = ({ children, sizes }: any) =>
  children.props && (
    <pre
      className={classNames(
        'font-mono text-gray-700 bg-gray-100 rounded p-2 tracking-wider border mt-3',
        sizes.text,
      )}
    >
      {children.props.children}
    </pre>
  );

const MarkdownLink = ({ children, sizes, primaryColor, ...props }: any) => {
  const href = get(props, 'href', '');
  // @ts-expect-error TS(2554): Expected 2-3 arguments, but got 1.
  const linkProps = getLinkProps({
    ...(first(href) === '/' ? { to: href } : { href }),
    target: href.includes(TARGET_BLANK) ? '_blank' : '_self',
  });

  const onClick = useCallback(
    (e: any) => {
      e.stopPropagation();

      if (props.onClick) {
        props.onClick(e);
      }
    },
    [props],
  );

  return (
    <Box
      {...props}
      {...linkProps}
      href={(linkProps as any).href?.replace(TARGET_BLANK, '')}
      to={linkProps.to?.replace(TARGET_BLANK, '')}
      onClick={onClick}
      rel="noreferrer"
      className={classNames(
        `cursor-pointer hover:underline break-all text-${getColorShade(
          primaryColor,
          500,
        )} hover:text-${getColorShade(primaryColor, 700)}`,
        sizes.text,
      )}
    >
      {children}
    </Box>
  );
};

const headings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const getOptions = (
  primaryColor: any,
  disableHeadings = false,
  sizes = BASE_SIZES,
  otherOverrides = {},
) => {
  const themedClassNames = elementClassNames(primaryColor, sizes);

  return {
    disableParsingRawHTML: Object.keys(otherOverrides).length === 0,
    overrides: Object.entries(themedClassNames).reduce(
      (acc, [Element, className]) => ({
        [Element]: {
          component: (props: any) => (
            <Element
              {...props}
              className={
                disableHeadings && headings.includes(Element)
                  ? themedClassNames.p
                  : className
              }
            >
              {props.children}
            </Element>
          ),
        },
        ...acc,
      }),
      {
        pre: {
          component: (props: any) => <Pre {...props} sizes={sizes} />,
        },
        a: {
          component: ({ children, ...props }: any) => (
            <MarkdownLink {...props} sizes={sizes} primaryColor={primaryColor}>
              {children}
            </MarkdownLink>
          ),
        },
        p: {
          component: ({ children }: { children: any }) => (
            <Paragraph className={themedClassNames.p}>{children}</Paragraph>
          ),
        },
        li: {
          component: ({ children, ...props }: any) => (
            <ListItem {...props}>{children}</ListItem>
          ),
        },
        input: {
          component: ({ ...props }: any) => (
            <span className={themedClassNames.input}>
              <Checkbox size="md" disabled={true} {...props} />
            </span>
          ),
        },
        ...otherOverrides,
      },
    ),
  };
};

type OwnMarkdownTextProps = {
  children?: string | null;
  className?: string;
  disabledHeadings?: boolean;
  markdownText?: string | null;
  overrides?: Record<string, any>;
  small?: boolean;
};

type MarkdownTextProps = OwnMarkdownTextProps & {
  theme: Theme;
};

const MarkdownText = ({
  children,
  className,
  disabledHeadings,
  markdownText,
  overrides,
  small,
  theme,
}: MarkdownTextProps) => {
  const primaryColor = theme.brandColors.primary;
  const themedOptions = useMemo(() => {
    const sizes = small ? SMALL_SIZES : BASE_SIZES;
    return getOptions(primaryColor, disabledHeadings, sizes, overrides);
  }, [disabledHeadings, overrides, primaryColor, small]);

  if (typeof markdownText !== 'string' && typeof children !== 'string') {
    return null;
  }

  return (
    <Markdown className={className} options={themedOptions}>
      {formatRichTextBulletsAndLists(
        (typeof markdownText === 'string' && markdownText) ||
          (typeof children === 'string' && children) ||
          '',
      )}
    </Markdown>
  );
};

MarkdownText.defaultProps = {
  disabledHeadings: false,
};

export default withTheme(MarkdownText) as React.FC<OwnMarkdownTextProps>;
