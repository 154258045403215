import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { DETAILS } from '../../../constants/elements';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import useRouter from '../../../utils/hooks/useRouter';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { RECORD_SCOPE } from '../../../utils/scope';
import ActionButton from './ActionButton';

const RecordActionButtons = ({
  actionButtons,
  backLink,
  buttonType,
  dataType,
  editorMode,
  elementPath,
  project,
  record,
  recordScope,
  rootPathname,
}: any) => {
  const { push } = useRouter();
  const visibleActionButtons = useMemo(
    () =>
      actionButtons
        // @ts-expect-error TS(7006): Parameter 'actionButton' implicitly has an 'any' t... Remove this comment to see the full error message
        .map((actionButton, idx) => ({
          actionButton,
          idx,
        }))
        .filter(
          ({ actionButton }: any) =>
            // Note: !== false is required as by default this will be undefined and still be displayed.
            get(actionButton, ['display', 'record']) !== false,
        ),
    [actionButtons],
  );

  const { actionButtons: parsedActionButtons } = useSectionScopeVariables(
    DETAILS,
    {
      actionButtons: visibleActionButtons.map(
        ({ actionButton }: any) => actionButton,
      ),
    },
    project,
    elementPath,
    recordScope,
    skipPropResolvingByValueIds([RECORD_SCOPE]),
  );

  const handleDeleteRecord = useCallback(
    (__, onNext) => {
      if (backLink && backLink.enabled) {
        push(backLink.to);
      } else {
        push(rootPathname);
      }
      onNext();
    },
    [push, backLink, rootPathname],
  );

  return parsedActionButtons.map((actionButton: any, index: any) => (
    <ActionButton
      actionButton={actionButton}
      buttonType={buttonType}
      dataType={dataType}
      editorMode={editorMode}
      index={visibleActionButtons[index].idx}
      key={actionButton.id}
      onDeleteRecord={handleDeleteRecord}
      record={record}
      recordScope={recordScope}
      project={project}
    />
  ));
};

export default RecordActionButtons;
