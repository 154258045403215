import React, { useMemo } from 'react';
import { IconArrowBack, IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';
import reverse from 'lodash/fp/reverse';
import { Link } from 'react-router-dom';
import { darkModeColors } from '../../../constants/darkModeColors';
import { BREADCRUMBS } from '../../../constants/elements';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useSetDocumentTitle from '../../../utils/hooks/useSetDocumentTitle';
import { getText } from '../../../utils/lang';
import Icon from '../../Icon';

type OwnProps = {
  additionalLinks?: {
    to: string;
    name: string;
  }[];
  name?: string;
  rootPathname?: string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ViewBreadcrumbs.defaultProps;

// @ts-expect-error TS(7022): 'ViewBreadcrumbs' implicitly has type 'any' becaus... Remove this comment to see the full error message
const ViewBreadcrumbs = ({
  additionalLinks,
  backLink,
  className,
  rootPathname,
  icon,
  name,
}: Props) => {
  const documentTitle = useMemo(
    () =>
      [...reverse(additionalLinks), { name }]
        .map((additionalLink, index, links) =>
          typeof additionalLink.name === 'string'
            ? `${additionalLink.name}${index !== links.length - 1 ? ' — ' : ''}`
            : null,
        )
        .filter(Boolean)
        .join(''),
    [additionalLinks, name],
  );

  const [isDarkModeEnabled] = useDarkMode();

  const textColor = isDarkModeEnabled
    ? darkModeColors.text.secondary
    : 'text-gray-600';
  const textColorHover = isDarkModeEnabled
    ? darkModeColors.text.primary
    : 'text-gray-800';

  useSetDocumentTitle(documentTitle);

  return (
    <div
      className={classNames(
        className,
        'flex-col font-medium tracking-wider text-sm',
        textColor,
      )}
    >
      {backLink && backLink.enabled && (
        <Link to={backLink.to} className="flex items-center mb-3">
          <IconArrowBack className="mr-2 opacity-75 h-4 w-4" />
          <span className="text-xs ">
            {getText(
              { pageName: backLink.name },
              'elements',
              BREADCRUMBS,
              'back',
            )}
          </span>
        </Link>
      )}
      <div className="space-x-2 flex items-center sm:flex-wrap">
        <Link
          className={classNames(
            'flex items-center hover:underline',
            `hover:${textColorHover}`,
          )}
          to={`${rootPathname}${
            (backLink && !backLink.enabled && backLink.qs) || ''
          }`}
        >
          {icon && (
            <Icon icon={icon} className="mr-3 opacity-75 h-6 w-6" size={6} />
          )}
          <span>{name}</span>
        </Link>
        {additionalLinks.map((additionalLink: any) => (
          <div className="flex items-center space-x-2" key={additionalLink.to}>
            <IconChevronRight size={12} className="flex-shrink-0 opacity-75" />
            <Link
              className={classNames(
                'flex items-center hover:underline',
                `hover:${textColorHover}`,
              )}
              to={`${rootPathname}${additionalLink.to}`}
            >
              <span>{additionalLink.name}</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

ViewBreadcrumbs.defaultProps = {
  additionalLinks: [],
};

export default ViewBreadcrumbs;
