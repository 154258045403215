import get from 'lodash/fp/get';
import { BOOLEAN, INTEGER } from '../constants/dataTypes';
import { API_REQUEST, INTERNAL } from '../constants/dataWrapperTypes';
import * as elements from '../constants/elements';
import { DATABASE, DERIVED } from '../constants/scopeTypes';
import { Element } from '../models/Element';
import { Project } from '../models/Project';
import StateItem from '../models/StateItem';
import { findEndpoint } from './apis';
import {
  getApiEndpointCollectionOptions,
  getApiEndpointDataTypeOptions,
} from './apis';
import { getText } from './lang';

export const getStateItemOption = (stateItem: StateItem) => ({
  value: stateItem,
  label: stateItem.display,
});

const getListDefaultStateItems = (id: string) => [
  getStateItemOption(
    new StateItem({
      id,
      dataType: INTEGER,
      path: 'index',
      source: DERIVED,
      display: getText('elements', elements.LIST, 'data.index'),
    }),
  ),
  getStateItemOption(
    new StateItem({
      id,
      dataType: BOOLEAN,
      path: 'isFirst',
      source: DERIVED,
      display: getText('elements', elements.LIST, 'data.first'),
    }),
  ),
  getStateItemOption(
    new StateItem({
      id,
      dataType: BOOLEAN,
      path: 'isLast',
      source: DERIVED,
      display: getText('elements', elements.LIST, 'data.last'),
    }),
  ),
  getStateItemOption(
    new StateItem({
      id,
      dataType: INTEGER,
      path: 'totalCount',
      source: DATABASE,
      display: getText('elements', elements.LIST, 'data.totalCount'),
    }),
  ),
];

export const deriveViewState = (
  { id, props: { dataList } }: Element,
  project: Project,
  elementPath: string[],
  context: any,
) => {
  let state: any[] = [];
  if (
    dataList.dataType &&
    (!dataList.dataSource || dataList.dataSource === INTERNAL)
  ) {
    const dataType = project.dataTypes.getByName(dataList.dataType);

    if (dataType) {
      const label = getText(
        { dataType: dataType.display },
        'elements',
        elements.LIST,
        'state.listItem',
      );

      // If we're not requesting a collecion, we're requesting the individual values of one of the records
      if (!context.collections) {
        state = state.concat(
          context.getDataTypeOptions(
            new StateItem({
              id: `${id}:VIEW`,
              dataType: dataList.dataType,
              path: '',
              source: DATABASE,
              display: label,
            }),
          ),
        );
      } else {
        state = state.concat(
          context.getDataTypeOptions(
            new StateItem({
              id: `${id}:VIEW`,
              dataType: dataList.dataType,
              path: 'edges.node',
              source: DATABASE,
              display: label,
            }),
          ),
        );
      }
    }
  }

  return state;
};

export const getDataListState = (
  id: string,
  props: Record<any, any> = {},
  project: Project,
  context: any,
) => {
  let state: any[] = [];
  if (props.dataType && (!props.dataSource || props.dataSource === INTERNAL)) {
    const dataType = project.dataTypes.getByName(props.dataType);

    if (dataType) {
      const label = getText(
        { dataType: dataType.display },
        'elements',
        elements.LIST,
        'state.listItem',
      );

      state = state.concat(
        context.getDataTypeOptions(
          new StateItem({
            id,
            dataType: props.dataType,
            path: 'edges.node',
            source: DATABASE,
            display: label,
          }),
        ),
      );
    }
  }

  if (
    props.dataSource === API_REQUEST &&
    props.dataType &&
    props.endpoint &&
    props.filter &&
    !context.collections
  ) {
    const { apiId, endpointId } = props.endpoint;
    const { endpoint } = findEndpoint(project.apis, apiId, endpointId);

    if (endpoint) {
      const filterPath = props.filter.path.split('.').slice(1);
      const dataShape =
        filterPath.length > 0
          ? get(filterPath, endpoint.dataShape)
          : endpoint.dataShape;

      if (Array.isArray(dataShape)) {
        const newEndpoint = {
          ...endpoint,
          dataShape: dataShape[0],
        };

        return [
          ...getApiEndpointDataTypeOptions(id, newEndpoint, undefined, [
            'edges',
          ]).map((option) =>
            option instanceof StateItem ? getStateItemOption(option) : option,
          ),
          ...getListDefaultStateItems(id),
        ];
      }
    }
  }

  return state;
};

export const getApiRequestState = (
  { id, props: { endpoint = {} } = {} }: Partial<Element>,
  project: Project,
  __: any,
  context: any,
) => {
  const { endpointId, apiId } = endpoint;
  const { acceptableDataTypes, collections } = context;

  if (!acceptableDataTypes) {
    return [];
  }

  const { endpoint: endpointDef } = findEndpoint(
    project.apis,
    apiId,
    endpointId,
  );

  if (!endpointDef) {
    return [];
  }

  if (!collections) {
    return getApiEndpointDataTypeOptions(
      id!,
      endpointDef,
      acceptableDataTypes,
    ).map((option) =>
      option instanceof StateItem ? getStateItemOption(option) : option,
    );
  }

  if (acceptableDataTypes.includes(`ENDPOINT:${endpointId}`)) {
    return getApiEndpointCollectionOptions(id!, endpointDef).map((option) =>
      option instanceof StateItem ? getStateItemOption(option) : option,
    );
  }

  return [];
};
