import React, { useCallback, useMemo, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { filterOptions } from '../select/OptionList';
import SelectBase, { SelectOption } from '../select/SelectBase';
import { TextInput, TextInputProps } from './TextInput';

type Props = TextInputProps & {
  onChange: (nextValue: string) => void;
  options: SelectOption[];
};

const AutocompleteTextInput = ({
  disabled,
  readOnly,
  onChange,
  options,
  surface,
  value,
  ...rest
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const filteredOptions = useMemo(() => {
    if (!value) {
      return options;
    }

    return options.filter(filterOptions(value));
  }, [options, value]);

  const handleSelect = useCallback(
    (nextValue) => {
      if (onChange) {
        onChange({ target: { value: nextValue } });
      }
      setIsOpen(false);
    },
    [onChange],
  );

  const handleTextChange = useCallback(
    (event) => {
      if (onChange) {
        onChange(event);
      }
    },
    [onChange],
  );

  return (
    <SelectBase
      className="w-full"
      disabled={readOnly || disabled}
      fillBackground={false}
      onChange={handleSelect}
      options={filteredOptions}
      surface={surface}
      showEmptyState={false}
      open={isOpen}
    >
      {() => (
        <TextInput
          {...rest}
          disabled={disabled}
          onBlur={() => setIsOpen(false)}
          onFocus={() => setIsOpen(true)}
          readOnly={readOnly}
          value={value}
          onChange={handleTextChange}
          surface={surface}
        />
      )}
    </SelectBase>
  );
};

export default withTheme(AutocompleteTextInput);
