import { createSlice } from '@reduxjs/toolkit';
import { setAutoFreeze } from 'immer';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import initial from 'lodash/initial';
import last from 'lodash/last';
import mutableSet from 'lodash/set';
import { VIEW } from '@noloco/core/src/constants/elements';
import DataTypeFields from '@noloco/core/src/models/DataTypeFields';
import DataTypes, {
  DataSource,
  DataType,
  DataTypeArray,
} from '@noloco/core/src/models/DataTypes';
import { Element, ElementPath } from '@noloco/core/src/models/Element';
import { Workflow } from '@noloco/core/src/models/Workflow';
import DataTypePermissions from '../models/DataTypePermissions';
import { Permission } from '../models/Permission';
import { Project, ProjectUser } from '../models/Project';
import {
  buildFormattedReverseRelateField,
  getDataTypesWithRelations,
} from '../utils/data';
import { rollupToFakeField } from '../utils/fields';

// This is necessary because auto-freezing causes BaseArrayTypeMap can't be built
// if the object is frozen
setAutoFreeze(false);

const unwrapReduxProxy = (object: Record<any, any>) =>
  JSON.parse(JSON.stringify(object));

export type ProjectState = Project;

export type ProjectUpdate = {
  path: ElementPath;
  value: any;
};

const projectSlice = createSlice({
  name: 'project',
  initialState: {
    data: null as null | ProjectState,
    undoStack: [] as ProjectUpdate[],
    redoStack: [] as ProjectUpdate[],
  },
  reducers: {
    addMedia: (state, { payload }) => {
      state.data = {
        ...state.data,
        media: [...(state.data?.media ?? []), payload],
      } as ProjectState;
    },
    redo: (state) => {
      const redoUpdate = last(state.redoStack);
      if (redoUpdate) {
        const existingValue = get(state.data, redoUpdate.path);
        state.undoStack.push({ ...redoUpdate, value: existingValue });
        state.redoStack = initial(state.redoStack);
      }
    },
    undo: (state) => {
      const undoUpdate = last(state.undoStack);
      if (undoUpdate) {
        const existingValue = get(state.data, undoUpdate.path);
        state.redoStack.push({ ...undoUpdate, value: existingValue });
        state.undoStack = initial(state.undoStack);
      }
    },
    updateProject: (state, { payload: { value, path, skipHistory } }) => {
      if (first(path) === 'elements' && !skipHistory) {
        const existingValue = get(state.data, path);
        state.undoStack.push({
          path,
          value: existingValue,
        });
        state.redoStack = [];
      }
      mutableSet(state.data as Record<any, any>, path, value);
    },
    updateProjectStatus: (state, { payload }) => {
      if (state.data) {
        state.data.live = payload;
      }
    },
    setProject: (state, { payload }) => {
      state.data = {
        ...payload,
        dataTypes: getDataTypesWithRelations(payload.dataTypes),
      };
    },
    updateSource: (state, { payload: dataSource }: { payload: DataSource }) => {
      const dataTypesToUpdate = state.data?.dataTypes?.filter(
        ({ source }) => source.id === dataSource.id,
      );

      dataTypesToUpdate?.forEach((dataType) => {
        const idx = state.data?.dataTypes.findIndex(
          ({ id }) => id === dataType.id,
        );
        if (idx !== undefined && idx >= 0) {
          const updateDataSource = set(
            'source.display',
            dataSource.display,
            dataType,
          );
          state.data = set(
            ['dataTypes', idx],
            updateDataSource,
            state.data as ProjectState,
          );
        }
      });
    },
    setHasUnpublishedChanges: (state, { payload }) => {
      if (state.data) {
        state.data.hasUnpublishedChanges = payload;
      }
    },
    incrementPublishedVersion: (state) => {
      if (state.data) {
        state.data.publishedVersion = (state.data.publishedVersion ?? 0) + 1;
      }
    },
    setPublishedVersion: (state, { payload }) => {
      if (state.data) {
        state.data.publishedVersion = payload;
      }
    },
    addDataField: (state, { payload: { dataTypeName, dataField } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ name }) => name === dataTypeName,
      );
      if (state.data && typeIndex !== undefined) {
        state.data.dataTypes[typeIndex].fields.push(dataField);
        if (dataField.relationship) {
          const relatedType = state.data?.dataTypes.find(
            ({ name }) => name === dataField.type,
          );
          if (relatedType) {
            const reverseField = buildFormattedReverseRelateField(
              dataField,
              state.data.dataTypes[typeIndex] as DataType,
              relatedType,
            );
            const relatedTypeIndex = state.data?.dataTypes.findIndex(
              ({ name }) => name === relatedType.name,
            );
            state.data.dataTypes[relatedTypeIndex].fields.push(reverseField);
          }
        }
      }
    },
    addDataRollup: (state, { payload: { dataTypeName, dataRollup } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ name }) => name === dataTypeName,
      );
      if (state.data && typeIndex !== undefined) {
        state.data.dataTypes[typeIndex].rollups.push(dataRollup);

        const rollupField = unwrapReduxProxy(
          rollupToFakeField(
            dataRollup,
            state.data.dataTypes[typeIndex] as DataType,
            state.data.dataTypes as DataTypes,
          ),
        );

        state.data.dataTypes[typeIndex].fields = new DataTypeFields([
          ...state.data.dataTypes[typeIndex].fields,
          rollupField,
        ]);
      }
    },
    removeDataRollup: (state, { payload: { dataTypeId, id } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );
      if (state.data && typeIndex !== undefined) {
        state.data.dataTypes[typeIndex].rollups = state.data.dataTypes[
          typeIndex
        ].rollups.filter((rollup) => rollup.id !== id);
      }
    },
    removeDataField: (state, { payload: { dataTypeId, dataField } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );
      if (state.data && typeIndex !== undefined) {
        state.data.dataTypes[typeIndex].fields = new DataTypeFields(
          state.data.dataTypes[typeIndex].fields.filter(
            ({ id }) => id !== dataField.id,
          ),
        );
      }
    },
    updateDataField: (state, { payload: { dataTypeId, dataField } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );
      const fieldIndex =
        typeIndex !== undefined
          ? state.data?.dataTypes[typeIndex].fields.findIndex(
              ({ id }) => id === dataField.id,
            )
          : undefined;
      if (state.data && typeIndex !== undefined && fieldIndex !== undefined) {
        state.data.dataTypes[typeIndex].fields[fieldIndex] = dataField;
      }
    },
    setPermissionsEnabled: (
      state,
      { payload: { dataTypeId, permissionsEnabled } },
    ) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );

      if (state.data && typeIndex !== undefined) {
        state.data = set(
          ['dataTypes', typeIndex, 'permissionsEnabled'],
          permissionsEnabled,
          state.data,
        );
      }
    },
    setPermissionRules: (state, { payload: { dataTypeId, permissions } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );

      if (state.data && typeIndex !== undefined) {
        state.data.dataTypes[typeIndex].permissions = new DataTypePermissions(
          permissions,
        );
      }
    },
    setPermissionRule: (state, { payload: { dataTypeId, permission } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );

      const permissionIndex =
        typeIndex &&
        state.data?.dataTypes[typeIndex].permissions.findIndex(
          ({ id }) => id === permission.id,
        );

      if (
        state.data &&
        typeIndex !== undefined &&
        permissionIndex !== undefined
      ) {
        state.data.dataTypes[typeIndex].permissions = new DataTypePermissions(
          set(permissionIndex, permission, [
            ...state.data.dataTypes[typeIndex].permissions,
          ]) as Permission[],
        );
      }
    },
    removeDataSource: (state, { payload: { dataSource } }) => {
      const dataTypesToRemove = state.data?.dataTypes.filter(
        ({ source }) =>
          source.id === dataSource.id && source.type === dataSource.type,
      );

      dataTypesToRemove?.forEach((dataType) =>
        projectSlice.caseReducers.removeDataType(state, {
          payload: { dataType },
        } as { payload: { dataType: DataType } }),
      );
    },
    removeDataType: (
      state,
      { payload: { dataType } }: { payload: { dataType: DataType } },
    ) => {
      if (!state.data) {
        return state;
      }

      state.data.dataTypes = new DataTypes(
        state.data?.dataTypes
          .filter(({ id }) => id !== dataType.id)
          .map((dt) =>
            set(
              'fields',
              new DataTypeFields(
                dt.fields.filter((field) => field.type !== dataType.name),
              ),
              dt,
            ),
          ),
      );

      state.data.elements = state.data.elements.filter(
        (element: Element) =>
          !(
            element.type === VIEW &&
            get(element, 'props.dataList.dataType') === dataType.name
          ),
      );
    },
    removePermissionRule: (
      state,
      { payload: { dataTypeId, permissionId } },
    ) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );

      if (state.data && typeIndex !== undefined) {
        const withoutRemovedPermission = new DataTypePermissions(
          [...state.data.dataTypes[typeIndex].permissions].filter(
            (it) => it.id !== permissionId,
          ) as Permission[],
        );
        state.data.dataTypes[typeIndex].permissions = withoutRemovedPermission;
      }
    },
    addDataType: (state, { payload }) => {
      if (state.data) {
        state.data.dataTypes.push(DataTypeArray.formatDataType(payload));
      }
    },
    addDataTypes: (state, { payload }) => {
      if (state.data) {
        state.data.dataTypes.push(...payload.map(DataTypes.formatDataType));
      }
    },
    updateDataType: (state, { payload: dataType }) => {
      const idx = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataType.id,
      );
      if (idx !== undefined && idx >= 0 && state.data) {
        state.data.dataTypes[idx] = DataTypes.formatDataType(dataType);
      }
    },
    addWorkflow: (state, { payload: { dataTypeName, workflow } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ name }) => name === dataTypeName,
      );

      if (state.data && typeIndex !== undefined) {
        const workflowPath = ['dataTypes', typeIndex, 'workflows'];
        const existingWorkflows = get(state.data, workflowPath, []);
        state.data = set(
          workflowPath,
          [...existingWorkflows, workflow],
          state.data,
        );

        state.data = set(
          ['workflows', workflow.workflow.id, 'actions'],
          [],
          state.data,
        );
      }
    },
    changeWorkflow: (state, { payload: { dataTypeName, workflow } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ name }) => name === dataTypeName,
      );
      if (state.data && typeIndex !== undefined) {
        const workflowPath = ['dataTypes', typeIndex, 'workflows'];
        const existingWorkflows = get(state.data, workflowPath, []);
        const workflowIndex = existingWorkflows.findIndex(
          ({ id }: Workflow) => id === workflow.id,
        );

        if (workflowIndex !== undefined) {
          state.data = set(
            [...workflowPath, String(workflowIndex)],
            workflow,
            state.data,
          ) as ProjectState;
        }
      }
    },
    cloneWorkflow: (
      state,
      { payload: { dataTypeName, workflow, actions } },
    ) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ name }) => name === dataTypeName,
      );

      if (state.data && typeIndex !== undefined) {
        const workflowPath = ['dataTypes', typeIndex, 'workflows'];
        const existingWorkflows = get(state.data, workflowPath, []);
        state.data = set(
          workflowPath,
          [...existingWorkflows, workflow],
          state.data,
        );
        state.data = set(
          ['workflows', String(workflow.workflow.id), 'actions'],
          actions,
          state.data,
        ) as ProjectState;
      }
    },
    removeWorkflow: (state, { payload: { dataTypeName, id } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ name }) => name === dataTypeName,
      );
      if (state.data && typeIndex !== undefined) {
        const workflowPath = ['dataTypes', typeIndex, 'workflows'];
        const existingWorkflows = get(state.data, workflowPath, []);
        const workflowsWithout = existingWorkflows.filter(
          (workflow: Workflow) => workflow.id !== id,
        );
        state.data = set(workflowPath, workflowsWithout, state.data);
      }
    },

    addGoogleSignInClient: (state, { payload }) => {
      if (state.data && state.data.integrations) {
        state.data.integrations.google = payload;
      }
    },
    addApi: (state, { payload }) => {
      if (state.data) {
        state.data.apis = [...state.data.apis, payload];
      }
    },
    updateApiInList: (state, { payload }) => {
      const apiIndex = state.data?.apis.findIndex(
        ({ name }: any) => name === payload.name,
      );

      if (state.data && apiIndex !== undefined) {
        state.data.apis = set([apiIndex], payload, state.data.apis);
      }
    },
    addEndpoint: (state, { payload: { endpoint, apiId } }) => {
      const apiIndex = state.data?.apis.findIndex(
        ({ id }: any) => id === apiId,
      );

      if (state.data && apiIndex !== undefined) {
        const existingEndpoints = get(
          state.data.apis[apiIndex],
          'endpoints',
          [],
        );
        state.data.apis[apiIndex] = set(
          'endpoints',
          [...existingEndpoints, endpoint],
          state.data.apis[apiIndex],
        );
      }
    },
    changeEndpoint: (state, { payload: { endpoint, apiId } }) => {
      const apiIndex = state.data?.apis.findIndex(
        ({ id }: any) => id === apiId,
      );

      if (state.data && apiIndex !== undefined) {
        const existingEndpoints = get(
          state.data.apis[apiIndex],
          'endpoints',
          [],
        );
        const endpointIndex = existingEndpoints.findIndex(
          ({ id }: any) => id === endpoint.id,
        );
        state.data.apis[apiIndex] = set(
          'endpoints',
          set(endpointIndex, endpoint, existingEndpoints),
          state.data.apis[apiIndex],
        );
      }
    },
    addProjectUsers: (state, { payload }) => {
      if (state.data) {
        state.data.users = [...(state.data?.users ?? []), ...payload];
      }
    },
    removeProjectUserById: (state, { payload }) => {
      if (state.data && state.data.users) {
        state.data.users = state.data.users.filter(
          ({ user }: { user: ProjectUser }) => user.id !== payload,
        );
      }
    },
    addDomain: (state, { payload }) => {
      if (state.data) {
        state.data.domains = [...state.data.domains, payload];
      }
    },
    removeDomain: (state, { payload }) => {
      if (state.data) {
        state.data.domains = state.data.domains.filter(
          (domain: { id: number }) => domain.id !== payload,
        );
      }
    },
    updateSmtp: (state, { payload }) => {
      if (state.data && state.data.integrations) {
        state.data.integrations.smtp = payload;
      }
    },
  },
});

export const {
  addApi,
  addDataField,
  addDataRollup,
  addDomain,
  addGoogleSignInClient,
  removeDataField,
  removeDataSource,
  removeDataType,
  removePermissionRule,
  addDataType,
  addDataTypes,
  addEndpoint,
  addMedia,
  addWorkflow,
  addProjectUsers,
  changeEndpoint,
  setPermissionRules,
  setPermissionRule,
  setPermissionsEnabled,
  updateApiInList,
  updateDataField,
  removeDataRollup,
  removeDomain,
  removeProjectUserById,
  removeWorkflow,
  changeWorkflow,
  cloneWorkflow,
  incrementPublishedVersion,
  setPublishedVersion,
  setHasUnpublishedChanges,
  setProject,
  undo,
  redo,
  updateSource,
  updateDataType,
  updateProject,
  updateProjectStatus,
  updateSmtp,
} = projectSlice.actions;

export default projectSlice.reducer;
