import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import gql from 'graphql-tag';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { Button, Loader, getColorShade } from '@noloco/components';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import Title from '../../elements/sections/Title';
import {
  ONBOARDING_TASK_COLLECTION_QUERY,
  TOGGLE_ONBOARDING_TASK_COMPLETE_MUTATION,
  UPDATE_ONBOARDING_TASKS_MUTATION,
} from '../../queries/project';
import useCacheQuery from '../../utils/hooks/useCacheQuery';
import { NEW_TAB, useOpenUrl } from '../../utils/hooks/useOpenUrl';
import usePrevious from '../../utils/hooks/usePrevious';
import useRouter from '../../utils/hooks/useRouter';
import useScopeUser from '../../utils/hooks/useScopeUser';
import { getText } from '../../utils/lang';
import { isInternal } from '../../utils/user';
import OnboardingTask from './OnboardingTask';

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof OnboardingTasks.defaultProps;

// @ts-expect-error TS(7022): 'OnboardingTasks' implicitly has type 'any' becaus... Remove this comment to see the full error message
const OnboardingTasks = ({
  className,
  project,
  onClick,
  title,
  theme,
  subtitle,
}: Props) => {
  const user = useScopeUser();
  const openUrl = useOpenUrl();
  const { push } = useRouter();
  const errorAlert = useGraphQlErrorAlert();
  const successColor = theme.brandColorGroups.success;
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setDraftTasks] = useState(null);
  const queryArgs = {
    context: {
      projectQuery: true,
      projectName: project.name,
    },
  };

  const { data: onboardingTaskData, loading } = useCacheQuery(
    gql`
      ${ONBOARDING_TASK_COLLECTION_QUERY}
    `,
    { ...queryArgs, fetchPolicy: 'network-only' },
  );

  const [updateOnboardingTasks] = useMutation(
    gql`
      ${UPDATE_ONBOARDING_TASKS_MUTATION}
    `,
    queryArgs,
  );
  const [toggleOnboardingTaskComplete] = useMutation(
    gql`
      ${TOGGLE_ONBOARDING_TASK_COMPLETE_MUTATION}
    `,
    queryArgs,
  );

  const addTask = useCallback(() => {
    // @ts-expect-error TS(2345): Argument of type '(currentTasks: null) => { title:... Remove this comment to see the full error message
    setDraftTasks((currentTasks) => [
      ...(currentTasks || []),
      {
        title: getText(
          { count: currentTasks ? (currentTasks as any).length + 1 : 1 },
          'core.ONBOARDING_TASKS.edit.task.title',
        ),
        description: getText('core.ONBOARDING_TASKS.edit.task.description'),
        type: 'user',
      },
    ]);
  }, []);

  const previousOnboardingTasks = usePrevious(
    JSON.stringify(onboardingTaskData),
  );
  useEffect(() => {
    if (
      onboardingTaskData &&
      (!tasks || JSON.stringify(onboardingTaskData) !== previousOnboardingTasks)
    ) {
      const newTasks = get(
        onboardingTaskData,
        'onboardingTaskCollection.edges',
        [],
      ).map((edge: any) => edge.node);
      setDraftTasks(newTasks);

      if (newTasks.length === 0 && isInternal(user)) {
        addTask();
        addTask();
      }
    }
  }, [tasks, onboardingTaskData, previousOnboardingTasks, user, addTask]);

  const onSave = useCallback(() => {
    setIsLoading(true);
    updateOnboardingTasks({
      variables: {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        tasks: tasks.map((task: any) => ({
          ...task,
          __typename: undefined,
        })),
      },
    })
      .then(({ data }) => {
        setDraftTasks(data.updateOnboardingTasks);
      })
      .catch((error) => {
        errorAlert(getText('core.ONBOARDING_TASKS.edit.error'), error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [errorAlert, tasks, updateOnboardingTasks]);

  const onUpdateTask = useCallback(
    (index: any) => (path: any, value: any) => {
      // @ts-expect-error TS(2345): Argument of type '(currentTasks: null) => LodashSe... Remove this comment to see the full error message
      setDraftTasks((currentTasks) => set([index, path], value, currentTasks));
    },
    [],
  );

  const onDeleteTask = useCallback(
    (id: any) => () => {
      setDraftTasks((currentTasks) =>
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        currentTasks.filter((task: any) => task.id !== id),
      );
    },
    [],
  );

  const onClickTask = useCallback(
    (task: any) => () => {
      if (!isInternal(user) && task.url) {
        if (task.url.startsWith('/')) {
          push(task.url);
        } else {
          openUrl(task.url, NEW_TAB);
        }
      }
    },
    [openUrl, push, user],
  );

  const onCheckTask = useCallback(
    (index: any) => (e: any) => {
      e.stopPropagation();
      if (!isInternal(user)) {
        const task = get(tasks, [index]);
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        if (task.type !== 'company' || user.company) {
          const variables =
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            task.type === 'user'
              ? // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                { id: task.id, userIds: [user.id] }
              : // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                { id: task.id, companyIds: [user.company.id] };
          toggleOnboardingTaskComplete({ variables });
          // @ts-expect-error TS(2345): Argument of type '(currentTasks: null) => LodashSe... Remove this comment to see the full error message
          setDraftTasks((currentTasks) =>
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            set([index, 'completed'], !task.completed, currentTasks),
          );
        } else {
          alert('You have not been assigned a company');
        }
      }
    },
    [tasks, toggleOnboardingTaskComplete, user],
  );

  const completedTasks = !tasks
    ? 0
    : (tasks as any).filter((t: any) => t.completed).length;

  return (
    <div
      className={classNames(
        className,
        'mt-8 flex flex-col bg-white shadow-md rounded-lg py-12 px-8 mx-8 md:mx-2 w-full max-w-screen-lg',
      )}
      onClick={onClick}
    >
      {(title || subtitle) && (
        <Title
          subtitle={{
            hidden: !subtitle,
            value: subtitle,
          }}
          title={{
            hidden: !title,
            value: title,
          }}
          className="mb-8"
        >
          {isInternal(user) && (
            <Button disabled={isLoading} onClick={onSave}>
              {isLoading ? (
                <Loader type="bars" size="sm" />
              ) : (
                getText('core.ONBOARDING_TASKS.save')
              )}
            </Button>
          )}
          {!isInternal(user) && tasks && (
            <div className="flex items-center">
              <div className="flex flex-col font-medium">
                <span className="text-xl font-gray-800">
                  <span>{completedTasks}</span>
                  <span className="mx-px">/</span>
                  <span>{(tasks as any).length}</span>
                </span>
              </div>
            </div>
          )}
        </Title>
      )}
      <div className="w-full mb-8 rounded-full overflow-hidden bg-gray-100">
        <div
          className={classNames(
            'h-6 rounded-full transition-all',
            `bg-${getColorShade(successColor, 400)}`,
          )}
          style={{
            width:
              tasks && completedTasks > 0
                ? `${(completedTasks / (tasks as any).length) * 100}%`
                : '25px',
          }}
        />
      </div>
      <div className="flex flex-col space-y-8">
        {loading && (
          <div className="w-full flex items-center justify-center py-4">
            <Loader />
          </div>
        )}
        {tasks &&
          (tasks as any).map((task: any, index: any) => (
            <OnboardingTask
              key={task.id || index * -1}
              onClick={onClickTask(task)}
              onCheck={onCheckTask(index)}
              editing={isInternal(user)}
              onDelete={onDeleteTask(task.id)}
              onUpdate={onUpdateTask(index)}
              task={task}
            />
          ))}
        {isInternal(user) && (
          <div
            onClick={addTask}
            className="flex items-center group p-3 cursor-pointer border border-dashed border-gray-200 rounded-lg"
          >
            <div className="w-12 h-12 bg-gray-100 group-hover:bg-gray-200 text-gray-400 group-hover:bg-gray-100 flex justify-center items-center flex-shrink-0 rounded-full">
              <IconPlus size={16} />
            </div>
            <div className="ml-4 flex flex-grow justify-center flex-col">
              <span className="text-base font-medium text-gray-400 group-hover:text-gray-600">
                {getText('core.ONBOARDING_TASKS.edit.new')}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

OnboardingTasks.defaultProps = {};

export default withTheme(OnboardingTasks);
