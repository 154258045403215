import React from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconDots } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { Color, Popover, getColorShade } from '@noloco/components';
import { IMAGE } from '@noloco/core/src/constants/fileTypes';
import { FILE } from '../../../constants/builtInDataTypes';
import { DataField } from '../../../models/DataTypeFields';
import { RecordEdge } from '../../../models/Record';
import {
  formatDisplayField,
  getPreviewFieldsForField,
} from '../../../utils/dataTypes';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { isMultiField } from '../../../utils/relationships';
import { isMultiRelationship } from '../../../utils/relationships';
import { formatBg } from '../../../utils/styles';
import FilePreview from '../forms/FilePreview';

const FieldPreview = ({
  children,
  className,
  backLink,
  hideSecondary,
  innerClassName,
  imageField,
  imagePlaceholder,
  recordLinkRoot,
  secondaryField,
  size,
  primaryColor,
  textFields,
  value,
}: any) => (
  <Box
    is={recordLinkRoot ? Link : 'div'}
    to={
      recordLinkRoot
        ? `${recordLinkRoot}/view/${get(value, 'uuid')}${
            backLink ? `?_parentPage=${backLink}` : ''
          }`
        : '#'
    }
    className={classNames(
      'flex items-center overflow-hidden',
      {
        [`text-${getColorShade(
          primaryColor,
          600,
        )} hover:underline hover:text-${getColorShade(
          primaryColor,
          800,
        )}`]: !!recordLinkRoot,
      },
      className,
    )}
  >
    {((imageField && get(value, [imageField.apiName, 'fileType']) === IMAGE) ||
      imagePlaceholder) && (
      <Box
        w={size}
        h={size}
        className="flex-shrink-0 rounded-md overflow-hidden mr-1 flex justify-center items-center"
      >
        <Box
          className="bg-center bg-contain bg-no-repeat"
          name={get(value, [imageField.apiName, 'name'])}
          w={size}
          h={size}
          style={formatBg(
            get(value, [imageField.apiName, 'url'], imagePlaceholder),
          )}
        />
      </Box>
    )}
    <div
      className={classNames(innerClassName, 'flex flex-col overflow-hidden')}
    >
      <span className="block max-w-full truncate">
        {textFields
          .map((textField: any) =>
            formatDisplayField(textField, get(value, textField.apiName)),
          )
          .join(' ') || `${get(value, 'uuid', '').substring(0, 8)}...`}
      </span>
      {secondaryField && !hideSecondary && (
        <span className="block text-sm opacity-75 max-w-xs truncate">
          {formatDisplayField(
            secondaryField,
            get(value, secondaryField.apiName),
          )}
        </span>
      )}
      {children}
    </div>
  </Box>
);

type RelatedCellCondensedPreviewProps = {
  backLink?: string;
  hideSecondary: boolean;
  innerClassName: string | undefined;
  imageField: DataField | undefined;
  imagePlaceholder: string | undefined;
  size: number;
  edges: RecordEdge[];
  isDarkModeEnabled: boolean;
  primaryColor: Color;
  secondaryField: DataField | undefined | null;
  textFields: DataField[];
  recordLinkRoot: string;
};

const preventDefault = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  event.preventDefault();
};

const RelatedCellCondensedPreview = ({
  backLink,
  hideSecondary,
  innerClassName,
  imageField,
  imagePlaceholder,
  size,
  edges,
  isDarkModeEnabled,
  primaryColor,
  secondaryField,
  textFields,
  recordLinkRoot,
}: RelatedCellCondensedPreviewProps) => {
  return (
    <div onClick={preventDefault} className="flex my-1 rounded-lg">
      <Popover
        p={0}
        showArrow={false}
        content={
          <div
            className={classNames(
              'flex flex-col space-y-2 p-3 w-64 max-h-64 overflow-y-auto overflow-x-hidden',
              { dark: isDarkModeEnabled },
            )}
          >
            {edges.map((edge) => (
              <FieldPreview
                className={classNames(
                  'rounded-lg p-2 flex-shrink-0 text-xs',
                  `bg-${getColorShade(
                    primaryColor,
                    isDarkModeEnabled ? 600 : 100,
                  )}`,
                  `dark:text-${getColorShade(primaryColor, 100)}`,
                )}
                backLink={backLink}
                hideSecondary={hideSecondary}
                innerClassName={innerClassName}
                imageField={imageField}
                imagePlaceholder={imagePlaceholder}
                secondaryField={secondaryField}
                size={size}
                textFields={textFields}
                recordLinkRoot={recordLinkRoot}
                primaryColor={primaryColor}
                key={edge.node.id}
                value={get(edge, 'node')}
              />
            ))}
          </div>
        }
      >
        <div
          className={classNames(
            'flex items-center justify-center text-center px-2 py-1 rounded-lg',
            `bg-${getColorShade(primaryColor, isDarkModeEnabled ? 800 : 50)}`,
            `hover:bg-${getColorShade(
              primaryColor,
              isDarkModeEnabled ? 700 : 200,
            )}`,
            `text-${getColorShade(
              primaryColor,
              isDarkModeEnabled ? 200 : 600,
            )}`,
            `dark:text-${getColorShade(primaryColor, 100)}`,
          )}
        >
          <IconDots size={16} />
        </div>
      </Popover>
    </div>
  );
};

const CONDENSED_MAX_ITEMS = 4;

const RelatedCellItem = ({
  className,
  condensed,
  elementType,
  innerClassName,
  hideSecondary,
  children,
  imagePlaceholder,
  backLink,
  recordLinkRoot,
  field,
  value,
  dataTypes,
  size,
  single,
  theme,
}: any) => {
  const [isDarkModeEnabled] = useDarkMode();

  if (!field) {
    return null;
  }

  const previewFields = getPreviewFieldsForField(field, dataTypes);

  if (!previewFields) {
    return null;
  }

  const { imageField, secondaryField, textFields } = previewFields;

  if (value && field.type === FILE) {
    const isMultiFile = isMultiRelationship(field.relationship);
    return (
      <FilePreview
        // @ts-expect-error TS(2322): Type '{ id: any; elementType: any; files: any; isM... Remove this comment to see the full error message
        id={field.id}
        elementType={elementType}
        files={
          isMultiFile
            ? get(value, 'edges', []).map((edge: any) => edge.node)
            : [value]
        }
        isMultiple={isMultiFile}
        readOnly={true}
      />
    );
  }

  const primaryColor = get(theme, 'brandColorGroups.primary');

  if (isMultiField(field) && !single) {
    const edges = get(value, 'edges', []);
    return (
      <div className="flex flex-wrap overflow-hidden">
        {edges
          .slice(0, condensed ? CONDENSED_MAX_ITEMS : undefined)
          .map((edge: any) => (
            <FieldPreview
              className={classNames(
                className,
                'mr-2 my-1 rounded-lg px-2 py-1',
                `bg-${getColorShade(
                  primaryColor,
                  isDarkModeEnabled ? 800 : 100,
                )}`,
                `dark:text-${getColorShade(primaryColor, 100)}`,
              )}
              backLink={backLink}
              hideSecondary={hideSecondary}
              innerClassName={innerClassName}
              imageField={imageField}
              imagePlaceholder={imagePlaceholder}
              secondaryField={secondaryField}
              size={size}
              textFields={textFields}
              recordLinkRoot={recordLinkRoot}
              primaryColor={primaryColor}
              key={edge.node.id}
              value={get(edge, 'node')}
            />
          ))}
        {condensed && edges.length > CONDENSED_MAX_ITEMS && (
          <RelatedCellCondensedPreview
            backLink={backLink}
            hideSecondary={hideSecondary}
            innerClassName={innerClassName}
            imageField={imageField}
            imagePlaceholder={imagePlaceholder}
            secondaryField={secondaryField}
            size={size}
            textFields={textFields}
            recordLinkRoot={recordLinkRoot}
            primaryColor={primaryColor}
            edges={edges}
            isDarkModeEnabled={isDarkModeEnabled}
          />
        )}
      </div>
    );
  }

  return (
    <FieldPreview
      className={classNames(
        className,
        `dark:text-${getColorShade(primaryColor, 400)}`,
      )}
      backLink={backLink}
      hideSecondary={hideSecondary}
      innerClassName={innerClassName}
      imageField={imageField}
      imagePlaceholder={imagePlaceholder}
      secondaryField={secondaryField}
      size={size}
      recordLinkRoot={recordLinkRoot}
      textFields={textFields}
      primaryColor={primaryColor}
      value={value}
    >
      {children}
    </FieldPreview>
  );
};

RelatedCellItem.defaultProps = {
  innerClassName: '',
  hideSecondary: false,
  size: 7,
  single: false,
};

export default withTheme(RelatedCellItem);
