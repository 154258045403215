export const FULL_BACKGROUND = 'full-background';
export const FULL_BACKGROUND_BORDER = 'full-background-border';
export const ROUNDED = 'rounded';
export const ROUNDED_FULL = 'rounded-full';
export const BOLD = 'bold';
export const BORDERED = 'bordered';

const verticalNavTypes = [
  FULL_BACKGROUND,
  FULL_BACKGROUND_BORDER,
  ROUNDED,
  ROUNDED_FULL,
  BOLD,
  BORDERED,
];

export default verticalNavTypes;
