import React from 'react';
import { Badge } from '@noloco/components';
import { darkModeColors } from '../../../constants/darkModeColors';
import useCmdKey from '../../../utils/hooks/useCmdKey';
import useDarkMode from '../../../utils/hooks/useDarkMode';

const KeyboardShortcutTooltip = ({ text, shortcut }: any) => {
  const cmdKey = useCmdKey();
  const [isDarkModeEnabled] = useDarkMode();

  return (
    <div
      className={`m-1.5 font-mono text-xs ${
        isDarkModeEnabled ? darkModeColors.text.primary : ''
      }`}
    >
      {text}{' '}
      <Badge color="gray">
        {cmdKey} + {shortcut}
      </Badge>
    </div>
  );
};

export default KeyboardShortcutTooltip;
