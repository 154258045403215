import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import max from 'lodash/max';
import min from 'lodash/min';
import union from 'lodash/union';
import { FILE } from '../constants/builtInDataTypes';
import { BOOLEAN, DATE, DECIMAL, INTEGER, TEXT } from '../constants/dataTypes';
import {
  CONTAINS,
  EQUAL,
  Filter,
  GREATER,
  GREATER_OR_EQUAL,
  IN,
  LESS,
  LESS_OR_EQUAL,
  NOT_IN,
} from '../constants/filters';
import { BETWEEN } from '../constants/operators';
import { DataField } from '../models/DataTypeFields';
import { BaseRecord, RecordValue } from '../models/Record';

export const getQueryStringFilterValue = (
  field: DataField,
  config: Record<any, any>,
  value: RecordValue,
) => {
  if (field.relationship || field.relatedField) {
    if (config.multiple) {
      if (Array.isArray(value)) {
        return { edges: value.map((v) => ({ node: { id: String(v) } })) };
      } else {
        return { edges: [{ node: { id: String(value) } }] };
      }
    } else if (Array.isArray(value) && value.length > 0) {
      return { id: String(value[0]) };
    }

    return { id: String(value) };
  }

  if (field.type === BOOLEAN) {
    return value === 'true' || value === true;
  }

  if (field.type === DATE && value) {
    if (config.selectRange === false) {
      return value;
    }
    const [start, end] = (value as string).split('_');
    return {
      start,
      end,
    };
  }

  if (
    (field.type === DECIMAL || field.type === INTEGER) &&
    config.filterOperator !== BETWEEN
  ) {
    return Number(value);
  }

  return value;
};

export const formatFilterValueForQueryString = (
  field: DataField,
  value: any,
  filterConfig: Record<any, any> | null = null,
) => {
  if (field.relationship || field.relatedField) {
    if (value?.edges) {
      return value.edges.map((edge: { node: BaseRecord }) => edge.node.id);
    }

    return value !== undefined && value?.id ? value.id : undefined;
  }

  if (field.type === DECIMAL || field.type === INTEGER) {
    if (!value && value !== 0) {
      return undefined;
    }

    if (filterConfig && filterConfig.filterOperator === BETWEEN) {
      const min = get(value, 'min', '');
      const max = get(value, 'max', '');

      if (!min && !max) {
        return undefined;
      }

      return `${min}:${max}`;
    }
  }

  if (field.type === TEXT && value === null) {
    return null;
  }

  if (field.type === TEXT && (!value || !value.trim())) {
    return undefined;
  }

  if (field.type === DATE) {
    if (filterConfig && filterConfig.selectRange === false) {
      return value;
    }

    if (value?.start && value?.end) {
      return `${value.start}_${value.end}`;
    }
  }

  return value;
};

export const formatTextInput = (fieldType: string, value: any) => {
  if (fieldType === TEXT) {
    return value;
  }

  if (fieldType === DECIMAL) {
    return parseFloat(value);
  }

  return parseInt(value, 10);
};

export const filterValidFilterFields = (field: DataField): boolean =>
  field.type !== FILE && !field.rollup;

const mergeEqualFilter = (where: any, result: any) => {
  const existingEqual = where[EQUAL];

  // Duplicate equals
  if (!isUndefined(existingEqual) && existingEqual === result) {
    return where;
  }

  // Mismatched equals
  if (!isUndefined(existingEqual) && existingEqual !== result) {
    throw new Error('Cannot merge two EQUAL filters that do not match');
  }

  const existingIn = where[IN];

  // Merge in and equals
  if (!isUndefined(existingIn)) {
    return {
      ...where,
      [IN]: existingIn.filter(
        (existingInValue: any) => existingInValue === result,
      ),
    };
  }

  // Add equals
  return { ...where, [EQUAL]: result };
};

const mergeInFilter = (where: any, result: any[]) => {
  const existingIn = where[IN];

  if (!isUndefined(existingIn)) {
    return {
      ...where,
      [IN]: intersection(existingIn, result),
    };
  }

  const existingEqual = where[EQUAL];

  if (!isUndefined(existingEqual) && result.includes(existingEqual)) {
    return where;
  }

  if (!isUndefined(existingEqual) && !result.includes(existingEqual)) {
    throw new Error(
      'Cannot merge an IN filter that does not match an existing EQUAL',
    );
  }

  return { ...where, [IN]: result };
};

const combineFilterResults = {
  [CONTAINS]: (a: any, b: any) => {
    if (a === b) {
      return a;
    }

    throw new Error('Cannot merge two CONTAINS filters that do not match');
  },
  [GREATER]: (a: any, b: any) => max([a, b]),
  [GREATER_OR_EQUAL]: (a: any, b: any) => max([a, b]),
  [LESS]: (a: any, b: any) => min([a, b]),
  [LESS_OR_EQUAL]: (a: any, b: any) => min([a, b]),
  [NOT_IN]: (a: any, b: any) => union(a, b),
};

const mergeFilter = (filter: Filter, where: any, result: any) => {
  const existingResult = where[filter];

  if (!isUndefined(existingResult)) {
    const combineResults = combineFilterResults[filter];

    return {
      ...where,
      [filter]: combineResults(existingResult, result),
    };
  }

  return {
    ...where,
    [filter]: result,
  };
};

export const mergeFilterIntoWhere = (
  existingWhere: any,
  filter: Filter,
  not: boolean,
  result: any,
): any => {
  if (not) {
    return {
      ...existingWhere,
      not: mergeFilterIntoWhere(
        get(existingWhere, ['not'], {}),
        filter,
        false,
        result,
      ),
    };
  }

  if (isEmpty(existingWhere)) {
    return { [filter]: result };
  }

  if (filter === EQUAL) {
    return mergeEqualFilter(existingWhere, result);
  }

  if (filter === IN) {
    return mergeInFilter(existingWhere, result);
  }

  return mergeFilter(filter, existingWhere, result);
};
