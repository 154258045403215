import React, { forwardRef, useMemo, useState } from 'react';
import { Box, NavToggle, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { BELOW, CENTER, LEFT, RIGHT } from './headerNavTypes';

type Props = {
  className?: string;
  Logo?: React.ReactNode;
  ProfilePicture?: React.ReactNode;
  SearchInput?: React.ReactNode;
  Nav?: React.ReactNode;
  NavItems?: React.ReactNode;
  screen?: boolean | string;
  type?: any; // TODO: PropTypes.oneOf(headerNavTypes)
  theme: {};
};

const HeaderNav = forwardRef<any, Props>(
  // @ts-expect-error TS(2339): Property 'style' does not exist on type 'Props'.
  ({ className, Nav, Logo, ProfilePicture, SearchInput, style, type }, ref) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen((isOpen) => !isOpen);

    // @ts-expect-error TS(2604): JSX element type 'Nav' does not have any construct... Remove this comment to see the full error message
    const navElement = useMemo(() => Nav && <Nav />, [Nav]);
    // @ts-expect-error TS(2604): JSX element type 'Logo' does not have any construc... Remove this comment to see the full error message
    const logoElement = useMemo(() => Logo && <Logo className="" />, [Logo]);

    // @ts-expect-error TS(2604): JSX element type 'SearchInput' does not have any c... Remove this comment to see the full error message
    const searchElement = useMemo(() => SearchInput && <SearchInput />, [
      SearchInput,
    ]);

    // @ts-expect-error TS(2604): JSX element type 'ProfilePicture' does not have an... Remove this comment to see the full error message
    const profileElement = useMemo(() => ProfilePicture && <ProfilePicture />, [
      ProfilePicture,
    ]);

    const headerNavChildren = useMemo(() => {
      switch (type) {
        case RIGHT:
          return (
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center">
                {logoElement}
                {searchElement}
              </div>
              <div className="flex items-center text-white">
                <div className="flex space-x-4 mr-8 items-center justify-start">
                  {navElement}
                </div>
                {profileElement}
              </div>
            </div>
          );
        case CENTER:
          return (
            <div className="flex flex-1 justify-between items-center w-full">
              <div className="flex space-x-4 mr-8 items-center justify-start">
                {navElement}
              </div>
              <div className="flex flex-1 justify-center items-center">
                {logoElement}
              </div>
              <div className="flex flex-1 justify-end items-center">
                {searchElement}
                {profileElement}
              </div>
            </div>
          );
        case BELOW:
          return (
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center">
                {logoElement}
                {searchElement}
              </div>
              <div className="flex items-center justify-end">
                {profileElement}
              </div>
            </div>
          );
        default:
        case LEFT:
          return (
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center">
                {logoElement}
                <div className="flex space-x-4 ml-8 items-center justify-start">
                  {navElement}
                </div>
              </div>
              <div className="flex items-center justify-end">
                {searchElement}
                {profileElement}
              </div>
            </div>
          );
      }
    }, [type, logoElement, searchElement, navElement, profileElement]);

    const bgClasses =
      className && className.matchAll
        ? [...className.matchAll(/(bg-[a-z0-9-]+)/g)].map((m) => m[0]).join(' ')
        : '';
    return (
      <div
        className={classNames(className, 'flex flex-col w-full')}
        style={style}
        ref={ref}
      >
        <Box className={classNames('flex flex w-full relative')} is="header">
          <div className="flex flex-col w-full hidden md:block">
            <div className="flex justify-between items-center w-full px-8 py-2">
              <div className="flex items-center">{logoElement}</div>
              {SearchInput && <div className="mx-1">{searchElement}</div>}
              <div className="flex items-center">
                <NavToggle header={{ style: {}, onToggle: toggleOpen }} />
              </div>
            </div>
            {open && (
              <div className="w-full relative h-px">
                <div
                  className={classNames(
                    bgClasses,
                    'flex space-y-2 px-8 py-4 z-50 flex-col w-full absolute top-0 left-0 right-0 shadow-md',
                  )}
                >
                  {navElement}
                  <div className="mt-4">{profileElement}</div>
                </div>
              </div>
            )}
          </div>
          <div className="w-full md:hidden px-8 py-2">{headerNavChildren}</div>
        </Box>
        {type === BELOW && (
          <Box
            className="flex space-x-4 items-center justify-center w-full md:hidden"
            bg="white"
            p={{ x: 8, y: 2 }}
          >
            {navElement}
          </Box>
        )}
      </div>
    );
  },
);

HeaderNav.defaultProps = {
  className: '',
  // @ts-expect-error TS(2322): Type '{ className: string; navOptions: never[]; sc... Remove this comment to see the full error message
  navOptions: [],
  screen: 'lg',
  type: LEFT,
};

export default withTheme(HeaderNav);
