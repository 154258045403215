import React, { useCallback, useMemo, useState } from 'react';
import {
  IconCircleCheck,
  IconFlag3Filled,
  TablerIconsProps,
} from '@tabler/icons-react';
import classNames from 'classnames';
import {
  AutoSizedTextInput,
  Button,
  FormField,
  Loader,
  Modal,
} from '@noloco/components';
import Title from '@noloco/core/src/elements/sections/Title';
import { Project } from '@noloco/core/src/models/Project';
import { WEBSITE_EXAMPLES, trackEvent } from '@noloco/core/src/utils/analytics';
import useSetBaseScope from '@noloco/core/src/utils/hooks/useSetBaseScope';
import { getText } from '@noloco/core/src/utils/lang';
import BaseExample from '../BaseExample';

const LANG_KEY = 'websiteExamples.actionButton';

type DemoAction = {
  button: string;
  Icon: (props: TablerIconsProps) => JSX.Element;
  title: string;
  subtitle: string;
};

const START_PROJECT_ACTION: DemoAction = {
  button: 'Start Project',
  Icon: IconCircleCheck,
  title: "Are you sure '{{name}}' is ready to start?",
  subtitle:
    'This will start the project kickoff process and assign you as the project lead.',
};

const FINISH_PROJECT_ACTION: DemoAction = {
  button: 'Finish Project',
  Icon: IconFlag3Filled,
  title: "Have you finished '{{name}}'?",
  subtitle:
    'Clicking confirm will send the final assets to the client and request an invoice for the remaining billable hours.',
};

const PROJECT_NAME = 'Marina Shop Fit Project';

const replaceNamePlaceholder = (str: string) =>
  str.replace(/{{name}}/g, PROJECT_NAME);

const ExampleActionEditor = ({
  action,
  disabled,
  onChange,
}: {
  action: DemoAction;
  disabled: boolean;
  onChange: (action: DemoAction) => void;
}) => {
  const onChangeProperty = useCallback(
    (property, value) => {
      onChange({
        ...action,
        [property]: value,
      });
    },
    [action, onChange],
  );

  const { button, Icon, title, subtitle } = action;

  return (
    <div className="flex flex-col border rounded-lg p-3 w-full max-w-80 mx-auto bg-gray-50">
      <div className="flex items-center">
        <Icon size={22} className="mr-3" />
        <AutoSizedTextInput
          value={button}
          disabled={disabled}
          onChange={({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) =>
            onChangeProperty('button', value)
          }
        />
      </div>
      {!disabled && (
        <div className="flex flex-col space-y-3 mt-6">
          <FormField
            label="Confirmation title"
            value={title}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) =>
              onChangeProperty('title', value)
            }
          />
          <FormField
            label="Confirmation description"
            value={subtitle}
            type="textarea"
            rows={2}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) =>
              onChangeProperty('subtitle', value)
            }
          />
        </div>
      )}
    </div>
  );
};

const RecordActionsExample = ({ project }: { project: Project }) => {
  useSetBaseScope(project.dataTypes);

  const [startProjectAction, setStartProjectAction] = useState(
    START_PROJECT_ACTION,
  );
  const [finishProjectAction, setFinishProjectAction] = useState(
    FINISH_PROJECT_ACTION,
  );
  const [projectIsStarted, setProjectIsStarted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onStartAction = useCallback(() => {
    setShowModal(true);
    trackEvent(WEBSITE_EXAMPLES.ACTION_BUTTON_CLICKED);
  }, []);

  const onCancel = useCallback(() => {
    closeModal();
    trackEvent(WEBSITE_EXAMPLES.ACTION_BUTTON_CANCELED);
  }, [closeModal]);

  const onConfirm = useCallback(() => {
    setLoading(true);
    trackEvent(WEBSITE_EXAMPLES.ACTION_BUTTON_CONFIRMED);
    setTimeout(() => {
      setLoading(false);
      setProjectIsStarted((currentProjectStatus) => !currentProjectStatus);
      closeModal();
    }, 700);
  }, [closeModal]);

  const { button, Icon, title, subtitle } = useMemo(() => {
    const baseAction = projectIsStarted
      ? finishProjectAction
      : startProjectAction;

    return {
      ...baseAction,
      button: replaceNamePlaceholder(baseAction.button),
      title: replaceNamePlaceholder(baseAction.title),
      subtitle: replaceNamePlaceholder(baseAction.subtitle),
    };
  }, [finishProjectAction, projectIsStarted, startProjectAction]);

  return (
    <BaseExample showGradient={false}>
      <div className="flex md:flex-col items-center overflow-hidden">
        <div className="flex flex-col justify-center items-center mb-8 flex-shrink-0 w-1/2 md:w-full space-y-3 px-4">
          <div className="flex flex-col ">
            <h2 className="text-3xl font-medium mb-3">
              {getText(LANG_KEY, 'title')}
            </h2>
            <p className="text-base mb-8">{getText(LANG_KEY, 'subtitle')}</p>
          </div>
          <ExampleActionEditor
            action={startProjectAction}
            onChange={setStartProjectAction}
            disabled={projectIsStarted}
          />
          <ExampleActionEditor
            action={finishProjectAction}
            onChange={setFinishProjectAction}
            disabled={!projectIsStarted}
          />
        </div>
        <div className="px-6 top-0 z-30 flex flex-col w-full bg-white rounded-lg border">
          <Title
            className="py-6 sm:py-3 w-full max-w-6xl mx-auto"
            subtitle={{
              hidden: true,
            }}
            title={{
              hidden: false,
              value: PROJECT_NAME,
            }}
          >
            <Button
              className={classNames(
                'flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap action-button border-gray-300 bg-white hover:bg-gray-50',
              )}
              onClick={() => onStartAction()}
              disabled={showModal}
              variant="secondary"
            >
              <Icon className="opacity-75 w-5 h-5" />
              <span>{button}</span>
            </Button>
          </Title>
        </div>
      </div>
      {showModal && (
        <Modal
          title={title}
          contextual={true}
          onClose={closeModal}
          onCancel={onCancel}
          onConfirm={onConfirm}
          confirmText={
            loading ? (
              <Loader size="sm" className="mx-auto" />
            ) : (
              getText('elements.VIEW.actionButtons.confirm')
            )
          }
          rootSelector="#record-actions-example"
        >
          <p className="mb-6 text-base">{subtitle}</p>
        </Modal>
      )}
    </BaseExample>
  );
};

export default RecordActionsExample;
